import {
  PLAN_OLD_FREE,
  PLAN_NEW_FREE,
  PLAN_OLD_PRO,
  PLAN_OLD_ULTIMATE,
  PLAN_SMALL_BUSINESS,
  PLAN_ENGAGE_PLUS,
  PLAN_EXTENSION_ADDON,
  PLAN_ENTERPRISE,
  PLAN_ENGAGE_WIX,
} from "../../constants";
import GiftBoxIcon from "../../../assets/icons/gift_box.svg";
import BirdIcon from "../../../assets/icons/bird.svg";
import TieIcon from "../../../assets/icons/tie.svg";
import PlugIcon from "../../../assets/icons/plug.svg";
import ShiningTieIcon from "../../../assets/icons/engage_plus.svg"

export const PLANS = [
  {
    planId: PLAN_OLD_FREE.toString(),
    planName: "Current Plan",
    recommended: false,
    currency: "$",
    price: "0",
    sub_interval: "Month",
    icon: TieIcon,
    included: "",
    key_features: [
      "50+ types Whatsapp Widget and buttons",
      "Multiple whatsapp numbers and time of chat availability",
    ],
    catch_line: "",
  },
  {
    planId: PLAN_OLD_PRO.toString(),
    planName: "Pro",
    recommended: false,
    currency: "$",
    price: "7.99",
    sub_interval: "Month",
    icon: TieIcon,
    included: "",
    key_features: [
      "50+ types Whatsapp Widget and buttons",
      "Multiple whatsapp numbers and time of chat availablity.",
      "Analytics Dashboard",
      "WhatsApp Customer Support Tool",
    ],
    catch_line: "",
  },
  {
    planId: PLAN_OLD_ULTIMATE.toString(),
    planName: "Ultimate",
    recommended: false,
    currency: "$",
    price: "9.99",
    sub_interval: "Month",
    icon: TieIcon,
    included: "Everything in Free, plus:",
    key_features: [
      "Private whatsapp number",
      "Whatsapp Business Account setup with Green Tick",
      "WhatsApp Customer Support Tool",
    ],
    catch_line: "",
  },
  {
    planId: PLAN_NEW_FREE.toString(),
    planName: "Free",
    recommended: false,
    currency: "$",
    price: "0",
    additionalCharges: "+ Usage Based Charge",
    sub_interval: "Month",
    icon: GiftBoxIcon,
    included: "",
    key_features: [
      "50+ types Whatsapp Widget and buttons",
      "Multiple whatsapp numbers and time of chat availability",
      "Analytics Dashboard",
      "Automated Whatsapp messages (Enabled by Default)",
      "Pre approved templates",
      "Order Confirmation, Order Shipment and Abandoned Cart Recovery",
    ],
    catch_line: '',
    note: 'Note: Per message charges for free plan users can be found <a target="_blank" href="https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0" rel="noopener noreferrer">pricing chart</a>',
  },
  {
    planId: PLAN_SMALL_BUSINESS.toString(),
    planName: "Growth",
    recommended: false,
    currency: "$",
    price: "15",
    sub_interval: "Month",
    icon: BirdIcon,
    included: "Everything in Free, plus:",
    key_features: [
      "Private whatsapp number",
      "Whatsapp Business Account setup with Green Tick",
      "Create your own templates",
      "Marketing campaigns",
      "Advanced analytics",
      "WhatsApp Customer Support Tool",
    ],
    catch_line: '1000 free user-initiated conversations/Month',
    note: 'Note: Per Message Charges for growth plan users = <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/14Wc_J09VwCZ3LaIzVfsCs5bySHTVSITk2sk0JsEjzAE">WA Charges</a> + $ 0.004',
  },
  {
    planId: PLAN_ENTERPRISE.toString(),
    planName: "Engage",
    recommended: false,
    currency: "$",
    price: "25",
    sub_interval: "Month",
    icon: TieIcon,
    included: "Everything in Growth, plus:",
    key_features: [
      'WhatsApp Inbox for your Whatsapp Business Account',
      'Dashboard with unlimited agents',
      'Tag, assign & reply to Whatsapp chats',
      'Setup auto reply & quick reply messages',
      'Detailed reporting of support conversations',
      'Round-robin allocation of incoming customer conversations',
    ],
    catch_line: '',
    note: 'Note: Per Message Charges for engage plan users = <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/14Wc_J09VwCZ3LaIzVfsCs5bySHTVSITk2sk0JsEjzAE">WA Charges</a> + $ 0.004',
  },
  {
    planId: PLAN_ENGAGE_WIX.toString(),
    planName: "Engage",
    recommended: true,
    currency: "$",
    price: "15",
    sub_interval: "Month",
    icon: TieIcon,
    included: "",
    key_features: [
      'WhatsApp Inbox for your Whatsapp Business Account',
      'Dashboard with unlimited agents',
      'Tag, assign & reply to Whatsapp chats',
      'Setup auto reply & quick reply messages',
      'Detailed reporting of support conversations',
      'Round-robin allocation of incoming customer conversations',
    ],
    catch_line: '1000 free user-initiated conversations/Month',
    note: 'Note : Charges per conversation for engage plan users = <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/14Wc_J09VwCZ3LaIzVfsCs5bySHTVSITk2sk0JsEjzAE">WhatsApp Charges</a> + $ 0.004',
  },
];

export const NEW_PLANS = [
  {
    planId: PLAN_NEW_FREE.toString(),
    planName: "Free",
    recommended: false,
    currency: "$",
    price: "0",
    additionalCharges: "+ Usage Based Charge",
    sub_interval: "Month",
    icon: GiftBoxIcon,
    included: "",
    key_features: [
      {
        title: 'Widgets + Automation:',
        featuresList: [
          '50+ customizable WhatsApp chat and share widgets',
          'Automated WhatsApp messages for order confirmation, shipment updates and abandoned cart recovery',
          'Cross-selling. COD confirmation, and feedback features',
          'Analytics dashboard for monitoring automated message performance',
          '5+ opt-in widgets for building customer database and expanding marketing reach'
        ],
      },
      {
        title: 'Branding & Marketing:',
        featuresList: [
          'Private WhatsApp Business API for a personalized brand experience',
          'WhatsApp marketing campaigns to drive sales and increase customer engagement',
          'Pre-approved marketing templates to streamline your marketing efforts',
          'Advanced Customer segmentation tool (Upto 5 customer groups)'
        ],
      },
      {
        title: 'Support Tool:',
        featuresList: [
          'Advanced agent assist tool to improve agent efficiency and customer satisfaction'          
        ],
      }
    ],
    catch_line: '',
    note: '<p style="text-align: left;">Note:</p><ol><li style="text-align: left;">The usage charge per conversation for different plan users can be found in &nbsp;<a href="https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0" target="_blank" rel="noopener noreferrer">pricing chart</a></li><li style="text-align: left;">Automated templates are enabled by default</li></ol>',
  },
  {
    planId: PLAN_SMALL_BUSINESS.toString(),
    planName: "Engage",
    recommended: true,
    currency: "$",
    price: "15",
    additionalCharges: "+ Usage Based Charge",
    sub_interval: "Month",
    icon: TieIcon,
    included: "Everything in Free plus:",
    key_features: [
      {
        title: 'Branding & Marketing:',
        featuresList: [
          'Free green tick application to verify your business account',
          'Custom automated messages to engage with customers on a deeper level',
          'Advanced Customer segmentation tool (Upto 10 customer groups)'
        ],
      },
      {
        title: 'Support Tool:',
        featuresList: [
          'CRM tool to manage customer interactions and streamline customer support.',
        ],
      },
      {
        title: 'Lower Pricing per message:',
        featuresList: [
        ],
      }
    ],
    catch_line: '',
    note: '<p style="text-align: left;">Note:</p><ol><li style="text-align: left;">The usage charge per conversation for different plan users can be found in &nbsp;<a href="https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0" target="_blank" rel="noopener noreferrer">pricing chart</a></li><li style="text-align: left;">Automated templates are enabled by default</li><li style="text-align: left;">This plan comes with a 7-day free trial excluding usage based charge.</li></ol>',
  },
  {
    planId: PLAN_ENGAGE_PLUS.toString(),
    planName: "Engage Plus",
    recommended: true,
    currency: "$",
    price: "40",
    additionalCharges: "+ Usage Based Charge",
    sub_interval: "Month",
    icon: ShiningTieIcon,
    included: "Everything in Engage plus:",
    key_features: [
      {
        title: 'Branding & Marketing:',
        featuresList: [
          'Advanced Campaign Analytics with link tracking analysis',
          'Advanced Customer segmentation tool (Unlimited Groups)',
          'Campaign Scheduler'
        ],
      },
      {
        title: 'Hand held onboarding',
        featuresList: [],
      },
      {
        title: 'Lower WhatsApp Conversation prices',
        featuresList: [],
      },
      {
        title: 'Priority Support',
        featuresList: [],
      },
    ],
    catch_line: '',
    note: '<p style="text-align: left;">Note:</p><ol><li style="text-align: left;">The usage charge per conversation for different plan users can be found in &nbsp;<a href="https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0" target="_blank" rel="noopener noreferrer">pricing chart</a></li><li style="text-align: left;">Automated templates are enabled by default</li><li style="text-align: left;">This plan comes with a 7-day free trial excluding usage based charge.</li></ol>',
  },
];

export const EXTENSION_ONLY_PLAN = {
  planId: PLAN_EXTENSION_ADDON,
  planName1: "Whatsapp Support Tool",
  planName2: "Extension",
  currency: "$",
  price: "7.99",
  sub_interval: "Month",
  icon: PlugIcon,
  included: "",
  key_features: ["Everything in Free + Manual Whatsapp Customer Support Tool"],
  catch_line: "",
};

export const ENGAGE_WIX_PLAN_INFO = {
  planId: PLAN_ENGAGE_WIX.toString(),
  planName: "Engage Plan",
  currency: "$",
  price: "15",
  sub_interval: "Per month",
  icon: PlugIcon,
  included: '',
  key_features: ['Everything in Free + Manual Whatsapp Customer Support Tool'],
  catch_line: 'Take Your Business to next level with Engage Plan!',
  start_line: 'Get Started',
  features1: [
    'Whatsapp Business API number',
    'Green tick application',
    'WhatsApp campaigns',
    'Advanced analytics',
    'Shared Team Inbox',
  ],
  features2: [
    'Automated messages',
    'Abandoned cart recovery',
    'Chat Widget & Share Widget',
    'CRM tool',
    'Opt-in tools',
  ],
  note1: 'You will also be charged based on number of WhatsApp messages sent.',
  note2:
    'Charge per message = <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/14Wc_J09VwCZ3LaIzVfsCs5bySHTVSITk2sk0JsEjzAE">WhatsApp Cost</a> + $ 0.004',
};

export const ENGAGE_PLUS_WIX_PLAN_INFO = {
  planId: PLAN_ENGAGE_PLUS.toString(),
  planName: "Engage Plus Plan",
  currency: "$",
  price: "40",
  sub_interval: "Per month",
  icon: ShiningTieIcon,
  included: '',
  key_features: ['Everything in Engage + Campaign Scheduler'],
  catch_line: 'Take Your Business to next level with Engage Plus Plan!',
  start_line: 'Get Started',
  features1: [
    'Whatsapp Business API number',
    'Green tick application',
    'WhatsApp campaigns',
    'Advanced analytics',
    'Shared Team Inbox',
    'Advanced Campaign Analytics with link tracking analysis'
  ],
  features2: [
    'Automated messages',
    'Abandoned cart recovery',
    'Chat Widget & Share Widget',
    'CRM tool',
    'Opt-in tools',
    'Campaign Scheduler'
  ],
  note1: 'You will also be charged based on number of WhatsApp messages sent.',
  note2:
    'Charge per message = <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/14Wc_J09VwCZ3LaIzVfsCs5bySHTVSITk2sk0JsEjzAE">WhatsApp Cost</a> + $ 0.004',
};

export const recommendedPlans = {
  [PLAN_OLD_FREE.toString()]: [
    PLANS[3],
    {
      ...PLANS[4],
      recommended: true,
    },
    PLANS[5],
  ],
  [PLAN_OLD_PRO.toString()]: [
    PLANS[3],
    {
      ...PLANS[4],
      recommended: true,
    },
    PLANS[1],
  ],
  [PLAN_OLD_ULTIMATE.toString()]: [
    PLANS[3],
    {
      ...PLANS[4],
      recommended: true,
    },
    PLANS[2],
  ],
  [PLAN_NEW_FREE.toString()]: [
    PLANS[3],
    {
      ...PLANS[4],
      recommended: true,
    },
    PLANS[5],
  ],
  [PLAN_SMALL_BUSINESS.toString()]: [
    PLANS[3],
    PLANS[4],
    {
      ...PLANS[5],
      recommended: true,
    },
  ],
  [PLAN_ENTERPRISE.toString()]: [
    PLANS[3],
    PLANS[4],
    {
      ...PLANS[5],
      recommended: true,
    },
  ],
  [PLAN_ENGAGE_WIX.toString()]: [PLANS[0], PLANS[6]],
  [PLAN_ENGAGE_PLUS.toString()]: [NEW_PLANS[0], NEW_PLANS[2]],
};

export const recommendedPlansNew = [
  NEW_PLANS[0],
  {
    ...NEW_PLANS[1],
    recommended: true,
  },
  {
    ...NEW_PLANS[2],
  }
];

export const TILE = 10;

export const planStatusDetails = {
  [PLAN_OLD_FREE]: {
    name: 'Old FREE',
    color: 0,
    description1: 'Upgrade to new',
    nextPlan: ' Free Plan',
    nextPlanId: PLAN_NEW_FREE,
    description2:
      ' to get access to automated templates and abandoned cart notifications',
    premium: false,
  },
  [PLAN_OLD_PRO]: {
    name: 'Old PRO',
    color: 1,
    title: "Congrats! Now you can enjoy Engage Plan features at the price of Pro Plan.",
    description1: 'You are all set to use WhatsApp Inbox. You can also run marketing campaigns, create custom templates and send automated & manual notifications.',
    nextPlan: ' Growth Plan',
    nextPlanId: PLAN_SMALL_BUSINESS,
    premium: true,
  },
  [PLAN_OLD_ULTIMATE]: {
    name: 'Old ULTIMATE',
    color: 1,
    title: "Congrats! Now you can enjoy Engage Plan features at the price of Ultimate Plan.",
    description1: 'You are all set to use WhatsApp Inbox. You can also run marketing campaigns, create custom templates and send automated & manual notifications.',
    nextPlanId: PLAN_SMALL_BUSINESS,
    premium: true,
  },
  [PLAN_NEW_FREE]: {
    name: 'FREE',
    color: 1,
    description1:
      'Use our chat and share widgets and enable automated messages on Whatsapp',
    nextPlanId: PLAN_SMALL_BUSINESS,
    premium: false,
  },
  [PLAN_SMALL_BUSINESS]: {
    name: 'GROWTH',
    color: 1,
    title: "Congrats! Now you can enjoy Engage Plan features at the price of Growth Plan.",
    description1:
      'You are all set to use WhatsApp Inbox. You can also run marketing campaigns, create custom templates and send automated & manual notifications.',
    nextPlanId: PLAN_ENGAGE_PLUS,
    premium: false,
  },
  [PLAN_ENTERPRISE]: {
    name: 'ENGAGE',
    color: 1,
    description1:
      'You are all set to use WhatsApp Inbox. You can also run marketing campaigns, create custom templates and send automated & manual notifications.',
    nextPlanId: PLAN_ENGAGE_PLUS,
    premium: false,
  },
  [TILE]: {
    name: 'Old ULTIMATE',
    color: 1,
    description1: 'You are all set to use WhatsApp Inbox. You can also run marketing campaigns, create custom templates and send automated & manual notifications.',
    nextPlan: ' Growth Plan',
    nextPlanId: PLAN_SMALL_BUSINESS,
    description2:
      ' to create customized templates and launch marketing campaigns',
    premium: false,
  },
  [PLAN_ENGAGE_WIX]: {
    name: 'ENGAGE',
    color: 1,
    description1:
      'You are all set to use WhatsApp Inbox. You can also run marketing campaigns, create custom templates and send automated & manual notifications.',
    nextPlanId: PLAN_ENGAGE_PLUS,
    premium: false,
  },
  [PLAN_ENGAGE_PLUS]: {
    name: 'ENGAGE PLUS',
    color: 1,
    description1:
      'You are all set to use WhatsApp Inbox. You can also run marketing campaigns, create custom templates and send automated & manual notifications.',
    nextPlanId: PLAN_ENGAGE_PLUS,
    premium: true,
  },
};
