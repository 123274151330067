// @ts-nocheck
import {
  Page,
  TextStyle,
  Button,
  Card,
  TextField,
  Checkbox,
} from "@shopify/polaris";
import styles from "./wallet.module.scss";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  PLAN_ENGAGE_WIX,
  WALLET_MODE_KEYS,
  WALLET_TAX,
} from "../../constants/constants";
import CustomForm from "../../common/CustomForm";
import { WALLET_BILLING_ADDRESS } from "../../constants/wallet-constants";
import greenRadioButton from "../../assets/icons/greenRadioButton.png";
import { updatePlan } from "../../redux/actions/pricing";
import PlanModal from "../PlanModal/PlanModal";
import { useShopStore } from '../../hooks/shopStore';

function PlanRecharge(props: any) {
  const { shopDetailsData } = useShopStore();
  const { control, handleSubmit, formState, getValues, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      includeWalletRecharge: true,
      ...props.savedAddress,
    },
  });
  const { errors } = formState;

  const currentBalance = useWatch({
    control,
    name: "balance",
  });

  const includeWalletRechargeChange = useWatch({
    control,
    name: "includeWalletRecharge",
  });

  useEffect(() => {
    if (includeWalletRechargeChange) setValue("balance", "5");
    else setValue("balance", "0");
  }, [includeWalletRechargeChange]);

  const getCalculatedValues = (mode: number) => {
    if (
      !currentBalance ||
      !/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?$/.test(currentBalance)
    ) {
      return null;
    }
    let stripedBalance =
      currentBalance[0] === "$"
        ? Number(currentBalance.substring(1))
        : Number(currentBalance);

    stripedBalance = Math.round(stripedBalance * 1e2) / 1e2;

    const PLAN_CHARGES = 15;
    switch (mode) {
      case WALLET_MODE_KEYS.PLAN_CHARGES:
        return "$" + `${PLAN_CHARGES}`;
      case WALLET_MODE_KEYS.RECHARGE_AMOUNT:
        return "$" + stripedBalance;
      case WALLET_MODE_KEYS.ESTIMATED_TAX:
        return "$" + (stripedBalance * WALLET_TAX).toFixed(0);
      case WALLET_MODE_KEYS.TOTAL_AMOUNT:
        return "$" + (stripedBalance * (1 + WALLET_TAX)).toFixed(0);
      case WALLET_MODE_KEYS.TOTAL_AMOUNT_WITHOUT_TAX:
        return "$" + (stripedBalance + PLAN_CHARGES).toFixed(0);
      default:
        return null;
    }
  };

  let walletRenewalStatus = JSON.parse(
    localStorage.getItem("walletRenewalStatus") || "{}"
  );
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleChange = useCallback(
    (newChecked: boolean) => setChecked(newChecked),
    []
  );

  const isTextValid = (value: any) => {
    if (!value || !value.trim()) return "Cannot be empty";
    return true;
  };

  const isCurrencyValid = (value: any) => {
    if (!value || !value.trim()) return "Cannot be empty";
    else if (!/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?$/.test(value))
      return "Invalid Format, Please use numbers without decimal places only";
    return true;
  };

  const validations = {
    text: isTextValid,
    currency: isCurrencyValid,
  };

  const activatePlan = (planId: number, data: any) => {
    let shop = shopDetailsData;
    let params = {
      from: "pricing",
      returnUrl: window.location.origin + "/confirmation",
      ...data,
    };
    updatePlan(shop.userId, planId.toString(), params).then(
      async (res: any) => {
        if (res.status === "success" || res === 202) {
          if (res.charge && res.charge.confirmationUrl) {
            window.open(res.charge.confirmationUrl, "_self");
          } else {
            console.log("Plan Activated Successfully");
          }
        } else {
          console.log(res);
        }
      },
      () => {}
    );
  };

  const getPaymentLinkCall = (data: any) => {
    let balance =
      data.balance[0] === "$" ? data.balance.substring(1) : data.balance;
    balance = (Math.round(balance * 1e2) / 1e2).toFixed(0);

    if (data.saveBillingAddress === undefined) data.saveBillingAddress = false;
    if (!includeWalletRechargeChange) balance = 0;
    data["rechargeAmount"] = balance;
    activatePlan(PLAN_ENGAGE_WIX, data);
  };

  const handleSubmitForm = async (data: any) => {
    getPaymentLinkCall(data);
  };

  return (
    <Page
      title="Billing Details"
      primaryAction={
        <button
          className="flatGreenOutline_btn"
          onClick={() => props.setPage(0)}
        >
          Back
        </button>
      }
    >
      <div className={styles.wltByline}>
        <TextStyle variation="subdued">
          {
            "All your usage charges from Superlemon Product will be deducted from this wallet."
          }
        </TextStyle>
      </div>
      <PlanModal
        showModal={showModal}
        setShowModal={setShowModal}
        walletRenewalStatus={walletRenewalStatus}
      />
      <div className={styles.wltBalanceContainer}>
        <div className={styles.wltBalanceLeft}>
          <Card sectioned>
            <div className={styles.planRecharge__card}>
              <div
                className={styles.planRecharge__left}
                style={{
                  minWidth: "93%",
                }}
              >
                <p
                  style={{
                    color: "#6D7175",
                    lineHeight: "2rem",
                    fontSize: "1.2rem",
                  }}
                >
                  MONTHLY
                </p>
                <p
                  style={{
                    lineHeight: "3rem",
                    fontSize: "2rem",
                    fontWeight: "500",
                  }}
                >
                  $ 15/Month - Engage{" "}
                </p>
                <p
                  style={{
                    color: "#6D7175",
                    lineHeight: "2rem",
                    fontSize: "1.2rem",
                  }}
                >
                  This fee does not include usage based charge{" "}
                </p>
              </div>
              <div className={styles.planRecharge__right}>
                <img
                  src={greenRadioButton}
                  alt=""
                  height={"25px"}
                  width={"25px"}
                ></img>
              </div>
            </div>
            <p
              onClick={() => {
                setShowModal(true);
              }}
              className=""
              style={{
                fontSize: "1.1rem",
                flex: "0 0 auto",
                display: "flex",
                color: "rgb(94, 193, 39)",
                cursor: "pointer",
                position: "relative",
                flexDirection: "row-reverse",
              }}
            >
              See Details
            </p>
          </Card>
          <Card sectioned>
            <Controller
              name={"includeWalletRecharge"}
              control={control}
              render={({ field }) => (
                <div className="planRecharge_cbx_container">
                  <input
                    type="checkbox"
                    checked={field.value === undefined ? true : field.value}
                    {...field}
                  />
                  <div>{"Include wallet recharge"}</div>
                </div>
              )}
              key={"includeWalletRecharge"}
            />
            {errors["includeWalletRecharge"] && (
              <p className="customForm__ErrorMessage">
                {errors["includeWalletRecharge"].message}
              </p>
            )}
            <br />
            <TextStyle variation="subdued">
              {
                "We strongly recommend wallet recharge along with subscription charge to ensure uninterrupted access to Superlemon’s feature."
              }
            </TextStyle>
            <br />
            <br />
            <TextStyle variation="strong">{"Enter Recharge Amount"}</TextStyle>
            <Controller
              name={"balance"}
              control={control}
              render={({ field }) => (
                <TextField label={""} type={"currency"} {...field} />
              )}
              rules={{
                validate: { required: validations.currency },
              }}
              key={"balance"}
            />
            {errors["balance"] && (
              <p className="customForm__ErrorMessage">
                {errors["balance"].message}
              </p>
            )}
            <br />
          </Card>
          <Card sectioned>
            <TextStyle variation="strong">{"Billing Details"}</TextStyle>

            <br />
            <CustomForm
              data={WALLET_BILLING_ADDRESS}
              control={control}
              formState={formState}
              errors={errors}
            />
          </Card>
        </div>

        <div className={styles.wltBalanceRight}>
          <Card sectioned>
            <div className={styles.wltBalRightRowContainer}>
              <div className={styles.wltBalRightRow}>
                <div className={styles.wltBalRightAmountText}>
                  <TextStyle variation="subdued">{"Engage Plan"}</TextStyle>
                </div>
                <div className={styles.wltBalRightAmountNumber}>
                  <TextStyle variation="subdued">
                    {getCalculatedValues(WALLET_MODE_KEYS.PLAN_CHARGES)}
                  </TextStyle>
                </div>
              </div>

              <div className={styles.wltBalRightRow}>
                <div className={styles.wltBalRightAmountText}>
                  <TextStyle variation="subdued">{"Wallet Recharge"}</TextStyle>
                </div>
                <div className={styles.wltBalRightAmountNumber}>
                  <TextStyle variation="subdued">
                    {getCalculatedValues(WALLET_MODE_KEYS.RECHARGE_AMOUNT)}
                  </TextStyle>
                </div>
              </div>

              <div className={styles.wltBalRightRow}>
                <div className={styles.wltBalRightTotalText}>
                  <TextStyle variation="strong">{"Total"}</TextStyle>
                </div>
                <div className={styles.wltBalRightTotalNumber}>
                  <TextStyle variation="strong">
                    {getCalculatedValues(
                      WALLET_MODE_KEYS.TOTAL_AMOUNT_WITHOUT_TAX
                    )}
                  </TextStyle>
                </div>
              </div>

              <div
                className={styles.wltBalRightButton}
                onClick={handleSubmit(handleSubmitForm)}
              >
                <Button primary fullWidth>
                  {"Pay Now"}
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Page>
  );
}

export default PlanRecharge;
