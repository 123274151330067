import {
  Banner,
  Button,
  Card,
  Heading,
  Layout,
  TextStyle,
} from "@shopify/polaris";
import { useState } from "react";
import {
  applyOptin,
  fetchOptinAdminDetails,
} from "../../../../redux/actions/optIn";
import CheckoutOptinGuideModalShopify from "../../../AbandonedCart/CheckoutOptinGuideShopify";
import CheckoutOptinGuideModalWiX from "../../../AbandonedCart/CheckoutOptinGuideWiX";
import { PLATFORM } from "../../../../constants/constants";
import { useShopStore } from '../../../../hooks/shopStore';

const CheckoutOptinWidget = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [checkoutOptinRequest, setCheckoutOptinRequest] = useState(props.data);
  const [checkout_optin_modal_steps_completed] = useState(false);
  const [open_checkout_optin_modal, setopen_checkout_optin_modal] =
    useState(false);
  const shopData = shopDetailsData;

  const ApplyForApprovalClick = () => {
    const shop_origin = shopData.url || "";
    var data = {};

    if (!checkoutOptinRequest.status) {
      data = {
        shop: shop_origin,
      };
    } else {
      data = {
        status: 1,
        is_email_sent: false,
      };
    }

    applyOptin(shopData.userId).then((res: any) => {
      setCheckoutOptinRequest(res);
      getOptinAdminDetails(shopData.userId);
      props.refresh();
    });
  };

  const getOptinAdminDetails = (userId: string) => {
    fetchOptinAdminDetails(userId).then((res: any) => {
      if (res.status === "success") {
        setCheckoutOptinRequest(res.optin);
      } else if (res !== 204) {
        props.showErrorToast(res);
      }
    });
  };

  const checkoutoptintext = (
    <>
      <p>
        <b>
          Please note that in order to send automated messages, user opt-in is
          mandatory by WhatsApp.
        </b>
      </p>
      <br />
      Kindly go through the complete tutorial and approve the opt-in collection
      from the checkout page.
    </>
  );

  const getCheckoutOptinDynamicContent = () => {
    const modalStepsCompletedContent = checkout_optin_modal_steps_completed ? (
      <span>
        <Button primary onClick={ApplyForApprovalClick}>
          {checkoutOptinRequest.status === "PENDING"
            ? "Re-apply for approval"
            : "Apply for approval"}
        </Button>
      </span>
    ) : (
      <span>
        <Button
          primary
          onClick={() => {
            setopen_checkout_optin_modal(true);
          }}
        >
          Go through the tutorial and complete the steps
        </Button>
      </span>
    );

    if (checkoutOptinRequest) {
      if (!checkoutOptinRequest.status) {
        return modalStepsCompletedContent;
      } else if (checkoutOptinRequest.status === "PENDING") {
        return (
          <Banner>
            <p>
              Your request has been <b>received</b>. You will get a response
              from us on your registered email address.
            </p>
          </Banner>
        );
      } else if (checkoutOptinRequest.status === "APPROVED") {
        return (
          <div>
            <Banner status="success">
              <p>
                Your request has been <b>approved</b>. WhatsApp Opt-in is now
                being collected from the Checkout page.
              </p>
            </Banner>
            <br />
            <p>
              <TextStyle variation="subdued">
                No further action required from your side. You can now disable
                the storefront opt-in widget if you want to (see section below).
              </TextStyle>
            </p>
          </div>
        );
      } else if (checkoutOptinRequest.status === "REJECTED") {
        return (
          <span>
            <Banner status="warning">
              <p>
                Your request has been <b>rejected</b>. Please fix the issue and
                contact the support team.
              </p>
              <br />
              <p>
                <b>Reason for rejection:</b>{" "}
                {checkoutOptinRequest.responseDetails}
              </p>
            </Banner>
            <br />
            {modalStepsCompletedContent}
          </span>
        );
      }
    }
  };

  const checkoutOptinContent = (
    <>
      {checkoutOptinRequest.status === "REJECTED" ? null : (
        <div className="mt-2">
          <Heading element="p">
            Recover more sales by collecting WhatsApp opt-in from Checkout page
          </Heading>
          <br />
          <TextStyle variation="subdued">
            Complete the steps in the guide and apply for approval. We will
            manually check your store checkout page to verify the changes.
          </TextStyle>
        </div>
      )}
      <Card>
        <Card.Section>
          <div className="removebannermargin">
            {getCheckoutOptinDynamicContent()}
          </div>
        </Card.Section>
      </Card>
    </>
  );

  const closeCheckoutOptinGuideModal = () => {
    setopen_checkout_optin_modal(false);
  };

  const checkoutOptinModalStepsCompleted = () => {
    setopen_checkout_optin_modal(false);
    ApplyForApprovalClick();
  };

  return (
    <>
      {open_checkout_optin_modal ? (
        shopData.platform == PLATFORM.SHOPIFY ? (
          <CheckoutOptinGuideModalShopify
            open={true}
            completed={checkout_optin_modal_steps_completed}
            closeModal={closeCheckoutOptinGuideModal}
            stepsCompleted={checkoutOptinModalStepsCompleted}
          />
        ) : (
          <CheckoutOptinGuideModalWiX
            open={true}
            completed={checkout_optin_modal_steps_completed}
            closeModal={closeCheckoutOptinGuideModal}
            stepsCompleted={checkoutOptinModalStepsCompleted}
          />
        )
      ) : null}
      <Layout.AnnotatedSection
        title="Collect WhatsApp opt-in from Checkout Page"
        description={checkoutoptintext}
      >
        {checkoutOptinContent}
      </Layout.AnnotatedSection>
    </>
  );
};

export default CheckoutOptinWidget;
