export const faqs = [
  {
    title: "WhatsApp Business Account",
    display: true,
    content: [
      {
        question: "What is the WhatsApp Business API?",
        answer:
          "WhatsApp Business API is a communication tool that allows businesses to interact with their customers on the WhatsApp messaging platform at scale. It provides an official way for businesses to communicate with their customers via WhatsApp.<br> <br> WhatsApp Business API enables businesses to send and receive messages, notifications, and alerts to customers in real-time. With this API, businesses can automate their customer support, send appointment reminders, order confirmations, shipping notifications, and other customer-related information. However, it's important to note that WhatsApp Business API is available only on “Engage Plan.”",
      },
      {
        question:
          "What is the difference between WhatsApp business app & whatsapp business API?",
        answer:
          "The main difference between the WhatsApp Business app and the WhatsApp Business API lies in their features, capabilities, and the way they are intended to be used. <br> <br> <b class='faq_bold'>WhatsApp Business App:</b>The WhatsApp Business app is a standalone application designed for small and medium-sized businesses (SMBs) to communicate with their customers. It provides business-specific features to enhance customer interactions, such as creating a business profile, automated greetings and replies, labels for organizing conversations, and statistics on message metrics. The app is primarily meant for manual, one-on-one communication with customers. <br> <br> <b class='faq_bold'>WhatsApp Business API </b>:The WhatsApp Business API, on the other hand, is an interface provided by WhatsApp for larger businesses. It allows businesses to send messages in a more automated and programmatic way, enabling features like sending notifications, transactional messages, and customer support messages. The API offers greater scalability, customization, and flexibility, making it suitable for businesses with higher volumes of messages or complex integration requirements.",          
      },
      {
        question:
          "How to apply for WhatsApp Green Tick Verification?",
        answer:
          "To apply for a whatsapp green tick reach out to us on support@superlemon.xyz",
      },
      {
        question:
          "Is a green tick guaranteed?",
        answer:
          "No, a green tick is not guaranteed on platforms like WhatsApp. The green tick, or verified badge, typically signifies that an account or business has been verified as genuine by the Meta. <br> <br>  On WhatsApp, verified badges are currently only given to select business accounts that have completed a verification process. This process involves meeting certain criteria and providing documentation to prove the authenticity of the business. WhatsApp evaluates each application individually, and not all businesses may be eligible or approved for a green tick.",
      },
      
      {
        question:
          "Is there any limit on the number of messages I can send?",
        answer:
          "Our messaging limits are automatically defined by WhatsApp based on the volume and quality of the messages you send. If you send high or medium-quality messages at higher volumes, you will be able to send an unlimited number of messages. Read more about messaging limits <a href='http://superlemon.xyz/help-center/business-api/17/' class='FAQ_link'> here.</a>",
      },
      {
        question:
          "Is there any limit on the number of messages I can send?",
        answer:
          "Your messaging limits are automatically defined by WhatsApp based on the volume and quality of the messages you send. If you send high or medium-quality messages at higher volumes, you will be able to send an unlimited number of messages. Read more about messaging limits <a href='http://superlemon.xyz/help-center/business-api/17/' class='FAQ_link'>here.</a>",
      },
      {
        question:
          "Is there any charge for creating a WhatsApp Business Account?",
        answer:
          "There are NO charges for creating a WhatsApp Business Account on SuperLemon.",
      },
      {
        question:
          "Will I be able to use the WhatsApp app after registering for WhatsApp Business API?",
        answer:
          "No, registering for the WhatsApp Business API does not grant you access to the regular WhatsApp app. The WhatsApp Business API is specifically designed for businesses to communicate with their customers at scale. It provides additional features and capabilities tailored for business use, such as automated messaging, chatbot integration, and analytics.<br> <br>If you register for the WhatsApp Business API, you will need to develop or use third-party software to interact with the API and manage your business communications. This is typically done through a separate platform or application specifically designed for the WhatsApp Business API.",
      },
    ],
  },
  {
    title: "Prerequisites",
    display: false,
    content: [
      {
        question: "Facebook Business Manager",
        answer: `It is a tool that allows you to manage multiple Facebook Pages, business assets, and ad accounts, as well as Instagram accounts and product catalogs, in one place.<br/>For creating your Facebook Business Manager, you need a Facebook Business page. (If you don't have one, you can easily create one from <a target="_blank" href="https://www.facebook.com/pages/creation/">here</a>).<br/>Go to the <a target="_blank" href="https://business.facebook.com/latest/home">Facebook Business Manager</a> page and click on “Create Account”. Then fill out the form with basic information such as your business name, your name, and your business email address`,
      },
      {
        question: "Mobile Number",
        answer:
          "A WhatsApp Business Account (WABA) needs a valid phone number that is dedicated to using the WhatsApp Business API. It should be an active phone number that is not linked to any existing WhatsApp account.<br/>If it is linked to an existing WhatsApp account you will have to delete that account before reusing the same number.",
      },
    ],
  },
  {
    title: "Features",
    display: false,
    content: [
      {
        question: "Marketing Campaigns on WhatsApp",
        answer:
          "It is one of the most popular marketing strategies that allow sending of promotional messages, product releases, newsletters & much more to users in bulk. It’s a hassle-free way of delivering business updates to customers on a day-to-day basis. <br/>E.g. product-related insights, latest arrivals, delivery updates, newsletter subscriptions, and more.<br/>With SuperLemon, you can easily create and publish marketing campaigns from your WhatsApp Business Account without any limits and also check on how many people ordered from your store after the campaign",
      },
      {
        question: "Create your own message templates",
        answer:
          "On SuperLemon, we provide the functionality to create your own personalized message templates for your customers and business.",
      },
    ],
  },
  {
    title: "Others",
    display: false,
    content: [
      {
        question: "I have a custom requirement",
        answer:
          "We create AI-powered chatbots and integrate them with 500+ platforms to engage customers in 2-way conversations across marketing, commerce, and support workflows. To know more, contact us here.",
      },
      {
        question: "My question is not listed here",
        answer:
          "For any product-related queries and support, email us at: support@superlemon.xyz",
      },
    ],
  },
];
