// @ts-nocheck
import { Filters, Icon, Popover } from "@shopify/polaris";
import { FilterMajor } from "@shopify/polaris-icons";
import { useState } from "react";
import { useUsersContext } from "../../../../context/usersContext";
import TagsComponent from "./TagsComponent";

const SearchBar = (props: any) => {
  const { Tags, showErrorToast, viewChatPhone, setViewChatPhone } =
    useUsersContext();

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = () => {
    if (Tags.length) {
      setPopoverActive((popoverActive) => !popoverActive);
    } else {
      showErrorToast("No Tags Found");
    }
  };

  const applyTagFilter = () => {
    const tags = Object.keys(selectedTags).filter((tag: any) => {
      return selectedTags[tag];
    });
    if (tags.length) {
      props.setSelectedTag(tags.join(","));
    } else {
      props.removeFilter("tags");
    }
    togglePopoverActive();
  };
  return (
    <div className="filterContainer">
      <Filters
        queryValue={props.queryValue}
        filters={[]}
        appliedFilters={[]}
        onQueryChange={(val) => {
          props.handleFiltersQueryChange(val);
          if (viewChatPhone) setViewChatPhone("");
        }}
        onQueryClear={() => {
          props.handleQueryValueRemove();
          if (viewChatPhone) setViewChatPhone("");
        }}
        onClearAll={() => {}}
        queryPlaceholder="Search by name, number"
      />

      <div className="chat__tagHamburger">
        <Popover
          active={popoverActive}
          activator={
            <div onClick={togglePopoverActive} style={{ cursor: "pointer" }}>
              <Icon source={FilterMajor} color="base" />
            </div>
          }
          onClose={togglePopoverActive}
          fluidContent
          preferredAlignment="right"
        >
          <div className="chat__globalCss">
            <div style={{ width: "350px", maxHeight: "350px" }}>
              <br />
              <TagsComponent setSelectedTags={setSelectedTags} Tags={Tags} />
              <div
                className="chat__sendTemplateButton chat__associateButton"
                onClick={applyTagFilter}
              >
                <button>
                  <strong style={{ color: "#73d13f" }}>Apply Filter</strong>
                </button>
              </div>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default SearchBar;
