import { Page, TextStyle, Button, Card } from '@shopify/polaris';
import WalletBIC from '../../assets/icons/walletBIC.svg';
import WalletIncoming from '../../assets/icons/walletIncoming.svg';
import WalletOutgoing from '../../assets/icons/walletOutgoing.svg';
import WalletTemplates from '../../assets/icons/walletTemplates.svg';
import WalletUtility from '../../assets/icons/walletUtility.svg';
import greenDot from '../../assets/icons/greenDot.svg';
import greyDot from '../../assets/icons/greyDot.svg';
import WalletUIC from '../../assets/icons/walletUIC.svg';
import styles from './wallet.module.scss';
import DateFilter from '../Analytics_MessageLogs/Analytics/DateFilter';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import AddBalance from './AddBalance';
import {
  getWalletAddress,
  getWalletInfo,
  setWalletAddress,
} from "../../redux/actions/wallet";
import PlanRecharge from "./PlanRecharge";
import DownloadWalletStatement from "./ExportToCSVWalletStatement";
import DownloadWalletInvoice from "./DownloadWalletInvoice";
import { useLocation } from "react-router-dom";
import PlanModal from "../PlanModal/PlanModal";
import Invoice from "../../common/Invoice";
import { useShopStore } from '../../hooks/shopStore';
import {
  PLAN_ENGAGE_PLUS,
} from "../../constants/constants";
import { ENGAGE_WIX_PLAN_INFO, ENGAGE_PLUS_WIX_PLAN_INFO } from "../../constants/Pricing/Plans/constants";

function Wallet(props: any) {
  const [page, setPage] = useState(0);
  const { shopDetailsData } = useShopStore();
  let shop = shopDetailsData;
  const [showInvoiceUI, setShowInvoiceUI] = useState(false);
  const [currentPlanInfo, setCurrentPlanInfo] = useState(ENGAGE_WIX_PLAN_INFO);
  const [invoiceUIParams, setInvoiceUIParams] = useState({});
  let walletRenewalStatus = JSON.parse(
    localStorage.getItem('walletRenewalStatus') || '{}'
  );
  const [showModal, setShowModal] = useState(false);
  const [usageCount, setUsageCount] = useState({
    ftc: '0',
    marketing: '0',
    utility: '0',
    service: '0',
    outgoing: '0',
    template: '0',
  });
  const [wallet, setWallet] = useState({
    currency: '$',
    availableBalance: '0',
  });

  const walletUsageCards = [
    {
      title: 'Conversations (FTC)',
      count: usageCount['ftc'],
      imgSrc: WalletIncoming,
    },
    {
      title: 'Conversations (Marketing)',
      count: usageCount['marketing'],
      imgSrc: WalletBIC,
    },
    {
      title: 'Conversations (Utility)',
      count: usageCount['utility'],
      imgSrc: WalletUtility,
    },
    {
      title: 'Conversations (Service)',
      count: usageCount['service'],
      imgSrc: WalletUIC,
    },
    {
      title: 'Outgoing',
      count: usageCount['outgoing'],
      imgSrc: WalletOutgoing,
    },
    {
      title: 'Templates',
      count: usageCount['template'],
      imgSrc: WalletTemplates,
    },
  ];

  const location: any = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const togglePopoverActive = useCallback(
    () => setShowPicker((showPicker) => !showPicker),
    []
  );

  const [savedAddress, setAddress] = useState({
    name: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    country: '',
    state: '',
    city: '',
    zip: '',
    saveBillingAddress: true,
  });

  useEffect(() => {
    if (location?.state?.page) {
      setPage(location?.state?.page);
    }

    // Update plan info based on current plan 
    if (shopDetailsData.planId === PLAN_ENGAGE_PLUS.toString()) setCurrentPlanInfo(ENGAGE_PLUS_WIX_PLAN_INFO);
    //eslint-disable-next-line
  }, []);

  const fetchWalletAddress = async () => {
    const res: any = await getWalletAddress(shop.userId);
    if (res.status == 'success') {
      setAddress(res.address);
    }
  };

  const saveWalletAddress = async (data: any) => {
    const res = setWalletAddress(shop.userId, data);
    return res;
  };

  const fetchWalletInfo = async (usageInterval: any) => {
    const infoRes: any = await getWalletInfo(shop.userId, usageInterval);
    if (infoRes?.wallet) {
      setWallet({
        availableBalance: Number(infoRes?.wallet?.balance).toFixed(3),
        currency: '$',
      });
    }
    if (infoRes?.wallet?.usageCount) {
      setUsageCount(infoRes.wallet.usageCount);
    }
  };

  useEffect(() => {
    let usageInterval = {
      start: moment().subtract(6, 'days').valueOf(),
      end: moment().valueOf(),
    };
    fetchWalletInfo(usageInterval);
    fetchWalletAddress();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async (filter: any) => {
    const finalStart = moment(filter.startDate).valueOf();
    const finalEnd = moment(filter.endDate).valueOf();

    if (shop.userId && finalStart && finalEnd) {
      fetchWalletInfo({
        start: finalStart,
        end: finalEnd,
      });
      togglePopoverActive();
    }
  };

  const walletUsageCardElement = (card: any, index: any) => {
    return (
      <Card sectioned key={index}>
        <div className={styles.wltUsageCard}>
          <div className={styles.wltUsageCardContainer}>
            <div className={styles.wltUsageNumber}>
              <TextStyle variation='strong'>{card.count}</TextStyle>
            </div>
            <div className={styles.wltUsageText}>
              <TextStyle variation='subdued'>{card.title}</TextStyle>
            </div>
          </div>
          <div className={styles.wltUsageIcon}>
            <img src={card.imgSrc} alt='wltUsageIcon' />
          </div>
        </div>
      </Card>
    );
  };

  return page === 0 ? (
    <>
      {showInvoiceUI ? (
        <Invoice
          setShowInvoiceUI={setShowInvoiceUI}
          walletInvoiceParams={invoiceUIParams}
        />
      ) : (
        <Page title='My Wallet'>
          <PlanModal
            showModal={showModal}
            setShowModal={setShowModal}
            walletRenewalStatus={walletRenewalStatus}
            page={true}
            setPage={setPage}
            currentPlanInfo={currentPlanInfo}
          />
          <div className={styles.wltByline}>
            <TextStyle variation='subdued'>
              {
                'All your usage charges from Superlemon Product will be deducted from this wallet'
              }
            </TextStyle>
          </div>
          <div className={styles.wltAvlBalCard}>
            <div className={styles.wltAvlBalComp}>
              <Card sectioned>
                <TextStyle variation='strong'>{'Available Balance'}</TextStyle>
                <div className={styles.wltAvlBal}>
                  <div className={styles.wltAvlBalNum}>
                    {wallet.currency}
                    {wallet.availableBalance}
                  </div>
                  <div className={styles.wltAddBalBtn}>
                    <Button primary onClick={() => setPage(1)}>
                      {'Add Balance'}
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
            <div className={styles.wltAvlBalComp}>
              <Card sectioned>
                <div className={styles.planRecharge__card}>
                  <div className={styles.planRecharge__left}>
                    <div className={styles.badgeLabel}>
                      {`Current Plan: ${currentPlanInfo.planName}`}
                    </div>
                    <div className={styles.currentPlanPrice}>{`${currentPlanInfo.currency}${currentPlanInfo.price}/Month`}</div>
                  </div>
                  <div className={styles.planRecharge__right}>
                    {walletRenewalStatus?.active ? (
                      <div className={styles.statusCss}>
                        <img src={greenDot} alt='Icon' />
                        <div className={styles.badgeActive}>{'Active'}</div>
                      </div>
                    ) : (
                      <div className={styles.statusCss}>
                        <img src={greyDot} alt='Icon' />
                        <div className={styles.badgeInactive}>{'Inactive'}</div>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className={styles.details}
                  >
                    {'See Details'}
                  </div>
                </div>
              </Card>
            </div>
          </div>

          <div className={styles.wltUsageSec}>
            <div className={styles.wltUsageTopBar}>
              <div className={styles.wltHeader}>{'Usage'}</div>
              <DateFilter
                showPicker={showPicker}
                togglePopoverActive={togglePopoverActive}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            </div>
            <div className={styles.wltUsageGrid}>
              {walletUsageCards.map((card, index) => {
                return walletUsageCardElement(card, index);
              })}
            </div>
          </div>

          <div className={styles.wltBillingHeader}>{'Billing History'}</div>
          <div className={styles.wltBillingSec}>
            <DownloadWalletStatement />
            <DownloadWalletInvoice
              setShowInvoiceUI={setShowInvoiceUI}
              setInvoiceUIParams={setInvoiceUIParams}
            />
          </div>
        </Page>
      )}
    </>
  ) : walletRenewalStatus?.active ? (
    <AddBalance
      userId={shop.userId}
      setPage={setPage}
      saveWalletAddress={saveWalletAddress}
      savedAddress={savedAddress}
    />
  ) : (
    <PlanRecharge
      userId={shop.userId}
      setPage={setPage}
      saveWalletAddress={saveWalletAddress}
      savedAddress={savedAddress}
    />
  );
}

export default Wallet;
