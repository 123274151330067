import { useEffect, useRef, useState } from 'react';

import {
  Button,
  Layout,
  Page,
  Toast,
  TextContainer,
  TextStyle,
  Link,
  Heading,
} from '@shopify/polaris';
import ListCampaigns from './ListCampaigns';
import BalanceUpdate from '../../common/BalanceUpdate';
import balanceMeter from '../../assets/images/balanceMeter.svg';
import GenericImageOverlay from '../../common/GenericImageOverlay';
import { PlusMinor } from '@shopify/polaris-icons';
import Plans from '../Pricing/Plans';
import {
  recommendedPlans,
  recommendedPlansNew,
} from '../../constants/Pricing/Plans/constants';
import {
  CAMPAIGNSPAGE,
  PLATFORM,
  SHOP_FEATURES,
} from '../../constants/constants';
import { getThresholdValue } from '../../redux/actions/campaigns';
import {
  getWabaDetailsbyShopId,
  loadWabaDetails,
} from '../../redux/actions/private-waba';
import { clevertapEventPush, CT_EVENT_IDS } from '../../utils/clevertapUtils';
import CreateNewCampaign from '../MarketingCampaigns/CreateNewCampaign';
import { getBalance } from '../../redux/actions/shop';
import NudgeScreenContent from '../../common/NudgeScreen';
import { shopFeaturesFromStoreData } from '../../utils/helper';
import { useShopStore } from '../../hooks/shopStore';

const MyCampaigns = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [disabled, setDisabled] = useState(false);
  const { showPricingBanner, privateWabaDetails, currentSelectedTemplateId } =
    props;
  const [pvtWabaDetails, setPvtWabaDetails]: any = useState(privateWabaDetails);
  const [toast, setToast] = useState({ show: false, message: '' });
  const [errorToast, setErrorToast] = useState({ show: false, message: '' });
  const [errToast, setErrToast] = useState({ show: false, message: '' });
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [isExceed, setIsExceed] = useState(false);
  const [balance, setBalance]: any = useState({});
  const [isMarketingCampaignEnabled, setIsMarketingCampaignEnabled] =
    useState(false);
  const [thresholdValue, setThresholdValue] = useState(75);
  const [usagePercentage, setUsagePercentage] = useState(0);
  const [createNewCampaignView, setCreateNewCampaignView] = useState(false);
  let eventTriggered = useRef({ TF: 0, TT: 0, length: 0 });

  const setEventTriggered = (value: any) => {
    eventTriggered.current = value;
    if (value.TT === 1) {
      clevertapEventPush({
        eventId: CT_EVENT_IDS.MARKETING_CAMPAIGN_PAGE_VIEWED,
        eventData: {
          Accessible: true,
          'WABA status': true,
          'Number of campaigns created': eventTriggered.current.length,
        },
      });
    } else if (value.TF === 1) {
      clevertapEventPush({
        eventId: CT_EVENT_IDS.MARKETING_CAMPAIGN_PAGE_VIEWED,
        eventData: {
          Accessible: true,
          'WABA status': false,
          'Number of campaigns created': 0,
        },
      });
    }
  };

  const [currentPlanId, setCurrentPlanId] = useState(shopDetailsData?.planId);

  let shop = shopDetailsData;

  const getThresholdData = (userId: string) => {
    getThresholdValue(userId).then((res: any) => {
      if (res?.settings?.threshold) {
        setThresholdValue(Number(res?.settings?.threshold) * 100);
      }
    });
  };

  useEffect(() => {
    if (shop && shop.id) {
      getTotalBalance(shop.userId);
      setIsMarketingCampaignEnabled(
        shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.CAMPAIGNS)
      );
      if (!shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.CAMPAIGNS)) {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.MARKETING_CAMPAIGN_PAGE_VIEWED,
          eventData: {
            Accessible: false,
            'WABA status': false,
            'Number of campaigns created': 0,
          },
        });
      }
      getThresholdData(shop.userId);
    }
  }, []);

  useEffect(() => {
    getEmbeddedWabaDetails();
  }, []);

  useEffect(() => {
    if (props.currentSelectedTemplateId) {
      setCreateNewCampaignView(true);
    }
  }, [props.currentSelectedTemplateId]);

  const getEmbeddedWabaDetails = () => {
    if (shop && shop.userId) {
      loadWabaDetails(shop.userId).then((res: any) => {
        if (res && res.appId && res.whatsappVerificationStatus) {
          setPvtWabaDetails(res);
        } else {
          getWabaDetailsbyShopId(shop.userId).then((res: any) => {
            if (res && res.appId && res.whatsappVerificationStatus) {
              setPvtWabaDetails(res);
            }
          });
        }
      });
    }
  };

  const handleClick = () => {
    if (balance && balance?.balanceUsed >= balance?.cappedAmount) {
      setErrToast({
        show: true,
        message: CAMPAIGNSPAGE.EXHAUSTED_MONTHLY_LIMIT,
      });
    } else if (
      !pvtWabaDetails ||
      (pvtWabaDetails &&
        !(
          pvtWabaDetails.whatsappVerificationStatus ===
          'WHATSAPP_VERIFICATION_DONE'
        ))
    ) {
      setErrToast({
        show: true,
        message: CAMPAIGNSPAGE.PRIVATE_WABA_NOT_ENABLED,
      });
    } else {
      clevertapEventPush({
        eventId: CT_EVENT_IDS.CREATE_NEW_CAMPAIGN_CLICKED,
        eventData: {},
      });
      updateCampaignsListViewFlag();
    }
  };
  const wabaStatus = () => {
    return (
      !pvtWabaDetails ||
      (pvtWabaDetails &&
        !(
          pvtWabaDetails.whatsappVerificationStatus ===
          'WHATSAPP_VERIFICATION_DONE'
        ))
    );
  };
  const getTotalBalance = (userId: any) => {
    getBalance(userId)
      .then((res: any) => {
        if (res.status === 'success') {
          setDisabled(false);
          setBalance(res);
          let usage: any =
            100 * (res.message.balanceUsed / res.message.cappedAmount);
          setUsagePercentage(usage);
          if (
            res.message.balanceUsed >= res.message.cappedAmount &&
            localStorage.getItem('overlay') === '0'
          ) {
            setIsExceed(true);
            localStorage.setItem('overlay', '1');
          } else {
            localStorage.setItem('overlay', '0');
          }
        } else {
          setDisabled(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const errorToastMarkup = errToast.show ? (
    <Toast
      error
      content={errToast.message}
      onDismiss={() => {
        setErrToast({ show: false, message: '' });
      }}
    />
  ) : null;
  const toastMarkup = toast.show ? (
    <Toast
      error
      content={toast.message}
      onDismiss={() => {
        setErrToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const [recommendedPlanPrice, setRecommendedPlanPrice] = useState(
    [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? recommendedPlans[7][1].price
      : recommendedPlansNew[1].price
  );
  const [recommendedPlanName, setRecommendedPlanName] = useState(
    [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? recommendedPlans[7][1].planName
      : recommendedPlansNew[1].planName
  );

  const updateCampaignsListViewFlag = () => {
    setCreateNewCampaignView(!createNewCampaignView);
  };

  return isMarketingCampaignEnabled ? (
    <>
      {createNewCampaignView ? (
        <Page>
          <>
            <CreateNewCampaign
              updateCampaignsListViewFlag={updateCampaignsListViewFlag}
              currentSelectedTemplateId={currentSelectedTemplateId}
              setCurrentSelectedTemplateId={props.setCurrentSelectedTemplateId}
            />
          </>
        </Page>
      ) : (
        <Page>
          <>
            <div className='flexSpaceBetween' style={{ marginBottom: '10px' }}>
              <BalanceUpdate shop={shop} balance={balance} />
            </div>
            <div className='flexSpaceBetween createNewCampaign'>
              <div style={{ width: '75%' }}>{CAMPAIGNSPAGE.DESCRIPTION}</div>
              <div className='button-design-new'>
                <Button
                  icon={PlusMinor}
                  disabled={
                    wabaStatus() ||
                    Number(usagePercentage) >= Number(thresholdValue) ||
                    (balance &&
                      balance?.balanceUsed >= balance?.cappedAmount) ||
                    disabled
                  }
                  onClick={() => handleClick()}
                  primary
                >
                  {CAMPAIGNSPAGE.CREATE_CAMPAIGN}
                </Button>
              </div>
            </div>
            <br />
            <Layout>
              <Layout.Section>
                <ListCampaigns
                  privateWabaDetails={pvtWabaDetails}
                  setToast={setToast}
                  setErrorToast={setErrorToast}
                  setShowErrorToast={setShowErrorToast}
                  setShowSuccessToast={setShowSuccessToast}
                  handleClick={handleClick}
                  balance={balance}
                  disabled={disabled}
                  limitReached={
                    !!(Number(usagePercentage) >= Number(thresholdValue))
                  }
                  eventTriggered={eventTriggered}
                  setEventTriggered={setEventTriggered}
                ></ListCampaigns>
              </Layout.Section>
            </Layout>
          </>{' '}
          {errorToastMarkup}
          <></>
          {errorToastMarkup}
          {toastMarkup}
          <GenericImageOverlay
            isOpen={isExceed}
            setIsOpen={setIsExceed}
            value={CAMPAIGNSPAGE.EXCEEDED_MONTHLY_LIMIT}
            src={balanceMeter}
          />
        </Page>
      )}
    </>
  ) : (
    <>
      <Page title={''}>
        <br />
        <br />
        <div className='nudgeScreen-singlebox'>
          <div>
            <TextContainer>
              <Heading>
                <TextStyle variation='strong'>
                  {CAMPAIGNSPAGE.WHY_MARKETING_CAMPAIGNS}
                </TextStyle>
              </Heading>
              <TextStyle variation='subdued'>
                <br />
                <br />
                {CAMPAIGNSPAGE.WHY_MARKETING_CAMPAIGNS_DESCP}
              </TextStyle>
            </TextContainer>

            <TextContainer>
              <TextStyle variation='subdued'>
                <br />
                <br />
                {CAMPAIGNSPAGE.EXAMPLE}
                <br />
                <br />
                {CAMPAIGNSPAGE.UPGRADE_MSG.replace(
                  '{recommendedPlanName}',
                  `${recommendedPlanName}`
                ).replace('{recommendedPlanPrice}', `${recommendedPlanPrice}`)}
                <br />
                <br />
                {CAMPAIGNSPAGE.LEARN_MORE_TXT} &nbsp;
                <Link
                  onClick={() => {
                    window.location.href = '/pricing';
                  }}
                >
                  {CAMPAIGNSPAGE.LEARN_MORE_BUTTON}
                </Link>
              </TextStyle>
            </TextContainer>
          </div>
          {[PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform) ? (
            <NudgeScreenContent />
          ) : (
            <Plans
              page={CAMPAIGNSPAGE.HEADER}
              planId={currentPlanId}
              setPlanId={props.setPlanId}
              upgradePlanButton={false}
              setShowUpgradePlanDiv={() => {}}
              shopId={shop.id}              
              setRecommendedPlanPrice={setRecommendedPlanPrice}
              setRecommendedPlanName={setRecommendedPlanName}
              userId={shop.userId}
            />
          )}
        </div>
      </Page>
    </>
  );
};
export default MyCampaigns;
