import { Modal } from "@shopify/polaris";
import PaymentFailedIcon from "../../../assets/icons/payment-failed.svg";
import { WIX_NO_SCRIPT_MODAL } from "../../../constants/constants";

const NoScriptModal = (props: any) => {
  return (
    <Modal
      open={props.showModal}
      sectioned={true}
      title={""}
      onClose={() => {
        props.setShowModal(false);
      }}
    >
      <Modal.Section>
        <div className="display-flex-center">
          <img
            alt="Success Icon"
            src={PaymentFailedIcon}
            title="Guide"
            className="tooltip-svg-icon"
          />
        </div>
        <div>{WIX_NO_SCRIPT_MODAL.DESC}</div>
        <div className="display-flex-center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://manage.wix.com/dashboard/${props.wixSiteId}/manage-website/plans`}
          >
            <button className="flatGreen_btn">
              {WIX_NO_SCRIPT_MODAL.BUTTON}
            </button>
          </a>
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default NoScriptModal;
