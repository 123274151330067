import Feature from "./Feature";
import "./feature.scss";
import { useMemo } from "react";
const FeatureList = (props: any) => {
  const featureData: any = useMemo(() => {
    return props?.plan?.reduce(
      (accumulator: any, currentValue: any, currentIndex: any, array: any) => {
        if (currentIndex % 2 === 0)
          accumulator.push(array.slice(currentIndex, currentIndex + 2));
        return accumulator;
      },
      [] as any
    );
  }, [props.plan]);
  return (
    <div className="featurePageContainer">
      <div className="mainTitle">
        Explore all of SuperLemon’s amazing features
      </div>
      {featureData &&
        featureData.map((feature: any, index: number) => (
          <div className="featureRow" key={index}>
            <Feature
              icon={feature[0]?.icon}
              heading={feature[0]?.heading}
              content={feature[0]?.content}
              path={feature[0]?.path}
              tab={feature[0]?.tab}
            />
            {feature[1] ? (
              <Feature
                icon={feature[1]?.icon}
                heading={feature[1]?.heading}
                content={feature[1]?.content}
                path={feature[1]?.path}
                tab={feature[1]?.tab}
              />
            ) : (
              <div style={{ width: "50%" }}></div>
            )}
          </div>
        ))}
    </div>
  );
};

export default FeatureList;
