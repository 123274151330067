import React, { useState } from "react";
import "../assets/css/waba.scss";
const FileUploadPage = ({ selectedFile, setSelectedFile }: any) => {
  const [isFilePicked, setIsFilePicked] = useState(false);

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  return (
    <div>
      <input
        type="file"
        className="custom-file-input"
        id="file-upload"
        name="file"
        onChange={changeHandler}
        accept=".csv,.xls,.xlsx"
      />
    </div>
  );
};
export default FileUploadPage;
