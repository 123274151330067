import { TextContainer, TextStyle } from '@shopify/polaris';

const TemplateMessageCard = ({ template }: any) => {
  return (
    <div className='Polaris-Card w-100'>
      <div className='Polaris-Card__Section break-word'>
        <TextContainer>
          <TextStyle>
            <span>
              {template.buttons.length
                ? template.internalText.replace(
                    'https://i.gs.im/{{1}}',
                    template?.buttons[0]?.value
                  )
                : template.internalText}
              <br />
              <br />
            </span>
          </TextStyle>
        </TextContainer>
      </div>
    </div>
  );
};
export default TemplateMessageCard;
