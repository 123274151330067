import {
  Card,
  Stack,
  Button,
  Collapsible,
  TextContainer,
  Link,
  DisplayText,
  Layout,
  Page,
} from "@shopify/polaris";
import { useState } from "react";
import { faqs } from "../../constants/faqConstant";
import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import FAQImage from "../../assets/icons/faq.png";
import "../../assets/css/waba.scss";
import { clevertapEventPush, CT_EVENT_IDS } from "../../utils/clevertapUtils";

function FAQ() {
  const [faqsArray, setFaqsArray]: any = useState([...faqs]);

  const handleToggle = (title: any) => {
    let array = [...faqsArray];
    let faqArray = array.map((e: any) => {
      if (e.title === title) {
        if (!e.display) {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.FAQ_VIEWED,
            eventData: {
              "FAQ Topic": title,
            },
          });
        }
        e.display = !e.display;
      }
      return e;
    });
    setFaqsArray(faqArray);
  };

  return (
    <Page title="FAQ">
      <Layout>
        <Layout.Section>
          <div className="faq">
            {faqsArray.map((element: any, index: number) => {
              return (
                <div className="faqData" key={index}>
                  <div
                    onClick={() => handleToggle(element.title)}
                    className="flexSpaceBetween"
                  >
                    <div>
                      <b>{element.title}</b>
                    </div>
                    <div>
                      {!element.display ? (
                        <Button
                          icon={ChevronDownMinor}
                          ariaExpanded={faqsArray.display}
                          ariaControls={String(index)}
                        ></Button>
                      ) : (
                        <Button
                          icon={ChevronUpMinor}
                          ariaExpanded={faqsArray.display}
                          ariaControls={String(index)}
                        ></Button>
                      )}
                    </div>
                  </div>
                  <Collapsible
                    open={element.display}
                    id={String(index)}
                    transition={{
                      duration: "500ms",
                      timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                  >
                    <div className="geyDottedLine"></div>
                    <TextContainer>
                      {element.content.map((e: any) => {
                        return (
                          <div>
                            <p className="faqQuestion">
                              <b
                                dangerouslySetInnerHTML={{ __html: e.question }}
                              ></b>
                            </p>
                            <p
                              className="faqAnswer"
                              dangerouslySetInnerHTML={{ __html: e.answer }}
                            ></p>
                          </div>
                        );
                      })}
                    </TextContainer>
                  </Collapsible>
                </div>
              );
            })}
          </div>
        </Layout.Section>
        <Layout.Section secondary>
          <div className="faqImageCenter">
            <img className="faqImage" src={FAQImage} alt={"faq"} />
          </div>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
export default FAQ;
