import { useEffect, useState } from "react";
import { Layout, SettingToggle, TextStyle } from "@shopify/polaris";
import WheelConfig from "./WheelConfiguration";
import ChangeWidgetTemplateModal from "./ChangeWidgetTemplateModal";
import WidgetPreview from "./WidgetPreview";
import MessageLanguage from "./MessageLanguage";

const WidgetDesign = (props: any) => {
  // const { setToast, setErrorToast, setShowSuccessToast, setShowErrorToast } = props;
  const [openChangeShareTemplateModal, setOpenChangeShareTemplateModal] =
    useState(false);

  const [wheelData, setWheelData] = useState(props.wheelConfig);
  const [initialWheelData, setInitialWheelData] = useState(props.wheelConfig);
  const [refWheelData, setRefWheelData] = useState(props.wheelConfig);
  const [selectedWheel, setSelectedWheel] = useState({
    id: "",
    colorOdd: "",
    colorEven: "",
  });
  useEffect(() => {
    if (props.isDataSubmitted) {
      setRefWheelData(props.wheelConfig);
      setInitialWheelData(props.wheelConfig);
    }
  }, [props.isDataSubmitted]);

  useEffect(() => {
    if (props.reset) {
      setRefWheelData(initialWheelData);
      setWheelData(initialWheelData);
    }
  }, [props.reset]);

  const getWidgetPreview = (custom: any, id: any, config: any) => {
    return (
      <WidgetPreview
        wheelTemplates={props.wheelTemplates}
        onChanges={props.onChanges}
        wheelConfig={props.wheelConfig}
        selectedWheelID={props.selectedWheelID}
        custom={custom}
        id={id}
        config={config}
      />
    );
  };

  return (
    <Layout.AnnotatedSection
      title="Widget Design"
      // description='Configure Spin the Wheel widget and personalise it for your store visitors. Choose from several beautiful widget designs on what suits your store best'
    >
      <TextStyle variation="subdued">
        <p>
          SuperLemon provides you with a variety of options to configure the
          spin the wheel widget.
        </p>
        <p>
          Select Design: You can select the design, layout and color of the
          widget button from the various options provided.
        </p>
        <p>
          You can configure between 4 to 12 options in the spin the wheel
          widget.
        </p>
        <p>
          Set various discounts for the winning slots and keep the losing slots
          empty.
        </p>
      </TextStyle>
      <br />
      <SettingToggle
        action={{
          content: "Select Design",
          onAction: () => {
            setOpenChangeShareTemplateModal(true);
          },
        }}
        enabled={true}
      >
        <div className="previewCustomShareBtnBackground">
          {getWidgetPreview(false, 1, props.wheelConfig)}
        </div>
      </SettingToggle>

      <ChangeWidgetTemplateModal
        onModalClose={() => {
          setOpenChangeShareTemplateModal(false);
        }}
        open={openChangeShareTemplateModal}
        wheelTemplates={props.wheelTemplates}
        onChanges={props.onChanges}
        wheelConfiguration={props.wheelConfig}
        setWheelConfig={props.setWheelConfig}
        selectedWheel={selectedWheel}
        setSelectedWheel={setSelectedWheel}
        selectedWheelID={props.selectedWheelID}
        setSelectedWheelID={props.setSelectedWheelID}
      />
      <WheelConfig
        reset={props.reset}
        onChanges={props.onChanges}
        wheelConfig={props.wheelConfig}
        selectedWheel={selectedWheel}
        wheelTemplates={props.wheelTemplates}
        selectedWheelID={props.selectedWheelID}
      />
      <MessageLanguage
        reset={props.reset}
        onChanges={props.onChanges}
        selectedLanguage={props.selectedLanguage}
        setLanguage={props.setLanguage}
        internalText={props.internalText}
      />
    </Layout.AnnotatedSection>
  );
};

export default WidgetDesign;
