import { Fragment, useEffect, useState } from "react";
import { Link } from "@shopify/polaris";
import ContactUsModal from "./ContactUsModal/ContactusModal";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";

const FAQComponent = (props: any) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (props.index == props.openIndex) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [props]);

  const [showModal, setShowModal] = useState(false);
  return (
    <div className="accordion-big-item">
      <div
        className="accordion-title"
        onClick={() => {
          if (isActive) {
            setIsActive(false);
            props.setOpenIndex(-1);
          } else {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.FAQ_VIEWED,
              eventData: {
                "FAQ Topic": props.faqObj.topic,
              },
            });
            props.setOpenIndex(props.index);
          }
        }}
      >
        <strong>{props.faqObj.topic}</strong>
        <div>{isActive ? "^" : "⌄"}</div>
      </div>
      {isActive ? (
        <Fragment>
          <div className="accordion-faq-content">
            <hr className="hrCss" />
            {Object.keys(props.faqObj.questions).map((key, bigIndex) => {
              return (
                <div className="accordion-item" key={bigIndex}>
                  <div className="accordion-title">
                    <div>
                      <p className="FAQ__question">{props.faqObj.questions[key].ques}</p>
                    </div>
                  </div>
                  <div
                    id={`ques_${props.index}_ans_${key}`}
                    className="accordion-content"
                    style={{ display: "block" }}
                  >
                    {props.faqObj.questions[key].ans.map(
                      (text: string, index: number) => {
                        return (
                          <div key={index}>
                            {index !== 0 ? <br /> : null}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${text}`,
                              }}
                            ></div>
                            {props.faqObj.topic === "Others" &&
                              Number(key) === 0 && (
                                <>
                                  <p>
                                    To know more, &nbsp;
                                    <Link
                                      onClick={() => {
                                        setShowModal(true);
                                      }}
                                    >
                                      contact us here
                                    </Link>
                                  </p>
                                  {showModal && (
                                    <ContactUsModal
                                      showModal={showModal}
                                      setShowModal={setShowModal}
                                      secondarySheet={false}
                                    />
                                  )}
                                </>
                              )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </Fragment>
      ) : null}
      <br />
    </div>
  );
};

export default FAQComponent;
