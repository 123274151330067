import { useEffect, useState } from 'react';
import {
  getAbandonedCartCount,
  handleFiltersAbandonedCart,
} from '../../../../../redux/actions/manual-messaging';
import moment from 'moment';
import { ReactComponent as LeftArrow } from '../../../../../assets/icons/leftArrowGreen.svg';
import { ReactComponent as RightArrow } from '../../../../../assets/icons/rightArrowGreen.svg';
import OrderCard from './OrderCard';
import { useShopStore } from '../../../../../hooks/shopStore';

const AbandonedCarts = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const shop = shopDetailsData;
  const userId = shop?.userId;
  const templateType = null;
  const name = '';
  const orderId = '';
  const orderBy = 'checkoutId';
  const descending = true;
  const pageSize = 1;
  const [orderNumber, setOrderNumber] = useState(0);
  const [totalOrderCount, setTotalOrderCount] = useState(0);
  const defaultOrder = {
    orderId: '-NA-',
    name: '-NA-',
    orderDate: '-NA-',
    products: [''],
    orderAmount: '-NA-',
    currency: 'INR',
  };
  const [currentOrder, setCurrentOrder] = useState(defaultOrder);

  useEffect(() => {
    setOrderNumber(0);
    getAbandonedCartCount(userId, props.phone).then((res: any) => {
      setTotalOrderCount(res.count);
    });
    getOrders(orderNumber, props.phone);
  }, [props.phone]);

  useEffect(() => {
    getOrders(orderNumber, props.phone);
  }, [orderNumber]);

  const getProductDetails = (details: any) => {
    const pdList = JSON.parse(details);
    let products = defaultOrder.products;
    if (pdList) {
      products = pdList.map((pd: any) => pd.p);
    }
    return products;
  };
  const getOrders = (page_num: any, phone: any) => {
    handleFiltersAbandonedCart(
      userId,
      templateType,
      orderId,
      name,
      orderBy,
      descending,
      pageSize,
      page_num,
      phone
    ).then((res: any) => {
      if (res.checkouts[0]?.name) {
        const { name, customerName, createdOn, details, total, currency } =
          res.checkouts[0];

        const orderDate = moment(createdOn).format('MMMM DD, YYYY, hh:mm A');
        const products = getProductDetails(details);

        setCurrentOrder({
          orderId: name,
          name: customerName,
          orderDate: orderDate,
          products: products,
          orderAmount: total,
          currency: currency ? currency : defaultOrder.currency,
        });
      } else {
        setCurrentOrder(defaultOrder);
      }
    });
  };

  const nextOrder = () => {
    if (orderNumber + 1 < totalOrderCount) {
      setOrderNumber(orderNumber + 1);
    }
  };

  const prevOrder = () => {
    if (orderNumber !== 0) {
      setOrderNumber(orderNumber - 1);
    }
  };

  return (
    <div>
      <OrderCard orderDetails={currentOrder} orderType={'AbandonedOrders'} />
      <span style={{ display: 'flex', justifyContent: 'center' }}>
        <LeftArrow
          onClick={prevOrder}
          className='cp'
          style={{ width: '10px', height: '17px', marginRight: '2px' }}
        />
        {totalOrderCount === 0 ? (
          <span>0</span>
        ) : (
          <span>{` ${orderNumber + 1}/${totalOrderCount} `}</span>
        )}
        <RightArrow
          onClick={nextOrder}
          className='cp'
          style={{ width: '10px', height: '17px', marginLeft: '2px' }}
        />
      </span>
    </div>
  );
};

export default AbandonedCarts;
