import { useEffect, useState } from "react";
import {
  Layout,
  TextContainer,
  TextStyle,
  Heading,
  Icon,
  Link,
} from "@shopify/polaris";
import { SettingsMinor } from "@shopify/polaris-icons";
import Switch from "react-switch";
import {
  CRM_TEMPLATE_DICT,
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_DUTCH,
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_TURK,
  TEMPLATE_LANGUAGE_RUSSIAN,
  OPTIN_ENTERPRISE_TEMPLATES,
} from "../../../../constants/constants";
import OrderConformationModal from "./OrderConformationModal";
import { updateOrderSettings } from "../../../../redux/actions/crmOrders";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../../utils/clevertapUtils";

function OrderConfirmation({
  userId,
  orderConfirmationLanguage,
  setOrderConfirmationLanguage,
  orderConfirmationData,
  isOrderConfirmationEnabled,
  setIsOrderConfirmationEnabled,
  showToast,
  showErrorToast,
  phoneData,
  checkForApproval,
  privateWabaDetails,
  optinEnterpriseTemplates,
}: any) {
  var TEMPLATE_DICT: any = CRM_TEMPLATE_DICT;
  const [
    openOrderConfirmationSettingsModal,
    setOpenOrderConfirmationSettingsModal,
  ] = useState(false);

  const getOrderConfirmationPreview = () => {
    switch (orderConfirmationLanguage) {
      case TEMPLATE_LANGUAGE_ENG:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/eng-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_PORT:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/por-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_SPAN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/spa-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_ITAL:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ita-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_FREN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/fr-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_INDO:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/indo-order_confirmation_preview.png?v=1587749561";
      case TEMPLATE_LANGUAGE_GERM:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ger-order_confirmation_preview-updated.png?v=1587819720";
      case TEMPLATE_LANGUAGE_ARAB:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/arabic-order_confirmation_preview.png?v=1587749561";
      case TEMPLATE_LANGUAGE_TURK:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/turkish-order_confirmation_preview.png?v=1587819648";
      case TEMPLATE_LANGUAGE_HEBR:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/hebrew-order_confirmation_preview.png?v=1591679012";
      case TEMPLATE_LANGUAGE_DUTCH:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/dutch-order_confirmation_preview.png?v=1591677402";
      case TEMPLATE_LANGUAGE_RUSSIAN:
        return "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/russian_order_confirmation.png?v=1654772304";
    }
  };

  const enableOrderConfirmation = (enabled: boolean) => {
    if (enabled && !checkForApproval()) return;
    if (
      (!phoneData.phone ||
      !phoneData.countryCode ||
      phoneData.phone.length === 0 ||
      phoneData.countryCode.length === 0) &&
      enabled
    ) {
      showErrorToast(
        "Please add WhatsApp support number by going to Settings"
      );
      return;
    }
    var params: any = new URLSearchParams();
    params.append("confirmationEnabled", enabled);
    updateOrderSettings(userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          setIsOrderConfirmationEnabled(response.settings.confirmationEnabled);
          showToast(
            "Order Confirmation message template has been " +
              (enabled ? "ENABLED" : "DISABLED")
          );
          if (enabled) {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_ENABLED,
              eventData: {
                template_name: "Order Confirmation",
                template_language: orderConfirmationLanguage,
                "Private waba":
                  localStorage.getItem("private_waba") === "true"
                    ? true
                    : false,
              },
            });
          } else {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_DISABLED,
              eventData: {
                template_name: "Order Confirmation",
                template_language: orderConfirmationLanguage,
                "Private waba":
                  localStorage.getItem("private_waba") === "true"
                    ? true
                    : false,
              },
            });
          }
        } else {
          showErrorToast(response);
        }
      },
      (err: any) => {
        showErrorToast("Something went wrong");
      }
    );
  };

  const orderConfirmationMarkup = () => {
    return (
      <>
        <Layout.Section>
          <div className="template-info-container">
            <div className="Polaris-TextContainer Polaris-TextContainer--spacingTight template-info">
              <div className="templateSwitchMobCss">
                <Heading>Order Confirmation</Heading>
                <Switch
                  onChange={() =>
                    enableOrderConfirmation(!isOrderConfirmationEnabled)
                  }
                  checked={isOrderConfirmationEnabled}
                  onColor="#5DC001"
                  onHandleColor="#fff"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 6px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  handleDiameter={30}
                />
              </div>
              <>
                {isOrderConfirmationEnabled ? (
                  <p>
                    Send an order confirmation message as soon as a customer
                    places an order on your store. You can also give customers a
                    coupon code for their next order.
                  </p>
                ) : (
                  <p>
                    Order confirmation messages provide brand assurance to your
                    customers and imbues a sense of trust.
                  </p>
                )}
                <br />
                {/* {"To learn about the cost of sending messages, view"}
                <Link
                  external={true}
                  url="https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0"
                >
                  {" pricing chart"}
                </Link> */}
              </>
            </div>
            <div className="Polaris-Card w-100">
              <div className="Polaris-Card__Section break-word">
                <TextContainer>
                  <TextStyle>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          optinEnterpriseTemplates &&
                          orderConfirmationLanguage === "ENGLISH"
                            ? OPTIN_ENTERPRISE_TEMPLATES.order_confirmation_template
                            : TEMPLATE_DICT[orderConfirmationLanguage][
                                "order_confirmation_template"
                              ],
                      }}
                    ></span>

                    <br />
                    <br />
                    <Link
                      external={true}
                      url={getOrderConfirmationPreview()}
                      onClick={() => {
                        clevertapEventPush({
                          eventId:
                            CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_PREVIEWED,
                          eventData: {
                            template_name: "Order Confirmation",
                            template_language: orderConfirmationLanguage,
                            "Private waba":
                              localStorage.getItem("private_waba") === "true"
                                ? true
                                : false,
                          },
                        });
                      }}
                    >
                      Preview
                    </Link>
                  </TextStyle>
                </TextContainer>
              </div>
            </div>
          </div>
        </Layout.Section>
        <div className="template Polaris-Layout__Section Polaris-Layout__Section--secondary m-auto text-center">
          <div className="flex-col w-100">
            <div>
              <button
                className={
                  isOrderConfirmationEnabled
                    ? "Polaris-Button Polaris-Button--primary Polaris-Button--outline w-50"
                    : "Polaris-Button Polaris-Button--outline Polaris-Button--disabled w-50"
                }
                type="button"
                disabled={!isOrderConfirmationEnabled}
                onClick={() => setOpenOrderConfirmationSettingsModal(true)}
              >
                <span className="Polaris-Button__Content">
                  <span className="Polaris-Button__Text">
                    <div className="d-flex-center">
                      <div>
                        <Icon source={SettingsMinor} />
                      </div>
                      <div>&nbsp;Settings</div>
                    </div>
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {userId ? (
        <>
          {orderConfirmationMarkup()}
          <OrderConformationModal
            open={openOrderConfirmationSettingsModal}
            closeModal={() => setOpenOrderConfirmationSettingsModal(false)}
            showToast={showToast}
            showErrorToast={showErrorToast}
            setOrderConfirmationLanguage={setOrderConfirmationLanguage}
            orderConfirmationData={orderConfirmationData}
          />
        </>
      ) : null}
    </>
  );
}

export default OrderConfirmation;
