import { Page, Modal, Link } from "@shopify/polaris";
import "./errorModal.scss";
import errorIcon from "../../../assets/icons/error-svgrepo-com.svg";
import {
  errorModalCampaignContent,
  errorModalCampaignLearnMore,
  errorModalCampaignLimitExhaust,
} from "../../../constants/constants";

const ErrorModal = (props: any) => {
  return (
    <Page title="">
      <Modal
        open={props.showModal}
        sectioned={true}
        title={""}
        onClose={() => {
          props.setShowModal(false);
        }}
      >
        <Modal.Section>
          <div className="basic-error-modal">
            <div className="errorModalHeading">
              <img src={errorIcon} alt="ErrorIcon"></img>
              Oops! Action Not Allowed
            </div>
            {props?.errorDetails?.limit <= 0 ? (
              <div className="errorModalContent">
                <p>{errorModalCampaignLimitExhaust}</p>
                <br />
                <p>
                  <Link url={errorModalCampaignLearnMore} external={true}>
                    Learn more
                  </Link>{" "}
                  about how to increase your messaging limit.
                </p>
                <br />
              </div>
            ) : (
              <div className="errorModalContent">
                <p>
                  {errorModalCampaignContent
                    .replace(`{SIZE}`, props?.errorDetails?.size)
                    .replace(`{TIER}`, props?.errorDetails?.tier)
                    .replace(`{LIMIT}`, props?.errorDetails?.limit)}{" "}
                </p>
                <br />
                <p>
                  <Link url={errorModalCampaignLearnMore} external={true}>
                    Learn more
                  </Link>{" "}
                  about how to increase your messaging limit.
                </p>
                <br />
                <p>
                  Please go back & choose a different set of customers to
                  continue with campaign.
                </p>
                <br />
              </div>
            )}

            <div className="errorModalFooter">
              <button
                className="errorModalButtonCss"
                type="button"
                onClick={() => {
                  props.setShowModal(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Section>
      </Modal>
    </Page>
  );
};

export default ErrorModal;
