import { Fragment, SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  Layout,
  Page,
  TextContainer,
  TextStyle,
  Toast,
  Heading,
  Modal,
  Icon,
  RadioButton,
} from '@shopify/polaris';
import { EditMinor, DeleteMinor } from '@shopify/polaris-icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  deleteTemplate,
  editTemplate,
  getTemplates,
} from '../../../redux/actions/templates';
import Template from '../../../types/Template.type';
import Switch from 'react-switch';
import { PlusMinor } from '@shopify/polaris-icons';
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from '../../../utils/clevertapUtils';
import { objectDifference } from '../../../utils/helper';
import {
  getAllTemplates,
  settings,
  updateSettings,
  deleteTemplate as deleteAbTemplate,
} from '../../../redux/actions/manual-messaging';
import CreateTemplateModal from '../Settings/MessagingTemplates/CreateTemplateModal';
import styles from '../manual-message.module.scss';
import EditTemplateModal from '../Settings/MessagingTemplates/EditTemplateModal';
import { PLATFORM, PRO_TEMPLATES } from '../../../constants/constants';

const ProTemplates = (props: any) => {
  const shop = props.shop;
  const [templates, setTemplates] = useState<Template[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);
  const [toast, setToast] = useState({ show: false, message: '' });
  const [errorToast, setErrorToast] = useState({ show: false, message: '' });
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [currentTemplateDeleteId, setCurrentTemplateDeleteId] = useState<
    any | null
  >(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [openTutorial, setopenTutorial] = useState(false);
  const [selectedTemplateCategory, setSelectedTemplateCategory] = useState(
    props.mode ? props.mode : PRO_TEMPLATES.MANUAL_TEMP_NAMES.ALL
  );

  let { path } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (shop && shop.userId) {
      fetchTemplates();
      fetchAllTemplates(shop.userId);
    }
  }, [shop]);

  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const fetchTemplates = () => {
    getTemplates(shop.userId).then(
      (response: any) => {
        if (response.status === 'success') {
          setTemplates(response.template);
          if (
            selectedTemplateCategory === PRO_TEMPLATES.MANUAL_TEMP_NAMES.ALL
          ) {
            setFilteredTemplates(templates);
          } else {
            setFilteredTemplates(() => {
              return response.template.filter((template: any) => {
                return template.category === selectedTemplateCategory;
              });
            });
          }
        } else {
          showErrorToast(response);
        }
      },
      (err) => {
        showErrorToast('Something went wrong');
      }
    );
  };

  const handleDeleteTemplate = () => {
    if (currentTemplateDeleteId) {
      let deletedTemplate = templates.filter(
        (e: any) => e.id === currentTemplateDeleteId
      );
      let category = deletedTemplate[0].category || '';
      setSaveLoading(true);
      deleteTemplate(shop.userId, currentTemplateDeleteId).then(
        (response: any) => {
          if (response.status === 'success') {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.EXTENSION_TEMPLATE_DELETED,
              eventData: {
                Type:
                  category === PRO_TEMPLATES.MANUAL_TEMP_NAMES.ORDER_CRM
                    ? PRO_TEMPLATES.EXTENSION_TEMP_NAMES.ORDER
                    : PRO_TEMPLATES.EXTENSION_TEMP_NAMES.QUICK_REPLY,
              },
            });
            setOpenConfirmation(false);
            setCurrentTemplateDeleteId(null);
            showToast('Template deleted successfully');
            fetchTemplates();
          } else {
            showErrorToast(response);
          }
          setSaveLoading(false);
        },
        (err) => {
          showErrorToast('Unable to delete template');
          setSaveLoading(false);
        }
      );
    }
  };

  const handleDeleteClick = (templateId: number) => {
    setCurrentTemplateDeleteId(templateId);
    setOpenConfirmation(true);
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const enableTemplate = (template: any) => {
    let params = new URLSearchParams();
    params.append('active', (template.active ? 'false' : 'true').toString());

    editTemplate(shop.userId, template.id, params).then(
      (response: any) => {
        if (response.status === 'success') {
          showToast(
            `Template has been ${
              response.template.active ? 'enabled' : 'disabled'
            }`
          );
          fetchTemplates();
        } else {
          showErrorToast(response);
        }
      },
      (err) => {
        showErrorToast('Something went wrong');
      }
    );
  };

  const openEditMode = (template: any) => {
    history.push({
      pathname: `${path}/create`,
      state: {
        template: template,
        shop: shop,
        sourceTab: selectedTemplateCategory,
      },
    });
  };

  const renderTemplatesMarkup = (templates: Template[]) => {
    var markup = [];
    for (let i = 0; i < templates?.length; i++) {
      if (templates[i]) {
        markup.push(
          <>
            <Fragment key={i}>
              <Layout.Section>
                <div className='template-info-container'>
                  <div className='Polaris-TextContainer Polaris-TextContainer--spacingTight overflow-wrap width-30'>
                    <div className='templateSwitchMobCss'>
                      <Heading>{templates[i].name}</Heading>
                      <TextStyle variation='subdued'>
                        {templates[i].category ===
                        PRO_TEMPLATES.MANUAL_TEMP_NAMES.ORDER_CRM
                          ? PRO_TEMPLATES.EXTENSION_TEMP_NAMES.ORDER
                          : PRO_TEMPLATES.EXTENSION_TEMP_NAMES.QUICK_REPLY}
                      </TextStyle>
                      <br></br>
                      <Switch
                        onChange={() => enableTemplate(templates[i])}
                        checked={templates[i].active}
                        onColor='#5DC001'
                        onHandleColor='#fff'
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                        activeBoxShadow='0px 0px 1px 6px rgba(0, 0, 0, 0.2)'
                        height={20}
                        width={48}
                        handleDiameter={30}
                      />
                    </div>
                  </div>
                  <div className='Polaris-Card w-60'>
                    <div className='Polaris-Card__Section break-word w-100'>
                      <TextContainer>
                        <TextStyle variation='subdued'>
                          {templates[i]?.text?.split('\\n').map((item, key) => {
                            return (
                              <span key={key}>
                                {item}
                                <br />
                              </span>
                            );
                          })}
                        </TextStyle>
                      </TextContainer>
                    </div>
                  </div>
                  <div className='template Polaris-Layout__Section Polaris-Layout__Section--secondary m-auto text-center w-10'>
                    <div className='flex-col w-100 extensionButtonCss'>
                      <div>
                        <button
                          className={
                            templates[i].active
                              ? 'Polaris-Button Polaris-Button--outline Polaris-Button--primary w-50'
                              : 'Polaris-Button Polaris-Button--outline Polaris-Button--disabled w-50'
                          }
                          type='button'
                          disabled={!templates[i].active}
                          onClick={() => openEditMode(templates[i])}
                        >
                          <span className='Polaris-Button__Content'>
                            <span className='Polaris-Button__Text'>
                              <div className='d-flex-center'>
                                <div>
                                  <Icon source={EditMinor} />
                                </div>
                                <div>&nbsp;Edit</div>
                              </div>
                            </span>
                          </span>
                        </button>
                      </div>
                      <div className='delete-button-container'>
                        <button
                          className='Polaris-Button Polaris-Button--outline outline Polaris-Button--destructive mt-1-half w-50'
                          type='button'
                          onClick={() => {
                            handleDeleteClick(templates[i].id);
                          }}
                        >
                          <span className='Polaris-Button__Content'>
                            <span className='Polaris-Button__Text'>
                              <div className='d-flex-center'>
                                <div>
                                  <Icon source={DeleteMinor} />
                                </div>
                                <div>&nbsp;Delete</div>
                              </div>
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Layout.Section>
            </Fragment>
          </>
        );
      }
    }
    return markup;
  };

  const templatecategory = [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
    ? PRO_TEMPLATES.MANUAL_TEMPLATES_CATEGORIES_WIX
    : PRO_TEMPLATES.MANUAL_TEMPLATES_CATEGORIES;

  const handleFilter = (category: SetStateAction<string>) => {
    setSelectedTemplateCategory(category);
    if (category === 'ALL') {
      setFilteredTemplates(templates);
    } else {
      setFilteredTemplates(() => {
        return templates?.filter((template) => {
          return template.category === category;
        });
      });
    }
  };

  const [activateViewTemplate, setactivateViewTemplate] = useState(false);
  const [activateCreateTemplate, setactivateCreateTemplate] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);
  const [dataChanges, setDataChanges] = useState<any>({
    whatsappWeb: null,
  });

  const [initialData, setInitialData] = useState({
    whatsappWeb: null,
  });
  const [settingsData, setSettingsData] = useState({
    whatsappWeb: null,
  });
  const [refSettingsData, setRefSettingsData] = useState({
    whatsappWeb: null,
  });

  useEffect(() => {
    if (shop && shop.userId) {
      getSettings(shop.userId);
    }
  }, []);

  useEffect(() => {
    var diffObj: any = objectDifference(refSettingsData, settingsData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        whatsappWeb: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        whatsappWeb: null,
      }));
    }
  }, [settingsData]);

  useEffect(() => {
    if (dataChanges.whatsappWeb !== null) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [dataChanges]);

  const getSettings = (userId: string) => {
    if (userId) {
      settings(userId).then((res: any) => {
        if (res.status === 'success') {
          setInitialData(res.settings);
          setRefSettingsData(res.settings);
          setSettingsData(res.settings);
        }
      });
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState<any>();
  const [abTemplates, setAbTemplates] = useState<Template[]>([]);
  const [confirm, setDeleteConfirm] = useState(false);
  const [activateEditModal, setactivateEditModal] = useState(false);
  const [template, setTemplate] = useState({});

  useEffect(() => {
    fetchAllTemplates(shop.userId);
  }, [shop.userId]);

  const fetchAllTemplates = (userId: string) => {
    if (userId) {
      getAllTemplates(userId).then((res: any) => {
        if (res.status === 'success' && res.template) {
          setAbTemplates(res.template);
        }
      });
    }
  };

  const handleDeleteAbTemplate = (userId: string) => {
    if (selected?.id) {
      deleteAbTemplate(userId, selected?.id).then(
        (response: any) => {
          if (response.status === 'success') {
            setDeleteConfirm(false);
            showToast('Template deleted successfully');
            fetchAllTemplates(userId);
          } else {
            showErrorToast(response);
          }
        },
        (err) => {
          showErrorToast('Unable to delete template');
        }
      );
    }
  };

  const openEditAbMode = (template: any) => {
    setTemplate(template);
    setactivateEditModal(true);
    setOpenModal(false);
  };

  const renderAbandonedTemplatesMarkup = (templates: any) => {
    var markup = [];
    for (let i = 0; i < templates?.length; i++) {
      if (templates[i]) {
        markup.push(
          <Fragment key={i}>
            <Layout.Section>
              <div
                className={`${styles.templatesContainer} template-info-container`}
              >
                <div className='Polaris-TextContainer Polaris-TextContainer--spacingTight overflow-wrap width-30'>
                  <Heading>{templates[i].name}</Heading>
                  <TextStyle variation='subdued'>
                    {templates[i].category}
                  </TextStyle>
                  <br></br>
                </div>
                <div className={`${styles.textContentCard} Polaris-Card w-60`}>
                  <div className='Polaris-Card__Section break-word w-100'>
                    <TextContainer>
                      <TextStyle variation='subdued'>
                        {templates[i].message}
                      </TextStyle>
                    </TextContainer>
                  </div>
                </div>
                <div
                  className={`${styles.buttonsOuterContainer} template Polaris-Layout__Section Polaris-Layout__Section--secondary m-auto text-center w-10`}
                >
                  <div
                    className={`${styles.templatesButtonContainer} flex-col w-100`}
                  >
                    <div>
                      <button
                        className={
                          'Polaris-Button Polaris-Button--outline Polaris-Button--primary w-50'
                        }
                        type='button'
                        onClick={() => openEditAbMode(templates[i])}
                      >
                        <span className='Polaris-Button__Content'>
                          <span className='Polaris-Button__Text'>
                            <div className='d-flex-center'>
                              <div>
                                <Icon source={EditMinor} />
                              </div>
                              <div>&nbsp;Edit</div>
                            </div>
                          </span>
                        </span>
                      </button>
                    </div>
                    <div className='delete-button-container'>
                      <button
                        className='Polaris-Button Polaris-Button--outline outline Polaris-Button--destructive mt-1-half w-50'
                        type='button'
                        onClick={() => {
                          setSelected(templates[i]);
                          setDeleteConfirm(true);
                        }}
                      >
                        <span className='Polaris-Button__Content'>
                          <span className='Polaris-Button__Text'>
                            <div className='d-flex-center'>
                              <div>
                                <Icon source={DeleteMinor} />
                              </div>
                              <div>&nbsp;Delete</div>
                            </div>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Layout.Section>
          </Fragment>
        );
      }
    }
    return markup;
  };

  const renderAll = () => {
    return renderAbandonedTemplatesMarkup(abTemplates).concat(
      renderTemplatesMarkup(templates)
    );
  };

  const renderOrder = () => {
    let filteredABTemplates = abTemplates.filter((template) => {
      return template.category === PRO_TEMPLATES.MANUAL_TEMP_NAMES.ORDER;
    });
    return [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? renderAbandonedTemplatesMarkup(filteredABTemplates)
      : renderAbandonedTemplatesMarkup(filteredABTemplates).concat(
          renderTemplatesMarkup(filteredTemplates)
        );
  };

  return (
    <Page>
      <Modal
        open={confirm}
        small
        onClose={() => setDeleteConfirm(false)}
        title={`Are you sure you want to delete "${
          selected?.name ? selected.name : ''
        }"?`}
        primaryAction={{
          content: 'Confirm',
          onAction: () => handleDeleteAbTemplate(shop.userId),
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>{PRO_TEMPLATES.CONFIRMATION_TEXT}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      {activateEditModal ? (
        <EditTemplateModal
          open={activateEditModal}
          setactivateEditModal={setactivateEditModal}
          setactivateViewTemplate={setactivateViewTemplate}
          closeModal={() => {
            setactivateEditModal(false);
            setactivateViewTemplate(false);
          }}
          showToast={showToast}
          showErrorToast={showErrorToast}
          userId={shop.userId}
          template={template}
          fetchAllTemplates={() => fetchAllTemplates(shop.userId)}
          platform={shop.platform}
        />
      ) : null}
      <CreateTemplateModal
        open={activateCreateTemplate}
        setactivateCreateTemplate={setactivateCreateTemplate}
        closeModal={() => {
          setactivateCreateTemplate(false);
          fetchAllTemplates(shop.userId);
          fetchTemplates();
        }}
        showToast={showToast}
        showErrorToast={showErrorToast}
        userId={shop.userId}
        platform={shop.platform}
      />
      <div className='flexSpaceBetween'>
        <div className='template-filter'>
          <strong className='mr-1rem'>{'Show Templates: '} </strong>
          <div className='radioButtonBorderFilter'>
            {templatecategory.map((templateCategory: any, index: any) => {
              return (
                <RadioButton
                  label={templateCategory.label}
                  checked={
                    templateCategory.value === selectedTemplateCategory
                      ? true
                      : false
                  }
                  name='template-select'
                  onChange={() => {
                    handleFilter(templateCategory.value);
                  }}
                  key={index}
                />
              );
            })}
          </div>
        </div>

        <div className='button-design-new'>
          <Button
            primary
            icon={PlusMinor}
            onClick={() => {
              setactivateCreateTemplate(true);
            }}
          >
            {PRO_TEMPLATES.BUTTON}
          </Button>
        </div>
      </div>
      <br /> <br />
      {toastMarkup}
      {errorToastMarkup}
      <Layout>
        {templates?.length > 0 || abTemplates?.length > 0
          ? selectedTemplateCategory === PRO_TEMPLATES.MANUAL_TEMP_NAMES.ALL
            ? renderAll()
            : selectedTemplateCategory ===
              PRO_TEMPLATES.MANUAL_TEMP_NAMES.ORDER_CRM
            ? renderOrder()
            : selectedTemplateCategory ===
              PRO_TEMPLATES.MANUAL_TEMP_NAMES.ABANDONED_CART
            ? renderAbandonedTemplatesMarkup(
                abTemplates.filter((template) => {
                  return (
                    template.category === PRO_TEMPLATES.MANUAL_TEMP_NAMES.CART
                  );
                })
              )
            : renderTemplatesMarkup(filteredTemplates)
          : null}
      </Layout>
      <div className='Polaris-TextContainer Polaris-TextContainer--spacingLoose fs-3 text-center my-3'>
        <span className='Polaris-TextStyle--variationStrong text-primary'>
          {PRO_TEMPLATES.TIP}
        </span>
      </div>
      <Modal
        small
        open={openConfirmation}
        onClose={() => {
          setOpenConfirmation(false);
          setCurrentTemplateDeleteId(null);
        }}
        title={`Are you sure you want to delete?`}
        primaryAction={{
          content: 'Confirm',
          loading: saveLoading,
          onAction: () => handleDeleteTemplate(),
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>{PRO_TEMPLATES.CONFIRMATION_TEXT}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      <Modal
        open={openTutorial}
        sectioned={true}
        title={'Tutorial video'}
        onClose={() => {
          setopenTutorial(false);
          document.body.classList.remove('tutorial-modal');
          clevertapEventPush({
            eventId: CT_EVENT_IDS.TUTORIAL_VIDEO_CLOSED,
            eventData: {},
          });
        }}
      >
        <iframe
          id='ytplayer'
          width='100%'
          height='360'
          allow='fullscreen'
          src={'https://www.youtube.com/embed/8ug_qyXxqAs'}
          title={'Extension Tutorial Video'}
        ></iframe>
      </Modal>
    </Page>
  );
};

export default ProTemplates;
