const GenericImageOverlay = ({ isOpen, setIsOpen, value, src }: any) => {
  return (
    <div>
      {isOpen && (
        <div id="overlay" onClick={() => setIsOpen(false)}>
          <div className="overLayText">
            <div>
              <img
                width={100}
                height={100}
                style={{ display: "block", margin: "auto" }}
                src={src}
                alt="test"
              />
            </div>
            <div style={{ width: "350px" }}>
              <p style={{ fontSize: "16px" }}>{value}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default GenericImageOverlay;
