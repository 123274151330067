import {
  RadioButton,
  Stack,
  Heading,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { useState, useEffect } from "react";
import {
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_TURK,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_DUTCH,
} from "../constants/constants";

const WidgetLanguageMarkup = (props: any) => {
  const [language, setLanguage] = useState(props.language);

  useEffect(() => {
    setLanguage(props.language);
  }, [props.language]);

  const handleWidgetLanguageChange = (newValue: any) => {
    return () => {
      setLanguage(newValue);
      props.onLanguageChange(newValue);
    };
  };
  return (
    <Stack vertical>
      <TextContainer>
        <TextStyle variation="strong">
          {"Storefront Opt-in Widget Language"}
        </TextStyle>
      </TextContainer>

      <Stack>
        <RadioButton
          label="English"
          checked={language === TEMPLATE_LANGUAGE_ENG || language === "NONE"}
          name="widget-language"
          disabled={!props.enabled}
          onChange={handleWidgetLanguageChange(TEMPLATE_LANGUAGE_ENG)}
        />
        <RadioButton
          label="Portuguese"
          checked={language === TEMPLATE_LANGUAGE_PORT}
          name="widget-language"
          disabled={!props.enabled}
          onChange={handleWidgetLanguageChange(TEMPLATE_LANGUAGE_PORT)}
        />
        <RadioButton
          label="Spanish"
          checked={language === TEMPLATE_LANGUAGE_SPAN}
          name="widget-language"
          disabled={!props.enabled}
          onChange={handleWidgetLanguageChange(TEMPLATE_LANGUAGE_SPAN)}
        />
        <RadioButton
          label="Italian"
          checked={language === TEMPLATE_LANGUAGE_ITAL}
          name="widget-language"
          disabled={!props.enabled}
          onChange={handleWidgetLanguageChange(TEMPLATE_LANGUAGE_ITAL)}
        />
        <RadioButton
          label="French"
          checked={language === TEMPLATE_LANGUAGE_FREN}
          name="widget-language"
          disabled={!props.enabled}
          onChange={handleWidgetLanguageChange(TEMPLATE_LANGUAGE_FREN)}
        />
        <RadioButton
          label="Indonesian"
          checked={language === TEMPLATE_LANGUAGE_INDO}
          name="widget-language"
          disabled={!props.enabled}
          onChange={handleWidgetLanguageChange(TEMPLATE_LANGUAGE_INDO)}
        />
        <RadioButton
          label="German"
          checked={language === TEMPLATE_LANGUAGE_GERM}
          name="widget-language"
          disabled={!props.enabled}
          onChange={handleWidgetLanguageChange(TEMPLATE_LANGUAGE_GERM)}
        />
        <RadioButton
          label="Arabic"
          checked={language === TEMPLATE_LANGUAGE_ARAB}
          name="widget-language"
          disabled={!props.enabled}
          onChange={handleWidgetLanguageChange(TEMPLATE_LANGUAGE_ARAB)}
        />
        <RadioButton
          label="Turkish"
          checked={language === TEMPLATE_LANGUAGE_TURK}
          name="widget-language"
          disabled={!props.enabled}
          onChange={handleWidgetLanguageChange(TEMPLATE_LANGUAGE_TURK)}
        />
        <RadioButton
          label="Hebrew"
          checked={language === TEMPLATE_LANGUAGE_HEBR}
          name="widget-language"
          disabled={!props.enabled}
          onChange={handleWidgetLanguageChange(TEMPLATE_LANGUAGE_HEBR)}
        />
        <RadioButton
          label="Dutch"
          checked={language === TEMPLATE_LANGUAGE_DUTCH}
          name="widget-language"
          disabled={!props.enabled}
          onChange={handleWidgetLanguageChange(TEMPLATE_LANGUAGE_DUTCH)}
        />
      </Stack>
    </Stack>
  );
};
export default WidgetLanguageMarkup;
