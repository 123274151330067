//@ts-nocheck
import { Filters, Modal, RadioButton, Toast } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import TemplateSelectedMockup from "./TemplateSelectedMockup";
import { AGENT_ASSIST_TEMPLATES } from "../../constants/constants";
import { ReactComponent as TemplateImage } from "../../assets/images/templateImage.svg";
import { Link, useHistory } from "react-router-dom";

const HSMTemplateModal = (props: any) => {
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState(
    props.fetchedTemplates
  );
  const [allTemplates, setAllTemplates] = useState(props.fetchedTemplates);
  const [templatecategory, setTemplatecategory] = useState(
    AGENT_ASSIST_TEMPLATES.TEMPLATE_CATEGORY
  );
  const history = useHistory();
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });

  const setDefault = () => {
    AGENT_ASSIST_TEMPLATES.TEMPLATE_CATEGORY.forEach((category: any) => {
      return category.label == "Utility"
        ? (category.checked = true)
        : (category.checked = false);
    });
    setTemplatecategory(AGENT_ASSIST_TEMPLATES.TEMPLATE_CATEGORY);
    handleTemplateTypeChange("UTILITY");
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  useEffect(() => {
    let data =
      selectedTemplate == "" ? (
        filteredTemplates.length != 0 ? (
          filteredTemplates.map((data: any) => {
            return data.status != "APPROVED" ? null : (
              <div key={data.id} className="template">
                <p style={{ fontWeight: "600", marginBottom: "8px" }}>
                  {data.name}
                </p>
                <p style={{ fontSize: "1.6rem", lineHeight: "23px" }}>
                  {data.body}
                </p>
                <div
                  className="chat__selectTemplateButton"
                  onClick={() => {
                    if (props.from === "Campaign") {
                      props.selectTemplate(data.id);
                      props.setTemplateId(data.id);
                      closeModal();
                    } else {
                      setSelectedTemplate(data);
                    }
                  }}
                >
                  <button>
                    <strong style={{ color: "#5EC127", fontSize: "1.4rem" }}>
                      Select Template
                    </strong>
                  </button>
                </div>
                <div className="chat__templateHrLineCss"></div>
              </div>
            );
          })
        ) : (
          <div className="chat_templateNotFound">No Templates Found</div>
        )
      ) : (
        <TemplateSelectedMockup
          agentPhone={props.agentPhone}
          userId={props.userId}
          template={selectedTemplate}
          closeModal={closeModal}
          showSuccess={props.showSuccess}
          setShowSuccess={props.setShowSuccess}
          currentSelectedOrder={props.currentSelectedOrder}
          showErrorToast={showErrorToast}
          loadMessageLogs={props.loadMessageLogs}
          customerPhone={props.customerPhone}
          from={props.from}
        />
      );
    setData(data);
  }, [selectedTemplate, filteredTemplates]);

  const handleTemplateTypeChange = (value: any) => {
    setSearchInput("");
    templatecategory.map((t: any) => {
      return value === t.value ? (t.checked = true) : (t.checked = false);
    });

    let fetchedTemplates = props.fetchedTemplates;

    if (value != "") {
      fetchedTemplates = fetchedTemplates.filter(
        (element: { templateCategory: null }) =>
          element.templateCategory == value
      );
    }

    setFilteredTemplates(fetchedTemplates);
    setAllTemplates(fetchedTemplates);
  };

  useEffect(() => {
    setDefault();
    handleTemplateTypeChange("UTILITY");
  }, []);

  const handleFiltersQueryChange = (input: any) => {
    setSearchInput(input);
    let fetchedTemplates =
      input == ""
        ? filteredTemplates
        : allTemplates.filter((template: any) => {
            if (
              template.body
                .toLowerCase()
                .replace(/[\n\r]/g, " ")
                .replace(/ /g, "")
                .includes(
                  input
                    .toLowerCase()
                    .replace(/[\n\r]/g, " ")
                    .replace(/ /g, "")
                )
            ) {
              return template;
            }
            if (
              template.name
                .toLowerCase()
                .replace(/[\n\r]/g, " ")
                .replace(/ /g, "")
                .includes(
                  input
                    .toLowerCase()
                    .replace(/[\n\r]/g, " ")
                    .replace(/ /g, "")
                )
            ) {
              return template;
            }
          });
    setFilteredTemplates(fetchedTemplates);
  };

  const handleQueryValueRemove = () => {
    setSearchInput("");
    setFilteredTemplates(props.fetchedTemplates);
  };

  const closeModal = () => {
    props.setHsmTemplateModalOpen(false);
    setDefault();
    setSelectedTemplate("");
  };

  const NoTemplateMarkup = () => {
    return (
      <div className={`chat`}>
        <div className="chat__noTemplate__container">
          <p className="chat__noTemplate__TitleTextCss">Select Template</p>
          <div className="chat__noTemplate__Image">
            <TemplateImage />
          </div>
          <p className="chat__noTemplate__TextCss">
            Oops! You don’t have template ready.
          </p>
          <button
            className="chat__noTemplate__ButtonCss"
            type="button"
            onClick={() => {
              history.push("/whatsapp-templates");
            }}
          >
            Create new template
          </button>
        </div>
      </div>
    );
  };

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  return (
    <Modal
      open={props.isHsmTemplateModalOpen}
      onClose={() => {
        closeModal();
      }}
      title={""}
      small={props.fetchedTemplates.length ? false : true}
    >
      <Modal.Section>
        {errorToastMarkup}
        {props.fetchedTemplates.length ? (
          <div className="chat__globalCss">
            <div className="chat_selectedTemplate">
              {selectedTemplate == ""
                ? "Select Templates"
                : "Selected Template"}
            </div>

            {selectedTemplate == "" && (
              <div>
                <div className="chat_searchBar">
                  <Filters
                    queryValue={searchInput}
                    filters={[]}
                    appliedFilters={[]}
                    onQueryChange={(val) => {
                      handleFiltersQueryChange(val);
                    }}
                    onQueryClear={handleQueryValueRemove}
                    onClearAll={() => {}}
                    queryPlaceholder="Search the template..."
                  />
                </div>
                <div className="chat_radioButton">
                  <div className="chat__radioButtonBorderFilter">
                    {AGENT_ASSIST_TEMPLATES.TEMPLATE_CATEGORY.map(
                      (templateCategory: any, index) => {
                        return (
                          <RadioButton
                            label={
                              templateCategory.label != "Select Category"
                                ? templateCategory.label
                                : "All"
                            }
                            checked={templateCategory?.checked}
                            name="template-select"
                            onChange={() => {
                              handleTemplateTypeChange(templateCategory.value);
                            }}
                            key={index}
                          />
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            )}
            <div
              className={`chat_templatesSection ${
                selectedTemplate == ""
                  ? " chat__selectTemplateHeight"
                  : " chat__heightauto"
              }`}
            >
              {data}
            </div>
            {selectedTemplate == "" && (
              <div className="chat__linktoTemplate">
                Not able to find the template?
                <span className="chat__link">
                  <Link to={"/whatsapp-templates"}> Create New Template.</Link>
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className="chat__globalCss">
            <NoTemplateMarkup />
          </div>
        )}
      </Modal.Section>
    </Modal>
  );
};

export default HSMTemplateModal;
