import { Modal } from "@shopify/polaris";
import WabaNotProcured from "../components/AgentAssist/Chat/WabaNotProcured";

const WabaNotProcuredModal = (props: any) => {
  return (
    <Modal
      open={props.open}
      sectioned={true}
      title=""
      onClose={() => {
        props.close();
      }}
    >
      <div className="chat__globalCss">
        <WabaNotProcured from="config" mobileView={props.mobileView} />
      </div>
    </Modal>
  );
};

export default WabaNotProcuredModal;
