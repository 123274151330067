import { create } from "zustand";

type State = {
  shopDetailsData: any;
  shopSetting: any;
  shopWabaDetails: any;
  shopPrivateWabaDetails: any;
  shopWABAVerificationDetails: any;
  shopOwnerDetails: any;
  agentPhone: string;
  planPaymentInfo: any;
  walletRenewalInfo: any;
  showSharedTeamInbox: boolean;
}

const initialState: State = {
  shopDetailsData: null,
  shopSetting: null,
  shopWabaDetails: null,
  shopPrivateWabaDetails: null,
  shopWABAVerificationDetails: null,
  shopOwnerDetails: null,
  agentPhone: '',
  planPaymentInfo: null,
  walletRenewalInfo: null,
  showSharedTeamInbox: false,
}

type Actions = {
  setStoreShopDetails: (storeShop: any) => void;
  setShopSettings: (shopSetting: any) => void;
  setShopWABADetails: (shopWabaDetails: any) => void;
  setShopPrivateWABADetails: (shopPrivateWabaDetails: any) => void;
  setShopWABAVerificationDetails: (shopWABAVerificationDetails: any) => void;
  setShopOwnerDetails: (shopWabaDetails: any) => void;
  setAgentPhone: (agentPhone: any) => void;
  setPlanPaymentInfo: (planPaymentInfo: any) => void;
  setWalletRenewalInfo: (walletRenewalInfo: any) => void;
  setShowSharedTeamInbox: (showSharedTeamInbox: any) => void;
  resetShopStore: () => void;
}

export const useShopStore = create<State & Actions>()((set) => ({
  ...initialState,
  setStoreShopDetails: (shopDetailsData) => set({ shopDetailsData }),
  setShopSettings: (shopSetting) => set({ shopSetting }),
  setShopWABADetails: (shopWabaDetails) => set({ shopWabaDetails }),
  setShopPrivateWABADetails: (shopPrivateWabaDetails) => set({ shopPrivateWabaDetails }),
  setShopWABAVerificationDetails: (shopWABAVerificationDetails) => set({ shopWABAVerificationDetails }),
  setShopOwnerDetails: (shopOwnerDetails) => set({ shopOwnerDetails }),
  setAgentPhone: (agentPhone) => set({ agentPhone }),
  setPlanPaymentInfo: (planPaymentInfo) => set({ planPaymentInfo }),
  setWalletRenewalInfo: (walletRenewalInfo) => set({ walletRenewalInfo }),
  setShowSharedTeamInbox: (showSharedTeamInbox) => set({ showSharedTeamInbox }),
  resetShopStore: () => {
    set(initialState)
  },
}));
