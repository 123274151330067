import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Icon, Button, Modal, TextField, Select } from "@shopify/polaris";
import { SettingsMinor } from "@shopify/polaris-icons";
import "../../../assets/css/waba.scss";
import "./MyWhatsAppPage.scss";
import ChatWidget from "../../../assets/icons/chat-widget.svg";
import {
  MY_WHATSAPP_PAGE,
  VERTICALS_OPTIONS,
  WABA_VERIFICATION_STATUS,
  WABA_QUALITY_RATING,
  options,
} from "../../../constants/constants";

function MyWhatsAppPage(props: any) {
  const {
    wabaVerificationDetails,
    wabaProfile,
    language,
    about,
    wabaImage,
    address,
    fileUpload,
    clickInputField,
    changeLanguage,
    changeAbout,
    handleChange,
    updateWabaData,
    resetData,
    wabaCurrentState,
  } = props;
  const history = useHistory();
  const [businessMessageLimitPerDay, setBusinessMessageLimitPerDay] = useState(
    wabaProfile?.limit || 50
  );
  const [editProfileDetailsModalOpen, setEditProfileDetailsModalOpen] =
    useState(false);
  const [editOtherDetailsModalOpen, setEditOtherDetailsModalOpen] =
    useState(false);
  const [businessVerificationStatus, setBusinessVerificationStatus] = useState({
    label: WABA_VERIFICATION_STATUS[1].label,
    value: WABA_VERIFICATION_STATUS[1].value,
    style: WABA_VERIFICATION_STATUS[1].style,
  });
  const [businessQualityRating, setBusinessQualityRating] = useState({
    label: WABA_QUALITY_RATING[1].label,
    value: WABA_QUALITY_RATING[1].value,
    style: WABA_QUALITY_RATING[1].style,
  });

  // useEffect(() => {
  //   console.log("wabaCurrentState ==> ", wabaCurrentState)
  // }, [props.wabaCurrentState])

  useEffect(() => {
    if (
      props.wabaVerificationDetails?.business_verification_status &&
      props.wabaVerificationDetails?.business_verification_status !== ""
    ) {
      const busVerStatus = formatVerificationStatus(
        wabaVerificationDetails?.business_verification_status
      );
      if (busVerStatus) {
        setBusinessVerificationStatus({
          label: busVerStatus.label,
          value: busVerStatus.value,
          style: busVerStatus.style,
        });
        setBusinessMessageLimitPerDay(busVerStatus.messageLimitPerDay); // Set limit if status is verified and no limit present
      }
      if (wabaVerificationDetails.limit)
        setBusinessMessageLimitPerDay(wabaVerificationDetails.limit); // If limit is passed from API set it here.
    }
    if (
      props.wabaVerificationDetails?.quality_rating &&
      props.wabaVerificationDetails?.quality_rating !== ""
    ) {
      const busQuaRating = formatQualityRating(
        wabaVerificationDetails?.quality_rating
      );
      if (busQuaRating)
        setBusinessQualityRating({
          label: busQuaRating.label,
          value: busQuaRating.value,
          style: busQuaRating.style,
        });
    }
  }, [props.wabaVerificationDetails]);

  const closeProfileUpdateModal = () => {
    setEditProfileDetailsModalOpen(false);
    resetData();
  };

  const closeOtherDetailsUpdateModal = () => {
    setEditOtherDetailsModalOpen(false);
    resetData();
  };

  const updateProfileDataCall = () => {
    updateWabaData();
    setEditProfileDetailsModalOpen(false);
    resetData();
  };

  const updateOtherDataCall = () => {
    updateWabaData();
    setEditOtherDetailsModalOpen(false);
    resetData();
  };

  const handleProfileUpdateChange = (value: any, key: any) => {
    if (key === "language") changeLanguage(value);
    if (key === "about") changeAbout(value);
  };

  const formatVerificationStatus = (value: any) => {
    value = value.toLowerCase();
    const WVSCurrent = WABA_VERIFICATION_STATUS.filter((WVS) => {
      return WVS.value === value;
    });
    return WVSCurrent[0];
  };

  const formatQualityRating = (value: any) => {
    value = value.toLowerCase();
    const WQRCurrent = WABA_QUALITY_RATING.filter((WQR) => {
      return WQR.value === value;
    });
    return WQRCurrent[0];
  };

  const WBAProfileDetailsEditView = (
    <>
      <div className="myWhatsAppPageContainer">
        <div className="businessInfoContainer">
          <div className="marginTB">
            <label className="boldFontBlue">
              {MY_WHATSAPP_PAGE.EDIT_DETAILS.PROFILE_INFO_LABEL}
            </label>
          </div>

          <div className="businessDetails">
            <label className="boldFont">
              {MY_WHATSAPP_PAGE.EDIT_DETAILS.LANGUAGE.LABEL}
            </label>
            <Select
              label={MY_WHATSAPP_PAGE.EDIT_DETAILS.LANGUAGE.DECSP}
              options={options["WABA_LANGUAGE"]}
              value={language}
              onChange={(e) => handleProfileUpdateChange(e, "language")}
            />
          </div>

          <div className="businessDetails">
            <label className="boldFont">
              {MY_WHATSAPP_PAGE.EDIT_DETAILS.DESCRIPTION.LABEL}
            </label>
            <TextField
              label={MY_WHATSAPP_PAGE.EDIT_DETAILS.DESCRIPTION.DECSP}
              onChange={(e) => handleProfileUpdateChange(e, "about")}
              autoComplete="off"
              multiline={4}
              value={about}
            />
          </div>
        </div>
      </div>
    </>
  );

  const WBAOtherDetailsEditView = (
    <>
      <div className="myWhatsAppPageContainer">
        <div>
          <span>{MY_WHATSAPP_PAGE.EDIT_DETAILS.DESCP}</span>
        </div>

        <div className="businessInfoContainer">
          <div className="marginTB">
            <label className="boldFontBlue">
              {MY_WHATSAPP_PAGE.EDIT_DETAILS.BUSINESS_INFO_LABEL}
            </label>
          </div>

          <div className="businessDetails">
            <label className="boldFont">
              {MY_WHATSAPP_PAGE.EDIT_DETAILS.ADDRESS.LABEL}
            </label>
            <TextField
              label={
                MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.ADDRESS_DETAILS
                  .LINE1
              }
              onChange={(e) => handleChange(e, "addressLineOne")}
              autoComplete="off"
              value={address?.addressLineOne}
            />
            <TextField
              label={
                MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.ADDRESS_DETAILS
                  .LINE2
              }
              onChange={(e) => handleChange(e, "addressLineTwo")}
              autoComplete="off"
              value={address?.addressLineTwo}
            />
            <TextField
              label={
                MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.ADDRESS_DETAILS
                  .CITY
              }
              onChange={(e) => handleChange(e, "city")}
              autoComplete="off"
              value={address?.city}
            />
            <TextField
              label={
                MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.ADDRESS_DETAILS
                  .STATE
              }
              onChange={(e) => handleChange(e, "state")}
              autoComplete="off"
              value={address?.state}
            />
            <TextField
              label={
                MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.ADDRESS_DETAILS
                  .COUNTRY
              }
              onChange={(e) => handleChange(e, "country")}
              autoComplete="off"
              value={address?.country}
            />
            <TextField
              type="number"
              label={
                MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.ADDRESS_DETAILS
                  .ZIPCODE
              }
              onChange={(e) => handleChange(e, "zipcode")}
              autoComplete="off"
              value={address?.zipcode}
            />
          </div>

          <div className="businessDetails">
            <label className="boldFont">
              {MY_WHATSAPP_PAGE.EDIT_DETAILS.VERTICAL.LABEL}
            </label>
            <Select
              label={MY_WHATSAPP_PAGE.EDIT_DETAILS.VERTICAL.DECSP}
              placeholder=""
              options={VERTICALS_OPTIONS}
              value={address?.vertical}
              onChange={(e) => handleChange(e, "vertical")}
            />
          </div>

          <div className="businessDetails">
            <label className="boldFont">
              {MY_WHATSAPP_PAGE.EDIT_DETAILS.EMAIL.LABEL}
            </label>
            <TextField
              label={MY_WHATSAPP_PAGE.EDIT_DETAILS.EMAIL.DECSP}
              onChange={(e) => handleChange(e, "email")}
              autoComplete="off"
              value={address?.email}
            />
          </div>

          <div className="businessDetails">
            <label className="boldFont">
              {MY_WHATSAPP_PAGE.EDIT_DETAILS.WEBSITE.LABEL}
            </label>
            <TextField
              label={MY_WHATSAPP_PAGE.EDIT_DETAILS.WEBSITE.DECSP}
              onChange={(e) => handleChange(e, "website")}
              autoComplete="off"
              value={address?.website}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="myWhatsAppPageContainer">
        {/* waba Overview Container */}
        <div className="wabaOverviewContainer">
          <div className="wabaStatusSection">
            <div className="heading">
              <img src={ChatWidget} alt="ChatWidget" className="iconImage" />
              <span>{MY_WHATSAPP_PAGE.FB_VERIFICATION_SATUS.TITLE}</span>
            </div>

            <div
              className={`wabaStatusTag ${businessVerificationStatus.style}`}
            >
              <span className="wabaStatusTagValue">
                {businessVerificationStatus.label}
              </span>
            </div>

            <div
              className="subheading"
              dangerouslySetInnerHTML={{
                __html: `${MY_WHATSAPP_PAGE.FB_VERIFICATION_SATUS.CONTENT}`,
              }}
            ></div>
          </div>

          <div className="wabaStatusSection">
            <div className="heading">
              <img src={ChatWidget} alt="ChatWidget" className="iconImage" />
              <span>{MY_WHATSAPP_PAGE.MESSAGING_LIMIT.TITLE}</span>
            </div>
            <div className="wabaStatusTag blueColor">
              <span className="wabaStatusTagValue">
                {businessMessageLimitPerDay}/day
              </span>
            </div>
            <div
              className="subheading"
              dangerouslySetInnerHTML={{
                __html: `${MY_WHATSAPP_PAGE.MESSAGING_LIMIT.CONTENT}`,
              }}
            ></div>
          </div>

          <div className="wabaStatusSection">
            <div className="heading">
              <img src={ChatWidget} alt="ChatWidget" className="iconImage" />
              <span>{MY_WHATSAPP_PAGE.QUALITY_RATING.TITLE}</span>
            </div>

            <div className={`wabaStatusTag ${businessQualityRating.style}`}>
              <span className="wabaStatusTagValue">
                {businessQualityRating.label}
              </span>
            </div>

            <div
              className="subheading"
              dangerouslySetInnerHTML={{
                __html: `${MY_WHATSAPP_PAGE.QUALITY_RATING.CONTENT}`,
              }}
            ></div>
          </div>
        </div>

        {/* WhatsApp Business Account Profile Details: */}
        <div className="wabaDetailsContainer">
          <div className="wabaBussAccountDetailsContainer backgroundImage">
            <div className="headingSection">
              <div className="heading headingGreen">
                {MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.PROFILE_TITLE}
              </div>
              <div
                className="editSettingLink"
                onClick={() => setEditProfileDetailsModalOpen(true)}
              >
                <Icon source={SettingsMinor} />
                <span>
                  {MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.EDIT_LABEL}
                </span>
              </div>
            </div>
            <div className="Polaris-Page--divider"></div>

            <div className="wabaBussAccountInfoContainer">
              <div className="wabaBussAccountPicture">
                <div className="flexCenter">
                  <img
                    alt="whatsappDp"
                    className="businessImage"
                    src={wabaImage}
                  />
                </div>
                <div className="flexCenter displayPicture">
                  {
                    MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION
                      .DISPLAY_PICTURE
                  }
                </div>

                <div className="flexCenter customButton">
                  <input
                    onChange={fileUpload}
                    accept="image/png, image/jpeg, image/jpg"
                    id="uploadWhatsappImage"
                    type="file"
                    hidden
                  />
                  <Button onClick={clickInputField}>Upload Photo</Button>
                </div>
              </div>

              <div className="wabaBussAccountInfo">
                <div className="accountInfoEachSection">
                  <div>
                    <label className="fontColorGrey">
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.DETAILS
                          .PHONE_NUMBER_LABEL
                      }
                    </label>
                  </div>
                  <div>
                    <label>
                      {wabaProfile?.wabaNumber ? wabaProfile.wabaNumber : ""}
                    </label>
                  </div>
                </div>

                <div className="accountInfoEachSection">
                  <div>
                    <label className="fontColorGrey">
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.DETAILS
                          .WABA_ID_LABEL
                      }
                    </label>
                  </div>
                  <div>
                    <label>
                      {wabaProfile?.wabaId ? wabaProfile.wabaId : "--"}
                    </label>
                  </div>
                </div>

                <div className="accountInfoEachSection">
                  <div>
                    <label className="fontColorGrey">
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.DETAILS
                          .LANG_LABEL
                      }
                    </label>
                  </div>
                  <div>
                    <label>
                      {wabaProfile?.language ? wabaProfile.language : "--"}
                    </label>
                  </div>
                </div>

                <div className="accountInfoEachSection">
                  <div>
                    <label className="fontColorGrey">
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.DETAILS
                          .ABOUT_LABEL
                      }
                    </label>
                  </div>
                  <div>
                    <label>
                      {wabaProfile?.about ? wabaProfile.about : "--"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* WhatsApp Business Account Other Details: */}
        <div className="wabaDetailsContainer">
          <div className="wabaBussAccountDetailsContainer backgroundImage">
            <div className="headingSection">
              <div className="heading headingGreen">
                {MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.OTHERS_TITLE}
              </div>
              <div
                className="editSettingLink"
                onClick={() => setEditOtherDetailsModalOpen(true)}
              >
                <Icon source={SettingsMinor} />
                <span>
                  {MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.EDIT_LABEL}
                </span>
              </div>
            </div>
            <div className="Polaris-Page--divider"></div>

            <div className="wabaBussAccountOtherDetails">
              <div className="wabaBussAccountInfo">
                {/* Address Details: */}
                <div className="accountInfoEachSectionTitle">
                  <div>
                    <label>
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION
                          .ADDRESS_LABEL
                      }
                    </label>
                  </div>
                </div>

                <div className="accountInfoEachSection">
                  <div>
                    <label className="fontColorGrey">
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION
                          .ADDRESS_DETAILS.LINE1
                      }
                    </label>
                  </div>
                  <div>
                    <label>
                      {address?.addressLineOne ? address.addressLineOne : ""}
                    </label>
                  </div>
                </div>

                <div className="accountInfoEachSection">
                  <div>
                    <label className="fontColorGrey">
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION
                          .ADDRESS_DETAILS.LINE2
                      }
                    </label>
                  </div>
                  <div>
                    <label>
                      {address?.addressLineTwo ? address.addressLineTwo : ""}
                    </label>
                  </div>
                </div>

                <div className="accountInfoEachSection">
                  <div>
                    <label className="fontColorGrey">
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION
                          .ADDRESS_DETAILS.CITY
                      }
                    </label>
                  </div>
                  <div>
                    <label>{address?.city ? address.city : ""}</label>
                  </div>
                </div>

                <div className="accountInfoEachSection">
                  <div>
                    <label className="fontColorGrey">
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION
                          .ADDRESS_DETAILS.STATE
                      }
                    </label>
                  </div>
                  <div>
                    <label>{address?.state ? address.state : ""}</label>
                  </div>
                </div>

                <div className="accountInfoEachSection">
                  <div>
                    <label className="fontColorGrey">
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION
                          .ADDRESS_DETAILS.COUNTRY
                      }
                    </label>
                  </div>
                  <div>
                    <label>{address?.country ? address.country : ""}</label>
                  </div>
                </div>

                <div className="accountInfoEachSection">
                  <div>
                    <label className="fontColorGrey">
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION
                          .ADDRESS_DETAILS.ZIPCODE
                      }
                    </label>
                  </div>
                  <div>
                    <label>{address?.zipcode ? address.zipcode : ""}</label>
                  </div>
                </div>

                {/* Other Details: */}
                <div className="accountInfoEachSectionTitle">
                  <div>
                    <label>
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION
                          .OTHER_DETAILS_LABEL
                      }
                    </label>
                  </div>
                </div>

                <div className="accountInfoEachSection">
                  <div>
                    <label className="fontColorGrey">
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION
                          .OTHER_DETAILS.BUSSINESS_VERTICAL
                      }
                    </label>
                  </div>
                  <div>
                    <label>{address?.vertical ? address.vertical : ""}</label>
                  </div>
                </div>

                <div className="accountInfoEachSection">
                  <div>
                    <label className="fontColorGrey">
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION
                          .OTHER_DETAILS.WEBSITE
                      }
                    </label>
                  </div>
                  <div>
                    <label>{address?.website ? address.website : ""}</label>
                  </div>
                </div>

                <div className="accountInfoEachSection">
                  <div>
                    <label className="fontColorGrey">
                      {
                        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION
                          .OTHER_DETAILS.EMAIL_ID
                      }
                    </label>
                  </div>
                  <div>
                    <label>{address?.email ? address.email : ""}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Banner Start Sending Message */}
        <div className="wabaDetailsContainer">
          <div className="wabaBussAccountDetailsContainer borderLeft">
            <div className="headingSection">
              <div className="heading hmin">
                {MY_WHATSAPP_PAGE.ACCOUNT_ACTIVE_SECTION.TITLE}
              </div>
              <div className="customButton">
                <Button
                  primary
                  onClick={() => {
                    history.push("/campaigns");
                  }}
                >
                  {MY_WHATSAPP_PAGE.ACCOUNT_ACTIVE_SECTION.BUTTON}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        small
        open={editProfileDetailsModalOpen}
        sectioned={true}
        title={MY_WHATSAPP_PAGE.EDIT_DETAILS.PROFILE_TITLE}
        onClose={() => closeProfileUpdateModal()}
        primaryAction={{
          content: MY_WHATSAPP_PAGE.EDIT_DETAILS.BUTTON.UPDATE,
          onAction: updateProfileDataCall,
          disabled:
            wabaCurrentState.about === true ||
            wabaCurrentState.language === true
              ? false
              : true,
        }}
        secondaryActions={[
          {
            content: MY_WHATSAPP_PAGE.EDIT_DETAILS.BUTTON.CANCEL,
            onAction: closeProfileUpdateModal,
          },
        ]}
      >
        {WBAProfileDetailsEditView}
      </Modal>

      <Modal
        small
        open={editOtherDetailsModalOpen}
        sectioned={true}
        title={MY_WHATSAPP_PAGE.EDIT_DETAILS.OTHER_TITLE}
        onClose={() => {
          closeOtherDetailsUpdateModal();
        }}
        primaryAction={{
          content: MY_WHATSAPP_PAGE.EDIT_DETAILS.BUTTON.UPDATE,
          onAction: updateOtherDataCall,
          disabled: !wabaCurrentState.businessDetails,
        }}
        secondaryActions={[
          {
            content: MY_WHATSAPP_PAGE.EDIT_DETAILS.BUTTON.CANCEL,
            onAction: closeOtherDetailsUpdateModal,
          },
        ]}
      >
        {WBAOtherDetailsEditView}
      </Modal>
    </>
  );
}
export default MyWhatsAppPage;
