// @ts-nocheck
import { createRef, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { getCampaignReport } from '../redux/actions/campaigns';
import { clevertapEventPush, CT_EVENT_IDS } from '../utils/clevertapUtils';
import { useShopStore } from '../hooks/shopStore';

const DownloadCampaingReport = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [data, setData] = useState([]);
  const [readyToDownload, setReadyToDownload] = useState(false);
  const csvLinkEl = createRef<any>();

  const headers = [
    { label: 'Customer Number', key: 'customerNumber' },
    { label: 'Status', key: 'status' },
    //{ label: 'Sent', key: 'sent' },
    //{ label: 'Delivered', key: 'delivered' },
    //{ label: 'Read', key: 'read' },
    { label: 'Reason for Failure', key: 'reason' },
    { label: 'Message ID', key: 'messageId' },
    { label: 'Link Clicked', key: 'linkClicked' },
  ];

  const getFormatttedData = (data: any) => {
    let formattedData: any = [];
    data.forEach((o: any) => {
      let individualData: any = {
        customerNumber: o.phone ? o.phone : '',
        status: o.status,
        // sent: !!o.sent ? 'Yes' : 'No',
        // delivered: !!o.delivered ? 'Yes' : 'No',
        // read: !!o.read ? 'Yes' : 'No',
        reason: !!o.reason ? o.reason : 'NA',
        messageId: o.messageId,
        linkClicked: o.linkClicked,
      };
      formattedData.push(individualData);
    });
    return formattedData;
  };

  useEffect(() => {
    if (readyToDownload) {
      csvLinkEl.current.link.click();
      props.setDownloadLoaidng(false);
      setReadyToDownload(false);
    }
  }, [readyToDownload]);

  const getCampaignReportData = async () => {
    let shop = shopDetailsData;
    if (!(shop && shop.userId) || !props.campaignId) return;
    props.setDownloadLoaidng(true);
    const res = await getCampaignReport(shop.userId, props.campaignId);
    try {
      if (res?.data?.length > 0) {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.CAMPAIGN_REPORT_DOWNLOADED,
          eventData: {
            'Campaign Id': props.campaignId,
          },
        });
        const formattedData = getFormatttedData(res.data);
        setData(formattedData);
        setReadyToDownload(true);
      } else {
        props.setShowErrorsToast({
          show: true,
          message: 'No campaign report to download.',
        });
        props.setDownloadLoaidng(false);
      }
    } catch (err) {
      props.setShowErrorsToast({
        show: true,
        message: 'Unable to fetch campaign report.',
      });
      props.setDownloadLoaidng(false);
    }
  };

  return (
    <div>
      <div className='flexCenter'>
        <div>
          <span
            className='campaignDownloadReportCss'
            onClick={() => getCampaignReportData()}
          >
            Download Report
          </span>
        </div>
      </div>
      <CSVLink
        headers={headers}
        filename={`campaignReport_${Date.now()} .csv`}
        data={data}
        ref={csvLinkEl}
      />
    </div>
  );
};

export default DownloadCampaingReport;
