const {
  planPermissions,
  options,
  SAMPLE_MEDIA_FILE,
  PLAN_ENGAGE_PLUS,
} = require("../constants/constants");

function capitalize(input) {
  if (input) {
    input = input.toLowerCase();
    var words = input.split(" ");
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(" ");
  }
  return input;
}

const sortObj = (obj, key) =>
  obj.sort((first, second) => (first[key] > second[key] ? -1 : 1));

const isHavePermission = (shopDetailsData, feature, checkFeature = true) => {
  const features = shopDetailsData?.featuresList;
  const shop = shopDetailsData;
  const featurePlanPermissions = planPermissions[feature];

  if (checkFeature) {
    if (
      features?.includes(featurePlanPermissions?.features[0]) &&
      featurePlanPermissions?.planIds?.includes(Number(shop?.planId))
    ) {
      return true;
    }
  } else if (featurePlanPermissions?.planIds?.includes(Number(shop?.planId))) {
    return true;
  }
  return false;
};

const validateSupportCountryCode = (countryCode) => {
  var cc = /^(?:[1-9]\d{0,5})$/;
  if (countryCode || countryCode.match(cc)) {
    return false;
  } else if (countryCode && countryCode[0] === "0") {
    return "cannot start with 0";
  } else {
    return "enter a valid country code";
  }
};

const validateSupportPhoneNo = (phone) => {
  var phoneno = /^(?:[0-9]\d{0,14})$/;
  if (!phone || phone.match(phoneno)) {
    return false;
  } else {
    return "Enter a valid phone number";
  }
};

const invalidMediaFileSizeAndType = (fileType, fileExtension, fileSize) => {
  if (
    fileType === options.TYPE[2].value &&
    (fileSize > 2097152 ||
      !["image/jpeg", "image/png", "image/jpg"].includes(fileExtension))
  ) {
    return {
      error: true,
      message: SAMPLE_MEDIA_FILE.imageFileError,
    };
  } else if (
    fileType === options.TYPE[3].value &&
    (fileSize > 16971520 || !["video/mp4"].includes(fileExtension))
  ) {
    return {
      error: true,
      message: SAMPLE_MEDIA_FILE.videoFileError,
    };
  } else if (
    fileType === options.TYPE[4].value &&
    (fileSize > 2097152 || !["application/pdf"].includes(fileExtension))
  ) {
    return {
      error: true,
      message: SAMPLE_MEDIA_FILE.documentFileError,
    };
  } else {
    return {
      error: false,
      message: "",
    };
  }
};

// Will decide whether to show relase notes or product tour based on new release date
// relase notes Users subscribed to older plans like (Old Free, Old Pro, Old Ultimate)
// Product Tour for new plans
const releaseNotesToBeShowned = (shop) => {
  if (!shop.newUser && shop.platform !== "WIX") return true;
  return false;
};

const privateWabaPresent = (shopWabaDetails, shopPrivateWabaDetails) => {
  if (shopWabaDetails && shopWabaDetails.appId) {
    return true;
  } else if (shopPrivateWabaDetails && shopPrivateWabaDetails.appId) {
    return true;
  }
  return false;
};

const releaseNotesSeen = () => {
  if (sessionStorage.getItem("realease_notes")) return true;
  return false;
};

module.exports = {
  capitalize,
  sortObj,
  isHavePermission,
  validateSupportCountryCode,
  validateSupportPhoneNo,
  invalidMediaFileSizeAndType,
  releaseNotesToBeShowned,
  releaseNotesSeen,
  privateWabaPresent,
};
