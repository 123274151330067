import { Modal } from "@shopify/polaris";
import WhatsappBusiness from "../../../assets/icons/whatsappBusiness.png";
import { NO_WABA_MODAL } from "../../../constants/constants";
import { useHistory } from "react-router-dom";

const NoWABAModal = (props: any) => {
  const history = useHistory();

  return (
    <Modal
      open={props.showModal}
      sectioned={true}
      small
      title={""}
      onClose={() => {
        props.setShowModal(false);
      }}
    >
      <Modal.Section>
        <div className="display-flex-center">
          <img
            alt="Success Icon"
            src={WhatsappBusiness}
            title="WhatsappBusiness"
            className="no-waba-modal-img"
          />
        </div>
        <div className="no-waba-modal-desc">
          <div>{NO_WABA_MODAL.DESC[0]}</div>
          <div>{NO_WABA_MODAL.DESC[1]}</div>
        </div>
        <div
          className="display-flex-center"
          onClick={() => {
            history.push("/private-waba");
          }}
        >
          <button className="flatGreen_btn">{NO_WABA_MODAL.BUTTON}</button>
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default NoWABAModal;
