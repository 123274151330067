import React, { useState, useEffect } from 'react';
import {
  ContextualSaveBar,
  Heading,
  Layout,
  Link,
  Page,
  SettingToggle,
  TextContainer,
  TextStyle,
} from '@shopify/polaris';
import AgentSettings from './Agents/AgentsSettings';
import CallOutTag from './CallOut';
import GreetingWidget from './Greetings/GreetingsWidget';
import ChatButtonTheme from './ChatButtonTheme/ChatButtonTheme';
import PagesToDisplay from './PagesToDisplay';
import Devices from './Devices/index';
import {
  getChatDetails,
  updateCallout,
  updateChatDetails,
  updateGreetingWidgetData,
  updateChatDevices,
  deleteChatDevices,
  updateChatTheme,
  updateOptinWidgetData,
} from '../../redux/actions/chat-settings';
import { getShopSettings, updateShopSettings } from '../../redux/actions/shop';
import ShareSettings from './ShareSetting';
import {
  DEVICE_TYPE_DESKTOP_ONLY,
  DEVICE_TYPE_MOBILE_AND_DESKTOP,
  DEVICE_TYPE_MOBILE_ONLY,
  NUDGE_SCREEN_CONTENT,
  PLAN_OLD_FREE,
  PLATFORM,
  TUTORIAL_VIDEOS,
  SHOP_FEATURES,
  PLANS_MODAL_TITLE,
  PLAN_ENGAGE_WIX,
  PLAN_ENTERPRISE,
  NUDGE_SCREEN_CONTACT_SUPPORT,
} from "../../constants/constants";
import PageMarkup from "../../common/PageMarkup";
import widgetGuideJSON from "../../content/Widget/OverlaysContent/en.json";
import wixWidgetGuideJSON from "../../content/WiXWidget/en.json";
import TooltipOverlay from "../../common/Tooltip";
import GuideModal from "../../common/GuideModal";
import { clevertapEventPush, CT_EVENT_IDS } from "../../utils/clevertapUtils";
import { useLocation } from "react-router-dom";
import OptinWidgets from "./OptinWidgets";
import AppEmbedDeepLink from "../../common/AppEmbedDeepLink";
import NudgeScreenContent from "../../common/NudgeScreen";
import NoScriptModal from "../Modals/NoScriptTagModal/NoScriptModal";
import PlansModal from "../Modals/PlansModal";
import { shopFeaturesFromStoreData } from "../../utils/helper";
import { useShopStore } from '../../hooks/shopStore';
import NudgeScreenContactSupport from '../../common/NudgeScreenContactSupport';

const ChatSettings = ({ showPricingBanner }: any) => {
  const { shopDetailsData } = useShopStore();
  const location: any = useLocation();
  const [fetchOptinData, setFetchOptinData] = useState(false);
  const [isWAChatEnabled, setIsWAChatEnabled] = useState(false);
  const [userId, setUserId] = useState('');
  const [toast, setToast] = useState('');
  const [errorToast, setErrorToast] = useState('');
  const [saveFlag, setSaveFlag] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [selected, setSelected] = useState<any>(0);
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);

  const [shopSettings, setShopSettings] = useState({
    isMultiAgentEnabled: true,
    buttonEnabled: true,
    is_store_open: true,
    store_timezone: 'Asia/Calcutta',
    optinEnabled: true,
  });

  const [chatData, setChatData] = useState({
    btn_cta: '',
    buttonCustom: '',
    buttonId: '',
    buttonPages: [],
    ctaEnabled: '',
    iconCustom: '',
    iconUrl: '',
    include_product_link: '',
    is_chat_btn_color_custom: false,
    is_chat_btn_solid_background: false,
    lang: '',
    prefilled_msg: '',
  });

  const [dataChanges, setDataChanges] = useState<any>({
    agentSettings: null,
    chat: null,
    share: null,
    greeting: null,
    callout: null,
    chatTheme: null,
    pagesToDisplay: '',
    sharePagesToDisplay: '',
    devices: null,
  });

  const [hasChanges, setHasChanges] = useState(false);
  const [selectedDeviceType, setSelectedDeviceType] = useState<number>();
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [mobileData, setMobileData] = useState({});
  const [desktopData, setDesktopData] = useState({});
  const [errorMessage, setErrorMessage] = useState<any>({
    chatTheme: '',
    callout: '',
    greeting: '',
    mobile: '',
    desktop: '',
  });
  const [showNoScriptModal, setShowNoScriptModal] = useState(false);

  const tabs = [
    {
      id: 'Chat Widgets',
      content: 'Chat Widgets',
      accessibilityLabel: 'Chat Widgets',
      panelID: 'chatswidgets',
    },
    {
      id: 'Share Widgets',
      content: 'Share Widgets',
      panelID: 'sharewidgets',
    },
    {
      id: 'Optin Widgets',
      content: 'Optin Widgets',
      panelID: 'optinwidgets',
    },
  ];

  let shop = shopDetailsData;
  let planId = shopDetailsData.planId;
  
  useEffect(() => {
    if (shop && shop.userId) {
      setUserId(shop.userId);
      fetchShopSettings(shop.userId);
      fetchChatDetails(shop.userId);
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    let selectedIndex = tabs.findIndex(
      (tab) => tab.id === location?.state?.tab
    );
    if (selectedIndex > -1) {
      setSelected(selectedIndex);
    } else {
      setSelected(0);
    }
  }, []);

  useEffect(() => {
    if (hasChanges) {
      setDiscardChanges(false);
    }
  }, [hasChanges]);

  const fetchShopSettings = (userId: string) => {
    getShopSettings(userId).then((response: any) => {
      setShopSettings(response.settings);
    });
  };

  const fetchChatDetails = (userId: string) => {
    getChatDetails(userId).then((response: any) => {
      setIsWAChatEnabled(response.chat.is_chat_enabled);
      setChatData(response.chat);
      clevertapEventPush({
        eventId: CT_EVENT_IDS.CHAT_SETTINGS_PAGE_VIEWED,
        eventData: { widgetEnabled: response.chat.is_chat_enabled },
      });
    });
  };

  const getToggleSwitchVariationStyle = () => {
    return isWAChatEnabled ? 'strong' : 'negative';
  };

  const getToggleSwitchTxtContent = () => {
    return isWAChatEnabled ? 'enabled' : 'disabled';
  };

  const getToggleSwitchBtnContent = () => {
    return isWAChatEnabled ? 'Disable' : 'Enable';
  };

  const checkForApproval = () => {
    if (shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.WIDGETS_RESTRICTED)) {
      setShowModal(true);
      return true;
    } else {
      setShowModal(false);
      return false;
    }
  };

  // Enable/Disable Chat button
  const enableWhatsAppChat = () => {
    if (!shop.scriptTagAdded) {
      setShowNoScriptModal(true);
      return;
    }
    if (checkForApproval()) {
      return;
    }
    let isChatEnabled = isWAChatEnabled ? false : true;
    const params = new URLSearchParams();
    params.append('enabled', isChatEnabled.toString());
    updateChatDetails(userId, params).then((chat: any) => {
      let toastMessage = isChatEnabled ? 'Enabled Chat' : 'Disabled Chat';
      clevertapEventPush({
        eventId: isChatEnabled
          ? CT_EVENT_IDS.CHAT_WIDGET_ENABLED
          : CT_EVENT_IDS.CHAT_WIDGET_DISABLED,
        eventData: {},
      });
      setToast(toastMessage);
      setChatData(chat);
      setIsWAChatEnabled(isChatEnabled);
    });
  };

  const pagesToDisplayChanges = (data: string[]) => {
    if (data && data.length > 0) {
      setHasChanges(true);

      setDataChanges({ ...dataChanges, pagesToDisplay: data.toString() });
    } else {
      setHasChanges(false);
    }
  };

  const calloutCardChanges = (data: any) => {
    if (data && Object.keys(data).length > 0) {
      setHasChanges(true);

      setDataChanges({ ...dataChanges, callout: data });
    }
  };

  const chatButtonThemeChanges = (data: any) => {
    if (data && Object.keys(data).length > 0) {
      setHasChanges(true);

      setDataChanges({ ...dataChanges, chatTheme: data });
    }
  };
  const agentSettingChanges = (data: any) => {
    if (Object.keys(data).length > 0) {
      setDataChanges({ ...dataChanges, agentSettings: data });
      setHasChanges(true);
    }
  };
  const greetingWidgetChanges = (data: any) => {
    if (Object.keys(data).length > 0) {
      if (data.hasOwnProperty('multiAgentEnabled')) {
        let params = new URLSearchParams();
        params.append('multiAgentEnabled', data.multiAgentEnabled);
        updateShopSettings(userId, params).then((response: any) => {
          if (response.status === 'success') {
            let toastMessage = data.multiAgentEnabled
              ? 'Enabled Greeting Widget'
              : 'Disabled Greeting Widget';
            setToast(toastMessage);
            clevertapEventPush({
              eventId: CT_EVENT_IDS.WIDGET_EDITED,
              eventData: {
                'Widget Name': 'Chat widget',
              },
            });
          } else {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(response);
          }
        });
      } else {
        setDataChanges({ ...dataChanges, greeting: data });
        setHasChanges(true);
      }
    }
  };

  const optinWidgetChanges = (data: any) => {
    if (Object.keys(data).length != 0) {
      setDataChanges({ ...dataChanges, optinWidget: data });
      setHasChanges(true);
    }
  };

  const handleDeviceTypeChange = (type: number, showCTX: boolean) => {
    setSelectedDeviceType(type);
    setHasChanges(showCTX);
  };

  const devicesChanges = (data: any) => {
    if (Object.keys(data).length > 0) {
      if (Object.keys(data.mobile).length > 0) {
        setDataChanges({
          ...dataChanges,
          devices: {
            ...dataChanges.devices,
            mobile: data.mobile,
          },
        });
        setHasChanges(true);
      }

      if (Object.keys(data.desktop).length > 0) {
        setDataChanges({
          ...dataChanges,
          devices: {
            ...dataChanges.devices,
            desktop: data.desktop,
          },
        });

        setHasChanges(true);
      }
    }
  };

  const handleErrorMessage = (message: string, key: string) => {
    let obj: any = errorMessage;
    obj[key] = message;
    setErrorMessage(obj);
  };

  const isFormValid = () => {
    let valid = true,
      message: string = '';

    for (const item of Object.keys(errorMessage)) {
      if (item === 'mobile' && selectedDeviceType === DEVICE_TYPE_DESKTOP_ONLY)
        continue;
      if (item === 'desktop' && selectedDeviceType === DEVICE_TYPE_MOBILE_ONLY)
        continue;

      if (errorMessage[item]) {
        valid = false;
        message = errorMessage[item];
      }
    }

    return { valid: valid, message: message };
  };

  const updateChatData = () => {
    let form = isFormValid();
    if (!form.valid) {
      setShowErrorToast((showErrorToast) => !showErrorToast);
      setErrorToast(form.message);
    } else {
      setSaveFlag(!saveFlag);
      setIsDataSubmitted(false);
      clevertapEventPush({
        eventId: CT_EVENT_IDS.WIDGET_EDITED,
        eventData: {
          'Widget Name': 'Chat widget',
        },
      });
      if (dataChanges.agentSettings) {
        if (dataChanges.agentSettings.hasOwnProperty('buttonEnabled')) {
          let params = new URLSearchParams();
          params.append(
            'buttonEnabled',
            dataChanges.agentSettings.buttonEnabled
          );

          updateShopSettings(userId, params).then((response: any) => {
            if (response.status === 'success') {
              setShowSuccessToast((successToast: any) => !successToast);
              setToast('Data Saved');

              setDataChanges({ ...dataChanges, agentSettings: null });
              setIsDataSubmitted(true);
            } else {
              setShowErrorToast((errorToast: any) => !errorToast);
              setErrorToast(response);
            }
          });
        }
      }

      if (dataChanges.chatTheme) {
        let params = new URLSearchParams();
        let customParams = new URLSearchParams();
        var data: any = dataChanges.chatTheme;

        Object.keys(data).forEach((key) => {
          switch (key) {
            case 'templateId':
              customParams.append(key, data[key]);
              params.append(key, data[key]);
              break;
            case 'bgColor1':
            case 'bgColor2':
            case 'iconColor':
            case 'textColor':
            case 'solidBg':
              customParams.append(key, data[key]);
              break;
            default:
              params.append(key, data[key]);
              break;
          }
        });

        if (data.templateId) {
          updateChatTheme(userId, data.templateId, customParams).then(
            (response: any) => {
              if (response.status === 'success') {
                updateChatButtonData(params);
                setShowSuccessToast((successToast: any) => !successToast);
                setToast('Custom Changes Saved');

                setDataChanges({ ...dataChanges, chatTheme: null });
                setIsDataSubmitted(true);
              } else {
                setShowErrorToast((errorToast: any) => !errorToast);
                setErrorToast(response);
              }
            },
            (err) => {
              setShowErrorToast((errorToast: any) => !errorToast);
              setErrorToast('Something went wrong');
            }
          );
        }
      }

      if (dataChanges.callout) {
        var params: any = new URLSearchParams();
        var cData: any = dataChanges.callout;

        Object.keys(cData).forEach((key) => {
          params.append(key, cData[key]);
        });

        updateCallout(userId, params).then(
          (response: any) => {
            if (response.status === 'success') {
              setShowSuccessToast((successToast: any) => !successToast);
              setToast('Callout Data Saved');

              setDataChanges({ ...dataChanges, callout: null });
              setIsDataSubmitted(true);
            } else {
              setShowErrorToast((errorToast: any) => !errorToast);
              setErrorToast(response);
            }
          },
          (err) => {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast('Something went wrong');
          }
        );
      }

      if (dataChanges.greeting) {
        let params: any = new URLSearchParams();
        var gData: any = dataChanges.greeting;

        Object.keys(gData).forEach((key) => {
          params.append(key, gData[key]);
        });

        updateGreetingWidgetData(userId, params).then(
          (response: any) => {
            if (response.status === 'success') {
              setShowSuccessToast((successToast: any) => !successToast);
              setToast('Greetings Widget Data Saved');

              setDataChanges({ ...dataChanges, greeting: null });
              setIsDataSubmitted(true);
            } else {
              setShowErrorToast((errorToast: any) => !errorToast);
              setErrorToast(response);
            }
          },
          (err) => {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast('Something went wrong');
          }
        );
      }

      if (dataChanges.optinWidget) {
        let params: any = new URLSearchParams();
        let optinData: any = dataChanges.optinWidget;

        Object.keys(optinData).forEach((key) => {
          params.append(key, optinData[key]);
        });

        updateOptinWidgetData(userId, params).then(
          (response: any) => {
            if (response.status === 'success') {
              setShowSuccessToast((successToast: any) => !successToast);
              setToast('Optin Widget Data Saved');

              setDataChanges({ ...dataChanges, optinWidget: null });
              setIsDataSubmitted(true);
            } else {
              setShowErrorToast((errorToast: any) => !errorToast);
              setErrorToast(response);
            }
          },
          (err) => {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast('Something went wrong');
          }
        );
      }

      switch (selectedDeviceType) {
        case DEVICE_TYPE_MOBILE_AND_DESKTOP:
          updateMobileData();
          updateDesktopData();
          break;
        case DEVICE_TYPE_MOBILE_ONLY:
          updateMobileData();
          deleteDesktopData();
          break;
        case DEVICE_TYPE_DESKTOP_ONLY:
          updateDesktopData();
          deleteMobileData();
          break;
      }

      if (dataChanges.pagesToDisplay) {
        const params = new URLSearchParams();
        params.append('buttonPages', dataChanges.pagesToDisplay);
        updateChatDetails(userId, params).then((response: any) => {
          if (response.status === 'success') {
            setShowSuccessToast((successToast: any) => !successToast);
            setToast('Data Saved Successfully');

            setDataChanges({ ...dataChanges, pagesToDisplay: '' });
            setIsDataSubmitted(true);
          } else {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(response);
          }
        });
      }

      setHasChanges(false);
    }
  };

  const updateChatButtonData = (params: any) => {
    updateChatDetails(userId, params).then(
      (response: any) => {
        if (response.status === 'success') {
          setShowSuccessToast((successToast: any) => !successToast);
          setToast('Chat Design Data Saved');

          setDataChanges({ ...dataChanges, chatTheme: null });
          setIsDataSubmitted(true);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      },
      (err) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast('Something went wrong');
      }
    );
  };

  const updateMobileData = () => {
    if (Object.keys(mobileData).length) {
      const params = new URLSearchParams();
      var mData: any = mobileData;

      Object.keys(mData).forEach((key) => {
        if (key !== 'userId') params.append(key, mData[key]);
      });

      updateChatDevices(userId, params).then((response: any) => {
        if (response.status === 'success') {
          setShowSuccessToast((showSuccessToast) => !showSuccessToast);
          setToast('Data Saved Successfully');

          setDataChanges({
            ...dataChanges,
            devices: { ...dataChanges.devices, mobile: null },
          });
          setIsDataSubmitted(true);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      });
    }
  };

  const deleteMobileData = () => {
    const params = new URLSearchParams();
    params.append('deviceType', 'mobile');
    deleteChatDevices(userId, params).then((response: any) => {
      if (response.status === 'success') {
        setDataChanges({
          ...dataChanges,
          devices: { ...dataChanges.devices, mobile: null },
        });
      }
    });
  };

  const updateDesktopData = () => {
    if (Object.keys(desktopData).length) {
      const params = new URLSearchParams();
      var dData: any = desktopData;

      Object.keys(dData).forEach((key) => {
        if (key !== 'userId') params.append(key, dData[key]);
      });
      updateChatDevices(userId, params).then((response: any) => {
        if (response.status === 'success') {
          setShowSuccessToast((showSuccessToast) => !showSuccessToast);
          setToast('Data Saved Successfully');

          setDataChanges({
            ...dataChanges,
            devices: { ...dataChanges.devices, desktop: null },
          });
          setIsDataSubmitted(true);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      });
    }
  };

  const deleteDesktopData = () => {
    const params = new URLSearchParams();
    params.append('deviceType', 'desktop');
    deleteChatDevices(userId, params).then((response: any) => {
      if (response.status === 'success') {
        setDataChanges({
          ...dataChanges,
          devices: { ...dataChanges.devices, desktop: null },
        });
      }
    });
  };

  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message='Unsaved changes'
      saveAction={{
        content: 'Save Settings',
        onAction: updateChatData,
      }}
      discardAction={{
        onAction: () => {
          if (dataChanges.optinWidget) {
            setFetchOptinData(true);
          }
          setHasChanges(false);
          setDiscardChanges(true);
        },
      }}
    />
  ) : null;

  const tabContent = {
    0: (
      <React.Fragment>
        <Layout.AnnotatedSection
          title='WhatsApp Chat Widget'
          description='Enable or Disable WhatsApp chat on your store in order to help your customers interact with your support agents.'
        >
          <div className='pt-2'>
            <SettingToggle
              action={{
                content: getToggleSwitchBtnContent(),
                onAction: enableWhatsAppChat,
              }}
              enabled={isWAChatEnabled}
            >
              WhatsApp Chat Widget is currently{' '}
              <TextStyle variation={getToggleSwitchVariationStyle()}>
                {getToggleSwitchTxtContent()}
              </TextStyle>{' '}
              <Link url={'https://' + shop.url} external={true}>
                {' '}
                Click here to view it on your store
              </Link>
            </SettingToggle>
          </div>
        </Layout.AnnotatedSection>
        <AgentSettings
          onChanges={agentSettingChanges}
          shopSettings={shopSettings}
          setToast={setToast}
          setErrorToast={setErrorToast}
          setShowSuccessToast={setShowSuccessToast}
          setShowErrorToast={setShowErrorToast}
          isDataSubmitted={isDataSubmitted}
          reset={discardChanges}
          onError={handleErrorMessage}
        />
        <ChatButtonTheme
          chatData={chatData}
          onChanges={chatButtonThemeChanges}
          setToast={setToast}
          setErrorToast={setErrorToast}
          setShowSuccessToast={setShowSuccessToast}
          setShowErrorToast={setShowErrorToast}
          setHasChanges={setHasChanges}
          isDataSubmitted={isDataSubmitted}
          reset={discardChanges}
          onError={handleErrorMessage}
        />
        <CallOutTag
          onChanges={calloutCardChanges}
          isDataSubmitted={isDataSubmitted}
          reset={discardChanges}
          onError={handleErrorMessage}
        />
        <GreetingWidget
          onChanges={greetingWidgetChanges}
          userId={userId}
          shopSettings={shopSettings}
          setToast={setToast}
          setErrorToast={setErrorToast}
          setShowSuccessToast={setShowSuccessToast}
          setShowErrorToast={setShowErrorToast}
          setHasChanges={setHasChanges}
          isDataSubmitted={isDataSubmitted}
          reset={discardChanges}
          onError={handleErrorMessage}
        />
        <Devices
          onChanges={devicesChanges}
          onTypeChange={handleDeviceTypeChange}
          isDataSubmitted={isDataSubmitted}
          reset={discardChanges}
          saveFlag={saveFlag}
          setMobileData={setMobileData}
          setDesktopData={setDesktopData}
          onError={handleErrorMessage}
          setHasChanges={setHasChanges}
        />
        <PagesToDisplay
          title='Pages to display'
          isWAEnabled={isWAChatEnabled}
          subTitle='Select the pages where you want to show the WhatsApp Chat button.'
          footerText='Please note that by default we show the WhatsApp chat button on all other pages that aren’t listed above.'
          errorText='Atleast one page needs to be selected if Whatsapp Chat is enabled'
          pages={chatData?.buttonPages}
          onFieldChange={pagesToDisplayChanges}
          isDataSubmitted={isDataSubmitted}
          reset={discardChanges}
          platform={shop.platform}
        />
        <AppEmbedDeepLink source='chat' />
        <NoScriptModal
          showModal={showNoScriptModal}
          setShowModal={setShowNoScriptModal}
          wixSiteId={shop.wixSiteId}
        />
        <PlansModal
          title={PLANS_MODAL_TITLE.WIDGET_PAGE}
          currentPlanId={planId}
          shop={shop}
          showPricingPlansModal={showModal}
          setShowPricingPlansModal={setShowModal}
        />
      </React.Fragment>
    ),
    1: (
      <ShareSettings
        setToast={setToast}
        setErrorToast={setErrorToast}
        setShowSuccessToast={setShowSuccessToast}
        setShowErrorToast={setShowErrorToast}
      ></ShareSettings>
    ),
    2: (
      <OptinWidgets
        setToast={setToast}
        setErrorToast={setErrorToast}
        setShowSuccessToast={setShowSuccessToast}
        setShowErrorToast={setShowErrorToast}
        onChanges={optinWidgetChanges}
        setHasChanges={setHasChanges}
        isDataSubmitted={isDataSubmitted}
        reset={discardChanges}
        onError={handleErrorMessage}
        setFetchOptinData={setFetchOptinData}
        fetchOptinData={fetchOptinData}
      ></OptinWidgets>
    ),
  };

  const handleTabChange = (selected: number) => {
    setSelected(selected);
  };
  return [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform) &&
    PLAN_OLD_FREE === Number(shop.planId) ? (
    <Page title={NUDGE_SCREEN_CONTENT.WIDGET.TITLE}>
      <br />
      <br />
      <div className='nudgeScreen-singlebox'>
        <div>
          <TextContainer>
            <Heading>
              <TextStyle variation='strong'>
                {NUDGE_SCREEN_CONTENT.WIDGET.SUBTITLE}
              </TextStyle>
            </Heading>
            <TextStyle variation='subdued'>
              <br />
              <br />
              {NUDGE_SCREEN_CONTENT.WIDGET.CONTENT[0]}
            </TextStyle>
          </TextContainer>

          <TextContainer>
            <TextStyle variation='subdued'>
              <br />
              {NUDGE_SCREEN_CONTENT.WIDGET.CONTENT[1]}
              <br />
              <br />
              {NUDGE_SCREEN_CONTENT.WIDGET.CONTENT[2]}
              <br />
              <br />
              {NUDGE_SCREEN_CONTENT.WIDGET.CONTENT[3]}
            </TextStyle>
          </TextContainer>
        </div>
        <NudgeScreenContent />
      </div>
    </Page>
  ) : [PLATFORM.NONE].includes(shop.platform) &&
    [PLAN_ENGAGE_WIX, PLAN_ENTERPRISE].includes(Number(shop.planId)) ? (
    <NudgeScreenContactSupport
      pageDetails={NUDGE_SCREEN_CONTACT_SUPPORT.WHATSAPP_WIDGETS}
      shop={shop}
    />
  ) : (
    <>
      <TooltipOverlay
        btn1Action={() => setOpenGuideModal(true)}
        btn2Action={() => {
          setOpenGuideModal(true);
          setTabIndex(1);
        }}
        btn3Action={() => {
          setOpenGuideModal(true);
          setTabIndex(2);
        }}
        obj={
          [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
            ? wixWidgetGuideJSON
            : widgetGuideJSON
        }
        showPricingBanner={showPricingBanner}
        currentSection={selected}
      />
      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={
            [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
              ? wixWidgetGuideJSON
              : widgetGuideJSON
          }
          tabIndex={tabIndex}
        />
      ) : null}
      <PageMarkup
        title='Widget Settings'
        tutorial
        selected={selected}
        tutorialSrc={
          selected
            ? TUTORIAL_VIDEOS.WIDGETS_PAGE[selected].videoSrc
            : TUTORIAL_VIDEOS.WIDGETS_PAGE[0].videoSrc
        }
        tutorialTitle={
          selected
            ? TUTORIAL_VIDEOS.WIDGETS_PAGE[selected].content
            : TUTORIAL_VIDEOS.WIDGETS_PAGE[0].content
        }
        tabs={tabs}
        tabContent={tabContent}
        toast={toast}
        errorToast={errorToast}
        showErrorToast={showErrorToast}
        showSuccessToast={showSuccessToast}
        handleTabChange={handleTabChange}
      >
        {ctxSaveBarMarkup}
      </PageMarkup>
    </>
  );
};

export default ChatSettings;
