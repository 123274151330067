import { CAMPAIGNS } from '../../constants/api-constants';
import httpClient from '../../utils/http-client';

let config = {
  baseURL: CAMPAIGNS.baseUrl,
};

export const getCampaignTemplates = (userId: string) => {
  return httpClient.get(
    CAMPAIGNS.api.GET_CAMPAIGN_TEMPLATES.replace('$userId', userId),
    config
  );
};
export const uploadCampaignMedia = (userId: string, params: any) => {
  return httpClient.post(
    CAMPAIGNS.api.UPLOAD_CAMPAIGN_MEDIA.replace('$userId', userId),
    params,
    config
  );
};
export const createCampaign = (userId: string, params: any) => {
  return httpClient.post(
    CAMPAIGNS.api.CREATE_CAMPAIGN.replace('$userId', userId),
    params,
    config
  );
};
export const createCampaignSegment = (userId: string, params: any) => {
  return httpClient.post(
    CAMPAIGNS.api.CREATE_CAMPAIGN_SEGMENT.replace('$userId', userId),
    params,
    config
  );
};

export const scheduleCampaignSegment = (userId: string, params: any) => {
  return httpClient.post(
    CAMPAIGNS.api.SCHEDULE_CAMPAIGN_SEGMENT.replace("$userId", userId),
    params,
    config
  );
};
export const scheduleCampaignFile = (userId: string, params: any) => {
  return httpClient.post(
    CAMPAIGNS.api.SCHEDULE_CAMPAIGN_FILE.replace("$userId", userId),
    params,
    config
  );
};

export const getCampaigns = (userId: any) => {
  return httpClient.get(
    CAMPAIGNS.api.GET_CAMPAIGNS.replace('$userId', userId),
    config
  );
};

export const getCampaignReport = (userId: any, campaignId: any) => {
  return httpClient.get(
    CAMPAIGNS.api.GET_CAMPAIGN_REPORT.replace('$userId', userId).replace(
      '$campaignId',
      campaignId
    )
  );
};

export const downloadFileForSegment = (
  userId: string, //TODO : ask if sender needs to be same
  segmentId: string,
  templateId: string,
  forFile: boolean
) => {
  let params = `/header?templateId=${templateId}`;
  params = forFile
    ? params
    : `?segmentId=${segmentId}&templateId=${templateId}`;
  return httpClient.get(
    CAMPAIGNS.api.DOWNLOAD_FILE_FOR_SEGMENT.replace('$userId', userId) + params
  );
};
export const getThresholdValue = (userId: any) => {
  return httpClient.get(
    CAMPAIGNS.api.CAMPAIGNS_THRESHOLD.replace('$userId', userId),
    config
  );
};
export const setThresholdData = (userId: any, params: any) => {
  return httpClient.post(
    CAMPAIGNS.api.CAMPAIGNS_THRESHOLD.replace('$userId', userId),
    params,
    config
  );
};

export const deleteScheduledCampaign = (userId: any, campaignId: any) => {
  return httpClient.delete(
    CAMPAIGNS.api.CAMPAIGNS_DELETE_SCHEDULED_CAMPAIGN.replace(
      '$userId',
      userId
    ).replace('$campaignId', campaignId)
  );
};
