//@ts-nocheck

import { useMemo, useState } from 'react';
import { ReactComponent as DocumentDownloadIcon } from '../../../../assets/icons/documentDownloadIcon.svg';
import { useUsersContext } from '../../../../context/usersContext';
import VideoPreview from './videoPreview';
import { Modal } from '@shopify/polaris';

const ShowImage = ({ mediaPath, mediaFile, height, width, from }) => {
  const { fetchChatMedia, mediaBlobs } = useUsersContext();
  const [mediaBlob, setMediaBlob] = useState(mediaFile);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const getMedia = () => {
    if (mediaPath && !loading) {
      setLoading(true);
      fetchChatMedia(mediaPath).then((res: any) => {
        setMediaBlob(res);
        setLoading(false);
      });
    }
  };

  useMemo(() => {
    if (mediaBlobs && mediaBlobs[mediaPath]) {
      setMediaBlob(mediaBlobs[mediaPath]);
    }
  }, [mediaPath]);

  const renderVideo = useMemo(() => {
    return (
      <VideoPreview
        from={from}
        mediaBlob={mediaBlob}
        height={height}
        width={width}
        setOpenModal={setOpenModal}
      />
    );
  }, [from, mediaBlob, height, width]);

  const getModal = () => {
    return (
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title='Preview'
      >
        <Modal.Section>
          <div
            style={{
              borderRadius: '5px',
              maxHeight: '500px',
            }}
          >
            {['video/mp4'].includes(mediaBlob?.type) && (
              <div>
                <video
                  style={{
                    width: '100%',
                    objectFit: 'scale-down',
                    borderRadius: '5px',
                    maxHeight: '500px',
                  }}
                  src={URL.createObjectURL(mediaBlob)}
                  width='100%'
                  controls
                />
              </div>
            )}
            {['image/jpeg', 'image/png', 'image/gif'].includes(
              mediaBlob?.type
            ) && (
              <img
                alt='image1'
                src={URL.createObjectURL(mediaBlob)}
                style={{
                  width: `100%`,
                  borderRadius: '5px',
                  objectFit: 'scale-down',
                  maxHeight: '500px',
                }}
              />
            )}
          </div>
        </Modal.Section>
      </Modal>
    );
  };

  return (
    <div>
      {mediaBlob ? (
        <div
          style={{
            maxHeight: '350px',
            maxWidth: '250px',
            marginBottom: '15px',
          }}
        >
          {['video/mp4'].includes(mediaBlob.type) && renderVideo}
          {['image/jpeg', 'image/png', 'image/gif'].includes(
            mediaBlob.type
          ) && (
            <img
              alt='image1'
              title='Click to enlarge'
              src={URL.createObjectURL(mediaBlob)}
              style={{
                width: `${width ? width : '250px'}`,
                height: `${height ? height : '350px'}`,
                cursor: 'pointer',
                objectFit: `${from === 'chats' ? 'cover' : 'scale-down'}`,
                borderRadius: '5px',
              }}
              onClick={() => setOpenModal(true)}
            />
          )}
          {[
            'application/pdf',
            'text/csv',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'text/plain',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/octet-stream',
          ].includes(mediaBlob.type) && (
            <a
              style={{
                width: `${width ? width : '250px'}`,
                height: `${height ? height : '350px'}`,
                borderRadius: '5px',
              }}
              title='Click to download'
              href={URL.createObjectURL(mediaBlob)}
              download
            >
              <DocumentDownloadIcon />
              <br />
            </a>
          )}
        </div>
      ) : (
        <div className='chat__showImageSkeleton' onClick={getMedia}>
          <p className='chat__chatWindow__skeletonText'>
            {!loading ? 'Tap to load' : 'Loading...'}
          </p>
        </div>
      )}
      {getModal()}
    </div>
  );
};

export default ShowImage;
