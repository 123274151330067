import { Heading } from '@shopify/polaris';
import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { getOptinAnalyticsCount } from '../../../redux/actions/analytics';
import styles from './analytics.module.scss';
import LineCharts from './LineChart';
import DateFilter from './DateFilter';
import { PLATFORM, planPermissions } from '../../../constants/constants';
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";
import { Link } from "react-router-dom";
import { useShopStore } from '../../../hooks/shopStore';

function OptIns() {
  const { shopDetailsData } = useShopStore();
  const [showPicker, setShowPicker] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);
  const [isOptIns, setIsOptIns] = useState(false);

  const togglePopoverActive = useCallback(() => {
    setShowPicker((showPicker) => !showPicker);
  }, []);

  let lines = [
    {
      dataKey: 'CHECKOUT',
      label: 'Via Checkout Screen',
      stroke: '#ad7ffd',
    },
    {
      dataKey: 'SPIN_WHEEL',
      label: 'Via Spin the Wheel',
      stroke: '#f2cc52',
    },
    {
      dataKey: 'CHAT_WIDGET',
      label: 'Via Widget',
      stroke: '#f61613',
    },
  ];
  const xDataKey = 'date';
  const disabledLink = '/admin-config';

  let shop = shopDetailsData;

  if ([PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)) {
    lines = lines.filter((obj) => {
      return obj.dataKey !== 'SPIN_WHEEL';
    });
  }

  useEffect(() => {
    if (shop && shop.userId) {
      const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
      const end = moment().format('YYYY-MM-DD');
      fetchData(shop, start, end);
      if (planPermissions.opt_ins.planIds.toString().includes(shop?.planId)) {
        setIsOptIns(true);
      }
    }
  }, []);

  const fetchData = (shop: any, start?: string, end?: string) => {
    getOptinAnalyticsCount(shop.userId, start, end).then((res: any) => {
      if (res.status === 'success' && res.message) {
        const result: any = {};
        res.message.map((item: any) => {
          const date = moment(item.date).format('YYYY-MM-DD');
          if (!result[date]) {
            result[date] = {
              date: date,
              CHAT_WIDGET: 0,
              CHECKOUT: 0,
              SPIN_WHEEL: 0,
            };
          }
          result[date][item.source] += item.count;
        });
        setData(Object.values(result));
      }
    });
  };

  const handleSubmit = async (filter: any) => {
    const finalStart = moment(filter.startDate).format('YYYY-MM-DD');
    const finalEnd = moment(filter.endDate).format('YYYY-MM-DD');

    setIsSubmitting(true);
    if (shop.userId && finalStart && finalEnd) {
      fetchData(shop, finalStart, finalEnd);
      clevertapEventPush({
        eventId: CT_EVENT_IDS.ANALYTICS_DATE_SELECTED,
        eventData: {
          'Analytics name': 'Optins Report Analytics',
        },
      });
      setIsSubmitting(false);
      togglePopoverActive();
    }
  };

  return (
    <>
      <div className={styles.analyticContainer}>
        <Heading>{'Opt Ins'}</Heading>
        <DateFilter
          showPicker={showPicker}
          togglePopoverActive={togglePopoverActive}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </div>
      <div className={styles.lineChartLayoutContainer}>
        <LineCharts
          data={data}
          lines={lines}
          xDataKey={xDataKey}
          disabled={!isOptIns}
          disabledRedirectLink={disabledLink}
          disabledText={
            <span>
              Opt-in configuration helps you deliver more messages to your
              customers. This ensures increased abandoned cart recovery and
              improved customer experience. In order to boost your business via
              Whatsapp messaging, user opt-in is mandatory. To enable Opt-in{' '}
              <span className={styles.automation__links}>
                <Link to={`${disabledLink}`}>Click here</Link>
              </span>
            </span>
          }
        />
      </div>
    </>
  );
}

export default OptIns;
