const SubTitle = (props: { subtitle: string }) => {
  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <p
        style={{
          fontSize: 20,
          fontWeight: 400,
          fontFamily: "Rubik",
        }}
      >
        {props.subtitle}
      </p>
    </div>
  );
};

export default SubTitle;
