// @ts-nocheck
import { useEffect, useState } from "react";
import {
  TextField,
  FormLayout,
  Card,
  Stack,
  TextContainer,
  TextStyle,
  Popover,
  ColorPicker,
  Button,
  Toast,
  Link,
} from "@shopify/polaris";
import { hexToHsv, hsvToHex, isValidHex } from "../../../utils/helper";
import { getWheelConfig } from "../../../redux/actions/spin-the-wheel";
import { PLATFORM } from "../../../constants/constants";
import { useShopStore } from '../../../hooks/shopStore';

const WheelConfig = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [picker1, setPicker] = useState(false);
  const [pickerIndex, setPickerIndex] = useState("");
  const [colorOverwritten, setColorOverWritten] = useState(false);
  const [wheelColorConfig, setWheelColorConfig] = useState<any>(
    props.wheelConfig
  );
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [colorErrorIndex, setColorErrorIndex]: any = useState([]);

  useEffect(() => {
    setWheelColorConfig(props.wheelConfig);
  }, [props.wheelConfig]);

  useEffect(() => {
    if (props.reset) {
      fetchWheelConfig();
      setColorErrorIndex([]);
    }
  }, [props.reset]);

  let shop = shopDetailsData;

  const fetchWheelConfig = () => {
    getWheelConfig(shop.userId).then((data: any) => {
      if (data.status === "success") {
        setWheelColorConfig(data.spinWheel);
      }
    });
  };

  const handleChange = (index: any, val: any, field: string) => {
    let data = [...wheelColorConfig];
    let newdata = data.map((e: any, i: any) => {
      if (i === index - 1) {
        e[field] = val;
      }
      return e;
    });
    props.onChanges({ spinTheWheel: [...newdata] });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const handleColorChange = (
    index: any,
    inputfield: string,
    field: string,
    color: any
  ) => {
    setColorOverWritten(true);
    handleColorInputChange("bgColor1HSV", "bgColor1", color, index);
    handleChange(index, color, "color");
  };

  const handleColorInputChange = (
    inputField: string,
    field: string,
    color: any,
    index: any
  ) => {
    if (isValidHex(color)) {
      if (colorErrorIndex.includes(index)) {
        let clrIndex = [...colorErrorIndex];
        let idx = clrIndex.indexOf(index);
        clrIndex.splice(idx, 1);
        setColorErrorIndex(clrIndex);
      }
    } else {
      if (!colorErrorIndex.includes(index)) {
        let clrIndex = [...colorErrorIndex];
        clrIndex.push(index);
        setColorErrorIndex(clrIndex);
      }
    }
  };

  const bottomText = (
    <TextStyle variation="subdued">
      For losing options keep the discount code empty
      <br></br>
      For winning options create discount code from{" "}
      <Link external={true} url={ shop.platform === PLATFORM.SHOPIFY ? `https://${shop.url}/admin/discounts` : `https://manage.wix.com/dashboard/${shop.wixSiteId}/coupons`}>
        E-commerce manager {">"} Discounts
      </Link>
    </TextStyle>
  );

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const RowBuilder = (index: any, text: any, code: any, color: any) => {
    return (
      <FormLayout.Group key={index}>
        <Stack distribution="fill" wrap={false}>
          <TextStyle variation="subdued">{index}</TextStyle>
          <TextField
            label="1"
            labelHidden
            value={text}
            onChange={(val) => {
              handleChange(index, val, "text");
            }}
          />
          <TextField
            label="1"
            labelHidden
            value={code}
            onChange={(val) => {
              handleChange(index, val, "code");
            }}
          />
          <Popover
            active={picker1 && index === pickerIndex}
            sectioned={true}
            activator={
              <TextField
                label="color picker 1"
                labelHidden
                value={color}
                error={
                  color && colorErrorIndex.includes(index)
                    ? "Please enter a valid hex code "
                    : !color
                    ? "Please select a color"
                    : ""
                }
                onChange={(clr: any) => {
                  handleColorInputChange("bgColor1HSV", "bgColor1", clr, index);
                  handleChange(index, clr, "color");
                }}
                suffix={
                  <button
                    className="btn-in-input"
                    style={{ backgroundColor: color }}
                    onClick={() => {
                      setPickerIndex(index);
                      setPicker(true);
                    }}
                  ></button>
                }
              />
            }
            onClose={() => {
              setPicker(false);
            }}
          >
            <Popover.Pane fixed>
              <Popover.Section>
                <ColorPicker
                  color={hexToHsv(color)}
                  onChange={(clr: any) => {
                    handleColorChange(
                      index,
                      "bgColor1HSV",
                      "bgColor1",
                      hsvToHex(clr.hue, clr.saturation, clr.brightness)
                    );
                  }}
                />
              </Popover.Section>
            </Popover.Pane>
          </Popover>
          <div style={{ color: "#bf0711" }}>
            <Button
              monochrome
              outline
              onClick={() => removeWheelConfig(index - 1)}
            >
              Delete
            </Button>
          </div>
        </Stack>
      </FormLayout.Group>
    );
  };

  const removeWheelConfig = (index: any) => {
    if (wheelColorConfig.length === 4) {
      showErrorToast("Minimum 4 slots are required");
    } else {
      let data = [...wheelColorConfig];
      let newdata = data.filter((e, i) => {
        return i !== index;
      });
      if (
        !colorOverwritten &&
        props.selectedWheel &&
        parseInt(props.selectedWheelID) >= 0
      ) {
        for (let i = 0; i < newdata.length; i++) {
          if (i % 2 === 0) {
            newdata[i].color =
              props.wheelTemplates[props.selectedWheelID]["color1"];
          } else {
            newdata[i].color =
              props.wheelTemplates[props.selectedWheelID]["color2"];
          }
        }
      }
      setWheelColorConfig([...newdata]);
      props.onChanges({ spinTheWheel: [...newdata] });
    }
  };

  const FormBuilder = (config: any) => {
    let rowList = [];
    for (var i = 0; i < Object.keys(config).length; i++) {
      rowList.push(
        RowBuilder(i + 1, config[i].text, config[i].code, config[i].color)
      );
    }
    return rowList;
  };

  const addWheelConfig = () => {
    if (wheelColorConfig.length >= 12) {
      showErrorToast("You have reached maximum limit of 12");
    } else {
      let data = [...wheelColorConfig];
      if (parseInt(props.selectedWheelID) >= 0) {
        let colors = props.wheelTemplates[props.selectedWheelID];
        if (data.length % 2 === 0) {
          data.push({
            text: "",
            code: "",
            color: colors.color1,
          });
        } else {
          data.push({
            text: "",
            code: "",
            color: colors.color2,
          });
        }
      } else if (props.selectedWheel && parseInt(props.selectedWheel.id) >= 0) {
        if (data.length % 2 === 0) {
          data.push({
            text: "",
            code: "",
            color: props.selectedWheel.colorOdd,
          });
        } else {
          data.push({
            text: "",
            code: "",
            color: props.selectedWheel.colorEven,
          });
        }
      } else {
        data.push({ text: "", code: "", color: "#346463" });
      }
      setWheelColorConfig(data);
      props.onChanges({ spinTheWheel: data });
    }
  };

  return (
    <Card sectioned>
      <div className="spinWheelConfig">
        <Card.Section>
          <FormLayout>
            <div className="wheelconfigScrollCss">
              <FormLayout.Group>
                <Stack distribution="fill" wrap={false}>
                  <TextStyle variation="strong">#</TextStyle>
                  <TextStyle variation="strong">Text</TextStyle>
                  <div className="discountCodeColumn">
                    <TextStyle variation="strong">
                      <Link
                        external={true}
                        url={shop.platform === PLATFORM.SHOPIFY ? `https://${shop.url}/admin/discounts` : `https://manage.wix.com/dashboard/${shop.wixSiteId}/coupons`}
                      >
                        Discount Code
                      </Link>{" "}
                    </TextStyle>
                  </div>
                  <TextStyle variation="strong">Color</TextStyle>
                  <TextStyle variation="strong"></TextStyle>
                </Stack>
              </FormLayout.Group>
              {FormBuilder(wheelColorConfig)}
            </div>
            {
              <Button onClick={addWheelConfig} primary>
                Add More
              </Button>
            }
            <FormLayout.Group>
              <TextContainer>{bottomText}</TextContainer>
            </FormLayout.Group>
            {errorToastMarkup}
          </FormLayout>
        </Card.Section>
      </div>
    </Card>
  );
};

export default WheelConfig;
