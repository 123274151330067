import { Fragment } from "react";
import { Layout, TextStyle } from "@shopify/polaris";
import "./features.scss";

const FeatureComponent = (props: any) => {
  return (
    <Fragment key={props.index}>
      <Layout.Section oneThird key={props.index}>
        <div className={`Polaris-Card basic-tile-feat gen-card-feat`}>
          <div className="iconCSS">
            <img
              src={props.feature.icon}
              alt="profile"
              width="70px"
              height="70px"
            />
          </div>
          <div className="Polaris-Card__Header">
            <h2 className="Polaris-Heading card-heading mt-2">
              {props.feature.heading}
            </h2>
          </div>
          <div className="feat-content">
            <TextStyle variation="subdued">{props.feature.content}</TextStyle>
          </div>
        </div>
      </Layout.Section>
    </Fragment>
  );
};

export default FeatureComponent;
