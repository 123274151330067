import { PLATFORM } from '../../constants/constants';
import { getDiscountCode } from '../../redux/actions/manual-messaging';
import { getOwnerDetails } from '../../redux/actions/shop';

const fetchOwnerDetails = async (userId: string) => {
  const response: any = await getOwnerDetails(userId);
  if (response.status === 'success') {
    return response.owner;
  }
};

const fetchDiscountCode = async (userId: any, template: any) => {
  let discountObj = {
    foundDiscountCode: false,
    code: '',
    discountRatio: '',
  };
  if (template.discount) {
    await getDiscountCode(userId, template.id).then((response: any) => {
      if (response.status === 'success') {
        discountObj.foundDiscountCode = true;
        discountObj.code = response.message.code;
        discountObj.discountRatio = template.discountRatio;
      }
    });
  }
  return discountObj;
};

const parseOrder = async (shopDetailsData:any, userId: any, order: any, template: any) => {
  const shop = shopDetailsData;
  const discount = await fetchDiscountCode(userId, template);
  const owner = await fetchOwnerDetails(userId);
  //Updating Object Key
  const formattedObject = {
    cartItems: '',
    firstName: '',
    totalOrderValue: '',
    currency: 'INR',
    details: '',
    orderItems: '',
    lastName: '',
    customerName: '',
    orderAddress: '',
    orderId: '',
    orderNumber: '',
    trackingNumber: '',
    trackingId: '',
    ownerName: '',
    shopName: '',
    storeUrl: '',
    source: '',
    lastAbCartValue: '',
  };

  if (discount.foundDiscountCode) {
    order.discount = `${discount.discountRatio}`;
    order.discountCode = `"${discount.code}"`;
    order.checkoutUrl = `${order.checkoutUrl}&discount=${discount.code}`;
    order.storeUrl = [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? `https://${order.storeUrl}`
      : `https://${order.storeUrl}/discount/${discount.code}`;
    formattedObject.storeUrl = order.storeUrl;
  } else {
    order.discount = '';
    formattedObject.storeUrl = `https://${shop.url}`;
  }

  delete Object.assign(formattedObject, order, {
    'checkoutUrl': order['checkoutUrl'],
  })['checkoutUrl'];
  delete Object.assign(formattedObject, order, {
    'firstName': order['customerName'],
  })['customerName'];
  delete Object.assign(formattedObject, order, {
    'orderStatusUrl': order['statusUrl'],
  })['statusUrl'];
  delete Object.assign(formattedObject, order, {
    'trackingUrl': order['trackingUrl'],
  })['trackingUrl'];
  delete Object.assign(formattedObject, order, {
    'customerName': order['customerName'],
  })['customerName'];

  delete Object.assign(formattedObject, order, {
    'orderId': order['name'],
  })['name'];
  delete Object.assign(formattedObject, order, {
    'orderNumber': order['id'],
  })['id'];

  formattedObject.shopName = shop.name;

  //@ts-ignore
  formattedObject.orderAddress = formattedObject?.address?.replaceAll(
    '</br>',
    ', '
  );

  if (formattedObject.details) {
    const allItems = JSON.parse(formattedObject.details);
    let itemsString = '';
    if (allItems && allItems.length > 0) {
      allItems.forEach((el: any) => {
        itemsString += `${el.c} X ${el.p},`;
      });
    }

    formattedObject.orderItems = itemsString;
    formattedObject.cartItems = itemsString;
  }
  formattedObject.totalOrderValue = `${formattedObject.currency} ${order.total}`;
  formattedObject.ownerName = owner.name;
  const splitName = formattedObject.customerName?.split(' ');
  formattedObject.firstName =
    formattedObject.firstName === undefined
      ? 'Customer'
      : splitName
      ? splitName[0]
      : 'Customer';

  formattedObject.lastName = splitName ? splitName[splitName.length - 1] : '';

  formattedObject.source = order.source;

  return formattedObject;
};

export const populateTemplate = async (
  shop: any,
  userId: any,
  vObj: any,
  order: any,
  template: any
) => {
  if (!order) {
    return vObj;
  }
  const v1Obj = {
    ...vObj,
  };

  const formattedObj: Object = await parseOrder(shop, userId, order, template);
  for (let key in vObj) {
    if (formattedObj.hasOwnProperty(key)) {
      //@ts-ignore
      v1Obj[key].value = [undefined, null].includes(formattedObj[key])
        ? ''
        : //@ts-ignore
          `${formattedObj[key]}`;
    }
  }
  return v1Obj;
};
