// @ts-nocheck
import { useUsersContext } from '../../../../context/usersContext';

const ChatTicketStatus = (props: any) => {
  const {
    usersChatFilterTags,
    setTicketStatus,
    ticketStatus,
    getChatCount,
    setCurrentUser,
    currentUser,
  } = useUsersContext();
  return (
    <div className='chatTagsContainer'>
      {['ALL', 'OPEN', 'CLOSED'].map((currentTicketStatus: any) => {
        return (
          <div
            className={
              currentTicketStatus === ticketStatus
                ? 'chatTag bgGreen'
                : 'chatTag'
            }
            key={currentTicketStatus}
            onClick={() => {
              setCurrentUser({
                ...currentUser,
                customerPhone: '',
              });
              setTicketStatus(currentTicketStatus);
            }}
          >
            <span>{usersChatFilterTags[currentTicketStatus]?.label}</span>
            <span className='boldText whiteBg'>
              {props.showChatWindow
                ? usersChatFilterTags[currentTicketStatus]?.count
                : '0'}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default ChatTicketStatus;
