// @ts-nocheck
import {
  Button,
  DatePicker,
  Select,
  TextField,
  Toast,
  Popover,
  Icon,
} from '@shopify/polaris';

import { useCallback, useState } from "react";
import "../../userGroup.scss";
import { DeleteMajor } from "@shopify/polaris-icons";
import { choiceOptions, choiceOptionsWix, operatorConst } from "../../utils";
import { PLATFORM } from "../../../../constants/constants";
import { useShopStore } from '../../../../hooks/shopStore';

const ConditionSet = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const {
    index,
    groupData,
    setGroupData,
    parameterOption,
    conditionOption,
    typeOption,
  } = props;
  const [toast, setToast] = useState({ show: false, message: '' });
  const [errorToast, setErrorToast] = useState({ show: false, message: '' });
  const [popoverActive, setPopoverActive] = useState(false);
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const onConditionTypeChange = (key: any) => {
    let data: any = [...groupData.conditions];
    data[index]['operator'] = key;
    setGroupData({ ...groupData, conditions: [...data] });
  };

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );
  let shop = shopDetailsData;

  const handleSelectChange = (name: string, conditionValue: any) => {
    let data: any = [...groupData.conditions];
    if (
      typeOption[groupData.conditions[index]['param']] === 'date' &&
      name === 'operation' &&
      conditionValue !== operatorConst['IS_BETWEEN'] &&
      data[index].conditionValue
    ) {
      data[index].conditionValue.end = data[index].conditionValue.start;
    }
    data[index][name] = conditionValue;

    if (
      name === 'operation' &&
      groupData.conditions[index]['operation'] === operatorConst['IS_BETWEEN']
    ) {
      groupData.conditions[index]['conditionValue'] = {
        start: new Date(),
        end: new Date(),
      };
    }
    if (name === 'param') {
      groupData.conditions[index]['operation'] = '';
      groupData.conditions[index]['conditionValue'] = '';
    }
    setGroupData({ ...groupData, conditions: [...data] });
  };

  const handleRemoveActionButton = () => {
    let data: any = [...groupData.conditions];
    data.splice(index, 1);
    if (data.length === 2) {
      data = data.map((item: any, index: any) => {
        if (index === 1) item.index = 2;
        return item;
      });
    }
    // setConditionBlockObj(data);
    setGroupData({ ...groupData, conditions: [...data] });
  };

  const renderValue = () => {
    if (
      groupData.conditions[index]['operation'] ===
        operatorConst['IS_BETWEEN'] &&
      groupData.conditions[index]?.conditionValue?.start &&
      groupData.conditions[index]?.conditionValue?.end
    ) {
      return (
        new Date(
          groupData.conditions[index]?.conditionValue?.start
        ).toLocaleDateString() +
        ' - ' +
        new Date(
          groupData.conditions[index]?.conditionValue?.end
        ).toLocaleDateString()
      );
    } else if (groupData.conditions[index]?.conditionValue?.start) {
      return new Date(
        groupData.conditions[index]?.conditionValue?.start
      ).toLocaleDateString();
    } else {
      return groupData.conditions[index]?.conditionValue || 'Select Value';
    }
  };

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {renderValue()}
    </Button>
  );

  const handleFocus = (event: any) => {
    if (event.target.id === 'param') {
      let data: any = [...groupData.conditions];
      if (!data[index]['param'])
        setErrorToast({
          show: true,
          message: 'Please select the parameter before selecting condition',
        });
    }
    if (event.target.id === 'conditionValue') {
      let data: any = [...groupData.conditions];
      if (!data[index]['param'])
        setErrorToast({
          show: true,
          message: 'Please select the parameter before selecting value',
        });
      else if (!data[index]['operation'])
        setErrorToast({
          show: true,
          message: 'Please select the condition before selecting value',
        });
    }
  };

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const selectedDate = (data: any) => {
    if (data?.start) {
      data.start = new Date(data.start);
      data.end = data?.end ? new Date(data.end) : new Date(data.start);
    }
    return data;
  };

  return (
    <div className='condition-set'>
      {toastMarkup}
      {errorToastMarkup}
      {index !== 0 && (
        <div className='d-flex-center'>
          <div className='d-flex-center andOrContainer'>
            <div
              onClick={() => onConditionTypeChange('AND')}
              className={`andContainer ${
                groupData.conditions[index]['operator'] === 'AND' &&
                'andOrContainerActive'
              }`}
            >
              AND
            </div>
            <div
              onClick={() => onConditionTypeChange('OR')}
              className={`pl-2 pr-2 orContainer ${
                groupData.conditions[index]['operator'] === 'OR' &&
                'andOrContainerActive'
              }`}
            >
              OR
            </div>
          </div>
        </div>
      )}

      <br />
      <div className='card'>
        <div className='conditionContainer'>
          <div style={{ width: '100%' }}>
            <Select
              label=''
              value={
                groupData.conditions[index]['param']
                  ? groupData.conditions[index]['param']
                  : ''
              }
              onChange={(val) => handleSelectChange('param', val)}
              options={parameterOption}
              placeholder={'Select Parameter'}
            />
          </div>
          <div style={{ width: '100%' }}>
            <Select
              id='param'
              label=''
              value={groupData.conditions[index]['operation']}
              onChange={(val) => handleSelectChange('operation', val)}
              onFocus={() => handleFocus}
              options={conditionOption[groupData.conditions[index]['param']]}
              placeholder={'Select Condition'}
            />
          </div>
          <div style={{ width: '100%' }}>
            {typeOption[groupData.conditions[index]['param']] === 'date' ? (
              <Popover
                active={popoverActive}
                activator={activator}
                onClose={togglePopoverActive}
                ariaHaspopup={false}
                sectioned
              >
                <DatePicker
                  month={month}
                  year={year}
                  onChange={(val: any) =>
                    handleSelectChange('conditionValue', val)
                  }
                  onMonthChange={handleMonthChange}
                  selected={selectedDate(
                    groupData.conditions[index]['conditionValue']
                  )}
                  allowRange={
                    groupData.conditions[index]['operation'] ===
                    operatorConst['IS_BETWEEN']
                  }
                />
              </Popover>
            ) : typeOption[groupData.conditions[index]['param']] ===
              'choice' ? (
              <Select
                label=''
                value={groupData.conditions[index]['conditionValue']}
                onChange={(val) => handleSelectChange('conditionValue', val)}
                onFocus={() => handleFocus}
                options={
                  [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
                    ? choiceOptionsWix[groupData.conditions[index]['param']]
                    : choiceOptions[groupData.conditions[index]['param']]
                }
                placeholder={'Select Value'}
              />
            ) : (
              <TextField
                id='conditionValue'
                label=''
                maxLength={512}
                onFocus={() => handleFocus}
                value={groupData.conditions[index]['conditionValue']}
                onChange={(val) => handleSelectChange('conditionValue', val)}
                type={typeOption[groupData.conditions[index]['param']]}
                autoComplete='off'
              />
            )}
          </div>
        </div>
        {index !== 0 ? (
          <div className='deleteContainer'>
            <div className='deleteIcon' onClick={handleRemoveActionButton}>
              <Icon source={DeleteMajor} color='primary' />
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <br />
    </div>
  );
};

export default ConditionSet;
