import { useEffect, useMemo, useState } from "react";

const AvatarInitial = (props: any) => {
  const [randomColor, setRandomColor] = useState("");
  const [initials, setInitials] = useState("");
  const [avatarSize, setAvatarSize] = useState("small");

  const getInitials = (fullName: any) => {
    //const allNames = fullName.trim().split(' ');
    //const initials = allNames.reduce((acc: any, curr: any, index: any) => {
    //  if (index === 0 || index === allNames.length - 1) {
    //    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    //  }
    //  return acc;
    //}, '');
    //return initials;
    if (fullName) return `${fullName[0].toLowerCase()}.`;
    else return "";
  };

  var stringToColour = function (str: any) {
    var colour = "#6097f6";
    if (str?.length) {
      colour = "#";
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xff;
        colour += ("00" + value.toString(16)).substr(-2);
      }
    }
    return colour;
  };

  useMemo(() => {
    let name = props.name ? props.name : "";
    let randomColorr = stringToColour(name);
    setRandomColor(randomColorr);

    setInitials(getInitials(name));
  }, [props.name]);

  useMemo(() => {
    setAvatarSize(props.avatarSize);
  }, [props.avatarSize]);

  return (
    <div
      className={`initials-avatar-container ${avatarSize}`}
      style={{
        backgroundColor: randomColor,
      }}
    >
      <div className={`initials-avatar ${avatarSize}`}>{initials}</div>
    </div>
  );
};

export default AvatarInitial;
