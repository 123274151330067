import {
  Card,
  Checkbox,
  FormLayout,
  RadioButton,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import {
  DEVICE_TYPE_MOBILE_AND_DESKTOP,
  DEVICE_TYPE_MOBILE_ONLY,
} from "../../../../constants/constants";
import DevicesType from "../../../../types/Devices.type";
import { objectDifference } from "../../../../utils/helper";

const MobileConfig = (props: any) => {
  const [initialData, setInitialData] = useState<DevicesType>(props.data);
  const [data, setData] = useState<DevicesType>(props.data);
  const [refData, setRefData] = useState<DevicesType>(props.data);

  const [
    activateErrorCheckForHeightOffset,
    setActivateErrorCheckForHeightOffset,
  ] = useState(false);
  const [activateErrorCheckForEdgeOffset, setActivateErrorCheckForEdgeOffset] =
    useState(false);
  const [
    activateErrorCheckForProductPageHeight,
    setActivateErrorCheckForProductPageHeight,
  ] = useState(false);

  const [updatedData, setUpdatedData] = useState({});

  useEffect(() => {
    setRefData(props.data);
    setData(props.data);
    setInitialData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (props.isDataSubmitted) {
      setRefData(data);
      setInitialData(data);
    }
  }, [props.isDataSubmitted]);

  useEffect(() => {
    var diffObj: any = objectDifference(refData, data);
    setUpdatedData({});
    if (Object.keys(diffObj).length !== 0) {
      switch (props.selected) {
        case DEVICE_TYPE_MOBILE_AND_DESKTOP:
        case DEVICE_TYPE_MOBILE_ONLY:
          setDataToUpdate(diffObj);
          break;
      }
    } else {
      setUpdatedData({});
    }
  }, [data]);

  useEffect(() => {
    let errorMessage = getErrorMessage();
    if (errorMessage) {
      props.onError(errorMessage, "mobile");
      props.onChanges(null);
    } else {
      props.onError("", "mobile");
      props.onChanges(updatedData);
    }
  }, [updatedData]);

  useEffect(() => {
    switch (props.selected) {
      case DEVICE_TYPE_MOBILE_AND_DESKTOP:
        props.onChanges(updatedData);
        break;
      case DEVICE_TYPE_MOBILE_ONLY:
        props.onChanges(updatedData);
        break;
    }
  }, [props.selected]);

  useEffect(() => {
    if (props.reset) {
      setRefData(initialData);
      setData(initialData);
      props.setHasChanges(false);
    }
  }, [props.reset]);

  const getErrorMessage = () => {
    if (!data.spinWheelPosition) {
      return "Invalid Spin The Wheel Position";
    } else if (validateVerticalOffset() !== false) {
      return "Invalid Height Offset";
    } else if (validateHorizontalOffset() !== false) {
      return "Invalid Edge Offset";
    } else if (validateProductOffset() !== false) {
      return "Invalid Product Height Offset";
    } else {
      return false;
    }
  };

  const setDataToUpdate = (diffObj: any) => {
    let deviceType = "mobile";
    if ("spinWheelPosition" in diffObj) {
      setUpdatedMobileData("deviceType", deviceType);
      setUpdatedMobileData("spinWheelPosition", diffObj.spinWheelPosition);
    }
    if ("wheelVerticalOffset" in diffObj) {
      setUpdatedMobileData("deviceType", deviceType);
      setUpdatedMobileData("wheelVerticalOffset", diffObj.wheelVerticalOffset);
    }
    if ("wheelHorizontalOffset" in diffObj) {
      setUpdatedMobileData("deviceType", deviceType);
      setUpdatedMobileData(
        "wheelHorizontalOffset",
        diffObj.wheelHorizontalOffset
      );
    }
    if ("productPageHeight" in diffObj) {
      setUpdatedMobileData("deviceType", deviceType);
      setUpdatedMobileData("productPageHeight", diffObj.productPageHeight);
    }
    if ("productPageOffset" in diffObj) {
      setUpdatedMobileData("deviceType", deviceType);
      setUpdatedMobileData("productPageOffset", diffObj.productPageOffset);
    }
  };

  const setUpdatedMobileData = (field: string, value: any) => {
    setUpdatedData((mData: any) => ({ ...mData, [field]: value }));
  };

  const handleButtonPositionChange = (value: string) => {
    setData({ ...data, spinWheelPosition: value });
  };

  const handleOffsetChange = (offsetType: string, value: string) => {
    if (offsetType === "vertical") {
      if (!activateErrorCheckForHeightOffset) {
        setActivateErrorCheckForHeightOffset(true);
      }
      setData({ ...data, wheelVerticalOffset: value });
    } else if (offsetType === "horizontal") {
      if (!activateErrorCheckForEdgeOffset) {
        setActivateErrorCheckForEdgeOffset(true);
      }
      setData({ ...data, wheelHorizontalOffset: value });
    }
  };

  const validateVerticalOffset = () => {
    // if(activateErrorCheckForHeightOffset) {
    if (data.wheelVerticalOffset && Number(data.wheelVerticalOffset) < 8) {
      return "Minimum offset is 8px";
    } else if (!data.wheelVerticalOffset) {
      return "Mobile height offset cannot be empty";
    }
    // }
    return false;
  };

  const validateHorizontalOffset = () => {
    // if(activateErrorCheckForEdgeOffset) {
    if (data.wheelHorizontalOffset && Number(data.wheelHorizontalOffset) < 8) {
      return "Minimum offset is 8px";
    } else if (!data.wheelHorizontalOffset) {
      return "Mobile edge offset cannot be empty";
    }
    // }
    return false;
  };

  const validateProductOffset = () => {
    // if(activateErrorCheckForProductPageHeight) {
    if (data.productPageHeight && Number(data.productPageOffset) < 8) {
      return "Minimum offset is 8px";
    } else if (data.productPageHeight && !data.productPageOffset) {
      return "Mobile height offset cannot be empty";
    }
    // }
    return false;
  };

  const renderMarkup = () => {
    switch (props.selected) {
      case DEVICE_TYPE_MOBILE_AND_DESKTOP:
      case DEVICE_TYPE_MOBILE_ONLY:
        return (
          <Card sectioned title="Mobile">
            <FormLayout>
              <FormLayout.Group>
                <div className="mt-1">
                  <TextContainer>
                    <p>Spin The Wheel Widget Position</p>
                  </TextContainer>
                  <Stack>
                    <RadioButton
                      label="Left"
                      checked={data.spinWheelPosition === "left"}
                      id="m-left"
                      name="btnpositionmobile"
                      onChange={() => handleButtonPositionChange("left")}
                    />
                    <RadioButton
                      label="Right"
                      id="m-right"
                      name="btnpositionmobile"
                      checked={data.spinWheelPosition === "right"}
                      onChange={() => handleButtonPositionChange("right")}
                    />
                  </Stack>
                  <TextContainer>
                    <TextStyle variation="subdued">
                      You can select whether to display your spin the wheel on
                      the right/left side of your website. You can also set the
                      position by configuring the right and left offset.
                    </TextStyle>
                  </TextContainer>
                </div>
              </FormLayout.Group>

              <FormLayout.Group>
                <TextField
                  label="HEIGHT offset"
                  type="number"
                  suffix="px"
                  value={data.wheelVerticalOffset}
                  onChange={(val) => handleOffsetChange("vertical", val)}
                  helpText={
                    "Set the height offset of the spin the wheel on mobile devices."
                  }
                  error={validateVerticalOffset()}
                />

                <TextField
                  label="EDGE offset"
                  type="number"
                  suffix="px"
                  value={data.wheelHorizontalOffset}
                  onChange={(val) => handleOffsetChange("horizontal", val)}
                  helpText={
                    "Set the distance of the spin the wheel from the edge of the mobile screen."
                  }
                  error={validateHorizontalOffset()}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card>
        );
    }
  };

  return <>{renderMarkup()}</>;
};

export default MobileConfig;
