import {
  BALANCE,
  OWNER_DETAILS,
  SHOP_DETAILS,
  SHOP_SETTINGS,
  SHOP_TIMINGS,
  UPGRADE,
} from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

export const getOwnerDetails = (userId: string) => {
  return httpClient.get(OWNER_DETAILS.replace("$userId", userId));
};

export const getShopDetails = () => {
  return httpClient.get(SHOP_DETAILS);
};

export const getBalance = (userId: string) => {
  return httpClient.get(BALANCE.replace("$userId", userId));
};
export const upgradeCappedAmount = (userId: string, amount: string) => {
  return httpClient.get(
    UPGRADE.replace("$userId", userId).replace("$amount", amount)
  );
};

export const getShopSettings = (userId: string) => {
  return httpClient.get(SHOP_SETTINGS.replace("$userId", userId));
};

export const updateShopSettings = (userId: string, params: any) => {
  return httpClient.post(SHOP_SETTINGS.replace("$userId", userId), params);
};

export const updateShopTimings = (userId: string, params: any) => {
  return httpClient.post(SHOP_TIMINGS.replace("$userId", userId), params);
};
