import { useEffect, useState } from "react";
import obj from "../../../content/Analytics/OverlaysContent/en.json";
import TooltipOverlay from "../../../common/Tooltip";
import GuideModal from "../../../common/GuideModal";
import WidgetAnalytics from "./WidgetAnalytics";
import SpinWheelAnalytics from "./SpinWheelAnalytics";
import AutomatedMessageReport from "./AutomatedMessageReport";
import OptIns from "./OptIns";
import { getMessageSettings } from "../../../redux/actions/analytics";
import { ChartActiveProps } from "./common.interface";
import BalanceUpdate from "../../../common/BalanceUpdate";
import { getBalance } from "../../../redux/actions/shop";
import { Page } from "@shopify/polaris";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";
import { SHOP_FEATURES } from "../../../constants/constants";
import { useShopStore } from '../../../hooks/shopStore';

function Analytics() {
  const { shopDetailsData } = useShopStore();
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [chartStatus, setChartStatus] = useState({} as ChartActiveProps);
  const [balance, setBalance]: any = useState({});

  let shop = shopDetailsData;

  useEffect(() => {
    const features = shopDetailsData.featuresList;
    if (features) {
      const showAnalytics = features.includes(SHOP_FEATURES.WIDGETS);
      showAnalytics && setShowAnalytics(true);
      if (shop && shop.userId) {
        getTotalBalance(shop.userId);
        getMessageSettings(shop.userId)
          .then((res: any) => {
            if (res && res.status === "success") {
              const settings: ChartActiveProps = res.settings;
              setChartStatus({ ...settings });
            }
          })
          .catch(console.log);
      }
    }
    clevertapEventPush({
      eventId: CT_EVENT_IDS.ANALYTICS_PAGE_VIEWED,
      eventData: {},
    });
  }, []);

  const getTotalBalance = (userId: any) => {
    getBalance(userId)
      .then((res: any) => {
        if (res.status === "success") {
          setBalance(res.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };

  return (
    <Page>
      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      ) : null}
      <div className="flexSpaceBetween" style={{ marginBottom: "10px" }}>
        <BalanceUpdate shop={shop} balance={balance} />
        <div className="flexCenterAlign" style={{ marginLeft: "10px" }}>
          <TooltipOverlay
            btn1Action={() => setOpenGuideModal(true)}
            obj={obj}
          />
        </div>
      </div>
      <div>
        {showAnalytics ? (
          <>
            <WidgetAnalytics />
            <SpinWheelAnalytics chartStatus={chartStatus} />
            <AutomatedMessageReport chartStatus={chartStatus} />
            <OptIns />
          </>
        ) : null}
      </div>
    </Page>
  );
}

export default Analytics;
