import { useHistory } from "react-router-dom";
import { Button } from "@shopify/polaris";
import PaymentFailedIcon from "../assets/icons/payment-failed.svg";
import moment from "moment";
import { PAYMENT_FAILED_PAGE } from "../constants/constants";

const PaymentFailed = (props: any) => {
  const history = useHistory();
  const failedOnDate = moment(Number(props?.failedOn)).format("DD MMMM YYYY");

  const goToWalletPage = () => {
    history.push("/wallet");
  };

  return (
    <div className="calloutCardContentContainer">
      <div className="display-flex-center">
        <img
          alt="Success Icon"
          src={PaymentFailedIcon}
          title="Guide"
          className="tooltip-svg-icon"
        />
      </div>
      <div className="display-flex-column-center">
        <h2 className="title-bold pad2rem">{PAYMENT_FAILED_PAGE.TITLE}</h2>
        <p className="pad2rem payment-failed-message">
          {PAYMENT_FAILED_PAGE.DECSP.replace("##FailedOnDate##", failedOnDate)}
        </p>

        <div className="button-design-new payment-button">
          <Button onClick={() => goToWalletPage()} primary>
            {PAYMENT_FAILED_PAGE.BUTTON}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
