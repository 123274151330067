// @ts-nocheck
import saveAs from 'file-saver';
import moment from 'moment';
const Excel = require('exceljs');

const getHeaders = (data: any) => {
  let headers: string[] = [
    'Phone Number',
    'Customer Name',
    'Number of Orders',
    'Total Order Value',
    'Abandoned Cart Value',
    'Optin',
    'Optin Date',
    'Optin Source',
  ];
  return headers;
};
const getFormatttedData = (data: any) => {
  let formattedData: any = [];
  data.forEach((o: any) => {
    let individualData: any = [
      o.phone,
      `${o.firstName || ''} ${o.lastName || ''}`,
      o.noOfOrders,
      o.totalOrderValue,
      o.lastAbCartValue,
      !!o.optedIn ? 'Opted-in' : 'Not opted-in',
      o.createdOn
        ? moment(new Date(parseInt(o.createdOn))).format(
            'MMMM Do YYYY, hh:mm a'
          )
        : '',
      o.source,
    ];
    formattedData.push(individualData);
  });
  return formattedData;
};

const addDatainExcelSheet = ({
  worksheet,
  data,
  isFormatedData,
  customHeaders,
}: any) => {
  let formattedData = isFormatedData ? data : getFormatttedData(data);

  let row = worksheet.addRow(customHeaders || getHeaders(data[0]));
  row.font = {
    family: 4,
    color: { argb: 'FF000000' },
    size: 10,
    underline: true,
    bold: true,
  };
  row.border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };
  worksheet.addRows(formattedData);
  return worksheet;
};

async function ExportToExcel(
  data: any,
  filename: any,
  extension: string = 'xlsx',
  isFormatedData = false,
  customHeaders: any = null
) {
  const workbook = new Excel.Workbook();
  let worksheet = workbook.addWorksheet(filename);
  worksheet.properties.outlineLevelCol = 2;
  worksheet.properties.defaultRowHeight = 15;
  worksheet = addDatainExcelSheet({
    worksheet,
    data,
    isFormatedData,
    customHeaders,
  });
  workbook[extension].writeBuffer().then(function (buffer: any) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${filename + new Date().getTime()}.${extension}`
    );
  });
}
export { ExportToExcel };
