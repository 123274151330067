// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import {
  Card,
  DataTable,
  Layout,
  Link,
  Pagination,
  Stack,
  TextStyle,
  Filters,
  ChoiceList,
  TextField,
  Button,
  Badge,
  Tag,
  Toast,
  Banner,
} from "@shopify/polaris";
import {
  getAllTemplates,
  getAllTags,
  handleFiltersOrdersCrm,
  addTagOrder,
  settings,
} from "../../../redux/actions/manual-messaging";
import { capitalize } from "../../../utils/common";
import styles from "../manual-message.module.scss";
import AddTagModal from "./AddTagModal";
import SendMessageModal from "./SendMessageModal";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";
import { getOrderURL } from "../../../utils/helper";
import { SEARCH } from "../../../constants/constants";
import HSMTemplateModal from "../../../common/HsmTemplates/TemplateSection";
import { getCustomTemplates } from "../../../redux/actions/custom-templates";
import { useHistory } from "react-router-dom";
import { useUsersContext } from "../../../context/usersContext";
import WabaNotProcuredModal from "../../../common/WabaNotProcuredModal";
import { updateOrderSettings } from "../../../redux/actions/crmOrders";
import { useShopStore } from '../../../hooks/shopStore';

function OrderCrm({
  owner,
  isCrmToolEnabled,
  showSuccess,
  setShowSuccess,
  agentPhone,
}: any) {
  const { shopDetailsData } = useShopStore();
  const [templateType, setTemplateType] = useState(null);
  const [status, setStatus] = useState(null);
  const [name, setName] = useState("");
  const [tmpName, setTmpName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [orders, setOrders] = useState<any>([]);
  const [pageNum, setPageNum] = useState(0);
  const [descending, setDescending] = useState(true);
  const [orderBy, setOrderBy] = useState("orderId");
  const pageSize = 10;
  const [noDataFound, setNoDataFound] = useState(false);
  const [allTemplatesAbCart, setAllTemplatesAbCart] = useState([]);
  const [tags, setTags] = useState<any[]>([]);
  const [currentCheckoutId, setCurrentCheckoutId] = useState("");
  const history = useHistory();
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [openAbandonedCartTagModal, setOpenAbandonedCartTagModal] =
    useState(false);
  const [openSendMessageModal, setOpenSendMessageModal] = useState(false);
  const [currentSelectedOrder, setCurrentSelectedOrder] = useState({});
  const [isWhatsappWebEnabled, setisWhatsappWebEnabled] = useState(true);
  const [isHsmTemplateModalOpen, setHsmTemplateModalOpen] = useState(false);
  const [fetchedTemplates, setFetchedTemplates] = useState([]);
  const { setViewChatPhone, mobileView } = useUsersContext();

  const fetchTemplates = async (userId) => {
    let data = await getCustomTemplates(userId);
    setFetchedTemplates(data.templates);
  };

  let shop = shopDetailsData;
  const [noWabaModalOpen, setNoWabaModalOpen] = useState(false);

  const openNoWabaModal = () => {
    setNoWabaModalOpen(true);
  };

  const closeNoWabaModal = () => {
    setNoWabaModalOpen(false);
  };
  useEffect(() => {
    if (shop && shop.userId) {
      loadMessageLogs(shop.userId, pageNum);
      fetchAllTemplates(shop.userId);
      fetchAllTags(shop.userId);
      getSettings(shop.userId);
      if (agentPhone) fetchTemplates(shop.userId);
    }
    clevertapEventPush({
      eventId: CT_EVENT_IDS.ORDERS_MANUAL_MESSAGING_PAGE_VIEWED,
      eventData: {},
    });
  }, []);

  useEffect(() => {
    loadMessageLogs(shop.userId, pageNum);
  }, [pageNum]);

  useEffect(() => {
    setPageNum(0);
    loadMessageLogs(shop.userId, pageNum);
  }, [name, templateType, orderId, orderBy, descending]);
  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const loadMessageLogs = (shopid: string, page_num: any) => {
    handleSearchFilters(shopid, page_num);
  };

  const getSettings = (userId: string) => {
    if (userId) {
      settings(userId).then((res: any) => {
        if (res.status === "success") {
          setisWhatsappWebEnabled(res.settings.whatsappWeb);
        }
      });
    }
  };

  const fetchAllTemplates = (userId: string) => {
    if (userId) {
      getAllTemplates(userId).then((res: any) => {
        if (res.status === "success" && res.template) {
          const templatesForAbCart = res.template.filter(
            (template: any) => template.category === "ORDER"
          );
          setAllTemplatesAbCart(templatesForAbCart);
        }
      });
    }
  };
  const fetchAllTags = (userId: string) => {
    if (userId) {
      getAllTags(userId).then((res: any) => {
        if (res.status === "success" && res.tags && res.tags.length !== 0) {
          const tagsForAbCart = res.tags.filter(
            (tag: any) => tag.category === "ORDER"
          );
          setTags(tagsForAbCart);
        }
      });
    }
  };
  const handleSearchFilters = (userId: string, page_num: number) => {
    if (userId) {
      handleFiltersOrdersCrm(
        userId,
        templateType,
        orderId,
        name,
        orderBy,
        descending,
        pageSize,
        page_num
      ).then((res: any) => {
        if (res.status === "success" && res.orders) {
          setOrders(res.orders);
          if (res.orders.length === 0) {
            setNoDataFound(true);
          } else {
            setNoDataFound(false);
          }
        }
      });
    }
  };
  const handleTemplateTypeChange = useCallback((value) => {
    setTemplateType(value);
  }, []);
  const handleStatusChange = useCallback((value) => {
    setStatus(value);
  }, []);
  const handleTempNameChange = (value: string) => {
    setTmpName(value);
  };
  const handleNameChange = () => {
    setName(tmpName);
  };
  const handleFiltersQueryChange = useCallback(
    (value) => setOrderId(value),
    []
  );
  const handleTemplateTypeRemove = useCallback(() => {
    setTemplateType(null);
  }, []);
  const handleStatusRemove = useCallback(() => {
    setStatus(null);
  }, []);
  const handleNameRemove = useCallback(() => {
    setName("");
    setTmpName("");
  }, []);

  const handleOrderIdRemove = useCallback(() => setOrderId(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleTemplateTypeRemove();
    handleStatusRemove();
    handleOrderIdRemove();
  }, [handleTemplateTypeRemove, handleStatusRemove, handleOrderIdRemove]);
  const getOptionsForTagsFilter = () => {
    const options = tags.map((template: any) => ({
      label: template.name,
      value: template.id,
    }));
    return options;
  };

  const filters = [
    {
      key: "templateType",
      label: "Tag",
      filter: (
        <ChoiceList
          title="Type"
          titleHidden
          choices={getOptionsForTagsFilter()}
          selected={templateType || []}
          onChange={handleTemplateTypeChange}
        />
      ),
      shortcut: true,
    },
    {
      key: "name",
      label: "Name",
      filter: (
        <>
          <TextField
            label="Search by name"
            value={tmpName}
            onChange={(val) => handleTempNameChange(val)}
            autoComplete="off"
            placeholder="eg. John Doe"
          />
          <div className={styles.searchFilter}>
            <Button primary onClick={() => handleNameChange()}>
              {SEARCH.BUTTON}
            </Button>
          </div>
        </>
      ),
      shortcut: true,
    },
    //TBD to enable it or not
    // {
    //   key: 'status',
    //   label: 'Status',
    //   filter: (
    //     <ChoiceList
    //       title="Status"
    //       titleHidden
    //       choices={[
    //         { label: 'PHONE_NO_NOT_FOUND_IN_REQUEST', value: 'PHONE_NO_NOT_FOUND_IN_REQUEST' },
    //         { label: 'AUTOMATED_MESSAGE_DISABLED', value: 'AUTOMATED_MESSAGE_DISABLED' },
    //       ]}
    //       selected={status || []}
    //       onChange={handleStatusChange}
    //       allowMultiple
    //     />
    //   ),
    //   shortcut: true,
    // },
  ];

  const appliedFilters: any = [];
  if (!isEmpty(templateType)) {
    const key = "templateType";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, templateType),
      onRemove: handleTemplateTypeRemove,
    });
  }
  if (!isEmpty(status)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status),
      onRemove: handleStatusRemove,
    });
  }
  if (!isEmpty(name)) {
    const key = "name";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, name),
      onRemove: handleNameRemove,
    });
  }
  function disambiguateLabel(key: any, value: any) {
    switch (key) {
      case "templateType":
        let label = tags.find((o) => o.id === Number(value));
        return value.map((val: any) => `${label.name}`).join(", ");
      case "status":
        return value.map((val: any) => `${val}`).join(",  ");
      case "name":
        return `Name - ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value: any) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  useEffect(() => {
    getRows();
  }, [orders]);

  const getMessageStatus = (
    status: string,
    fulfillmentStatus: string,
    paymentStatus: string,
    paymentDone = false
  ) => {
    let statusBadge;
    let fulfillmentBadge;
    let paymentStatusBadge;
    status = capitalize(status?.replace(/_/g, " "));
    fulfillmentStatus = capitalize(fulfillmentStatus?.replace(/_/g, " "));

    if (status === "Closed") {
      statusBadge = (
        <Badge progress="complete" status="success">
          {status}
        </Badge>
      );
    } else {
      statusBadge = <Badge progress="incomplete">{status}</Badge>;
    }
    if (fulfillmentStatus === "Fulfilled") {
      fulfillmentBadge = (
        <Badge progress="complete" status="attention">
          {fulfillmentStatus}
        </Badge>
      );
    } else {
      fulfillmentBadge = (
        <Badge progress="incomplete" status="attention">
          {fulfillmentStatus}
        </Badge>
      );
    }
    if (paymentDone) {
      paymentStatus = capitalize(paymentStatus?.replace(/_/g, " "));
      if (paymentStatus === "Paid") {
        paymentStatusBadge = (
          <Badge progress="complete" status="success">
            {paymentStatus}
          </Badge>
        );
      } else {
        paymentStatusBadge = (
          <Badge progress="incomplete">{paymentStatus}</Badge>
        );
      }
    }

    return (
      <>
        Order - {statusBadge}
        <div className="mt-1">Fulfillment - {fulfillmentBadge}</div>
        {paymentDone && (
          <div className="mt-1">Payment - {paymentStatusBadge}</div>
        )}
      </>
    );
  };

  const enableAutomation = () => {
    var params: any = new URLSearchParams();
    params.append("confirmationEnabled", true);
    updateOrderSettings(shop.userId, params);
    showToast("Automation Enabled");
  };

  const getAutomatedMessageStatus = (order: {}) => {
    const status = order.messageStatus ? order.messageStatus : "";
    return (
      <div style={{ color: "#6D7175" }}>
        {status === "SUBMITTED_TO_PROVIDER" ? (
          <div>
            {order?.messageType && (
              <p style={{ color: "#5EC127" }}>{order?.messageType}</p>
            )}
            <p>Sent at:</p>
            {getAttemptedDate(order.sentAt)}
          </div>
        ) : status === "AUTOMATED_MESSAGE_DISABLED" ? (
          <div>
            <p>Automation disabled</p>
            <p
              style={{
                color: "#5EC127",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => enableAutomation()}
            >
              Enable now
            </p>
          </div>
        ) : (
          <p>Failed</p>
        )}
      </div>
    );
  };

  const getMessageMarkup = (order: {}, checkoutId: any) => {
    if (order.messageManuallySent) {
      return viewChatMarkup(order);
    } else {
      return (
        <>
          <div className="d-flex-start">
            <div>
              <Button
                key={"enable"}
                primary
                onClick={() => {
                  //@ts-ignore
                  if (!order.phone) {
                    showErrorToast(
                      "Cannot send message because phone number is missing for this order"
                    );
                    return;
                  }
                  setCurrentCheckoutId(checkoutId);
                  setCurrentSelectedOrder({
                    ...order,
                    shop_name: owner?.shopName,
                    store_url: shop?.url,
                  });
                  if (isCrmToolEnabled) {
                    if (agentPhone) {
                      setHsmTemplateModalOpen(true);
                    } else {
                      openNoWabaModal();
                    }
                  } else {
                    if (allTemplatesAbCart.length > 0) {
                      setOpenSendMessageModal(true);
                    } else {
                      showErrorToast(
                        "Please add message templates from Settings Tab"
                      );
                      return;
                    }
                  }
                }}
              >
                Send Message
              </Button>
            </div>
          </div>
        </>
      );
    }
  };

  const getAttemptedDate = (date: number) => {
    if (date === 0) {
      return "-";
    } else {
      const optionsDate: any = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const optionsTime: any = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const convertedDate = new Date(Number(date));
      const formattedDate = convertedDate.toLocaleDateString(
        "en-US",
        optionsDate
      );
      const formattedTime = convertedDate.toLocaleString("en-US", optionsTime);
      return (
        <TextStyle variation="subdued">
          {formattedDate}
          <br />
          {formattedTime}
        </TextStyle>
      );
    }
  };

  const getAmountText = (amount: string, currency: string) => {
    if (currency) {
      return `${amount} ${currency}`;
    } else {
      return amount;
    }
  };
  const deleteTagFromOrder = (
    userId: string,
    checkoutId: any,
    tagId: any,
    de: any
  ) => {
    if (userId) {
      let params = new URLSearchParams();
      params.append("tagId", tagId);
      params.append("delete", de);
      addTagOrder(userId, checkoutId, params).then((res: any) => {
        if (res.status === "success") {
          showToast("Tag removed");
          loadMessageLogs(userId, 0);
        }
      });
    }
  };
  const getTags = (tagId: number, checkoutId: string) => {
    const result2 = tags.filter((tag: any) => tag.id === tagId);
    return (
      <>
        <div>
          {result2.length > 0 ? (
            <Tag
              key={checkoutId}
              onRemove={() =>
                deleteTagFromOrder(shop.userId, checkoutId, tagId, true)
              }
            >
              {result2[0].name}
            </Tag>
          ) : tags.length > 0 ? (
            <div className="d-flex-start">
              <div>
                <Button
                  key={"enable"}
                  primary
                  onClick={() => {
                    setCurrentCheckoutId(checkoutId);
                    setOpenAbandonedCartTagModal(true);
                  }}
                >
                  Add tag
                </Button>
              </div>
            </div>
          ) : (
            <p>Please add tags from Settings Tab</p>
          )}
        </div>
        <br />
      </>
    );
  };

  const viewChatMarkup = (order) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p>Sent</p>
        <p
          onClick={() => {
            history.push({
              pathname: `/chat`,
            });
            setViewChatPhone(order.phone);
          }}
          style={{
            textDecoration: "underline",
            color: "#5ec127",
            cursor: "pointer",
          }}
        >
          View Chat
        </p>
      </div>
    );
  };

  const getRows = () => {
    var rows = [];
    const localOrders = [...orders];
    if (localOrders && localOrders.length !== 0) {
      for (var i = 0; i < localOrders.length; i++) {
        rows.push([
          localOrders[i].customerName &&
          localOrders[i].customerName !== null ? (
            <div key={localOrders[i].id}>
              <Link
                external={true}
                url={getOrderURL(
                  shop,
                  localOrders[i].category,
                  localOrders[i].externalId
                    ? localOrders[i].externalId
                    : localOrders[i].entityId
                )}
              >
                {localOrders[i].name}
              </Link>
              <br />
              {localOrders[i].customerName}
            </div>
          ) : (
            <div key={localOrders[i].id}>
              <Link
                external={true}
                url={getOrderURL(
                  shop,
                  localOrders[i].category,
                  localOrders[i].externalId
                    ? localOrders[i].externalId
                    : localOrders[i].entityId
                )}
              >
                {localOrders[i].name}
              </Link>
            </div>
          ),
          getAttemptedDate(localOrders[i].createdOn),
          getAmountText(
            localOrders[i].total.toString(),
            localOrders[i].currency
          ),
          localOrders[i].paymentStatus
            ? getMessageStatus(
                localOrders[i].status,
                localOrders[i].fulfillmentStatus,
                localOrders[i].paymentStatus,
                true
              )
            : getMessageStatus(
                localOrders[i].status,
                localOrders[i].fulfillmentStatus,
                localOrders[i].paymentStatus,
                false
              ),

          getTags(localOrders[i].tagId, localOrders[i].id),
          getAutomatedMessageStatus(localOrders[i]),
          getMessageMarkup(localOrders[i], localOrders[i].id),
        ]);
      }
    }
    return rows;
  };
  const footerMarkup =
    orders && orders.length > 0 ? (
      <Stack vertical>
        <p>{"Page " + (pageNum + 1)}</p>
        <p>{"Showing " + orders.length + " results"}</p>
      </Stack>
    ) : null;

  const handleSort = (headingIndex: number, direction: string) => {
    direction === "descending" ? setDescending(true) : setDescending(false);
    if (headingIndex === 0) {
      setOrderBy("orderId");
    }
    if (headingIndex === 1) {
      setOrderBy("name");
    }
    if (headingIndex === 2) {
      setOrderBy("total");
    }
  };

  const crmLogsMarkup = (
    <Card>
      <Card.Section>
        <Filters
          queryValue={orderId}
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={handleOrderIdRemove}
          onClearAll={handleFiltersClearAll}
          hideQueryField={false}
          queryPlaceholder={"Search by id"}
        />
      </Card.Section>
      <DataTable
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
        ]}
        headings={[
          "Order",
          // "Name",
          "Date",
          "Amount",
          // "Type",
          "Status",
          "Tag",
          "Automated Message Status",
          "Message",
        ]}
        sortable={[true, false, true, false, false]}
        defaultSortDirection="descending"
        initialSortColumnIndex={0}
        hoverable={false}
        onSort={(index, direction) => handleSort(index, direction)}
        rows={getRows()}
        footerContent={
          <Stack vertical>
            {footerMarkup}
            <Pagination
              hasPrevious={pageNum > 0}
              onPrevious={() => {
                setPageNum((pageNum) => pageNum - 1);
              }}
              hasNext={orders?.length === pageSize}
              onNext={() => {
                setPageNum((pageNum) => pageNum + 1);
              }}
            />
          </Stack>
        }
      />
      <Card.Section>
        {noDataFound ? (
          <h1 className="Polaris-TextStyle text-center">No result found</h1>
        ) : null}
      </Card.Section>
    </Card>
  );

  return (
    <div>
      {toastMarkup}
      {errorToastMarkup}

      <div className="Polaris-Page mt-2">
        <div className="Polaris-Page-Header__TitleWrapper">
          <div className="Polaris-Header-Title__TitleAndSubtitleWrapper">
            {/* <div className="Polaris-Header-Title__TitleWithMetadataWrapper">
              <h1 className="Polaris-Header-Title">Orders Manual Messaging</h1>
            </div> */}
            <div className="Polaris-Header-Title__SubTitle-light Polaris-Header-Title__SubtitleCompact mt-2">
              <p>
                <b>Note:</b> Send manual messages faster by opening our app on
                your mobile phone browser, or by using WhatsApp Desktop app. Go
                to <b>Settings</b> tab to configure.
                <br />
              </p>
            </div>
          </div>
        </div>
        {/* <div className="Polaris-Page--divider"></div> */}
        <br />
      </div>
      <div className={styles.outerContainerTable}>
        {crmLogsMarkup}
        <br />
        <Layout.Section>
          <>
            <Banner
              title="We strongly recommend that you do not send more than 20-30 messages / hour"
              status="warning"
            >
              <p>
                This is to ensure that WhatsApp doesn't think you are engaging
                in spammy behaviour and ban your phone number.
                <br />
                The best practice is to send few messages at a time (10-20)
                distributed throughout the day.
              </p>
            </Banner>
            <Banner
              title="If you are using a new number, wait a few days before sending manual messages"
              status="warning"
            >
              <p>
                WhatsApp's spam detection algorithms are getting more aggressive
                day by day. If you activate a new number and suddenly start
                sending manual messages to unsaved contacts, they ban the
                number. Therefore, we recommend waiting a few days after
                activating a new number before you start sending messages.
              </p>
            </Banner>
          </>
        </Layout.Section>
        <AddTagModal
          open={openAbandonedCartTagModal}
          showToast={showToast}
          showErrorToast={showErrorToast}
          closeModal={() => setOpenAbandonedCartTagModal(false)}
          currentCheckoutId={currentCheckoutId}
          tags={tags}
          userId={shop.userId}
          loadMessageLogs={loadMessageLogs}
        />
        {isHsmTemplateModalOpen && (
          <HSMTemplateModal
            customerPhone={currentSelectedOrder.phone}
            userId={shop.userId}
            agentPhone={agentPhone}
            fetchedTemplates={fetchedTemplates}
            setHsmTemplateModalOpen={setHsmTemplateModalOpen}
            isHsmTemplateModalOpen={isHsmTemplateModalOpen}
            showSuccess={showSuccess}
            setShowSuccess={setShowSuccess}
            currentSelectedOrder={currentSelectedOrder}
            owner={owner}
            from={"CRM"}
            loadMessageLogs={() => {
              loadMessageLogs(shop.userId, pageNum);
            }}
          />
        )}
        <SendMessageModal
          open={openSendMessageModal}
          showToast={showToast}
          showErrorToast={showErrorToast}
          closeModal={() => setOpenSendMessageModal(false)}
          currentCheckoutId={currentCheckoutId}
          allTemplatesAbCart={allTemplatesAbCart}
          userId={shop.userId}
          loadMessageLogs={loadMessageLogs}
          currentSelectedOrder={currentSelectedOrder}
          owner={owner}
          isWhatsappWebEnabled={isWhatsappWebEnabled}
          platform={shop.platform}
        />
        <div />
      </div>
      <WabaNotProcuredModal
        open={noWabaModalOpen}
        close={closeNoWabaModal}
        mobileView={mobileView}
      />
    </div>
  );
}

export default OrderCrm;
