import { Checkbox, Modal, Select, TextField } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { updateTemplate } from "../../../../redux/actions/manual-messaging";
import { handleNewLineCharacters } from "../../../../utils/helper";
import { PLATFORM } from "../../../../constants/constants";

function EditTemplateModal({
  showToast,
  showErrorToast,
  open,
  closeModal,
  userId,
  template,
  fetchAllTemplates,
  setactivateViewTemplate,
  platform,
}: any) {
  const [openModal, setOpenModal] = useState(open);

  const [selectedTemplate, setSelectedTemplate] = useState({
    message: "",
    name: "",
    category: "",
    id: "",
    discount: true,
    discountRatio: "",
  });
  const [initialTemplate, setInitialTemplate] = useState({
    message: "",
    name: "",
    category: "",
    id: "",
    discount: true,
    discountRatio: "",
  });
  const [activateErrorCheckForName, setActivateErrorCheckForName] =
    useState(false);
  const [activateErrorCheckForContent, setActivateErrorCheckForContent] =
    useState(false);
  const [activateErrorCheckForDiscount, setActivateErrorCheckForDiscount] =
    useState(false);

  const categories = [
    { label: "Abandoned Cart", value: "CART" },
    { label: "Orders", value: "ORDER" },
  ];
  useEffect(() => {
    setSelectedTemplate(template);
  }, []);

  useEffect(() => {
    setOpenModal(open);
  }, [open, template]);

  const resetData = () => {
    setSelectedTemplate(initialTemplate);
    setActivateErrorCheckForContent(false);
    setActivateErrorCheckForName(false);
  };

  const validateForm = () => {
    let template = selectedTemplate;
    if (!template.name) {
      return "Template name can not be empty";
    } else if (!template.category) {
      return "Please select a category";
    } else if (!template.message) {
      return "Template message can not be empty";
    } else if (
      !template.discountRatio &&
      template.discount &&
      platform === PLATFORM.SHOPIFY
    ) {
      return "Disount value cannot be empty or 0";
    } else if (validateTemplateName() !== false) {
      return "Template name should be less than 50 characters";
    } else if (validateTemplateContent() !== false) {
      return "Template content should be less than 1000 characters";
    } else if (validateDiscountRatio() !== false) {
      return "Discount value cannot be 0";
    }
    return false;
  };

  const validateTemplateName = () => {
    if (activateErrorCheckForName) {
      let template = selectedTemplate;
      if (template.name.length > 50) {
        return "Template name should be less than 50 characters";
      }
      if (template.name.length <= 0) {
        return "Template name cannot be empty";
      }
    }
    return false;
  };
  const validateTemplateContent = () => {
    if (activateErrorCheckForContent) {
      let template = selectedTemplate;
      if (template.message.length > 1000) {
        return "Template content should be less than 1000 characters";
      }
      if (template.message.length <= 0) {
        return "Template content cannot be empty";
      }
    }
    return false;
  };

  const handleChange = (val: any, field: string) => {
    if (!activateErrorCheckForName && field === "name") {
      setActivateErrorCheckForName(true);
    }
    if (!activateErrorCheckForContent && field === "message") {
      setActivateErrorCheckForContent(true);
    }
    setSelectedTemplate((template: any) => ({ ...template, [field]: val }));
  };
  const validateDiscountRatio = () => {
    if (platform === PLATFORM.SHOPIFY) {
      if (activateErrorCheckForDiscount) {
        if (selectedTemplate.discount && !selectedTemplate.discountRatio) {
          return "Discount Value cannot be empty";
        }
        if (
          selectedTemplate.discount &&
          String(parseInt(selectedTemplate.discountRatio)) === "0"
        ) {
          return "Discount Value cannot be 0";
        }
      }
    }
    return false;
  };

  const handleDiscountChange = (newValue: any) => {
    if (!activateErrorCheckForDiscount) {
      setActivateErrorCheckForDiscount(true);
    }
    if (newValue <= 100 && newValue >= 0) {
      setSelectedTemplate((template: any) => ({
        ...template,
        discountRatio: newValue,
      }));
    }
  };
  const toggleOptinBehaviour = (name: string, value: boolean) => {
    setSelectedTemplate((template: any) => ({
      ...template,
      [name]: value,
    }));
  };

  const saveTemplate = (userId: string) => {
    let params = new URLSearchParams();
    let message = selectedTemplate.message;
    message = handleNewLineCharacters(message);
    if (selectedTemplate.name !== template.name) {
      params.append("name", selectedTemplate.name);
    }
    params.append("category", selectedTemplate.category);
    // @ts-ignore
    params.append("discount", selectedTemplate.discount);
    // @ts-ignore
    params.append("discountRatio", parseInt(selectedTemplate.discountRatio));
    params.append("message", message);

    let error = validateForm();
    if (error) {
      showErrorToast(error);
      return;
    } else {
      updateTemplate(userId, selectedTemplate.id, params).then(
        (response: any) => {
          if (response.status === "success") {
            setSelectedTemplate(initialTemplate);
            showToast("Template updated successfully");
            setActivateErrorCheckForContent(false);
            setActivateErrorCheckForName(false);
            fetchAllTemplates(userId);
            closeModal();
          } else {
            showErrorToast(response);
          }
        },
        (err) => {
          showErrorToast("Unable to update template");
        }
      );
    }
  };

  const getTemplateMarkup = () => {
    return (
      <>
        <TextField
          label="Name"
          value={selectedTemplate.name}
          onChange={(val) => {
            handleChange(val, "name");
          }}
          autoComplete="off"
          error={validateTemplateName()}
        />
        <br />
        <TextField
          label="Message"
          value={selectedTemplate.message}
          onChange={(val) => {
            handleChange(val, "message");
          }}
          multiline={3}
          autoComplete="off"
          error={validateTemplateContent()}
        />
        <br />

        <Select
          label="Select Category"
          options={categories}
          onChange={(val) => {
            handleChange(val, "category");
          }}
          placeholder="Select Category"
          value={selectedTemplate.category}
        />
        {platform === PLATFORM.SHOPIFY && (
          <>
            <br />
            <Checkbox
              label={
                "Create discount code (creates a unique code for each customer)"
              }
              checked={selectedTemplate.discount}
              onChange={(value) => toggleOptinBehaviour("discount", value)}
            />

            <br />
            <br />

            <TextField
              label="Discount value (1-100)"
              suffix="%"
              type="number"
              name="discount_percentage"
              disabled={!selectedTemplate.discount}
              value={selectedTemplate.discountRatio.toString()}
              error={validateDiscountRatio()}
              onChange={(val) => handleDiscountChange(val)}
            />
          </>
        )}
      </>
    );
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        resetData();
        closeModal();
        setOpenModal(false);
      }}
      title={"Edit Message Template"}
      primaryAction={{
        content: "Save",
        onAction: () => saveTemplate(userId),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            resetData();
            closeModal();
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>{getTemplateMarkup()}</Modal.Section>
    </Modal>
  );
}

export default EditTemplateModal;
