// @ts-nocheck
import { useState } from "react";
import cookie from "react-cookies";
import { DOMAIN } from "../constants/constants";
import Login from "../types/Login";

const useToken = () => {
  const getToken = () => {
    return cookie.load("access_token");
  };

  const saveToken = (userToken: Login | string) => {
    setToken(userToken);
  };

  const removeToken = () => {
    document.cookie =
      "access_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=" +
      DOMAIN;
    cookie.remove("access_token");
    localStorage.clear();
    getToken();
  };

  const [token, setToken] = useState(getToken());

  return {
    setToken: saveToken,
    token,
    removeToken,
    getToken,
  };
};

export default useToken;
