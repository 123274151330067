//@ts-nocheck
import { useCallback, useEffect, useState } from "react";
import { Tabs } from "@shopify/polaris";
import OpenOrders from "./OpenOrders";
import AbandonedCarts from "./AbandonedCarts";

const OrderComponent = (props: any) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabContent, setTabContent] = useState({
    0: (
      <div>
        <OpenOrders phone={props.phone} />
      </div>
    ),
    1: (
      <div>
        <AbandonedCarts phone={props.phone} />
      </div>
    ),
  });

  useEffect(() => {
    setTabContent({
      0: (
        <div>
          <OpenOrders phone={props.phone} />
        </div>
      ),
      1: (
        <div>
          <AbandonedCarts phone={props.phone} />
        </div>
      ),
    });
  }, [props.phone]);

  const [tabs, setTabs] = useState([
    {
      id: "Open Orders",
      content: "Open Orders",
      accessibilityLabel: "Open Orders",
      panelID: "OpenOrders",
    },
    {
      id: "Abandoned Carts",
      content: "Abandoned Carts",
      accessibilityLabel: "Abandoned Carts",
      panelID: "AbandonedCarts",
    },
  ]);

  const renderContent = () => {
    return tabContent[selectedTab];
  };

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelectedTab(selectedTabIndex);
  }, []);

  return (
    <div>
      <div className="w-100">
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
          {renderContent()}
        </Tabs>
      </div>
    </div>
  );
};

export default OrderComponent;
