import { FormLayout, Select, TextField, Tooltip } from "@shopify/polaris";
import Cancel from "../../../assets/images/Cancel";
import QuestionMark from "../../../assets/images/QuestionMark";
import TooltipContent from "./ToolTipContent";

const CallToActionSection = (props: any) => {
  const handleFormChange = (name: string, value: string) => {
    let data: any = [...props.callToAction];
    data[props.index][name] = value;

    if (
      name === "type" &&
      value === "URL" &&
      data[props.index].urlType === "DYNAMIC"
    )
      props.setCallToActionUrlType("DYNAMIC");
    else if (
      name === "type" &&
      value === "PHONE_NUMBER" &&
      props.callToActionCount === 1
    )
      props.setCallToActionUrlType(null);
    if (name === "urlType") props.setCallToActionUrlType(value);
    props.setCallToAction(data);
  };
  const handleRemoveActionButton = () => {
    let data: any = [...props.callToAction];
    data.splice(props.index, 1);
    props.setCallToAction(data);
    props.setCallToActionCount(props.callToActionCount - 1);
    if ((data.length && data[0].type != "URL") || !data.length)
      props.setCallToActionUrlType(null);
    props.option["CALL_TO_ACTION_BUTTON_TYPE"].map((item: any) => {
      item.disabled = false;
      return item;
    });
  };

  return (
    <div className="inline-input flex-box call-to-action">
      <h3>Call to actions {props.index + 1}</h3>
      <FormLayout.Group condensed>
        <div className="d-flex vertical-align-center">
          <div style={{ flexGrow: 1 }}>
            <Select
              value={
                props.callToAction[props.index]
                  ? props.callToAction[props.index].type
                  : ""
              }
              label=""
              placeholder="Select Type"
              options={props.option["CALL_TO_ACTION_BUTTON_TYPE"]}
              onChange={(val) => handleFormChange("type", val)}
              disabled={
                props.callToActionCount > 1 && props.index == 0 ? true : false
              }
            />
          </div>
          <Tooltip
            content={
              <TooltipContent fieldName={"call_to_action_button_type"} />
            }
          >
            <div className="d-flex">
              <QuestionMark />
            </div>
          </Tooltip>
        </div>
        <TextField
          maxLength={20}
          label=""
          value={
            props.callToAction[props.index]
              ? props.callToAction[props.index].name
              : ""
          }
          onChange={(val) => {
            handleFormChange("name", val);
          }}
          placeholder="Button Name"
          name="buttonName"
          error={props.formValueValidation(
            "buttonName",
            props.callToAction[props.index].name
          )}
        />
        {props.callToAction[props.index]?.type == "URL" && (
          <div className="d-flex vertical-align-center">
            <div style={{ flexGrow: 1 }}>
              <Select
                value={
                  props.callToAction[props.index]
                    ? props.callToAction[props.index].urlType
                    : ""
                }
                label=""
                placeholder="Select URL Type"
                options={props.option["CALL_TO_ACTION_BUTTON_URL_TYPE"]}
                onChange={(val) => handleFormChange("urlType", val)}
                disabled={
                  props.callToActionCount > 1 && props.index == 0 ? true : false
                }
              />
            </div>
            <Tooltip
              content={
                <TooltipContent fieldName={"call_to_action_button_url_type"} />
              }
            >
              <div className="d-flex">
                <QuestionMark />
              </div>
            </Tooltip>
          </div>
        )}
        <div className="flexCenter">
          <TextField
            type={
              props.callToAction[props.index]?.type === "PHONE_NUMBER"
                ? "number"
                : "url"
            }
            maxLength={100}
            label=""
            value={
              props.callToAction[props.index]
                ? props.callToAction[props.index].value
                : ""
            }
            onChange={(val) => {
              handleFormChange("value", val);
            }}
            placeholder="Button Value"
            name="buttonValue"
            error={props.formValueValidation(
              "buttonValue",
              props.callToAction[props.index].value
            )}
          />
          {props.callToActionUrlType === "DYNAMIC" &&
          props.callToAction[props.index]?.type === "URL" ? (
            <div className="call-to-action-str">{"{{1}}"}</div>
          ) : (
            ""
          )}
        </div>
      </FormLayout.Group>
      {/* <div className='call-action-close' onClick={handleRemoveActionButton}>
        <Cancel />
      </div> */}
    </div>
  );
};

export default CallToActionSection;
