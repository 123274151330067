import NudgeScreenNew from "../assets/images/nudgeScreenNew.svg";
import { useState } from "react";
import PlanModal from "../components/PlanModal/PlanModal";

const NudgeScreenContent = () => {
  const [showModal, setShowModal] = useState(false);
  let walletRenewalStatus = JSON.parse(
    localStorage.getItem("walletRenewalStatus") || "{}"
  );
  return (
    <>
      <div className="nudgeScreenWix_container">
        <div className="nudgeScreenWix_Imagecontainer">
          <img src={NudgeScreenNew} height={500} width={450} />
        </div>
        <div>
          <button
            onClick={() => setShowModal(true)}
            className="nudgeScreenWix_button"
          >
            {"View Details"}
          </button>
        </div>
      </div>
      <PlanModal
        showModal={showModal}
        setShowModal={setShowModal}
        walletRenewalStatus={walletRenewalStatus}
      />
    </>
  );
};

export default NudgeScreenContent;
