// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";

import {
  Card,
  Layout,
  Link,
  Modal,
  Select,
  Stack,
  TextContainer,
} from "@shopify/polaris";
import {
  addTagAbCartOrder,
  addTagOrder,
} from "../../../redux/actions/manual-messaging";
function AddTagModal({
  showToast,
  showErrorToast,
  open,
  closeModal,
  currentCheckoutId,
  tags,
  userId,
  loadMessageLogs,
}: any) {
  const [openModal, setOpenModal] = useState(open);
  //   const [selectedTag, setSelectedTag] = useState("");
  const [selected, setSelected] = useState("");

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  //   const [initialOrderData, setInitialOrderData] = useState({
  //     couponCode: "",
  //     discount: true,
  //     discountType: "FIXED",
  //     discountRatio: 10,
  //     language: "ENGLISH",
  //   });
  //   const [orderData, setOrderData] = useState({
  //     couponCode: "",
  //     discount: true,
  //     discountType: "FIXED",
  //     discountRatio: 10,
  //     language: "ENGLISH",
  //   });
  //   const [refOrderData, setRefOrderData] = useState({
  //     couponCode: "",
  //     discount: true,
  //     discountType: "FIXED",
  //     discountRatio: 10,
  //     language: "ENGLISH",
  //   });

  //   const [shop, setShop] = useState({
  //     id: "",
  //     url: "",
  //   });
  const [dataChanges, setDataChanges] = useState<any>({
    order: null,
    phone: null,
  });
  const [activateErrorCheckForCouponCode, setActivateErrorCheckForCouponCode] =
    useState(false);
  const [
    activateErrorCheckForhandleDiscountChange,
    setActivateErrorCheckForhandleDiscountChange,
  ] = useState(false);
  const getAbandonedCartSettingsMarkup = () => {
    return (
      <>
        {/* <Layout.AnnotatedSection
                      title="WhatsApp Configuration"
                      description={description}
                    >
                      <Stack vertical>
                      </Stack>
                    </Layout.AnnotatedSection> */}

        {/* <Layout.AnnotatedSection
                      title="Abandoned Cart Template"
                      description={'Ssup bro'}
                    > */}
        <Stack vertical>
          {cartRecoveryTemplateMarkup}
          {nudgeCheckoutOptinScrollSection}
        </Stack>
        {/* </Layout.AnnotatedSection> */}
      </>
    );
  };

  //   useEffect(() => {
  //     setInitialOrderData(abCartData);
  //     setRefOrderData(abCartData);
  //     setOrderData(abCartData);
  //   }, [abCartData]);

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  //   useEffect(() => {
  //     var diffObj: any = objectDifference(refOrderData, orderData);
  //     if (Object.keys(diffObj).length > 0) {
  //       setDataChanges((dataChanges: any) => ({
  //         ...dataChanges,
  //         order: diffObj,
  //       }));
  //     } else {
  //       setDataChanges((dataChanges: any) => ({ ...dataChanges, order: null }));
  //     }
  //   }, [orderData, refOrderData]);

  const resetData = () => {
    setDataChanges({
      order: null,
      phone: null,
    });
    // setRefOrderData(initialOrderData);
    // setOrderData(initialOrderData);
  };

  const handleOrderChange = (field: string, value: any) => {
    // setOrderData((data) => ({ ...data, [field]: value }));
  };
  //   const toggleDiscount = () => {
  //     setOrderData((data) => ({
  //       ...data,
  //       discount: !orderData.discount,
  //       discountType: COUPON_TYPE_PERCENT,
  //     }));
  //   };
  const handleDiscountTypeChange = (newValue: any) => {
    // setOrderData((data: any) => ({ ...data, discountType: newValue }));
  };

  const handleDiscountChange = (newValue: any) => {
    if (!activateErrorCheckForhandleDiscountChange) {
      setActivateErrorCheckForhandleDiscountChange(true);
    }
    if (newValue <= 100 && newValue >= 0) {
      //   setOrderData((data) => ({ ...data, discountRatio: newValue }));
    }
  };

  const handleCouponChange = (newValue: any) => {
    if (!activateErrorCheckForCouponCode) {
      setActivateErrorCheckForCouponCode(true);
    }
    // setOrderData((data) => ({ ...data, couponCode: newValue }));
  };

  const updateSettings = () => {
    if (!selected) {
      showErrorToast("Please select a tag");
      return;
    }
    let params = new URLSearchParams();
    params.append("tagId", selected);

    addTagOrder(userId, currentCheckoutId, params).then((response: any) => {
      if (response.status === "success") {
        showToast("Tag Added");
        setSelected("");
        closeModal();
        loadMessageLogs(userId, 0);
      } else {
        showErrorToast(response);
      }
    });
    // }
    setActivateErrorCheckForhandleDiscountChange(false);
    setActivateErrorCheckForCouponCode(false);
    closeModal();
  };
  const description = (
    <span>
      <p>
        <b>
          {
            "Automated WhatsApp messages are sent from our official WhatsApp Business API approved "
          }
        </b>
        <Link external={true} url={"ff"}>
          {"phone number"}
        </Link>
        {" using message templates approved by WhatsApp."}
        <br />
        <br />
        We include your{" "}
        <Link url={"gg"} external={true}>
          business name
        </Link>{" "}
        and{" "}
        <Link url={"gg"} external={true}>
          WhatsApp support number
        </Link>{" "}
        in every automated message. Your customers can recognise you and contact
        you easily if they need help.
      </p>
    </span>
  );
  const nudgeCheckoutOptinScrollSection = (
    <div id="nudgeCheckoutOptin_anchor">
      <Card></Card>
    </div>
  );
  const getOptionsForTags = () => {
    const optionss = tags.map((template: any) => ({
      label: template.name,
      value: template.id.toString(),
    }));
    return optionss;
  };

  const cartRecoveryTemplateMarkup = (
    <Card>
      <Card.Section>
        <TextContainer>
          <Select
            label=""
            value={selected}
            options={getOptionsForTags()}
            onChange={handleSelectChange}
            placeholder={"Select..."}
            helpText="Select a tag from list above"
            key={selected}
          />
        </TextContainer>
      </Card.Section>
    </Card>
  );
  return (
    <Modal
      open={openModal}
      onClose={() => {
        setSelected("");
        resetData();
        closeModal();
        setOpenModal(false);
      }}
      title={"Add tag"}
      primaryAction={{
        content: "Add",
        onAction: updateSettings,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setSelected("");
            resetData();
            closeModal();
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>{getAbandonedCartSettingsMarkup()}</Modal.Section>
    </Modal>
  );
}

export default AddTagModal;
