import { useHistory } from 'react-router-dom';
import { ReactComponent as BrandImage } from '../../../assets/images/brandImage.svg';

const WabaNotProcured = (props: any) => {
  const history = useHistory();
  return (
    <div
      className={`${
        props.mobileView.status ? 'chat__wabaNotProcured_mobile' : ''
      } ${props.from === 'config' ? `chat` : `chat wid80`}`}
    >
      <div className='chat__wabaNotProcured__container'>
        <BrandImage />
        <p className='chat__wabaNotProcured__TextCss'>
          You don't have a private Whatsapp Businss API account.
        </p>
        <p className='chat__wabaNotProcured__TextCss'>
          {' '}
          Let's create one & Start Messaging!
        </p>
        <div className='chat__hrGreyLineCss'></div>
        <button
          className='chat__wabaNotProcured__ButtonCss'
          type='button'
          onClick={() => {
            history.push('/private-waba');
          }}
        >
          Create WhatsApp Business API
        </button>
      </div>
    </div>
  );
};

export default WabaNotProcured;
