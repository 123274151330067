// @ts-nocheck
import { useState, useEffect } from 'react';
import {
  Page,
  Button,
  Stack,
  Card,
  TextStyle,
  TextContainer,
  ContextualSaveBar,
} from '@shopify/polaris';
import Switch from 'react-switch';
import '../index.css';
import { CONFIGURATIONSPAGE } from '../../../../constants/constants';
import {
  getWorkingHours,
  updateWorkingHours,
  updateAwayMessage,
  getAwayMessage,
} from '../../../../redux/actions/chats';
import { useUsersContext } from '../../../../context/usersContext';
import { useShopStore } from '../../../../hooks/shopStore';
import moment from 'moment';

function SetWorkingHours(props: any) {
  const { agentPhone } = useShopStore();
  const { showErrorToast } = useUsersContext();
  const [hasChanges, setHasChanges] = useState(false);
  const [awayMessageEnabled, setAwayMessageEnabled] = useState(false);
  const [awayMessage, setAwayMessage] = useState('');
  const [workingHours, setWorkingHours] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const DAYS = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
  ];

  const fetchWorkingHours = async () => {
    const res = await getWorkingHours(agentPhone);
    const wh = DAYS.map((day: any) => {
      const dayObject = res.filter((d: any) => {
        return d.day === day;
      });
      if (dayObject.length) {
        return {
          day: dayObject[0].day,
          start: `${dayObject[0].startHour}:${dayObject[0].startMinute}`,
          end: `${dayObject[0].endHour}:${dayObject[0].endMinute}`,
        };
      } else {
        return {
          day: day,
          start: `00:00`,
          end: `23:59`,
        };
      }
    });
    setWorkingHours(wh);
  };

  const fetchAwayMessage = async () => {
    const res = await getAwayMessage(agentPhone);
    setAwayMessage(res.awayMessage);
    setAwayMessageEnabled(res.awayMessageEnabled);
  };

  const reset = () => {
    fetchWorkingHours();
    fetchAwayMessage();
    setHasChanges(false);
  };

  useEffect(() => {
    if (agentPhone) {
      fetchWorkingHours();
      fetchAwayMessage();
    }
  }, [agentPhone]);

  const saveWorkingHours = async () => {
    const regExp = new RegExp(
      '^([0-9]|[0-1][0-9]|2[0-3]):([0-9]|[0-5][0-9])?$'
    );
    let errorFlag = false;
    const params = workingHours.map((wh) => {
      if (regExp.test(wh.start) !== true || regExp.test(wh.end) !== true) {
        errorFlag = true;
      }
      return {
        ...wh,
        startHour: wh.start.split(':')[0],
        startMinute: wh.start.split(':')[1],
        endHour: wh.end.split(':')[0],
        endMinute: wh.end.split(':')[1],
      };
    });
    if (errorFlag) {
      showErrorToast('Working hours must be in the format of hh:mm');
      reset();
      return;
    }

    const error = validateTime();
    if (error) {
      showErrorToast('End Time must be greater than Start Time');
      reset();
      return;
    }

    await props.runFunc(
      updateWorkingHours,
      params,
      'Working hours settings updated successfully.'
    );

    const data = {
      awayMessage,
      awayMessageEnabled,
    };
    await props.runFunc(
      updateAwayMessage,
      data,
      'Working hours settings updated successfully.'
    );

    reset();
  };

  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message='Unsaved changes'
      saveAction={{
        content: 'Save Settings',
        onAction: saveWorkingHours,
      }}
      discardAction={{
        onAction: () => {
          reset();
        },
      }}
    />
  ) : null;

  const renderAwayMessageUI = () => {
    return (
      <>
        <div className='topRow'>
          <div>
            <h3 className='sl-card-title' style={{ fontSize: '2.5rem' }}>
              {CONFIGURATIONSPAGE.SET_WORKING_HOURS.AWAY_MESSAGE.TITLE}
            </h3>
          </div>
          <br />
          <br />
          <div className='switch'>
            <Switch
              onChange={() => {
                setAwayMessageEnabled(!awayMessageEnabled);
                setHasChanges(true);
              }}
              checked={awayMessageEnabled}
              onColor='#5DC001'
              onHandleColor='#fff'
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
              activeBoxShadow='0px 0px 1px 6px rgba(0, 0, 0, 0.2)'
              height={20}
              width={48}
              handleDiameter={30}
              disabled={props.disabled}
            />
            <div className='switchValue'>
              <TextStyle variation='subdued'>
                {awayMessageEnabled ? 'ON' : 'OFF'}
              </TextStyle>
            </div>
          </div>
        </div>
        <br />
        <div className='sl-card-descp'>
          <TextContainer>
            <TextStyle variation='subdued'>
              {CONFIGURATIONSPAGE.SET_WORKING_HOURS.AWAY_MESSAGE.SUB_TITLE}
            </TextStyle>
          </TextContainer>
        </div>
        <br />
        {isEditMode ? (
          <textarea
            style={{
              width: '100%',
              marginTop: '10px',
              lineHeight: 'inherit',
              letterSpacing: '1px',
              padding: '10px',
              resize: 'none',
              fontSize: '17px',
            }}
            id='descript'
            rows={5}
            value={awayMessage}
            onChange={(e) => {
              setAwayMessage(e.target.value);
              setHasChanges(true);
            }}
            onBlur={() => {
              setIsEditMode(false);
            }}
            autoFocus={true}
            maxLength={1024}
          />
        ) : (
          <div
            className='sl-away-message'
            onClick={() => {
              awayMessageEnabled && setIsEditMode(true);
            }}
          >
            <p>{awayMessage}</p>
          </div>
        )}
      </>
    );
  };

  const setTime = (value: any, timeOfDay: any, dayObj: any) => {
    const newWorkingHours = workingHours.map((wh) => {
      if (wh.day === dayObj.day) {
        return {
          ...dayObj,
          [timeOfDay]: value,
        };
      } else return wh;
    });
    setWorkingHours(newWorkingHours);
    setHasChanges(true);
  };

  const validateTime = () => {
    let errorFlag = false;

    for (let wh of workingHours) {
      var timefrom = new Date();
      let temp = wh.start.split(':');
      timefrom.setHours((parseInt(temp[0]) + 24) % 24);
      timefrom.setMinutes(parseInt(temp[1]));

      var timeto = new Date();
      let temp1 = wh.end.split(':');
      timeto.setHours((parseInt(temp1[0]) + 24) % 24);
      timeto.setMinutes(parseInt(temp1[1]));

      if (moment(timeto).isSameOrBefore(timefrom)) {
        errorFlag = true;
      }

      const regX = new RegExp('^[:0]*$');
      if (regX.test(wh.start) && regX.test(wh.end)) {
        errorFlag = false;
      }

      if (errorFlag) return errorFlag;
    }
    return errorFlag;
  };

  const getCapitalString = (day: any) => {
    day = day.toLowerCase();
    return day[0].toUpperCase() + day.slice(1);
  };

  const WorkingHourComponent = () => {
    return (
      <>
        {workingHours.map((o: any, index: any) => {
          return (
            <div key={index}>
              <Stack vertical spacing='extraTight'>
                <p style={{ marginBottom: '5px' }}>{getCapitalString(o.day)}</p>
              </Stack>
              <Stack>
                <Stack vertical spacing='extraTight'>
                  <input
                    className='agent_assist_working_hours_input'
                    value={o.start}
                    onChange={(e) => {
                      setTime(e.target.value, 'start', o);
                    }}
                  ></input>
                </Stack>
                <Stack vertical spacing='extraTight'>
                  <p>{'To'}</p>
                </Stack>
                <Stack vertical spacing='extraTight'>
                  <input
                    className='agent_assist_working_hours_input'
                    value={o.end}
                    onChange={(e) => {
                      setTime(e.target.value, 'end', o);
                    }}
                  ></input>
                </Stack>
              </Stack>
              <br />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Page>
        {ctxSaveBarMarkup}
        <div className='flexSpaceBetween mb2'>
          <div className='Polaris-Page-Header__TitleWrapper'></div>
        </div>
        <div className='flexSpaceBetween createNewCampaign'>
          <div style={{ width: '75%' }}>
            <h1 className='Polaris-Header-Title'>
              {CONFIGURATIONSPAGE.SET_WORKING_HOURS.TITLE}
            </h1>
          </div>
        </div>
        <br />
        <p style={{ color: 'gray' }}>
          Set On/Off hours as per the availability of your agents and their
          Working hours.Time in 24h format (HHMM or hours minutes). Example -
          8:30AM is 08:30 and 8:30PM is 20:30. By default, hours are set from
          00:00 to 23:59 which means your agents are active all the time. If
          your agents are away for an entire day, set 00:00 to 00:00. The app
          follows your store's timezone.
        </p>

        <br />
        <br />
        {WorkingHourComponent()}
        <br />
        <div className=''>{renderAwayMessageUI()}</div>
      </Page>{' '}
    </>
  );
}

export default SetWorkingHours;
