import { ReactComponent as PlayCircleMajor } from '../../../../assets/icons/PlayCircleMajor.svg';

const VideoPreview = (props: any) => {
  return (
    <div>
      {props.from === 'chats' && (
        <div className='playButton'>
          {' '}
          <span className='material-icons'>
            <PlayCircleMajor />
          </span>
        </div>
      )}
      <video
        style={{
          width: '100%',
          cursor: 'pointer',
          objectFit: 'cover',
          borderRadius: '5px',
        }}
        src={URL.createObjectURL(props.mediaBlob)}
        width={props.width ? props.width : '250px'}
        height={props.height ? props.height : '350px'}
        onClick={() => props.setOpenModal(true)}
      />
    </div>
  );
};

export default VideoPreview;
