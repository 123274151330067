import { useState, useEffect } from 'react';

import {
  Stack,
  Card,
  DataTable,
  Layout,
  Button,
  Toast,
} from '@shopify/polaris';
import moment from 'moment';
import {
  getCampaigns,
  deleteScheduledCampaign,
} from '../../redux/actions/campaigns';
import { useHistory, useRouteMatch } from 'react-router-dom';
import EmptyListIcon from '../../assets/icons/empty_list.png';
import { clevertapEventPush, CT_EVENT_IDS } from '../../utils/clevertapUtils';
import LoadingUI from '../../common/LoadingUI';
import { LOADING_SPINNER, PLAN_ENGAGE_PLUS } from '../../constants/constants';
import DownloadCampaingReport from '../../common/ExportToCSVCampaignReport';
import { useShopStore } from '../../hooks/shopStore';
import { ReactComponent as DeleteLogo } from '../../assets/icons/deleteIcon.svg';
import DeleteScheduledCampaignModal from '../Modals/DeleteScheduledCampaignModal/DeleteScheduledCampaignModal';

export default function ListCampaigns(props: any) {
  const { shopDetailsData } = useShopStore();
  const [shopId, setShopId] = useState(null);
  const [userId, setUserId] = useState('');
  const [shopUrl, setShopUrl] = useState('');
  const [pipeLineStage, setPipelineStage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loading_results, setLoading_results] = useState(true);
  const [results, setResults] = useState([]);
  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [downloadLoading, setDownloadLoaidng] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [campaignDeleted, setCampaignDeleted] = useState(0);
  const history = useHistory();
  let { path } = useRouteMatch();
  const [shop, setShop] = useState({
    id: '',
    url: '',
    planId: '',
  });

  const [showErrorsToast, setShowErrorsToast] = useState({
    show: false,
    message: '',
  });

  const [campaignId, setCampaignId] = useState('');

  useEffect(() => {
    let shop = shopDetailsData;
    if (shop && shop.id) {
      setShopId(shop.id);
      setUserId(shop.userId);
    }
    if (shop && shop.url) {
      setShopUrl(shop.url);
    }
  }, []);

  useEffect(() => {
    if (props?.privateWabaDetails?.pipeLineStage) {
      setPipelineStage(props.privateWabaDetails.pipeLineStage);
    } else if (props?.privateWabaDetails?.pipelineStage) {
      setPipelineStage(props.privateWabaDetails.pipelineStage);
    }
  }, [props]);

  useEffect(() => {
    if (!userId) return;
    if (
      props.privateWabaDetails &&
      props.privateWabaDetails.pipeLineStage === 'FINALIZE'
    ) {
      setLoading(true);
      getCampaigns(userId).then((data: any) => {
        if (
          data &&
          data.status === 'success' &&
          data !== undefined &&
          data.campaigns
        ) {
          data.campaigns.sort((a: any, b: any) =>
            a.createdOn > b.createdOn ? -1 : a.createdOn < b.createdOn ? 1 : 0
          );
          setResults(data.campaigns);
        }
        let length = data?.campaigns?.length ? data.campaigns.length : 0;
        props.setEventTriggered({
          ...props.eventTriggered.current,
          TT: props.eventTriggered.current.TT + 1,
          length: length,
        });
        setLoading_results(false);
        setLoading(false);
      });

      // add a class to the root for css.
      document.body.className += 'optin-number-page';

      return () => {
        document.body.className = '';
      };
    } else {
      props.setEventTriggered({
        ...props.eventTriggered.current,
        TF: props.eventTriggered.current.TF + 1,
        length: 0,
      });
      setLoading_results(false);
      setLoading(false);
    }
  }, [userId, props.privateWabaDetails, campaignDeleted]);

  useEffect(() => {
    if (!userId) return;
    getCampaigns(userId).then((data: any) => {
      if (
        data &&
        data.status === 'success' &&
        data !== undefined &&
        data.campaigns
      ) {
        data.campaigns.sort((a: any, b: any) =>
          a.createdOn > b.createdOn ? -1 : a.createdOn < b.createdOn ? 1 : 0
        );
        setResults(data.campaigns);
        setLoading_results(false);
        setLoading(false);
        toggleLoading();
      }
    });
  }, [currentPageNum]);

  const handlePagination = async (num: any) => {
    setCurrentPageNum((old) => old + num);
    setLoading_results(true);
    setLoading(true);
  };

  const removeScheduledCampaign = async () => {
    try {
      const res: any = await deleteScheduledCampaign(userId, campaignId);
      if (res.status === 'success') {
        setShowErrorsToast({
          show: true,
          message: 'Scheduled campaign deleted successfully.',
        });
        setCampaignId('');
        setDeleteModalOpen(false);
        setCampaignDeleted(parseInt(campaignId));
      } else {
        setShowErrorsToast({
          show: true,
          message: 'Unable to delete scheduled campaign.',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const toggleLoading = () => {
    setLoading(!loading);
  };

  const errorToastMarkup = showErrorsToast.show ? (
    <Toast
      error
      content={showErrorsToast.message}
      onDismiss={() => {
        setShowErrorsToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const rows = results.map((o: any) => {
    return [
      <Stack vertical spacing='extraTight'>
        <p>{o.name}</p>
      </Stack>,
      <Stack vertical spacing='extraTight'>
        <p>
          {o.status === 'SCHEDULED' && o.scheduledAt
            ? moment(new Date(o.scheduledAt)).format('MMMM Do YYYY, hh:mm a')
            : moment(new Date(o.createdOn)).format('MMMM Do YYYY, hh:mm a')}
        </p>
      </Stack>,
      <Stack vertical spacing='extraTight'>
        <p>{o.status}</p>
      </Stack>,
      <Stack vertical spacing='extraTight'>
        <p>{`${o.size} ${o.size > 1 ? 'contacts' : 'contact'}`}</p>
      </Stack>,
      <Stack vertical spacing='extraTight'>
        <p className='flexCenter'>{o.optedIn ?? 0}</p>
      </Stack>,
      <Stack vertical spacing='extraTight'>
        <p className='flexCenter'>{o.delivered ?? 0}</p>
      </Stack>,
      <Stack vertical spacing='extraTight'>
        <p className='flexCenter'>{o.read ?? 0}</p>
      </Stack>,
      ...(Number(shopDetailsData.planId) === PLAN_ENGAGE_PLUS
        ? [
            <Stack vertical spacing='extraTight'>
              <p className='flexCenter'>{o.linksClicked ?? 0}</p>
            </Stack>,
          ]
        : []),
      <Stack vertical spacing='extraTight'>
        {o.status === 'SCHEDULED' ? (
          <div className='deleteLogoCampaign'>
            <DeleteLogo
              onClick={() => {
                setDeleteModalOpen(true);
                setCampaignId(o.id);
              }}
            />
          </div>
        ) : (
          <DownloadCampaingReport
            campaignId={o.id}
            setDownloadLoaidng={setDownloadLoaidng}
            setShowErrorsToast={setShowErrorsToast}
          />
        )}
      </Stack>,
    ];
  });

  return (
    <div className='optin-number-page' style={{ marginTop: '1%' }}>
      {downloadLoading && (
        <LoadingUI note={LOADING_SPINNER.CAMPAIGN_REPORT_DOWNLOAD} />
      )}
      {errorToastMarkup}
      <div style={{ marginBottom: '1rem' }} className='optin-number-page'></div>
      {loading_results || loading ? (
        <LoadingUI note={LOADING_SPINNER.CAMPAIGN_LIST_FETCH} />
      ) : !loading_results && rows?.length > 0 ? (
        <>
          {isDeleteModalOpen && (
            <DeleteScheduledCampaignModal
              showModal={isDeleteModalOpen}
              setShowModal={setDeleteModalOpen}
              removeScheduledCampaign={removeScheduledCampaign}
            />
          )}
          <Card>
            <DataTable
              columnContentTypes={[
                'text',
                'text',
                'text',
                'text',
                'text',
                'text',
                'text',
                'text',
                'text',
                'text',
              ]}
              headings={[
                'Campaign Name',
                'Date & Time',
                'Status',
                'Recepients',
                'Opted-In',
                'Delivered',
                'Read',
                ...(Number(shopDetailsData.planId) === PLAN_ENGAGE_PLUS
                  ? ['Links Clicked']
                  : []),
                'Reports',
              ]}
              rows={rows}
              // footerContent={
              //   <Stack vertical>
              //     <Pagination
              //       hasPrevious={currentPageNum > 0}
              //       onPrevious={() => handlePagination(-1)}
              //       hasNext={showNext}
              //       onNext={() => handlePagination(1)}
              //     />
              //   </Stack>
              // }
            />
          </Card>
        </>
      ) : (
        !loading_results && (
          <>
            {!loading && !props.privateWabaDetails ? (
              <Layout>
                <div style={{ padding: '45px 0' }}>
                  <div className='flexCenter'>
                    <img
                      src='https://cdn.shopify.com/s/files/1/0449/7794/6790/files/Screenshot_2022-01-07_112352_1mc.svg?v=1662704182'
                      width='200px'
                      alt='alternatetext'
                    ></img>
                  </div>
                  <br />
                  <div className='flexCenter helpText'>
                    You don't have a private Whatsapp account
                  </div>
                  <div className='flexCenter helpText'>
                    Let's create one & Start Messaging!
                  </div>
                  <div className='borderLine'></div>
                  <div className='flexCenter customButton'>
                    <Button onClick={() => history.push('/private-waba')}>
                      Create WhatsApp Business Account
                    </Button>
                  </div>
                </div>
              </Layout>
            ) : !loading &&
              props.privateWabaDetails &&
              pipeLineStage !== 'FINALIZE' ? (
              <Layout>
                <div style={{ padding: '45px 0' }}>
                  <div className='flexCenter'>
                    <img
                      src='https://cdn.shopify.com/s/files/1/0449/7794/6790/files/sand-clock-svgrepo-com_1timer.svg?v=1662704181'
                      width='100px'
                      alt='alternatetext'
                    ></img>
                  </div>
                  <br />
                  <div className='flexCenter helpText'>
                    Hold tight! your private WhatsApp business<br></br> account
                    is under review
                  </div>
                  <div className='borderLine'></div>
                  <div className='flexCenter customButton'>
                    <Button
                      onClick={() =>
                        history.push({
                          pathname: '/private-waba',
                          state: { modal: 'guide_modal' },
                        })
                      }
                    >
                      Read the best practices to get you account approved
                    </Button>
                  </div>
                </div>
              </Layout>
            ) : (
              !loading && (
                <Layout>
                  <div style={{ padding: '45px 0' }}>
                    <div className='flexCenter'>
                      <img
                        src={EmptyListIcon}
                        width='100px'
                        alt='alternatetext'
                      ></img>
                    </div>
                    <br />
                    <div className='flexCenter helpText'>
                      There are no marketing campaign created yet!
                    </div>
                    <div className='borderLine'></div>
                    <div className='flexCenter customButton'>
                      <Button
                        disabled={
                          props.limitReached ||
                          (props.balance &&
                            props.balance?.balanceUsed >=
                              props.balance?.cappedAmount) ||
                          props.disabled
                        }
                        onClick={() => {
                          clevertapEventPush({
                            eventId: CT_EVENT_IDS.CREATE_NEW_CAMPAIGN_CLICKED,
                            eventData: {},
                          });
                          history.push({
                            pathname: `${path}/create`,
                            state: { shop: shop },
                          });
                        }}
                      >
                        Create new Campaign
                      </Button>
                    </div>
                  </div>
                </Layout>
              )
            )}
          </>
        )
      )}
    </div>
  );
}
