import { Page, TextStyle, TextContainer } from "@shopify/polaris";
import Plans from "./Plans/index";
import Features from "./Features/index";
import FAQ from "./FAQ/index";
import MoreFeaturesIcon from "../../assets/icons/more-features.svg";
import "./pricing.scss";
import { useState, useEffect } from "react";
import { PLAN_NEW_FREE, PLATFORM } from "../../constants/constants";
import { clevertapEventPush, CT_EVENT_IDS } from "../../utils/clevertapUtils";
import { useHistory, useLocation } from "react-router-dom";
import { useShopStore } from '../../hooks/shopStore';

const Pricing = (props: any) => {
  const [showUpgradePlanDiv, setShowUpgradePlanDiv] = useState(false);
  const history = useHistory();
  const { shopDetailsData } = useShopStore();
  const searchParams = new URLSearchParams(useLocation().search)

  useEffect(() => {
    clevertapEventPush({
      eventId: CT_EVENT_IDS.PLANS_PAGE_VIEWED,
      eventData: {},
    });
  }, []);

  const scrollTo = (id: any) => {
    const divElement = document.getElementById(id);
    if (divElement) divElement.scrollIntoView({ behavior: 'smooth' });
    clevertapEventPush({
      eventId: CT_EVENT_IDS.VIEW_ALL_PLANS_CLICKED,
      eventData: {},
    });
  };

  if (searchParams.get('plan')) {
    scrollTo('ScrollToShowPlans');
  }

  const [upgradePlanButton, setUpgradePlanButton] = useState(false);
  let shop = shopDetailsData;

  return (
    <Page title=''>
      <div>
        <div className='pricing-heading-css'>
          <TextContainer spacing='tight'>
            <TextStyle variation='strong'>
              Grow your business fast, with our flexible and transparent pricing
              plans.
            </TextStyle>
            <div className='pricing-subHeading-css'>
              <TextContainer>
                <TextStyle variation='subdued'>
                  {' '}
                  Our premium plans come with a{' '}
                </TextStyle>
                <TextStyle variation='strong'>7-days free trial</TextStyle>.
                <TextStyle variation='subdued'>
                  {' '}
                  You can cancel or upgrade any time.
                </TextStyle>
              </TextContainer>
            </div>
          </TextContainer>
        </div>
        {showUpgradePlanDiv && (
          <div className='upgradePlanCss'>
            <div className='title'>
              <TextContainer>
                {shopDetailsData?.planId?.toString() === PLAN_NEW_FREE.toString()
                  ? "You are currently on FREE subscription plan. Upgrade to enjoy premium benefits and grow your business"
                  : "You're currently not on Engage plan. Upgrade to enjoy premium benefits and grow your business."}
              </TextContainer>
            </div>
            <button
              className='pricingButtonCss'
              type='button'
              onClick={() => {
                if ([PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)) {
                  history.push('/wallet');
                  return;
                }
                setUpgradePlanButton(true);
              }}
            >
              <span className='Polaris-Button__Content'>
                <span className='Polaris-Button__Text'>Upgrade</span>
              </span>
            </button>
          </div>
        )}

        <div className='Polaris-Stack'>
          <div className='Polaris-Stack__Item headingCss'>
            <div className='heading'>One subscription, more features</div>
            <div className='content'>
              Subscribe to our premium plans and enable access to premium
              features that allows to engage more customers and grow your
              business
            </div>
            <button
              className='buttonCss'
              type='button'
              onClick={() => scrollTo('ScrollToShowPlans')}
            >
              <span className='Polaris-Button__Content'>
                <span className='Polaris-Button__Text'>View all plans</span>
              </span>
            </button>
          </div>
          <div className='Polaris-Stack__Item UpgradeIconCss-box'>
            <div className=' d-flex-center'>
              <img
                src={MoreFeaturesIcon}
                alt='Upgrade to Premium Plans'
                className='UpgradeIconCss'
              />
            </div>
          </div>
        </div>
        <Features />
        <div id='ScrollToShowPlans'></div>
        <br />
        <br />
        <Plans
          page="Plans"
          planId={shopDetailsData?.planId}
          setPlanId={props.setPlanId}
          setShowUpgradePlanDiv={setShowUpgradePlanDiv}
          upgradePlanButton={upgradePlanButton}
          userId={shop.userId}
          recommendedPlanId={""}
          setRecommendedPlanPrice={() => {}}
          setRecommendedPlanName={() => {}}
          tc={true}
        />
        <br />
        <br />
        <FAQ platform={shop.platform} />
      </div>
    </Page>
  );
};

export default Pricing;
