import { useEffect, useState } from "react";
import {
  Card,
  Checkbox,
  Layout,
  Link,
  Modal,
  RadioButton,
  Select,
  Stack,
  TextContainer,
  TextField,
} from "@shopify/polaris";
import { objectDifference } from "../../../../utils/helper";
import {
  AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW,
  COD_TEMPLATE_DICT,
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_DUTCH,
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_TURK,
  TEMPLATE_LANGUAGE_RUSSIAN,
  nonOptinMessage,
} from "../../../../constants/constants";
import TemplateLanguageSettingMarkup from "../../../../common/TemplateLanguageSettingMarkup";
import { updateCODSettings } from "../../../../redux/actions/codSettings";
import { COUNTRY_CODES } from "../../../../constants/countryCodes";
import {
  clevertapEventPush,
  constants,
  CT_EVENT_IDS,
} from "../../../../utils/clevertapUtils";
import NonOptinMessageSettings from "../../../../common/NonOptinMessage";
import { useShopStore } from '../../../../hooks/shopStore';

const CashOnDeliveryModal = (
  {
    showToast,
    showErrorToast,
    open,
    closeModal,
    setCodLanguage,
    cashOnDeliveryData,
  }: any,
  props: any
) => {
  const [openModal, setOpenModal] = useState(open);
  const { shopDetailsData } = useShopStore();
  const [initialOrderData, setInitialOrderData] = useState({
    cancelOnCancelled: true,
    cancelOnNoResponse: true,
    cancelledTag: "",
    confirmationLanguage: "ENGLISH",
    confirmedTag: "",
    countryCode: "",
    email: "",
    emailEnabled: false,
    enabled: false,
    language: "HEBREW",
    noResponseTag: "",
    phone: "",
    phoneEnabled: false,
    nonOptinEnabled: true,
  });
  const [orderData, setOrderData] = useState({
    cancelOnCancelled: true,
    cancelOnNoResponse: true,
    cancelledTag: "",
    confirmationLanguage: "ENGLISH",
    confirmedTag: "",
    countryCode: "",
    email: "",
    emailEnabled: false,
    enabled: false,
    language: "HEBREW",
    noResponseTag: "",
    phone: "",
    phoneEnabled: false,
    nonOptinEnabled: true,
  });
  const [refOrderData, setRefOrderData] = useState({
    cancelOnCancelled: true,
    cancelOnNoResponse: true,
    cancelledTag: "",
    confirmationLanguage: "ENGLISH",
    confirmedTag: "",
    countryCode: "",
    email: "",
    emailEnabled: false,
    enabled: false,
    language: "HEBREW",
    noResponseTag: "",
    phone: "",
    phoneEnabled: false,
    nonOptinEnabled: true,
  });

  const [shopId, setShopId] = useState("");
  const [shop, setShop] = useState({
    id: "",
    url: "",
    userId: "",
  });
  const [dataChanges, setDataChanges] = useState<any>({
    cod: null,
  });
  const isPrivateWABAPresent = JSON.parse(
    localStorage.getItem("private_waba") || "{}"
  );

  useEffect(() => {
    let shop = shopDetailsData;
    if (shop && shop.id) {
      setShop(shop);
      setShopId(shop.id);
    }
    setOpenModal(open);
  }, [props, open]);

  useEffect(() => {
    setInitialOrderData(cashOnDeliveryData);
    setRefOrderData(cashOnDeliveryData);
    setOrderData(cashOnDeliveryData);
  }, [cashOnDeliveryData]);

  useEffect(() => {
    var diffObj: any = objectDifference(refOrderData, orderData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({ ...dataChanges, cod: diffObj }));
    } else {
      setDataChanges((dataChanges: any) => ({ ...dataChanges, cod: null }));
    }
  }, [orderData, refOrderData]);

  const resetData = () => {
    setDataChanges({
      cod: null,
    });
    setRefOrderData(initialOrderData);
    setOrderData(initialOrderData);
  };

  const validateCountryCode = () => {
    var cc = /^(?:[1-9]\d{0,5})$/;
    if (!orderData.countryCode || orderData.countryCode.match(cc)) {
      return false;
    } else if (orderData.countryCode && orderData.countryCode[0] === "0") {
      return "cannot start with 0";
    } else {
      return "enter a valid country code";
    }
  };

  const validatePhoneNo = () => {
    var phoneno = /^(?:[0-9]\d{0,14})$/;
    if (!orderData.phone || orderData.phone.match(phoneno)) {
      return false;
    } else {
      return "Enter a valid phone number";
    }
  };

  const validateEmail = () => {
    //eslint-disable-next-line
    var emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!orderData.email || orderData.email.match(emailRegex)) {
      return false;
    } else {
      return "Enter a valid email address";
    }
  };

  const updateSettings = () => {
    if (orderData.phoneEnabled) {
      if (!orderData.countryCode || validateCountryCode()) {
        showErrorToast("A valid country code must be given");
        return;
      }

      if (!orderData.phone || validatePhoneNo()) {
        showErrorToast("A valid phone number must be given");
        return;
      }
    }

    if (orderData.emailEnabled && (!orderData.email || validateEmail())) {
      showErrorToast("A valid email must be given");
      return;
    }

    if (!orderData.confirmedTag) {
      showErrorToast("Order Confirmed tag cannot be empty");
      return;
    }
    if (!orderData.cancelledTag) {
      showErrorToast("Order Cancelled tag cannot be empty");
      return;
    }
    if (!orderData.noResponseTag) {
      showErrorToast("No Response tag cannot be empty");
      return;
    }

    if (dataChanges.cod) {
      let params = new URLSearchParams();
      var cData: any = dataChanges.cod;

      Object.keys(cData).forEach((key) => {
        params.append(key, cData[key]);
      });

      updateCODSettings(shop.userId, params).then((response: any) => {
        if (response.status === "success") {
          showToast("Data Saved");
          setDataChanges({ ...dataChanges, cod: null });
          setInitialOrderData((data: any) => ({
            ...data,
            ...response.settings,
          }));
          if (Object.keys(cData).includes("language")) {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_LANGUAGE_CHANGED,
              eventData: {
                "Automated template message name": constants.cod,
                Language: cData.language,
                "Private waba":
                  localStorage.getItem("private_waba") === "true"
                    ? true
                    : false,
              },
            });
          }
          setRefOrderData((data: any) => ({ ...data, ...response.settings }));
          setOrderData((data: any) => ({ ...data, ...response.settings }));
          setCodLanguage(response.settings.language);
        } else {
          showErrorToast(response);
        }
      });
    }
    closeModal();
  };
  const description = (
    <span>
      <p>
        <b>
          {
            "Automated WhatsApp messages are sent from our official WhatsApp Business API approved "
          }
        </b>
        <Link external={true} url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}>
          {"phone number"}
        </Link>
        {" using message templates approved by WhatsApp."}
        <br />
        <br />
        We include your{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          business name
        </Link>{" "}
        and{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          WhatsApp support number
        </Link>{" "}
        in every automated message. Your customers can recognise you and contact
        you easily if they need help.
      </p>
    </span>
  );
  const nudgeCheckoutOptinScrollSection = (
    <div id="nudgeCheckoutOptin_anchor">
      <Card></Card>
    </div>
  );
  const handleLanguageChange = (newValue: any) => {
    return () => {
      handlecodChange("language", newValue);
    };
  };

  const handlecodChange = (field: string, value: any) => {
    setOrderData((data) => ({ ...data, [field]: value }));
  };

  const tagForCoDOrdersMarkup = (
    <div>
      <Card>
        <Card.Section>
          <TextField
            label="Order Confirmed tag"
            value={orderData.confirmedTag}
            onChange={(value) => {
              setOrderData((data) => ({ ...data, confirmedTag: value }));
            }}
          />
          <br />
          <TextField
            label="Order Cancelled tag"
            value={orderData.cancelledTag}
            onChange={(value) => {
              setOrderData((data) => ({ ...data, cancelledTag: value }));
            }}
          />
          <br />
          <TextField
            label="No Response tag"
            value={orderData.noResponseTag}
            onChange={(value) => {
              setOrderData((data) => ({ ...data, noResponseTag: value }));
            }}
          />
        </Card.Section>
      </Card>
      <br />
    </div>
  );

  const templateLanguageSettingMarkup = (
    <Card sectioned>
      <Stack vertical>
        <TextContainer>
          {
            "Message Language (all messages for COD will be sent in this language)"
          }
        </TextContainer>
        <Stack>
          <RadioButton
            label="English"
            checked={orderData.language === TEMPLATE_LANGUAGE_ENG}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ENG)}
            name="language"
          />
          <RadioButton
            label="Spanish"
            checked={orderData.language === TEMPLATE_LANGUAGE_SPAN}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_SPAN)}
            name="language"
          />
          <RadioButton
            label="Italian"
            checked={orderData.language === TEMPLATE_LANGUAGE_ITAL}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ITAL)}
            name="language"
          />
          <RadioButton
            label="Arabic"
            checked={orderData.language === TEMPLATE_LANGUAGE_ARAB}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ARAB)}
            name="language"
          />
          <RadioButton
            label="Hebrew"
            checked={orderData.language === TEMPLATE_LANGUAGE_HEBR}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_HEBR)}
            name="language"
          />
        </Stack>
      </Stack>
    </Card>
  );
  const handleCODConfirmationLanguageChange = (newValue: any) => {
    return () => {
      handlecodChange("confirmationLanguage", newValue);
    };
  };
  const getCodConfirmationPagePreivew = () => {
    switch (orderData.confirmationLanguage) {
      case TEMPLATE_LANGUAGE_ENG:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_page_preview_-_eng.png?v=1591794612";
      case TEMPLATE_LANGUAGE_SPAN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_page_preview_-_spa.png?v=1591794616";
      case TEMPLATE_LANGUAGE_ITAL:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_page_preview_-_ita.png?v=1591794616";
      case TEMPLATE_LANGUAGE_ARAB:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_page_preview_-_arabic.png?v=1591794611";
      case TEMPLATE_LANGUAGE_HEBR:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_page_preview_-_hebrew.png?v=1591794615";
      default:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_page_preview.png?v=1589532117";
    }
  };

  const codConfirmationLanguageMarkup = (
    <Card sectioned>
      <Stack vertical>
        <TextContainer>{"Order Confirmation Page Language"}</TextContainer>
        <Stack>
          <RadioButton
            label="English"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_ENG}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_ENG
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Portugese"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_PORT}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_PORT
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Spanish"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_SPAN}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_SPAN
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Italian"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_ITAL}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_ITAL
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="French"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_FREN}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_FREN
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Indonesian"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_INDO}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_INDO
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="German"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_GERM}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_GERM
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Arabic"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_ARAB}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_ARAB
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Turkish"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_TURK}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_TURK
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Hebrew"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_HEBR}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_HEBR
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Dutch"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_DUTCH}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_DUTCH
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Russian"
            checked={
              orderData.confirmationLanguage === TEMPLATE_LANGUAGE_RUSSIAN
            }
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_RUSSIAN
            )}
            name="confirmationLanguage"
          />
        </Stack>
        <Link
          external={true}
          url={
            "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/ordr-confirmation.png?v=1639563285"
          }
        >
          Preview
        </Link>
      </Stack>
    </Card>
  );
  const tag_for_cod_order_description = (
    <span>
      <p>
        Automatically add tags to your orders based on COD order status.{" "}
        <Link
          external={true}
          url="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_tags_preview.png?v=1589530683"
        >
          Preview
        </Link>{" "}
        how tags appear in your E-commerce manager.
      </p>
      <br />
      <p>
        No response tag - added if the customer does not confirm or cancel the
        order within 48 hours.
      </p>
      <br />
      <p>
        <b>Note</b> - If you have selected the "automatically cancel the order
        in E-commerce manager" checkbox, we also auto restock items from the
        cancelled order.
      </p>
    </span>
  );
  const settingForAutomaticOrderCancellation = (
    <Stack vertical>
      <Checkbox
        label="If an order is cancelled, automatically cancel the order in E-commerce manager"
        checked={orderData.cancelOnCancelled}
        onChange={(val) => {
          setOrderData((data) => ({
            ...data,
            cancelOnCancelled: val,
          }));
        }}
      />
      <Checkbox
        label="If an order has no response, automatically cancel the order in E-commerce manager"
        checked={orderData.cancelOnNoResponse}
        onChange={(val) => {
          setOrderData((data) => ({
            ...data,
            cancelOnNoResponse: val,
          }));
        }}
      />
    </Stack>
  );
  const cod_order_contact_description = (
    <p>
      Configure the language and contact options to display on the COD order
      confirmation page
    </p>
  );
  const codOrderContactMarkup = (
    <div>
      <Card>
        <Card.Section>
          <Checkbox
            label="Call"
            checked={orderData.phoneEnabled}
            onChange={(val) => {
              setOrderData((data) => ({ ...data, phoneEnabled: val }));
            }}
          />
          <Stack>
            <Stack.Item>
              <Select
                label={
                  <TextContainer>
                    Please select your country code.
                  </TextContainer>
                }
                options={COUNTRY_CODES}
                value={orderData.countryCode}
                error={validateCountryCode()}
                onChange={(value) => {
                  setOrderData((data: any) => ({
                    ...data,
                    countryCode: value,
                  }));
                }}
                disabled={!orderData.phoneEnabled}
              />
            </Stack.Item>
            <Stack.Item>
              <TextField
                label="Phone Number"
                value={orderData.phone}
                maxLength={15}
                placeholder={"Example: 9035026993"}
                error={validatePhoneNo()}
                onChange={(val) => {
                  setOrderData((data) => ({ ...data, phone: val }));
                }}
                disabled={!orderData.phoneEnabled}
              />
            </Stack.Item>
          </Stack>
        </Card.Section>
        <Card.Section>
          <Checkbox
            label="Email"
            checked={orderData.emailEnabled}
            onChange={(val) => {
              setOrderData((data) => ({ ...data, emailEnabled: val }));
            }}
          />
          <Stack>
            <TextField
              label="Email"
              type="email"
              value={orderData.email}
              error={validateEmail()}
              onChange={(val) => {
                setOrderData((data) => ({ ...data, email: val }));
              }}
              disabled={!orderData.emailEnabled}
            />
          </Stack>
        </Card.Section>
      </Card>
    </div>
  );

  const getAbandonedCartSettingsMarkup = () => {
    return (
      <>
        <Layout.AnnotatedSection
          title="WhatsApp Configuration"
          description={description}
        >
          <Stack vertical>
            <TemplateLanguageSettingMarkup
              language={orderData.language}
              onChange={(val: any) => {
                handlecodChange("language", val);
              }}
            ></TemplateLanguageSettingMarkup>
          </Stack>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title="Tags for COD Orders"
          description={tag_for_cod_order_description}
        >
          <Stack vertical>{tagForCoDOrdersMarkup}</Stack>
          <Card sectioned>{settingForAutomaticOrderCancellation}</Card>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title="COD Order Confirmation Page - Settings"
          description={cod_order_contact_description}
        >
          <Stack vertical>
            {codConfirmationLanguageMarkup}
            {codOrderContactMarkup}
            {nudgeCheckoutOptinScrollSection}
          </Stack>
        </Layout.AnnotatedSection>
        {isPrivateWABAPresent && (
          <NonOptinMessageSettings
            nonOptinMessage={nonOptinMessage.CASH_ON_DELIVERY}
            handleChange={handlecodChange}
            nonOptinMessageEnabled={orderData.nonOptinEnabled}
          />
        )}
      </>
    );
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        resetData();
        closeModal();
        setOpenModal(false);
      }}
      title={"Cash on Delivery Settings"}
      primaryAction={{
        content: "Save",
        onAction: updateSettings,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            resetData();
            closeModal();
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>{getAbandonedCartSettingsMarkup()}</Modal.Section>
    </Modal>
  );
};

export default CashOnDeliveryModal;
