import { MESSAGING } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

let config = {
  baseURL: MESSAGING.baseUrl,
};

export const getAbondonedCartSettings = (userId: string) => {
  return httpClient.get(
    MESSAGING.api.ABANDONED_CART_SETTINGS.replace("$userId", userId),
    config
  );
};

export const getOptinWidgetSettings = (userId: string) => {
  return httpClient.get(
    MESSAGING.api.OPTIN_WIDGET_SETTINGS.replace("$userId", userId),
    config
  );
};

export const automatedPhoneSettings = (userId: string) => {
  return httpClient.get(
    MESSAGING.api.AUTOMATED_PHONE_SETTINGS.replace("$userId", userId),
    config
  );
};

export const updateAbandonedCartSettings = (userId: string, params: any) => {
  return httpClient.post(
    MESSAGING.api.ABANDONED_CART_SETTINGS.replace("$userId", userId),
    params,
    config
  );
};

export const updateOptinWidgetSettins = (userId: string, params: any) => {
  return httpClient.post(
    MESSAGING.api.OPTIN_WIDGET_SETTINGS.replace("$userId", userId),
    params,
    config
  );
};

export const updatePhoneSettings = (userId: string, params: any) => {
  return httpClient.post(
    MESSAGING.api.AUTOMATED_PHONE_SETTINGS.replace("$userId", userId),
    params,
    config
  );
};
