// @ts-nocheck
import React, { createRef, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { TextStyle } from "@shopify/polaris";
import styles from "./wallet.module.scss";
import { getWalletStatement } from "../../redux/actions/wallet";
import { useShopStore } from '../../hooks/shopStore';

const DownloadWalletStatement = (props: any) => {
  const [data, setData] = useState([]);
  const { shopDetailsData } = useShopStore();
  const [readyToDownload, setReadyToDownload] = useState(false);
  const csvLinkEl = createRef<any>();
  const [headers, setHeaders] = useState<any>([]);

  const getFormatttedData = (data: any) => {
    let formattedData: any = [];
    data.forEach((row: any, index: any) => {
      // console.log(index);
      if (index === 0) {
        let headers = Object.keys(row).map((col: any) => {
          let colHeader = col.split("_");
          let label = colHeader
            .map((word: any) => {
              return word[0].toUpperCase() + word.substring(1);
            })
            .join(" ");

          return {
            label: label,
            key: col,
          };
        });
        setHeaders(headers);
      }

      let individualData = {} as any;
      Object.keys(row).forEach((key) => {
        individualData[key] = row[key];
      });

      formattedData.push(individualData);
    });
    return formattedData;
  };

  useEffect(() => {
    if (readyToDownload) {
      csvLinkEl.current.link.click();
      setReadyToDownload(false);
    }
  }, [readyToDownload]);

  const getWalletStatementData = async () => {
    let shop = shopDetailsData;
    let res: any = await getWalletStatement(shop.userId);
    try {
      if (res?.statement?.walletCharges?.length > 0) {
        const formattedData = getFormatttedData(res?.statement?.walletCharges);
        setData(formattedData);
        setReadyToDownload(true);
      } else {
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.wltBillingCard}>
      <div className={styles.wltBillingCardTitle}>
        <TextStyle variation="strong">{"Wallet Statement"}</TextStyle>
      </div>
      <div className={styles.wltBillingCardTxt}>
        <TextStyle variation="subdued">
          {"You  can download your Wallet statement of last month here"}
        </TextStyle>
      </div>
      <button
        className={styles.wltBillingBtn}
        onClick={() => getWalletStatementData()}
      >
        {"Download Statement"}
      </button>
      <CSVLink
        headers={headers}
        filename={`walletStatement_${Date.now()} .csv`}
        data={data}
        ref={csvLinkEl}
      />
    </div>
  );
};

export default DownloadWalletStatement;
