const OptinTemplatePreview = (props: any) => {
  const template = props.template;
  const custom = props.template.is_customizable ? props.custom : props.template;

  var bgColor1 = custom.bgColor1 || template.bg_color_1 || "#20802C";
  var bgColor2 = custom.bgColor2 || template.bg_color_2 || "#30BF42";

  var classNames =
    "wa-chat-bubble-floating-popup animated wa-optin-widget-z-index";
  var headerClassNames = " ";
  var headerStyle = {};

  switch (template.style_type) {
    case "wavy":
      headerClassNames += "wavy";
      break;
    case "intercom":
      classNames += " wa-intercom";
      break;
  }

  if (custom.is_optin_template_solid_background) {
    headerStyle = { background: bgColor1 };
  } else {
    if (template.gradient_type === "horizontal") {
      headerStyle = {
        backgroundImage: `linear-gradient(110.56deg, ${bgColor1} 0%, ${bgColor2} 100%)`,
      };
    } else {
      headerStyle = {
        backgroundImage: `linear-gradient(164.25deg, ${bgColor1} 18.04%, ${bgColor2} 81.96%)`,
      };
    }
  }

  var avatarStyle = { background: bgColor1 };
  var headStyle = { color: custom.buttonTextColor || "#ffffff" };
  var descStyle = { color: custom.descTextColor || "#ffffff" };

  return (
    <div id="wa-chat-bubble" className={`${classNames}`}>
      <div
        className={`wa-chat-bubble-header-common ${headerClassNames}`}
        style={headerStyle}
      >
        <div className="wa-chat-bubble-header-title" style={headStyle}>
          {"Hi there👋"}
        </div>
        <div className="wa-chat-bubble-header-desc" style={descStyle}>
          {
            "We are here to help you. Chat with us on WhatsApp for any queries.\nPlease provide your whatsapp number to start the chat"
          }
        </div>
      </div>
      <div className="wa-chat-bubble-chat whiteBG" id="wa-chat-bubble-chat">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div id="wa-chat-optin">
          <div className="wa-optin-widget-input-box wa-optin-preview-input-box">
            <span
              className="wa-optin-widget-country-flag"
              id="wa-splmn-country-flag-logo"
            ></span>
            <select
              className="wa-optin-widget-input input-country-code-select"
              id="wa-optin-country-code"
              autoComplete="off"
              disabled={true}
            >
              <option value="">+91</option>
            </select>
            <input
              className="wa-optin-widget-input"
              placeholder="99999"
              id="wa-optin-phone-number"
              autoComplete="off"
              type="tel"
              disabled={true}
            />
          </div>
          <div id="wa-optin-clickButton-container">
            <img
              alt=""
              src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/Optin-ChatWidget-Send_30_12_22.svg?v=1669788356"
              id="wa-optin-clickButton"
            />
          </div>
        </div>
        <div className="wa-chat-widget-footer">
          <span style={{ verticalAlign: "middle" }}>
            Powered by
            <span className="wa-chat-widget-footer-superlemon">
              {" "}
              SuperLemon
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default OptinTemplatePreview;
