// @ts-nocheck
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, FormLayout, TextField, Stack, Frame } from '@shopify/polaris';
import cookie from 'react-cookies';
import useCustomForm from '../../hooks/useCustomForm';
import {
  ACCOUNT_OAUTH,
  ACCOUNT_OAUTH_WIX,
  SERVER_ADDR,
} from '../../constants/api-constants';
import { SIGNUP_PAGE, LOGIN_PAGE } from '../../constants/login-constants';
import { getShopDetails, getOwnerDetails } from '../../redux/actions/shop';
import SuperlemonLogo from '../../assets/images/superlemon-logo_2x.png';
import LoginBannerWixShopify from '../../assets/images/login-banner-2-wixshopify.png';
import LoginBanner from '../../assets/images/login-banner-2.svg';
import ShopifyLogo from '../../assets/icons/shopify-login.png';
import GoogleLogo from '../../assets/icons/google-login.png';
import WhatsappLogo from '../../assets/icons/whatsapp-login.png';
import WixLogo from '../../assets/icons/wix-login.png';
import { whatsappAuth, redirectUser } from '../Login/whatsappLogin';
import CustomForm from '../../common/CustomForm';
import {
  getGoogleRedirectionUrl,
  user_signup,
  verify_otp,
} from '../../redux/actions/login';

const Signup = (props: any) => {
  const [fields] = useState({ shopId: '' });
  const [fieldsWix] = useState({ shopId: '' });
  const [token, setToken] = useState<string>();
  const [showWixLogin, setShowWixLogin] = useState(false);
  const [showShopifyLogin, setShowShopifyLogin] = useState(false);
  const [loginMethodClicked, setLoginMethodClicked] = useState(false);
  const [onlyOnce, setOnlyOnce] = useState(true);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const whatsappToken = searchParams?.get('token');
  const [userId, setUserId] = useState('');
  const [showOTP, toggleOTPshow] = useState(false);

  //useEffect(() => {
  //  if (whatsappToken && onlyOnce) {
  //    whatsappAuth(whatsappToken)
  //      .then((apiRes) => {
  //        if (apiRes.status) {
  //          fetchShopDetails();
  //          setOnlyOnce(false);
  //        }
  //      })
  //      .catch((err) => {
  //        console.log(err);
  //      });
  //  }
  //}, [whatsappToken]);

  const [toast, setToast] = useState({
    show: false,
    message: '',
  });

  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    if (token) {
      fetchShopDetails();
    }
  }, [token]);

  let winObj: any;
  const validateStoreId = () => {
    if (firstTime) {
      return false;
    }
    //eslint-disable-next-line
    var shopURLRegex =
      /^[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*[a-z0-9]{2,5}(:[0-9]{1,5})?$/;

    if (
      inputsShopify.shopId === '' ||
      inputsShopify.shopId === undefined ||
      !shopURLRegex.test(inputsShopify.shopId)
    ) {
      return 'Enter a valid store id';
    } else {
      return false;
    }
  };

  const validateStoreIdWix = () => {
    if (firstTime) {
      return false;
    }
    if (inputsWix.shopId === '' || inputsWix.shopId === undefined) {
      return 'Enter a valid store id';
    } else {
      return false;
    }
  };

  const openModal = () => {
    if (inputsShopify.shopId != null && inputsShopify.shopId.length > 0) {
      //eslint-disable-next-line
      var shopURLRegex =
        /^[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*[a-z0-9]{2,5}(:[0-9]{1,5})?$/;

      if (
        inputsShopify.shopId === '' ||
        inputsShopify.shopId === undefined ||
        !shopURLRegex.test(inputsShopify.shopId)
      ) {
        setToast({ show: true, message: 'Invalid shop name' });
        return;
      }

      var url = SERVER_ADDR + ACCOUNT_OAUTH + '?shop=' + inputsShopify.shopId;
      winObj = window.open(
        url,
        '_blank',
        'toolbar=no,scrollable=no,left=100,width=600,height=600'
      );

      let interval = setInterval(() => {
        if (winObj.closed) {
          props.setToken(cookie.load('access_token'));
          setToken(cookie.load('access_token'));
          clearInterval(interval);
        }
      }, 1000);
    }
  };

  const openModalWix = () => {
    if (inputsWix.shopId != null && inputsWix.shopId.length > 0) {
      //eslint-disable-next-line
      var shopURLRegex =
        /^[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*[a-z0-9]{2,5}(:[0-9]{1,5})?$/;

      if (
        inputsWix.shopId === '' ||
        inputsWix.shopId === undefined ||
        !shopURLRegex.test(inputsWix.shopId)
      ) {
        setToast({ show: true, message: 'Invalid shop name' });
        return;
      }

      var url = SERVER_ADDR + ACCOUNT_OAUTH_WIX + '?shop=' + inputsWix.shopId;
      winObj = window.open(
        url,
        '_blank',
        'toolbar=no,scrollable=no,left=100,width=600,height=600'
      );

      let interval = setInterval(() => {
        if (winObj.closed) {
          props.setToken(cookie.load('access_token'));
          setToken(cookie.load('access_token'));
          clearInterval(interval);
        }
      }, 1000);
    }
  };

  const openGoogleModal = async () => {
    const apiRes: any = await getGoogleRedirectionUrl();
    if (apiRes?.status === 'success' && apiRes?.redirectionUrl) {
      winObj = window.open(
        apiRes.redirectionUrl,
        '_blank',
        'toolbar=no,scrollable=no,left=100,width=600,height=600'
      );
    }

    let interval = setInterval(() => {
      if (winObj.closed) {
        props.setToken(cookie.load('access_token'));
        setToken(cookie.load('access_token'));
        clearInterval(interval);
      }
    }, 1000);
  };

  const fetchOwnerDetails = (shopId: string) => {
    getOwnerDetails(shopId).then(
      (response: any) => {
        if (response.status === 'success') {
          props.setOwner();
          props.history.push('/home');
          window.location.reload();
        }
      },
      (err) => {
        props.history.push('/home');
      }
    );
  };

  const fetchShopDetails = () => {
    getShopDetails().then((response: any) => {
      if (response.status === 'success') {
        fetchOwnerDetails(response.shop.userId);
        localStorage.setItem('currentPlan', response.shop.planId);
      }
    });
  };

  const { handleSubmitShopify, handleInputChangeShopify, inputsShopify } =
    useCustomForm(openModal, fields);

  const { handleSubmitWix, handleInputChangeWix, inputsWix } = useCustomForm(
    openModalWix,
    fieldsWix
  );

  const handleLoginClick = (loginMethod: any) => {
    setLoginMethodClicked(true);
    switch (loginMethod) {
      case 'WIX':
        setShowWixLogin(true);
        break;
      case 'EMAIL_PASSWORD':
        break;
      case 'GOOGLE':
        openGoogleModal();
        break;
      case 'SHOPIFY':
        setShowShopifyLogin(true);
        break;
      case 'WHATSAPP':
        redirectUser();
        break;
    }
  };

  const wixLoginMarkup = () => {
    return (
      <>
        <div className='login-content'>
          <div className='login text-center'>
            <Stack vertical>
              <img src={SuperlemonLogo} alt='Superlemon Logo' className='w-1' />
              <p className='strong fs-2 mt-2 mb-2'>
                {LOGIN_PAGE.WIX_LOGIN_PAGE.TITLE}
              </p>
              <Form onSubmit={handleSubmitWix}>
                <FormLayout>
                  <TextField
                    label={LOGIN_PAGE.WIX_LOGIN_PAGE.TITLE}
                    value={inputsWix.shopId}
                    onChange={(val) => {
                      handleInputChangeWix('shopId', val);
                      setFirstTime(false);
                    }}
                    error={validateStoreIdWix()}
                    placeholder={LOGIN_PAGE.WIX_LOGIN_PAGE.PLACEHOLDER}
                    align='center'
                    labelHidden={true}
                  ></TextField>
                  <button
                    className='Polaris-Button Polaris-Button--primary mt-1'
                    type='submit'
                  >
                    <span className='Polaris-Button__Content'>
                      <span className='Polaris-Button__Text'>
                        {LOGIN_PAGE.WIX_LOGIN_PAGE.BUTTON}
                      </span>
                    </span>
                  </button>
                </FormLayout>
              </Form>
            </Stack>
          </div>
        </div>
        <div className='login-image-div'>
          <img src={LoginBannerWixShopify} alt='' className='login-image'></img>
        </div>
      </>
    );
  };

  const shopifyLoginMarkup = () => {
    return (
      <>
        <div className='login-content'>
          <div className='login text-center'>
            <Stack vertical>
              <img src={SuperlemonLogo} alt='Superlemon Logo' className='w-1' />
              <p className='strong fs-2 mt-2 mb-2'>
                {LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.TITLE}
              </p>
              <Form onSubmit={handleSubmitShopify}>
                <FormLayout>
                  <TextField
                    label={LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.TITLE}
                    value={inputsShopify.shopId}
                    onChange={(val) => {
                      handleInputChangeShopify('shopId', val);
                      setFirstTime(false);
                    }}
                    error={validateStoreId()}
                    placeholder={LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.PLACEHOLDER}
                    align='center'
                    labelHidden={true}
                  ></TextField>
                  <button
                    className='Polaris-Button Polaris-Button--primary mt-1'
                    type='submit'
                  >
                    <span className='Polaris-Button__Content'>
                      <span className='Polaris-Button__Text'>
                        {LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.BUTTON}
                      </span>
                    </span>
                  </button>
                </FormLayout>
              </Form>
            </Stack>
          </div>
        </div>
        <div className='login-image-div'>
          <img src={LoginBannerWixShopify} alt='' className='login-image'></img>
        </div>
      </>
    );
  };

  const handleFormSubmit = async (data: any) => {
    const res: any = await user_signup(data);
    console.log(res);
    if (String(res.status) === 'success') {
      setUserId(res?.user?.id);
      toggleOTPshow(true);
    } else {
      console.log('Hello');
    }
  };

  const handleOTPinput = async (data: any) => {
    console.log('Check for Verification');
    const res = await verify_otp(userId, data);
    fetchShopDetails();
  };

  if (loginMethodClicked) {
    return (
      <Frame>
        <div className='login-container'>
          {showWixLogin && wixLoginMarkup()}
          {showShopifyLogin && shopifyLoginMarkup()}
        </div>
      </Frame>
    );
  } else {
    return (
      <Frame>
        <div className='login-container'>
          <div className='login-left'>
            <img
              src={SuperlemonLogo}
              alt='Superlemon Logo'
              className='sl-logo-login'
            />
            <img src={LoginBanner} alt='' className='login-image'></img>
          </div>
          {showOTP ? (
            <div className='login-right'>
              <p className='strong fs-2 mt-2 mb-2'>{SIGNUP_PAGE.OTP.TITLE}</p>
              <p>{SIGNUP_PAGE.OTP.SUB_TITLE}</p>
              <div className='signin-form'>
                <CustomForm
                  handleFormSubmit={handleOTPinput}
                  data={SIGNUP_PAGE.OTP}
                />
              </div>
            </div>
          ) : (
            <div className='login-right'>
              <p className='strong fs-2 mt-2 mb-2'>{SIGNUP_PAGE.TITLE}</p>
              <p>{SIGNUP_PAGE.SUB_TITLE}</p>
              <div className='signin-form'>
                {/*<CustomForm
                  handleFormSubmit={handleFormSubmit}
                  data={SIGNUP_PAGE}
                />
                <span className='hline'> {SIGNUP_PAGE.OR} </span>
                */}
                <div className='social-login-container-signup'>
                  <div
                    className='login-button-outline'
                    onClick={() => handleLoginClick('SHOPIFY')}
                  >
                    <div className='loginBtnIcon'>
                      <img
                        src={ShopifyLogo}
                        alt='Shopify Logo'
                        width={21}
                        height={21}
                      />
                    </div>
                    <div className='loginBtnText'>
                      {SIGNUP_PAGE.SHOPIFY_LOGIN.BUTTON}
                    </div>
                  </div>
                  {/*<div
                    className='login-button-outline'
                    onClick={() => handleLoginClick('GOOGLE')}
                  >
                    <div className='loginBtnIcon'>
                      <img
                        src={GoogleLogo}
                        alt='Google Logo'
                        width={21}
                        height={21}
                      />
                    </div>
                    <div className='loginBtnText'>
                      {SIGNUP_PAGE.GOOGLE_LOGIN.BUTTON}
                    </div>
                  </div>*/}
                </div>
                <div className='social-login-container-signup'>
                  {/*} <div
                    className='login-button-outline'
                    onClick={() => handleLoginClick('WHATSAPP')}
                  >
                    <div className='loginBtnIcon'>
                      <img
                        src={WhatsappLogo}
                        alt='Whatsapp Logo'
                        width={30}
                        height={30}
                      />
                    </div>
                    <div className='loginBtnText'>
                      {SIGNUP_PAGE.WHATSAPP_LOGIN.BUTTON}
                    </div>
                  </div>
                */}
                  <div
                    className='login-button-outline'
                    onClick={() => handleLoginClick('WIX')}
                  >
                    <div className='loginBtnIcon'>
                      <img
                        src={WixLogo}
                        alt='WiX Logo'
                        width={25}
                        height={25}
                      />
                    </div>
                    <div className='loginBtnText'>
                      {SIGNUP_PAGE.WIX_LOGIN.BUTTON}
                    </div>
                  </div>
                </div>
                <div className='signUp'>
                  {SIGNUP_PAGE.HAVE_ACC_LABEL}
                  &ensp;
                  <a href='/login' className='forgot-pass-link'>
                    {SIGNUP_PAGE.LOGIN_BTN_TEXT}
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </Frame>
    );
  }
};

export default Signup;
