import { useState } from 'react';
import PageMarkup from '../../common/PageMarkup';
import AllUsers from './AllUsers/AllUsers';
import UserGroups from './UserGroups/UserGroups';
import { useLocation } from 'react-router-dom';
import { SHOP_FEATURES, TUTORIAL_VIDEOS } from '../../constants/constants';
import { shopFeaturesFromStoreData } from '../../utils/helper';
import { useShopStore } from '../../hooks/shopStore';

const CustomersPage = ({
  selectedTabForCustomersPage,
  showPricingBanner,
}: any) => {
  const location: any = useLocation();
  const { shopDetailsData } = useShopStore();
  const [selected, setSelected] = useState<any>();

  if (location?.state?.tab === 'createUserGroup') {
    selectedTabForCustomersPage = 1;
  }
  const tabs = {
    0: {
      id: 'AllUsers',
      content: 'All Customers',
      accessibilityLabel: 'All Users',
      panelID: 'AllUsers',
    },
    ...(shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.CUSTOMERS_GROUP)
      ? {
          1: {
            id: 'UserGroups',
            content: 'Customer Groups',
            accessibilityLabel: 'User Groups',
            panelID: 'UserGroups',
          },
        }
      : {}),
  };

  const tabContent = {
    0: <AllUsers showPricingBanner={showPricingBanner}></AllUsers>,
    ...(shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.CUSTOMERS_GROUP)
      ? {
          1: <UserGroups showPricingBanner={showPricingBanner}></UserGroups>,
        }
      : {}),
  };

  const handleTabChange = (selected: number) => {
    setSelected(selected);
  };

  return (
    <>
      <PageMarkup
        title='Customers'
        tabs={Object.values(tabs)}
        tabContent={tabContent}
        selected={selectedTabForCustomersPage}
        tutorial
        tutorialSrc={
          selected
            ? TUTORIAL_VIDEOS.CUSTOMERS_PAGE[selected].videoSrc
            : TUTORIAL_VIDEOS.CUSTOMERS_PAGE[0].videoSrc
        }
        tutorialTitle={
          selected
            ? TUTORIAL_VIDEOS.CUSTOMERS_PAGE[selected].content
            : TUTORIAL_VIDEOS.CUSTOMERS_PAGE[0].content
        }
        handleTabChange={handleTabChange}
      ></PageMarkup>
    </>
  );
};
export default CustomersPage;
