// @ts-nocheck
import {
  Page,
  TextStyle,
  Button,
  Card,
  TextField,
  Checkbox,
} from "@shopify/polaris";
import styles from "./wallet.module.scss";
import { clevertapEventPush, CT_EVENT_IDS } from "../../utils/clevertapUtils";
import { getPaymentLink } from "../../redux/actions/wallet";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { WALLET_MODE_KEYS, WALLET_TAX } from "../../constants/constants";
import CustomForm from "../../common/CustomForm";
import { WALLET_BILLING_ADDRESS } from "../../constants/wallet-constants";

function AddBalance(props: any) {
  const { control, handleSubmit, formState, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      balance: "10",
      ...props.savedAddress,
    },
  });
  const { errors } = formState;

  const currentBalance = useWatch({
    control,
    name: "balance",
  });

  const getCalculatedValues = (mode: number) => {
    if (
      !currentBalance ||
      !/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?$/.test(currentBalance)
    ) {
      return null;
    }
    let stripedBalance =
      currentBalance[0] === "$"
        ? Number(currentBalance.substring(1))
        : Number(currentBalance);

    stripedBalance = Math.round(stripedBalance * 1e2) / 1e2;

    switch (mode) {
      case WALLET_MODE_KEYS.RECHARGE_AMOUNT:
        return "$" + stripedBalance;
      case WALLET_MODE_KEYS.ESTIMATED_TAX:
        return "$" + (stripedBalance * WALLET_TAX).toFixed(0);
      case WALLET_MODE_KEYS.TOTAL_AMOUNT:
        //return '$' + (stripedBalance * (1 + WALLET_TAX)).toFixed(0);
        return "$" + stripedBalance;
      default:
        return null;
    }
  };

  const isTextValid = (value: any) => {
    if (!value || !value.trim()) return "Cannot be empty";
    return true;
  };

  const isCurrencyValid = (value: any) => {
    if (!value || !value.trim()) return "Cannot be empty";
    else if (!/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?$/.test(value))
      return "Invalid Format, Please use numbers without decimal places only";
    else if (value < 10 || value > 10000)
      return "Amount must be between 10 and 10000";
    return true;
  };

  const validations = {
    text: isTextValid,
    currency: isCurrencyValid,
  };

  const getPaymentLinkCall = (data: any) => {
    let balance =
      data.balance[0] === "$" ? data.balance.substring(1) : data.balance;
    balance = Math.round(balance * 1e2) / 1e2;

    if (data.saveBillingAddress === undefined) data.saveBillingAddress = false;
    let params = new URLSearchParams();
    params.append("rechargeAmount", balance);
    Object.keys(data).forEach((key) => {
      if (key !== "balance") params.append(key, data[key]);
    });

    getPaymentLink(props.userId, params).then((response: any) => {
      if (response.paymentLink) {
        window.location.href = response.paymentLink;
      }
    });
  };

  const handleSubmitForm = (data: any) => {
    getPaymentLinkCall(data);
  };

  return (
    <Page
      title="Add Balance"
      primaryAction={
        <button
          className="flatGreenOutline_btn"
          onClick={() => props.setPage(0)}
        >
          Back
        </button>
      }
    >
      <div className={styles.wltByline}>
        <TextStyle variation="subdued">
          {
            "All your usage charges from Superlemon Product will be deducted from this wallet."
          }
        </TextStyle>
      </div>
      <div className={styles.wltBalanceContainer}>
        <div className={styles.wltBalanceLeft}>
          <Card sectioned>
            <TextStyle variation="strong">{"Enter Recharge Amount"}</TextStyle>

            <Controller
              name={"balance"}
              control={control}
              render={({ field }) => (
                <TextField label={""} type={"currency"} {...field} />
              )}
              rules={{
                validate: { required: validations.currency },
              }}
              key={"balance"}
            />
            {errors["balance"] && (
              <p className="customForm__ErrorMessage">
                {errors["balance"].message}
              </p>
            )}
            <br />

            <TextStyle variation="subdued">
              <li>
                {"Recharge value must be minimum $10, and maximum $10000"}
              </li>
              <li>
                {
                  "Recharge done in the wallet is non-transferrable and non-refundable"
                }
              </li>
            </TextStyle>
          </Card>
          <Card sectioned>
            <br />
            <TextStyle variation="strong">{"Billing Details"}</TextStyle>
            <br />
            <CustomForm
              data={WALLET_BILLING_ADDRESS}
              control={control}
              formState={formState}
              errors={errors}
            />
          </Card>
        </div>

        <div className={styles.wltBalanceRight}>
          <Card sectioned>
            <div className={styles.wltBalRightRowContainer}>
              <div className={styles.wltBalRightRow}>
                <div className={styles.wltBalRightAmountText}>
                  <TextStyle variation="subdued">{"Recharge Amount"}</TextStyle>
                </div>
                <div className={styles.wltBalRightAmountNumber}>
                  <TextStyle variation="subdued">
                    {getCalculatedValues(WALLET_MODE_KEYS.RECHARGE_AMOUNT)}
                  </TextStyle>
                </div>
              </div>

              {/*  <div className={styles.wltBalRightRow}>
                <div className={styles.wltBalRightAmountText}>
                  <TextStyle variation='subdued'>{'Estimated Tax'}</TextStyle>
                </div>
                <div className={styles.wltBalRightAmountNumber}>
                  <TextStyle variation='subdued'>
                    {getCalculatedValues(WALLET_MODE_KEYS.ESTIMATED_TAX)}
                  </TextStyle>
                </div>
              </div>*/}

              <div className={styles.wltBalRightRow}>
                <div className={styles.wltBalRightTotalText}>
                  <TextStyle variation="strong">{"Total"}</TextStyle>
                </div>
                <div className={styles.wltBalRightTotalNumber}>
                  <TextStyle variation="strong">
                    {getCalculatedValues(WALLET_MODE_KEYS.TOTAL_AMOUNT)}
                  </TextStyle>
                </div>
              </div>

              <div
                className={styles.wltBalRightButton}
                onClick={handleSubmit(handleSubmitForm)}
              >
                <Button primary fullWidth>
                  {"Pay Now"}
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Page>
  );
}

export default AddBalance;
