import { useEffect, useState } from "react";
import PageMarkup from "../../../common/PageMarkup";
import AutomatedTemplates from "./Automated/Automated";
import EmailLogs from "./EmailLogs/EmailLogs";
import { useShopStore } from '../../../hooks/shopStore';

const EmailTemplates = () => {
  const { shopDetailsData } = useShopStore();
  let shop = shopDetailsData;

  const tabs = [
    {
      id: "Templates",
      content: "Templates",
      accessibilityLabel: "Templates",
      panelID: "templates",
    },
    {
      id: "Email Logs",
      content: "Logs",
      accessibilityLabel: "Email Logs",
      panelID: "emailLogs",
    },
  ];

  const tabContent = {
    0: <AutomatedTemplates userId={shop.userId} shopUrl={shop.url} />,
    1: <EmailLogs userId={shop.userId} shopUrl={shop.url} />,
  };

  return (
    <>
      {shop.userId && (
        <PageMarkup
          title="Email"
          tabs={tabs}
          tabContent={tabContent}
          selected={0}
        ></PageMarkup>
      )}
    </>
  );
};
export default EmailTemplates;
