// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { ReactComponent as UploadIcon } from '../../assets/icons/uploadIcon.svg';
import { ReactComponent as XCircleIcon } from '../../assets/icons/XCircle.svg';

const DragAndDropImportChats = (props: any) => {
  const formats = ['json'];
  const [fileSelected, setFileSelected] = useState('');

  const drop = React.useRef(null);
  useEffect(() => {
    drop.current.addEventListener('dragover', handleDragOver);
    drop.current.addEventListener('drop', handleDrop);
  }, []);

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const files = [...e.dataTransfer.files];

    // check if some uploaded file is not in one of the allowed formats
    if (
      formats &&
      files.some(
        (file) =>
          !formats.some((format) =>
            file.name.toLowerCase().endsWith(format.toLowerCase())
          )
      )
    ) {
      props.setErrorToast(
        `Only following file formats are acceptable: ${formats.join(', ')}`
      );
      return;
    }

    if (files && files.length) {
      props.setSelectedFile(files[0]);
      setFileSelected(files[0]);
    }
  };

  const changeHandler = (event: any) => {
    props.setSelectedFile(event.target.files[0]);
    setFileSelected(event.target.files[0]);
  };

  const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const getBytes = (bytes) => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (
      (!bytes && '0 Bytes') ||
      (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sufixes[i]
    );
  };

  return (
    <div ref={drop} className='FilesDragAndDrop__area'>
      {fileSelected ? (
        <div className='chat__mediaPreview_importChat_container'>
          <div className='chat__mediaPreview_importChat_name'>
            <p>
              {'Selected file: '} <span>{fileSelected.name}</span>
            </p>

            <p style={{ color: '#6D7175', fontSize: '1.4rem' }}>
              {getBytes(fileSelected.size)}
            </p>
          </div>
          <XCircleIcon
            className='chat__mediaPreview_importChat_xcircle'
            onClick={() => {
              props.setSelectedFile('');
              setFileSelected('');
            }}
          />
        </div>
      ) : (
        <>
          <UploadIcon />
          <p style={{ color: '#6D7175', fontWeight: '600', fontSize: '2rem' }}>
            Drag & Drop files here
          </p>
          <p style={{ color: '#6D7175', fontWeight: '400', fontSize: '2rem' }}>
            or
          </p>
          <input
            type='file'
            id='importChat-file-input'
            name='file'
            onChange={changeHandler}
            accept={'.json'}
            hidden
          />
          <label
            className='importChat-file-button'
            htmlFor='importChat-file-input'
          >
            Browse Files
          </label>
        </>
      )}
    </div>
  );
};

export default DragAndDropImportChats;
