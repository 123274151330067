// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { ReactComponent as UploadIcon } from '../../assets/icons/uploadIcon.svg';
import { ReactComponent as XCircleIcon } from '../../assets/icons/XCircle.svg';
import ShowImage from '../../components/AgentAssist/Chat/ChatWindow/ShowImage';
import { invalidMediaFileSizeAndType } from '../../utils/common';
import { SAMPLE_MEDIA_FILE, options } from '../../constants/constants';

const DragAndDropTemplate = (props: any) => {
  const [fileSelected, setFileSelected] = useState(false);

  const drop = React.useRef(null);
  useEffect(() => {
    if (drop) {
      drop.current.addEventListener('dragover', handleDragOver);
      drop.current.addEventListener('drop', handleDrop);
    }
  }, [drop]);

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const validate = (files) => {
    const uploadedFile = files[0];
    if (uploadedFile) {
      const resMessage = invalidMediaFileSizeAndType(
        props.templateType,
        uploadedFile.type,
        uploadedFile.size
      );
      if (resMessage?.error) {
        props.showErrorToast(resMessage.message);
        props.setSelectedFile('');
        setFileSelected('');
        return;
      }
      return uploadedFile;
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const files = [...e.dataTransfer.files];
    const uploadedFile = validate(files);
    if (uploadedFile) {
      props.setSelectedFile(uploadedFile);
      setFileSelected(uploadedFile);
    }
  };

  const changeHandler = (event: any) => {
    const uploadedFile = validate(event.target.files);
    if (uploadedFile) {
      props.setSelectedFile(uploadedFile);
      setFileSelected(uploadedFile);
    }
  };

  const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const getBytes = (bytes) => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (
      (!bytes && '0 Bytes') ||
      (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sufixes[i]
    );
  };

  return (
    <>
      {fileSelected ? (
        <div className='chat__mediaPreview_container'>
          <div style={{ width: '100px', height: '70px' }}>
            <ShowImage
              mediaFile={fileSelected}
              width={'100px'}
              height={'70px'}
              from='dragAndDrop'
            />
          </div>
          <div className='chat__mediaPreview_name'>
            <p>{fileSelected.name}</p>
            <p style={{ color: '#6D7175' }}>{getBytes(fileSelected.size)}</p>
          </div>
          <XCircleIcon
            className='chat__mediaPreview_xcircle'
            onClick={() => {
              props.setSelectedFile('');
              setFileSelected('');
            }}
          />
        </div>
      ) : (
        <div ref={drop} className='FilesDragAndDrop__area'>
          <UploadIcon />
          <input
            type='file'
            id='templateChat-file-input'
            name='file'
            accept='image/png, image/jpeg, image/jpg, video/mp4, application/pdf'
            onChange={changeHandler}
            hidden
          />
          <label
            className='templateChat-file-button'
            htmlFor='templateChat-file-input'
          >
            Browse Files
          </label>
        </div>
      )}
      {!fileSelected && (
        <>
          <div className='fileTypeNote'>
            {props.templateType === options.TYPE[2].value && (
              <span>{SAMPLE_MEDIA_FILE.imageFileLimit}</span>
            )}
            {props.templateType === options.TYPE[3].value && (
              <span>{SAMPLE_MEDIA_FILE.videoFileLimit}</span>
            )}
            {props.templateType === options.TYPE[4].value && (
              <span>{SAMPLE_MEDIA_FILE.documentFileLimit}</span>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DragAndDropTemplate;
