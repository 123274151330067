import httpClient from "../../utils/http-client";
import {
  CUSTOMER_SEGMENTATION,
  MESSAGING,
  OPTIN_ADMIN,
} from "../../constants/api-constants";

let config = {
  baseURL: MESSAGING.baseUrl,
};

export const exportNumbers = (userId: any, data: any) => {
  return httpClient.post(
    MESSAGING.api.EXPORT_NUMBERS.replace("$userId", userId),
    data,
    config
  );
};

export const getNumbers = (userId: any, pageNumber: any, pageSize: any) => {
  return httpClient.get(
    CUSTOMER_SEGMENTATION.api.GET_CUSTOMERS_BY_PAGE.replace("$userId", userId)
      .replace("$pageSize", pageSize)
      .replace("$pageNumber", pageNumber),
    {
      baseURL: CUSTOMER_SEGMENTATION.baseUrl,
    }
  );
};

export const getCustomerOptins = (userId: any) => {
  return httpClient.get(
    CUSTOMER_SEGMENTATION.api.GET_CUSTOMER_OPTINS.replace("$userId", userId),
    {
      baseURL: CUSTOMER_SEGMENTATION.baseUrl,
    }
  );
};

export const getTotalNumbers = (userId: any) => {
  return httpClient.get(
    MESSAGING.api.GET_TOTAL_NUMBERS.replace("$userId", userId),
    config
  );
};

export const fetchOptinAdminDetails = (userId: string) => {
  return httpClient.get(OPTIN_ADMIN.replace("$userId", userId));
};

export const applyOptin = (userId: string) => {
  return httpClient.post(OPTIN_ADMIN.replace("$userId", userId));
};
