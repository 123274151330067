import {
  WALLET_RECHARGE_LINK,
  WALLET_BALANCE,
  WALLET_RENEWAL,
  WALLET_INVOICE,
  WALLET_STATEMENT,
  WALLET_ADDRESS,
} from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

export const getPaymentLink = (userId: string, params: any) => {
  return httpClient.post(
    WALLET_RECHARGE_LINK.replace("$userId", userId),
    params
  );
};

export const getWalletInfo = (userId: string, params: any) => {
  return httpClient.post(WALLET_BALANCE.replace("$userId", userId), params);
};

export const getWalletRenewalInfo = (userId: string) => {
  return httpClient.get(WALLET_RENEWAL.replace("$userId", userId));
};

export const getWalletAddress = (userId: string) => {
  return httpClient.get(WALLET_ADDRESS.replace("$userId", userId));
};

export const setWalletAddress = (userId: string, params: any) => {
  return httpClient.post(WALLET_ADDRESS.replace("$userId", userId), params);
};

export const getWalletStatement = (userId: string) => {
  return httpClient.get(WALLET_STATEMENT.replace("$userId", userId));
};

export const getWalletInvoice = (userId: string) => {
  return httpClient.get(WALLET_INVOICE.replace("$userId", userId));
};
