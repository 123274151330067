import { Heading, Modal, Stack, TextContainer } from "@shopify/polaris";

const Release_22_03_01_Modal = (props: any) => {
  const modalContentGuideStep = () => {
    return (
      <TextContainer>
        <p className="release-heading ">
          <Heading>1. SuperLemon Extension Update!!!</Heading>
        </p>
        <p>
          The new and revamped SuperLemon chrome extension is here!
          <br />
          <br />
          Pro and Ultimate plan users will be able to leverage the all-new
          powerful and revamped SuperLemon extension loaded with all new
          features.
          <br />
          <br />
          The upgraded SuperLemon chrome extension includes:
          <ul>
            <li>Revamped All Orders</li>
            <li>New Abandoned Cart Section added</li>
            <li>Search Functionality for All Orders and Abandon Cart</li>
            <li>Tags for Orders</li>
            <li>Filters</li>
            <li>New Card layout</li>
            <li>Multilingual (coming soon)</li>
            <li>Android Mobile app of extension (coming soon)</li>
          </ul>
        </p>
        <img
          className="checkoutOptinModalImageMoreWidth"
          src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/ext32.png?v=1646387543"
          alt="Extension"
        />
        <img
          className="checkoutOptinModalImageMoreWidth mt-3"
          src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/Release-extension_img_2.png?v=1646635540"
          alt="Extension Guide Reference"
        />
        <p>
          You can also access the same guide under the Templates &gt; Whatsapp
          Extension section.
        </p>
      </TextContainer>
    );
  };

  const handleClose = () => {
    props.closeModal();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
      title={"Release - 22.03.01"}
      secondaryActions={[
        {
          content: "Close",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>{modalContentGuideStep()}</Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
export default Release_22_03_01_Modal;
