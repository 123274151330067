// @ts-nocheck
import {
  Button,
  Checkbox,
  FormLayout,
  Layout,
  Link,
  Modal,
  Page,
  RadioButton,
  Select,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
  Icon,
  Popover,
  ButtonGroup,
} from '@shopify/polaris';
import { CircleCancelMinor } from '@shopify/polaris-icons';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createCampaign,
  uploadCampaignMedia,
  getCampaignTemplates,
  downloadFileForSegment,
  createCampaignSegment,
  scheduleCampaignSegment,
  scheduleCampaignFile,
} from '../../redux/actions/campaigns';
import WhatsappContainer from '../../common/WhatsappContainer';
import FileUploadPage from '../../common/FileUploader';
import GenericImageToast from '../../common/GenericImageToast';
import { getAllSegment } from '../../redux/actions/customerSegmentation';
import { ExportToExcel } from '../../common/ExportToExcel';
import {
  inbuildVariables,
  inbuildVariables_custom,
  variableKeysConst,
} from '../OptinNumber/utils';
import ErrorModal from './ErrorModal/ErrorModal';
import {
  campaignConsentErrorMessage,
  errorCreateCampaign,
  learnMoreCreateCompaign,
  RUN_CAMPAIGN_MEDIA_FILE,
  options,
  SAMPLE_MEDIA_FILE,
  LOADING_SPINNER,
  PLATFORM,
  NEW_PRICING_CREATE_CAMPGN_PAGE,
  AGENT_ASSIST_TEMPLATES,
  PLAN_ENGAGE_PLUS,
} from '../../constants/constants';
import { invalidMediaFileSizeAndType } from '../../utils/common';
import InsufficientBalModal from '../InsufficientBalModal/insufficientBalModal';
import LoadingUI from '../../common/LoadingUI';
import HSMTemplateModal from '../../common/HsmTemplates/TemplateSection';
import { DateTimePicker } from './dateTimePicker/dateTimePicker';
import { useShopStore } from '../../hooks/shopStore';

const CreateNewCampaign = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const currentSelectedTemplateId = props.currentSelectedTemplateId?.templateId;
  const history = useHistory();
  const location = useLocation();
  const mediaFileTemplateTypes = [
    options.TYPE[2].value,
    options.TYPE[3].value,
    options.TYPE[4].value,
  ];
  const [activateErrorCheckForName, setActivateErrorCheckForName] =
    useState(false);
  const [activateErrorCheckForContent, setActivateErrorCheckForContent] =
    useState(false);

  const [shop, setShop] = useState({
    id: '',
    url: '',
    userId: '',
    platform: '',
  });

  const [campaignDetails, setcampaignDetails] = useState({
    name: '',
    body: '',
    file: '',
    recipientType: 'segment',
    segment: '',
    consent: 'false',
  });
  const [toast, setToast] = useState({ show: false, message: '' });
  const [errorToast, setErrorToast] = useState({ show: false, message: '' });
  const [openTutorial, setopenTutorial] = useState(false);
  const [openScheduleCampaign, setOpenScheduleCampaign] = useState(false);
  const [isHsmTemplateModalOpen, setHsmTemplateModalOpen] = useState(false);
  const [templateId, setTemplateId] = useState<any>(
    currentSelectedTemplateId || ''
  );
  const [templates, setTemplates]: any = useState([]);
  const [messageTemplate, selectedMessageTemplate]: any = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState();
  const [variablesFile, setVariablesFile]: any = useState();
  const [isCustomeVariable, setIsCustomeVariable]: any = useState(false);
  const [userGroupList, setUserGroupList] = useState([
    { label: '+ Create new segment', value: 'create' },
  ]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorDetails, setErrorDetails] = useState({});
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [sampleMediaFileName, setSampleMediaFileName] = useState('');
  const [mediaFile, setMediaFile] = useState('');
  const [showLoadingUI, setShowLoadingUI] = useState(false);
  const [agentAssistTemplateCategories, setAgentAssistTemplateCategories] =
    useState(AGENT_ASSIST_TEMPLATES.TEMPLATE_CATEGORY);

  const [fetchingTemplates, setFetchingTemplate] = useState(false);

  const [openScheduler, setOpenScheduler] = useState(false);
  const [dateTime, setDateTime] = useState({
    date: new Date().toISOString().split('T')[0],
    time: new Date().getHours() + ':' + new Date().getMinutes(),
  });
  const [popoverActive, setpopoverActive] = useState(false);

  let walletRenewalStatus = JSON.parse(
    localStorage.getItem('walletRenewalStatus') || '{}'
  );

  useEffect(() => {
    const state = location.state as any;
    let shop = shopDetailsData;
    if (shop && shop.id) {
      setShop({ ...shop, id: shop.id, url: shop.url, platform: shop.platform });

      getAllSegment(shop.userId).then(
        (response: any) => {
          const groupList: any = [];
          response.message.map((item: any) => {
            if (item && item.name)
              groupList.push({ label: item.name, value: item.id.toString() });
            return item;
          });
          setUserGroupList(userGroupList.concat(groupList));
          if (state?.groupId)
            setcampaignDetails({
              ...campaignDetails,
              segment: state.groupId.toString(),
            });
        },
        (err) => {
          console.log('err', err);
        }
      );
      getCampaignTemplates(shop.userId).then((data: any) => {
        if (
          data?.templates?.filter((e: any) => e.status === 'APPROVED').length >
          0
        ) {
          setTemplates(
            data?.templates?.filter((e: any) => e.status === 'APPROVED')
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    if (shop?.id) setandListOpenTemplateList(false);
  }, [shop]);

  useEffect(() => {
    if (templateId && templateId !== '' && templates.length > 0)
      selectTemplate(templateId);
  }, [templateId, templates]);

  const showErrorToast = (message: string) => {
    if (typeof message !== 'string') message = 'Something went wrong';
    setErrorToast({ show: true, message: message });
  };

  const saveCampaign = () => {
    if (
      [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform) &&
      walletRenewalStatus.balance <= 0.09
    ) {
      setShowErrorModal(true);
      return;
    }
    setLoading(true);
    let params = new FormData();
    if (campaignDetails.recipientType === 'segment' && !isCustomeVariable) {
      params = new URLSearchParams();
    }

    params.append('name', campaignDetails.name);
    params.append('templateId', templateId);
    params.append('segmentId', campaignDetails.segment);
    if (campaignDetails.recipientType === 'segment' && isCustomeVariable) {
      params.append('file', variablesFile);
    } else if (campaignDetails.recipientType === 'file') {
      params.append('file', selectedFile);
    }
    params.append('consent', campaignDetails.consent);
    params.append('media', mediaFile);

    let error = validateForm();
    if (error) {
      setLoading(false);
      showErrorToast(error);
      return;
    } else {
      let createCampaignAPI = createCampaign;
      if (campaignDetails.recipientType === 'segment' && !isCustomeVariable) {
        createCampaignAPI = createCampaignSegment;
      }
      createCampaignAPI(shop.userId, params).then(
        (response: any) => {
          if (response.status === 'success') {
            //showToast('New Campaign created successfully');
            setShowSuccess(true);
            setLoading(false);
            setTimeout(() => {
              discardCreateCampaign();
            }, 1000);
          } else {
            setLoading(false);
            showErrorToast(response);
          }
        },
        (err) => {
          setLoading(false);
          if (err?.response?.data?.limit_issue) {
            setErrorDetails(err?.response?.data?.limit_issue);
            setShowErrorModal(true);
          } else {
            let error =
              err?.response?.data?.errors && err?.response?.data?.errors[0]
                ? err.response.data.errors[0]
                : errorCreateCampaign;
            showErrorToast(error);
          }
        }
      );
    }
  };

  const scheduleCampaign = (dateTime: any) => {
    let tempDate: string = dateTime.date + ' ' + dateTime.time;
    let timestamp = Date.parse(tempDate);

    if (
      [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform) &&
      walletRenewalStatus.balance <= 0.09
    ) {
      setShowErrorModal(true);
      return;
    }
    setLoading(true);
    let params = new FormData();
    if (campaignDetails.recipientType === 'segment' && !isCustomeVariable) {
      params = new URLSearchParams();
    }

    params.append('name', campaignDetails.name);
    params.append('templateId', templateId);
    params.append('segmentId', campaignDetails.segment);
    let scTime = timestamp;
    params.append('scheduleTime', scTime.toString());
    if (campaignDetails.recipientType === 'segment' && isCustomeVariable) {
      params.append('file', variablesFile);
    } else if (campaignDetails.recipientType === 'file') {
      params.append('file', selectedFile);
    }
    params.append('consent', campaignDetails.consent);
    params.append('media', mediaFile);

    let error = validateForm();
    if (error) {
      setLoading(false);
      showErrorToast(error);
      return;
    } else {
      let scheduleCampaignAPI = scheduleCampaignFile;
      if (campaignDetails.recipientType === 'segment' && !isCustomeVariable) {
        scheduleCampaignAPI = scheduleCampaignSegment;
      }
      scheduleCampaignAPI(shop.userId, params).then(
        (response: any) => {
          if (response.status === 'success') {
            //showToast('New Campaign created successfully');
            setShowSuccess(true);
            setLoading(false);
            setTimeout(() => {
              discardCreateCampaign();
            }, 1000);
          } else {
            setLoading(false);
            showErrorToast(response);
          }
        },
        (err) => {
          setLoading(false);
          if (err?.response?.data?.limit_issue) {
            setErrorDetails(err?.response?.data?.limit_issue);
            setShowErrorModal(true);
          } else {
            let error =
              err?.response?.data?.errors && err?.response?.data?.errors[0]
                ? err.response.data.errors[0]
                : errorCreateCampaign;
            showErrorToast(error);
          }
        }
      );
    }
  };

  const handleChange = (val: any, field: string) => {
    if (!activateErrorCheckForName && field === 'name') {
      setActivateErrorCheckForName(true);
    }
    if (!activateErrorCheckForContent && field === 'text') {
      setActivateErrorCheckForContent(true);
    }
    if (field === 'segment' && val === 'create') {
      history.push({ pathname: '/optins', state: { tab: 'createUserGroup' } });
    }
    return setcampaignDetails((campaign: any) => ({
      ...campaign,
      [field]: val,
    }));
  };

  const validateForm = () => {
    let campaign = campaignDetails;
    if (!campaign.name) {
      return 'Campaign name can not be empty';
    } else if (validateCampaignName() !== false) {
      return 'Campaign name should be less than 50 characters';
    } else if (campaign.recipientType === 'file' && !selectedFile) {
      return 'Receipients file is not found';
    } else if (!templateId) {
      return 'Please select a template to proceed';
    } else if (
      mediaFileTemplateTypes.includes(messageTemplate.type) &&
      !mediaFile
    ) {
      return 'Please select a media file for campaign';
    } else if (campaignDetails.consent !== 'true') {
      return campaignConsentErrorMessage;
    } else if (
      campaignDetails.recipientType === 'segment' &&
      isCustomeVariable &&
      !variablesFile
    ) {
      return 'Please select a message variable file';
    }
    return false;
  };

  const checkIsCustomVariableIncluded = (message: any, template: any) => {
    const variables: any = [];
    let curMatch: any = '';
    const regExp = /{{(.*?)}}/g; // Regex for get value between two {{}}

    while ((curMatch = regExp.exec(message))) {
      variables.push(curMatch[1]);
    }

    let customVariables = variables.filter(
      (value: string) =>
        !inbuildVariables.includes(value) ||
        inbuildVariables_custom.includes(value)
    );

    //if only link is there with static link - treat as no variable and no custom variable
    if (variables.length === 1 && customVariables.length) {
      if (
        customVariables.length === 1 &&
        customVariables[0] === '1' &&
        template?.buttons[0]?.urlType === 'STATIC'
      ) {
        variables.length = 0;
        customVariables.length = 0;
      }
    }

    if (customVariables.length > 0) setIsCustomeVariable(true);
    else setIsCustomeVariable(false);
    return variables;
  };

  const selectTemplate = (templId: any) => {
    var template = templates.find((el: any) => el.id === templId);
    if (template && template.internalText) {
      template.variables = checkIsCustomVariableIncluded(
        template.internalText,
        template
      );
    }
    selectedMessageTemplate(template);
    setMediaFile('');
    setSampleMediaFileName('');
  };

  const validateCampaignName = () => {
    if (activateErrorCheckForName) {
      let campaign = campaignDetails;
      if (campaign.name && campaign.name.trim()) {
        if (campaign.name.length > 50) {
          return 'Campaign name should be less than 50 characters';
        }
        if (campaign.name.length <= 0) {
          return 'Campaign name cannot be empty';
        }
      } else {
        return 'Campaign name cannot be empty';
      }
    }
    return false;
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const setandListOpenTemplateList = (openTemplatesList: boolean) => {
    setFetchingTemplate(true);
    if (!templates.length) {
      getCampaignTemplates(shop.userId).then((data: any) => {
        if (
          data?.templates?.filter((e: any) => e.status === 'APPROVED').length >
          0
        ) {
          if (!templateId && openTemplatesList) {
            setTemplateId(
              data?.templates?.filter((e: any) => e.status === 'APPROVED')[0].id
            );
          }
          setTemplates(
            data?.templates?.filter((e: any) => e.status === 'APPROVED')
          );
          setHsmTemplateModalOpen(openTemplatesList);
          setFetchingTemplate(false);
        } else {
          showErrorToast("You don't have any approved templates");
          setFetchingTemplate(false);
        }
      });
    } else {
      if (!templateId && openTemplatesList) {
        setTemplateId(
          templates?.filter((e: any) => e.status === 'APPROVED')[0].id
        );
      }
      setHsmTemplateModalOpen(openTemplatesList);
      setFetchingTemplate(false);
    }
  };

  useEffect(() => {
    console.log('Template Id: ', templateId);
  }, [templateId]);

  const getFormatttedData = (data: any, dataFromFile?: boolean) => {
    let formattedData: any = [];
    let headers: any = ['Phone Number'];
    //data.length > 0 ? Object.keys(data[0]) : ['Phone Number'];

    let isDynamicURLBtn: boolean = false;
    messageTemplate?.variables?.map((variable: string) => {
      if (!headers?.includes(variable)) {
        if (variable === '1') {
          if (messageTemplate?.buttons[0]?.urlType === 'DYNAMIC')
            isDynamicURLBtn = true;
        } else {
          headers.push(variable);
        }
      }
    });

    if (isDynamicURLBtn) {
      if (headers.length > 1) {
        // Move 'Button Url Suffix' at last in headers
        headers.push('Button URL suffix');
        headers.push(
          headers.splice(headers.indexOf('Button Url suffix'), 1)[0]
        );
      } else {
        headers.push('Button URL suffix');
      }
    }

    if (!dataFromFile) {
      data.forEach((o: any) => {
        let individualData: any = headers.map((variable: string) => {
          if (variable === variableKeysConst.OPT_IN_STATUS) {
            return !!o.optedIn ? 'Opted-in' : 'Not opted-in';
          }
          return o[variable];
        });
        formattedData.push(individualData);
      });
    }
    return {
      formattedData,
      headers,
    };
  };

  const handleDownload = (extension?: string, dataFromFile?: boolean) => {
    let forFile = dataFromFile;
    if (!templateId) {
      showErrorToast('Please select a template to download file');
      return;
    }
    if (!campaignDetails.segment && !dataFromFile) {
      showErrorToast('Please select a segment to download file');
      return;
    }
    // In case a template don't have any variables
    if (messageTemplate?.variables.length < 1) {
      const headers: any = ['Phone Number'];
      const formattedData: any = [];

      ExportToExcel(formattedData, 'customers', extension, true, headers);
    } else {
      // If dataFromFile, means we have to get only headers with no data, hence sending segmentId as a defalut one from
      // userGroupList (else API will fail) and then don't use the data.
      // TO DO: Call diffrenet API if no segment selected.
      setShowLoadingUI(true);
      downloadFileForSegment(
        shop.userId,
        dataFromFile ? '' : campaignDetails.segment,
        templateId,
        dataFromFile ? true : false
      ).then((res: any) => {
        setShowLoadingUI(false);
        if (res !== undefined && res.data && res.data.length > 0) {
          const { formattedData, headers } = dataFromFile
            ? getFormatttedData(JSON.parse(res.data), dataFromFile)
            : getFormatttedData(JSON.parse(res.data));
          ExportToExcel(formattedData, 'customers', extension, true, headers);
        } else {
          setErrorToast({
            show: true,
            message: 'Customer details not found',
          });
        }
      });
    }
  };

  const toggleConsent = (val: string) => {
    setcampaignDetails({
      ...campaignDetails,
      consent: val === 'true' ? 'false' : 'true',
    });
  };

  const discardCreateCampaign = () => {
    props.updateCampaignsListViewFlag();
    props.setCurrentSelectedTemplateId('');
    history.push({ pathname: `/campaigns` });
  };

  const clickInputField = () => {
    (document as any).getElementById('uploadMediaFileRunCampg').click();
  };

  const fileUpload = (e: any) => {
    //20MB === 20971520
    let resMessage = e.target.files.length
      ? invalidMediaFileSizeAndType(
          messageTemplate.type,
          e.target.files[0].type,
          e.target.files[0].size
        )
      : { error: true, message: SAMPLE_MEDIA_FILE.file_size_error };
    if (e.target?.files.length < 1 || resMessage?.error) {
      setErrorToast({ show: true, message: resMessage.message });
    } else {
      let formdata = new FormData();
      formdata.append('file', e.target.files[0]);
      formdata.append('fileType', e.target.files[0].type);
      setUploadingMedia(true);
      uploadCampaignMedia(shop.userId, formdata)
        .then((response: any) => {
          if (response?.message) setMediaFile(response?.message);
          setSampleMediaFileName(e.target.files[0].name);
          setUploadingMedia(false);
        })
        .catch((err) => {
          console.log(err);
          setSampleMediaFileName('');
          setMediaFile('');
          setUploadingMedia(false);
        });
    }
  };

  const removeSampleMedia = () => {
    setMediaFile('');
    setSampleMediaFileName('');
  };

  return showLoadingUI ? (
    <>
      <LoadingUI note={LOADING_SPINNER.EXCEL_DOWNLOAD} />
    </>
  ) : (
    <>
      <Page title='Create New Campaign'>
        <>
          <div className='flexSpaceBetween'>
            <div style={{ width: '65%' }}>
              No hassle of sending messages manually. Just upload the contacts
              and send messages together.
            </div>
            <div className='flexCenter'>
              <div className='tutorialButton '></div>
              <Button onClick={() => discardCreateCampaign()} primary>
                View Campaigns
              </Button>
            </div>
          </div>
        </>{' '}
        <></>
        <div className='createCampaignButtons'>
          <Layout>
            <Layout.Section>
              <FormLayout>
                <div>
                  <TextStyle variation='strong'>Campaign Name</TextStyle>
                </div>
                <TextField
                  label=''
                  placeholder='Enter Campaign Name'
                  value={campaignDetails.name}
                  onChange={(val) => handleChange(val, 'name')}
                  error={validateCampaignName()}
                />
                <label>
                  <div className='labelMargin'>
                    <TextStyle variation='strong'>Message</TextStyle>
                  </div>
                  {messageTemplate?.id ? (
                    <div
                      onClick={() => {
                        setandListOpenTemplateList(true);
                      }}
                    >
                      <TextField
                        label=''
                        value={messageTemplate.name}
                        onChange={() => {}}
                        readOnly
                        disabled={fetchingTemplates}
                        placeholder='Template name'
                      />{' '}
                      <br></br>
                      <TextField
                        label=''
                        value={
                          messageTemplate.buttons.length
                            ? messageTemplate.internalText.replace(
                                'https://i.gs.im/{{1}}',
                                messageTemplate?.buttons[0]?.value
                              )
                            : messageTemplate.internalText
                        }
                        onChange={() => {}}
                        readOnly
                        disabled={fetchingTemplates}
                        multiline={4}
                        placeholder='Message'
                      />{' '}
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={() => {
                          setandListOpenTemplateList(true);
                        }}
                        outline
                        primary
                      >
                        Select Template
                      </Button>
                    </div>
                  )}
                </label>

                {mediaFileTemplateTypes.includes(messageTemplate.type) ? (
                  <>
                    <div className='labelMargin'>
                      <TextStyle variation='strong'>
                        {RUN_CAMPAIGN_MEDIA_FILE.label}
                      </TextStyle>
                    </div>
                    <div className='uploadMediaFile'>
                      <input
                        onChange={fileUpload}
                        accept='image/png, image/jpeg, image/jpg, image/jpg, video/mp4, application/pdf'
                        id='uploadMediaFileRunCampg'
                        type='file'
                        hidden
                      />
                      <Button
                        onClick={clickInputField}
                        loading={uploadingMedia}
                        disabled={sampleMediaFileName ? true : false}
                      >
                        {RUN_CAMPAIGN_MEDIA_FILE.button_text}
                      </Button>
                      {sampleMediaFileName ? (
                        <div
                          className='removeMediaFile'
                          onClick={removeSampleMedia}
                        >
                          <label>
                            {sampleMediaFileName}
                            <Icon source={CircleCancelMinor}></Icon>
                          </label>
                        </div>
                      ) : (
                        <label>{RUN_CAMPAIGN_MEDIA_FILE.no_file_text}</label>
                      )}
                    </div>
                    <div className='fileTypeNote'>
                      {messageTemplate.type === options.TYPE[2].value && (
                        <span>{SAMPLE_MEDIA_FILE.imageFileLimit}</span>
                      )}
                      {messageTemplate.type === options.TYPE[3].value && (
                        <span>{SAMPLE_MEDIA_FILE.videoFileLimit}</span>
                      )}
                      {messageTemplate.type === options.TYPE[4].value && (
                        <span>{SAMPLE_MEDIA_FILE.documentFileLimit}</span>
                      )}
                    </div>
                  </>
                ) : null}

                <div>
                  <div className='labelMargin'>
                    <TextStyle variation='strong'>Recipients</TextStyle>
                  </div>
                  <div>
                    <Stack>
                      <RadioButton
                        label='From Segment'
                        checked={campaignDetails.recipientType == 'segment'}
                        id='From Segment'
                        name=''
                        onChange={(val) =>
                          handleChange('segment', 'recipientType')
                        }
                      />
                      <RadioButton
                        label='From file'
                        id='From file'
                        name=''
                        checked={campaignDetails.recipientType === 'file'}
                        onChange={(val) =>
                          handleChange('file', 'recipientType')
                        }
                      />
                    </Stack>
                  </div>
                  <br />
                  {campaignDetails?.recipientType === 'file' && (
                    <div>
                      <FileUploadPage
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                      />
                      <p style={{ color: '#6D7175' }}>
                        Upload the list of phone numbers with country code as prefix and no "+" in this format:&nbsp;
                        <a
                          className='link-color'
                          onClick={() => handleDownload('xlsx', true)}
                          href='#'
                        >
                          MS Excel
                        </a>
                        &nbsp; or&nbsp;
                        <a
                          className='link-color'
                          onClick={() => handleDownload('csv', true)}
                          href='#'
                        >
                          CSV
                        </a>
                      </p>
                    </div>
                  )}
                  {campaignDetails?.recipientType === 'segment' && (
                    <div>
                      <Select
                        label=''
                        placeholder='Select a segment'
                        options={userGroupList}
                        value={campaignDetails.segment}
                        onChange={(val) => handleChange(val, 'segment')}
                      />
                    </div>
                  )}
                </div>
                {campaignDetails.recipientType === 'segment' &&
                  isCustomeVariable && (
                    <div>
                      <div className='labelMargin'>
                        <TextStyle variation='strong'>
                          Message variables
                        </TextStyle>
                      </div>
                      <p style={{ color: '#6D7175' }}>
                        As the selected template contains variables. Please fill
                        the variables in this format:<br></br>
                        <a
                          className='link-color'
                          onClick={() => handleDownload('xlsx', false)}
                          href='#'
                          // download
                        >
                          MS Excel
                        </a>
                        &nbsp; or &nbsp;
                        <a
                          className='link-color'
                          onClick={() => handleDownload('csv', false)}
                          href='#'
                          // download
                        >
                          CSV &nbsp;
                        </a>
                        and upload the filled file by clicking on "Choose file"
                        button.
                      </p>
                      <div className='mt-1'>
                        <FileUploadPage
                          selectedFile={variablesFile}
                          setSelectedFile={setVariablesFile}
                        />
                      </div>
                    </div>
                  )}
                <div>
                  <TextContainer>
                    <Checkbox
                      label={`I confirm that I have collected customer opt-in for the uploaded numbers.`}
                      checked={campaignDetails.consent === 'true'}
                      onChange={(val) => toggleConsent(campaignDetails.consent)}
                    />
                    <span className='learnMoreCreateCompaignCss'>
                      <Link url={learnMoreCreateCompaign} external={true}>
                        Learn more
                      </Link>
                    </span>
                  </TextContainer>
                </div>
                <div className='labelMargin'>
                  {Number(shopDetailsData.planId) === PLAN_ENGAGE_PLUS ? (
                    <>
                      <Button
                        onClick={() => discardCreateCampaign()}
                        outline
                        primary
                      >
                        Cancel
                      </Button>
                      <div className='dropdown'>
                        <button
                          disabled={loading}
                          onClick={() => saveCampaign()}
                          className='btn btn-right'
                          onMouseEnter={() => setpopoverActive(false)}
                        >
                          Submit
                        </button>
                        <Popover
                          active={popoverActive}
                          sectioned={true}
                          preferredAlignment='right'
                          activator={
                            <button
                              className='btn btn-left'
                              onClick={() => setpopoverActive(true)}
                              onMouseEnter={() => setpopoverActive(true)}
                            >
                              &#9660;
                            </button>
                          }
                          onClose={() => {
                            setpopoverActive(false);
                          }}
                        >
                          <p
                            className='schedule_p'
                            onClick={() => {
                              setOpenScheduler(true);
                            }}
                          >
                            &#128339; Schedule
                          </p>
                        </Popover>
                      </div>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => discardCreateCampaign()}
                        outline
                        primary
                      >
                        Cancel
                      </Button>
                      <button
                          disabled={loading}
                          onClick={() => saveCampaign()}
                          className='btn btn-submit'
                        >
                          Submit
                        </button>
                    </>
                  )}
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${NEW_PRICING_CREATE_CAMPGN_PAGE}`,
                  }}
                ></div>
              </FormLayout>
            </Layout.Section>
            <Layout.Section secondary>
              <WhatsappContainer messageTemplate={messageTemplate} />
            </Layout.Section>
          </Layout>
        </div>
      </Page>
      <Modal
        open={openScheduler}
        sectioned={true}
        title={'Schedule your Campaign'}
        onClose={() => {
          setOpenScheduler(false);
        }}
      >
        <Modal.Section>
          <div className='campaign__scheduler'>
            <DateTimePicker dateTime={dateTime} setDateTime={setDateTime} />
            <div className='schedule_button'>
              <Button
                disabled={loading}
                onClick={() => scheduleCampaign(dateTime)}
                primary
              >
                Schedule
              </Button>
            </div>
          </div>
        </Modal.Section>
      </Modal>

      <div className=''>
        <Modal
          open={openTutorial}
          sectioned={true}
          title={'Tutorial video'}
          onClose={() => {
            setOpenScheduleCampaign(false);
          }}
        >
          <iframe
            id='ytplayer'
            width='100%'
            height='360'
            allow='fullscreen'
            src={props.tutorialSrc}
            frameBorder='0'
            title={'Tutorial Video'}
          ></iframe>
        </Modal>

        {isHsmTemplateModalOpen && (
          <HSMTemplateModal
            fetchedTemplates={templates}
            templateId={templateId}
            setTemplateId={setTemplateId}
            selectTemplate={selectTemplate}
            setHsmTemplateModalOpen={setHsmTemplateModalOpen}
            isHsmTemplateModalOpen={isHsmTemplateModalOpen}
            from={'Campaign'}
            showSuccess={showSuccess}
            setShowSuccess={setShowSuccess}
          />
        )}

        <GenericImageToast
          showSuccess={showSuccess}
          setShowSuccess={setShowSuccess}
          value='Message triggered successfully'
        />
        {[PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform) &&
        walletRenewalStatus.balance <= 0.09 ? (
          <InsufficientBalModal
            showModal={showErrorModal}
            setShowModal={setShowErrorModal}
            walletBalance={walletRenewalStatus.balance}
          />
        ) : (
          <ErrorModal
            showModal={showErrorModal}
            setShowModal={setShowErrorModal}
            errorDetails={errorDetails}
          ></ErrorModal>
        )}
      </div>
      {toastMarkup}
      {errorToastMarkup}
    </>
  );
};

export default CreateNewCampaign;
