import { Page } from '@shopify/polaris';
import { ReactComponent as PuzzleIcon } from '../assets/icons/puzzleIcon.svg';

const NudgeScreenContactSupport = (props: any) => {
  const contactUs = () => {
    let subject = 'I need to link my shopify/wix account';
    subject = encodeURIComponent(subject);
    window.open(
      `mailto:support@superlemon.shop?subject=${subject}` + props.shop.url,
      '_blank'
    );
  };

  return (
    <Page title={props.pageDetails.TITLE}>
      <div className='color-grey font-weight-500'>
        {props.pageDetails.SUB_TITLE}
      </div>
      <div className='nudgeScreenContactSupport_container'>
        <div className='nudgeScreenContactSupport_Imagecontainer'>
          <PuzzleIcon />
        </div>
        <div>
          <button
            onClick={() => contactUs()}
            className='nudgeScreenContactSupport_button'
          >
            {props.pageDetails.BUTTON_TEXT}
          </button>
        </div>
        <div>
          <p className='color-grey'>
            <span className='superLemonColor'>Note: </span>
            {props.pageDetails.NOTE}
          </p>
        </div>
      </div>
    </Page>
  );
};

export default NudgeScreenContactSupport;
