import { Button, ButtonGroup, Card, Stack } from '@shopify/polaris';
import { AUTOMATION_BANNER } from '../../constants/constants';
import { useHistory } from 'react-router-dom';

const AutomationBanner = (props: any) => {
  const history = useHistory();

  return (
    <>
      <div className={'planStatusNew'}>
        <Card>
          <div className={'planColorNew'}>
            <Card.Section>
              <Stack alignment='center'>
                <Stack.Item fill>
                  <p
                    style={{
                      fontWeight: '600',
                      lineHeight: '40px',
                      fontSize: '1.8rem',
                    }}
                  >
                    {AUTOMATION_BANNER.HEADING}
                  </p>
                  <p
                    style={{
                      color: 'rgba(109, 113, 117, 1)',
                      fontSize: '1.5rem',
                    }}
                  >
                    {AUTOMATION_BANNER.SUB_HEADING}
                    <span
                      onClick={() =>
                        window.open(AUTOMATION_BANNER.LINK, '_blank')
                      }
                      style={{ color: '#5ec127', cursor: 'pointer' }}
                    >
                      {' '}
                      here
                    </span>
                  </p>
                </Stack.Item>

                <Stack.Item>
                  <ButtonGroup>
                    <Button
                      primary
                      onClick={() => {
                        history.push('/private-waba');
                      }}
                    >
                      {AUTOMATION_BANNER.BUTTON_TEXT}
                    </Button>
                  </ButtonGroup>
                </Stack.Item>
              </Stack>
            </Card.Section>
          </div>
        </Card>
      </div>
      <br />
    </>
  );
};

export default AutomationBanner;
