import './dateTimePicker.scss';
import moment from 'moment';

export const DateTimePicker = (props) => {
  let minDate = moment();
  let maxDate = moment().add(3, 'months');

  let setDateTime = (value, key) => {
    props.setDateTime({
      ...props.dateTime,
      [key]: value,
    });
  };

  return (
    <div class='wrapper'>
      <div className='label_class'>
        Pick a Date
        <input
          className='input_class'
          type='date'
          id='date'
          value={props.dateTime.date}
          onChange={(e) => {
            setDateTime(e.target.value, 'date');
          }}
          min={minDate.toDate().toISOString().split('T')[0]}
          max={maxDate.toDate().toISOString().split('T')[0]}
          autocomplete='off'
        />
      </div>
      <div className='label_class'>
        Pick a time
        <input
          className='input_class'
          type='time'
          id='appt'
          name='appt'
          value={props.dateTime.time}
          onChange={(e) => {
            setDateTime(e.target.value, 'time');
          }}
          required
        />
      </div>
    </div>
  );
};
