import { useEffect, useState } from "react";
import {
  Card,
  Layout,
  Link,
  Modal,
  RadioButton,
  Stack,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { objectDifference } from "../../../../utils/helper";
import {
  COUPON_TYPE_PERCENT,
  COUPON_TYPE_STATIC,
  nonOptinMessage,
} from "../../../../constants/constants";
import { updateOrderSettings } from "../../../../redux/actions/crmOrders";
import {
  clevertapEventPush,
  constants,
  CT_EVENT_IDS,
} from "../../../../utils/clevertapUtils";
import NonOptinMessageSettings from "../../../../common/NonOptinMessage";
import { useShopStore } from '../../../../hooks/shopStore';

const OrderShipmentModal = (
  {
    showToast,
    showErrorToast,
    open,
    closeModal,
    setOrderConfirmationLanguage,
    orderShipmentData,
  }: any,
  props: any
) => {
  const [openModal, setOpenModal] = useState(open);
  const { shopDetailsData } = useShopStore();
  const [initialOrderData, setInitialOrderData] = useState({
    couponCode: "",
    discount: true,
    discountType: "FIXED",
    discountRatio: 10,
    language: "ENGLISH",
    thankYouPage: true,
    order_tracking_url_type: 1,
    shipmentNonOptinEnabled: true,
  });
  const [orderData, setOrderData] = useState({
    couponCode: "",
    discount: true,
    discountType: "FIXED",
    discountRatio: 10,
    language: "ENGLISH",
    thankYouPage: true,
    order_tracking_url_type: 1,
    shipmentNonOptinEnabled: true,
  });
  const [refOrderData, setRefOrderData] = useState({
    couponCode: "",
    discount: true,
    discountType: "FIXED",
    discountRatio: 10,
    language: "ENGLISH",
    thankYouPage: true,
    order_tracking_url_type: 1,
    shipmentNonOptinEnabled: true,
  });

  const [shop, setShop] = useState({
    id: "",
    url: "",
    userId: "",
  });
  const [dataChanges, setDataChanges] = useState<any>({
    order: null,
    phone: null,
  });
  const isPrivateWABAPresent = JSON.parse(
    localStorage.getItem("private_waba") || "{}"
  );
  const handleOrderChange = (field: string, value: any) => {
    setOrderData((data) => ({ ...data, [field]: value }));
  };

  useEffect(() => {
    let shop = shopDetailsData;
    if (shop && shop.id) {
      setShop(shop);
    }
    setOpenModal(open);
  }, [props, open]);

  useEffect(() => {
    setInitialOrderData(orderShipmentData);
    setRefOrderData(orderShipmentData);
    setOrderData(orderShipmentData);
  }, [orderShipmentData]);

  useEffect(() => {
    var diffObj: any = objectDifference(refOrderData, orderData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        order: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({ ...dataChanges, order: null }));
    }
  }, [orderData, refOrderData]);

  const resetData = () => {
    setDataChanges({
      order: null,
      phone: null,
    });
    setRefOrderData(initialOrderData);
    setOrderData(initialOrderData);
  };

  const updateSettings = () => {
    if (dataChanges.order) {
      let params = new URLSearchParams();
      let data: any = dataChanges.order;

      Object.keys(data).forEach((key) => {
        params.append(key, data[key]);
      });

      updateOrderSettings(shop.userId, params).then((response: any) => {
        if (response.status === "success") {
          showToast("Data Saved");
          if (Object.keys(data).includes("language")) {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_LANGUAGE_CHANGED,
              eventData: {
                "Automated template message name": constants.shipment,
                Language: data.language,
                "Private waba":
                  localStorage.getItem("private_waba") === "true"
                    ? true
                    : false,
              },
            });
          }
          setDataChanges({ ...dataChanges, order: null });
          setInitialOrderData((data: any) => ({
            ...data,
            ...response.settings,
          }));
          setRefOrderData((data: any) => ({
            ...data,
            ...response.settings,
          }));
          setOrderData((data: any) => ({
            ...data,
            ...response.settings,
          }));
        } else {
          showErrorToast(response);
        }
      });
    }
    closeModal();
  };

  const nudgeCheckoutOptinScrollSection = (
    <div id="nudgeCheckoutOptin_anchor">
      <Card></Card>
    </div>
  );
  const trackingShippingUrl = (
    <div className="discountTypeHelpTextFontSize">
      <p>
        Tracking URL from order data{" "}
        <TextStyle variation="subdued">
          <Link
            external={true}
            url={`https://cdn.shopify.com/s/files/1/0070/3666/5911/files/tracking_link_shipment_url.png?v=1591762091`}
          >
            (see example)
          </Link>
        </TextStyle>
      </p>
    </div>
  );
  const trackingThankyoupageUrl = (
    <div className="discountTypeHelpTextFontSize">
      <p>
        Thank you page{" "}
        <TextStyle variation="subdued">
          <Link
            external={true}
            url={`https://cdn.shopify.com/s/files/1/0070/3666/5911/files/tracking_link_thank_you_page.png?v=1591762200`}
          >
            (see example)
          </Link>
        </TextStyle>
      </p>
    </div>
  );

  const cartRecoveryTemplateMarkup = (
    <Card sectioned>
      <div className="verticalMarginTextContainer">
        <TextContainer>
          <p>Configure the {"{{tracking_url}}"} to send in the message</p>
        </TextContainer>
      </div>

      <div className="stackVerticalMarginTopAdjustment">
        <Stack vertical={true}>
          <RadioButton
            label={trackingShippingUrl}
            checked={orderData.thankYouPage === false}
            id="tracking1"
            name="shippingurl"
            onChange={() => {
              setOrderData((order) => ({
                ...order,
                thankYouPage: false,
              }));
            }}
          />
          <RadioButton
            label={trackingThankyoupageUrl}
            id="tracking2"
            name="thankyoupageurl"
            checked={orderData.thankYouPage === true}
            onChange={() => {
              setOrderData((order) => ({
                ...order,
                thankYouPage: true,
              }));
            }}
          />
        </Stack>
      </div>
    </Card>
  );
  const order_shipment_message_template_description = (
    <span>
      <p>
        Order shipment message is sent when you fulfill an order in the
        E-commerce manager.
      </p>
      <br />
      <p>Tracking number and URL is taken from your E-commerce order data.</p>
    </span>
  );

  const getAbandonedCartSettingsMarkup = () => {
    return (
      <>
        <Layout.AnnotatedSection
          title="Order Shipment Template"
          description={order_shipment_message_template_description}
        >
          <Stack vertical>
            {cartRecoveryTemplateMarkup}
            {nudgeCheckoutOptinScrollSection}
          </Stack>
        </Layout.AnnotatedSection>
        {isPrivateWABAPresent && (
          <NonOptinMessageSettings
            nonOptinMessage={nonOptinMessage.ORDER_SHIPMENT}
            handleChange={handleOrderChange}
            nonOptinMessageEnabled={orderData.shipmentNonOptinEnabled}
            shipment={true}
          />
        )}
      </>
    );
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        resetData();
        closeModal();
        setOpenModal(false);
      }}
      title={"Order Shipment Settings"}
      primaryAction={{
        content: "Save",
        onAction: updateSettings,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            resetData();
            closeModal();
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>{getAbandonedCartSettingsMarkup()}</Modal.Section>
    </Modal>
  );
};

export default OrderShipmentModal;
