export const operatorConst = {
  IS: 'IS',
  IS_NOT: 'IS NOT',
  CONTAINS: 'CONTAINS',
  DOES_NOT_CONTAIN: 'DOES NOT CONTAIN',
  IS_AFTER: 'IS AFTER',
  IS_BEFORE: 'IS BEFORE',
  IS_BETWEEN: 'BETWEEN',
  IS_GREATER_THAN: 'IS GREATER THAN',
  IS_LESS_THAN: 'IS LESS THAN',
};

export const parameterConst = {
  PHONE_NUMBER: 'Phone Number',
  CUSTOMER_FIRST_NAME: "Customer's First Name",
  CUSTOMER_LAST_NAME: "Customer's Last Name",
  LAST_ORDERED_DATE: 'Last Ordered Date',
  NUMBER_OF_ORDER: 'Number Of Order',
  TOTAL_ORDER_VALUE: 'Total Order Value',
  ABANDONED_CART_VALUE: 'Abandoned Cart Value',
  TRACKING_ID: 'Tracking Id',
  ORDER_STATUS_URL: 'Order Status Url',
  OPT_IN_STATUS: 'Opt in Status',
  OPT_IN_DATE: 'Opt in date',
  OPT_IN_SOURCE: 'Opt in Source',
  SHOP_NAME: 'Shop name',
  STORE_URL: 'Store Url',
  ORDER_ID: 'Order Id',
  CUSTOMER_NAME: 'Full customer name',
  ORDER_ITEMS: 'Order items',
  TRACKING_NUMBER: 'Tracking number',
  TRACKING_URL: 'Order tracking url',
  DISCOUNT: 'Discount %',
  DISCOUNT_CODE: 'Discount code',
  OWNER_NAME: 'Owner Name',
  ORDER_ADDRESS: 'Order Address',
  CART_ITEMS: 'Cart items',
  CHECKOUT_URL: 'Checkout Url',
  ORDER_NUMBER: 'Order Number',
};

export const parameterKeysConst: any = {
  PHONE_NUMBER: 'phone',
  CUSTOMER_FIRST_NAME: 'first_name',
  CUSTOMER_LAST_NAME: 'last_name',
  LAST_ORDERED_DATE: 'last_ordered_on',
  NUMBER_OF_ORDER: 'no_of_orders',
  TOTAL_ORDER_VALUE: 'total_order_value',
  ABANDONED_CART_VALUE: 'last_ab_cart_value',
  OPT_IN_STATUS: 'opted_in',
  OPT_IN_DATE: 'created_on',
  OPT_IN_SOURCE: 'source',
};
export const parameterKeysCleverTap: any = {
  PHONE_NUMBER: 'phone',
  CUSTOMER_FIRST_NAME: 'firstName',
  CUSTOMER_LAST_NAME: 'lastName',
  LAST_ORDERED_DATE: 'lastOrderedOn',
  NUMBER_OF_ORDER: 'noOfOrders',
  TOTAL_ORDER_VALUE: 'totalOrderValue',
  ABANDONED_CART_VALUE: 'lastAbCartValue',
  OPT_IN_STATUS: 'optedIn',
  OPT_IN_DATE: 'createdOn',
  OPT_IN_SOURCE: 'source',
};

export const parameterOption = [
  {
    label: parameterConst['PHONE_NUMBER'],
    value: parameterKeysConst['PHONE_NUMBER'],
  },
  {
    label: parameterConst['CUSTOMER_FIRST_NAME'],
    value: parameterKeysConst['CUSTOMER_FIRST_NAME'],
  },
  {
    label: parameterConst['CUSTOMER_LAST_NAME'],
    value: parameterKeysConst['CUSTOMER_LAST_NAME'],
  },
  {
    label: parameterConst['LAST_ORDERED_DATE'],
    value: parameterKeysConst['LAST_ORDERED_DATE'],
  },
  {
    label: parameterConst['NUMBER_OF_ORDER'],
    value: parameterKeysConst['NUMBER_OF_ORDER'],
  },
  {
    label: parameterConst['TOTAL_ORDER_VALUE'],
    value: parameterKeysConst['TOTAL_ORDER_VALUE'],
  },
  {
    label: parameterConst['ABANDONED_CART_VALUE'],
    value: parameterKeysConst['ABANDONED_CART_VALUE'],
  },
  {
    label: parameterConst['OPT_IN_STATUS'],
    value: parameterKeysConst['OPT_IN_STATUS'],
  },
  {
    label: parameterConst['OPT_IN_DATE'],
    value: parameterKeysConst['OPT_IN_DATE'],
  },
  {
    label: parameterConst['OPT_IN_SOURCE'],
    value: parameterKeysConst['OPT_IN_SOURCE'],
  },
];

export const conditionOption: any = {
  [parameterKeysConst['PHONE_NUMBER']]: [
    { label: 'Is', value: operatorConst.IS },
    { label: 'Is Not', value: operatorConst.IS_NOT },
    { label: 'Contains', value: operatorConst.CONTAINS },
    { label: 'Does not contain', value: operatorConst.DOES_NOT_CONTAIN },
  ],
  [parameterKeysConst['CUSTOMER_FIRST_NAME']]: [
    { label: 'Is', value: operatorConst.IS },
    { label: 'Is Not', value: operatorConst.IS_NOT },
    { label: 'Contains', value: operatorConst.CONTAINS },
    { label: 'Does not contain', value: operatorConst.DOES_NOT_CONTAIN },
  ],
  [parameterKeysConst['CUSTOMER_LAST_NAME']]: [
    { label: 'Is', value: operatorConst.IS },
    { label: 'Is Not', value: operatorConst.IS_NOT },
    { label: 'Contains', value: operatorConst.CONTAINS },
    { label: 'Does not contain', value: operatorConst.DOES_NOT_CONTAIN },
  ],
  [parameterKeysConst['LAST_ORDERED_DATE']]: [
    { label: 'Is After', value: operatorConst.IS_AFTER },
    { label: 'Is Before', value: operatorConst.IS_BEFORE },
    { label: 'Is Between', value: operatorConst.IS_BETWEEN },
  ],
  [parameterKeysConst['NUMBER_OF_ORDER']]: [
    { label: 'Is Greater than', value: operatorConst.IS_GREATER_THAN },
    { label: 'Is Less than', value: operatorConst.IS_LESS_THAN },
  ],
  [parameterKeysConst['TOTAL_ORDER_VALUE']]: [
    { label: 'Is Greater than', value: operatorConst.IS_GREATER_THAN },
    { label: 'Is Less than', value: operatorConst.IS_LESS_THAN },
  ],
  [parameterKeysConst['ABANDONED_CART_VALUE']]: [
    { label: 'Is Greater than', value: operatorConst.IS_GREATER_THAN },
    { label: 'Is Less than', value: operatorConst.IS_LESS_THAN },
  ],
  [parameterKeysConst['OPT_IN_STATUS']]: [
    { label: 'Is', value: operatorConst.IS },
  ],
  [parameterKeysConst['OPT_IN_DATE']]: [
    { label: 'Is After', value: operatorConst.IS_AFTER },
    { label: 'Is Before', value: operatorConst.IS_BEFORE },
    { label: 'Is Between', value: operatorConst.IS_BETWEEN },
  ],
  [parameterKeysConst['OPT_IN_SOURCE']]: [
    { label: 'Is', value: operatorConst.IS },
    { label: 'Is Not', value: operatorConst.IS_NOT },
  ],
};

export const typeOption: any = {
  [parameterKeysConst['PHONE_NUMBER']]: 'number',
  [parameterKeysConst['CUSTOMER_FIRST_NAME']]: 'text',
  [parameterKeysConst['CUSTOMER_LAST_NAME']]: 'text',
  [parameterKeysConst['LAST_ORDERED_DATE']]: 'date',
  [parameterKeysConst['NUMBER_OF_ORDER']]: 'number',
  [parameterKeysConst['TOTAL_ORDER_VALUE']]: 'number',
  [parameterKeysConst['ABANDONED_CART_VALUE']]: 'number',
  [parameterKeysConst['OPT_IN_STATUS']]: 'choice',
  [parameterKeysConst['OPT_IN_DATE']]: 'date',
  [parameterKeysConst['OPT_IN_SOURCE']]: 'choice',
};

export const choiceOptions: any = {
  [parameterKeysConst['OPT_IN_STATUS']]: [
    { label: 'Opted in', value: '1' },
    { label: 'Not opted in', value: '0' },
  ],
  [parameterKeysConst['OPT_IN_SOURCE']]: [
    { label: 'Opt-in Widget', value: '0' },
    { label: 'Checkout Screen', value: '1' },
    { label: 'Spin the wheel widget', value: '2' },
  ],
};

export const choiceOptionsWix: any = {
  [parameterKeysConst['OPT_IN_STATUS']]: [
    { label: 'Opted in', value: '1' },
    { label: 'Not opted in', value: '0' },
  ],
  [parameterKeysConst['OPT_IN_SOURCE']]: [
    { label: 'Checkout Screen', value: '1' },
    { label: 'Opt-in via Chat widget', value: '4' },
  ],
};

export const variableKeysConst = {
  PHONE_NUMBER: 'phone',
  CUSTOMER_FIRST_NAME: 'firstName',
  CUSTOMER_LAST_NAME: 'lastName',
  LAST_ORDERED_DATE: 'lastOrderedOn',
  NUMBER_OF_ORDER: 'noOfOrders',
  TOTAL_ORDER_VALUE: 'totalOrderValue',
  ABANDONED_CART_VALUE: 'lastAbCartValue',
  TRACKING_ID: 'trackingId',
  ORDER_STATUS_URL: 'orderStatusUrl',
  OPT_IN_STATUS: 'optedIn',
  OPT_IN_DATE: 'createdOn',
  OPT_IN_SOURCE: 'source',
  SHOP_NAME: 'shopName',
  STORE_URL: 'storeUrl',
  ORDER_ID: 'orderId',
  CUSTOMER_NAME: 'customerName',
  ORDER_ITEMS: 'orderItems',
  TRACKING_NUMBER: 'trackingNumber',
  TRACKING_URL: 'trackingUrl',
  DISCOUNT: 'discount',
  DISCOUNT_CODE: 'discountCode',
  OWNER_NAME: 'ownerName',
  ORDER_ADDRESS: 'orderAddress',
  CART_ITEMS: 'cartItems',
  CHECKOUT_URL: 'checkoutUrl',
  ORDER_NUMBER: 'orderNumber',
};

export const inbuildVariables = Object.values(variableKeysConst);
export const inbuildVariables_custom = [
  'shopName',
  'storeUrl',
  'orderId',
  'orderNumber',
  'orderValue',
  'orderStatusUrl',
  'customerName',
  'orderItems',
  'trackingNumber',
  'trackingUrl',
  'trackingId',
  'discount',
  'discountCode',
  'ownerName',
  'orderAddress',
  'checkout_url',
  'first_name',
  'cart_items',
];

export const variableOptions = [
  {
    label: parameterConst['PHONE_NUMBER'],
    value: variableKeysConst['PHONE_NUMBER'],
  },
  {
    label: parameterConst['CUSTOMER_FIRST_NAME'],
    value: variableKeysConst['CUSTOMER_FIRST_NAME'],
  },
  {
    label: parameterConst['CUSTOMER_LAST_NAME'],
    value: variableKeysConst['CUSTOMER_LAST_NAME'],
  },
  {
    label: parameterConst['LAST_ORDERED_DATE'],
    value: variableKeysConst['LAST_ORDERED_DATE'],
  },
  {
    label: parameterConst['NUMBER_OF_ORDER'],
    value: variableKeysConst['NUMBER_OF_ORDER'],
  },
  {
    label: parameterConst['TOTAL_ORDER_VALUE'],
    value: variableKeysConst['TOTAL_ORDER_VALUE'],
  },
  {
    label: parameterConst['ABANDONED_CART_VALUE'],
    value: variableKeysConst['ABANDONED_CART_VALUE'],
  },
  {
    label: parameterConst['ORDER_NUMBER'],
    value: variableKeysConst['ORDER_NUMBER'],
  },
  {
    label: parameterConst['TRACKING_ID'],
    value: variableKeysConst['TRACKING_ID'],
  },
  {
    label: parameterConst['ORDER_STATUS_URL'],
    value: variableKeysConst['ORDER_STATUS_URL'],
  },
  {
    label: parameterConst['OPT_IN_STATUS'],
    value: variableKeysConst['OPT_IN_STATUS'],
  },
  {
    label: parameterConst['OPT_IN_DATE'],
    value: variableKeysConst['OPT_IN_DATE'],
  },
  {
    label: parameterConst['OPT_IN_SOURCE'],
    value: variableKeysConst['OPT_IN_SOURCE'],
  },
  {
    label: parameterConst['SHOP_NAME'],
    value: variableKeysConst['SHOP_NAME'],
  },
  {
    label: parameterConst['STORE_URL'],
    value: variableKeysConst['STORE_URL'],
  },
  {
    label: parameterConst['ORDER_ID'],
    value: variableKeysConst['ORDER_ID'],
  },
  {
    label: parameterConst['CUSTOMER_NAME'],
    value: variableKeysConst['CUSTOMER_NAME'],
  },
  {
    label: parameterConst['ORDER_ITEMS'],
    value: variableKeysConst['ORDER_ITEMS'],
  },
  {
    label: parameterConst['TRACKING_NUMBER'],
    value: variableKeysConst['TRACKING_NUMBER'],
  },
  {
    label: parameterConst['TRACKING_URL'],
    value: variableKeysConst['TRACKING_URL'],
  },
  {
    label: parameterConst['DISCOUNT'],
    value: variableKeysConst['DISCOUNT'],
  },
  {
    label: parameterConst['DISCOUNT_CODE'],
    value: variableKeysConst['DISCOUNT_CODE'],
  },
  {
    label: parameterConst['OWNER_NAME'],
    value: variableKeysConst['OWNER_NAME'],
  },
  {
    label: parameterConst['ORDER_ADDRESS'],
    value: variableKeysConst['ORDER_ADDRESS'],
  },
  {
    label: parameterConst['CART_ITEMS'],
    value: variableKeysConst['CART_ITEMS'],
  },
  {
    label: parameterConst['CHECKOUT_URL'],
    value: variableKeysConst['CHECKOUT_URL'],
  },
];

export const excelKeyMap: any = {
  phone: {
    header: 'Phone Number',
    dataKey: 'phone',
  },
  firstName: {
    header: 'Customer Name',
    dataKey: 'firstName',
  },
  lastName: {
    header: 'Customer Name',
    dataKey: 'lastName',
  },
  lastOrderedOn: {
    header: 'Last Ordered On',
    dataKey: 'lastOrderedOn',
  },
  noOfOrders: {
    header: 'Number of Orders',
    dataKey: 'noOfOrders',
  },
  trackingId: {
    header: 'Tracking Id',
    dataKey: 'trackingId',
  },
  orderStatusUrl: {
    header: 'Order Status Url',
    dataKey: 'orderStatusUrl',
  },
  totalOrderValue: {
    header: 'Total Order Value',
    dataKey: 'totalOrderValue',
  },
  lastAbCartValue: {
    header: 'Abandoned Cart Value',
    dataKey: 'lastAbCartValue',
  },
  optedIn: {
    header: 'Optin',
    dataKey: 'optedIn',
  },
  createdOn: {
    header: 'Optin Date',
    dataKey: 'createdOn',
  },
  source: {
    header: 'Optin Source',
    dataKey: 'source',
  },
};
