const ShareBtnPreview = (props: any) => {
  const template = props.template;
  const custom = props.custom;
  const selected = props.selected;

  if (selected && selected.template) {
    custom["is_share_btn_solid_background"] = selected.isBgStyleColorSolid;
    custom["bgColor1"] = selected.bgColor1;
    custom["bgColor2"] = selected.bgColor2;
    custom["iconColor"] = selected.iconColor;
    custom["buttonTextColor"] = selected.buttonTextColor;
  }

  template.icon_url =
    "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/wa.svg?v=1586952948";

  var is_share_btn_solid_background = custom.is_share_btn_solid_background;
  var bgColor1 =
    custom.share_btn_bg_color_1 || template.share_btn_bg_color_1 || "#22CD5B";
  var bgColor2 =
    custom.share_btn_bg_color_2 || template.share_btn_bg_color_2 || "#30BF42";
  var share_btn_text_color =
    custom.share_btn_text_color || template.share_btn_text_color || "#ffffff";

  var mainStyle = {};
  var textStyle = { color: share_btn_text_color };
  var iconStyle = { background: custom.share_btn_icon_color };

  mainStyle = is_share_btn_solid_background
    ? { background: bgColor1 }
    : {
        backgroundImage: `linear-gradient(90deg, ${bgColor1} 0%, ${bgColor2} 100%`,
      };

  return (
    <div
      className="wa-share-btn-container wa-share-btn-tmpl-regular"
      style={mainStyle}
    >
      <p className="wa-share-btn-cta" style={textStyle}>
        Share
      </p>
      <div
        className="wa-share-btn-img wa-share-icon wa-share-mask"
        style={iconStyle}
      ></div>
    </div>
  );
};

export default ShareBtnPreview;
