import { MESSAGING } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

const config = {
  baseURL: MESSAGING.baseUrl,
};

export const fetchOrderSettings = (userId: string) => {
  return httpClient.get(
    MESSAGING.api.CRM_ORDERS_SETTIGS.replace("$userId", userId),
    config
  );
};

export const fetchOptinWidgetSettings = (userId: string) => {
  return httpClient.get(
    MESSAGING.api.OPTIN_WIDGET_SETTINGS.replace("$userId", userId),
    config
  );
};

export const fetchPhoneSettings = (userId: string) => {
  return httpClient.get(
    MESSAGING.api.AUTOMATED_PHONE_SETTINGS.replace("$userId", userId),
    config
  );
};

export const updateOrderSettings = (userId: string, params: any) => {
  return httpClient.post(
    MESSAGING.api.CRM_ORDERS_SETTIGS.replace("$userId", userId),
    params,
    config
  );
};

export const updateOptinWidgetSettins = (userId: string, params: any) => {
  return httpClient.post(
    MESSAGING.api.OPTIN_WIDGET_SETTINGS.replace("$userId", userId),
    params,
    config
  );
};

export const updatePhoneSettings = (userId: string, params: any) => {
  return httpClient.post(
    MESSAGING.api.AUTOMATED_PHONE_SETTINGS.replace("$userId", userId),
    params,
    config
  );
};

export const fetchMessageLogs = (
  userId: string,
  category: string,
  pageSize: number,
  pageIndex: number
) => {
  return httpClient.get(
    MESSAGING.api.MESSAGE_LOGS.replace("$userId", userId) +
      "?category=" +
      category +
      "&pageSize=" +
      pageSize +
      "&pageIndex=" +
      pageIndex,
    config
  );
};

export const getCRMToolSettings = (userId: string) => {
  return httpClient.get(
    MESSAGING.api.CRM_TOOL_SETTINGS.replace("$userId", userId),
    config
  );
};

export const saveCRMToolSettings = (userId: string, params: any) => {
  return httpClient.post(
    MESSAGING.api.CRM_TOOL_SETTINGS.replace("$userId", userId),
    params,
    config
  );
};
