import moment from "moment";

const formatTime = (myDate, fromChatList = false, fromDateFloater = false) => {
  if (fromChatList) {
    return moment(myDate).calendar(null, {
      sameDay: "h:mm A",
      lastDay: "[Yesterday]",
      lastWeek: "DD/MM/YYYY",
      sameElse: "DD/MM/YYYY",
    });
  }
  if (fromDateFloater) {
    return moment(myDate).calendar(null, {
      sameDay: "[TODAY]",
      lastDay: "[YESTERDAY]",
      lastWeek: "dddd",
      sameElse: "DD MMMM yyyy",
    });
  }
  return moment(myDate).format("h:mm A");
};

export default formatTime;
