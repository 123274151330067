import { CalloutCard } from "@shopify/polaris";
import { APP_NAME, PLATFORM } from "../constants/constants";

const ReviewCard = (props: any) => {
  return (
    <div className="calloutCardContentContainer">
      <CalloutCard
        title="Enjoying our app? Please support us with a review 💖"
        illustration="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/undraw_smiley_face_lmgm.svg?535"
        onDismiss={() => {
          localStorage.setItem("review_clicked", "true");
          props.setReviewClicked(true);
        }}
        primaryAction={{
          content: "Rate our app",
          onAction: () => {
            localStorage.setItem("review_clicked", "true");
            props.setReviewClicked(true);
          },
          url:
            props.platform === PLATFORM.SHOPIFY
              ? "https://apps.shopify.com/" +
                APP_NAME +
                "?#modal-show=ReviewListingModal"
              : "https://www.wix.com/app-market",
          external: true,
        }}
      >
        <p className="reviewText">
          {"We have worked very hard to create this app."}
        </p>
        <p className="reviewText">
          {
            "If our app has added value to your store, please give us your love and support by writing a review. Team SuperLemon thanks you for your kindness 😊"
          }
        </p>
      </CalloutCard>
    </div>
  );
};

export default ReviewCard;
