import { useEffect, useState } from "react";
import {
  Layout,
  TextContainer,
  TextStyle,
  Heading,
  Icon,
  Link,
} from "@shopify/polaris";
import { SettingsMinor } from "@shopify/polaris-icons";
import {
  getWinbackTemplateSettings,
  updateWinbackTemplateSettings,
} from "../../../../redux/actions/whatsappPromotional";
import Switch from "react-switch";
import {
  CUSTOMER_WINBACK_TEMPLATE_DICT,
  OPTIN_ENTERPRISE_TEMPLATES,
} from "../../../../constants/constants";
import SettingsModal from "./Modal";

function CustomerWinback({
  userId,
  showToast,
  showErrorToast,
  phoneData,
  checkForApproval,
  optinEnterpriseTemplates,
}: any) {
  let TEMPLATE_DICT: any = CUSTOMER_WINBACK_TEMPLATE_DICT;
  const [isModalOpen, setisModalOpen] = useState(false);
  const [isTemplateEnabled, setisTemplateEnabled] = useState<boolean>(false);
  const [templateLanguage, setTemplateLanguage] = useState<any>("ENGLISH");
  const [templateData, setTemplateData] = useState({
    discount: false,
    discountType: "FIXED",
  });
  useEffect(() => {
    if (userId) {
      getTemplateSettings(userId);
    }
  }, [userId]);

  const getTemplateSettings = (userId: string) => {
    getWinbackTemplateSettings(userId).then((res: any) => {
      if (res.status === "success") {
        if (res.settings.language === "NONE") {
          res.settings.language = "ENGLISH";
        }
        setisTemplateEnabled(res.settings.enabled);
        setTemplateLanguage("ENGLISH"); // Forcing English as other languages are not supported
        setTemplateData(res.settings);
      } else {
        showErrorToast(res);
      }
    });
  };

  const enableTemplate = (enabled: boolean) => {
    if (enabled && !checkForApproval()) return;
    if (
      (!phoneData.phone ||
      !phoneData.countryCode ||
      phoneData.phone.length === 0 ||
      phoneData.countryCode.length === 0) &&
      enabled
    ) {
      showErrorToast(
        "Please add WhatsApp support number by going to Settings"
      );
      return;
    }
    var params: any = new URLSearchParams();
    params.append("enabled", enabled);
    updateWinbackTemplateSettings(userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          setisTemplateEnabled(response.settings.enabled);
          showToast(
            "Customer Win-back template has been " +
              (enabled ? "ENABLED" : "DISABLED")
          );
        } else {
          showErrorToast(response);
        }
      },
      (err: any) => {
        showErrorToast("Something went wrong");
      }
    );
  };

  const templateMarkup = () => {
    return (
      <>
        <Layout.Section>
          <div className="template-info-container">
            <div className="Polaris-TextContainer Polaris-TextContainer--spacingTight template-info">
              <div className="templateSwitchMobCss">
                <Heading>Winback</Heading>
                <Switch
                  onChange={() => enableTemplate(!isTemplateEnabled)}
                  checked={isTemplateEnabled}
                  onColor="#5DC001"
                  onHandleColor="#fff"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 6px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  handleDiameter={30}
                />
              </div>
              <TextContainer spacing="loose">
                {isTemplateEnabled ? (
                  <p>
                    Winback your old customers by reaching out to them on
                    whatsapp. You can also give customers a discount coupon code
                    for their next order.<br></br>
                    Message is sent after 7 days, you can configure it in
                    template Settings.
                  </p>
                ) : (
                  <p>
                    Win-back messages help you increase customer loyalty and
                    increase the lifetime value of customers.
                  </p>
                )}
                <br />
                {/* <p>
                  To learn about the cost of sending automated messages, view{" "}
                  <Link
                    external={true}
                    url="https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0"
                  >
                    {"pricing chart"}
                  </Link>
                </p> */}
              </TextContainer>
            </div>
            <div className="Polaris-Card w-100">
              <div className="Polaris-Card__Section break-word">
                <TextContainer>
                  <TextStyle>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          optinEnterpriseTemplates &&
                          templateLanguage === "ENGLISH"
                            ? OPTIN_ENTERPRISE_TEMPLATES.win_back_template
                            : templateData.discount
                            ? templateData.discountType === "PERCENT"
                              ? TEMPLATE_DICT[templateLanguage]
                                  .discountEnabledPercent
                              : TEMPLATE_DICT[templateLanguage]
                                  .discountEnabledCouponCode
                            : TEMPLATE_DICT[templateLanguage]?.discountDisabled,
                      }}
                    ></span>
                    <br />
                    <br />
                    {/* TODO-will be integrated once dynamic preview component is ready */}
                    {/* <Link external={true} url={getAbandonedCartPreview()}>
                        Preview
                      </Link> */}
                  </TextStyle>
                </TextContainer>
              </div>
            </div>
          </div>
        </Layout.Section>
        <div className="template Polaris-Layout__Section Polaris-Layout__Section--secondary m-auto text-center">
          <div className="flex-col w-100">
            <div>
              <button
                className={
                  isTemplateEnabled
                    ? "Polaris-Button Polaris-Button--primary Polaris-Button--outline w-50"
                    : "Polaris-Button Polaris-Button--outline Polaris-Button--disabled w-50"
                }
                type="button"
                disabled={!isTemplateEnabled}
                onClick={() => setisModalOpen(true)}
              >
                <span className="Polaris-Button__Content">
                  <span className="Polaris-Button__Text">
                    <div className="d-flex-center">
                      <div>
                        <Icon source={SettingsMinor} />
                      </div>
                      <div>&nbsp;Settings</div>
                    </div>
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {userId && (
        <>
          {templateMarkup()}
          <SettingsModal
            open={isModalOpen}
            closeModal={() => setisModalOpen(false)}
            showToast={showToast}
            showErrorToast={showErrorToast}
            setTemplateLanguage={setTemplateLanguage}
            templateData={templateData}
            enabled={isTemplateEnabled}
            setTemplateData={setTemplateData}
          />
        </>
      )}
    </>
  );
}

export default CustomerWinback;
