// @ts-nocheck
import {
  AGENT_SETTINGS,
  CREATE_AGENT,
  UPDATE_AGENT,
  UPDATE_AGENT_DETAILS,
  UPDATE_AGENT_TIMINGS,
} from "../../constants/api-constants";
import httpClient from "../../utils/http-client";
import cookie from "react-cookies";
import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
    token: cookie.load("access_token"),
  },
};

export const getAgents = (userId: string) => {
  return httpClient.get(AGENT_SETTINGS.replace("$userId", userId));
};

export const updateAgent = (userId: string, params: any) => {
  return httpClient.put(UPDATE_AGENT.replace("$userId", userId), params);
};

export const createAgent = (userId: string, params: any) => {
  return httpClient.post(CREATE_AGENT.replace("$userId", userId), params);
};
export const updateAgentDetails = (
  userId: string,
  agentId: any,
  params: any
) => {
  return httpClient.post(
    UPDATE_AGENT_DETAILS.replace("$userId", userId).replace(
      "$agentId",
      agentId
    ),
    params
  );
};

export const updateAgentTiming = (
  userId: string,
  agentId: any,
  params: any
) => {
  return axios.post(
    UPDATE_AGENT_TIMINGS.replace("$userId", userId).replace(
      "$agentId",
      agentId
    ),
    params,
    config
  );
};
