// @ts-nocheck
import { useState, useCallback, Fragment, useEffect } from 'react';
import {
  Banner,
  Button,
  Card,
  DisplayText,
  Heading,
  Layout,
  Link,
  List,
  Modal,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  Spinner,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
  Tooltip,
  Checkbox,
  RadioButton,
} from '@shopify/polaris';
import ApplicationForm from './ApplicationForm';
import ProfileDetails from './ProfileDetails';
import SupportedIntegrations from './SupportedIntegrations';
import PaymentFailed from '../../common/PaymentFailed';
import MyWhatsAppPage from './MyWhatsAppPage/MyWhatsAppPage';
import '../../assets/css/waba.scss';
import error from '../../assets/icons/error.png';
import { PlayCircleMajor } from '@shopify/polaris-icons';
import WhatsAppBusinessDemo from '../../assets/images/WhatsAppBusinessDemo.svg';
import { ReactComponent as LeftArrowGreen } from '../../assets/icons/leftArrowGreen.svg';
import { ReactComponent as CircleTickIcon } from '../../assets/icons/circleTick.svg';

import {
  deleteWhatsappPhoto,
  getWabaDetails,
  getWabaDetailsbyId,
  getWabaDetailsbyShopId,
  getWhatsappBusinessDetails,
  getWhatsappPhoto,
  launchWaba,
  loadWabaDetails,
  updateWhatsappAbout,
  updateWhatsappBusinessDetails,
  updateWhatsappLanguage,
  updateWhatsappPhoto,
  retryWabaDetailsbyShopId,
  getWhatsappBusinessVerificationDetails,
} from '../../redux/actions/private-waba';
import { getIntegrationsListForShop } from '../../redux/actions/private-waba';
import qs from 'qs';
import FAQ from './FAQ';
import WhatsappBusiness from '../../assets/icons/whatsappBusiness.png';
import WabaDetails from './WabaDetails';
import '../../assets/css/waba.scss';
import {
  options,
  WHATSAPP_BUSINESS_ACCOUNT,
  MY_WHATSAPP_PAGE,
  TUTORIAL_VIDEOS,
  PLATFORM,
  SHOP_FEATURES,
  PLAN_OLD_PRO,
} from '../../constants/constants';
import { ContextualSaveBar } from '@shopify/polaris';
import TooltipOverlay from '../../common/Tooltip';
import obj from '../../content/WhatsappApi/OverlaysContent/en.json';
import objData from '../../content/WhatsappApi/PostWabaOverlaysContent/en.json';
import GuideModal from '../../common/GuideModal';
import { useHistory, useLocation } from 'react-router-dom';
import {
  recommendedPlans,
  recommendedPlansNew,
} from '../../constants/Pricing/Plans/constants';
import Plans from '../Pricing/Plans';
import { clevertapEventPush, CT_EVENT_IDS } from '../../utils/clevertapUtils';
import LearnMoreModal from './LearnMoreModal/LearnMoreModal';
import InstructionsModal from './InstructionsModal/InstructionsModal';
import { getplanPaymentInfo, shopFeaturesFromStoreData } from '../../utils/helper';
import NudgeScreenContent from '../../common/NudgeScreen';
import DragAndDropImportChats from './DragAndDropImportChats';
import { uploadImportChats } from '../../redux/actions/chats';
import MyWhatsAppModalGuide from './MyWhatsAppModalGuide';
import ApprovalModal from '../ShopifyApprovalModal/ApprovalModal';
import { useShopStore } from '../../hooks/shopStore';
declare global {
  interface Window {
    wabaStatusPollingInProgress: boolean;
  }
}

const WABA = (props: any) => {
  const history = useHistory();
  const { shopDetailsData, planPaymentInfo } = useShopStore();
  let shop = shopDetailsData;
  const [selectedFile, setSelectedFile]: any = useState();
  const [selected, setSelected] = useState(0);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [wabaProfile, setWabaProfile] = useState<any>('');
  const [wabaDetails, setWabaDetails] = useState<any>({});
  const [toast, setToast] = useState('');
  const [errorToast, setErrorToast] = useState('');
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [otpSms, setOtpSms] = useState();
  const [isVerified, setIsVerified] = useState(false);
  const [openTutorial, setopenTutorial] = useState(false);
  const [address, setAddress]: any = useState({
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    vertical: '',
    email: '',
    website: '',
  });
  const [wabaImage, setWABAImage] = useState(
    'https://cdn.shopify.com/s/files/1/0449/7794/6790/files/image_1.png?v=1661258250'
  );
  const [wabaId, setWabaId] = useState('');
  const [language, setLanguage]: any = useState();
  const [about, setAbout]: any = useState();
  const [wabaSettings, setWabaSettings] = useState({
    language: false,
    file: false,
    businessDetails: false,
    about: false,
    data: {},
  });
  const [image, setImage]: any = useState();
  const [timeoutForWabaCall, settimeoutForWabaCall] = useState<number>(10000);
  const [isSupportedIntegrationActivated, setIsSupportedIntegrationActivated] =
    useState(false);
  const [page, setPage] = useState(0);
  const [hasChanges, setHasChanges] = useState(false);
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);
  const location: any = useLocation();
  const showPaymentFailedUI = getplanPaymentInfo(planPaymentInfo);

  const [currentPlanId, setCurrentPlanId] = useState(shopDetailsData?.planId);
  const [recommendedPlanPrice, setRecommendedPlanPrice] = useState(
    [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? recommendedPlans[7][1].price
      : recommendedPlansNew[1].price
  );
  const [recommendedPlanName, setRecommendedPlanName] = useState(
    [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? recommendedPlans[7][1].planName
      : recommendedPlansNew[1].planName
  );
  const [isPrivateWABAEnabled, setIsPrivateWABAEnabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const [readInstructions, setReadInstructions] = useState(false);
  const [wabaVerificationDetails, setWabaVerificationDetails] = useState({
    business_verification_status: '',
    quality_rating: '',
    limit: '',
  });
  const [importChatStep, setImportChatStep] = useState(0);

  const [open_marketing_campaign_modal, setopen_marketing_campaign_modal] =
    useState(false);
  const [marketing_campaign_modal_steps_completed] = useState(false);
  const [guideStep, setGuideStep] = useState(0);
  const [showProPlanUpgradeModal, setShowProPlanUpgradeModal] = useState(false);

  const marketingCampaignModalStepsCompleted = () => {
    setopen_marketing_campaign_modal(false);
  };

  const closeMarketingCampaignGuideModal = () => {
    setopen_marketing_campaign_modal(false);
    if (guideStep < 4) {
      clevertapEventPush({
        eventId: CT_EVENT_IDS.GUIDE_CLOSED,
        eventData: {},
      });
    } else if (guideStep == 4) {
      clevertapEventPush({
        eventId: CT_EVENT_IDS.GUIDE_COMPLETED,
        eventData: {},
      });
    }
  };

  const resetData = () => {
    setHasChanges(false);
    setLanguage('');
    setImage('');
    setWabaSettings({
      language: false,
      file: false,
      businessDetails: false,
      about: false,
      data: {},
    });
    loadDetails(shop.userId);
  };
  const validateEmail = () => {
    var cc =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!address.email || address.email.match(cc)) {
      return false;
    } else {
      return 'Enter a valid email';
    }
  };
  const validateBusinessDetails = () => {
    var mandatory_fields = [
      {
        field_name: 'addressLineOne',
        error_msg: 'Address line 1 cannot be empty',
      },
      {
        field_name: 'addressLineTwo',
        error_msg: 'Address line 2 cannot be empty',
      },
      { field_name: 'state', error_msg: 'State cannot be empty' },
      { field_name: 'zipcode', error_msg: 'Zipcode cannot be empty' },
      { field_name: 'vertical', error_msg: 'Vertical cannot be empty' },
      {
        field_name: 'city',
        error_msg: 'City cannot be empty',
      },
      { field_name: 'country', error_msg: 'Country cannot be empty' },
      { field_name: 'website', error_msg: 'Wesite cannot be empty' },
      { field_name: 'email', error_msg: 'Email cannot be empty' },
    ];

    for (var i = 0; i < mandatory_fields.length; i++) {
      if (!address[mandatory_fields[i].field_name]) {
        return mandatory_fields[i].error_msg;
      }
    }

    let error = validateEmail();
    if (error) {
      return error;
    }
    return null;
  };
  const updateWabaData = async () => {
    try {
      let error = false;
      let message = 'Please enter proper values';
      if (wabaId) {
        let array = [];
        if (wabaSettings.businessDetails) {
          let params = address;
          let value = validateBusinessDetails();
          if (value) {
            error = true;
            message = value;
          } else {
            array.push(
              updateWhatsappBusinessDetails(shop.userId, wabaId, params)
            );
          }
        }
        if (wabaSettings.file) {
          let formdata = new FormData();
          formdata.append('image', image);
          array.push(updateWhatsappPhoto(shop.userId, wabaId, formdata));
        }
        if (wabaSettings.language && wabaSettings.about) {
          let params = qs.stringify({ language: language });
          await updateWhatsappLanguage(shop.userId, wabaId, params).then(() => {
            let params = qs.stringify({ about: about });
            array.push(updateWhatsappAbout(shop.userId, wabaId, params));
          });
        }
        if (wabaSettings.language && !wabaSettings.about) {
          let params = qs.stringify({ language: language });
          array.push(updateWhatsappLanguage(shop.userId, wabaId, params));
        }
        if (!wabaSettings.language && wabaSettings.about) {
          if (about.length > 139) {
            error = true;
            message = 'About text length is exceeded. Maximum length is 139';
          } else {
            let params = qs.stringify({ about: about });
            array.push(updateWhatsappAbout(shop.userId, wabaId, params));
          }
        }
        if (!error) {
          const res: any = await Promise.all(array);
          const data = res.map((res: any) => res);
          if (data) {
            setHasChanges(false);
            setWabaSettings({
              language: false,
              file: false,
              businessDetails: false,
              about: false,
              data: {},
            });
            loadDetails(shop.userId);
          }
        } else {
          setErrorToast(message);
        }
      } else {
        setErrorToast('Waba id not found');
      }
    } catch {
      console.log('Error occured');
    }
  };
  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message='Unsaved changes'
      saveAction={{
        content: 'Save Settings',
        onAction: updateWabaData,
      }}
      discardAction={{
        onAction: () => {
          resetData();
        },
      }}
    />
  ) : null;

  useEffect(() => {
    if (location?.state?.modal === 'guide_modal') {
      setOpenGuideModal(true);
    }
    clevertapEventPush({
      eventId: CT_EVENT_IDS.WHATSAPP_BUSINESS_ACCOUNT_PAGE_VIEWED,
      eventData: {},
    });
  }, []);

  useEffect(() => {
    (window as any).fbAsyncInit = function () {
      // JavaScript SDK configuration and setup
      (window as any).FB.init({
        appId: '2281283925530161', // Facebook App ID
        cookie: true, // enable cookies
        xfbml: true, // parse social plugins on this page
        version: 'v12.0', //Graph API version
      });
    };
    // Load the SDK asynchronously using IIFE
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      (js as any).src = 'https://connect.facebook.net/en_US/sdk.js';
      (fjs as any).parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  useEffect(() => {
    if (shop && shop.userId) {
      loadDetails(shop.userId);
      setIsPrivateWABAEnabled(shopFeaturesFromStoreData(shop).includes(SHOP_FEATURES.PRIVATE));
    }
  }, []);

  const checkIntegrations = (shop: any) => {
    getIntegrationsListForShop(shop.userId).then(
      (response: any) => {
        if (response.status === 'success') {
          if (
            response.integrations.some((integration: any) => integration.active)
          ) {
            setIsSupportedIntegrationActivated(true);
          }
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      },
      (err) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast(
          'Something went wrong. Unable to fetch integrations of shop.'
        );
      }
    );
  };
  const getWabaImage = (userId: any, wabaId: any) => {
    getWhatsappPhoto(userId, wabaId).then((res: any) => {
      if (res && res.image) {
        setWABAImage(res.image);
      }
    });
  };

  const getBusinessDetails = (userId: any, wabaId: any) => {
    getWhatsappBusinessDetails(userId, wabaId).then((res: any) => {
      if (res && res.details) {
        let address = res.details;
        setAddress(address);
      }
    });
  };

  const getWabaVerificationDetails = (userId: any, wabaId: any) => {
    getWhatsappBusinessVerificationDetails(userId, wabaId).then((res: any) => {
      if (res.message) {
        setWabaVerificationDetails({
          business_verification_status:
            res.message.business_verification_status,
          quality_rating: res.message.quality_rating,
          limit: res.message.limit,
        });
      }
    });
  };

  const loadDetails = (userId: string) => {
    setIsDetailsLoading(true);
    setIsLoading(true);
    getWabaDetailsbyShopId(userId).then(
      (response: any) => {
        if (!!(response && response.status)) {
          let waba = response;
          setWabaProfile(waba);
          if (response.status === 'FINALIZE') {
            setAbout(waba.about);
            setOtpSms(waba.otpSms);
            setLanguage(waba.language);
            setSelected(getSelectedTabIndex(waba));
            setWabaId(waba.id);
            getWabaImage(userId, waba.id);
            getBusinessDetails(userId, waba.id);
            getWabaVerificationDetails(userId, waba.id);
            setPage(2);
            setIsLoading(false);
          } else if (response.status === 'RETRY') {
            retryWABADetails(userId, response.id);
          } else if (response.status === 'ERROR') {
            setIsLoading(false);
            setPage(3);
          } else {
            pollWABAStatus(userId, wabaId);
          }
        } else {
          setPage(0);
          setIsLoading(false);
        }
        setIsDetailsLoading(false);
      },
      (err) => {
        setPage(4);
        setIsLoading(false);
        setErrorToast('Something went wrong');
        setIsDetailsLoading(false);
      }
    );
  };

  const getSelectedTabIndex = (waba_profile: any) => {
    if (waba_profile.whatsappVerificationStatus === 'NONE') {
      return 2;
    } else if (
      waba_profile.whatsappVerificationStatus !== 'WHATSAPP_VERIFICATION_DONE'
    ) {
      return 2;
    } else {
      return 0;
    }
  };

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: 'checklist',
      content: 'Checklist before starting',
      accessibilityLabel: 'Checklist before starting',
      panelID: 'checklist',
    },
    {
      id: 'applicationForm',
      content: 'Application Form',
      accessibilityLabel: 'Application Form',
      panelID: 'applicationForm',
    },
    {
      id: 'profile',
      content: 'Profile Details',
      accessibilityLabel: 'Profile Details',
      panelID: 'profile',
    },
  ];

  if (wabaProfile && wabaProfile.live && isSupportedIntegrationActivated) {
    tabs.push({
      id: 'supportedIntegrations',
      content: 'Supported integrations',
      accessibilityLabel: 'Supported integrations',
      panelID: 'supportedIntegrations',
    });
  }

  const proceedToApplicationForm = () => {
    setSelected(1);
  };
  const fetchWABAStatus = (userId: string, wabaId: any) => {
    var interval = setInterval(() => {
      getWabaDetailsbyId(userId, wabaId).then(
        (response: any) => {
          if (response && response.applicationStatus) {
            let waba = response;
            if (waba && waba.status && waba.applicationStatus === 'FINALIZE') {
              loadDetails(userId);
              settimeoutForWabaCall(10000);
              clearInterval(interval);
              window.wabaStatusPollingInProgress = false;
            } else if (
              waba &&
              waba.status &&
              waba.applicationStatus === 'RETRY'
            ) {
              window.wabaStatusPollingInProgress = false;
              retryWABADetails(userId, wabaId);
            } else if (
              waba &&
              waba.status &&
              waba.applicationStatus === 'ERROR'
            ) {
              settimeoutForWabaCall(10000);
              clearInterval(interval);
              window.wabaStatusPollingInProgress = false;
              setPage(3);
            }
          } else {
            window.wabaStatusPollingInProgress = false;
          }
        },
        (err) => {
          window.wabaStatusPollingInProgress = false;
        }
      );

      // 5 minutes delay
    }, timeoutForWabaCall);
  };

  const isPipeLineStatusUnknown = (waba_profile: any) => {
    switch (waba_profile.status) {
      case 'FINALIZE':
        return false;
      default:
        return true;
    }
  };

  const pollWABAStatus = (userId: string, wabaId: any) => {
    if (!window.wabaStatusPollingInProgress) {
      window.wabaStatusPollingInProgress = true;
      fetchWABAStatus(userId, wabaId);
    }
  };

  const isApplicationProcessComplete = () => {
    if (
      wabaProfile.whatsappVerificationStatus === 'WHATSAPP_VERIFICATION_DONE'
    ) {
      return true;
    } else {
      return false;
    }
  };
  const launchWhatsAppSignup = () => {
    (window as any).FB.login(
      function (response: any) {
        setIsLoading(true);
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          if (accessToken) checkIfWabaExistForFBUser(accessToken);
        } else {
          setIsLoading(false);
        }
      },
      {
        scope: 'business_management,whatsapp_business_management',
        extras: {
          feature: 'whatsapp_embedded_signup',
        },
      }
    );
  };

  const retryWABADetails = (userId: any, waba_id: any) => {
    let params = qs.stringify({ id: waba_id });
    retryWabaDetailsbyShopId(userId, waba_id, params)
      .then((res) => {
        pollWABAStatus(userId, waba_id);
      })
      .catch((e) => {
        pollWABAStatus(userId, waba_id);
      });
  };

  const checkIfWabaExistForFBUser = async (token: any) => {
    let params = qs.stringify({ inputToken: token });
    let userId = shop.userId;
    getWabaDetails(userId, params)
      .then((res: any) => {
        if (res.status === 'success') {
          let response = res;
          response = {
            wabaId: res.message.wabaId,
            wabaNumber: res.message.wabaNumber,
          };
          let queryParams = qs.stringify(response);
          launchWaba(userId, queryParams)
            .then((result: any) => {
              if (result?.id) {
                setWabaId(result.id);
                getWabaDetailsbyId(userId, result.id)
                  .then((waba: any) => {
                    if (waba) {
                      if (waba.applicationStatus === 'FINALIZE') {
                        setIsLoading(false);
                        setPage(2);
                        loadDetails(userId);
                      } else if (waba.applicationStatus === 'ERROR') {
                        setIsLoading(false);
                        setPage(3);
                      } else if (waba.applicationStatus === 'RETRY') {
                        retryWABADetails(userId, result.id);
                      } else {
                        pollWABAStatus(userId, result.id);
                      }
                    }
                  })
                  .catch((err: any) => {
                    console.log(err);
                    setErrorToast('Error while fetching waba details');
                    setIsLoading(false);
                  });
              }
            })
            .catch((err: any) => {
              console.log(err);
              setIsLoading(false);
            });
        } else {
          setErrorToast('Error while fetching waba details');
        }
      })
      .catch((err: any) => {
        console.log(err);
        setErrorToast('Error while fetching waba details');
        setIsLoading(false);
      });
  };

  const handleChange = (value: any, key: any) => {
    setAddress({ ...address, [key]: value });
    // setHasChanges(true);
    setWabaSettings({ ...wabaSettings, businessDetails: true });
  };
  const clickInputField = () => {
    (document as any).getElementById('uploadWhatsappImage').click();
  };

  const fileUpload = (e: any) => {
    //5MB === 5242880
    if (e.target?.files.length < 1 || e.target.files[0].size > 5242880) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast(
        MY_WHATSAPP_PAGE.WABA_BUSINESS_DETAIL_SECTION.IMAGE_UPLOAD_ERROR
      );
    } else {
      let imageUrl = (URL as any).createObjectURL(e.target.files[0]);
      setWABAImage(imageUrl);
      setImage(e.target.files[0]);
      setHasChanges(true);
      setWabaSettings({ ...wabaSettings, file: true });
    }
  };

  const removePhoto = () => {
    deleteWhatsappPhoto(shop.userId, wabaId).then((res: any) => {
      if (res) {
        setWABAImage(
          'https://cdn.shopify.com/s/files/1/0449/7794/6790/files/image_1.png?v=1661258250'
        );
      }
    });
  };

  const changeLanguage = (lang: any) => {
    if (wabaProfile.language !== lang) {
      setLanguage(lang);
      // setHasChanges(true);
      setWabaSettings({ ...wabaSettings, language: true });
    } else {
      if (!wabaSettings.file && !wabaSettings.businessDetails) {
        setHasChanges(false);
      }
      setWabaSettings({ ...wabaSettings, language: false });
    }
  };

  const changeAbout = (e: any) => {
    if (wabaProfile.about !== e && e !== '') {
      setAbout(e);
      // setHasChanges(true);
      setWabaSettings({ ...wabaSettings, about: true });
    } else {
      if (
        !wabaSettings.file &&
        !wabaSettings.businessDetails &&
        !wabaSettings.language
      ) {
        setHasChanges(false);
      }
      setWabaSettings({ ...wabaSettings, about: false });
    }
  };

  const contactUs = () => {
    let subject = 'Issue while creating private waba ';
    subject = encodeURIComponent(subject);
    window.open(
      `mailto:support@superlemon.xyz?subject=${subject}` + shop.url,
      '_blank'
    );
  };

  const [selectedRButton, setSelectedRButton] = useState(
    WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.R1
  );

  const startWABAFlow = () => {
    setShowInstructionModal(false);
    clevertapEventPush({
      eventId: CT_EVENT_IDS.FACEBOOK_EMBEDDED_FLOW_STARTED,
      eventData: {},
    });
    launchWhatsAppSignup();
  };

  const importChats = async () => {
    if (selectedFile) {
      let formdata = new FormData();
      formdata.append('file', selectedFile);
      formdata.append('fileType', selectedFile.type);
      const res = await uploadImportChats(shop.userId, formdata);
      setSelectedFile('');
      if (res.status) {
        setToast('Import successful');
        setImportChatStep(2);
      }
    } else {
      setErrorToast('No File Selected');
    }
  };

  const onUpload = () => {
    console.log('uploaded');
  };

  const afterUploadMarkup = (
    <div className='afterUploadMarkup'>
      <CircleTickIcon style={{ marginBottom: '20px' }} />
      <div className='header'>
        {WHATSAPP_BUSINESS_ACCOUNT.AFTERIMPORTCHATS.TITLE}
      </div>
      <div className='note'>
        {WHATSAPP_BUSINESS_ACCOUNT.AFTERIMPORTCHATS.NOTE}
      </div>
      <div className='customButton'>
        <Button primary onClick={() => startWABAFlow()}>
          {WHATSAPP_BUSINESS_ACCOUNT.AFTERIMPORTCHATS.BUTTON.CONTINUE}
        </Button>
      </div>
    </div>
  );

  const uploadMarkup = (
    <div className='quickOverviewMarkup'>
      <TextContainer spacing='loose'>
        <p className='wabaHeader'>
          {WHATSAPP_BUSINESS_ACCOUNT.IMPORTCHATS.TITLE}{' '}
        </p>
        <div
          className='flexStart'
          dangerouslySetInnerHTML={{
            __html: `${WHATSAPP_BUSINESS_ACCOUNT.IMPORTCHATS.NOTE}`,
          }}
          style={{ color: '#6D7175' }}
        ></div>
      </TextContainer>
      <div className='FilesDragAndDrop__importChats'>
        <DragAndDropImportChats
          onUpload={onUpload}
          setErrorToast={setErrorToast}
          userId={shop.userId}
          setSelectedFile={setSelectedFile}
        />
      </div>
      <div className='wabaGOBACK'>
        <span
          style={{ color: '#5EC127', cursor: 'pointer' }}
          onClick={() => {
            setSelectedFile('');
            setImportChatStep(0);
          }}
        >
          <LeftArrowGreen />{' '}
          {WHATSAPP_BUSINESS_ACCOUNT.IMPORTCHATS.BUTTON.GO_BACK}
        </span>
      </div>

      <div className='wabaActionButton flexEnd'>
        <div className='customButtonGray'>
          <Button
            onClick={() => {
              setSelectedFile('');
              startWABAFlow();
            }}
          >
            {WHATSAPP_BUSINESS_ACCOUNT.IMPORTCHATS.BUTTON.SKIP}
          </Button>
        </div>
        <div className='customButton'>
          <Button
            disabled={!selectedFile}
            primary
            onClick={() => importChats()}
          >
            {WHATSAPP_BUSINESS_ACCOUNT.IMPORTCHATS.BUTTON.IMPORT}
          </Button>
        </div>
      </div>
    </div>
  );

  const quickOverviewMarkup = (
    <div className='quickOverviewMarkup'>
      <TextContainer spacing='loose'>
        <p className='wabaHeader'>
          {WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.TITLE}{' '}
        </p>
        <div
          className='flexStart'
          dangerouslySetInnerHTML={{
            __html: `${WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.P1}`,
          }}
        ></div>
        <div
          className='flexStart'
          dangerouslySetInnerHTML={{
            __html: `${WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.P2}`,
          }}
        ></div>
        <>
          <RadioButton
            label={WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.R1}
            checked={
              selectedRButton === WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.R1
            }
            onChange={() => {
              setSelectedRButton(WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.R1);
            }}
          />
          <RadioButton
            label={WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.R2}
            checked={
              selectedRButton === WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.R2
            }
            onChange={() => {
              setSelectedRButton(WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.R2);
            }}
          />
        </>
      </TextContainer>

      <div className='overViewFbButton'>
        <Checkbox
          label={WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.DISCLMR}
          checked={readInstructions}
          disabled={false}
          onChange={(value) => setReadInstructions(value)}
        />
        <div className='note'>
          <p>{WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.NOTE}</p>
        </div>
      </div>
      <div className='wabaActionButton'>
        <div className='customButton'>
          <Button
            primary
            disabled={!readInstructions}
            onClick={() => {
              if (
                selectedRButton === WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.R1
              ) {
                clevertapEventPush({
                  eventId: CT_EVENT_IDS.WHATSAPP_CHAT_IMPORT,
                  eventData: {},
                });
                setSelectedFile('');
                setImportChatStep(1);
              } else {
                startWABAFlow();
              }
            }}
          >
            {WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.BUTTON.PROCEED}
          </Button>
        </div>
        <div className='customButtonGray'>
          <Button
            onClick={() => {
              clevertapEventPush({
                eventId: CT_EVENT_IDS.WHATSAPP_APPLICATION_PAGE_VIEWED,
                eventData: {},
              });
              setReadInstructions(false);
              setShowInstructionModal(false);
              setImportChatStep(0);
            }}
          >
            {WHATSAPP_BUSINESS_ACCOUNT.INSTRUCTIONS.BUTTON.CANCEL}
          </Button>
        </div>
      </div>
    </div>
  );

  const introOverviewMarkup = (
    <div className='wabaMainDiv quickOverviewMarkup intoOverviewMarkup backgroundImage'>
      <div className='introDocs'>
        <TextContainer spacing='loose'>
          <p className='wabaHeader'>
            {WHATSAPP_BUSINESS_ACCOUNT.INTRO.HEADING}
          </p>
          <div className='flexStart'>
            <div className='descpText'>
              {WHATSAPP_BUSINESS_ACCOUNT.INTRO.DESC1}
            </div>
          </div>
          <div className='flexStart'>
            <div className='descpText'>
              {WHATSAPP_BUSINESS_ACCOUNT.INTRO.DESC2}
            </div>
          </div>
          <div className='flexStart'>
            <div className='descpText'>
              {WHATSAPP_BUSINESS_ACCOUNT.INTRO.DESC3}
            </div>
          </div>
          <div className='wabaActionButton'>
            <div className='customButton'>
              <Button
                primary
                onClick={() => {
                  clevertapEventPush({
                    eventId: CT_EVENT_IDS.WHATSAPP_APPLICATION_PAGE_VIEWED,
                    eventData: {},
                  });
                  // setPage(1);
                  if (
                    Number(shop.planId) === PLAN_OLD_PRO &&
                    !shop.featuresList.includes(SHOP_FEATURES.PRIVATE)
                  ) {
                    setShowProPlanUpgradeModal(true);
                  } else {
                    setShowInstructionModal(true);
                  }
                }}
              >
                {WHATSAPP_BUSINESS_ACCOUNT.INTRO.SETUP_WABA}
              </Button>
            </div>
            <div className='customButtonGray'>
              <Button
                onClick={() => {
                  window.open(
                    WHATSAPP_BUSINESS_ACCOUNT.INTRO.KNOW_MORE_LINK,
                    '_blank'
                  );
                }}
              >
                {WHATSAPP_BUSINESS_ACCOUNT.INTRO.KNOW_MORE}
              </Button>
            </div>
          </div>
        </TextContainer>
      </div>
      <div className='introImage'>
        <img src={WhatsAppBusinessDemo} alt='WhatsApp_Business_Demo' />
      </div>
    </div>
  );

  const loader = (
    <div className='wabaLoader'>
      <div>
        <div className='flexCenter spinLoader'>
          <Spinner accessibilityLabel='Spinner example' size='large' />
        </div>
        <div className='flexCenter'>
          <TextContainer>
            This may take few minutes. Please be patient while we set up your
            acount. Do not close the window in the meanwhile
          </TextContainer>
        </div>
      </div>
    </div>
  );

  const waitingWindow = (
    <Layout>
      <div style={{ padding: '45px 0' }}>
        <div className='flexCenter'>
          <img
            src='https://cdn.shopify.com/s/files/1/0449/7794/6790/files/sand-clock-svgrepo-com_1timer.svg?v=1662704181'
            width='100px'
            alt='alternatetext'
          ></img>
        </div>
        <br />
        <div className='flexCenter helpText'>
          Hold tight! your private WhatsApp business<br></br> account is under
          review
        </div>
        <div className='borderLine'></div>
        <div className='flexCenter customButton'>
          <Button onClick={() => setOpenGuideModal(true)}>
            Read the best practices to get you account approved
          </Button>
        </div>
        {new Date().getTime() - wabaProfile.modifiedOn >= 300000 && (
          <div className='flexCenter remove'>
            <Button onClick={contactUs}> Contact US</Button>
          </div>
        )}
      </div>
    </Layout>
  );

  const contactUS = (
    <div>
      {' '}
      <div style={{ padding: '30px' }}>
        <div className='wabaLoader'>
          <div>
            <div className='flexCenter spinLoader'>
              <img src={error} alt='error' />
            </div>
            <div className='flexCenter spinLoader'>
              <strong>OOPS! Something went wrong</strong>
            </div>
            <div className='flexCenter customButton'>
              <Button onClick={contactUs} primary>
                {' '}
                Contact US
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const setupPage = (
    <>
      <div className='wabaMainDiv quickOverviewMarkup'>
        <div>
          <TextContainer spacing='loose'>
            <p className='wabaHeader'>Set up your WhatsApp Business account </p>
          </TextContainer>
          <div className='setupDiv'>
            <div className='wrapperNumber'>
              {' '}
              <div className='positionRelative'>
                <div className='leftWABA'>1</div>
                <div className='absoluteTopRight'>Business details</div>
              </div>
              <hr className='divider' />
              <div className='positionRelative'>
                <div className='leftWABA'>2</div>
                <div className='absoluteTopRight'>Preview</div>
              </div>
              <hr className='divider' />
              <div className='positionRelative'>
                <div className='leftWABA'>3</div>
                <div className='absoluteTopRight'>Complete verification</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '100px' }}>
          {isLoading ? (
            !!wabaProfile ? (
              waitingWindow
            ) : (
              loader
            )
          ) : (
            <Layout>
              <Layout.Section>
                <div style={{ marginTop: '20px' }}>
                  <TextContainer>
                    <p>
                      You'll be directed to the Facebook Business Manager
                      pop-up, where you need to just go through the steps.
                    </p>
                    <p className='gupshupInsText'>
                      Gupshup is the parent company of Superlemon through which
                      your account will be created
                    </p>
                  </TextContainer>
                </div>
              </Layout.Section>
              <Layout.Section secondary>
                <div>
                  <img
                    className='whatsappImage '
                    src={WhatsappBusiness}
                    alt={'waba'}
                  />
                  <div className='spaceEven marginTop'>
                    <div className='customButtonGray'>
                      <Button onClick={() => setPage(0)}>Back</Button>
                    </div>
                    <div className='customButton'>
                      <Button
                        primary
                        onClick={() => {
                          clevertapEventPush({
                            eventId:
                              CT_EVENT_IDS.FACEBOOK_EMBEDDED_FLOW_STARTED,
                            eventData: {},
                          });
                          launchWhatsAppSignup();
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </Layout.Section>
            </Layout>
          )}
        </div>
      </div>
    </>
  );

  const addressDetails = (
    <div style={{ padding: '20px' }}>
      <div
        className='flexStart margin10'
        style={{ fontWeight: 'bold', marginBottom: '10px' }}
      >
        Business Details
      </div>
      <div style={{ marginBottom: '10px' }}>
        <TextContainer>
          These address will always show in the profile section to users who
          chat with you on WhatsApp. We will submit it to WhatsApp for approval
          once you edit it
        </TextContainer>
      </div>
      <Layout>
        <Layout.Section oneThird>
          <div className='businessDetails'>
            <TextField
              label='Address Line 1*'
              onChange={(e) => handleChange(e, 'addressLineOne')}
              autoComplete='off'
              value={address?.addressLineOne ?? ''}
            />
          </div>
          <div className='businessDetails'>
            <TextField
              label='State (if applicable*)'
              onChange={(e) => handleChange(e, 'state')}
              autoComplete='off'
              value={address?.state ?? ''}
            />
          </div>
          <div className='businessDetails'>
            <TextField
              label='Select business vertical *'
              onChange={(e) => handleChange(e, 'vertical')}
              autoComplete='off'
              value={address?.vertical ?? ''}
            />
          </div>
        </Layout.Section>
        <Layout.Section oneThird>
          <div className='businessDetails'>
            <TextField
              label='Address Line 2*'
              onChange={(e) => handleChange(e, 'addressLineTwo')}
              autoComplete='off'
              value={address?.addressLineTwo ?? ''}
            />
          </div>
          <div className='businessDetails'>
            <TextField
              type='number'
              label='Zip code/Postcode*'
              onChange={(e) => handleChange(e, 'zipcode')}
              autoComplete='off'
              value={address?.zipcode ?? ''}
            />
          </div>
          <div className='businessDetails'>
            <TextField
              label='Email id*'
              onChange={(e) => handleChange(e, 'email')}
              type={'email'}
              autoComplete='off'
              value={address?.email ?? ''}
            />
          </div>
        </Layout.Section>
        <Layout.Section oneThird>
          <div className='businessDetails'>
            <TextField
              label='City *'
              onChange={(e) => handleChange(e, 'city')}
              autoComplete='off'
              value={address?.city ?? ''}
            />
          </div>
          <div className='businessDetails'>
            <TextField
              label='Select Country *'
              onChange={(e) => handleChange(e, 'country')}
              autoComplete='off'
              value={address?.country ?? ''}
            />
          </div>
          <div className='businessDetails'>
            <TextField
              label='Website *'
              type='url'
              onChange={(e) => handleChange(e, 'website')}
              autoComplete='off'
              value={address?.website ?? ''}
            />
          </div>
        </Layout.Section>
      </Layout>
    </div>
  );

  const displayWaba = (
    <>
      <div className='wabaCard '>
        <div className='businessImageDiv'>
          <TextContainer spacing='loose'>
            <p className='wabaHeader'>WhatsApp Business account </p>
          </TextContainer>
          <Layout>
            <Layout.Section oneHalf>
              <div className='flexCenter '>
                <div>
                  <div className='flexCenter'>
                    <img
                      alt='whatsappDp'
                      className='businessImage'
                      width={'150px'}
                      height={'150px'}
                      src={
                        wabaImage ??
                        'https://cdn.shopify.com/s/files/1/0449/7794/6790/files/image_1.png?v=1661258250'
                      }
                    />
                  </div>
                  <div className='flexCenter businessDesc'>
                    This will be whatsapp display picture of your business
                  </div>
                  <div className='flexCenter businessButton'>
                    {wabaImage !==
                      'https://cdn.shopify.com/s/files/1/0449/7794/6790/files/image_1.png?v=1661258250' && (
                      <div className='remove'>
                        <Button onClick={removePhoto}>Remove Photo</Button>{' '}
                      </div>
                    )}
                    <div className='uploadImage'>
                      <input
                        onChange={fileUpload}
                        accept='image/png, image/jpeg, image/jpg'
                        id='uploadWhatsappImage'
                        type='file'
                        hidden
                      />
                      <Button onClick={clickInputField}>Upload Photo</Button>
                    </div>
                  </div>

                  <div className='flexCenter businessIns'>
                    Display picture should be of 640px and max size 5 MB
                  </div>
                  <div className='flexCenter businessIns'>
                    <TextField
                      label=''
                      value={about}
                      multiline={3}
                      onChange={changeAbout}
                      placeholder={'About'}
                    ></TextField>
                  </div>
                </div>
              </div>
            </Layout.Section>
            <Layout.Section oneHalf>
              <div className='wabaCard'>
                <div className=''>
                  <p className='wabaSubTitle'>
                    We have set up your account, you are now ready to send
                    messages.
                  </p>
                  <div className='wabaSubDiv'>
                    <div className='wabaSubdiv'>
                      <div className='wabaKey'>
                        Phone Number {wabaDetails.wabaNumber}
                      </div>
                      <div className='wabaValue'>
                        <TextField
                          value={wabaProfile.wabaNumber}
                          label=''
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                    <div className='wabaSubdiv'>
                      <div className='wabaKey'>
                        WhatsApp Business Account ID
                      </div>
                      <div className='wabaValue'>
                        <TextField
                          value={wabaProfile.wabaId}
                          label=''
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                    {/* <div className='wabaSubdiv'>
                      <div className='wabaKey'>Facebook Business Manager </div>
                      <div className='wabaValue'>
                        <TextField
                          value={
                            wabaProfile.status === 'FINALIZE'
                              ? 'Verified'
                              : 'Unverified'
                          }
                          label=''
                          onChange={() => {}}
                        />
                      </div>
                      <div className='wabaToolTip'>
                        <Tooltip
                          content={
                            <TooltipContent fieldName={'facebook_business'} />
                          }
                        >
                          <div className='d-flex'>
                            <QuestionMark />
                          </div>
                        </Tooltip>
                      </div>
                    </div> */}
                    <div className='wabaSubdiv'>
                      <div className='wabaKey'>
                        Default language for messages
                      </div>
                      <div className='wabaValue'>
                        <Select
                          label=''
                          placeholder=''
                          options={options['WABA_LANGUAGE']}
                          value={language ? language : wabaProfile.language}
                          onChange={(val) => changeLanguage(val)}
                        />{' '}
                      </div>
                    </div>
                    {isVerified && (
                      <div className='wabaSubdiv'>
                        <div className='wabaKey'>Facebook Business Manager</div>
                        <div className='wabaValue'>
                          {' '}
                          <TextField
                            value='test'
                            label=''
                            onChange={() => {}}
                          />
                        </div>
                      </div>
                    )}
                    <div className='customButton'>
                      <Button onClick={() => history.push('campaigns')} primary>
                        Start sending messages
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Layout.Section>
          </Layout>
        </div>
      </div>
      <br></br>
      {addressDetails}
      <WabaDetails isVerified={isVerified} />
    </>
  );
  const errorToastMarkup = errorToast ? (
    <Toast
      error
      content={errorToast}
      onDismiss={() => {
        setErrorToast('');
      }}
    />
  ) : null;

  const showContent = () => {
    if (importChatStep === 1) return uploadMarkup;
    else if (importChatStep === 2) return afterUploadMarkup;
    else return quickOverviewMarkup;
  };

  return isPrivateWABAEnabled ? (
    <>
      {showPaymentFailedUI.downgraded ? (
        <PaymentFailed failedOn={showPaymentFailedUI.downgradedOn} />
      ) : (
        <div className='waba'>
          <div className='videoTutsButton tooltip-overlay padRight5'>
            <Button
              icon={PlayCircleMajor}
              onClick={() => {
                setopenTutorial(true);
              }}
            >
              {TUTORIAL_VIDEOS.TITLE}
            </Button>
          </div>
          <TooltipOverlay
            btn1Action={() =>
              wabaProfile
                ? setOpenGuideModal(true)
                : setopen_marketing_campaign_modal(true)
            }
            obj={wabaProfile ? objData : obj}
          />
          {open_marketing_campaign_modal ? (
            <MyWhatsAppModalGuide
              open={true}
              completed={marketing_campaign_modal_steps_completed}
              closeModal={closeMarketingCampaignGuideModal}
              stepsCompleted={marketingCampaignModalStepsCompleted}
              shopName={shopDetailsData}
              setGuideStep={setGuideStep}
            />
          ) : null}
          {openGuideModal && (
            <GuideModal
              open={true}
              closeModal={closeGuideModal}
              obj={objData}
              tabIndex={0}
            />
          )}
          <Page title={WHATSAPP_BUSINESS_ACCOUNT.TITLE}>
            <Stack distribution='fill' wrap={false} alignment='center'></Stack>
            {ctxSaveBarMarkup}
            {page === 0 && (
              <>
                {isLoading ? (
                  !!wabaProfile ? (
                    waitingWindow
                  ) : (
                    <div className='wabaMainDiv quickOverviewMarkup'>
                      {' '}
                      <div style={{ marginTop: '100px' }}>{loader}</div>
                    </div>
                  )
                ) : (
                  <>
                    {introOverviewMarkup}
                    <FAQ />
                    <InstructionsModal
                      setShowInstructionModal={setShowInstructionModal}
                      showInstructionModal={showInstructionModal}
                      content={showContent()}
                      setImportChatStep={setImportChatStep}
                    />
                    <LearnMoreModal
                      setShowModal={setShowModal}
                      showModal={showModal}
                    />
                  </>
                )}
              </>
            )}
            {page === 1 && setupPage}
            {/* {page === 2 && displayWaba} */}
            {page === 2 && (
              <MyWhatsAppPage
                wabaVerificationDetails={wabaVerificationDetails}
                wabaProfile={wabaProfile}
                about={about}
                language={language}
                address={address}
                wabaImage={wabaImage}
                fileUpload={fileUpload}
                clickInputField={clickInputField}
                changeLanguage={changeLanguage}
                changeAbout={changeAbout}
                handleChange={handleChange}
                updateWabaData={updateWabaData}
                resetData={resetData}
                wabaCurrentState={wabaSettings}
              />
            )}
            {page === 3 && contactUS}
            <Modal
              open={openTutorial}
              sectioned={true}
              title={TUTORIAL_VIDEOS.MY_WHATSAPP_PAGE.content}
              onClose={() => {
                setopenTutorial(false);
                document.body.classList.remove('tutorial-modal');
              }}
            >
              <iframe
                id='ytplayer'
                width='100%'
                height='360'
                allow='fullscreen'
                src={TUTORIAL_VIDEOS.MY_WHATSAPP_PAGE.videoSrc}
                frameBorder='0'
                title={'Extension Tutorial Video'}
              ></iframe>
            </Modal>
            {errorToastMarkup}
          </Page>
          <ApprovalModal
            showModal={showProPlanUpgradeModal}
            setShowModal={setShowProPlanUpgradeModal}
            feature={'Whatsapp Business API'}
          />
        </div>
      )}
    </>
  ) : (
    <>
      {showPaymentFailedUI.downgraded ? (
        <PaymentFailed failedOn={showPaymentFailedUI.downgradedOn} />
      ) : (
        <Page
          title='Apply for WhatsApp Business API'
          // secondaryActions={[
          //   {
          //     content: 'Tutorial video',
          //     icon: PlayCircleMajor,
          //     accessibilityLabel: 'Tutorial Video',
          //     outline: true,
          //     onAction: () => {
          //       console.log('Open Tutorial Modal');
          //     },
          //   },
          // ]}
        >
          <br />
          <br />
          <div className='nudgeScreen-singlebox'>
            <div>
              <TextContainer>
                <Heading>
                  <TextStyle variation='strong'>
                    Why you should consider Whatsapp API?
                  </TextStyle>
                </Heading>
                <TextStyle variation='subdued'>
                  <br />
                  <br />
                  With Whatsapp API, you will get access to your very own
                  Whatsapp API account. This account gives you ability to:
                  <br />
                  <br />
                </TextStyle>
              </TextContainer>

              <TextContainer>
                <TextStyle variation='subdued'>
                  <List type='bullet'>
                    <List.Item>
                      Send Customized template messages and Promotional messages
                      to your customers at scale
                    </List.Item>
                    <List.Item>
                      Deploy chatbots for FAQ/Commerce/Support
                    </List.Item>
                    <List.Item>
                      Boost up your branding by getting a green tick for your
                      Whatsapp account
                    </List.Item>
                  </List>
                </TextStyle>
              </TextContainer>

              <TextContainer>
                <TextStyle variation='subdued'>
                  <br />
                  <br />
                  Whatsapp API is not included in your plan. Please upgrade to{' '}
                  {recommendedPlanName} Plan at just ${recommendedPlanPrice}.
                  Take your business to next level by engaging with your
                  customers like a pro!
                  <br />
                  <br />
                  To learn more about plans &nbsp;
                  <Link
                    onClick={() => {
                      window.location.href = '/pricing';
                    }}
                  >
                    Click here
                  </Link>
                </TextStyle>
              </TextContainer>
            </div>
            {[PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform) ? (
              <NudgeScreenContent />
            ) : (
              <Plans
                page='Private WABA'
                planId={currentPlanId}
                setPlanId={props.setPlanId}
                upgradePlanButton={false}
                setShowUpgradePlanDiv={() => {}}
                shopId={shop.id}
                userid={shop.userId}
                recommendedPlanId={
                  recommendedPlans[Number(currentPlanId)][1].planId
                }
                setRecommendedPlanPrice={setRecommendedPlanPrice}
                setRecommendedPlanName={setRecommendedPlanName}
              />
            )}
          </div>
        </Page>
      )}
    </>
  );
};

export default WABA;
