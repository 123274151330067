import { Heading, Modal, Stack, TextContainer } from "@shopify/polaris";
import "../../assets/css/releaseNotes.scss";

const Release_22_05_01_Modal = (props: any) => {
  const modalContentGuideStep = () => {
    return (
      <TextContainer>
        <p>
          We at Superlemon, always strive to help our merchants achieve
          excellence. With the same belief, we have made a few improvements to
          the product. The highlights of the 22.12.3 release can be found below:
        </p>
        <p className="release-heading ">
          <Heading>1. Welcome to the new world of WhatsApp Campaigns!</Heading>
          <ul>
            <li>Bug removal in the template creation process.</li>
            <li>Ready to use Campaign Templates.</li>
            <li>
              Create & manage templates from the templates subsection in the
              campaigns tab.
            </li>
          </ul>

          <Heading>2. All new WhatsApp Business API!</Heading>
          <ul>
            <li>Smoother Onboarding</li>
            <li>
              Know more about your WhatsApp Business API account's health,
              messaging limit & quality Rating.
            </li>
          </ul>

          <Heading>3. WhatsApp manual messaging made easier!</Heading>
          <ul>
            <li>
              Create & manage templates for extension tool templates & manual
              messaging templates from one place.
            </li>
          </ul>
          <p>
            Additionally, to help you serve your customers even better, we have
            also enabled automated Abandoned Cart Notifications, Order
            Confirmation & Shipment messages, and Payment Confirmation messages
            by default for all the Free Plan Users.
          </p>
        </p>

        <Heading>Details:</Heading>
        <p>
          We have revamped the process to trigger WhatsApp marketing campaigns.
          The process is simple like never before! You can create & manage all
          of your WhatsApp templates by going to the templates subsection in the
          campaigns tab. The template creation process is free of bugs!
        </p>
        <img
          className="checkoutOptinModalImageMoreWidth pd3rem"
          src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Release_Modal_1.png?v=1672920527"
          alt="marketing campaigns"
        />

        <br />
        <br />
        <p>
          Additionally, we understand your business needs. We have also
          introduced a set of ready-to-use campaign templates. These templates
          include templates for offer announcements, back-in-stock
          notifications, New launch announcements, End of season sale
          notifications, etc. You can access these templates by selecting the
          default template button in the templates section.
        </p>
        <img
          className="checkoutOptinModalImageMoreWidth pd3rem"
          src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Release_Modal_2.png?v=1672920527"
          alt="campaign templates"
        />

        <br />
        <br />
        <p>
          We understand that it's very your WhatsApp Business API plays an
          important role in your business's success. We have made the onboarding
          smooth & bug-free. You can go to “My WhastApp” section & click on
          “Set-up” now button to start the onboarding on WhatsApp Business API.
        </p>
        <img
          className="checkoutOptinModalImageMoreWidth pd3rem"
          src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Release_Modal_3.png?v=1672920528"
          alt="My WhastApp"
        />

        <br />
        <br />
        <p>
          Once your WhatsApp business API is created, you can also check the
          health, quality rating & messaging limit on your WhatsApp business API
          account in “My WhatsApp” section.
        </p>
        <img
          className="checkoutOptinModalImageMoreWidth pd3rem"
          src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Release_Modal_4.png?v=1672920527"
          alt="My WhastApp"
        />

        <br />
        <br />
        <p>
          {`Creating & managing templates for the manual messages will no longer be a Hassle. Create & manage all of the templates 
          from the “Messaging” section. Go create/manage templates Go to Messaging > Message templates.`}
        </p>
        <img
          className="checkoutOptinModalImageMoreWidth pd3rem"
          src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Release_Modal_5.png?v=1672920527"
          alt="Message templates"
        />

        <br />
        <br />
        <p>
          {`You can also download the Mobile app/chrome extension from this section, by clicking on the “Install Now” button on the tile.`}
        </p>
        <img
          className="checkoutOptinModalImageMoreWidth pd3rem"
          src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Release_Modal_6.png?v=1672920527"
          alt="chrome extension"
        />
      </TextContainer>
    );
  };

  const handleClose = () => {
    props.closeModal();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
      title={"Release - 23.01.09"}
      secondaryActions={[
        {
          content: "Close",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>{modalContentGuideStep()}</Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
export default Release_22_05_01_Modal;
