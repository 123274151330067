import {
  Card,
  Checkbox,
  FormLayout,
  RadioButton,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import {
  DEVICE_TYPE_DESKTOP_ONLY,
  DEVICE_TYPE_MOBILE_AND_DESKTOP,
  PLATFORM,
} from "../../../../constants/constants";
import DevicesType from "../../../../types/Devices.type";
import { objectDifference } from "../../../../utils/helper";

const ShareDesktopConfig = (props: any) => {
  const [initialData, setInitialData] = useState<DevicesType>(props.data);
  const [data, setData] = useState<DevicesType>(props.data);
  const [refData, setRefData] = useState<DevicesType>(props.data);

  const [
    activateErrorCheckForVerticalOffsetShare,
    setActivateErrorCheckForVerticalOffsetShare,
  ] = useState(false);

  const [updatedData, setUpdatedData] = useState({});

  useEffect(() => {
    setRefData(props.data);
    setData(props.data);
    setInitialData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (props.isDataSubmitted) {
      setRefData(data);
      setInitialData(data);
    }
  }, [props.isDataSubmitted]);

  useEffect(() => {
    var diffObj: any = objectDifference(refData, data);
    setUpdatedData({});
    if (Object.keys(diffObj).length !== 0) {
      switch (props.selected) {
        case DEVICE_TYPE_MOBILE_AND_DESKTOP:
        case DEVICE_TYPE_DESKTOP_ONLY:
          setDataToUpdate(diffObj);
          break;
      }
    } else {
      setUpdatedData({});
    }
  }, [data]);

  useEffect(() => {
    // props.onChanges(updatedData);
    let errorMessage = getErrorMessage();
    if (errorMessage) {
      props.onError(errorMessage, "desktop");
      props.onChanges(null);
    } else {
      props.onError("", "desktop");
      props.onChanges(updatedData);
    }
  }, [updatedData]);

  useEffect(() => {
    switch (props.selected) {
      case DEVICE_TYPE_MOBILE_AND_DESKTOP:
        props.onChanges(updatedData);
        break;
      case DEVICE_TYPE_DESKTOP_ONLY:
        props.onChanges(updatedData);
        break;
    }
  }, [props.selected]);

  useEffect(() => {
    if (props.reset) {
      setRefData(initialData);
      setData(initialData);
    }
  }, [props.reset]);

  const getErrorMessage = () => {
    if (!data.sharePosition) {
      return "Invalid Share Button Position";
    } else if (validateVerticalOffsetShare() !== false) {
      return "Invalid Height Offset";
    } else {
      return false;
    }
  };

  const setDataToUpdate = (diffObj: any) => {
    let deviceType = "desktop";
    if ("sharePosition" in diffObj) {
      setUpdatedDesktopData("deviceType", deviceType);
      setUpdatedDesktopData("sharePosition", diffObj.sharePosition);
    }
    if ("verticalOffset" in diffObj) {
      setUpdatedDesktopData("deviceType", deviceType);
      setUpdatedDesktopData("verticalOffset", diffObj.verticalOffset);
    }
    if ("verticalOffsetShare" in diffObj) {
      setUpdatedDesktopData("deviceType", deviceType);
      setUpdatedDesktopData("verticalOffsetShare", diffObj.verticalOffsetShare);
    }
    if ("horizontalOffset" in diffObj) {
      setUpdatedDesktopData("deviceType", deviceType);
      setUpdatedDesktopData("horizontalOffset", diffObj.horizontalOffset);
    }
  };

  const setUpdatedDesktopData = (field: string, value: any) => {
    setUpdatedData((mData: any) => ({ ...mData, [field]: value }));
  };

  const handleButtonPositionChange = (value: any) => {
    props.setDesktopSelector(value);
    setData({ ...data, sharePosition: value });
  };

  const handleOffsetChange = (value: string) => {
    if (!activateErrorCheckForVerticalOffsetShare) {
      setActivateErrorCheckForVerticalOffsetShare(true);
    }
    setData({ ...data, verticalOffsetShare: value });
  };

  const validateVerticalOffsetShare = () => {
    if (activateErrorCheckForVerticalOffsetShare) {
      if (data.verticalOffsetShare && Number(data.verticalOffsetShare) < 8) {
        return "Minimum offset should be 8%";
      } else if (
        data.verticalOffsetShare &&
        Number(data.verticalOffsetShare) > 100
      ) {
        return "Maximum offset should be 100%";
      } else if (!data.verticalOffsetShare) {
        return "Desktop height offset cannot be empty";
      }
    }
    return false;
  };

  const renderMarkup = () => {
    switch (props.selected) {
      case DEVICE_TYPE_MOBILE_AND_DESKTOP:
      case DEVICE_TYPE_DESKTOP_ONLY:
        return (
          <Card sectioned title="Desktop">
            <FormLayout>
              <FormLayout.Group>
                <div className="smallMarginTopSharePage">
                  <TextContainer>
                    <p>Share Button Position</p>
                  </TextContainer>
                  <Stack>
                    <RadioButton
                      label="Left"
                      checked={data.sharePosition === "left"}
                      id="d-left"
                      name="btnpositiondesktop"
                      onChange={() => handleButtonPositionChange("left")}
                    />
                    <RadioButton
                      label="Right"
                      id="d-right"
                      name="btnpositiondesktop"
                      checked={data.sharePosition === "right"}
                      onChange={() => handleButtonPositionChange("right")}
                    />
                  </Stack>
                </div>
              </FormLayout.Group>
              <TextStyle variation="subdued">
                {
                  "You can select whether to display your chat button on the right/left side of your website."
                }
                {"You can also set the position by configuring the right and left offset."}
              </TextStyle>

              <FormLayout.Group>
                <TextField
                  label="HEIGHT offset"
                  type="number"
                  suffix="%"
                  value={data.verticalOffsetShare}
                  max={100}
                  min={8}
                  onChange={(val) => handleOffsetChange(val)}
                  helpText={
                    "set the height offset of the chat button on mobile devices."
                  }
                  error={validateVerticalOffsetShare()}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card>
        );
    }
  };

  return <>{renderMarkup()}</>;
};

export default ShareDesktopConfig;
