import React, { useEffect, useState } from "react";

const WheelConfig = (props: any) => {
  let config = props.config;
  let text = config.map((e: any) => e.text);
  let colors = config.map((e: any) => e.color);
  const [list, setList] = useState({
    list: text,
    colors: colors,
    radius: 65, // PIXELS
    rotate: 0, // DEGREES
    easeOut: 0, // SECONDS
    angle: 0, // RADIANS
    top: null, // INDEX
    offset: 180, // RADIANS
    net: null, // RADIANS
    result: null, // INDEX
    spinning: false,
  });
  const renderWheel = (list: any) => {
    // determine number/size of sectors that need to created
    let numOptions = list.list.length;
    let arcSize = (2 * Math.PI) / numOptions;
    setList({ ...list, angle: arcSize });

    // get index of starting position of selector
    topPosition(numOptions, arcSize);

    // dynamically generate sectors from state list
    let angle = 0;
    for (let i = 0; i < numOptions; i++) {
      let text = list.list[i];
      let color = list.colors[i];
      renderSector(i + 1, text, angle, arcSize, color);
      angle += arcSize;
    }

    renderCircle();
    renderCenterCircle();
  };
  useEffect(() => {
    renderWheel(list);
  }, []);

  const topPosition = (num: any, angle: any) => {
    // set starting index and angle offset based on list length
    // works upto 9 options
    let topSpot = null;
    let degreesOff = null;
    if (num === 9) {
      topSpot = 7;
      degreesOff = Math.PI / 2 - angle * 2;
    } else if (num === 8) {
      topSpot = 6;
      degreesOff = 0;
    } else if (num <= 7 && num > 4) {
      topSpot = num - 1;
      degreesOff = Math.PI / 2 - angle;
    } else if (num === 4) {
      topSpot = num - 1;
      degreesOff = 0;
    } else if (num <= 3) {
      topSpot = num;
      degreesOff = Math.PI / 2;
    }
  };
  const renderCenterCircle = () => {
    var c: any = document.getElementById("wheel");
    var ctx = c.getContext("2d");
    ctx.beginPath();
    ctx.arc(150, 150, 10, 0, 2 * Math.PI);
    ctx.lineWidth = 20;
    ctx.strokeStyle = "white";
    ctx.shadowBlur = 5;
    ctx.shadowColor = "gray";
    ctx.stroke();
  };
  const renderCircle = () => {
    var c: any = document.getElementById("wheel");
    var ctx = c.getContext("2d");
    ctx.beginPath();
    ctx.arc(150, 150, list.radius * 2, 0, 2 * Math.PI);
    ctx.lineWidth = 5;
    ctx.strokeStyle = "white";
    ctx.shadowBlur = 5;
    ctx.shadowColor = "#A9A9A9";
    ctx.strokeStyle = "white";

    ctx.stroke();
  };
  const renderSector = (
    index: any,
    text: any,
    start: any,
    arc: any,
    color: any
  ) => {
    // create canvas arc for each list element
    let canvas: any = document.getElementById("wheel");
    let ctx: any = canvas.getContext("2d");
    let x: any = canvas.width / 2;
    let y: any = canvas.height / 2;
    let radius = list.radius;
    let startAngle = start;
    let endAngle = start + arc;
    let angle = index * arc;
    let baseSize = radius * 2.33;
    let textRadius = baseSize - 65;

    ctx.beginPath();
    ctx.arc(x, y, radius, startAngle, endAngle, false);
    ctx.lineWidth = radius * 2;
    ctx.strokeStyle = color;

    ctx.font = "500 14px Arial";
    ctx.fillStyle =
      parseInt(props.selectedWheelID) === 9 || index % 2 === 0
        ? "black"
        : "white";
    ctx.shadowBlur = 1;
    ctx.shadowColor =
      parseInt(props.selectedWheelID) === 9 || index % 2 === 0
        ? "black"
        : "white";
    ctx.stroke();

    ctx.save();
    ctx.translate(
      baseSize + Math.cos(angle - arc / 2.5) * textRadius,
      baseSize + Math.sin(angle - arc / 2) * textRadius
    );
    ctx.rotate(angle - arc / 1.9);
    let lengthX = ctx.measureText(text).width;
    let lengthY = -10;
    let fitwidth = 80;
    text = text.length > 8 ? text.substr(0, 7) + "..." : text;
    if (text.length > 8 && text.length <= 18) {
      lengthX = 60;
      fitwidth = 90;
      lengthY = -10;
    } else if (text.length > 18) {
      lengthX = 40;
      fitwidth = 80;
      lengthY = -20;
    }
    // if (props.config.length <= 5) {
    //   printAt(ctx, text, -lengthX, lengthY, 15, fitwidth);
    // } else {
    //   ctx.fillText(text, -ctx.measureText(text).width / 2, 0);
    // }
    ctx.fillText(text, -ctx.measureText(text).width / 2, 0);
    ctx.restore();
  };

  function printAt(
    context: any,
    text: any,
    x: any,
    y: any,
    lineHeight: any,
    fitWidth: any
  ) {
    fitWidth = fitWidth || 0;

    if (fitWidth <= 0) {
      context.fillText(text, x, y);
      return;
    }

    for (var idx = 1; idx <= text.length; idx++) {
      var str = text.substr(0, idx);
      if (context.measureText(str).width > fitWidth) {
        context.fillText(text.substr(0, idx - 1), x, y);
        printAt(
          context,
          text.substr(idx - 1),
          x,
          y + lineHeight,
          lineHeight,
          fitWidth
        );
        return;
      }
    }
    context.fillText(text, x, y);
  }

  useEffect(() => {
    let text = props.config.map((e: any) => e.text);
    let colors = props.config.map((e: any) => e.color);
    setList({ ...list, list: text, colors: colors });
    renderWheel({ ...list, list: text, colors: colors });
  }, [props.config]);

  return (
    <canvas
      id="wheel"
      width="300"
      height="300"
      style={{
        WebkitTransform: `rotate(${list.rotate}deg)`,
        WebkitTransition: `-webkit-transform ${list.easeOut}s ease-out`,
      }}
    />
  );
};
export default WheelConfig;
