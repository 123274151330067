import { Icon } from "@shopify/polaris";
import { Link } from "react-router-dom";

import React from "react";

export default function NavigationMenuItemBadge({
  to,
  selected,
  icon,
  label,
  badge,
}: any) {
  return (
    <Link to={to}>
      <div
        className="Polaris-Navigation__ItemWrapper"
        style={{ paddingTop: "0.4rem", paddingBottom: "1.6rem" }}
      >
        <span
          className={`Polaris-Navigation__Item ${
            selected ? "Polaris-Navigation__Item--selected" : ""
          } Polaris-Navigation--subNavigationActive`}
          data-polaris-unstyled="true"
        >
          <div className="Polaris-Navigation__Icon">
            <Icon source={icon} color="subdued" />
          </div>
          <span className="Polaris-Navigation__Text">{label}</span>
          {badge && (
            <div className="Polaris-Navigation__Badge">
              <span className="Polaris-Badge Polaris-Badge--statusSuccess Polaris-Badge--sizeSmall">
                <span className="Polaris-VisuallyHidden">Success</span>
                {badge}
              </span>
            </div>
          )}
        </span>
      </div>
    </Link>
  );
}
