import { useEffect } from "react";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";
import "../assets/css/invoice.scss";

export default function Invoice(props: any) {
  let issueDateConverted = parseInt(props?.walletInvoiceParams?.issueDate);
  let formatedDate = "";
  if (issueDateConverted)
    formatedDate = new Date(issueDateConverted).toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

  useEffect(() => {
    var invoiceComponent = document.getElementById("invoice-component");
    if (invoiceComponent != null) {
      toPng(invoiceComponent, { cacheBust: true })
        .then((invoiceComponentImage) => {
          const pdf = new jsPDF({
            orientation: "p",
            precision: 16,
            compress: false,
            floatPrecision: 16,
          });
          pdf.addImage(
            invoiceComponentImage,
            "PNG",
            15,
            15,
            180,
            250,
            "",
            "NONE"
          );
          pdf.save("Last_Recharge_Invoice.pdf");
          props.setShowInvoiceUI(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <div className="invoice-main-container" id="invoice-component">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="173.41"
        height="39.152"
        viewBox="0 0 173.41 39.152"
      >
        <g id="logo" transform="translate(-40.411 -59.648)">
          <g id="Group_54" data-name="Group 54">
            <path
              id="Path_74"
              d="M183.974 68.5v9.07c0 1.6.6 2.726 2.094 2.726 2.559 0 3.523-3.524 3.523-7.412V68.5h4.986l-.02 15.784h-3.39l-1.11-6.145c-.632 3.756-2.526 6.182-5.75 6.182-3.69 0-5.318-2.559-5.318-6.747V68.5Z"
              data-name="Path 74"
            />
            <path
              id="Path_75"
              d="M103.61 68.47a2 2 0 0 0 .232-.9c0-.765-.465-1.2-1.329-1.23l1.5-2.36c1.795.066 2.925 1.064 2.925 2.46 0 1.03-.565 1.828-1.928 2.625a4.537 4.537 0 0 1 2.46 4.022c0 2.759-2.626 5.152-7.312 5.152a10.392 10.392 0 0 1-3.49-.532 1.311 1.311 0 0 0-.831 1.064c0 .864.931 1.129 2.094 1.129h3.689c4.853 0 7.279 1.762 7.279 4.454 0 3.391-3.257 5.152-9.04 5.152-4.82 0-8.708-1.064-8.708-3.656 0-1.795 2.226-2.825 5.052-2.858-2.792-.4-4.221-1.23-4.221-3.092 0-1.661 1.961-2.526 3.456-2.758a4.49 4.49 0 0 1-2.559-4.055c0-3.025 2.825-5.185 7.279-5.185a10.769 10.769 0 0 1 3.452.568Zm-4.554 14.89c-2.027 0-3.257.532-3.257 1.729 0 1.262 1.595 1.927 4.254 1.927 2.726 0 4.288-.8 4.288-2.094 0-.9-.864-1.562-2.659-1.562Zm1.1-12.929a2.659 2.659 0 0 0 0 5.318 2.528 2.528 0 0 0 2.659-2.659 2.491 2.491 0 0 0-2.662-2.659Z"
              data-name="Path 75"
            />
            <path
              id="Path_76"
              d="M138.142 84.391c-2.659 0-4.088-1.729-4.919-3.69v8.808h-4.986V68.57h2.892l1.063 4.919c.632-2.46 2.028-5.252 5.817-5.252 4.188 0 6.481 3.49 6.481 8.077s-2.227 8.077-6.348 8.077Zm-1.728-12.83c-1.762 0-3.191 1.8-3.191 4.753s1.429 4.753 3.191 4.753c2.027 0 3.091-1.961 3.091-4.753s-1.064-4.753-3.091-4.753Z"
              data-name="Path 76"
            />
            <path
              id="Path_77"
              d="M114.644 68.5v9.07c0 1.6.6 2.726 2.093 2.726 2.56 0 3.524-3.524 3.524-7.412V68.5h4.985l-.019 15.784h-3.39l-1.111-6.145c-.632 3.756-2.526 6.182-5.75 6.182-3.689 0-5.318-2.559-5.318-6.747V68.5Z"
              data-name="Path 77"
            />
            <path
              id="Path_78"
              d="M152.356 84.317A8.927 8.927 0 0 1 145.108 81l2.21-2.275a6.444 6.444 0 0 0 4.876 2.7c1.3 0 1.722-.618 1.722-1.3 0-.779-.682-1.267-2.47-1.852-4.258-1.4-5.525-2.828-5.525-5.4 0-3.055 2.6-4.355 6.143-4.355a9.592 9.592 0 0 1 6.63 2.6l-1.723 2.341a7.494 7.494 0 0 0-4.745-2.048c-.975 0-1.527.39-1.527 1.138 0 1.072.91 1.3 3.152 2.177 3.088 1.2 4.843 2.308 4.843 4.843 0 2.993-2.438 4.748-6.338 4.748Z"
              data-name="Path 78"
            />
            <path
              id="Path_79"
              d="M171.345 84.317v-9.073c0-1.6-.6-2.726-2.094-2.726-2.559 0-3.523 3.523-3.523 7.412v4.387h-4.986V62.181h4.986v11.966c.631-3.59 2.625-5.651 5.284-5.651 3.69 0 5.318 2.56 5.318 6.748v9.073Z"
              data-name="Path 79"
            />
            <path
              id="Path_80"
              d="M207.473 84.391c-2.659 0-4.089-1.729-4.92-3.69v8.808h-4.985V68.57h2.892l1.063 4.919c.632-2.46 2.028-5.252 5.817-5.252 4.187 0 6.481 3.49 6.481 8.077s-2.227 8.077-6.348 8.077Zm-1.729-12.83c-1.761 0-3.191 1.8-3.191 4.753s1.43 4.753 3.191 4.753c2.028 0 3.091-1.961 3.091-4.753s-1.063-4.753-3.091-4.753Z"
              data-name="Path 80"
            />
          </g>
          <path
            id="Path_81"
            fill="#5e34f1"
            stroke="#5e34f1"
            stroke-width="1"
            d="M72.2 80.895a3.3 3.3 0 0 1-1.009 2 3.511 3.511 0 0 1-2.058 1.034 123.3 123.3 0 0 1-9.914.525 4.96 4.96 0 0 0-3.456 1.458l-4.346 4.347v-3.434a2.7 2.7 0 0 0-2.5-2.687c-.851-.061-1.709-.133-2.557-.212a3.511 3.511 0 0 1-2.058-1.034A3.285 3.285 0 0 1 43.3 80.9a97.867 97.867 0 0 1 0-15.083 3.284 3.284 0 0 1 1.006-2 3.513 3.513 0 0 1 2.058-1.035 121.8 121.8 0 0 1 22.77 0 3.513 3.513 0 0 1 2.058 1.035 3.3 3.3 0 0 1 1.009 2 98.627 98.627 0 0 1-.001 15.078Z"
            data-name="Path 81"
          />
          <path
            id="Path_82"
            fill="#ff3964"
            d="M73.25 98.8a1.823 1.823 0 0 1-1.291-.537L66.3 92.607a2.363 2.363 0 0 0-1.652-.7c-1.971-.024-2.884-.049-4.407-.15a1.3 1.3 0 0 1 .172-2.592c1.463.1 2.349.122 4.266.145a4.95 4.95 0 0 1 3.457 1.458l4.345 4.345v-3.426A2.7 2.7 0 0 1 74.98 89c.851-.062 1.711-.133 2.557-.212a3.523 3.523 0 0 0 2.063-1.035 3.294 3.294 0 0 0 1.007-2 97.867 97.867 0 0 0 0-15.083 3.652 3.652 0 0 0-2.531-2.923 1.3 1.3 0 0 1 .747-2.488 6.234 6.234 0 0 1 4.373 5.206 100.654 100.654 0 0 1 0 15.492 6.2 6.2 0 0 1-5.4 5.415h-.009c-.865.08-1.745.153-2.615.216a.1.1 0 0 0-.086.1v5.282a1.831 1.831 0 0 1-1.836 1.83Zm4.412-8.718Z"
            data-name="Path 82"
          />
          <path
            id="Path_83"
            fill="#5e34f1"
            d="M74.788 65.611a6.2 6.2 0 0 0-5.4-5.413h-.011a124.2 124.2 0 0 0-23.258 0h-.011a6.2 6.2 0 0 0-5.4 5.417 100.4 100.4 0 0 0 0 15.492 6.2 6.2 0 0 0 5.4 5.41h.011c.865.082 1.745.154 2.615.215a.1.1 0 0 1 .086.1v5.284a1.821 1.821 0 0 0 1.129 1.684 1.853 1.853 0 0 0 .7.14 1.821 1.821 0 0 0 1.289-.535l5.662-5.66a2.359 2.359 0 0 1 1.652-.7c3.337-.04 6.746-.219 10.126-.535h.011a6.2 6.2 0 0 0 5.4-5.413 100.615 100.615 0 0 0-.001-15.486ZM72.2 80.895a3.3 3.3 0 0 1-1.009 2 3.511 3.511 0 0 1-2.058 1.034 123.3 123.3 0 0 1-9.914.525 4.96 4.96 0 0 0-3.456 1.458l-4.346 4.347v-3.434a2.7 2.7 0 0 0-2.5-2.687c-.851-.061-1.709-.133-2.557-.212a3.511 3.511 0 0 1-2.058-1.034A3.285 3.285 0 0 1 43.3 80.9a97.867 97.867 0 0 1 0-15.083 3.284 3.284 0 0 1 1.006-2 3.513 3.513 0 0 1 2.058-1.035 121.8 121.8 0 0 1 22.77 0 3.513 3.513 0 0 1 2.058 1.035 3.3 3.3 0 0 1 1.009 2 98.627 98.627 0 0 1-.001 15.078Z"
            data-name="Path 83"
          />
          <path
            id="Path_86"
            fill="#fff"
            d="M53 78.642a1.138 1.138 0 0 1-.645-.2l-4.914-3.361a1.145 1.145 0 0 1 0-1.889l4.914-3.362a1.144 1.144 0 0 1 1.292 1.889l-3.533 2.417 3.533 2.417A1.145 1.145 0 0 1 53 78.642Z"
            data-name="Path 86"
          />
          <g id="Group_55" data-name="Group 55">
            <path
              id="Path_84"
              fill="#ff3964"
              d="M79.6 87.753a3.294 3.294 0 0 0 1.007-2 97.867 97.867 0 0 0 0-15.083 3.652 3.652 0 0 0-2.531-2.923 1.288 1.288 0 0 1-.631-.421 116.43 116.43 0 0 1-.022 17.45 3.9 3.9 0 0 1-1.19 2.354 4.143 4.143 0 0 1-2.426 1.22c-3.9.364-7.833.571-11.686.618a5.9 5.9 0 0 0-1.473.213c1.331.085 2.222.108 4.038.13a4.95 4.95 0 0 1 3.457 1.458l4.345 4.345v-3.427A2.7 2.7 0 0 1 74.98 89c.851-.062 1.711-.133 2.557-.212a3.523 3.523 0 0 0 2.063-1.035Z"
              data-name="Path 84"
            />
          </g>
          <g id="Group_56" data-name="Group 56">
            <path
              id="Path_85"
              fill="#fff"
              d="M62.5 78.642a1.145 1.145 0 0 1-.647-2.089l3.533-2.417-3.533-2.417a1.144 1.144 0 0 1 1.292-1.889l4.914 3.362a1.145 1.145 0 0 1 0 1.889l-4.914 3.361a1.14 1.14 0 0 1-.645.2Z"
              data-name="Path 85"
            />
          </g>
          <g id="Group_58" data-name="Group 58">
            <path
              id="Path_87"
              fill="#fff"
              d="M56.075 82a1.144 1.144 0 0 1-1.111-1.422l3.362-13.447a1.144 1.144 0 0 1 2.22.555l-3.361 13.45a1.145 1.145 0 0 1-1.11.864Z"
              data-name="Path 87"
            />
          </g>
        </g>
      </svg>
      <div className="home-address textColor">
        415 Jackson Street, Suite B, San Francisco, CA, 94111
      </div>
      <div className="home-header">
        <div className="textColor">Support : support@superlemon.xyz</div>
        <div className="home-text2">Invoice</div>
      </div>
      <div className="invoiceLine"></div>
      <div className="customer-container">
        <div className="customer-details">
          <div>
            Customer ID : {props?.walletInvoiceParams?.customerId || ""}
          </div>
          <div>
            Bill To Address : {props?.walletInvoiceParams?.address1 || ""}{" "}
            {props?.walletInvoiceParams?.address2 || ""}
          </div>
          <div>ATTN : Arti</div>
          <div className="other-details">
            <div>{props?.walletInvoiceParams?.address1 || ""}</div>
            <div>{props?.walletInvoiceParams?.address2 || ""}</div>
            <div>{props?.walletInvoiceParams?.city || ""}</div>
            <div>{props?.walletInvoiceParams?.zipcode || ""}</div>
            <div>{props?.walletInvoiceParams?.state}</div>
            <div>{props?.walletInvoiceParams?.country}</div>
            <div>{props?.walletInvoiceParams?.phone}</div>
            <div>Email : {props?.walletInvoiceParams?.email}</div>
            <div>VAT No. : 134</div>
          </div>
        </div>
        <div className="invoice-details">
          <div className="font2rem">Invoice Summary</div>
          <div className="invoice-details-tophalf">
            <div className="invoice-details-row">
              <div>Invoice Number :</div>
              <div className="fontWeight400 textColor">
                {props?.walletInvoiceParams?.invoiceId || ""}
              </div>
            </div>
            <div className="invoice-details-row">
              <div>Date of Issue :</div>
              <div className="fontWeight400 textColor">{formatedDate}</div>
            </div>
          </div>
          <div className="invoiceLineSmall"></div>
          <div className="invoice-details-row amt-font">
            <div>Total Amount </div>
            <div>{props?.walletInvoiceParams?.amount}</div>
          </div>
        </div>
      </div>
      <div className="greetings textColor">
        Greetings from Gupshup Inc. We are writing to provide you with an
        electronic invoice for your use of Gupshup messaging services.
      </div>
      <div className="invoice-table">
        <table id="customers">
          <tr>
            <th>Description</th>
            <th>Amount</th>
          </tr>
          <tr>
            <td>SuperLemon Wallet Credits</td>
            <td>{props?.walletInvoiceParams?.amount}</td>
          </tr>
          <tr>
            <td>VAT*</td>
            <td></td>
          </tr>
          <tfoot>
            <tr className="amt-font">
              <td className="right-text">Total:</td>
              <td>{props?.walletInvoiceParams?.amount}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="marB10">* This is not a VAT Invoice</div>
      <div className="account-details">
        <div className="font2rem">Electronic funds transfer details:</div>
        <br />
        <div className="account-details-row">
          <div>Account Name:</div>
          <div className="fontWeight400">Gupshup Inc</div>
        </div>
        <div className="account-details-row">
          <div>Bank:</div>
          <div className="fontWeight400">COMERICA BANK</div>
        </div>
        <div className="account-details-row">
          <div>Address:</div>
          <div className="fontWeight400">
            226, Airport Parkway, <br /> San Jose, CA 95110-4348
          </div>
        </div>
        <div className="account-details-row">
          <div>Account No:</div>
          <div className="fontWeight400">1892348610</div>
        </div>
        <div className="account-details-row">
          <div>ABA Routing:</div>
          <div className="fontWeight400">12117352286</div>
        </div>
        <div className="account-details-row">
          <div>Swift Code:</div>
          <div className="fontWeight400">MNBDUd58</div>
        </div>
        <div className="account-details-row">
          <div>Currency:</div>
          <div className="fontWeight400">USD</div>
        </div>
      </div>
    </div>
  );
}
