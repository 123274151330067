// @ts-nocheck
import { CancelSmallMinor } from "@shopify/polaris-icons";
import "../chat.scss";
import { useUsersContext } from "../../../../context/usersContext";
import { saveChatRoomInfo } from "../../../../redux/actions/chats";
import AvatarInitial from "../../../../common/AvatarInitials";
import { isSafari } from "../../../../utils/helper";
import { CHAT_USERPROFILE } from "../../../../constants/constants";
import OrderComponent from "./OrderComponent/OrderComponent";
import TagsComponent from "./TagsComponent";
import { Icon } from "@shopify/polaris";

const UserProfile = (props: any) => {
  const {
    currentUser,
    setCurrentUser,
    agentPhone,
    getChatCount,
    showToast,
    mobileView,
    mobileType,
  } = useUsersContext();

  const closeChat = async () => {
    if (currentUser && currentUser?.customerPhone) {
      const data = {
        agentPhone: agentPhone,
        customerPhone: currentUser.customerPhone,
        tags: currentUser.tags.length ? currentUser.tags.join(",") : "",
        ticketStatus: CHAT_USERPROFILE.CLOSED,
        notes: currentUser.notes,
        chatRead: currentUser?.chatRead,
      };
      if (currentUser.ticketStatus !== CHAT_USERPROFILE.CLOSED) {
        const res = await saveChatRoomInfo(data);
        showToast(CHAT_USERPROFILE.CHAT_CLOSED);
        getChatCount();
        setCurrentUser({
          ...currentUser,
          customerPhone: "",
        });
      } else {
        showToast(CHAT_USERPROFILE.CHAT_ALREADY_CLOSED);
      }
    }
  };

  return (
    <>
      {props.showUserInfoPanel && (
        <div
          className={`storeUserDetailsContainer ${
            mobileView.status ? " chat__userProfile_mobile" : ""
          } ${
            mobileType
              ? ` storeUserDetailsContainer--${mobileType}${
                  isSafari() ? "--safari" : ""
                }`
              : ""
          }`}
        >
          <div className="userDetailsInfoContainer">
            <div className="userDetailsInfo">
              <div
                className="closeIcon"
                onClick={props.handleCloseUserInfoDetails}
              >
                <Icon source={CancelSmallMinor} color="base" />
              </div>
              <div className="chat__userProfile_header">
                <AvatarInitial
                  name={currentUser?.customerName}
                  avatarSize={"xlarge"}
                />
                <span className="chat__userProfile_userName">
                  {currentUser?.customerName ? currentUser?.customerName : ""}
                </span>
                <label className="chat__userProfile_userPhone">
                  {currentUser?.customerPhone}
                </label>
              </div>

              <div className="chat__userProfile_content">
                <TagsComponent />
                <OrderComponent phone={currentUser?.customerPhone} />
                <div
                  className="chat__sendTemplateButton chat__userProfileCloseButton"
                  onClick={() => {
                    closeChat();
                  }}
                >
                  <button>
                    <strong className="buttonColor--primary">Close Chat</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
