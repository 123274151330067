import { useEffect, useState } from "react";
import WheelConfig from "./WheelConfig";

const WidgetPreview = (props: any) => {
  const templates = props.wheelTemplates;
  let config = props.wheelConfig;
  let [wheelConfig, setWheelConfig] = useState(props.wheelConfig);
  const custom = props.custom;
  const id = props.id;
  var URL =
    templates && templates[id]
      ? templates[id]["previewUrl"] || templates[id]["preview_url"]
      : null;

  useEffect(() => {
    setWheelConfig(props.wheelConfig);
  }, [props.wheelConfig]);
  return (
    <div>
      {!custom && templates && config ? (
        <WheelConfig
          onChanges={props.onChanges}
          colors={templates[id]}
          config={wheelConfig}
          selectedWheelID={props.selectedWheelID}
        />
      ) : (
        <img src={URL} alt="wheelImage" />
      )}
    </div>
  );
};

export default WidgetPreview;
