import {
  OLD_PLANS_RELEASE_NOTES,
  PLAN_ENTERPRISE,
  PLAN_OLD_PRO,
  PLAN_SMALL_BUSINESS,
  REACT_TOUR_STEPS,
  SHOP_FEATURES,
} from '../constants/constants';

const HSVtoRGB = function (h: any, s: any, v: any) {
  var r, g, b, i, f, p, q, t;
  if (arguments.length === 1) {
    s = h.s;
    v = h.v;
    h = h.h;
  }
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
  }
  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
};

const RGBtoHSV = function (r: any, g: any, b: any) {
  if (arguments.length === 1) {
    g = r.g;
    b = r.b;
    r = r.r;
  }
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b),
    d = max - min,
    h,
    s = max === 0 ? 0 : d / max,
    v = max / 255;

  switch (max) {
    case min:
      h = 0;
      break;
    case r:
      h = g - b + d * (g < b ? 6 : 0);
      h /= 6 * d;
      break;
    case g:
      h = b - r + d * 2;
      h /= 6 * d;
      break;
    case b:
      h = r - g + d * 4;
      h /= 6 * d;
      break;
  }

  return {
    h: h,
    s: s,
    v: v,
  };
};

const rgbToHex = (r: any, g: any, b: any) => {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

const hexToRgb = (hex: string) => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  if (hex) {
    hex = hex.replace(
      shorthandRegex,
      function (m: any, r: any, g: any, b: any) {
        return r + r + g + g + b + b;
      }
    );
  }

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};
export const hsvToHex = (h: any, s: any, v: any) => {
  const rgbVal = HSVtoRGB(h / 360, s, v);

  return rgbToHex(rgbVal.r, rgbVal.g, rgbVal.b);
};

export const hexToHsv = (hex: any) => {
  var rgbVal: any = hexToRgb(hex);

  if (rgbVal) {
    var hsvVal: any = RGBtoHSV(rgbVal.r, rgbVal.g, rgbVal.b);
    return {
      hue: hsvVal.h * 360,
      saturation: hsvVal.s,
      brightness: hsvVal.v,
    };
  }

  return {
    hue: 0,
    saturation: 0,
    brightness: 0,
  };
};

export const isValidHex = (hex: any) => {
  var RegExp = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i;
  return RegExp.test(hex);
};

export const getUserId = (shopDetailsData: any) => {
  let shop = shopDetailsData;
  if (shop && shop.userId) {
    return shop.userId;
  }

  return '';
};

export const getplanPaymentInfo = (planPaymentInfo: any) => {
  let paymentInfo = planPaymentInfo;
  if (paymentInfo && paymentInfo.downgraded) {
    return paymentInfo;
  }

  return {
    downgraded: false,
    downgradedOn: '',
  };
};

///difference obj
// obj1 reference
//obj2  new obj
export const objectDifference = (obj1: any, obj2: any) => {
  if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
    return obj1;
  }
  var diffs: any = {};
  var key;
  var arraysMatch = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) return false;
    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };
  var compare = (item1: any, item2: any, key: any) => {
    var type1 = Object.prototype.toString.call(item1);
    var type2 = Object.prototype.toString.call(item2);

    if (type2 === '[object Undefined]') {
      diffs[key] = null;
      return;
    }

    if (type1 !== type2) {
      diffs[key] = item2;
      return;
    }

    if (type1 === '[object Object]') {
      var objDiff = objectDifference(item1, item2);
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff;
      }
      return;
    }

    if (type1 === '[object Array]') {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item2;
      }
      return;
    }
    if (type1 === '[object Function]') {
      if (item1.toString() !== item2.toString()) {
        diffs[key] = item2;
      }
    } else {
      if (item1 !== item2) {
        diffs[key] = item2;
      }
    }
  };
  for (key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      compare(obj1[key], obj2[key], key);
    }
  }
  for (key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (!obj1[key] && obj1[key] !== obj2[key]) {
        diffs[key] = obj2[key];
      }
    }
  }
  return diffs;
};

export const isNullOrEmpty = (obj: string) => {
  if (obj === null) {
    return true;
  } else if (obj === '') {
    return true;
  } else if (obj === undefined) {
    return true;
  }

  return false;
};

export const handleNewLineCharacters = (value: string) => {
  return value.replace(/(\\n)+/g, '\\n');
};

export const mobilecheck = () => {
  var check = false;
  //eslint-disable-next-line
  (function (a: any) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const getMobileType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    )
  ) {
    return 'tablet';
  } else if (/iphone|ipod/.test(userAgent)) {
    return 'ios';
  } else {
    return 'android';
  }
};

export const isSafari = () => {
  var ua = window.navigator.userAgent;
  var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/iPod/i);
  var webkit = !!ua.match(/WebKit/i);
  var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  return iOSSafari;
};

export const getOrderURL = (shop:any, orderType: any, orderId: any) => {
  let shopDetails = shop;
  let platform = shopDetails.platform;
  if (platform === 'SHOPIFY') {
    let shopUrl = shopDetails.url;
    if (orderType === 'AB_CART') {
      return `https://${shopUrl}/admin/checkouts/${orderId}`;
    } else {
      return `https://${shopUrl}/admin/orders/${orderId}`;
    }
  } else if (platform === 'WIX') {
    let siteId = shopDetails.wixSiteId;
    if (orderType === 'AB_CART') {
      return `https://manage.wix.com/dashboard/${siteId}/store/abandoned-carts/`;
    } else {
      return `https://manage.wix.com/dashboard/${siteId}/store/orders/order/${orderId}`;
    }
  }
  return '';
};

export const shopFeatures = () => {
  let features = localStorage.getItem('features')?.split(',') || [];
  let shop = JSON.parse(localStorage.getItem('shop') || '[]');
  // For pro plan we enable features , but users have to add WABA and give approval for usage charges before using them
  if (Number(shop.planId) === PLAN_OLD_PRO && !shop.oldProApprovedUser) {
    features.push(SHOP_FEATURES.WIDGETS_RESTRICTED);
    features.push(
      SHOP_FEATURES.AGENT_ASSIST,
      SHOP_FEATURES.CAMPAIGNS,
      SHOP_FEATURES.TEMPLATE,
      SHOP_FEATURES.PRIVATE
    );
  }
  return features;
};

export const shopFeaturesFromStoreData = (shopDetailsData: any) => {
  let features = shopDetailsData?.featuresList;
  let shop = shopDetailsData;
  // For pro plan we enable features , but users have to add WABA and give approval for usage charges before using them
  if(Number(shop?.planId) === PLAN_OLD_PRO && !features.includes(SHOP_FEATURES.PRIVATE)){
    features.push(SHOP_FEATURES.WIDGETS_RESTRICTED);
    features.push(SHOP_FEATURES.AGENT_ASSIST, SHOP_FEATURES.CAMPAIGNS, SHOP_FEATURES.TEMPLATE, SHOP_FEATURES.PRIVATE);
  }
  return features ;
};


export const planDiffInfo = (shopDetailsData: any) => {
  let shop = shopDetailsData;
  if(Number(shop.planId) === PLAN_SMALL_BUSINESS){
    if(shop.newUser === false && shop.oldUserMigrateToEngage === false){
      return {
        releaseNotes: OLD_PLANS_RELEASE_NOTES.GROWTH,
        productTour: false,
        plan: 'Not Subscribed',
        tile: PLAN_SMALL_BUSINESS,
      };
    } else if (shop.newUser === false && shop.oldUserMigrateToEngage === true) {
      return {
        releaseNotes: OLD_PLANS_RELEASE_NOTES.ENGAGE,
        productTour: false,
        plan: 'Subscribed',
        tile: PLAN_ENTERPRISE,
      };
    } else if (shop.newUser === true && shop.oldUserMigrateToEngage === false) {
      return {
        releaseNotes: false,
        productTour: REACT_TOUR_STEPS.PLAN_ENGAGE,
        plan: 'Subscribed',
        tile: PLAN_ENTERPRISE,
      };
    }
  }
  // Dead case added for typescript
  return {
    releaseNotes: false,
    productTour: false,
    plan: '',
    tile: Number(shop.planId),
  };
};
