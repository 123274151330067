export const SERVER_ADDR = process.env.REACT_APP_BASE_URL;
export const COD_SERVER_ADDR = process.env.REACT_APP_COD_URL;

export const MESSAGING = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  api: {
    // Abandoned cart
    ABANDONED_CART_SETTINGS: 'account/user/$userId/automated/abcart/settings',
    AUTOMATED_PHONE_SETTINGS: 'account/user/$userId/automated/phone/settings',
    MESSAGE_LOGS: 'event/user/$userId/message/logs',
    // Orders CRM
    CRM_ORDERS_SETTIGS: 'account/user/$userId/automated/order/settings',
    // COD
    COD_SETTINGS: 'account/user/$userId/automated/cod/settings',
    // OptIn Numbers
    EXPORT_NUMBERS: 'account/user/$userId/optin/export',
    GET_NUMBERS:
      'account/user/$userId/optin/numbers?pageSize=$pageSize&pageIndex=$pageNumber',
    GET_TOTAL_NUMBERS: 'account/user/$userId/optin/numbers/count',
    OPTIN_WIDGET_SETTINGS: 'account/user/optin/$userId/widget/settings',
    // Supported Integrations
    INTEGRATION: 'messaging/integration',
    SHOP_INTEGRATION: 'messaging/integration/user/$userId',
    //CRM
    CRM_TOOL_SETTINGS: '/account/user/$userId/crm/settings',
  },
};

export const MESSAGING_LOGS_V2 = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  api: {
    // Search by Name
    MESSAGE_LOGS: 'event/user/$userId/message/logs?',
    //Message Logs
    // MESSAGE_LOGS: "messaging/v1/automated/message/$userId/logs",
  },
};
export const EMAIL = {
  baseUrl: process.env.REACT_APP_MESSAGING_BASE_URL,
  api: {
    LOGS: 'messaging/v1/automated/mail/$userId/logs?',
    CONFIG_SETTING: 'messaging/v1/automated/mail/$userId/config',
    UPDATE_CONFIG_SETTINGS: 'messaging/v1/automated/mail/$userId/config',
    AB_CART_SETTINGS: 'messaging/v1/automated/mail/abcart/$userId/settings',
    COD_SETTINGS: 'messaging/v1/automated/mail/cod/$userId/settings',
    ORDER_SHIPMENT_SETTINGS:
      'messaging/v1/automated/mail/orderTracking/$userId/settings',
    ORDER_CONFIRMATION_SETTINGS:
      'messaging/v1/automated/mail/orderCrm/$userId/settings',
  },
};
export const MANUAL_MESSAGING = {
  baseUrl: process.env.REACT_APP_MESSAGING_BASE_URL,
  accountUrl: process.env.REACT_APP_BASE_URL,
  api: {
    OPEN_ORDERS_COUNT: '/event/user/$userId/order/count',
    ABANDONED_CART_COUNT:
      '/event/user/$userId/checkouts/count/v2/?status=ABANDONED',
    ABANDONED_CART: 'event/user/$userId/checkouts/v4?',
    ORDERS_CRM: 'event/user/$userId/orders/v4?',
    GET_ALL_TAGS: 'account/user/$userId/tags',
    GET_ALL_TEMPLATES: 'account/user/$userId/manual/template',
    CREATE_TEMPLATE: 'account/user/$userId/manual/template',
    ADD_TAG_TO_AB_CART_ORDER:
      'account/user/$userId/manual/checkout/$checkoutId',
    ADD_TAG_TO_ORDER: 'account/user/$userId/manual/order/$checkoutId',
    SETTINGS: 'account/user/$userId/manual/settings',
    UPDATE_DELETE_TEMPLATE: 'account/user/$userId/manual/template/$templateId',
    CREATE_TAG: 'account/user/$userId/tags',
    UPDATE_DELETE_TAG: 'account/user/$userId/tags/$tagId',
    GET_DISCOUNT_CODE: 'account/user/$userId/manual/msg/$templateId/discount',
  },
};

//Analytics
export const ANALYTICS = {
  baseUrl: process.env.REACT_APP_ANALYTICS_URL,
  accountBaseUrl: process.env.REACT_APP_BASE_URL,
  api: {
    SHARE_CHAT_CLICK_COUNT: 'api/v1/account/user/$userId/analytics/click?',
    OPTIN: 'api/v1/account/user/$userId/analytics/optin?',
    MESSAGES: 'api/v1/account/user/$userId/analytics/message?',
    SPIN_WHEE_ANALYTICS: 'api/v1/account/user/$userId/analytics/spinwheel?',
    ABANDONED_CART_ANALYTICS: 'account/user/$userId/analytics/abandonedCart?',
    MESSAGE_ANALYTICS: 'account/user/$userId/analytics/messageAnalytics?',
    MESSAGE_SETTING: '/account/user/$userId/automated/settings',
  },
};

export const ANALYTICS_NEW = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  api: {
    MESSAGE_SETTING: '/account/user/$userId/automated/settings',
  },
};

export const CAMPAIGNS = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  api: {
    // Abandoned cart
    GET_CAMPAIGNS: 'account/user/$userId/campaign',
    GET_CAMPAIGN_REPORT: 'account/user/$userId/campaign/$campaignId/reports',
    CAMPAIGNS_THRESHOLD: 'account/user/$userId/campaign/settings',
    UPLOAD_CAMPAIGN_MEDIA: 'account/user/$userId/campaign/upload',
    CREATE_CAMPAIGN: 'account/user/$userId/campaign/create',
    CREATE_CAMPAIGN_SEGMENT: 'account/user/$userId/campaign/create/segment',
    SCHEDULE_CAMPAIGN_FILE: 'account/user/$userId/campaign/schedule/file',
    SCHEDULE_CAMPAIGN_SEGMENT: 'account/user/$userId/campaign/schedule/segment',
    GET_CAMPAIGN_TEMPLATES: 'account/user/$userId/custom-templates',
    AUTOMATED_PHONE_SETTINGS: 'account/user/$userId/automated/phone/settings',
    MESSAGE_LOGS: 'event/user/$userId/message/logs',
    // Orders CRM
    CRM_ORDERS_SETTIGS: 'account/user/$userId/automated/order/settings',
    // COD
    COD_SETTINGS: 'account/user/$userId/automated/cod/settings',
    // OptIn Numbers
    EXPORT_NUMBERS: 'messaging/v1/optin/$userId/export',
    GET_NUMBERS:
      'messaging/v1/optin/$userId/numbers?pageSize=$pageSize&pageIndex=$pageNumber',
    GET_TOTAL_NUMBERS: 'messaging/v1/optin/$userId/numbers/count',
    OPTIN_WIDGET_SETTINGS: 'account/user/$userId/optin/widget/settings',
    // Supported Integrations
    INTEGRATION: 'messaging/integration',
    SHOP_INTEGRATION: 'messaging/integration/user/$userId',
    // Download file for segment
    DOWNLOAD_FILE_FOR_SEGMENT: 'account/user/$userId/campaign/segment/file',
    CAMPAIGNS_DELETE_SCHEDULED_CAMPAIGN:
      'account/user/$userId/campaign/$campaignId/schedule',
  },
};

export const CUSTOMER_SEGMENTATION = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  api: {
    // User group
    GET_CUSTOMERS_BY_PAGE:
      'account/user/$userId/customers?pageSize=$pageSize&pageIndex=$pageNumber',
    GET_USER_GROUPS:
      'account/user/$userId/segment?pageSize=$pageSize&pageIndex=$pageNumber',
    GET_ALL_SEGMENT: 'account/user/$userId/segment/all',
    CREATE_USER_GROUP: 'account/user/$userId/segment/create',
    EDIT_USER_GROUP: 'account/user/$userId/segment/$groupId',
    DELETE_USER_GROUP: 'account/user/$userId/segment/$groupId',
    DOWNLOAD_CUSTOMER_GROUP: 'account/user/$userId/segment/$groupId/customers',
    SEND_USER_LIST: 'account/user/$userId/segment/send/$groupId',
    GET_CUSTOMER_OPTINS: 'account/user/$userId/customers/download',
  },
};

export const ACCOUNT_OAUTH = 'account/oauth/shopify';
export const ACCOUNT_OAUTH_WIX = 'account/oauth/wix';
export const OWNER_DETAILS = 'account/user/$userId/owner';
export const SHOP_DETAILS = 'account/user';

//Username_password login
export const ACCOUNT_OAUTH_USER_SIGNUP = 'account/user/signup';
export const ACCOUNT_OAUTH_USER_LOGIN = 'account/user/login';
export const ACCOUNT_OAUTH_USER_FORGOT_PASSWORD =
  'account/user/forgot-password';
export const ACCOUNT_OAUTH_USER_RESET_PASSWORD = 'account/user/reset-password';
export const OWNER_PROFILE = 'account/user/$userId/complete-profile';
export const VERIFY_OTP = 'account/user/$userId/verify-otp';
export const LOGOUT = 'account/user/logout';

//Whatsapp login
export const LOGIN_WHATSAPP = {
  api: {
    GET_REDIRECTIONAL_URL: 'account/oauth/whatsapp',
    IS_AUTHENTICATED: 'account/oauth/whatsapp/callback',
    COOKIE_SET: 'account/oauth/whatsapp/cookie', //token in cookie
    COOKIE_SET2: 'account/oauth/whatsapp/cookie2', //token in body
  },
};

//
export const LOGIN_GOOGLE = {
  api: {
    GET_GOOGLE_REDIRECTION_URL: 'account/oauth/gauth',
  },
};

// Plan
export const PLANS = 'account/plan';
export const PLANS_FOR_SHOP = 'account/user/$userId/plan-updated/plan';
export const UPDATE_PLAN = 'account/user/$userId/plan-updated/$planId';
export const UPDATE_ADDON_PLAN =
  'account/user/$userId/plan-updated/subscribe/$feature/$planId';
export const PLAN_DETAILS = 'account/plan/$planId';
export const PLAN_CHECK = 'account/user/$userId/plan-updated/check';
export const UPDATE_PRO_PLAN = 'account/user/$userId/plan-updated/upgrade/2';

// Shop
export const SHOP_SETTINGS = 'account/user/$userId/settings';
export const SHOP_TIMINGS = 'account/user/$userId/store/timings';

// Agents
export const STORE_TIMINGS = 'account/user/$userId/store/timings';
export const AGENT_TIMINGS = 'account/chat/$userId/agent/timings';
export const AGENT_SETTINGS = 'account/chat/$userId/agent/settings';
export const UPDATE_AGENT = 'account/chat/$userId/agent';
export const UPDATE_AGENT_DETAILS =
  'account/chat/$userId/agent/$agentId/settings';
export const UPDATE_AGENT_TIMINGS =
  'account/chat/$userId/agent/$agentId/timings';
export const CREATE_AGENT = 'account/chat/$userId/agent';

// Chat Settings
export const CHAT = 'account/chat/$userId/button';
export const CHAT_BUTTON_TEMPLATES =
  'account/user/$userId/chat/button/templates';
export const CALL_OUT = 'account/chat/$userId/callout';
export const GREETING_WIDGET = 'account/chat/$userId/greeting';
//export const OPTIN_WIDGET = 'account/chat/$userId/optin';
export const OPTIN_WIDGET = 'account/chat/$userId/widget';
export const CHAT_DEVICES = 'account/user/$userId/chat/device';
export const CHAT_DEVICES_DELETE = 'account/user/$userId/chat/device/delete';
export const SHARE_DEVICES = 'account/user/$userId/share/device';
export const SHARE_DEVICES_DELETE = 'account/user/$userId/share/device/delete';
export const PAGES_TO_DISPLAY = 'account/chat/$userId/buttonpages';
export const CUSTOM_THEMES = 'account/user/$userId/chat/theme';

// Share Settings
export const SHARE = 'account/share/$userId/button';
export const SHARE_THEME = 'account/user/$userId/share/theme';
export const GET_SHARE_THEMES = 'account/user/$userId/share/themes';

// Spin The Wheel
export const WHEEL = 'account/wheel/$userId/button';
export const WHEEL_DEVICES = 'account/user/$userId/wheel/device';
export const WHEEL_DEVICES_DELETE = 'account/user/$userId/wheel/device/delete';
export const WHEEL_TEMPLATES = 'account/user/$userId/wheel/templates';
export const WHEEL_DATA = 'account/user/$userId/wheel/config';

// Private WABA
export const WABA = 'account/user/$userId/waba';
export const SEND_OTP = 'account/user/$userId/waba/opt/request';
export const SUBMIT_OTP = 'account/user/$userId/waba/otp/verify';
export const PROFILE_IMAGE = 'account/user/$userId/waba/profile/image';
export const BALANCE = 'account/user/$userId/balance';
export const UPGRADE =
  'account/user/$userId/upgrade-capped-amount?amount=$amount';
export const PRIVATE_WABA = 'account/user/$userId/embedded/check';
export const GET_PRIVATE_WABA = 'account/user/$userId/embedded/check';
export const GET_PRIVATE_WABA_STATUS = 'account/user/$userId/embedded/waba';
export const RETRY_PRIVATE_WABA_STATUS =
  'account/user/$userId/embedded/waba/$userId/retry';
export const WABA_STATUS = 'account/user/$userId/embedded/waba/$wabaId';
export const PRIVATE_WABA_BUSINESS_DETAILS = 'account/user/$userId/waba/info';
export const PRIVATE_WABA_FACEBOOK_DETAILS =
  'account/user/$userId/waba/business';
export const PRIVATE_WABA_PROFILE_DETAILS = 'account/user/$userId/waba/phone';
export const PRIVATE_WABA_CONTACT_DETAILS = 'account/user/$userId/waba/contact';
export const PRIVATE_WABA_PROFILE_ABOUT_DETAILS =
  'account/user/$userId/waba/profile/about';
export const WABA_TEMPLATES = 'account/user/$userId/waba/template/status';
export const WABA_PHOTO = 'account/user/$userId/embedded/waba/$wabaId/photo';
export const WABA_LANGUAGE =
  'account/user/$userId/embedded/waba/$wabaId/language';
export const WABA_BUSINESS_DETAILS =
  'account/user/$userId/embedded/waba/$wabaId/businessDetails';
export const WABA_BUSINESS_VERIFICATION_DETAILS =
  'account/user/$userId/embedded/waba/$wabaId/verificationDetails';
export const WABA_ABOUT = 'account/user/$userId/embedded/waba/$wabaId/about';
export const WABA_RETRY = 'account/user/$userId/embedded/waba/$wabaId/retry';
export const LOCALES_DETAILS = 'account/util/locales';
export const COUNTRY_CODES = 'account/util/countries';

export const OPTIN_ADMIN = 'account/user/$userId/admin/settings/optin';

// Templates
export const TEMPLATES = 'account/user/$userId/template';

//Custom Templates
export const CUSTOM_TEMPLATES = 'account/user/$userId/custom-templates';

// COD Order
export const COD_ORDER = 'account/cod/order/$token';
export const COD_ORDER_V2 = 'account/cod/order/v2/$token';

//Promotional Automated Templates - Whatsapp
export const AUTOMATED_PROMOTIONAL_TEMPLATES_WHATSAPP = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  api: {
    CROSS_SELL: 'account/user/$userId/automated/crossSell/settings',
    FEEDBACK: 'account/user/$userId/automated/feedback/settings',
    WIN_BACK: 'account/user/$userId/automated/winback/settings',
  },
};
export const NEW_TRANSACTION_TEMPLATES_WHATSAPP = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  api: {
    PAYMENT_SUCCESSFUL: 'account/user/$userId/automated/payment/settings',
    ORDER_CANCELLATION:
      'account/user/$userId/automated/order/cancelled/settings',
  },
};
// COD Order Email Settings
export const COD_ORDER_EMAIL = 'account/cod/order/$token?type=EMAIL';

//FAQ ContactUs Sheet
export const FAQ_CONTACT_US = 'account/user/$userId/sheet/contactus';
export const CHATBOT = 'account/user/$userId/sheet/chatbot-requirements';
export const CHATBOT_STATUS = 'account/user/$userId/sheet/chatbot';

//Team Inbox
export const TEAM_INBOX_STATUS = '/account/user/$userId/shared-inbox';
export const TEAM_INBOX_SEND_OTP =
  '/account/user/$userId/shared-inbox/send-otp';
export const TEAM_INBOX_VERIFY_OTP =
  '/account/user/$userId/shared-inbox/verify-otp';

//Wallet
export const WALLET_RECHARGE_LINK = 'account/user/$userId/recharge';
export const WALLET_BALANCE = '/account/user/$userId/wallet/balance';
export const WALLET_RENEWAL = '/account/user/$userId/wallet/renewal';
export const WALLET_ADDRESS = '/account/user/$userId/wallet/address';
export const WALLET_STATEMENT = '/account/user/$userId/wallet/statement';
export const WALLET_INVOICE = '/account/user/$userId/wallet/invoice';

//Agent Assist
export const GET_CHATS = `/agentassist/chatroom/search`;
export const CHATROOM_INFO = `/agentassist/chatroom/status`;
export const GET_COUNT = `/agentassist/chatroom/count/$agentPhone`;
export const AGENT_INFO = `/agentassist/user/$userId`;
export const SEND_MESSAGE = `/agentassist/messages/send`;
export const GET_MESSAGES =
  '/agentassist/messages/$customerPhone/$agentPhone/$pageNo/$pageSize';
export const AGENT_ASSIST_TAGS = '/agentassist/tags/$agentPhone';
export const AGENT_ASSIST_QUICK_RESPONSE =
  '/agentassist/quick-response/$agentPhone';
export const AGENT_ASSIST_QUICK_RESPONSE_UPDATE_DELETE =
  '/agentassist/quick-response/$agentPhone/$quickResponseId';

export const AGENT_ASSIST_WORKING_HOURS =
  '/agentassist/working-hours/$agentPhone';

export const AGENT_ASSIST_AWAY_MESSAGE =
  '/agentassist/working-hours/away-message/$agentPhone';

export const AGENT_ASSIST_SEND_TEMPLATE =
  '/agentassist/messages/template/$userId/$agentPhone';
export const CHAT_MEDIA = 'account/user/$userId/campaign/media';
export const AGENT_ASSIST_IMPORT_CHATS = '/agentassist/chatroom/import/$userId';
