import {
  PLAN_ENGAGE_WIX,
  PLAN_ENTERPRISE,
  PLAN_EXTENSION_ADDON,
  PLAN_NEW_FREE,
  PLAN_SMALL_BUSINESS,
} from "../constants/constants";
import { clevertapEventPush, CT_EVENT_IDS } from "../utils/clevertapUtils";

export const sendCleverTapEvent_planUpdate = (planId: any, shop: any) => {
  if (planId === PLAN_EXTENSION_ADDON) return;
  let whatsappVerificationStatus =
    localStorage.getItem("whatsappVerificationStatus") || "";
  let eventData = {
    "Upgraded to": planId,
    Status: planId != shop.planId ? "Migrated" : shop.active ? "Active" : "NA",
    "Opt-in from checkout": "NA",
    "WABA status": "NA",
  };

  if (
    [
      PLAN_NEW_FREE,
      PLAN_SMALL_BUSINESS,
      PLAN_ENTERPRISE,
      PLAN_ENGAGE_WIX,
    ].includes(Number(planId))
  ) {
    eventData["Opt-in from checkout"] = shop?.optinFromCheckout
      ? "Enabled"
      : "Disabled";
  }

  if (
    [PLAN_SMALL_BUSINESS, PLAN_ENTERPRISE, PLAN_ENGAGE_WIX].includes(
      Number(planId)
    )
  ) {
    eventData["WABA status"] =
      whatsappVerificationStatus === "WHATSAPP_VERIFICATION_DONE"
        ? "Procured"
        : "Not Procured";
  }

  clevertapEventPush({
    eventId: CT_EVENT_IDS.SUBSCRIBED_TO_PLAN,
    eventData: eventData,
  });
};
