// @ts-nocheck
import React from 'react';
import { Route } from 'react-router-dom';
import Login from '../components/Login/Login';
import CODPage from '../components/CODPage/index';
import Signup from '../components/Signup/Signup';
import Profile from '../components/Profile/Profile';
import OnBoardingFlow from '../components/OnBoardingFlow';
import UnsubscribeEmail from '../components/UnsubscribeEmail';

const PublicRoutes = (props: any) => {
  return (
    <>
      <Route path='/cod-order'>
        <CODPage hideFrame={props.hideFrame}></CODPage>
      </Route>
      <Route path='/unsubscribe'>
        <UnsubscribeEmail />
      </Route>
      <Route path='/signup'>
        <Signup
          setToken={props.setToken}
          setOwner={() => props.setOwnerDetails()}
        />
      </Route>
      {/*<Route path="/profile">
        <Profile></Profile>
       </Route>
      */}
      <Route path='/login'>
        <Login
          setToken={props.setToken}
          setOwner={() => props.setOwnerDetails()}
          hideFrame={props.hideFrame}
        />
      </Route>
      <Route path='/onboard'>
        <OnBoardingFlow />
      </Route>
    </>
  );
};

export default PublicRoutes;
