import { useEffect, useState } from "react";
import { Stack, TextContainer, TextStyle, Modal } from "@shopify/polaris";
import OptinTemplatePreview from "./OptinTemplatePreview";
import "./Optins.scss";

const ChangeOptinModal = (props: any) => {
  const [open, setOpen] = useState(props.open);
  const [selectedTemplateId, setSelectedTemplateId] = useState(
    props.selected_template_id
  );
  useEffect(() => {
    setOpen(props.open);
    setSelectedTemplateId(props.selected_template_id);
  }, [props.open]);

  const handleTemplateChange = (changeEvent: any) => {
    setSelectedTemplateId(parseInt(changeEvent.target.value));
  };

  const getCurrentSelectedOptinTemplate = () => {
    for (var i = 1; i <= Object.keys(props.available_templates).length; i++) {
      if (props.available_templates[i].id === selectedTemplateId) {
        return props.available_templates[i];
      }
    }
  };

  const onSave = () => {
    var selectedTemplate = getCurrentSelectedOptinTemplate();

    // update
    var data: any = {
      optin_template: selectedTemplateId,
      is_optin_template_color_custom: selectedTemplate.is_customizable || false,
    };
    props.changeTemplate(selectedTemplateId, data);
    props.onModalClose();
  };

  const getAvailableTemplates = () => {
    var templates = [];
    var customizable_templates = [];
    var available_templates = props.available_templates;

    var custom = props.customSettings;

    var customStyle = {
      display: "flex",
      alignItems: "flex-start",
    };

    for (var i = 1; i <= Object.keys(available_templates).length; i++) {
      var template = (
        <div className="templateItem optins" key={i}>
          <label
            style={available_templates[i].is_customizable ? customStyle : {}}
          >
            <input
              type="radio"
              value={available_templates[i]["id"]}
              checked={selectedTemplateId === available_templates[i]["id"]}
              onChange={(val) => handleTemplateChange(val)}
            />
            <span className="tmplinput">
              {available_templates[i].is_customizable ? (
                <OptinTemplatePreview
                  template={available_templates[i]}
                  custom={custom}
                />
              ) : (
                <img
                  className="tmplimg"
                  src={available_templates[i].preview_url}
                  alt=""
                />
              )}
            </span>
          </label>
        </div>
      );
      if (available_templates[i].is_customizable) {
        customizable_templates.push(template);
      } else {
        templates.push(template);
      }
    }

    return (
      <div>
        <Modal.Section>
          <TextContainer>
            <b>Customizable Designs </b>
            <br></br>
            <TextStyle variation="subdued">
              (Want to set custom colours? Click on "Change Design" button and
              select one of the "Customizable Designs")
            </TextStyle>
          </TextContainer>
          <br />
          <div className="previewBackground optins-templates">
            <Stack>{customizable_templates}</Stack>
          </div>
        </Modal.Section>

        <Modal.Section>
          <TextContainer>
            <b>Pre-configured Widget Designs</b>
          </TextContainer>
          <br />
          <div className="previewBackground optins-templates">
            <Stack>{templates}</Stack>
          </div>
        </Modal.Section>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        props.onModalClose();
      }}
      title="Opt-in via Chat Widget Templates"
      primaryAction={{
        content: "Save",
        onAction: onSave,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpen(false);
            props.onModalClose();
          },
        },
      ]}
      limitHeight={false}
    >
      {getAvailableTemplates()}
    </Modal>
  );
};

export default ChangeOptinModal;
