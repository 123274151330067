import { Page, Layout } from "@shopify/polaris";
import { PLAN_FEATURES } from "../../../constants/Pricing/Features/constants";
import FeatureComponent from "./FeatureComponent";

const Features = (props: any) => {
  return (
    <Page title="">
      <div className="basic-feature-card">
        <Layout>
          {PLAN_FEATURES.map((feature, index) => {
            return (
              <FeatureComponent key={index} feature={feature} index={index} />
            );
          })}
        </Layout>
      </div>
    </Page>
  );
};

export default Features;
