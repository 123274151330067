import {
  CampaignIcon,
  WhatsAppWidgetIcon,
  TeamInboxIcon,
  AbandonedCartIcon,
} from "../common/SvgIcons";
import WAChat from "../assets/images/wa-chat.gif";
import AbondedCart from "../assets/images/abonded-cart.gif";
import WACampaign from "../assets/images/wa-campaign.gif";
import WAInbox from "../assets/images/wa-inbox.gif";

export const ONBOARD = {
  LANDING: {
    TITLE: "Welcome to SuperLemon by Gupshup!",
    DESCPRIPTION: `At SuperLemon, we believe in creating a world where businesses can connect with their customers in a seamless & intuitive way, using the power of conversational technology. Our mission is to provide businesses with the ultimate conversational tool!`,
    BUTTON: "Continue",
  },
  FEATURES_GIF: {
    TITLE: "What's in the store",
    FEATURES: [
      {
        ICON: WhatsAppWidgetIcon,
        NAME: "WhatApp Chat Widget",
        DESCPRIPTION: "for customer support & Share Widget to increase sales.",
        GIF_FILE: WAChat,
      },
      {
        ICON: AbandonedCartIcon,
        NAME: "Recover abandoned carts",
        DESCPRIPTION: "Recover abandoned carts & upsell product.",
        GIF_FILE: AbondedCart,
      },
      {
        ICON: CampaignIcon,
        NAME: "WhatsApp campaigns",
        DESCPRIPTION: "Create your own WhatsApp campaigns.",
        GIF_FILE: WACampaign,
      },
      {
        ICON: TeamInboxIcon,
        NAME: "WhatsApp inbox",
        DESCPRIPTION: "Manage multiple customer queries efficiently",
        GIF_FILE: WAInbox,
      },
    ],
    BUTTON: "Continue",
  },
};
