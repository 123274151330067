import { useEffect, useState } from 'react';
import {
  Layout,
  TextContainer,
  TextStyle,
  Heading,
  Icon,
  Link,
} from '@shopify/polaris';
import { SettingsMinor } from '@shopify/polaris-icons';
import {
  getAbondonedCartSettings,
  updateAbandonedCartSettings,
} from '../../../../redux/actions/abandonedCart';
import Switch from 'react-switch';
import {
  ABANDONED_CART_TEMPLATE_DICT,
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_DUTCH,
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_TURK,
  TEMPLATE_LANGUAGE_RUSSIAN,
  OPTIN_ENTERPRISE_TEMPLATES,
  PLATFORM,
} from '../../../../constants/constants';
import AbandonedCartModal from './AbandonCartModal';
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../../utils/clevertapUtils";
import { useShopStore } from '../../../../hooks/shopStore';

function AbandonedCart({
  userId,
  showToast,
  showErrorToast,
  phoneData,
  checkForApproval,
  privateWabaDetails,
  optinEnterpriseTemplates,
}: any) {
  const { shopDetailsData } = useShopStore();
  var TEMPLATE_DICT: any = ABANDONED_CART_TEMPLATE_DICT;
  const [openAbandonedCartSettingsModal, setOpenAbandonedCartSettingsModal] =
    useState(false);
  const [isAbandonedCartEnabled, setIsAbandonedCartEnabled] =
    useState<boolean>(false);
  const [abandonedCartLanguage, setAbandonedCartLanguage] = useState<any>(
    TEMPLATE_LANGUAGE_ENG
  );
  const [abCartData, setabCartData] = useState({});
  useEffect(() => {
    if (userId) {
      getAbandonCartData(userId);
    }
  }, [userId]);
  let shop = shopDetailsData;

  const getAbandonCartData = (userId: string) => {
    getAbondonedCartSettings(userId).then((res: any) => {
      if (res.status === 'success') {
        if (res.settings.language === 'NONE') {
          res.settings.language = TEMPLATE_LANGUAGE_ENG;
        }
        setIsAbandonedCartEnabled(res.settings.enabled);
        setAbandonedCartLanguage(res.settings.language);
        setabCartData(res.settings);
      } else {
        showErrorToast(res);
      }
    });
  };
  const getAbandonedCartPreview = () => {
    switch (abandonedCartLanguage) {
      case TEMPLATE_LANGUAGE_ENG:
        return 'https://cdn.shopify.com/s/files/1/0070/3666/5911/files/eng-abandoned_cart_preview.png?v=1587749560';
      case TEMPLATE_LANGUAGE_PORT:
        return 'https://cdn.shopify.com/s/files/1/0070/3666/5911/files/por-abandoned_cart_preview.png?v=1587749560';
      case TEMPLATE_LANGUAGE_SPAN:
        return 'https://cdn.shopify.com/s/files/1/0070/3666/5911/files/spa-abandoned_cart_preview.png?v=1587749561';
      case TEMPLATE_LANGUAGE_ITAL:
        return 'https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ita-abandoned_cart_preview.png?v=1587749560';
      case TEMPLATE_LANGUAGE_FREN:
        return 'https://cdn.shopify.com/s/files/1/0070/3666/5911/files/fr-abandoned_cart_preview.png?v=1587749561';
      case TEMPLATE_LANGUAGE_INDO:
        return 'https://cdn.shopify.com/s/files/1/0070/3666/5911/files/indo-abandoned_cart_preview.png?v=1587749560';
      case TEMPLATE_LANGUAGE_GERM:
        return 'https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ger-abandoned_cart_preview-updated.png?v=1587819719';
      case TEMPLATE_LANGUAGE_ARAB:
        return 'https://cdn.shopify.com/s/files/1/0070/3666/5911/files/arabic-abandoned_cart_preview.png?v=1587749558';
      case TEMPLATE_LANGUAGE_TURK:
        return 'https://cdn.shopify.com/s/files/1/0070/3666/5911/files/turkish-abandoned_cart_preview.png?v=1587819633';
      case TEMPLATE_LANGUAGE_HEBR:
        return 'https://cdn.shopify.com/s/files/1/0070/3666/5911/files/hebrew-abandoned_cart_preview.png?v=1591679011';
      case TEMPLATE_LANGUAGE_DUTCH:
        return 'https://cdn.shopify.com/s/files/1/0070/3666/5911/files/dutch-abandoned_cart_preview.png?v=1591677401';
      case TEMPLATE_LANGUAGE_RUSSIAN:
        return 'https://cdn.shopify.com/s/files/1/0449/7794/6790/files/russian_abandoned_cart.png?v=1654772304';
    }
  };

  const enableAbandonedCart = (enabled: boolean) => {
    if (enabled && !checkForApproval()) return;
    if (
      (!phoneData.phone ||
      !phoneData.countryCode ||
      phoneData.phone.length === 0 ||
      phoneData.countryCode.length === 0) &&
      enabled
    ) {
      showErrorToast('Please add WhatsApp support number by going to Settings');
      return;
    }
    var params: any = new URLSearchParams();
    params.append('enabled', enabled);
    updateAbandonedCartSettings(userId, params).then(
      (response: any) => {
        if (response.status === 'success') {
          setIsAbandonedCartEnabled(response.settings.enabled);
          showToast(
            'Abandoned Cart Recovery message template has been ' +
              (enabled ? 'ENABLED' : 'DISABLED')
          );
          if (enabled) {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_ENABLED,
              eventData: {
                template_name: 'Abandoned Cart',
                template_language: abandonedCartLanguage,
                'Private waba':
                  localStorage.getItem('private_waba') === 'true'
                    ? true
                    : false,
              },
            });
          } else {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_DISABLED,
              eventData: {
                template_name: 'Abandoned Cart',
                template_language: abandonedCartLanguage,
                'Private waba':
                  localStorage.getItem('private_waba') === 'true'
                    ? true
                    : false,
              },
            });
          }
        } else {
          showErrorToast(response);
        }
      },
      (err: any) => {
        showErrorToast('Something went wrong');
      }
    );
  };

  const abandonedCartMarkup = () => {
    return (
      <>
        <Layout.Section>
          <div className='template-info-container'>
            <div className='Polaris-TextContainer Polaris-TextContainer--spacingTight template-info'>
              <div className='templateSwitchMobCss'>
                <Heading>Abandoned Cart</Heading>
                <Switch
                  onChange={() => enableAbandonedCart(!isAbandonedCartEnabled)}
                  checked={isAbandonedCartEnabled}
                  onColor='#5DC001'
                  onHandleColor='#fff'
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                  activeBoxShadow='0px 0px 1px 6px rgba(0, 0, 0, 0.2)'
                  height={20}
                  width={48}
                  handleDiameter={30}
                />
              </div>
              <TextContainer spacing='loose'>
                {isAbandonedCartEnabled ? (
                  <p>
                    Recover abandoned carts by reaching your customers on
                    WhatsApp. Message is sent
                    {[PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
                      ? ' 1 hour '
                      : ' 15 minutes '}
                    after customer abandons cart.
                  </p>
                ) : (
                  <p>
                    In 2021, we helped 15K merchants to generate additional $15
                    million by simply converting abandoned carts into successful
                    orders.
                  </p>
                )}
                <br />

                {/* <p>
                  To learn about the cost of sending automated messages, view{" "}
                  <Link
                    external={true}
                    url="https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0"
                  >
                    {"pricing chart"}
                  </Link>
                </p> */}
              </TextContainer>
            </div>
            <div className='Polaris-Card w-100'>
              <div className='Polaris-Card__Section break-word'>
                <TextContainer>
                  <TextStyle>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          optinEnterpriseTemplates &&
                          abandonedCartLanguage === TEMPLATE_LANGUAGE_ENG
                            ? OPTIN_ENTERPRISE_TEMPLATES.abandoned_cart_recovery_template
                            : TEMPLATE_DICT[abandonedCartLanguage][
                                'abandoned_cart_recovery_template'
                              ],
                      }}
                    ></span>
                    <br />
                    <br />
                    {!(
                      optinEnterpriseTemplates &&
                      abandonedCartLanguage === TEMPLATE_LANGUAGE_ENG
                    ) && (
                      <Link
                        external={true}
                        url={getAbandonedCartPreview()}
                        onClick={() => {
                          clevertapEventPush({
                            eventId:
                              CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_PREVIEWED,
                            eventData: {
                              template_name: 'Abandoned Cart',
                              template_language: abandonedCartLanguage,
                              'Private waba':
                                localStorage.getItem('private_waba') === 'true'
                                  ? true
                                  : false,
                            },
                          });
                        }}
                      >
                        Preview
                      </Link>
                    )}
                  </TextStyle>
                </TextContainer>
              </div>
            </div>
          </div>
        </Layout.Section>
        <div className='template Polaris-Layout__Section Polaris-Layout__Section--secondary m-auto text-center'>
          <div className='flex-col w-100'>
            <div>
              <button
                className={
                  isAbandonedCartEnabled
                    ? 'Polaris-Button Polaris-Button--primary Polaris-Button--outline w-50'
                    : 'Polaris-Button Polaris-Button--outline Polaris-Button--disabled w-50'
                }
                type='button'
                disabled={!isAbandonedCartEnabled}
                onClick={() => setOpenAbandonedCartSettingsModal(true)}
              >
                <span className='Polaris-Button__Content'>
                  <span className='Polaris-Button__Text'>
                    <div className='d-flex-center'>
                      <div>
                        <Icon source={SettingsMinor} />
                      </div>
                      <div>&nbsp;Settings</div>
                    </div>
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {userId ? (
        <>
          {abandonedCartMarkup()}
          <AbandonedCartModal
            open={openAbandonedCartSettingsModal}
            closeModal={() => setOpenAbandonedCartSettingsModal(false)}
            showToast={showToast}
            showErrorToast={showErrorToast}
            setAbandonedCartLanguage={setAbandonedCartLanguage}
            abCartData={abCartData}
          />
        </>
      ) : null}
    </>
  );
}

export default AbandonedCart;
