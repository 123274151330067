// @ts-nocheck
import { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import useToken from './hooks/useToken';
import { useShopStore } from './hooks/shopStore';
import './App.scss';
import PublicRoutes from './common/PublicRoutes';
import PrivateRoutes from './common/PrivateRoutes';

import { clevertapEventPush, CT_EVENT_IDS } from './utils/clevertapUtils';
import {
  PLAN_OLD_FREE,
  UNAUTHORIZED_LINKS,
  HIDE_FRAME_LINKS,
  PLATFORM,
  SHOP_FEATURES,
} from './constants/constants';

// Firebase
import { remoteConfig } from './utils/firebase';
import { shopFeaturesFromStoreData } from './utils/helper';
import { UsersProvider } from './context/usersContext';

const App = () => {
  const { token, setToken } = useToken();
  const { agentPhone, shopDetailsData, shopOwnerDetails } = useShopStore();
  const [showFrame, setShowFrame] = useState(false);
  const [owner, setOwner] = useState<any>();
  const [shopDetails, setShopDetails] = useState<any>(shopDetailsData);
  const appLaunched = useRef(false);
  const location = useLocation();
  const history = useHistory();
  let currentPath = history.location.pathname;
  const isForwardSlashPresent = currentPath.split('').pop();
  if (currentPath !== '/' && isForwardSlashPresent === '/') {
    currentPath = currentPath.slice(0, -1);
  }

  useEffect(() => {
    if (shopDetailsData) {
      setShopDetails(shopDetailsData);
    }
  }, [shopDetailsData]);

  const eventPush = useCallback(
    () =>
      setTimeout(() => {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.APP_LAUNCHED,
          eventData: {},
        });
        appLaunched.current = true;
      }, 1000),
    []
  );

  useEffect(() => {
    !appLaunched.current && eventPush();
    remoteConfig.settings.minimumFetchIntervalMillis = 0;
    // getMaintenanceStatus();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!token && !UNAUTHORIZED_LINKS.includes(currentPath)) {
      history.push('/login');
    }
    if (HIDE_FRAME_LINKS.includes(currentPath)) {
      setShowFrame(false);
    } else {
      setShowFrame(true);
    }

    const protectRoutes = () => {
      const checkIfRouteAllowed = (
        shopData: any,
        planId: string,
        platform: string
      ) => {
        const routesNotAllowed = [];
        if (
          [PLAN_OLD_FREE].includes(Number(planId)) &&
          [PLATFORM.WIX, PLATFORM.NONE].includes(platform) &&
          location.pathname == '/home'
        ) {
          history.push('/private-waba');
        }
        if (
          !shopFeaturesFromStoreData(shopData).includes(
            SHOP_FEATURES.CUSTOMERS_LIST
          )
        )
          routesNotAllowed.push('/optins');
        if (
          !shopFeaturesFromStoreData(shopData).includes(SHOP_FEATURES.SETTINGS)
        )
          routesNotAllowed.push('/admin-config');
        if (routesNotAllowed.indexOf(currentPath) !== -1) {
          history.push('/pricing');
        }
        return;
      };

      if (currentPath !== '/login' && currentPath !== '/') {
        if (shopDetails) {
          checkIfRouteAllowed(
            shopDetails,
            shopDetails?.planId,
            shopDetails?.platform
          );
        }
      }
    };

    if (!UNAUTHORIZED_LINKS.includes(currentPath)) {
      protectRoutes();
    }
    //eslint-disable-next-line
  }, [location.pathname, shopDetails]);

  const setOwnerDetails = () => {
    setOwner(shopOwnerDetails ? shopOwnerDetails : '');
  };

  const hideFrame = (val: boolean) => {
    setShowFrame(val);
  };

  return (
    <>
      <UsersProvider agent={agentPhone}>
        {showFrame ? (
          <PrivateRoutes
            setOwnerDetails={setOwnerDetails}
            setToken={setToken}
            hideFrame={hideFrame}
          />
        ) : (
          <PublicRoutes
            setOwnerDetails={setOwnerDetails}
            setToken={setToken}
            hideFrame={hideFrame}
          />
        )}
      </UsersProvider>
    </>
  );
};

export default App;
