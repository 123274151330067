// @ts-nocheck
import { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import {
  Form,
  FormLayout,
  TextField,
  Toast,
  Stack,
  Frame,
} from '@shopify/polaris';
import cookie from 'react-cookies';
import useCustomForm from '../../hooks/useCustomForm';
import {
  ACCOUNT_OAUTH,
  ACCOUNT_OAUTH_WIX,
  SERVER_ADDR,
} from '../../constants/api-constants';
import { LOGIN_PAGE } from '../../constants/login-constants';
import { getShopDetails, getOwnerDetails } from '../../redux/actions/shop';
import SuperlemonLogo from '../../assets/images/superlemon-logo_2x.png';
import LoginBannerWixShopify from '../../assets/images/login-banner-2-wixshopify.png';
import LoginBanner from '../../assets/images/login-banner-2.svg';
import ShopifyLogo from '../../assets/icons/shopify-login.png';
import GoogleLogo from '../../assets/icons/google-login.png';
import WhatsappLogo from '../../assets/icons/whatsapp-login.png';
import WixLogo from '../../assets/icons/wix-login.png';
import { whatsappAuth, redirectUser } from './whatsappLogin';
import LoadingUI from '../../common/LoadingUI';
import CustomForm from '../../common/CustomForm';
import {
  getGoogleRedirectionUrl,
  user_forgot_password,
  user_login,
  user_reset_password,
} from '../../redux/actions/login';
import { DOMAIN } from '../../constants/constants';

const Login = (props: any) => {
  const [fields] = useState({ shopId: '' });
  const [fieldsWix] = useState({ shopId: '' });
  const [token, setToken] = useState<string>();
  const [showWixLogin, setShowWixLogin] = useState(false);
  const [showShopifyLogin, setShowShopifyLogin] = useState(false);
  const [loginMethodClicked, setLoginMethodClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onlyWAOnce, setOnlyWAOnce] = useState(true);
  const [onlyRPOnce, setOnlyRPOnce] = useState(true);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const whatsappToken = searchParams?.get('token');
  const resetPassword = searchParams?.get('resetToken');

  const [showForgotPasswordMarkup, setShowForgotPasswordMarkup] =
    useState(false);

  const [showResetPasswordMarkup, setShowResetPasswordMarkup] = useState(false);

  useEffect(() => {
    if (cookie.load('access_token')) {
      props.setToken(cookie.load('access_token'));
      setToken(cookie.load('access_token'));
      props.history.push('/home');
    }
  }, []);

  const setCookieManually = (access_token: any) => {
    document.cookie = `access_token=${access_token};max-age=604800;domain=${DOMAIN}`; //setting cookie manually
    props.setToken(access_token);
    setToken(access_token);
  };

  //const authenticateUserViaWA = async () => {
  //  setLoading(true);
  //  const apiRes = await whatsappAuth(whatsappToken);
  //  if (apiRes.status === 'success') {
  //    setCookieManually(apiRes.cookie);
  //  } else {
  //    setLoading(false);
  //  }
  //};

  //useEffect(() => {
  //  if (whatsappToken && onlyWAOnce) {
  //    authenticateUserViaWA(whatsappToken);
  //    setOnlyWAOnce(false);
  //  }
  //}, [whatsappToken]);

  //useEffect(() => {
  //  if (resetPassword && onlyRPOnce) {
  //    setShowResetPasswordMarkup(true);
  //    setOnlyRPOnce(false);
  //  }
  //}, [resetPassword]);

  const [toast, setToast] = useState({
    show: false,
    message: '',
  });

  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    console.log("Token change detected")
    if (token) {
      console.log("Token found , fetching shop details")
      fetchShopDetails();
    }
  }, [token]);

  let winObj: any;

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const validateStoreId = () => {
    if (firstTime) {
      return false;
    }
    //eslint-disable-next-line
    var shopURLRegex =
      /^[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*[a-z0-9]{2,5}(:[0-9]{1,5})?$/;

    if (
      inputsShopify.shopId === '' ||
      inputsShopify.shopId === undefined ||
      !shopURLRegex.test(inputsShopify.shopId)
    ) {
      return 'Enter a valid store id';
    } else {
      return false;
    }
  };

  const validateStoreIdWix = () => {
    if (firstTime) {
      return false;
    }
    //eslint-disable-next-line
    var shopURLRegex =
      /^[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*[a-z0-9]{2,5}(:[0-9]{1,5})?$/;

    if (
      inputsWix.shopId === '' ||
      inputsWix.shopId === undefined ||
      !shopURLRegex.test(inputsWix.shopId)
    ) {
      return 'Enter a valid store id';
    } else {
      return false;
    }
  };

  const openModal = () => {
    if (inputsShopify.shopId != null && inputsShopify.shopId.length > 0) {
      //eslint-disable-next-line
      var shopURLRegex =
        /^[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*[a-z0-9]{2,5}(:[0-9]{1,5})?$/;

      if (
        inputsShopify.shopId === '' ||
        inputsShopify.shopId === undefined ||
        !shopURLRegex.test(inputsShopify.shopId)
      ) {
        setToast({ show: true, message: 'Invalid shop name' });
        return;
      }

      var url = SERVER_ADDR + ACCOUNT_OAUTH + '?shop=' + inputsShopify.shopId;
      winObj = window.open(
        url,
        '_blank',
        'toolbar=no,scrollable=no,left=100,width=600,height=600'
      );

      let interval = setInterval(() => {
        if (winObj.closed && cookie.load('access_token')) {
            props.setToken(cookie.load('access_token'));
            setToken(cookie.load('access_token'));
            clearInterval(interval);
        }
      }, 1000);
    }
  };

  const openModalWix = () => {
    if (inputsWix.shopId != null && inputsWix.shopId.length > 0) {
      //eslint-disable-next-line
      var shopURLRegex =
        /^[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*[a-z0-9]{2,5}(:[0-9]{1,5})?$/;

      if (
        inputsWix.shopId === '' ||
        inputsWix.shopId === undefined ||
        !shopURLRegex.test(inputsWix.shopId)
      ) {
        setToast({ show: true, message: 'Invalid shop name' });
        return;
      }

      var url = SERVER_ADDR + ACCOUNT_OAUTH_WIX + '?shop=' + inputsWix.shopId;
      winObj = window.open(
        url,
        '_blank',
        'toolbar=no,scrollable=no,left=100,width=600,height=600'
      );

      let interval = setInterval(() => {
        if (winObj.closed && cookie.load('access_token')) {
            props.setToken(cookie.load('access_token'));
            setToken(cookie.load('access_token'));
            clearInterval(interval);
        }
      }, 1000);
    }
  };

  const openGoogleModal = async () => {
    const apiRes: any = await getGoogleRedirectionUrl();
    if (apiRes?.status === 'success' && apiRes?.redirectionUrl) {
      winObj = window.open(
        apiRes.redirectionUrl,
        '_blank',
        'toolbar=no,scrollable=no,left=100,width=600,height=600'
      );
    }

    let interval = setInterval(() => {
      if (winObj.closed) {
        props.setToken(cookie.load('access_token'));
        setToken(cookie.load('access_token'));
        clearInterval(interval);
      }
    }, 1000);
  };

  const fetchOwnerDetails = (shop: any) => {
    getOwnerDetails(shop.userId).then(
      (response: any) => {
        if (response) {
          props.setOwner();
        }
        props.history.push('/home');
        window.location.reload();
      },
      (err) => {
        props.history.push('/home');
        window.location.reload();
      }
    );
  };

  const fetchShopDetails = () => {
    getShopDetails().then((response: any) => {
      if (response.status === 'success') {
        fetchOwnerDetails(response.shop);
        localStorage.setItem('currentPlan', response.shop.planId);
      }
    });
  };

  const handleFormSubmit = async (data: any) => {
    setLoading(true);
    const apiRes: any = await user_login(data);
    if (apiRes.status === 'success') {
      setCookieManually(apiRes.cookie);
    } else {
      setLoading(false);
    }
  };

  const handleResetPasswordFormSubmit = async (data: any) => {
    if (data.newPassword === data.confirmPassword) {
      setLoading(true);
      const apiRes: any = await user_reset_password(data);
      if (apiRes.status === 'success') {
        window.location.reload();
      }
      setLoading(false);
    } else {
      setToast({ show: true, message: 'Password mismatch' });
    }
  };

  const handleForgotPasswordFormSubmit = async (data: any) => {
    const apiRes: any = await user_forgot_password(data);
    if (apiRes.status === 'success') {
      setToast({ show: true, message: 'Link sent' });
    }
  };

  const { handleSubmitShopify, handleInputChangeShopify, inputsShopify } =
    useCustomForm(openModal, fields);

  const { handleSubmitWix, handleInputChangeWix, inputsWix } = useCustomForm(
    openModalWix,
    fieldsWix
  );

  const handleLoginClick = async (loginMethod: any) => {
    setLoginMethodClicked(true);
    switch (loginMethod) {
      case 'WIX':
        setShowWixLogin(true);
        break;
      case 'EMAIL_PASSWORD':
        break;
      case 'GOOGLE':
        openGoogleModal();
        break;
      case 'SHOPIFY':
        setShowShopifyLogin(true);
        break;
      case 'WHATSAPP':
        setLoading(true);
        if (!(await redirectUser())) {
          setLoading(false);
          setLoginMethodClicked(false);
        }
        setLoading(false);
        break;
    }
  };

  const wixLoginMarkup = () => {
    return (
      <>
        <div className='login-content'>
          <div className='login text-center'>
            <Stack vertical>
              <img src={SuperlemonLogo} alt='Superlemon Logo' className='w-1' />
              <p className='strong fs-2 mt-2 mb-2'>
                {LOGIN_PAGE.WIX_LOGIN_PAGE.TITLE}
              </p>
              <Form onSubmit={handleSubmitWix}>
                <FormLayout>
                  <TextField
                    label={LOGIN_PAGE.WIX_LOGIN_PAGE.TITLE}
                    value={inputsWix.shopId}
                    onChange={(val) => {
                      handleInputChangeWix('shopId', val);
                      setFirstTime(false);
                    }}
                    error={validateStoreIdWix()}
                    placeholder={LOGIN_PAGE.WIX_LOGIN_PAGE.PLACEHOLDER}
                    align='center'
                    labelHidden={true}
                  ></TextField>
                  <button
                    className='Polaris-Button Polaris-Button--primary mt-1'
                    type='submit'
                  >
                    <span className='Polaris-Button__Content'>
                      <span className='Polaris-Button__Text'>
                        {LOGIN_PAGE.WIX_LOGIN_PAGE.BUTTON}
                      </span>
                    </span>
                  </button>

                  <div className='appStore'>
                    {LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.WIX_APP_STORE.DESCP}
                    &ensp;
                    <a
                      href={LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.WIX_APP_STORE.LINK}
                      target='_blank'
                      className='forgot-pass-link'
                      rel='noreferrer'
                    >
                      {LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.WIX_APP_STORE.LABEL}
                    </a>
                  </div>
                </FormLayout>
              </Form>
            </Stack>
          </div>
        </div>
        <div className='login-image-div'>
          <img
            src={LoginBannerWixShopify}
            alt=''
            className='login-right-image'
          ></img>
        </div>
      </>
    );
  };

  const shopifyLoginMarkup = () => {
    return (
      <>
        <div className='login-content'>
          <div className='login text-center'>
            <Stack vertical>
              <img src={SuperlemonLogo} alt='Superlemon Logo' className='w-1' />
              <p className='strong fs-2 mt-2 mb-2'>
                {LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.TITLE}
              </p>
              <Form onSubmit={handleSubmitShopify}>
                <FormLayout>
                  <TextField
                    label={LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.TITLE}
                    value={inputsShopify.shopId}
                    onChange={(val) => {
                      handleInputChangeShopify('shopId', val);
                      setFirstTime(false);
                    }}
                    error={validateStoreId()}
                    placeholder={LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.PLACEHOLDER}
                    align='center'
                    labelHidden={true}
                  ></TextField>
                  <button
                    className='Polaris-Button Polaris-Button--primary mt-1'
                    type='submit'
                  >
                    <span className='Polaris-Button__Content'>
                      <span className='Polaris-Button__Text'>
                        {LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.BUTTON}
                      </span>
                    </span>
                  </button>

                  <div className='appStore'>
                    {LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.SHOPIFY_APP_STORE.DESCP}
                    &ensp;
                    <a
                      href={
                        LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.SHOPIFY_APP_STORE.LINK
                      }
                      target='_blank'
                      className='forgot-pass-link'
                      rel='noreferrer'
                    >
                      {LOGIN_PAGE.SHOPIFY_LOGIN_PAGE.SHOPIFY_APP_STORE.LABEL}
                    </a>
                  </div>
                </FormLayout>
              </Form>
            </Stack>
          </div>
        </div>
        <div className='login-image-div'>
          <img
            src={LoginBannerWixShopify}
            alt=''
            className='login-right-image'
          ></img>
        </div>
      </>
    );
  };

  const elementsBeforeSubmitButton = () => {
    return (
      <p
        className='forgot-pass-link'
        onClick={() => {
          setShowForgotPasswordMarkup(true);
        }}
      >
        {LOGIN_PAGE.EMAIL_LOGIN.FORGOT_PASSWORD_LINK}
      </p>
    );
  };

  const forgotPasswordMarkup = () => {
    return (
      <>
        <div className='strong fs-2 mt-2 mb-2'>
          {LOGIN_PAGE.FORGOT_PASSWORD.TITLE}
        </div>
        <div>{LOGIN_PAGE.FORGOT_PASSWORD.SUB_TITLE}</div>
        <br />
        <CustomForm
          handleFormSubmit={handleForgotPasswordFormSubmit}
          data={LOGIN_PAGE.FORGOT_PASSWORD}
        />
      </>
    );
  };

  const resetPasswordMarkup = () => {
    return (
      <>
        <div className='strong fs-2 mt-2 mb-2'>
          {LOGIN_PAGE.RESET_PASSWORD.TITLE}
        </div>
        <div>{LOGIN_PAGE.RESET_PASSWORD.SUB_TITLE}</div>
        <br />
        <CustomForm
          handleFormSubmit={handleResetPasswordFormSubmit}
          data={LOGIN_PAGE.RESET_PASSWORD}
        />
      </>
    );
  };

  if (loading) {
    return <LoadingUI />;
  } else {
    if (loginMethodClicked) {
      return (
        <Frame>
          <div className='login-container'>
            {showWixLogin && wixLoginMarkup()}
            {showShopifyLogin && shopifyLoginMarkup()}
          </div>
        </Frame>
      );
    } else {
      return (
        <Frame>
          {toastMarkup}
          <div className='login-container'>
            <div className='login-left'>
              <img
                src={SuperlemonLogo}
                alt='Superlemon Logo'
                className='sl-logo-login'
              />
              <img src={LoginBanner} alt='' className='login-image'></img>
            </div>
            {showForgotPasswordMarkup ? (
              <div className='login-right'>{forgotPasswordMarkup()}</div>
            ) : showResetPasswordMarkup ? (
              <div className='login-right'>{resetPasswordMarkup()}</div>
            ) : (
              <div className='login-right'>
                <div className='strong fs-2 mt-2 mb-2'>
                  {LOGIN_PAGE.EMAIL_LOGIN.TITLE}
                </div>
                <div>{LOGIN_PAGE.EMAIL_LOGIN.SUB_TITLE}</div>
                <br />

                {/*
                 <CustomForm
                  handleFormSubmit={handleFormSubmit}
                  data={LOGIN_PAGE.EMAIL_LOGIN}
                  elementsBeforeSubmitButton={elementsBeforeSubmitButton}
                />
                <span className='hline'> {LOGIN_PAGE.OR} </span>

                */}
                <div className='social-login-container-signup'>
                  <div
                    className='login-button-outline'
                    onClick={() => handleLoginClick('SHOPIFY')}
                  >
                    <div className='loginBtnIcon'>
                      <img
                        src={ShopifyLogo}
                        alt='Shopify Logo'
                        width={21}
                        height={21}
                      />
                    </div>
                    <div className='loginBtnText'>
                      {LOGIN_PAGE.SHOPIFY_LOGIN.BUTTON}
                    </div>
                  </div>
                  {/*
                 <div
                    className='login-button-outline'
                    onClick={() => handleLoginClick('GOOGLE')}
                  >
                    <div className='loginBtnIcon'>
                      <img
                        src={GoogleLogo}
                        alt='Google Logo'
                        width={21}
                        height={21}
                      />
                    </div>
                    <div className='loginBtnText'>
                      {LOGIN_PAGE.GOOGLE_LOGIN.BUTTON}
                    </div>
                  </div>
                 */}
                </div>
                <div className='social-login-container-signup'>
                  {/*<div
                    className='login-button-outline'
                    onClick={() => handleLoginClick('WHATSAPP')}
                  >
                    <div className='loginBtnIcon'>
                      <img
                        src={WhatsappLogo}
                        alt='Whatsapp Logo'
                        width={30}
                        height={30}
                      />
                    </div>
                    <div className='loginBtnText'>
                      {LOGIN_PAGE.WHATSAPP_LOGIN.BUTTON}
                    </div>
                  </div>
                  */}
                  <div
                    className='login-button-outline'
                    onClick={() => handleLoginClick('WIX')}
                  >
                    <div className='loginBtnIcon'>
                      <img
                        src={WixLogo}
                        alt='WiX Logo'
                        width={25}
                        height={25}
                      />
                    </div>
                    <div className='loginBtnText'>
                      {LOGIN_PAGE.WIX_LOGIN.BUTTON}
                    </div>
                  </div>
                </div>
                <div className='signUp'>
                  {LOGIN_PAGE.NO_ACCOUNT_LABEL}
                  &ensp;
                  <a href='/signup' className='forgot-pass-link'>
                    {LOGIN_PAGE.SIGNUP_LINK}
                  </a>
                </div>
              </div>
            )}
          </div>
        </Frame>
      );
    }
  }
};

export default withRouter(Login);
