import { useEffect, useState } from "react";
import {
  Card,
  Layout,
  Link,
  Modal,
  RadioButton,
  Select,
  Stack,
  TextContainer,
} from "@shopify/polaris";
import { objectDifference } from "../../../../utils/helper";
import { updateCrossSellTemplateSettings } from "../../../../redux/actions/whatsappPromotional";
import DiscountMarkup from "../../../AbandonedCart/DiscountMarkup";
import {
  AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW,
  COUPON_TYPE_PERCENT,
  COUPON_TYPE_STATIC,
  TEMPLATE_LANGUAGE_ENG,
  nonOptinMessage,
  PLATFORM,
} from "../../../../constants/constants";
import NonOptinMessageSettings from "../../../../common/NonOptinMessage";
import { useShopStore } from '../../../../hooks/shopStore';

const SettingsModal = (
  {
    showToast,
    showErrorToast,
    open,
    closeModal,
    setTemplateLanguage,
    templateData,
    enabled,
    setTemplateData,
    optinEnterpriseTemplates,
  }: any,
  props: any
) => {
  const { shopDetailsData } = useShopStore();
  const [openModal, setOpenModal] = useState(open);
  const [initialOrderData, setInitialOrderData] = useState({
    couponCode: "",
    discount: false,
    discountType: "FIXED",
    discountRatio: 10,
    language: "ENGLISH",
    interval: 7,
    nonOptinEnabled: true,
  });
  const [orderData, setOrderData] = useState({
    couponCode: "",
    discount: false,
    discountType: "FIXED",
    discountRatio: 10,
    language: "ENGLISH",
    interval: 7,
    nonOptinEnabled: true,
  });
  const [refOrderData, setRefOrderData] = useState({
    couponCode: "",
    discount: false,
    discountType: "FIXED",
    discountRatio: 10,
    language: "ENGLISH",
    interval: 7,
    nonOptinEnabled: true,
  });
  const isPrivateWABAPresent = JSON.parse(
    localStorage.getItem("private_waba") || "{}"
  );

  const [dataChanges, setDataChanges] = useState<any>({
    template: null,
  });
  const [activateErrorCheckForCouponCode, setActivateErrorCheckForCouponCode] =
    useState(false);
  const [
    activateErrorCheckForhandleDiscountChange,
    setActivateErrorCheckForhandleDiscountChange,
  ] = useState(false);

  useEffect(() => {
    setInitialOrderData(templateData);
    setRefOrderData(templateData);
    setOrderData(templateData);
  }, [templateData]);

  let shop = shopDetailsData;

  useEffect(() => {
    setOpenModal(open);
  }, [props, open]);

  useEffect(() => {
    var diffObj: any = objectDifference(refOrderData, orderData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        template: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        template: null,
      }));
    }
  }, [orderData, refOrderData]);

  const resetData = () => {
    setDataChanges({
      template: null,
    });
    setRefOrderData(initialOrderData);
    setOrderData(initialOrderData);
  };

  const handleOrderChange = (field: string, value: any) => {
    setOrderData((data) => ({ ...data, [field]: value }));
  };
  const toggleDiscount = () => {
    setOrderData((data) => ({
      ...data,
      discount: !orderData.discount,
      discountType: COUPON_TYPE_PERCENT,
    }));
  };
  const handleDiscountTypeChange = (newValue: any) => {
    setOrderData((data: any) => ({ ...data, discountType: newValue }));
  };

  const handleDiscountChange = (newValue: any) => {
    if (!activateErrorCheckForhandleDiscountChange) {
      setActivateErrorCheckForhandleDiscountChange(true);
    }
    if (newValue <= 100 && newValue >= 0) {
      setOrderData((data) => ({ ...data, discountRatio: newValue }));
    }
  };

  const handleCouponChange = (newValue: any) => {
    if (!activateErrorCheckForCouponCode) {
      setActivateErrorCheckForCouponCode(true);
    }
    setOrderData((data) => ({ ...data, couponCode: newValue }));
  };

  const validateFixedCouponCode = (check = false) => {
    if (activateErrorCheckForCouponCode || check) {
      if (
        orderData.discount &&
        orderData.discountType === COUPON_TYPE_STATIC &&
        !orderData.couponCode
      ) {
        return "Coupon code cannot be empty";
      }
    }
    return false;
  };

  const validateDiscountRatio = (check = false) => {
    if (activateErrorCheckForhandleDiscountChange || check) {
      if (
        orderData.discount &&
        orderData.discountType === COUPON_TYPE_PERCENT &&
        String(orderData.discountRatio) === "0"
      ) {
        return "Discount Value cannot be 0";
      }
      if (
        orderData.discount &&
        orderData.discountType === COUPON_TYPE_PERCENT &&
        !orderData.discountRatio
      ) {
        return "Discount Value cannot be empty";
      }
    }
    return false;
  };
  const intervalDescription = (
    <TextContainer spacing="loose">
      <p>
        Select the number of days after which you want the template to get
        triggered.
      </p>
    </TextContainer>
  );
  const discountDescription = (
    <TextContainer spacing="loose">
      <p>You can enable and select the discount type.</p>
    </TextContainer>
  );
  const daysOptions = [
    { label: "3 Days", value: "3" },
    { label: "4 Days", value: "4" },
    { label: "5 Days", value: "5" },
    { label: "6 Days", value: "6" },
    { label: "7 Days", value: "7" },
  ];
  const intervalHelpText = (
    <TextContainer>Maximum days can be 7 and minimum is 3 days.</TextContainer>
  );
  const description = (
    <span>
      <p>
        <b>
          {
            "Automated WhatsApp messages are sent from our official WhatsApp Business API approved "
          }
        </b>
        <Link external={true} url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}>
          {"phone number"}
        </Link>
        {" using message templates approved by WhatsApp."}
        <br />
        <br />
        We include your{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          business name
        </Link>{" "}
        and{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          WhatsApp support number
        </Link>{" "}
        in every automated message. Your customers can recognise you and contact
        you easily if they need help.
      </p>
    </span>
  );
  const nudgeCheckoutOptinScrollSection = (
    <div id="nudgeCheckoutOptin_anchor">
      <Card></Card>
    </div>
  );
  const handleLanguageChange = (newValue: any) => {
    return () => {
      handleTemplateValueChange("language", newValue);
    };
  };

  const handleTemplateValueChange = (field: string, value: any) => {
    setOrderData((data: any) => ({ ...data, [field]: value }));
  };

  const languageMarkup = (
    <Card sectioned>
      <Stack vertical>
        <TextContainer>
          Message Language (all messages will be sent in this language)
        </TextContainer>
        <Stack>
          <RadioButton
            label="English"
            checked={orderData.language === TEMPLATE_LANGUAGE_ENG}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ENG)}
            name="language"
          />
          {/* Below languages not supported for now */}
          {/* <RadioButton
            label="Portugese"
            checked={orderData.language === TEMPLATE_LANGUAGE_PORT}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_PORT)}
            name="language"
          />
          <RadioButton
            label="Spanish"
            checked={orderData.language === TEMPLATE_LANGUAGE_SPAN}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_SPAN)}
            name="language"
          />
          <RadioButton
            label="Italian"
            checked={orderData.language === TEMPLATE_LANGUAGE_ITAL}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ITAL)}
            name="language"
          />
          <RadioButton
            label="French"
            checked={orderData.language === TEMPLATE_LANGUAGE_FREN}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_FREN)}
            name="language"
          />
          <RadioButton
            label="Indonesian"
            checked={orderData.language === TEMPLATE_LANGUAGE_INDO}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_INDO)}
            name="language"
          />
          <RadioButton
            label="German"
            checked={orderData.language === TEMPLATE_LANGUAGE_GERM}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_GERM)}
            name="language"
          />
          <RadioButton
            label="Arabic"
            checked={orderData.language === TEMPLATE_LANGUAGE_ARAB}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ARAB)}
            name="language"
          />
          <RadioButton
            label="Turkish"
            checked={orderData.language === TEMPLATE_LANGUAGE_TURK}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_TURK)}
            name="language"
          />
          <RadioButton
            label="Hebrew"
            checked={orderData.language === TEMPLATE_LANGUAGE_HEBR}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_HEBR)}
            name="language"
          />
          <RadioButton
            label="Dutch"
            checked={orderData.language === TEMPLATE_LANGUAGE_DUTCH}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_DUTCH)}
            name="language"
          />
          <RadioButton
            label="Russian"
            checked={orderData.language === TEMPLATE_LANGUAGE_RUSSIAN}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_RUSSIAN)}
            name="language"
          /> */}
        </Stack>
      </Stack>
    </Card>
  );

  const discountMarkup = (
    <Card>
      <Card.Section>
        <TextContainer>
          <DiscountMarkup
            enable_discount={orderData.discount}
            toggleDiscount={toggleDiscount}
            discount_percentage={orderData.discountRatio}
            handleDiscountChange={handleDiscountChange}
            coupon_code={orderData.couponCode}
            handleCouponChange={handleCouponChange}
            discount_type={orderData.discountType}
            handleDiscountTypeChange={handleDiscountTypeChange}
            validateFixedCouponCode={validateFixedCouponCode}
            validateDiscountRatio={validateDiscountRatio}
            shop={shop}
          />
        </TextContainer>
      </Card.Section>
    </Card>
  );

  const intervalMarkup = (
    <Card>
      <Card.Section>
        <Select
          label=""
          value={String(orderData.interval)}
          onChange={(val) => handleTemplateValueChange("interval", Number(val))}
          options={daysOptions}
          helpText={intervalHelpText}
          disabled={!enabled}
          placeholder={"Select interval..."}
        />
      </Card.Section>
    </Card>
  );

  const getTemplateMarkup = () => {
    return (
      <>
        <Layout.AnnotatedSection
          title="WhatsApp Message"
          description={description}
        >
          <Stack vertical>{languageMarkup}</Stack>
        </Layout.AnnotatedSection>

        {shop.platform === PLATFORM.SHOPIFY && (
          <Layout.AnnotatedSection
            title="Discount"
            description={discountDescription}
          >
            <Stack vertical>
              {discountMarkup}
              {nudgeCheckoutOptinScrollSection}
            </Stack>
          </Layout.AnnotatedSection>
        )}
        <Layout.AnnotatedSection
          title="Interval"
          description={intervalDescription}
        >
          <Stack vertical>
            {intervalMarkup}
            {nudgeCheckoutOptinScrollSection}
          </Stack>
        </Layout.AnnotatedSection>
        {isPrivateWABAPresent && (
          <NonOptinMessageSettings
            nonOptinMessage={nonOptinMessage.CROSS_SELL}
            handleChange={handleTemplateValueChange}
            nonOptinMessageEnabled={orderData.nonOptinEnabled}
          />
        )}
      </>
    );
  };
  const updateSettings = () => {
    setActivateErrorCheckForhandleDiscountChange(true);
    setActivateErrorCheckForCouponCode(true);
    if (validateFixedCouponCode(true) !== false) {
      let message = validateFixedCouponCode() || "Invalid coupon code";
      showErrorToast(message);
      return;
    }

    if (validateDiscountRatio(true) !== false) {
      let message = validateDiscountRatio() || "Invalid discount value";
      showErrorToast(message);
      return;
    }

    if (dataChanges.template) {
      let params = new URLSearchParams();
      let data: any = dataChanges.template;

      Object.keys(data).forEach((key) => {
        params.append(key, data[key]);
      });

      updateCrossSellTemplateSettings(shop.userId, params).then(
        (response: any) => {
          if (response.status === "success") {
            showToast("Data Saved");
            setDataChanges({ ...dataChanges, template: null });
            setInitialOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            setRefOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            setOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            setTemplateLanguage("ENGLISH"); // Forcing English as other languages are not supported
            setTemplateData(response.settings);
          } else {
            showErrorToast(response);
          }
        }
      );
    }
    setActivateErrorCheckForhandleDiscountChange(false);
    setActivateErrorCheckForCouponCode(false);
    closeModal();
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        resetData();
        closeModal();
        setOpenModal(false);
      }}
      title={"Cross Sell Template Settings"}
      primaryAction={{
        content: "Save",
        onAction: updateSettings,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            resetData();
            closeModal();
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>{getTemplateMarkup()}</Modal.Section>
    </Modal>
  );
};

export default SettingsModal;
