import { Link, Modal, Stack, TextContainer } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { clevertapEventPush, CT_EVENT_IDS } from "../utils/clevertapUtils";
import { useShopStore } from '../hooks/shopStore';

const GuideModal = ({ open, closeModal, obj, tabIndex = 0 }: any) => {
  const { shopDetailsData } = useShopStore();
  const [guideStep, setGuideStep] = useState(0);
  const [shop, setShop] = useState({ id: "", url: "" });
  const totalSteps = obj.modal.tabContent[tabIndex].content.length - 1;
  const currentTabTitle = obj.modal.tabContent[tabIndex].title;

  useEffect(() => {
    let shop = shopDetailsData;
    if (shop && shop.userId) {
      setShop(shop);
    }
    clevertapEventPush({
      eventId: CT_EVENT_IDS.GUIDE_STARTED,
      eventData: {
        guide_name: obj?.tooltip_card?.heading
          ? obj.tooltip_card.heading
          : obj?.modal?.tabContent[tabIndex]?.title
          ? obj.modal.tabContent[tabIndex].title
          : "Guide Modal",
      },
    });
  }, []);

  const modalContentGuideStep = () => {
    const currentStep = obj.modal.tabContent[tabIndex].content[guideStep];
    return (
      <TextContainer>
        {currentStep.text1 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `${currentStep.text1} `,
            }}
          ></div>
        ) : null}
        {currentStep.image_1 ? (
          <img
            className="checkoutOptinModalImage"
            src={currentStep.image_1.link}
            alt={
              currentStep.image_1.alt
                ? `${currentStep.image_1.alt}`
                : "Guide Image"
            }
          />
        ) : null}
        {currentStep.text2 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `${currentStep.text2} `,
            }}
          ></div>
        ) : null}
        {currentStep.image_2 ? (
          <img
            className="checkoutOptinModalImage"
            src={currentStep.image_2.link}
            alt={
              currentStep.image_2.alt
                ? `${currentStep.image_2.alt}`
                : "Guide Image"
            }
          />
        ) : null}
        {currentStep.text3 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `${currentStep.text3} `,
            }}
          ></div>
        ) : null}
        {currentStep.image_3 ? (
          <img
            className="checkoutOptinModalImage"
            src={currentStep.image_3.link}
            alt={
              currentStep.image_3.alt
                ? `${currentStep.image_3.alt}`
                : "Guide Image"
            }
          />
        ) : null}
        {currentStep.text4 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `${currentStep.text4}`,
            }}
          ></div>
        ) : null}
        {currentStep.image_4 ? (
          <img
            className="checkoutOptinModalImage"
            src={currentStep.image_4.link}
            alt={
              currentStep.image_4.alt
                ? `${currentStep.image_4.alt}`
                : "Guide Image"
            }
          />
        ) : null}
        {currentStep.text5 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `${currentStep.text5}`,
            }}
          ></div>
        ) : null}
        {currentStep.image_5 ? (
          <img
            className="checkoutOptinModalImage"
            src={currentStep.image_5.link}
            alt={
              currentStep.image_5.alt
                ? `${currentStep.image_5.alt}`
                : "Guide Image"
            }
          />
        ) : null}
        {currentStep.text6 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `${currentStep.text6} `,
            }}
          ></div>
        ) : null}
      </TextContainer>
    );
  };

  const incrementGuideStep = () => {
    setGuideStep((guideStep) => guideStep + 1);
  };

  const decrementGuideStep = () => {
    setGuideStep((guideStep) => guideStep - 1);
  };

  const stepsCompletedActions = () => {
    closeModal();
    clevertapEventPush({
      eventId: CT_EVENT_IDS.GUIDE_COMPLETED,
      eventData: {
        guide_name: obj?.tooltip_card?.heading
          ? obj.tooltip_card.heading
          : obj?.modal?.tabContent[tabIndex]?.title
          ? obj.modal.tabContent[tabIndex].title
          : "Guide Modal",
      },
    });
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        if (totalSteps !== guideStep) {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.GUIDE_CLOSED,
            eventData: { guide_name: obj.tooltip_card.heading },
          });
        } else {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.GUIDE_COMPLETED,
            eventData: {
              guide_name: obj?.tooltip_card?.heading
                ? obj.tooltip_card.heading
                : obj?.modal?.tabContent[tabIndex]?.title
                ? obj.modal.tabContent[tabIndex].title
                : "Guide Modal",
            },
          });
        }
        closeModal();
      }}
      title={
        totalSteps > 1
          ? `${currentTabTitle} ` +
            "(" +
            (guideStep + 1) +
            ` of ${totalSteps + 1})`
          : `${currentTabTitle} `
      }
      primaryAction={{
        content: guideStep === totalSteps ? "Guide Completed" : "Next",
        onAction:
          guideStep === totalSteps ? stepsCompletedActions : incrementGuideStep,
      }}
      secondaryActions={
        guideStep >= 1
          ? [
              {
                content: "Back",
                onAction: decrementGuideStep,
              },
            ]
          : undefined
      }
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>{modalContentGuideStep()}</Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
export default GuideModal;
