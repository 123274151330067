import { useEffect, useState } from "react";
import {
  Layout,
  TextContainer,
  TextStyle,
  Heading,
  Icon,
  Link,
} from "@shopify/polaris";
import { SettingsMinor } from "@shopify/polaris-icons";
import Switch from "react-switch";
import style from "../Automated/index.module.scss";

function TemplateView({
  children,
  data,
  openModal,
  open,
  apiRes,
  supportEmailEnabled,
  supportEmail,
  brandNameEnabled,
  languageTemplate,
  enabled,
  discount,
  toggleTemplate,
  discountType,
}: any) {
  return (
    <>
      {Object.keys(apiRes).length !== 0 && (
        <>
          <Layout.Section secondary>
            <div className="Polaris-TextContainer Polaris-TextContainer--spacingTight break-word alignSelfStart">
              <Heading>{data.heading}</Heading>
              <Switch
                onChange={() => toggleTemplate(!enabled)}
                checked={enabled}
                onColor="#5DC001"
                onHandleColor="#fff"
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 6px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
                handleDiameter={30}
              />
              <TextContainer spacing="loose">
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${data.helperText}`,
                  }}
                ></p>
                {/* <p>
                To learn about the cost of sending automated messages, view{" "}
                <Link
                  external={true}
                  url="https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0"
                >
                  {"pricing chart"}
                </Link>
              </p> */}
              </TextContainer>
            </div>
          </Layout.Section>
          <Layout.Section>
            <div className="Polaris-Card w-100 relative">
              <button
                className={
                  enabled
                    ? "Polaris-Button Polaris-Button--primary Polaris-Button--outline absolute r-0 mt-1 mr-1"
                    : "Polaris-Button Polaris-Button--outline Polaris-Button--disabled absolute r-0 mt-1 mr-1"
                }
                type="button"
                disabled={!enabled}
                onClick={openModal}
              >
                <span className="Polaris-Button__Content">
                  <span className="Polaris-Button__Text">
                    <div className="d-flex-center">
                      <div>
                        <Icon source={SettingsMinor} />
                      </div>
                      <div>&nbsp;Settings</div>
                    </div>
                  </span>
                </span>
              </button>
              <div className="Polaris-Card__Section break-word">
                <TextContainer>
                  <TextStyle>
                    <span>
                      <div className="mt-2">
                        <h1 className={`${style.heading}`}>Email Subject</h1>
                        <p className={`${style.inner_card} mt-1`}>
                          {brandNameEnabled && "{brandName} - "}
                          {
                            data.cardContent.language[languageTemplate]
                              .emailSubject
                          }
                        </p>
                      </div>
                      <div className="mt-2">
                        <h1 className={`${style.heading}`}>Email Body</h1>
                        <div className={`${style.inner_card} mt-1`}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: `${
                                discount
                                  ? discountType === "FIXED"
                                    ? data.cardContent.language[
                                        languageTemplate
                                      ].withFixedDiscount
                                    : data.cardContent.language[
                                        languageTemplate
                                      ].withDynamicDiscount
                                  : data.cardContent.language[languageTemplate]
                                      .withoutDiscount
                              } `,
                            }}
                          ></p>
                          {supportEmailEnabled && (
                            <p className="mt-2">
                              You can contact us at "{supportEmail}" for any
                              queries.
                            </p>
                          )}
                          <p className="mt-2">
                            Best regards,
                            <br />
                            Team {"{company name}"}
                          </p>
                        </div>
                      </div>
                      {}
                    </span>
                  </TextStyle>
                </TextContainer>
              </div>
            </div>
          </Layout.Section>
          {open && children}
        </>
      )}
    </>
  );
}

export default TemplateView;
