import { useEffect, useState } from 'react';
import {
  Card,
  Layout,
  Page,
  Toast,
  Stack,
  ContextualSaveBar,
  TextField,
  TextContainer,
} from '@shopify/polaris';
import SupportNumberMarkup from '../../common/SupportNumberMarkup';
import {
  AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW,
  PLATFORM,
  SHOP_FEATURES,
} from '../../constants/constants';
import {
  fetchPhoneSettings,
  updatePhoneSettings,
} from '../../redux/actions/crmOrders';
import {
  objectDifference,
  shopFeaturesFromStoreData,
} from '../../utils/helper';
import {
  getThresholdValue,
  setThresholdData,
} from '../../redux/actions/campaigns';
import { getBalance } from '../../redux/actions/shop';
import { clevertapEventPush, CT_EVENT_IDS } from '../../utils/clevertapUtils';
import { useShopStore } from '../../hooks/shopStore';

const AdminConfiguration = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [toast, setToast] = useState({ show: false, message: '' });
  const [errorToast, setErrorToast] = useState({ show: false, message: '' });
  const [hasChanges, setHasChanges] = useState(false);
  const [thresholdValue, setThresholdValue] = useState({ value: '75' });
  const [refThresholdValue, setRefThresholdValue] = useState({ value: '75' });
  const [dataChanges, setDataChanges] = useState<any>({
    phone: null,
    thresholdValue: null,
  });
  const [initialPhoneData, setInitialPhoneData] = useState({
    countryCode: '',
    phone: '',
  });
  const [phoneData, setPhoneData] = useState({
    countryCode: '',
    phone: '',
  });
  const [refPhoneData, setRefPhoneData] = useState({
    countryCode: '',
    phone: '',
  });
  const [usagePercentage, setUsagePercentage] = useState(0);

  let shop = shopDetailsData;

  const getAllData = () => {
    if (shop && shop.userId) {
      getPhoneData(shop.userId);
      getThresholdData(shop.userId);
      getTotalBalance(shop.userId);
    }
  };

  useEffect(() => {
    getAllData();
    clevertapEventPush({
      eventId: CT_EVENT_IDS.ADMIN_CONFIGURATIONS_PAGE_VIEWED,
      eventData: {},
    });
  }, []);

  useEffect(() => {
    var diffObj: any = objectDifference(refPhoneData, phoneData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        phone: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({ ...dataChanges, phone: null }));
    }
  }, [phoneData]);

  useEffect(() => {
    var diffObj: any = objectDifference(refThresholdValue, thresholdValue);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        thresholdValue: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        thresholdValue: null,
      }));
    }
  }, [thresholdValue]);

  useEffect(() => {
    if (dataChanges.phone || dataChanges.optin || dataChanges.thresholdValue) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [dataChanges]);

  const getPhoneData = (userId: string) => {
    fetchPhoneSettings(userId).then((res: any) => {
      if (res.status === 'success') {
        setInitialPhoneData(res.settings);
        setRefPhoneData(res.settings);
        setPhoneData(res.settings);
      }
    });
  };

  const getThresholdData = (userId: string) => {
    getThresholdValue(userId).then((res: any) => {
      if (res?.settings?.threshold) {
        setRefThresholdValue({
          ...thresholdValue,
          value: String(res.settings.threshold * 100),
        });
        setThresholdValue({
          ...thresholdValue,
          value: String(res.settings.threshold * 100),
        });
      } else if (res !== 204) {
        showErrorToast(res);
      }
    });
  };

  const validateSupportCountryCode = () => {
    var cc = /^(?:[1-9]\d{0,5})$/;
    if (!phoneData.countryCode || phoneData.countryCode.match(cc)) {
      return false;
    } else if (phoneData.countryCode && phoneData.countryCode[0] === '0') {
      return 'Cannot start with 0';
    } else {
      return 'Enter a valid country code';
    }
  };

  const validateSupportPhoneNo = () => {
    var phoneno = /^(?:[0-9]\d{0,14})$/;
    if (!phoneData.phone || phoneData.phone.match(phoneno)) {
      return false;
    } else {
      return 'Enter a valid phone number';
    }
  };

  const resetData = () => {
    getAllData();
    setDataChanges({
      phone: null,
      thresholdValue: null,
    });
    setThresholdValue(refThresholdValue);
    setRefPhoneData(initialPhoneData);
    setHasChanges(false);
  };

  const updateSettings = async () => {
    if (!phoneData.countryCode || validateSupportCountryCode()) {
      showErrorToast('A valid support country code must be given');
      return;
    }

    if (!phoneData.phone || validateSupportPhoneNo()) {
      showErrorToast('A valid support phone number must be given');
      return;
    }

    if (
      thresholdValue.value !== refThresholdValue.value &&
      (Number(thresholdValue.value) > 100 || Number(thresholdValue.value) < 1)
    ) {
      showErrorToast('Invalid marketing campaign monthly usage limit');
      return;
    }

    if (dataChanges.thresholdValue) {
      let params = new URLSearchParams();
      params.append('threshold', String(Number(thresholdValue.value) / 100));
      setThresholdData(shop.userId, params).then((response: any) => {
        if (response.status === 'success') {
          showToast('Data Saved');
          setRefThresholdValue(thresholdValue);
          setDataChanges({ ...dataChanges, thresholdValue: null });
        } else {
          showErrorToast(response);
        }
      });
    }

    if (dataChanges.phone !== null) {
      let params = new URLSearchParams();
      let data: any = dataChanges.phone;

      Object.keys(data).forEach((key) => {
        params.append(key, data[key]);
      });

      updatePhoneSettings(shop.userId, params).then((response: any) => {
        if (response.status === 'success') {
          showToast('Data Saved');
          clevertapEventPush({
            eventId: CT_EVENT_IDS.WHATSAPP_SUPPORT_NUMBER_ADDED,
            eventData: {
              'Country code': phoneData.countryCode,
            },
          });
          setDataChanges({ ...dataChanges, optin: null });
          setInitialPhoneData((data: any) => ({
            ...data,
            ...response.settings,
          }));
          setRefPhoneData((data: any) => ({
            ...data,
            ...response.settings,
          }));
          setPhoneData((data: any) => ({
            ...data,
            ...response.settings,
          }));
        } else {
          showErrorToast(response);
        }
      });
    }

    setHasChanges(false);
  };

  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message='Unsaved changes'
      saveAction={{
        content: 'Save Settings',
        onAction: updateSettings,
      }}
      discardAction={{
        onAction: () => {
          resetData();
        },
      }}
    />
  ) : null;

  const handlePhoneChange = (field: string, value: any) => {
    setPhoneData((data) => ({ ...data, [field]: value }));
  };

  const campaignDescription =
    'Please note that this configuration is to ensure \
  smooth functioning of automated messaging. The proportion of your monthly messaging usage limit \
  will be reserved for automated messages';
  const description = (
    <span>
      <p>
        {'SuperLemon includes your'}{' '}
        <a
          className='Polaris-Link Polaris-Link-Updated-Color'
          href={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}
          data-polaris-unstyled='true'
          target='_blank'
          rel='noopener noreferrer'
        >
          business name
        </a>{' '}
        and{' '}
        <a
          className='Polaris-Link Polaris-Link-Updated-Color'
          href={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}
          data-polaris-unstyled='true'
          target='_blank'
          rel='noopener noreferrer'
        >
          WhatsApp support number
        </a>{' '}
        {
          ' in every automated message, so that your customers can recognise you and contact you easily if they need help.'
        }
        <br />
        <br />
        Please note that Automated WhatsApp messages are sent from our official
        WhatsApp Business API approved{' '}
        <a
          className='Polaris-Link Polaris-Link-Updated-Color'
          href={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}
          data-polaris-unstyled='true'
          target='_blank'
          rel='noopener noreferrer'
        >
          phone number
        </a>{' '}
        using message templates approved by WhatsApp.
      </p>
    </span>
  );

  const getTotalBalance = (userId: any) => {
    getBalance(userId)
      .then((res: any) => {
        if (res.status === 'success') {
          let usage: any =
            100 * (res.message.balanceUsed / res.message.cappedAmount);
          setUsagePercentage(usage.toFixed(2).replace(/[.,]00$/, ''));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <Page title='Settings' divider>
      {toastMarkup}
      {errorToastMarkup}
      {ctxSaveBarMarkup}
      <Layout.AnnotatedSection
        title='WhatsApp Configuration'
        description={description}
      >
        <Stack vertical>
          <div className='mt-2'>
            <SupportNumberMarkup
              phoneNumber={phoneData.phone}
              countryCode={phoneData.countryCode}
              onCountryChange={(val: any) => {
                handlePhoneChange('countryCode', val);
              }}
              onNumberChange={(val: any) => {
                handlePhoneChange('phone', val);
              }}
            />
          </div>
        </Stack>
      </Layout.AnnotatedSection>

      {shop.platform == PLATFORM.SHOPIFY &&
        shopFeaturesFromStoreData(shop).includes(
          SHOP_FEATURES.CUSTOM_TEMPLATES
        ) && (
          <Layout.AnnotatedSection
            title='Marketing Campaign Usage Limit'
            description={campaignDescription}
          >
            <Stack vertical>
              <Card sectioned>
                <Stack vertical>
                  <TextField
                    label={
                      <TextContainer>
                        Marketing campaign monthly usage limit (in %)
                      </TextContainer>
                    }
                    value={thresholdValue.value}
                    maxLength={3}
                    max={100}
                    type={'number'}
                    onChange={(value) => {
                      setThresholdValue({ ...thresholdValue, value: value });
                    }}
                    error={
                      thresholdValue.value &&
                      (Number(thresholdValue.value) > 100 ||
                        Number(thresholdValue.value) < 1)
                        ? 'Invalid value'
                        : ''
                    }
                  />
                  <div
                    className='Polaris-Labelled__HelpText'
                    id='PolarisTextField21HelpText'
                  >
                    Note: Once the above mentioned usage limit is reached,
                    marketing campaigns would not be triggered and the remaining
                    usage will be reserved for automated messsaging.
                    {Number(usagePercentage) >= 50 && (
                      <>
                        To upgrade your monthly limit{' '}
                        <a href='/campaigns'>click here</a>{' '}
                      </>
                    )}
                  </div>
                </Stack>
              </Card>
            </Stack>
          </Layout.AnnotatedSection>
        )}
    </Page>
  );
};

export default AdminConfiguration;
