// @ts-nocheck
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Icon } from '@shopify/polaris';
import { ThumbsUpMajor, ThumbsDownMajor } from '@shopify/polaris-icons';
import Tour from 'reactour';
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from '../../../utils/clevertapUtils';

const PlanTour = (props: any) => {
  const location = useLocation();
  const [openStartTour, setOpenStartTour] = useState(true);
  const [openTour, setOpenTour] = useState(false);
  const [openEndTour, setOpenEndTour] = useState(false);
  const [skipTour, setSkipEndTour] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [tourStartSteps, setTourStartSteps] = useState<any>([
    { selector: '', content: () => {} },
  ]);
  const [tourSteps, setTourSteps] = useState<any>([
    { selector: '', content: () => {} },
  ]);
  const [tourEndSteps, setTourEndSteps] = useState<any>([
    { selector: '', content: () => {} },
  ]);

  useEffect(() => {
    if (props.planTourData.INTRO) {
      setTourStartSteps([
        {
          selector: '',
          content: () => (
            <div>
              <span className='productTourTitle'>
                {props.planTourData.INTRO.GREETING}
              </span>
              <p className='productTourDescription'>
                {props.planTourData.INTRO.DESCRIPTION}
              </p>

              <div className='navigationButton'>
                <div className='customButtonWhite'>
                  <Button onClick={() => handleSkipTour()}>
                    {props.planTourData.INTRO.BUTTONS[0].LABEL}
                  </Button>
                </div>
                <div className='customButtonGreen'>
                  <Button
                    primary
                    onClick={() =>
                      handleTourNextStep(
                        props.planTourData.INTRO.BUTTONS[1].ACTION
                      )
                    }
                  >
                    {props.planTourData.INTRO.BUTTONS[1].LABEL}
                  </Button>
                </div>
              </div>
            </div>
          ),
        },
      ]);
    }

    if (props.planTourData.STEPS) {
      let tmpData: any = [];
      props.planTourData.STEPS.forEach((step: any) => {
        let tmpStep = {
          selector: `#${step.SELECTOR} a`,
          content: () => (
            <div>
              <p className='productTourDescription'>{step.CONTENT}</p>

              <div className='navigationButton'>
                <div className='customButtonWhite'>
                  <Button
                    onClick={() =>
                      handleTourPrevStep(`${step.BUTTONS[0].ACTION}`)
                    }
                  >
                    {step.BUTTONS[0].LABEL}
                  </Button>
                </div>
                <div className='customButtonGreen'>
                  <Button
                    primary
                    onClick={() =>
                      handleTourNextStep(`${step.BUTTONS[1].ACTION}`)
                    }
                  >
                    {step.BUTTONS[1].LABEL}
                  </Button>
                </div>
              </div>
            </div>
          ),
        };
        tmpData.push(tmpStep);
      });
      setTourSteps(tmpData);
    }

    if (props.planTourData.END) {
      setTourEndSteps([
        {
          selector: '',
          content: () => (
            <div>
              <div className='productTourTitle textCenter'>
                {props.planTourData.END.GREETING}
              </div>
              <p className='productTourDescription'>
                {props.planTourData.END.DESCRIPTION}
              </p>
              <div className='userFeedback'>
                <span
                  className='cursorFeedback'
                  onClick={() => {
                    handleUserFeedback('Liked');
                  }}
                >
                  <Icon source={ThumbsUpMajor} color='base' />
                  {props.planTourData.END.YES}
                </span>
                <span
                  className='cursorFeedback'
                  onClick={() => {
                    handleUserFeedback('Disliked');
                  }}
                >
                  <Icon source={ThumbsDownMajor} color='base' />
                  {props.planTourData.END.NO}
                </span>
              </div>
            </div>
          ),
        },
      ]);
    }
  }, [props.planTourData]);

  useEffect(() => {
    if (openStartTour === false || skipTour) {
      let productTourViewed = JSON.parse(
        sessionStorage.getItem('productTourViewed') || '[]'
      );
      let pathName =
        location.pathname === '/'
          ? `/home_${props.planId}`
          : `${location.pathname}_${props.planId}`;
      if (!productTourViewed.includes(pathName)) {
        productTourViewed.push(pathName);
      }
      sessionStorage.setItem(
        'productTourViewed',
        JSON.stringify(productTourViewed)
      );
    }
  }, [openStartTour]);

  const closeStartTour = () => {
    setOpenStartTour(false);
    props.handleShowWABAMigrationModal();
  };

  const closeTour = () => {
    setOpenTour(false);
    props.handleShowWABAMigrationModal();
    // CLEVERTAP EVENT
    clevertapEventPush({
      eventId: CT_EVENT_IDS.RODUCT_TOUR_SKIPPED,
      eventData: {
        'Tour Name': props.planTourData.NAME,
        Step: props.planTourData.STEPS[currentStep].SELECTOR,
      },
    });
  };

  const closeEndTour = () => {
    setOpenEndTour(false);
    props.handleShowWABAMigrationModal();
  };

  const handleSkipTour = () => {
    setOpenStartTour(false);
    setSkipEndTour(true);
    props.handleShowWABAMigrationModal();

    // CLEVERTAP EVENT
    clevertapEventPush({
      eventId: CT_EVENT_IDS.RODUCT_TOUR_SKIPPED,
      eventData: {
        'Tour Name': props.planTourData.NAME,
      },
    });
  };

  const handleTourPrevStep = (wStatus: string) => {
    if (wStatus === props.planTourData.INTRO.BUTTONS[1].ACTION) {
      setOpenStartTour(true);
      setOpenTour(false);
    } else {
      setCurrentStep((prev) => {
        let prevStep = prev > 0 ? prev - 1 : 0;
        return prevStep;
      });
    }
  };

  const handleTourNextStep = (wStatus: string) => {
    if (wStatus === props.planTourData.INTRO.BUTTONS[1].ACTION) {
      closeStartTour();
      setOpenTour(true);

      // CLEVERTAP EVENT
      clevertapEventPush({
        eventId: CT_EVENT_IDS.RODUCT_TOUR_STARTED,
        eventData: {
          'Tour Name': props.planTourData.NAME,
        },
      });
    } else if (wStatus === props.planTourData.END.BUTTONS[0].ACTION) {
      setOpenTour(false);
      setOpenEndTour(true);

      // CLEVERTAP EVENT
      clevertapEventPush({
        eventId: CT_EVENT_IDS.RODUCT_TOUR_COMPLETED,
        eventData: {
          'Tour Name': props.planTourData.NAME,
        },
      });
    } else {
      setCurrentStep((prev) => {
        let nextStep =
          prev < props.planTourData.STEPS.length - 1 ? prev + 1 : prev;
        return nextStep;
      });
    }
  };

  const handleUserFeedback = (feedback: string) => {
    setOpenEndTour(false);

    // CLEVERTAP EVENT
    clevertapEventPush({
      eventId: CT_EVENT_IDS.RODUCT_TOUR_FEEDBACK,
      eventData: {
        'Tour Name': props.planTourData.NAME,
        Feedback: feedback,
      },
    });
  };

  const productTourStart = () => {
    return (
      <Tour
        steps={tourStartSteps}
        isOpen={openStartTour}
        onRequestClose={closeStartTour}
        className='reactour-helper-start'
        showNumber={false}
        showNavigation={false}
        showButtons={false}
        showCloseButton={true}
      />
    );
  };

  const productTourView = () => {
    return (
      <Tour
        steps={tourSteps}
        isOpen={openTour}
        onRequestClose={closeTour}
        disableInteraction
        className='reactour-helper'
        rounded={6}
        showNumber={false}
        showNavigation={false}
        showButtons={false}
        showCloseButton={true}
        goToStep={currentStep}
        nextStep={() => handleTourNextStep}
        prevStep={() => handleTourPrevStep}
      >
        <span className='tourStepsCount'>{`${currentStep + 1}/${
          tourSteps.length
        }`}</span>
      </Tour>
    );
  };

  const productTourEnd = () => {
    return (
      <Tour
        steps={tourEndSteps}
        isOpen={openEndTour}
        onRequestClose={closeEndTour}
        disableInteraction
        className='reactour-helper-end'
        showNumber={false}
        showNavigation={false}
        showButtons={false}
        showCloseButton={true}
        nextStep={() => handleTourNextStep}
        prevStep={() => handleTourPrevStep}
      />
    );
  };

  return (
    <>
      {openStartTour && productTourStart()}
      {openTour && productTourView()}
      {openEndTour && productTourEnd()}
    </>
  );
};

export default PlanTour;
