import { Modal, Stack, TextContainer } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useShopStore } from '../../hooks/shopStore';

const MarketingGuideModal = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [guideStep, setGuideStep] = useState(0);
  const [shop, setShop] = useState({ id: '', url: '' });
  const history = useHistory();

  useEffect(() => {
    let shop = shopDetailsData;
    if (shop && shop.id) {
      setShop(shop);
    }
  }, []);

  useEffect(() => {
    props.setGuideStep(guideStep);
  }, [guideStep]);

  const modalContentGuideStep = () => {
    switch (guideStep) {
      case 0:
        return (
          <TextContainer>
            <b>What is a WhatsApp marketing campaign?</b>
            <p>
              WhatsApp marketing campaigns are one of the most popular marketing
              strategies that allow sending of promotional messages, product
              releases, newsletters & much more to users via WhatsApp in bulk.
              It’s a hassle-free way of delivering business updates to customers
              on a day-to-day basis.
            </p>
            <p>
              E.g. product-related insights, latest arrivals, delivery updates,
              newsletter subscriptions, and more.
            </p>
          </TextContainer>
        );

      case 1:
        return (
          <span>
            <TextContainer>
              <p>
                You can view previously created campaigns and create new ones in
                the “Campaigns” section in the left navigation drawer.
              </p>
              <br></br>
              <div style={{ width: '100%', height: '100%' }}>
                <img
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                  }}
                  className=''
                  src='https://cdn.shopify.com/s/files/1/0683/1371/0892/files/image5_a000036a-9d36-4d99-b39f-7afe1b70774a.png?v=1691494627'
                  alt=''
                />
              </div>
            </TextContainer>
          </span>
        );

      case 2:
        return (
          <TextContainer>
            <p>
              On the “Marketing Campaigns” page click the “Create New Campaign”
              button to create a new campaign.
            </p>
            <div style={{ width: '100%', height: '100%' }}>
              <img
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                }}
                src='https://cdn.shopify.com/s/files/1/0683/1371/0892/files/Frame_26_1.png?v=1692132467'
                alt='Checkout Page Checkbox'
              />
            </div>
            <p>
              Enter the “Campaign Name” and click the “Select Template” button
            </p>
            <img
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                width: '100%',
                height: '100%',
              }}
              src='https://cdn.shopify.com/s/files/1/0683/1371/0892/files/Frame_49.png?v=1692170588'
              alt='Checkout Page Checkbox'
            />
            <p>
              Choose an approved template{' '}
              <span
                onClick={() => {
                  history.push({
                    pathname: '/whatsapp-templates',
                    state: { tab: 'ct' },
                  });
                  stepsCompletedActions();
                }}
                style={{ color: 'blue', cursor: 'pointer' }}
              >
                (You can create a new template here)
              </span>
            </p>
            <div style={{ width: '100%', height: '100%' }}>
              <img
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                }}
                src='https://cdn.shopify.com/s/files/1/0683/1371/0892/files/image3.png?v=1691471407'
                alt=''
              />
            </div>
          </TextContainer>
        );

      case 3:
        return (
          <TextContainer>
            <p>
              You can use the file upload method to fill in the phone numbers of
              your customers via an Excel sheet or csv file. Or you can also
              select customers list by selecting customer groups.
            </p>
            <p style={{ fontWeight: '600' }}>A. Via file upload method:</p>
            <p>
              You can download the sample sheet by clicking on MS Excel or CSV
              link.
            </p>
            <div style={{ width: '100%', height: '100%' }}>
              <img
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                }}
                src='https://cdn.shopify.com/s/files/1/0683/1371/0892/files/Frame_7_1.png?v=1692133063'
                alt=''
              />
            </div>
            <p>
              In the sheet you’ll need to enter the customer phone number & the
              variable details that are present in template. Once you've filled
              in all the data in the sheet, you can upload the file and submit
              the campaign.
            </p>
            <p style={{ fontWeight: '600' }}>B. Via customer group:</p>
            <p>
              You can also run a marketing campaign using customer segments,
              which has its own benefits. You’ll avoid the hassle of filling
              excel/csv files if you decide to run a campaign via customer
              group. As Superlemon has already integrated with Shopify. All the
              customer data is already Present with Supetrlemon. You can simply
              select the customer group & send the campaign.
            </p>

            <div style={{ width: '100%', height: '100%' }}>
              <img
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                }}
                src='https://cdn.shopify.com/s/files/1/0683/1371/0892/files/Frame_8_1.png?v=1692133135'
                alt=''
              />
            </div>
            <p>
              To learn more about how to create customer groups{' '}
              <span
                onClick={() => {
                  window.open(
                    'https://www.superlemon.xyz/help-center/campaigns/28/',
                    '_blank'
                  );
                }}
                style={{ color: 'blue', cursor: 'default' }}
              >
                {' click here.'}
              </span>
            </p>
            <p>
              Please note that the customer group method can only be used if the
              selected templat only has standard variable. if the selected
              temple has a custom variable, campaign should be run via file
              upload method.{' '}
            </p>
          </TextContainer>
        );

      case 4:
        return (
          <span>
            <TextContainer>
              <p>
                To measure the success of your campaign, you need to track and
                analyze your campaign metrics. This may include metrics such as
                message delivery rates, open rates, click-through rates, and
                conversion rates. Simply click on the “Download Report” button
                to view the report.
              </p>
              <div style={{ width: '100%', height: '100%' }}>
                <img
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                  }}
                  src='https://cdn.shopify.com/s/files/1/0683/1371/0892/files/Frame_45.png?v=1692133269'
                  alt=''
                />
              </div>
            </TextContainer>
          </span>
        );
    }
  };

  const incrementGuideStep = () => {
    setGuideStep(guideStep + 1);
  };

  const decrementGuideStep = () => {
    setGuideStep(guideStep - 1);
  };

  const stepsCompletedActions = () => {
    props.stepsCompleted();
    props.closeModal();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
      title={'Marketing Campaign'}
      primaryAction={{
        content: guideStep === 4 ? 'Guide Completed' : 'Next step',
        onAction: guideStep === 4 ? stepsCompletedActions : incrementGuideStep,
      }}
      secondaryActions={
        guideStep >= 1
          ? [
              {
                content: 'Back',
                onAction: decrementGuideStep,
              },
            ]
          : undefined
      }
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>{modalContentGuideStep()}</Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
export default MarketingGuideModal;
