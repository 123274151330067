import "./feature.scss";
import React from "react";
import { useHistory } from "react-router-dom";
const Feature = (props: any) => {
  const history = useHistory();
  const onClick = (props: any) => {
    history.push({
      pathname: props.path,
      state: { tab: props.tab },
    });
  };
  return (
    <div className="featureContainer">
      <div className="paddingContainer">
        <img src={props.icon} alt="profile" width="60px" height="60px" />
        <div className="heading">{props.heading}</div>
        <div className="subheading">{props.content}</div>
        <div className="buttonContainer">
          <button
            onClick={() => {
              onClick(props);
            }}
            className="featureButton"
          >
            {props.buttonText || "Explore now"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Feature;
