import { CUSTOM_TEMPLATES } from "../../constants/api-constants";
import { CAMPAIGNS_TEMPLATES_PAGE } from "../../constants/constants";
import httpClient from "../../utils/http-client";

export const getCustomTemplates = (userId: string) => {
  return httpClient.get(CUSTOM_TEMPLATES.replace("$userId", userId));
};

export const createCustomTemplates = (userId: string, params: any) => {
  return httpClient.post(
    `${CUSTOM_TEMPLATES.replace("$userId", userId)}/create`,
    params
  );
};

export const addCustomTemplateMedia = (userId: string, params: any) => {
  return httpClient.post(
    `${CUSTOM_TEMPLATES.replace("$userId", userId)}/upload`,
    params
  );
};

export const deleteCustomTemplates = (userId: string, templateId: string) => {
  return httpClient.delete(
    `${CUSTOM_TEMPLATES.replace("$userId", userId)}/${templateId}`
  );
};
