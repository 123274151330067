import { Page, Modal, Link } from "@shopify/polaris";
import "./approvalModal.scss";
import { updatePlan, updateProPlan } from "../../redux/actions/pricing";
import { PLAN_NEW_FREE, PLAN_OLD_PRO } from "../../constants/constants";
import { sendCleverTapEvent_planUpdate } from "../../common/helperFunctions";
import { useShopStore } from '../../hooks/shopStore';

const ApprovalModal = (props: any) => {
  const { shopDetailsData } = useShopStore();
  let shop = shopDetailsData;

  const activatePlan = (planId: number) => {
    let params = {
      from: "pricing",
      returnUrl: window.location.origin + "/confirmation",
    };
    updatePlan(shop.userId, planId.toString(), params).then(
      async (res: any) => {
        if (res.status === "success" || res === 202) {
          sendCleverTapEvent_planUpdate(planId, shop);
          if (res.charge && res.charge.confirmationUrl) {
            window.open(res.charge.confirmationUrl, "_self");
          } else {
            props.setShowModal(false);
          }
        }
      },
      (err) => {
        //setIsLoading(false);
      }
    );
  };

  const subscribeToFreePlan = () => {
    activatePlan(PLAN_NEW_FREE);
  };

  const subscribeToProPlanUpgraded = () => {
    let params = {
      from: "pricing",
      returnUrl: window.location.origin + "/confirmation",
    };
    updateProPlan(shop.userId, params).then(
      async (res: any) => {
        if (res.status === "success" || res === 202) {
          sendCleverTapEvent_planUpdate(PLAN_OLD_PRO, shop);
          if (res.charge && res.charge.confirmationUrl) {
            window.open(res.charge.confirmationUrl, "_self");
          } else {
            props.setShowModal(false);
          }
        }
      },
      (err) => {
        //setIsLoading(false);
      }
    );
  };

  return (
    <Page title="">
      <Modal
        open={props.showModal}
        sectioned={true}
        title={""}
        onClose={() => {
          props.setShowModal(false);
        }}
      >
        <Modal.Section>
          <div className="basic-approval-modal">
            <div className="approvalModalHeading">
              Shopify Approval Required
            </div>

            { props.feature === "Whatsapp Business API" ?
              <div className="approvalModalContent">
                To create {props.feature}, Shopify approval is required. Please
                note that after enabling {props.feature}, you will be able to send Automated 
                messages & Campaign messages over WhatsApp. You will be charged
                monthly based on the consumption of Whatsapp Messages.
              </div>
            :
              <div className="approvalModalContent">
                To enable {props.feature}, Shopify approval is required. Please
                note that after enabling {props.feature}, you will be charged
                monthly based on the consumption of Whatsapp Messages.
              </div>
            }
            <button
              className="approvalModalButtonCss"
              type="button"
              onClick={() => {
                (Number(shop.planId) === PLAN_OLD_PRO) ? 
                  subscribeToProPlanUpgraded() :
                  subscribeToFreePlan();
              }}
            >
              Proceed to Approval
            </button>
          </div>
          <div className="approvalModalFooter">
            To learn more about pricing{" "}
            <Link
              external={true}
              url="https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0"
            >
              {" Click here"}
            </Link>
          </div>
        </Modal.Section>
      </Modal>
    </Page>
  );
};

export default ApprovalModal;
