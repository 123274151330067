import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Page, Toast } from "@shopify/polaris";
import PlanChangeComponent from "./EnablePlans/PlanChangeComponent";
import FeatureList from "./FeatureList";
import {
  NEW_FREE_PLAN_FEATURE_LIST,
  SMALL_BUSINESS_PLAN_LIST,
  OLD_FREE_PLAN_FEATURE_LIST,
  OLD_PRO_PLAN_FEATURE_LIST,
  OLD_ULTIMATE_PLAN_FEATURE_LIST,
  NEW_USER_LIST,
  ENTERPRISE_PLAN_LIST,
  ENGAGE_WIX_FEATURE_LIST,
  WIX_OLD_FREE_PLAN_FEATURE_LIST,
  ENGAGE_PLUS_FEATURE_LIST,
} from "../../constants/Pricing/Features/constants";
import WabaConfiguration from "./WabaConfiguration";
import PlanStatus from "./PlanStatus";
import {
  PLAN_OLD_FREE,
  PLAN_NEW_FREE,
  PLAN_OLD_PRO,
  PLAN_OLD_ULTIMATE,
  PLAN_SMALL_BUSINESS,
  PLAN_ENTERPRISE,
  REACT_TOUR_STEPS,
  PLATFORM,
  PLAN_ENGAGE_WIX,
  PLAN_ENGAGE_PLUS,
  PLANS_MODAL_TITLE,
  PRIVATE_WABA_MIGRATION_NOTE,
} from "../../constants/constants";
import SetupWarnCard from "./SetupWarnCard/SetupWarnCard";
import PlanTour from "./ProductTour/PlanTour";
import ReleaseNotesModal from "../Modals/ReleaseNotesModal/ReleaseNotesModal";
import PlansModal from "../Modals/PlansModal";
import { updatePlan } from "../../redux/actions/pricing";
import { clevertapEventPush, CT_EVENT_IDS } from "../../utils/clevertapUtils";
import { sendCleverTapEvent_planUpdate } from "../../common/helperFunctions";
import {
  releaseNotesToBeShowned,
  privateWabaPresent,
} from "../../utils/common";
import { planDiffInfo } from "../../utils/helper";
import { useShopStore } from "../../hooks/shopStore";

const HomePage = (props: any) => {
  const history = useHistory();
  const {
    shopDetailsData,
    shopWabaDetails,
    shopPrivateWabaDetails,
    shopOwnerDetails,
  } = useShopStore();
  const planId = parseInt(shopDetailsData.planId);
  const shop = shopDetailsData;
  const owner = shopOwnerDetails;
  const [isWABAEnabled, setIsWABAEnabled] = useState(false);
  const [wabaNumber, setWABANumber] = useState("");
  const [isPlanStatusVisible, setIsPlanStatusVisible] = useState(false);
  const NEW_USER = 0;
  const WIX_OLD_FREE = 8;
  const planMapping = {
    [PLAN_OLD_FREE]: OLD_FREE_PLAN_FEATURE_LIST,
    [PLAN_OLD_PRO]: OLD_PRO_PLAN_FEATURE_LIST,
    [PLAN_OLD_ULTIMATE]: OLD_ULTIMATE_PLAN_FEATURE_LIST,
    [PLAN_NEW_FREE]: NEW_FREE_PLAN_FEATURE_LIST,
    [PLAN_SMALL_BUSINESS]: SMALL_BUSINESS_PLAN_LIST,
    [PLAN_ENTERPRISE]: ENTERPRISE_PLAN_LIST,
    [NEW_USER]: NEW_USER_LIST,
    [PLAN_ENGAGE_WIX]: ENGAGE_WIX_FEATURE_LIST,
    [WIX_OLD_FREE]: WIX_OLD_FREE_PLAN_FEATURE_LIST,
    [PLAN_ENGAGE_PLUS]: ENGAGE_PLUS_FEATURE_LIST,
  };

  const location = useLocation();
  const currentPlanId = planId;
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const queryParam = new URLSearchParams(location.search);
  const queryParamPlanId = queryParam.get("id");
  const [planTourData, setPlanTourData] = useState({});
  const [homePageTourShown, setHomePageTourShown] = useState(false);
  const [showReleaseNotes, setShowReleaseNotes] = useState(false);
  const [releaseNotesData, setReleaseNotesData] = useState({});
  const [showPricingPlansModal, setShowPricingPlansModal] = useState(false);

  useEffect(() => {
    if (queryParam.has("id")) {
      let planId: string = queryParam.get("id") || "";
      setToast({ show: true, message: "Plan Activated Successfully" });
      queryParam.delete("id");
      history.replace({
        search: queryParam.toString(),
      });
      history.push("/home");
      window.location.reload();
    }
  }, [queryParamPlanId]);

  useEffect(() => {
    setIsPlanStatusVisible(
      JSON.parse(localStorage.getItem("planStatus_clicked") || "{}") === true
        ? false
        : true
    );
    if (shop?.newUser && planId === PLAN_OLD_FREE) {
      setIsPlanStatusVisible(false);
    }

    if (
      shopWabaDetails &&
      shopWabaDetails.appId &&
      shopWabaDetails.whatsappVerificationStatus
    ) {
      setIsWABAEnabled(true);
      setWABANumber(shopWabaDetails?.number || shopWabaDetails?.phone);
    } else if (
      shopPrivateWabaDetails &&
      shopPrivateWabaDetails.appId &&
      shopPrivateWabaDetails.whatsappVerificationStatus
    ) {
      setIsWABAEnabled(true);
    }
  }, []);

  useEffect(() => {
    if (PLAN_OLD_FREE === planId && shop?.newUser) {
      setShowPricingPlansModal(true);
    } else if (
      shop &&
      releaseNotesToBeShowned(shop) &&
      !privateWabaPresent(shopWabaDetails, shopPrivateWabaDetails)
    ) {
      // Show private waba migration note for new free plan
      if ([PLAN_NEW_FREE].includes(planId)) {
        setReleaseNotesData(PRIVATE_WABA_MIGRATION_NOTE.FREE);
        setShowReleaseNotes(true);
      }        

      // Show private waba migration note for new free plan
      if (
        [
          PLAN_ENTERPRISE,
          PLAN_OLD_ULTIMATE,
          PLAN_SMALL_BUSINESS,
          PLAN_ENGAGE_PLUS,
          PLAN_ENGAGE_WIX,
          PLAN_OLD_PRO,
        ].includes(planId)
      ) {
        setReleaseNotesData(PRIVATE_WABA_MIGRATION_NOTE.PAID);
        setShowReleaseNotes(true);
      }
    } else {
      if (planId === PLAN_NEW_FREE)
        setPlanTourData(REACT_TOUR_STEPS.PLAN_NEW_FREE);
      if (planId === PLAN_SMALL_BUSINESS) {
        let pdTour = planDiffInfo(shop).productTour;
        if (pdTour) {
          setPlanTourData(pdTour);
        }
      }
      if ([PLAN_ENTERPRISE, PLAN_ENGAGE_WIX].includes(planId))
        setPlanTourData(REACT_TOUR_STEPS.PLAN_ENGAGE);
      if (planId === PLAN_ENGAGE_PLUS)
        setPlanTourData(REACT_TOUR_STEPS.PLAN_ENGAGE_PLUS);
      let productTourViewed = JSON.parse(
        sessionStorage.getItem("productTourViewed") || "[]"
      );
      let pathName =
        location.pathname === "/"
          ? `/home_${planId}`
          : `${location.pathname}_${planId}`;
      // if (!productTourViewed.includes(pathName)) setHomePageTourShown(true);

      // Remove release notes and show private waba migration note
      if ([PLAN_NEW_FREE].includes(planId))
        setReleaseNotesData(PRIVATE_WABA_MIGRATION_NOTE.FREE);

      if (
        [
          PLAN_ENTERPRISE,
          PLAN_OLD_ULTIMATE,
          PLAN_SMALL_BUSINESS,
          PLAN_ENGAGE_PLUS,
          PLAN_ENGAGE_WIX,
          PLAN_OLD_PRO,
        ].includes(planId)
      )
        setReleaseNotesData(PRIVATE_WABA_MIGRATION_NOTE.PAID);

      if (!productTourViewed.includes(pathName)) {
        setHomePageTourShown(true);
      } else if (!privateWabaPresent(shopWabaDetails, shopPrivateWabaDetails)) {
        // If no private waba setup then show modal for private waba
        setShowReleaseNotes(true);
      }
    }
  }, [planId]);

  const handleUpdatePlan = (planId: number, page: any) => {
    if (Number(currentPlanId) === planId) return null;
    clevertapEventPush({
      eventId: CT_EVENT_IDS.SUBSCRIBE_CLICKED,
      eventData: {
        "Upgrading to": planId,
        page: page,
      },
    });
    activatePlan(planId);
  };

  const handleShowWABAMigrationModal = () => {
    if (!privateWabaPresent(shopWabaDetails, shopPrivateWabaDetails)) setShowReleaseNotes(true);
  }

  const activatePlan = (planId: number) => {
    let params = {
      from: "pricing",
      returnUrl: window.location.origin + "/confirmation",
    };
    updatePlan(shop.userId, planId.toString(), params).then(
      async (res: any) => {
        if (res.status === "success" || res === 202) {
          sendCleverTapEvent_planUpdate(planId, shop);
          if (res.charge && res.charge.confirmationUrl) {
            window.open(res.charge.confirmationUrl, "_self");
          } else {
            setToast({ show: true, message: "Plan Activated Successfully" });
          }
        } else {
          setErrorToast({ show: true, message: res });
        }
      },
      () => {}
    );
  };

  const toggleToast = (message: string) => {
    setToast({
      show: !toast.show,
      message: message,
    });
  };

  const toggleErrorToast = (message: string) => {
    setErrorToast({
      show: !errorToast.show,
      message: message,
    });
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        toggleToast("");
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        toggleErrorToast("");
      }}
    />
  ) : null;

  const checkNewUser = () => {
    if (
      Number(shop.createdOn) >
      Number(process.env.REACT_APP_PLAN_NEW_USER_START_DATE)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getFeatureList = () => {
    let tempId = planId;
    if (shop.newUser && planId === PLAN_OLD_FREE) {
      tempId = NEW_USER;
    }
    if (
      [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform) &&
      planId === PLAN_OLD_FREE
    ) {
      tempId = WIX_OLD_FREE;
    }
    return planMapping[tempId];
  };

  return (
    <>
      {/* User Need to choose a plan when on PLAN_OLD_FREE when comes on home page 
       releaseNotes should be shown before select plan modal  */}
      {/* {PLAN_OLD_FREE === parseInt(planId) && releaseNotesSeen() && ( */}
      <PlansModal
        title={PLANS_MODAL_TITLE.HOME_PAGE}
        currentPlanId={planId}
        shop={shop}
        showPricingPlansModal={showPricingPlansModal}
        setShowPricingPlansModal={setShowPricingPlansModal}
      />
      {/* )} */}
      {![PLAN_OLD_FREE, PLAN_OLD_PRO, PLAN_OLD_ULTIMATE].includes(planId) &&
        homePageTourShown &&
        !!Object.keys(planTourData).length &&
        !!planId &&
        shop && (
          <PlanTour
            planTourData={planTourData}
            shopId={shop}
            planId={planId}
            handleShowWABAMigrationModal={handleShowWABAMigrationModal}
          />
        )}
      {showReleaseNotes && (
        <ReleaseNotesModal
          data={releaseNotesData}
          showReleaseNotes={showReleaseNotes}
          setShowReleaseNotes={setShowReleaseNotes}
        />
      )}

      <Page title={`Hello, ${owner?.name} !`}>
        {toastMarkup}
        {errorToastMarkup}
        {isPlanStatusVisible && !!planId && (
          <PlanStatus
            planId={
              // Show diff tile for Growth/Engage
              planDiffInfo(shop).tile ? planDiffInfo(shop).tile : planId
            }
            setIsPlanStatusVisible={setIsPlanStatusVisible}
          />
        )}
        {[PLAN_OLD_FREE].includes(planId) &&
          shop.platform === PLATFORM.SHOPIFY && (
            <SetupWarnCard
              title={
                <div className="mainTitle">
                  Update to the <p className="green">New FREE Plan</p>
                </div>
              }
              subtitle={
                "Get additional features without paying an extra penny."
              }
              description={
                <p>
                  Hello Customer, you are currently using the older version of
                  Superlemon Free plan which doesn't have the new features
                  available in the New Free Plan ($0 subscription plan +
                  <a
                    href="https://www.superlemon.xyz/help-center/pricing-chart/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    usage based charge
                  </a>
                  ). We would recommend you to upgrade to the New Free Plan and
                  make the full use of the Superlemon plugin to engage with your
                  customers via WhatsApp. Learn more about usage based charge{" "}
                  <a
                    href="https://www.superlemon.xyz/help-center/pricing-chart/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    here
                  </a>
                </p>
              }
              onSubmit={() => {
                handleUpdatePlan(PLAN_NEW_FREE, "Homepage");
              }}
              btnText={"Update Plan"}
            />
          )}
        {[
          PLAN_NEW_FREE,
          PLAN_SMALL_BUSINESS,
          PLAN_ENTERPRISE,
          PLAN_ENGAGE_WIX,
          PLAN_ENGAGE_PLUS,
          PLAN_OLD_ULTIMATE,
          PLAN_OLD_PRO,
        ].includes(planId) && (
          <WabaConfiguration
            isWABAEnabled={isWABAEnabled}
            wabaNumber={wabaNumber}
            privateWabaDetails={shopWabaDetails}
          />
        )}
        {shop.platform === PLATFORM.SHOPIFY && <PlanChangeComponent />}
        <FeatureList plan={getFeatureList()} />
      </Page>
    </>
  );
};

export default HomePage;
