import { useEffect, useState } from "react";
import TemplateModal from "./TemplateModal";
import TemplateView from "./TemplateView";
import { data } from "./data";
import { Layout, Page, Toast } from "@shopify/polaris";
import {
  getConfigurationSettings,
  getAbCartSettings,
  getCodSettings,
  updateAbCartSettings,
  updateCodSettings,
  updateOrderConfirmationSettings,
  updateOrderShipmentSettings,
  getOrderConfirmationSettings,
  getOrderShipmentSettings,
} from "../../../../redux/actions/email";

export default function AutomatedTemplates({ userId, shopUrl }: any) {
  const [openAbCartModal, setopenAbCartModal] = useState(false);
  const [openCodModal, setopenCodModal] = useState(false);
  const [openOrderShipmentModal, setopenOrderShipmentModal] = useState(false);
  const [openOrderConfirmationModal, setopenOrderConfirmationModal] =
    useState(false);
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [supportEmailData, setsupportEmailData] = useState({
    supportMailEnabled: false,
    email: "",
  });
  const [brandNameEnabled, setbrandNameEnabled] = useState({
    brandNameEnabled: false,
  });
  const [abCartData, setAbCartData] = useState<any>({});
  const [codData, setCodData] = useState<any>({});
  const [orderConfirmationData, setOrderConfirmationData] = useState<any>({});
  const [orderShipmentData, setOrderShipmentData] = useState<any>({});

  useEffect(() => {
    getConfigurationData(userId);
    getAbCartData(userId);
    getCodData(userId);
    getOrderConfirmationData(userId);
    getOrderShipmentData(userId);
  }, []);

  const getConfigurationData = (userId: string) => {
    getConfigurationSettings(userId).then((res: any) => {
      if (res.status === "success") {
        const supportEmailSettings = {
          supportMailEnabled: res.supportMailEnabled,
          email: res.email,
        };
        setsupportEmailData(supportEmailSettings);
        setbrandNameEnabled(res.brandNameEnabled);
      }
    });
  };
  const getAbCartData = (userId: string) => {
    getAbCartSettings(userId).then((res: any) => {
      if (res.status === "success") {
        setAbCartData(res.settings);
      }
    });
  };
  const getCodData = (userId: string) => {
    getCodSettings(userId).then((res: any) => {
      if (res.status === "success") {
        setCodData(res.settings);
      }
    });
  };
  const getOrderConfirmationData = (userId: string) => {
    getOrderConfirmationSettings(userId).then((res: any) => {
      if (res.status === "success") {
        setOrderConfirmationData(res.settings);
      }
    });
  };
  const getOrderShipmentData = (userId: string) => {
    getOrderShipmentSettings(userId).then((res: any) => {
      if (res.status === "success") {
        setOrderShipmentData(res.settings);
      }
    });
  };
  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };
  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;
  const enableAbandonedCart = (enabled: boolean) => {
    var params: any = new URLSearchParams();
    params.append("enabled", enabled);
    updateAbCartSettings(userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          setAbCartData(response.settings);
          showToast(
            "Abandoned Cart Recovery email template has been " +
              (enabled ? "ENABLED" : "DISABLED")
          );
        } else {
          showErrorToast(response);
        }
      },
      (err: any) => {
        showErrorToast("Something went wrong");
      }
    );
  };
  const enableCodTemplate = (enabled: boolean) => {
    var params: any = new URLSearchParams();
    params.append("enabled", enabled);
    updateCodSettings(userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          setCodData(response.settings);
          showToast(
            "COD email template has been " + (enabled ? "ENABLED" : "DISABLED")
          );
        } else {
          showErrorToast(response);
        }
      },
      (err: any) => {
        showErrorToast("Something went wrong");
      }
    );
  };
  const enableOrderConfirmationTemplate = (enabled: boolean) => {
    var params: any = new URLSearchParams();
    params.append("enabled", enabled);
    updateOrderConfirmationSettings(userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          setOrderConfirmationData(response.settings);
          showToast(
            "Order confirmation email template has been " +
              (enabled ? "ENABLED" : "DISABLED")
          );
        } else {
          showErrorToast(response);
        }
      },
      (err: any) => {
        showErrorToast("Something went wrong");
      }
    );
  };
  const enableOrderShipmentTemplate = (enabled: boolean) => {
    var params: any = new URLSearchParams();
    params.append("enabled", enabled);
    updateOrderShipmentSettings(userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          setOrderShipmentData(response.settings);
          showToast(
            "Order shipment email template has been " +
              (enabled ? "ENABLED" : "DISABLED")
          );
        } else {
          showErrorToast(response);
        }
      },
      (err: any) => {
        showErrorToast("Something went wrong");
      }
    );
  };
  return (
    <Page
      // subtitle={
      //   "Automated WhatsApp message templates cannot be modified or edited, because message templates need to be pre-approved by WhatsApp before they can be sent using the API."
      // }
      primaryAction={<button style={{ display: "none" }}></button>}
    >
      {toastMarkup}
      {errorToastMarkup}
      <Layout>
        <TemplateView
          data={data["ABCART"]}
          apiRes={abCartData}
          openModal={() => setopenAbCartModal(true)}
          open={openAbCartModal}
          supportEmailEnabled={supportEmailData.supportMailEnabled}
          supportEmail={supportEmailData.email}
          brandNameEnabled={brandNameEnabled}
          languageTemplate={abCartData.language}
          enabled={abCartData.enabled}
          discount={abCartData.discount}
          discountType={abCartData.discountType}
          toggleTemplate={(enabled: boolean) => enableAbandonedCart(enabled)}
        >
          <TemplateModal
            open={openAbCartModal}
            closeModal={() => setopenAbCartModal(false)}
            title="Abandoned Cart Settings"
            isDiscount={true}
            apiResData={abCartData}
            type="ABCART"
            showToast={showToast}
            showErrorToast={showErrorToast}
            userId={userId}
            shopUrl={shopUrl}
            language={abCartData.language}
            setData={setAbCartData}
            showSuccessToast={showToast}
            showErrorsToast={showErrorToast}
          />
        </TemplateView>
        <div className="Polaris-Page--divider w-100 mt-2"></div>
        <TemplateView
          data={data["ORDER_CONFIRMATION"]}
          apiRes={orderConfirmationData}
          openModal={() => setopenOrderConfirmationModal(true)}
          open={openOrderConfirmationModal}
          supportEmailEnabled={supportEmailData.supportMailEnabled}
          supportEmail={supportEmailData.email}
          brandNameEnabled={brandNameEnabled}
          languageTemplate={orderConfirmationData.language}
          enabled={orderConfirmationData.enabled}
          discount={orderConfirmationData.discount}
          discountType={orderConfirmationData.discountType}
          toggleTemplate={(enabled: boolean) =>
            enableOrderConfirmationTemplate(enabled)
          }
        >
          <TemplateModal
            open={openOrderConfirmationModal}
            closeModal={() => setopenOrderConfirmationModal(false)}
            title="Order Confirmation"
            isDiscount={true}
            apiResData={orderConfirmationData}
            type={"ORDER_CONFIRMATION"}
            showToast={showToast}
            showErrorToast={showErrorToast}
            language={orderConfirmationData.language}
            userId={userId}
            shopUrl={shopUrl}
            setData={setOrderConfirmationData}
            showSuccessToast={showToast}
            showErrorsToast={showErrorToast}
          />
        </TemplateView>
        <div className="Polaris-Page--divider w-100 mt-2"></div>
        <TemplateView
          data={data["ORDER_SHIPMENT"]}
          apiRes={orderShipmentData}
          openModal={() => setopenOrderShipmentModal(true)}
          open={openOrderShipmentModal}
          supportEmailEnabled={supportEmailData.supportMailEnabled}
          supportEmail={supportEmailData.email}
          brandNameEnabled={brandNameEnabled}
          languageTemplate={orderShipmentData.language}
          enabled={orderShipmentData.enabled}
          discount={false}
          toggleTemplate={(enabled: boolean) =>
            enableOrderShipmentTemplate(enabled)
          }
        >
          <TemplateModal
            open={openOrderShipmentModal}
            closeModal={() => setopenOrderShipmentModal(false)}
            title="Order Shipment"
            isDiscount={false}
            apiResData={orderShipmentData}
            type={"ORDER_SHIPMENT"}
            showToast={showToast}
            showErrorToast={showErrorToast}
            language={orderShipmentData.language}
            userId={userId}
            shopUrl={shopUrl}
            setData={setOrderShipmentData}
            showSuccessToast={showToast}
            showErrorsToast={showErrorToast}
          />
        </TemplateView>
        <div className="Polaris-Page--divider w-100 mt-2"></div>
        <TemplateView
          data={data["COD"]}
          apiRes={codData}
          openModal={() => setopenCodModal(true)}
          open={openCodModal}
          supportEmailEnabled={supportEmailData.supportMailEnabled}
          supportEmail={supportEmailData.email}
          brandNameEnabled={brandNameEnabled}
          languageTemplate={codData.language}
          enabled={codData.enabled}
          discount={false}
          toggleTemplate={(enabled: boolean) => enableCodTemplate(enabled)}
        >
          <TemplateModal
            open={openCodModal}
            closeModal={() => setopenCodModal(false)}
            title="COD Settings"
            isDiscount={false}
            apiResData={codData}
            type="COD"
            showToast={showToast}
            showErrorToast={showErrorToast}
            language={codData.language}
            userId={userId}
            shopUrl={shopUrl}
            setData={setCodData}
            showSuccessToast={showToast}
            showErrorsToast={showErrorToast}
          />
        </TemplateView>
      </Layout>
    </Page>
  );
}
