import { Fragment } from "react";
import { Layout, Spinner, List, Stack } from "@shopify/polaris";
import { PLAN_ENGAGE_WIX, PLAN_ENTERPRISE } from "../../../constants/constants";

const ExtensionPlanComponent = (props: any) => {
  const KeyFeaturesComponent = () => {
    return (
      <div className="detailed_plan_info">
        <tr className="Polaris-DataTable__TableRow">
          <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop">
            <p className="strong pb-2 includedTextCssShort">
              {props.planDetails.included ? props.planDetails.included : ""}
            </p>
            <div className="featuresPoints">
              <List>
                {props.planDetails.key_features.map((feat: any, index: any) => {
                  return <List.Item key={index}>{feat}</List.Item>;
                })}
              </List>
            </div>
          </td>
        </tr>
      </div>
    );
  };

  const ButtonCss = (r_button: boolean) => {
    if (r_button) {
      return `Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle text-center p-0 absolute align-at-top`;
    }
    return ``;
  };

  const renderButtonCSS = (r_button: boolean, isDisable: boolean) => {
    if (r_button) {
      return `Polaris-Button Polaris-Button--primary plan-recommend-btn`;
    }
    return `planButtonCssExtension ${isDisable ? "plan-btn-disabled" : ""}`;
  };

  return (
    <Fragment>
      <Stack vertical>
        <div className="iconSmall">
          <img src={props.planDetails.icon} alt="profile" height="40px" />
        </div>
        <Layout.Section oneThird key={props.index}>
          <div className="Polaris-Card short-tile plan-rec-card">
            <div className="Polaris-Card__Header">
              <div className="Polaris-Heading card-heading mt-2">
                {props.planDetails.planName1} <br />
                {props.planDetails.planName2}
              </div>
            </div>
            <div className="Polaris-Card__Section">
              <KeyFeaturesComponent />
              {props.planDetails.currency ? (
                <p className="plan_price_2">
                  <span className="sup2">{props.planDetails.currency}</span>
                  {props.planDetails.price}
                  <span className="plan_price_month_2">
                    /{props.planDetails.sub_interval}
                  </span>
                </p>
              ) : (
                <p className="plan_price_1_noPrice">
                  {props.planDetails.price}
                </p>
              )}
            </div>
            {props.planDetails.catch_line && (
              <div className="catchLineCSS">{props.planDetails.catch_line}</div>
            )}
            <div className={ButtonCss(false)}>
              <button
                onClick={() =>
                  [PLAN_ENTERPRISE, PLAN_ENGAGE_WIX].includes(
                    Number(props.planId)
                  )
                    ? props.contactUs()
                    : props.handleUpdatePlan(props.planId, props.page)
                }
                className={`${renderButtonCSS(
                  false,
                  props.currentPlanId === props.planId
                )}`}
                type="button"
                disabled={props.currentPlanId === props.planId}
              >
                <span className="Polaris-Button__Content">
                  <span className="Polaris-Button__Text">
                    {[PLAN_ENTERPRISE, PLAN_ENGAGE_WIX].includes(
                      Number(props.planId)
                    )
                      ? "Contact Us"
                      : props.currentPlanId === props.planId
                      ? "Subscribed"
                      : "Subscribe"}
                  </span>
                </span>
                {props.isLoading && props.selectedPlanId === props.planId && (
                  <span className="ml-1">
                    <Spinner size="small" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </Layout.Section>
      </Stack>
    </Fragment>
  );
};

export default ExtensionPlanComponent;
