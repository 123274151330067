import {
  GET_SHARE_THEMES,
  SHARE,
  SHARE_DEVICES,
  SHARE_DEVICES_DELETE,
  SHARE_THEME,
} from "../../constants/api-constants";
import api from "../../utils/api";
import httpClient from "../../utils/http-client";

export const getShareDetails = (userId: string) => {
  return new Promise((resolve, reject) => {
    api.get(SHARE.replace("$userId", userId)).then((response: any) => {
      if (response.status === "success") {
        resolve(response.share);
      } else {
        reject(response.message);
      }
    });
  });
};

export const updateShareData = (userId: string, params: any) => {
  return httpClient.post(SHARE.replace("$userId", userId), params);
};

export const updateShareDetails = (userId: string, params: any) => {
  return httpClient.post(SHARE.replace("$userId", userId), params);
};

export const getShareCustomThemes = (userId: string) => {
  return httpClient.get(GET_SHARE_THEMES.replace("$userId", userId));
};

export const updateShareTheme = (
  userId: string,
  buttonId: string,
  params: any
) => {
  return httpClient.post(
    SHARE_THEME.replace("$userId", userId).replace("$buttonId", buttonId),
    params
  );
};

export const getShareDevices = (userId: string) => {
  return httpClient.get(SHARE_DEVICES.replace("$userId", userId));
};

export const updateShareDevices = (userId: string, params: any) => {
  return httpClient.post(SHARE_DEVICES.replace("$userId", userId), params);
};

export const deleteShareDevices = (userId: string, params: any) => {
  return httpClient.post(
    SHARE_DEVICES_DELETE.replace("$userId", userId),
    params
  );
};
