import { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Layout,
  Page,
  Toast,
  Modal,
  Card,
  Icon,
  Button,
  Tooltip,
} from '@shopify/polaris';
import ModalForm from './ModalForm';
import { EditMinor, DeleteMinor, ArrowDownMinor } from '@shopify/polaris-icons';
import {
  typeOption,
  operatorConst,
  parameterKeysConst,
  choiceOptions,
  choiceOptionsWix,
} from '../../utils';
import {
  createUserGroup,
  editUserGroup,
  deleteUserGroup,
  getUserGroups,
  downloadCustomerGroup,
} from '../../../../redux/actions/customerSegmentation';
import { ExportToExcel } from '../../../../common/ExportToExcel';
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from '../../../../utils/clevertapUtils';
import LoadingUI from '../../../../common/LoadingUI';
import {
  CUSTOMER_DOWNLOAD_SENT_VIA_EMAIL,
  LOADING_SPINNER,
  PLAN_ENGAGE_PLUS,
  PLATFORM,
  segmentationLimit,
} from '../../../../constants/constants';
import { error } from 'console';

import './createUserGroup.scss';
import { useShopStore } from '../../../../hooks/shopStore';

const CreateUserGroupModal = (props: any) => {
  const {
    open,
    setCreateUserGroupModal,
    isEdit,
    setIsEdit,
    setAllowGroupCreation,
    setGroupLimit,
  } = props;
  // import { CUSTOMER_DOWNLOAD_SENT_VIA_EMAIL, LOADING_SPINNER, PLATFORM,segmentationLimit } from "../../../../constants/constants";

  const { shopDetailsData } = useShopStore();
  // const { open, setCreateUserGroupModal, isEdit, setIsEdit } = props;
  const initialConditionSet = {
    index: 1,
    param: '',
    operation: '',
    conditionValue: '',
    operator: '',
  };
  const [groupData, setGroupData] = useState<any>({
    id: '',
    name: '',
    conditions: [initialConditionSet],
  });
  const [toast, setToast] = useState({ show: false, message: '' });
  const [errorToast, setErrorToast] = useState({ show: false, message: '' });
  const [isDeletePopOpen, setIsDeletePopOpen] = useState(false);
  const [showLoadingUI, setShowLoadingUI] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [userGroupList, setUserGroupList] = useState<any>([]);
  const currPage = useRef<number>(-1);
  const history = useHistory();
  const observerTarget = useRef(null);
  const stopInfiniteScroll = useRef<boolean>(false);
  let groupLimit: any;
  const [hoveredIcon, setHoveredIcon] = useState('');

  let shop = shopDetailsData;

  segmentationLimit.forEach((plan) => {
    if (plan.planId == shop.planId) {
      groupLimit = plan.limit;
      setGroupLimit(plan.limit);
    }
  });

  useEffect(() => {
    if (shop && shop.userId) {
      if (
        shop.planId != Number(PLAN_ENGAGE_PLUS) ||
        (shop.planId == Number(PLAN_ENGAGE_PLUS) && currPage.current > 0)
      ) {
        fetchUserGroups(shop.userId, '', '');
      }
    }
  }, []);

  useEffect(() => {
    if (shop.planId == Number(PLAN_ENGAGE_PLUS)) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            if (!stopInfiniteScroll.current) {
              currPage.current += 1;
              fetchUserGroups(shop.userId, 'scroll', '');
            }
          }
        },
        { threshold: 1 }
      );

      if (observerTarget.current) {
        observer.observe(observerTarget.current);
      }

      return () => {
        if (observerTarget.current) {
          observer.unobserve(observerTarget.current);
        }
      };
    }
  }, [observerTarget]);

  const fetchUserGroups = (userId: any, type: string, eventData: any) => {
    if (!['', 'scroll'].includes(type)) {
      currPage.current = 0;
      stopInfiniteScroll.current = false;
    }
    setShowLoadingUI(true);
    getUserGroups(userId || shop.userId, 10, currPage.current).then(
      (response: any) => {
        setShowLoadingUI(false);
        const userGroups: any[] =
          response.message.customerSegmentResponseList || [];
        let segmentLength = userGroups.length;
        if (segmentLength === 0) {
          stopInfiniteScroll.current = true;
        }

        if (segmentLength >= groupLimit) setAllowGroupCreation(false);
        else setAllowGroupCreation(true);
        userGroups.forEach((eachGroup: any) => {
          if (eachGroup && eachGroup.conditionJson) {
            let groupObj: any = {};
            try {
              groupObj = JSON.parse(eachGroup.conditionJson);
            } catch (error) {
              console.log(error);
            }
            eachGroup.conditions = groupObj?.conditions?.map((cItem: any) => {
              if (cItem?.operation === operatorConst['IS_BETWEEN']) {
                cItem.conditionValue = {};
                cItem.conditionValue.start = new Date(
                  +cItem.startValue
                ).toString();
                cItem.conditionValue.end = new Date(+cItem.endValue).toString();
              } else if (
                cItem?.operation === operatorConst['IS_BEFORE'] ||
                cItem?.operation === operatorConst['IS_AFTER']
              ) {
                cItem.conditionValue = {};
                cItem.conditionValue.start = new Date(
                  +cItem.singleValue
                ).toString();
              } else {
                cItem.conditionValue = cItem.singleValue;
              }
              return cItem;
            });
          }
        });
        if (type) {
          if (type === 'edit') {
            let size = userGroups.filter((e: any) => e.id == eventData.id)[0]
              ?.size;
            eventData = { ...eventData, 'Number of contacts': size || 0 };
            clevertapEventPush({
              eventId: CT_EVENT_IDS.SEGMENT_EDITED,
              eventData: eventData,
            });
          } else if (type === 'create') {
            let size = userGroups.filter((e: any) => e.id == eventData.id)[0]
              ?.size;
            eventData = { ...eventData, 'Number of contacts': size || 0 };
            clevertapEventPush({
              eventId: CT_EVENT_IDS.SEGMENT_CREATED,
              eventData: eventData,
            });
          } else if (type === 'delete') {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.SEGMENT_DELETED,
              eventData: eventData,
            });
          }
        }
        if (type === 'scroll') {
          setUserGroupList((userGroupList: any) => {
            return userGroupList.concat(userGroups);
          });
        } else {
          setUserGroupList([...userGroups]);
        }
      },
      (err) => {
        showErrorToast(err.message);
        console.log('err', err.message);
      }
    );
  };

  const isValidForm = () => {
    let errorCondition = false;
    let errorValue = false;
    if (!groupData.name) {
      return 'Group Name is Required';
    }
    if (groupData.conditions.length > 0) {
      groupData.conditions.map((con: any) => {
        if (con.param !== 'created_on' && con.param !== 'last_ordered_on') {
          if (!con.conditionValue.trim()) {
            errorValue = true;
          }
          if (!con.operation.trim()) {
            errorCondition = true;
          }
        } else {
          // Handle case for dates separately
          if (Object.keys(con.conditionValue).length == 0) {
            errorValue = true;
          }
          if (!con.operation.trim()) {
            errorCondition = true;
          }
        }
      });
      if (errorCondition) return 'Condition is Required';
      if (errorValue) return 'Condition Value is Required';
    }
  };

  const showErrorToast = (message: string) => {
    if (typeof message !== 'string') message = 'Something went wrong';
    setErrorToast({ show: true, message: message });
  };

  const CreateUserGroup = useCallback(
    (_event?: any) => {
      let error = isValidForm();
      if (error) {
        showErrorToast(error);
      } else {
        if (groupData.conditions.length) {
          if (!groupData.conditions[groupData.conditions.length - 1]['param'])
            return showErrorToast('Please select the parameter');
          if (
            !groupData.conditions[groupData.conditions.length - 1]['operation']
          )
            return showErrorToast('Please select the condition');
          if (
            !groupData.conditions[groupData.conditions.length - 1][
              'conditionValue'
            ]
          )
            return showErrorToast('Please enter value');
        }

        groupData.conditions = groupData.conditions.map((cItem: any) => {
          cItem.startValue = '';
          cItem.endValue = '';
          cItem.singleValue = '';
          if (cItem?.operation === operatorConst['IS_BETWEEN']) {
            cItem.startValue = new Date(cItem.conditionValue.start).getTime();
            cItem.endValue = new Date(cItem.conditionValue.end).getTime();
          } else if (
            cItem?.operation === operatorConst['IS_BEFORE'] ||
            cItem?.operation === operatorConst['IS_AFTER']
          ) {
            cItem.singleValue = new Date(cItem.conditionValue.start).getTime();
          } else if (cItem?.param === parameterKeysConst.OPT_IN_STATUS) {
            cItem.singleValue = +cItem.conditionValue;
          } else {
            cItem.singleValue = cItem.conditionValue;
          }
          return cItem;
        });

        let params = {
          name: groupData.name,
          conditions: groupData.conditions,
        };
        setShowLoadingUI(true);
        if (isEdit) {
          editUserGroup(shop.userId, groupData.id, params).then(
            (response: any) => {
              if (response.status === 'success') {
                let eventData: any = {
                  Name: params.name,
                  id: response.id.message,
                };
                setToast({
                  show: true,
                  message: 'Customer Group updated successfully',
                });
                setGroupData({
                  id: '',
                  name: '',
                  conditions: [initialConditionSet],
                });
                setCreateUserGroupModal(false);
                fetchUserGroups(shop.userId, 'edit', eventData);
                setShowLoadingUI(false);
              } else {
                showErrorToast(response);
                setShowLoadingUI(false);
              }
            },
            (err) => {
              showErrorToast('Unable to update customer group');
              setShowLoadingUI(false);
            }
          );
        } else {
          createUserGroup(shop.userId, params).then(
            (response: any) => {
              if (response.status === 'success') {
                let eventData: any = {
                  Name: params.name,
                  id: response.id,
                };
                params.conditions.map((e: any, index: any) => {
                  eventData[
                    `Condition ${String.fromCharCode(65 + index)}`
                  ] = `${params.name} , ${e.param} , ${e.operation} , ${e.conditionValue}`;
                  if (
                    params.conditions.length > 0 &&
                    !!params.conditions[index + 1]
                  ) {
                    eventData[`Operator ${index + 1}`] = `${
                      params.conditions[index + 1].operator
                    }`;
                  }
                });
                setToast({
                  show: true,
                  message: 'Customer Group created successfully',
                });
                setGroupData({
                  id: '',
                  name: '',
                  conditions: [initialConditionSet],
                });
                setCreateUserGroupModal(false);
                fetchUserGroups(shop.userId, 'create', eventData);
                // setShowLoadingUI(false);
              } else {
                showErrorToast(response);
                setShowLoadingUI(false);
              }
            },
            (err) => {
              showErrorToast('Unable to create customer group');
              setShowLoadingUI(false);
            }
          );
        }
      }
    },
    [groupData]
  );

  const handleClose = () => {
    setGroupData({ id: '', name: '', conditions: [initialConditionSet] });
    setCreateUserGroupModal(false);
  };

  const handleEdit = (data: any) => {
    if (data) {
      setGroupData({
        id: data.id,
        name: data.name,
        conditions: data.conditions,
      });
      setCreateUserGroupModal(true);
      setIsEdit(true);
    }
  };
  const onDelete = (index: any) => {
    setSelectedIndex(index);
    setIsDeletePopOpen(true);
  };

  const handleDelete = () => {
    deleteUserGroup(shop.userId, userGroupList[selectedIndex].id).then(
      (response: any) => {
        let eventData = {
          'Number of contacts': userGroupList[selectedIndex].size,
          'Segment Name': userGroupList[selectedIndex].name,
        };
        if (response.status === 'success') {
          setToast({
            show: true,
            message: 'Customer Group deleted successfully',
          });
        } else {
          showErrorToast(response);
        }
        setSelectedIndex(null);
        setIsDeletePopOpen(false);
        fetchUserGroups(shop.userId, 'delete', eventData);
      },
      (err) => {
        showErrorToast('Unable to delete customer group');
      }
    );
  };

  const onDownload = (data: any) => {
    downloadCustomerGroup(shop.userId, data.id)
      .then((response: any) => {
        if (
          response.status === 'success' &&
          response.customers &&
          response.customers.length > 0
        ) {
          ExportToExcel(response.customers, 'customers');
          clevertapEventPush({
            eventId: CT_EVENT_IDS.SEGMENT_DOWNLOADED,
            eventData: {
              'Number of contacts': response?.customers?.length
                ? response.customers.length
                : 0,
              'Segment Name': data.name,
              'Sent Via Email': false,
            },
          });
        } else if (response.status === 'success' && response.email) {
          setToast({
            show: true,
            message: CUSTOMER_DOWNLOAD_SENT_VIA_EMAIL.replace(
              '$email',
              response.email
            ),
          });
          clevertapEventPush({
            eventId: CT_EVENT_IDS.SEGMENT_DOWNLOADED,
            eventData: {
              'Number of contacts': 0,
              'Segment Name': data.name,
              'Sent Via Email': true,
            },
          });
        } else {
          setErrorToast({
            show: true,
            message: 'Customer details not found',
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSendMessage = (data: any) => {
    clevertapEventPush({
      eventId: CT_EVENT_IDS.SEND_MESSAGE_TO_SEGMENT_CLICKED,
      eventData: {
        'Segment Name': data.name,
        'Number of contacts': data.size,
      },
    });
    history.push({ pathname: `campaigns/create`, state: { groupId: data.id } });
  };

  const trimLength = (value: any) => {
    if (value.length > 26) {
      return value.substring(0, 25) + '...';
    } else return value;
  };

  const getPrameterValue = (item: any) => {
    if (typeOption[item.param] === 'date') {
      return item.operation === operatorConst['IS_BETWEEN']
        ? `${handleDateFormate(
            item?.conditionValue?.start
          )} and ${handleDateFormate(item?.conditionValue?.end)}`
        : handleDateFormate(item?.conditionValue?.start);
    } else if (
      [
        parameterKeysConst.OPT_IN_STATUS,
        parameterKeysConst.OPT_IN_SOURCE,
      ].includes(item?.param)
    ) {
      const selectedOption = [PLATFORM.WIX, PLATFORM.NONE].includes(
        shop.platform
      )
        ? choiceOptionsWix[item?.param].find(
            (i: any) => +i.value === +item.singleValue
          )
        : choiceOptions[item?.param].find(
            (i: any) => +i.value === +item.singleValue
          );
      return selectedOption?.label || '';
    }
    return item.conditionValue;
  };

  const handleDateFormate = (date: any) => {
    return new Date(date).toLocaleDateString();
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: '' });
      }}
    />
  ) : null;

  return (
    <Page fullWidth>
      {/* <Layout> */}
      <div className='row'>
        {userGroupList?.map((data: any, index: any) => {
          return (
            // <Layout.Section oneThird key={index}>
            <div className='column'>
              <Card sectioned>
                <div className='card-size'>
                  <div className='d-flex-end' style={{ gap: '10px' }}>
                    <Tooltip content='Download'>
                      <div
                        onClick={() => onDownload(data)}
                        style={{ cursor: 'pointer' }}
                      >
                        <p
                          onMouseOver={() =>
                            setHoveredIcon(`download_${index}`)
                          }
                          onMouseLeave={() => setHoveredIcon('')}
                        >
                          <Icon
                            source={ArrowDownMinor}
                            color={
                              hoveredIcon != `download_${index}`
                                ? 'base'
                                : 'subdued'
                            }
                          />
                        </p>
                      </div>
                    </Tooltip>
                    <Tooltip content='Edit'>
                      <div
                        onClick={() => handleEdit(data)}
                        className='icons'
                        style={{ cursor: 'pointer' }}
                      >
                        <p
                          onMouseOver={() => setHoveredIcon(`edit_${index}`)}
                          onMouseLeave={() => setHoveredIcon('')}
                        >
                          <Icon
                            source={EditMinor}
                            color={
                              hoveredIcon != `edit_${index}`
                                ? 'base'
                                : 'subdued'
                            }
                          />
                        </p>
                      </div>
                    </Tooltip>
                    <Tooltip content='Delete'>
                      <div
                        onClick={() => onDelete(index)}
                        className='icons'
                        style={{ cursor: 'pointer' }}
                      >
                        <p
                          onMouseOver={() => setHoveredIcon(`delete_${index}`)}
                          onMouseLeave={() => setHoveredIcon('')}
                        >
                          <Icon
                            source={DeleteMinor}
                            color={
                              hoveredIcon != `delete_${index}`
                                ? 'base'
                                : 'subdued'
                            }
                          />
                        </p>
                      </div>
                    </Tooltip>
                  </div>
                  {data.name}
                  <div className='condition-set-section mt-1 condition-set-height'>
                    <div className=''>Conditions:</div>
                    {data &&
                      data.conditions &&
                      data?.conditions.map((item: any, i: any) => {
                        return (
                          <div key={i} className='ml-1 '>
                            {i + 1}. {item.param} {item.operation}{' '}
                            <Tooltip content={getPrameterValue(item)}>
                              <span className='word-wrap'>
                                {trimLength(getPrameterValue(item))}
                              </span>
                            </Tooltip>
                          </div>
                        );
                      })}
                    <div className='mt-1-half text-primary'>
                      {data.size} Customer
                    </div>
                    {/* <div>{index}. Order Value is Greater than $ 100</div>
                      <div>2. Last Order Date is after 31/07/2022</div> */}
                  </div>
                  <div className='mt-2 d-flex space-between vertical-align-center'>
                    <div style={{ fontSize: '11px', color: '#777' }}>
                      Created on {handleDateFormate(data.createdOn)}
                    </div>
                    <div>
                      <button
                        className='Send_Button'
                        onClick={() => onSendMessage(data)}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </Card>
              {/* </Layout.Section> */}
            </div>
          );
        })}
        {/* </Layout> */}
      </div>
      <div ref={observerTarget}></div>
      <Modal
        open={open}
        large={true}
        onClose={() => {
          setCreateUserGroupModal(false);
          setGroupData({ name: '', conditions: [initialConditionSet] });
        }}
        title={`${isEdit ? 'Edit' : 'Create'} Customer Groups`}
        primaryAction={{
          content: 'Save',
          onAction: CreateUserGroup,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleClose,
          },
        ]}
      >
        <Modal.Section>
          {
            <ModalForm
              groupData={groupData}
              initialConditionSet={initialConditionSet}
              setGroupData={setGroupData}
            />
          }
        </Modal.Section>
      </Modal>

      {/* delete modal */}
      <Modal
        open={isDeletePopOpen}
        onClose={() => {
          setIsDeletePopOpen(false);
        }}
        title={'Delete Customer Group'}
        primaryAction={{
          content: 'Delete',
          onAction: () => {
            handleDelete();
          },
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              setIsDeletePopOpen(false);
            },
          },
        ]}
      >
        <Modal.Section>
          Customer Group{' '}
          {userGroupList && userGroupList[selectedIndex]
            ? userGroupList[selectedIndex].name
            : ''}{' '}
          will be deleted permanently. Do you wish to delete the group?
        </Modal.Section>
      </Modal>

      {showLoadingUI && (
        <LoadingUI note={LOADING_SPINNER.CUSTOMER_SEGMENT_FETCH} />
      )}

      {toastMarkup}
      {errorToastMarkup}
    </Page>
  );
};

export default CreateUserGroupModal;
