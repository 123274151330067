// @ts-nocheck
import { useEffect, useState } from 'react';
import {
  Page,
  DataTable,
  Button,
  Stack,
  Icon,
  Modal,
  TextField,
} from '@shopify/polaris';
import { PlusMinor, EditMinor, DeleteMinor } from '@shopify/polaris-icons';
import moment from 'moment';
import { CONFIGURATIONSPAGE } from '../../../../constants/constants';
import {
  deleteTag,
  createTag,
  updateTag,
  getChatTags,
} from '../../../../redux/actions/chats';
import { useUsersContext } from '../../../../context/usersContext';

const Tags = (props: any) => {
  const { showToast, showErrorToast, Tags, fetchChatTags } = useUsersContext();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editTagValue, setEditTagValue] = useState('');
  const [editTagNewValue, setEditTagNewValue] = useState('');
  const [newTagValue, setNewTagValue] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tagToBeDeleted, setTagToBeDeleted] = useState('');

  const openAddNewTagModal = () => {
    setOpenAddModal(true);
  };

  const reset = (fetch = true) => {
    fetch && fetchChatTags();
    setOpenEditModal(false);
    setOpenAddModal(false);
    setOpenDeleteModal(false);
    setEditTagValue('');
    setEditTagNewValue('');
    setNewTagValue('');
    setTagToBeDeleted('');
  };

  const editTag = async () => {
    if (
      (editTagValue && editTagValue.trim()) ||
      (editTagNewValue && editTagNewValue.trim())
    ) {
      let params = new URLSearchParams();
      params.append('oldName', editTagValue.trim());
      params.append('name', editTagNewValue.trim());
      if (editTagValue.trim() !== editTagNewValue.trim()) {
        await props.runFunc(
          updateTag,
          params,
          'Tag Name updated successfully.'
        );
        reset();
      } else {
        showToast('Tag Name updated successfully.');
        reset(false);
      }
    } else {
      showErrorToast('Tag name should not be empty.');
    }
  };

  const removeTag = async (name: string) => {
    let params = new URLSearchParams();
    params.append('name', name);
    await props.runFunc(deleteTag, params, 'Tag deleted successfully.');
    reset();
  };

  const addNewTag = async () => {
    if (newTagValue && newTagValue.trim()) {
      let params = new URLSearchParams();
      params.append('name', newTagValue.trim());
      await props.runFunc(createTag, params, 'Tag added successfully.');
      reset();
    } else {
      showErrorToast('Tag name should not be empty.');
    }
  };

  const getRows = () => {
    let rows = [];
    Tags.forEach((o: any) => {
      rows.push([
        <Stack vertical spacing='extraTight'>
          <p style={{ whiteSpace: 'pre-wrap' }}>{o.name}</p>
        </Stack>,
        <Stack vertical spacing='extraTight'>
          <p>
            {moment(new Date(o.lastUpdatedOn)).format(
              'DD/MM/YYYY | hh:mm:ss A'
            )}
          </p>
        </Stack>,
        <Stack vertical spacing='extraTight'>
          <div style={{ display: 'flex', gap: '15px' }}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setEditTagValue(o.name);
                setEditTagNewValue(o.name);
                setOpenEditModal(true);
              }}
            >
              <Icon source={EditMinor} color='base' />
            </span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setOpenDeleteModal(true);
                setTagToBeDeleted(o.name);
              }}
            >
              <Icon source={DeleteMinor} color='base' />
            </span>
          </div>
        </Stack>,
      ]);
    });
    return rows;
  };

  return (
    <>
      <Page>
        <div className='config-page'>
          <div className='flexSpaceBetween mb2'></div>
          <div className='flexSpaceBetween createNewCampaign'>
            <div style={{ width: '75%' }}>
              <h1 className='Polaris-Header-Title'>
                {CONFIGURATIONSPAGE.TAGS.TITLE}
              </h1>
            </div>
            <div className='button-design-new'>
              <Button
                icon={PlusMinor}
                disabled={false}
                onClick={() => openAddNewTagModal()}
                primary
              >
                {CONFIGURATIONSPAGE.TAGS.ADD_NEW_BUTTON}
              </Button>
            </div>
          </div>
          <div className='table-list'>
            <DataTable
              columnContentTypes={['text', 'text', 'text', 'text']}
              headings={CONFIGURATIONSPAGE.TAGS.TABLE.HEADERS}
              rows={getRows()}
            />
          </div>

          <Modal
            open={openAddModal}
            onClose={() => {
              setOpenAddModal(false);
              reset();
            }}
            title={''}
            small
          >
            <Modal.Section>
              <p className='chat__configurationModalHeading'>
                {CONFIGURATIONSPAGE.TAGS.ADD_MODEL_TITLE}
              </p>
              <TextField
                value={newTagValue}
                onChange={(e) => {
                  if (e[e.length - 1] !== ',') setNewTagValue(e);
                }}
                label={CONFIGURATIONSPAGE.TAGS.NAME_LABEL}
                type='text'
                helpText={CONFIGURATIONSPAGE.TAGS.TAG_DESCRIPTION}
                maxLength={30}
              />
              <br />
              <br />
              <div className='chat__ConfigurationButtonSave'>
                <Button disabled={false} onClick={() => addNewTag()} primary>
                  {CONFIGURATIONSPAGE.TAGS.SAVE_TAG}
                </Button>
              </div>
            </Modal.Section>
          </Modal>

          <Modal
            open={openEditModal}
            onClose={() => {
              setOpenEditModal(false);
              reset();
            }}
            title=''
            small
          >
            <Modal.Section>
              <p className='chat__configurationModalHeading'>
                {CONFIGURATIONSPAGE.TAGS.EDIT_MODEL_TITLE}
              </p>
              <TextField
                value={editTagNewValue}
                onChange={(e) => {
                  if (e[e.length - 1] !== ',') setEditTagNewValue(e);
                }}
                label={CONFIGURATIONSPAGE.TAGS.NAME_LABEL}
                type='text'
                helpText={CONFIGURATIONSPAGE.TAGS.TAG_DESCRIPTION}
                maxLength={30}
              />
              <br />
              <br />
              <div className='chat__ConfigurationButtonSave'>
                <Button disabled={false} onClick={() => editTag()} primary>
                  {CONFIGURATIONSPAGE.TAGS.EDIT_TAG}
                </Button>
              </div>
            </Modal.Section>
          </Modal>

          <Modal
            open={openDeleteModal}
            onClose={() => {
              setOpenDeleteModal(false);
            }}
            title={''}
            small
          >
            <Modal.Section>
              <p className='chat__configurationModalHeading'>{'Delete Tag'}</p>
              <p style={{ fontSize: '2rem', marginBottom: '10px' }}>
                Are you sure you want to delete Tag ?
              </p>
              <p style={{ fontSize: '1.5rem', color: 'grey' }}>
                Note: the tag will be removed from all the numbers it is
                associated with.
              </p>
              <br />
              <br />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: 'auto',
                  justifyContent: 'center',
                  padding: '10px',
                  gap: '8px',
                }}
              >
                <div className='chat__ConfigurationButtonSave'>
                  <Button onClick={() => removeTag(tagToBeDeleted)} primary>
                    {'YES'}
                  </Button>
                </div>
                <div className='chat__ConfigurationButtonSave'>
                  <Button onClick={() => reset()}>{'NO'}</Button>
                </div>
              </div>
            </Modal.Section>
          </Modal>
        </div>
      </Page>
    </>
  );
};

export default Tags;
