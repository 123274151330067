import { Button, ButtonGroup, Modal } from '@shopify/polaris';
import { DELETE_SCHEDULED_CAMPAIGN } from '../../../constants/constants';

const DeleteScheduledCampaignModal = (props: any) => {
  return (
    <Modal
      open={props.showModal}
      sectioned={true}
      title={''}
      onClose={() => {
        props.setShowModal(false);
      }}
    >
      <div className='display-flex-column-center gap-3rem'>
        <p className='font-size-2-5rem font-weight-500'>
          {DELETE_SCHEDULED_CAMPAIGN.TITLE}
        </p>
        <p className='font-size-1-8rem color-grey'>
          {DELETE_SCHEDULED_CAMPAIGN.DESC}
        </p>
        <div className='d-flex-end deleteSheduledCampaign'>
          <ButtonGroup>
            <button
              className='Polaris-Button Polaris-Button--primary Polaris-Button--outline pt-1 pr-2 pl-2 fs-large'
              onClick={() => props.setShowModal(false)}
            >
              {DELETE_SCHEDULED_CAMPAIGN.BUTTON_NO}
            </button>

            <Button primary onClick={() => props.removeScheduledCampaign()}>
              {DELETE_SCHEDULED_CAMPAIGN.BUTTON_YES}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteScheduledCampaignModal;
