// @ts-nocheck
import { useState, useEffect } from 'react';
import { Checkbox } from '@shopify/polaris';
import { useUsersContext } from '../../../../context/usersContext';

function TagsComponent(props: any) {
  const { currentUser, showErrorToast } = useUsersContext();
  const [actionListItems, setActionListItems] = useState([]);
  const [selected, setSelected] = useState({});

  const handleChange = (ali: any) => {
    if (!selected[ali]) {
      const tags = Object.keys(selected).filter((tag: any) => {
        return selected[tag];
      });
      if (tags.length >= 6) {
        showErrorToast('You can only add 6 tags to a single chat.');
        return;
      }
    }
    setSelected({
      ...selected,
      [ali]: !selected[ali],
    });
  };

  useEffect(() => {
    if (
      Object.keys(currentUser).includes('tags') &&
      currentUser['tags'].length
    ) {
      const selTag = {};
      currentUser['tags'].forEach((tag: any) => {
        selTag[tag] = true;
      });
      setSelected(selTag);
    }
  }, [currentUser]);

  useEffect(() => {
    if (props?.Tags?.length) {
      setActionListItems(props.Tags);
    }
  }, [props.Tags]);

  useEffect(() => {
    props.setSelectedTags(selected);
  }, [selected]);

  return (
    <div className='chat__TagCheckbox'>
      {actionListItems.map((ali: any) => {
        return (
          <Checkbox
            id={ali.name}
            label={ali.name}
            checked={selected[ali.name]}
            onChange={() => {
              handleChange(ali.name);
            }}
          />
        );
      })}
    </div>
  );
}

export default TagsComponent;
