// @ts-nocheck
import formatTime from '../../../../utils/formatTime';
import { useUsersContext } from '../../../../context/usersContext';
import ShowImage from './ShowImage';

const Chats = (props: any) => {
  const { currentUser } = useUsersContext();
  const datesOnScreen = [];

  const currentChatDate = (timestamp: any) => {
    const formattedDate = formatTime(timestamp, false, true);
    if (timestamp && datesOnScreen.indexOf(formattedDate) === -1) {
      datesOnScreen.push(formattedDate);
      return (
        <div className='chat__date-wrapper'>
          <span className='chat__date'>{formattedDate}</span>
        </div>
      );
    } else {
      return null;
    }
  };

  let showTail = false;
  let lastMessageFromUser = false;
  let lastMessageFromAgent = false;

  return (
    <div className='chat__msg-group'>
      {props.chatRoomMessages[currentUser.customerPhone].map(
        (ucMessage: any, index: any) => {
          if (
            lastMessageFromUser != ucMessage.fromUser ||
            lastMessageFromAgent === ucMessage.fromUser
          ) {
            showTail = true;
          } else {
            showTail = false;
          }
          if (index === 0) {
            showTail = true;
          }
          lastMessageFromUser = ucMessage.fromUser;
          lastMessageFromAgent = !ucMessage.fromUser;

          return (
            <div key={index} style={{ display: 'contents' }}>
              {currentChatDate(ucMessage.messageTime)}
              <div
                className={
                  ucMessage.fromUser
                    ? `chat__msg chat__msg--rxd${showTail ? '--tail' : ''} ${
                        ucMessage?.mediaPath ? 'chat__mediaBlock' : ''
                      }`
                    : `chat__msg chat__msg--sent${showTail ? '--tail' : ''}  ${
                        ucMessage?.mediaPath ? 'chat__mediaBlock' : ''
                      }`
                }
              >
                <div
                  style={{
                    width: `${ucMessage?.mediaPath ? '250px' : '100%'}`,
                  }}
                >
                  {ucMessage?.mediaPath && (
                    <ShowImage mediaPath={ucMessage?.mediaPath} from='chats' />
                  )}
                  {ucMessage?.message && <span>{ucMessage.message}</span>}
                </div>
                <span className='chat__msg-filler'></span>
                <span className='chat__msg-footer'>
                  {formatTime(ucMessage.messageTime)}
                </span>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default Chats;
