import { Layout } from "@shopify/polaris";
import styles from "./analytics.module.scss";

import Chart, { ChartLegends, DisableBlock } from "./Chart";

type LineChartsProps = {
  data: any[];
  lines: any[];
  title?: string;
  children?: any;
  showLegends?: boolean;
  disabled?: boolean;
  disabledText?: any;
  disabledRedirectLink?: any;
  hasContent?: boolean;
  disableChartContent?: any;
  xDataKey?: any;
};

const LineCharts = ({
  data,
  lines,
  title,
  children,
  showLegends = true,
  disabled = false,
  disabledText,
  disabledRedirectLink,
  hasContent = false,
  disableChartContent,
  xDataKey = "",
}: LineChartsProps) => {
  return (
    <Layout>
      <div
        className={`${styles.lineChartContainer} ${styles.lineChartMobileContainer}`}
      >
        <Chart
          title={title}
          disabled={disabled}
          disabledRedirectLink={disabledRedirectLink}
          data={data}
          disableChartContent={disableChartContent}
          lines={lines}
          xDataKey={xDataKey}
        />
      </div>
      <div
        className={`${styles.lineChartLegendContainer} ${styles.lineChartLegendMobileContainer} `}
      >
        {disabled ? (
          <DisableBlock disabledText={disabledText} />
        ) : (
          <div className={styles.blockCont}>
            {showLegends && (
              <ChartLegends lines={lines} isHorizontal={hasContent} />
            )}
          </div>
        )}
        {children ? children : ""}
      </div>
    </Layout>
  );
};

export default LineCharts;
