import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  FormLayout,
  Layout,
  RadioButton,
  Stack,
  TextStyle,
} from "@shopify/polaris";

import { getWheelDetails } from "../../../redux/actions/spin-the-wheel";
import { MessageLanguagesList } from "../../../constants/constants";
import { useShopStore } from '../../../hooks/shopStore';

const MessageLanguage = (props: any) => {
  const { shopDetailsData } = useShopStore();
  useEffect(() => {
    if (props.reset) {
      resetLanguageData();
    }
  }, [props.reset]);

  const resetLanguageData = () => {
    let shop = shopDetailsData;
    getWheelDetails(shop.userId).then((data: any) => {
      props.setLanguage(data.language);
    });
  };

  const handleChange = (_checked: any, newValue: any) => {
    props.setLanguage(newValue.toUpperCase());
    props.onChanges({ language: newValue.toUpperCase() });
  };

  const getAvailableLanguages = (selectedLang: any) => {
    var languages_buttons = [];
    var available_languages = MessageLanguagesList;

    for (var i = 0; i < Object.keys(available_languages).length; i++) {
      var template = (
        <RadioButton
          label={available_languages[i]}
          id={available_languages[i]}
          checked={
            selectedLang.toUpperCase() == available_languages[i].toUpperCase()
          }
          onChange={(_checked, newValue) => {
            handleChange(_checked, newValue);
          }}
          key={i}
        />
      );
      languages_buttons.push(template);
    }

    return <Stack>{languages_buttons}</Stack>;
  };

  const getMessageContent = (selectedLang: any) => {
    return <TextStyle variation="subdued">{props.internalText}</TextStyle>;
  };

  return (
    <>
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <div>
              <TextStyle variation="strong">Language</TextStyle>
              <br />
              <br />
              {getAvailableLanguages(props.selectedLanguage)}
            </div>
          </FormLayout.Group>
        </FormLayout>
      </Card>
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <Stack vertical>
              <TextStyle variation="strong">Message</TextStyle>
              <div>{getMessageContent(props.selectedLanguage)}</div>
            </Stack>
          </FormLayout.Group>
        </FormLayout>
      </Card>
    </>
  );
};

export default MessageLanguage;
