import { Card, Stack } from "@shopify/polaris";
import { useEffect, useState } from "react";
import ReactSwitch from "react-switch";

const CRMTile = (props: any) => {
  const [p1, setP1] = useState("");
  const [p2, setP2] = useState("");

  useEffect(() => {
    if (props.isCrmToolEnabled) {
      setP1("You are using latest version of CRM tool!");
      setP2(
        "To move back to older version, please switch off the toggle button"
      );
    } else {
      setP1("Announcing a new CRM tool!");
      setP2(
        "We just launched a new way to manage messaging using WhatsApp Business API, Try out now!"
      );
    }
  }, [props.isCrmToolEnabled]);

  return (
    <>
      <div className={props.isCrmToolEnabled ? "planStatusNew" : ""}>
        <Card>
          <div
            className={props.isCrmToolEnabled ? "planColorNew" : "planColorOld"}
          >
            <Card.Section>
              <Stack alignment="center">
                <Stack.Item fill>
                  <p
                    style={{
                      fontWeight: "600",
                      lineHeight: "40px",
                      fontSize: "1.8rem",
                    }}
                  >
                    {p1}
                  </p>
                  <p
                    style={{
                      color: "rgba(109, 113, 117, 1)",
                      fontSize: "1.5rem",
                    }}
                  >
                    {p2}
                  </p>
                </Stack.Item>

                <Stack.Item>
                  <div className="crmTile">
                    <ReactSwitch
                      onChange={() => {
                        props.toggleCrmToolSettings(!props.isCrmToolEnabled);
                      }}
                      checked={props.isCrmToolEnabled}
                      onColor="#5DC001"
                      onHandleColor="#fff"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 6px rgba(0, 0, 0, 0.2)"
                      height={20}
                      handleDiameter={10}
                    />
                  </div>
                </Stack.Item>
              </Stack>
            </Card.Section>
          </div>
        </Card>
      </div>
      <br />
    </>
  );
};

export default CRMTile;
