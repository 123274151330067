import { Link, Modal, Stack, TextContainer } from "@shopify/polaris";
import { useState } from "react";
import { useShopStore } from '../../hooks/shopStore';

const CheckoutOptinGuideModalWiX = (props: any) => {
  const { shopDetailsData } = useShopStore(); 
  const [guideStep, setGuideStep] = useState(0);
  let shop = shopDetailsData;

  const modalContentGuideStep = () => {
    switch (guideStep) {
      case 0:
        return (
          <TextContainer>
            <p>
              <Link
                url={`https://www.wix.com/dashboard/${shop.wixSiteId}/settings`}
                external={true}
              >
                Go to Settings
              </Link>{" "}
              in your site's dashboard & click eCommerce settings.
            </p>
            <img
              className="checkoutOptinModalImage"
              src="https://cdn.shopify.com/s/files/1/0683/1371/0892/files/SL-WiX-OVC-Guide-Step1.jpg?v=1684306458"
              alt="OVC-Guide-WiX-Step1"
              rel="preload"
            />
          </TextContainer>
        );

      case 1:
        return (
          <TextContainer>
            <p>
              Scroll down to the Customize your checkout section & enable the
              phone number collection toggle switch. It is advised to click the
              Mandatory field checkbox next to the phone number
            </p>
            <img
              className="checkoutOptinModalImage"
              src="https://cdn.shopify.com/s/files/1/0683/1371/0892/files/SL-WiX-OVC-Guide-Step2.jpg?v=1684306458"
              alt="OVC-Guide-WiX-Step2"
              rel="preload"
            />
          </TextContainer>
        );

      case 2:
        return (
          <TextContainer>
            <p>
              Scroll down to the Subscription Checkbox section. Click the
              Subscription Checkbox toggle to enable it. It is advised to keep
              the the subscription checkbox checked by default.
            </p>
            <img
              className="checkoutOptinModalImage"
              src="https://cdn.shopify.com/s/files/1/0683/1371/0892/files/SL-WiX-OVC-Guide-Step3.png?v=1684306458"
              alt="OVC-Guide-WiX-Step3"
              rel="preload"
            />
          </TextContainer>
        );

      case 3:
        return (
          <TextContainer>
            <p>
              Further Scroll down to the Checkout policies section. If you
              already have T&C enabled on the checkout page, please add an
              additional term stating “By subscribing to email/sms
              notifications, you also agree to receive notifications on
              WhatsApp”
            </p>
            <p>OR</p>
            <p>
              You can also add custom policy by clicking on “Create your own
              custom policy” button. You can create a policy named “WhatsApp
              Optin”. In the policy description, please mention that “By
              subscribing to email/sms notifications, you also agree to receive
              notifications on WhatsApp”
            </p>
            <img
              className="checkoutOptinModalImage"
              src="https://cdn.shopify.com/s/files/1/0683/1371/0892/files/SL-WiX-OVC-Guide-Step4.jpg?v=1684306458"
              alt="OVC-Guide-WiX-Step4"
              rel="preload"
            />
          </TextContainer>
        );

      case 4:
        return (
          <TextContainer>
            <p>
              <b>Almost done!</b> If you completed all the steps successfully,
              your checkout page should look similar to this:
            </p>
            <img
              className="checkoutOptinModalImage"
              src="https://cdn.shopify.com/s/files/1/0683/1371/0892/files/SL-WiX-OVC-Guide-Step5.png?v=1684306458"
              alt="OVC-Guide-WiX-Step5"
              rel="preload"
            />
          </TextContainer>
        );
    }
  };

  const incrementGuideStep = () => {
    setGuideStep(guideStep + 1);
  };

  const decrementGuideStep = () => {
    setGuideStep(guideStep - 1);
  };

  const stepsCompletedActions = () => {
    props.stepsCompleted();
    props.closeModal();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
      title={
        guideStep === 4 ? "Final step" : "Step " + (guideStep + 1) + " of 5"
      }
      primaryAction={{
        content:
          guideStep === 4 ? "I have completed all the steps" : "Next step",
        onAction: guideStep === 4 ? stepsCompletedActions : incrementGuideStep,
      }}
      secondaryActions={
        guideStep >= 1
          ? [
              {
                content: "Back",
                onAction: decrementGuideStep,
              },
            ]
          : undefined
      }
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>{modalContentGuideStep()}</Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export default CheckoutOptinGuideModalWiX;
