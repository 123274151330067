import {
  Button,
  Card,
  ContextualSaveBar,
  Layout,
  Link,
  RadioButton,
  Stack,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { Fragment, useEffect, useState } from "react";
import SupportNumberMarkup from "../../common/SupportNumberMarkup";
import TemplateLanguageSettingMarkup from "../../common/TemplateLanguageSettingMarkup";
import {
  AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW,
  COUPON_TYPE_PERCENT,
  COUPON_TYPE_STATIC,
  CRM_TEMPLATE_DICT,
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_DUTCH,
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_TURK,
} from "../../constants/constants";
import {
  fetchOrderSettings,
  fetchPhoneSettings,
  updateOrderSettings,
  updatePhoneSettings,
} from "../../redux/actions/crmOrders";
import { clevertapEventPush, CT_EVENT_IDS } from "../../utils/clevertapUtils";
import { objectDifference } from "../../utils/helper";
import DiscountMarkup from "../AbandonedCart/DiscountMarkup";
import { useShopStore } from '../../hooks/shopStore';

const CrmSettingPage = (props: any) => {
  const { setToast, setErrorToast, setShowErrorToast, setShowSuccessToast } =
    props;
  var TEMPLATE_DICT: any = CRM_TEMPLATE_DICT;
  const { shopDetailsData } = useShopStore();

  const [initialOrderData, setInitialOrderData] = useState({
    confirmationEnabled: true,
    couponCode: "",
    discount: true,
    discountType: "FIXED",
    discountRatio: 10,
    language: "ENGLISH",
    shipmentEnabled: true,
    thankYouPage: true,
    order_tracking_url_type: 1,
  });
  const [orderData, setOrderData] = useState({
    confirmationEnabled: true,
    couponCode: "",
    discount: true,
    discountType: "FIXED",
    discountRatio: 10,
    language: "ENGLISH",
    shipmentEnabled: true,
    thankYouPage: true,
    order_tracking_url_type: 1,
  });
  const [refOrderData, setRefOrderData] = useState({
    confirmationEnabled: true,
    couponCode: "",
    discount: true,
    discountType: "FIXED",
    discountRatio: 10,
    language: "ENGLISH",
    shipmentEnabled: true,
    thankYouPage: true,
    order_tracking_url_type: 1,
  });
  const [initialPhoneData, setInitialPhoneData] = useState({
    countryCode: "",
    phone: "",
  });
  const [phoneData, setPhoneData] = useState({
    countryCode: "",
    phone: "",
  });
  const [refPhoneData, setRefPhoneData] = useState({
    countryCode: "",
    phone: "",
  });

  const [dataChanges, setDataChanges] = useState<any>({
    order: null,
    phone: null,
  });
  const [hasChanges, setHasChanges] = useState(false);
  const [shop, setShop] = useState({
    id: "",
    url: "",
    userId: "",
  });
  const [isOrderConfirmationEnabled, setIsOrderConfirmationEnabled] =
    useState<boolean>();
  const [isOrderShipmentEnabled, setIsOrderShipmentEnabled] =
    useState<boolean>();

  useEffect(() => {
    let shop = shopDetailsData;
    if (shop && shop.id) {
      setShop(shop);
      getOrderData(shop.userId);
      getPhoneData(shop.userId);
    }
  }, []);
  useEffect(() => {
    var diffObj: any = objectDifference(refOrderData, orderData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        order: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({ ...dataChanges, order: null }));
    }
  }, [orderData]);

  useEffect(() => {
    var diffObj: any = objectDifference(refPhoneData, phoneData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        phone: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({ ...dataChanges, phone: null }));
    }
  }, [phoneData]);

  useEffect(() => {
    if (dataChanges.order || dataChanges.phone) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [dataChanges]);

  const getOrderData = (userId: string) => {
    fetchOrderSettings(userId).then((res: any) => {
      if (res.status === "success") {
        if (res.settings.language === "NONE") {
          res.settings.language = "ENGLISH";
        }
        setInitialOrderData(res.settings);
        setRefOrderData(res.settings);
        setOrderData(res.settings);
        setIsOrderConfirmationEnabled(res.settings.confirmationEnabled);
        setIsOrderShipmentEnabled(res.settings.shipmentEnabled);
      }
    });
  };
  const getPhoneData = (userId: string) => {
    fetchPhoneSettings(userId).then((res: any) => {
      if (res.status === "success") {
        setInitialPhoneData(res.settings);
        setRefPhoneData(res.settings);
        setPhoneData(res.settings);
      }
    });
  };

  const description = (
    <span>
      <p>
        <b>
          {
            "Automated WhatsApp messages are sent from our official WhatsApp Business API approved "
          }
        </b>
        <Link external={true} url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}>
          {"phone number"}
        </Link>
        {" using message templates approved by WhatsApp."}
        <br />
        <br />
        We include your{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          business name
        </Link>{" "}
        and{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          WhatsApp support number
        </Link>{" "}
        in every automated message. Your customers can recognise you and contact
        you easily if they need help.
      </p>
    </span>
  );
  const order_confirmation_message_template_description = (
    <>
      <p>
        Send an order confirmation message as soon as a customer places an order
        on your store. You can also give customers a coupon code for their next
        order.
      </p>
      <br />
      <p>
        <b>Automated WhatsApp message templates cannot be modified or edited</b>
        , because message templates need to be pre-approved by WhatsApp before
        they can be sent using the API.
      </p>
      <br />
      {"To learn about the cost of sending messages, view"}
      <Link
        external={true}
        url="https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0"
      >
        {" pricing chart"}
      </Link>
    </>
  );

  const getOrderConfirmationPreview = () => {
    switch (orderData.language) {
      case TEMPLATE_LANGUAGE_ENG:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/eng-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_PORT:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/por-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_SPAN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/spa-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_ITAL:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ita-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_FREN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/fr-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_INDO:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/indo-order_confirmation_preview.png?v=1587749561";
      case TEMPLATE_LANGUAGE_GERM:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ger-order_confirmation_preview-updated.png?v=1587819720";
      case TEMPLATE_LANGUAGE_ARAB:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/arabic-order_confirmation_preview.png?v=1587749561";
      case TEMPLATE_LANGUAGE_TURK:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/turkish-order_confirmation_preview.png?v=1587819648";
      case TEMPLATE_LANGUAGE_HEBR:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/hebrew-order_confirmation_preview.png?v=1591679012";
      case TEMPLATE_LANGUAGE_DUTCH:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/dutch-order_confirmation_preview.png?v=1591677402";
    }
  };
  const toggleDiscount = () => {
    setOrderData((data) => ({
      ...data,
      discount: !orderData.discount,
      discountType: COUPON_TYPE_PERCENT,
    }));
  };

  const handleDiscountTypeChange = (newValue: any) => {
    setOrderData((data) => ({ ...data, discountType: newValue }));
  };

  const handleDiscountChange = (newValue: any) => {
    if (newValue <= 100 && newValue >= 0) {
      setOrderData((data) => ({ ...data, discountRatio: newValue }));
    }
  };

  const handleCouponChange = (newValue: any) => {
    setOrderData((data) => ({ ...data, couponCode: newValue }));
  };

  const validateFixedCouponCode = () => {
    if (
      orderData.discount &&
      orderData.discountType === COUPON_TYPE_STATIC &&
      !orderData.couponCode
    ) {
      return "Coupon code cannot be empty";
    }
    return false;
  };

  const validateDiscountRatio = () => {
    if (
      orderData.discount &&
      orderData.discountType === COUPON_TYPE_PERCENT &&
      !orderData.discountRatio
    ) {
      return "Discount Value cannot be empty";
    }
    if (
      orderData.discount &&
      orderData.discountType === COUPON_TYPE_PERCENT &&
      String(orderData.discountRatio) === "0"
    ) {
      return "Discount Value cannot be 0";
    }
    return false;
  };

  const validateSupportCountryCode = () => {
    var cc = /^(?:[1-9]\d{0,5})$/;
    if (!phoneData.countryCode || phoneData.countryCode.match(cc)) {
      return false;
    } else if (phoneData.countryCode && phoneData.countryCode[0] === "0") {
      return "cannot start with 0";
    } else {
      return "enter a valid country code";
    }
  };

  const validateSupportPhoneNo = () => {
    var phoneno = /^(?:[0-9]\d{0,14})$/;
    if (!phoneData.phone || phoneData.phone.match(phoneno)) {
      return false;
    } else {
      return "Enter a valid phone number";
    }
  };

  const OrderConfirmationTemplateClick = (enabled: boolean) => {
    if (!phoneData.phone || validateSupportPhoneNo()) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("A valid support phone number must be given");
      return;
    }

    if (!phoneData.countryCode || validateSupportCountryCode()) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("A valid support country code must be given");
      return;
    }

    if (
      orderData.discount &&
      orderData.discountType === COUPON_TYPE_STATIC &&
      !orderData.couponCode
    ) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("Coupon code cannot be empty");
      return;
    }

    if (
      orderData.discount &&
      orderData.discountType === COUPON_TYPE_PERCENT &&
      !orderData.discountRatio
    ) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("Discount % cannot be empty");
      return;
    }

    var params: any = new URLSearchParams();
    params.append("confirmationEnabled", enabled);
    updateOrderSettings(shop.userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          setIsOrderConfirmationEnabled(response.settings.confirmationEnabled);
          setToast(
            "Order Confirmation message template has been " +
              (enabled ? "ENABLED" : "DISABLED")
          );
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      },
      (err: any) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Something went wrong");
      }
    );
  };

  const orderConfirmationTemplateMarkup = (
    <Card>
      <Card.Header
        title="Order Confirmation"
        children={[
          isOrderConfirmationEnabled ? (
            <Button
              key={"order-crm-disable"}
              onClick={() => OrderConfirmationTemplateClick(false)}
            >
              Disable
            </Button>
          ) : (
            <Button
              primary
              key={"order-crm-enable"}
              onClick={() => OrderConfirmationTemplateClick(true)}
            >
              Enable
            </Button>
          ),
        ]}
      ></Card.Header>

      <Card.Section>
        <p className="paddingRight6px notranslate messageTemplateBackground">
          {TEMPLATE_DICT[orderData.language]["order_confirmation_template"]}
        </p>
        <br />
        <Link external={true} url={getOrderConfirmationPreview()}>
          Preview
        </Link>
      </Card.Section>

      <Card.Section>
        <TextContainer>
          <DiscountMarkup
            enable_discount={orderData.discount}
            toggleDiscount={toggleDiscount}
            discount_percentage={orderData.discountRatio}
            handleDiscountChange={handleDiscountChange}
            coupon_code={orderData.couponCode}
            handleCouponChange={handleCouponChange}
            discount_type={orderData.discountType}
            handleDiscountTypeChange={handleDiscountTypeChange}
            validateFixedCouponCode={validateFixedCouponCode}
            validateDiscountRatio={validateDiscountRatio}
            shop={shop}
          />
        </TextContainer>
      </Card.Section>
    </Card>
  );

  const order_shipment_message_template_description = (
    <span>
      <p>
        Order shipment message is sent when you fulfil an order in the
        E-commerce manager.
      </p>
      <br />
      <p>Tracking number and URL is taken from your E-commerce order data.</p>
    </span>
  );

  const trackingShippingUrl = (
    <div className="discountTypeHelpTextFontSize">
      <p>
        Tracking URL from order data{" "}
        <TextStyle variation="subdued">
          <Link
            external={true}
            url={`https://cdn.shopify.com/s/files/1/0070/3666/5911/files/tracking_link_shipment_url.png?v=1591762091`}
          >
            (see example)
          </Link>
        </TextStyle>
      </p>
    </div>
  );

  const trackingThankyoupageUrl = (
    <div className="discountTypeHelpTextFontSize">
      <p>
        Thank you page{" "}
        <TextStyle variation="subdued">
          <Link
            external={true}
            url={`https://cdn.shopify.com/s/files/1/0070/3666/5911/files/tracking_link_thank_you_page.png?v=1591762200`}
          >
            (see example)
          </Link>
        </TextStyle>
      </p>
    </div>
  );

  const getOrderTrackingPreview = () => {
    switch (orderData.language) {
      case TEMPLATE_LANGUAGE_ENG:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/eng-order_shipment_perview.png?v=1587749557";
      case TEMPLATE_LANGUAGE_PORT:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/por-order_shipment_perview.png?v=1587749558";
      case TEMPLATE_LANGUAGE_SPAN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/spa-order_shipment_perview.png?v=1587749557";
      case TEMPLATE_LANGUAGE_ITAL:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ita-order_shipment_perview.png?v=1587749557";
      case TEMPLATE_LANGUAGE_FREN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/fr-order_shipment_perview.png?v=1587749558";
      case TEMPLATE_LANGUAGE_INDO:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/indo-order_shipment_perview.png?v=1587749557";
      case TEMPLATE_LANGUAGE_GERM:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ger-order_shipment_perview.png?v=1587749558";
      case TEMPLATE_LANGUAGE_ARAB:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/arabic-order_shipment_perview.png?v=1587749557";
      case TEMPLATE_LANGUAGE_TURK:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/turkish-order_shipment_perview.png?v=1587819647";
      case TEMPLATE_LANGUAGE_HEBR:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/hebrew-order_shipment_perview.png?v=1591679011";
      case TEMPLATE_LANGUAGE_DUTCH:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/dutch-order_shipment_perview.png?v=1591677401";
    }
  };

  const OrderShipmentTemplateClick = (enabled: boolean) => {
    if (!phoneData.phone || validateSupportPhoneNo()) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("A valid support phone number must be given");
      return;
    }

    if (!phoneData.countryCode || validateSupportCountryCode()) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("A valid support country code must be given");
      return;
    }

    var params: any = new URLSearchParams();
    params.append("shipmentEnabled", enabled);
    updateOrderSettings(shop.userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          setIsOrderShipmentEnabled(response.settings.shipmentEnabled);
          setToast(
            "Delivery Update message template has been " +
              (enabled ? "ENABLED" : "DISABLED")
          );
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      },
      (err: any) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Something went wrong");
      }
    );
  };

  const orderTrackingTemplateMarkup = (
    <div>
      <Card>
        <Card.Header
          title="Order Shipment with Tracking URL"
          children={
            isOrderShipmentEnabled ? (
              <Button
                onClick={() => {
                  OrderShipmentTemplateClick(false);
                }}
              >
                Disable
              </Button>
            ) : (
              <Button
                primary
                onClick={() => {
                  OrderShipmentTemplateClick(true);
                }}
              >
                Enable
              </Button>
            )
          }
        ></Card.Header>

        <Card.Section>
          <p className="paddingRight6px notranslate messageTemplateBackground">
            {TEMPLATE_DICT[orderData.language]["order_tracking_template"]}
          </p>
          <br />
          <Link external={true} url={getOrderTrackingPreview()}>
            Preview
          </Link>
        </Card.Section>
      </Card>

      <Card sectioned>
        <div className="verticalMarginTextContainer">
          <TextContainer>
            <p>Configure the {"{{tracking_url}}"} to send in the message</p>
          </TextContainer>
        </div>

        <div className="stackVerticalMarginTopAdjustment">
          <Stack vertical={true}>
            <RadioButton
              label={trackingShippingUrl}
              checked={orderData.thankYouPage === false}
              id="tracking1"
              name="shippingurl"
              onChange={() => {
                setOrderData((order) => ({
                  ...order,
                  thankYouPage: false,
                }));
              }}
            />
            <RadioButton
              label={trackingThankyoupageUrl}
              id="tracking2"
              name="thankyoupageurl"
              checked={orderData.thankYouPage === true}
              onChange={() => {
                setOrderData((order) => ({
                  ...order,
                  thankYouPage: true,
                }));
              }}
            />
          </Stack>
        </div>
      </Card>
      <br />
    </div>
  );

  const nudgeCheckoutOptinScrollSection = (
    <div id="nudgeCheckoutOptin_anchor">
      <Card></Card>
    </div>
  );
  // TODO

  const handleOrderChange = (field: string, value: any) => {
    setOrderData((data) => ({ ...data, [field]: value }));
  };
  const handlePhoneChange = (field: string, value: any) => {
    setPhoneData((data) => ({ ...data, [field]: value }));
  };
  const updateSettings = () => {
    if (!phoneData.phone || validateSupportPhoneNo()) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("A valid support phone number must be given");
      return;
    }

    if (!phoneData.countryCode || validateSupportCountryCode()) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("A valid support country code must be given");
      return;
    }

    if (validateFixedCouponCode() !== false) {
      let message = validateFixedCouponCode() || "Invalid coupon code";
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast(message);
      return;
    }

    if (validateDiscountRatio() !== false) {
      let message = validateDiscountRatio() || "Invalid discount value";
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast(message);
      return;
    }

    if (dataChanges.order) {
      let params = new URLSearchParams();
      var data: any = dataChanges.order;

      Object.keys(data).forEach((key) => {
        params.append(key, data[key]);
      });

      updateOrderSettings(shop.userId, params).then((response: any) => {
        if (response.status === "success") {
          setInitialOrderData((data: any) => ({
            ...data,
            ...response.settings,
          }));
          setRefOrderData((data: any) => ({ ...data, ...response.settings }));
          setOrderData((data: any) => ({ ...data, ...response.settings }));
          setShowSuccessToast((successToast: any) => !successToast);
          setToast("Data Saved");
          setDataChanges({ ...dataChanges, order: null });
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      });
    }
    if (dataChanges.phone) {
      let params = new URLSearchParams();
      let data: any = dataChanges.phone;

      Object.keys(data).forEach((key) => {
        params.append(key, data[key]);
      });

      updatePhoneSettings(shop.userId, params).then((response: any) => {
        if (response.status === "success") {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.WHATSAPP_SUPPORT_NUMBER_ADDED,
            eventData: {
              "Country code": phoneData.countryCode,
            },
          });
          setInitialPhoneData((data: any) => ({
            ...data,
            ...response.settings,
          }));
          setRefPhoneData((data: any) => ({ ...data, ...response.settings }));
          setPhoneData((data: any) => ({ ...data, ...response.settings }));
          setShowSuccessToast((successToast: any) => !successToast);
          setToast("Data Saved");
          setDataChanges({ ...dataChanges, phone: null });
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      });
    }
    setHasChanges(false);
  };

  const resetData = () => {
    setHasChanges(false);
    setDataChanges({
      order: null,
      phone: null,
    });
    setRefOrderData(initialOrderData);
    setRefPhoneData(initialPhoneData);
    setOrderData(initialOrderData);
    setPhoneData(initialPhoneData);
  };

  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        content: "Save Settings",
        onAction: updateSettings,
      }}
      discardAction={{
        onAction: () => {
          resetData();
        },
      }}
    />
  ) : null;

  return (
    <Fragment>
      {ctxSaveBarMarkup}
      <Layout.AnnotatedSection
        title="WhatsApp Configuration"
        description={description}
      >
        <Stack vertical>
          <TemplateLanguageSettingMarkup
            language={orderData.language}
            onChange={(val: any) => {
              handleOrderChange("language", val);
            }}
          ></TemplateLanguageSettingMarkup>
          {/* <SupportNumberMarkup
            phoneNumber={phoneData.phone}
            countryCode={phoneData.countryCode}
            onCountryChange={(val: any) => {
              handlePhoneChange("countryCode", val);
            }}
            onNumberChange={(val: any) => {
              handlePhoneChange("phone", val);
            }}
          ></SupportNumberMarkup> */}
        </Stack>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection
        title="Order Confirmation Template"
        description={order_confirmation_message_template_description}
      >
        <Stack vertical>{orderConfirmationTemplateMarkup}</Stack>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection
        title="Order Shipment Template"
        description={order_shipment_message_template_description}
      >
        <Stack vertical>
          {orderTrackingTemplateMarkup}
          {nudgeCheckoutOptinScrollSection}
        </Stack>
      </Layout.AnnotatedSection>
    </Fragment>
  );
};
export default CrmSettingPage;
