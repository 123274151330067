import { MANUAL_MESSAGING } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

const config = {
  baseURL: MANUAL_MESSAGING.baseUrl,
};
const config2 = {
  baseURL: MANUAL_MESSAGING.accountUrl,
};

export const handleFiltersAbandonedCart = (
  userId: string,
  templateType: any,
  orderId: any,
  name: string,
  orderBy: string,
  desc: boolean,
  pageSize: number,
  pageIndex: number,
  phone: string
) => {
  let query = `&pageSize=${pageSize}&pageNo=${pageIndex}`;
  if (orderBy) {
    query = `&orderBy=${orderBy}&desc=${desc}` + query;
  }
  if (templateType) {
    query = `&tagId=${templateType}` + query;
  }
  if (name) {
    query = `&name=${name}` + query;
  }
  if (orderId) {
    query = `&checkoutId=${orderId}` + query;
  }
  if (phone) {
    query = `&phone=${phone}` + query;
  }
  return httpClient.get(
    MANUAL_MESSAGING.api.ABANDONED_CART.replace("$userId", userId) + `${query}`,
    config2
  );
};

export const getOrdersCrmCount = (userId: any, phone: any) => {
  let query = "";
  if (phone) {
    query += `?phone=${phone}`;
  }
  return httpClient.get(
    MANUAL_MESSAGING.api.OPEN_ORDERS_COUNT.replace("$userId", userId) +
      `${query}`,
    config2
  );
};

export const getAbandonedCartCount = (userId: any, phone: any) => {
  let query = "";
  if (phone) {
    query += `&phone=${phone}`;
  }
  return httpClient.get(
    MANUAL_MESSAGING.api.ABANDONED_CART_COUNT.replace("$userId", userId) +
      `${query}`,
    config2
  );
};

export const handleFiltersOrdersCrm = (
  userId: string, //TODO : ask if sender needs to be same
  templateType: any,
  orderId: any,
  name: string,
  orderBy: string,
  desc: boolean,
  pageSize: number,
  pageIndex: number,
  phone: string
) => {
  let query = `&pageSize=${pageSize}&pageNo=${pageIndex}`;
  if (orderBy) {
    query = `&orderBy=${orderBy}&desc=${desc}` + query;
  }
  if (templateType) {
    query = `&tagId=${templateType}` + query;
  }
  if (name) {
    query = `&name=${name}` + query;
  }
  if (orderId) {
    query = `&orderName=${orderId}` + query;
  }
  if (phone) {
    query = `&phone=${phone}` + query;
  }
  return httpClient.get(
    MANUAL_MESSAGING.api.ORDERS_CRM.replace("$userId", userId) + `${query}`,
    config2
  );
};
export const getAllTemplates = (userId: string) => {
  return httpClient.get(
    MANUAL_MESSAGING.api.GET_ALL_TEMPLATES.replace("$userId", userId),
    config2
  );
};
export const getAllTags = (userId: string) => {
  return httpClient.get(
    MANUAL_MESSAGING.api.GET_ALL_TAGS.replace("$userId", userId),
    config2
  );
};
export const addTagAbCartOrder = (
  userId: string,
  checkoutId: any,
  params: any
) => {
  return httpClient.post(
    MANUAL_MESSAGING.api.ADD_TAG_TO_AB_CART_ORDER.replace(
      "$userId",
      userId
    ).replace("$checkoutId", checkoutId),
    params,
    config2
  );
};
export const addTagOrder = (userId: string, checkoutId: any, params: any) => {
  return httpClient.post(
    MANUAL_MESSAGING.api.ADD_TAG_TO_ORDER.replace("$userId", userId).replace(
      "$checkoutId",
      checkoutId
    ),
    params,
    config2
  );
};
export const settings = (userId: string) => {
  return httpClient.get(
    MANUAL_MESSAGING.api.SETTINGS.replace("$userId", userId),
    config2
  );
};
export const updateSettings = (userId: string, params: any) => {
  return httpClient.post(
    MANUAL_MESSAGING.api.SETTINGS.replace("$userId", userId),
    params,
    config2
  );
};
export const createTemplate = (userId: string, params: any) => {
  return httpClient.post(
    MANUAL_MESSAGING.api.CREATE_TEMPLATE.replace("$userId", userId),
    params,
    config2
  );
};
export const deleteTemplate = (userId: string, templateId: any) => {
  return httpClient.delete(
    MANUAL_MESSAGING.api.UPDATE_DELETE_TEMPLATE.replace(
      "$userId",
      userId
    ).replace("$templateId", templateId),
    config2
  );
};
export const updateTemplate = (
  userId: string,
  templateId: any,
  params: any
) => {
  return httpClient.post(
    MANUAL_MESSAGING.api.UPDATE_DELETE_TEMPLATE.replace(
      "$userId",
      userId
    ).replace("$templateId", templateId),
    params,
    config2
  );
};
export const createTag = (userId: string, params: any) => {
  return httpClient.post(
    MANUAL_MESSAGING.api.CREATE_TAG.replace("$userId", userId),
    params,
    config2
  );
};
export const deleteTag = (userId: string, tagId: any) => {
  return httpClient.delete(
    MANUAL_MESSAGING.api.UPDATE_DELETE_TAG.replace("$userId", userId).replace(
      "$tagId",
      tagId
    ),
    config2
  );
};
export const updateTag = (userId: string, tagId: any, params: any) => {
  return httpClient.post(
    MANUAL_MESSAGING.api.UPDATE_DELETE_TAG.replace("$userId", userId).replace(
      "$tagId",
      tagId
    ),
    params,
    config2
  );
};
export const getDiscountCode = (userId: string, templateId: any) => {
  return httpClient.get(
    MANUAL_MESSAGING.api.GET_DISCOUNT_CODE.replace("$userId", userId).replace(
      "$templateId",
      templateId
    ),
    config2
  );
};
