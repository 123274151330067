import {
  COD_ORDER,
  COD_SERVER_ADDR,
  MESSAGING,
  COD_ORDER_EMAIL,
  COD_ORDER_V2,
} from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

const config = {
  baseURL: MESSAGING.baseUrl,
};

export const fetchCODSettings = (userId: string) => {
  return httpClient.get(
    MESSAGING.api.COD_SETTINGS.replace("$userId", userId),
    config
  );
};

export const updateCODSettings = (userId: string, params: any) => {
  return httpClient.post(
    MESSAGING.api.COD_SETTINGS.replace("$userId", userId),
    params,
    config
  );
};

export const fetchCODOrder = (token: string) => {
  return httpClient.get(COD_ORDER_V2.replace("$token", token), {});
};
export const fetchCODOrderEmail = (token: string) => {
  return httpClient.get(COD_ORDER_EMAIL.replace("$token", token), {});
};

export const updateCODOrderStatus = (token: string, params: any) => {
  return httpClient.post(COD_ORDER.replace("$token", token), params, {});
};
