import { Spinner, List } from "@shopify/polaris";
import { PLAN_ENGAGE_WIX, PLAN_ENTERPRISE } from "../../../constants/constants";
import PlanGreenTick from "../../../assets/icons/planGreenTick.svg";

const NewPlanComponent = (props: any) => {
  const KeyFeaturesComponent = (
    feature: { title: string; featuresList: string[] },
    index: number
  ) => {
    return (
      <div className="detailed_plan_info_new" key={index}>
        <div className="featureHeader">{feature.title}</div>
        <div className="featuresPoints">
          <List>
            {feature.featuresList.map((feat: any, index: any) => {
              return (
                <List.Item key={index}>
                  <span className="features_line_new">
                    <div>
                      <img src={PlanGreenTick} height={20} width={20} />
                    </div>
                    <div>{feat}</div>
                  </span>
                </List.Item>
              );
            })}
          </List>
        </div>
      </div>
    );
  };

  const FeaturesList = (features: any) => {
    return features.map((feature: any, index: any) => {
      return KeyFeaturesComponent(feature, index);
    });
  };

  const ButtonCss = (r_button: boolean) => {
    if (r_button) {
      return `Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle text-center p-0 absolute align-at-top`;
    }
    return ``;
  };

  const renderButtonCSS = (r_button: boolean, isDisable: boolean) => {
    if (r_button) {
      return `Polaris-Button Polaris-Button--primary plan-recommend-btn-new`;
    }
    return `planButtonCssNew ${isDisable ? "plan-btn-disabled-new" : ""}`;
  };

  const showRecommendedPlanButton = () => {
    return (
      <div className={ButtonCss(true)}>
        <button
          onClick={() =>
            [PLAN_ENTERPRISE, PLAN_ENGAGE_WIX].includes(Number(props.planId))
              ? props.contactUs()
              : props.handleUpdatePlan(props.planId, props.page)
          }
          className={`${renderButtonCSS(true, true)} ${
            props.currentPlanId === props.planId && !props.oldGrowthUser? "pointerNone" : ""
          }`}
          type="button"
          disabled={true}
        >
          <span className="Polaris-Button__Content">
            <span className="Polaris-Button__Text planCardBadge">
              {props.planDetails.planName}
            </span>
          </span>
          {props.isLoading && props.selectedPlanId === props.planId && (
            <span className="ml-1">
              <Spinner size="small" />
            </span>
          )}
        </button>
      </div>
    );
  };

  return (
    <div className={props.page === "Plans" ? 'planCardNew' : 'planCardW35'}>
      <div className="Polaris-Card plan-rec-card">
        <div className="Polaris-Card__Header">
          <div className="Polaris-Heading card-heading mt-2"></div>
        </div>
        <div className="iconCSSNew">
          <img
            src={props.planDetails.icon}
            alt="profile"
            width="65px"
            height="65px"
          />
        </div>
        <div className="cardHeightNew">
          {props.planDetails.currency ? (
            <div className="plan_price_1_new">
              {props.planDetails.currency}
              {props.planDetails.price}
              <span className="plan_price_month_1">
                /{props.planDetails.sub_interval}
              </span>
            </div>
          ) : (
            <p className="plan_price_1_noPrice">{props.planDetails.price}</p>
          )}
          {props.planDetails.additionalCharges ? (
            <div className="additionalCharges">
              <p>{props.planDetails.additionalCharges}</p>
            </div>
          ) : null}
          <div className="planBottomDivNew">
            <button
              onClick={() => props.handleUpdatePlan(props.planId, props.page)}
              className={`${renderButtonCSS(
                false,
                props.currentPlanId === props.planId && !props.oldGrowthUser
              )}`}
              type="button"
              disabled={props.currentPlanId === props.planId && !props.oldGrowthUser}
            >
              <span className="Polaris-Button__Content">
                <span className="Polaris-Button__Text">
                  {props.currentPlanId === props.planId && !props.oldGrowthUser
                    ? "Subscribed"
                    : "Select Plan"}
                </span>
              </span>
              {props.isLoading && props.selectedPlanId === props.planId && (
                <span className="ml-1">
                  <Spinner size="small" />
                </span>
              )}
            </button>
          </div>
          <div className="planFreePlus">
            {props.planDetails.included}
          </div>
          <div className="featureListContainer">{FeaturesList(props?.planDetails?.key_features)}</div>
        </div>
        {props.planDetails.catch_line && (
          <div className="catchLineCSSNew">
            {props.planDetails.catch_line}
            <div className="tc-icon">*T&C</div>
          </div>
        )}

        {showRecommendedPlanButton()}

        <div className="planBottomNoteContainer">
          {props.planDetails.note && (
            <span
              className="planBottomNote"
              dangerouslySetInnerHTML={{
                __html: props.planDetails.note,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewPlanComponent;
