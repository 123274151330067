import { Stack, TextContainer, Checkbox, TextStyle } from "@shopify/polaris";
import { useEffect, useState } from "react";
import {
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_DUTCH,
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_TURK,
} from "../constants/constants";

const OptinCheckboxesMarkup = (props: any) => {
  const [language, setLanguage] = useState(props.language);
  const [fields, setFields] = useState<any>({
    ADD_TO_CART: false,
    BUY_NOW: false,
    LAND_ON_CART: false,
    CLICK_CHECKOUT: false,
  });
  const [refFields, setRefFields] = useState<any>({
    ADD_TO_CART: false,
    BUY_NOW: false,
    LAND_ON_CART: false,
    CLICK_CHECKOUT: false,
  });
  const [initialData, setInitialData] = useState<any>({
    ADD_TO_CART: false,
    BUY_NOW: false,
    LAND_ON_CART: false,
    CLICK_CHECKOUT: false,
  });
  const [updatedData, setUpdatedData] = useState<string[]>();

  useEffect(() => {
    setLanguage(props.language);
  }, [props.language]);

  useEffect(() => {
    const setPlaces = (places: any) => {
      for (let i = 0; i < places.length; i++) {
        referenceObj(places[i], true);
      }

      for (let key in fields) {
        let exists = places.includes(key);

        if (!exists) {
          referenceObj(key, false);
        }
      }
    };
    setPlaces(props.places);
  }, [props.places]);

  useEffect(() => {
    if (props.allPlacesUnchecked) {
      setRefFields(initialData);
    }
  }, [props.allPlacesUnchecked]);

  useEffect(() => {
    setUpdatedData([]);
    if (!haveSameData(fields, refFields)) {
      var filtered = Object.keys(fields).filter((key) => {
        return fields[key];
      });
      setUpdatedData(filtered);
      props.onPlaceChange(filtered);
    } else {
      props.setAllPlacesUnchecked(false);
    }
  }, [fields]);

  const referenceObj = (name: string, value: boolean) => {
    setRefFields((refFields: any) => ({ ...refFields, [name]: value }));
    setFields((fields: any) => ({ ...fields, [name]: value }));
  };

  const haveSameData = (obj1: any, obj2: any) => {
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;

    if (obj1Length === obj2Length) {
      return Object.keys(obj1).every(
        (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
      );
    }
    return false;
  };

  const toggleOptinBehaviour = (name: string, value: boolean) => {
    props.setErrorToast({ show: false, message: "" });
    if (value == false) {
      var filtered = Object.keys(fields).filter((key) => {
        return fields[key];
      });
      if (filtered.length > 1) {
        setFields((field: any) => ({ ...field, [name]: value }));
      } else {
        props.showErrorToast(
          "Since Storefront opt-in widget is enabled, at least one display option needs to be selected"
        );
      }
    } else {
      setFields((field: any) => ({ ...field, [name]: value }));
    }
  };

  const getThankYouPageOptinWidgetPreview = () => {
    switch (language) {
      case TEMPLATE_LANGUAGE_ENG:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_ENG.png?1570";
      case TEMPLATE_LANGUAGE_PORT:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_POR.png?1570";
      case TEMPLATE_LANGUAGE_SPAN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_SPA.png?1570";
      case TEMPLATE_LANGUAGE_ITAL:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_ITA.png?1570";
      case TEMPLATE_LANGUAGE_FREN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/FR_-_thank_you_page_widget.png?v=1582884364";
      case TEMPLATE_LANGUAGE_INDO:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_IDA_updated.png?v=1587802222";
      case TEMPLATE_LANGUAGE_GERM:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/GER_-_thank_you_page_widget.png?v=1582884364";
      case TEMPLATE_LANGUAGE_ARAB:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_ARABIC.png?v=1586931260";
      case TEMPLATE_LANGUAGE_TURK:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_TURKISH.png?v=1587874601";
      case TEMPLATE_LANGUAGE_HEBR:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_HEBREW.png?v=1591681715";
      case TEMPLATE_LANGUAGE_DUTCH:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_DUTCH.png?v=1591681715";
    }
  };

  const getStoreOptinWidgetPreview = () => {
    switch (language) {
      case TEMPLATE_LANGUAGE_ENG:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ThankYouOptinGuide.png?v=1672148065";
      case TEMPLATE_LANGUAGE_PORT:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_POR.png?1562";
      case TEMPLATE_LANGUAGE_SPAN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_SPA.png?1562";
      case TEMPLATE_LANGUAGE_ITAL:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_ITA.png?1566";
      case TEMPLATE_LANGUAGE_FREN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/FR_-_optin_widget.png?v=1582878068";
      case TEMPLATE_LANGUAGE_INDO:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_IDA_-_updated.png?v=1587802221";
      case TEMPLATE_LANGUAGE_GERM:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/GER_-_optin_widget.png?v=1582883329";
      case TEMPLATE_LANGUAGE_ARAB:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_ARABIC.png?v=1586931259";
      case TEMPLATE_LANGUAGE_TURK:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_TURKISH.png?v=1587874600";
      case TEMPLATE_LANGUAGE_HEBR:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_HEBREW.png?v=1591681714";
      case TEMPLATE_LANGUAGE_DUTCH:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_DUTCH.png?v=1591681714";
    }
  };

  return (
    <Stack vertical>
      <TextContainer>
        <TextStyle variation="strong">
          {"Events for Displaying Storefront opt-in widget:"}
        </TextStyle>
      </TextContainer>

      <Stack alignment="center" spacing="extraTight" distribution="leading">
        <Checkbox
          label={"Clicking “Add to Cart” button in Product page"}
          checked={fields.ADD_TO_CART}
          disabled={!props.isStoreFrontOptinEnabled}
          onChange={(value) => toggleOptinBehaviour("ADD_TO_CART", value)}
        />
      </Stack>

      <Stack alignment="center" spacing="extraTight" distribution="leading">
        <Checkbox
          label={"Clicking “Buy Now” button in Product page"}
          checked={fields.BUY_NOW}
          disabled={!props.isStoreFrontOptinEnabled}
          onChange={(value) => toggleOptinBehaviour("BUY_NOW", value)}
        />
      </Stack>

      <Stack alignment="center" spacing="extraTight" distribution="leading">
        <Checkbox
          label={"Landing on Cart page "}
          checked={fields.LAND_ON_CART}
          disabled={!props.isStoreFrontOptinEnabled}
          onChange={(value) => toggleOptinBehaviour("LAND_ON_CART", value)}
        />
      </Stack>

      <Stack alignment="center" spacing="extraTight" distribution="leading">
        <Checkbox
          label={"Clicking “Checkout” button in Cart page "}
          checked={fields.CLICK_CHECKOUT}
          disabled={!props.isStoreFrontOptinEnabled}
          onChange={(value) => toggleOptinBehaviour("CLICK_CHECKOUT", value)}
        />
      </Stack>

      {/* <a
        className='Polaris-Link Polaris-Link-Updated-Color strong'
        href={getStoreOptinWidgetPreview()}
        data-polaris-unstyled='true'
        target='_blank'
        rel='noopener noreferrer'
      >
        Preview opt-in widget
      </a>
      <a
        className='Polaris-Link Polaris-Link-Updated-Color strong'
        href={getThankYouPageOptinWidgetPreview()}
        data-polaris-unstyled='true'
        target='_blank'
        rel='noopener noreferrer'
      >
        Preview thank you page widget
      </a> */}
    </Stack>
  );
};
export default OptinCheckboxesMarkup;
