import { useEffect, useState } from 'react';
import { Page, Button, Toast, Layout } from '@shopify/polaris';
import {
  PLAN_OLD_FREE,
  PLAN_OLD_PRO,
  PLAN_OLD_ULTIMATE,
  PLAN_NEW_FREE,
  PLAN_SMALL_BUSINESS,
  PLAN_ENTERPRISE,
  TermsAndConditions,
  PLAN_ENGAGE_WIX,
  PLATFORM,
  PLAN_ENGAGE_PLUS,
} from '../../../constants/constants';
import './plans.scss';

import { getPlans, updatePlan } from '../../../redux/actions/pricing';
import { useLocation, useHistory } from 'react-router-dom';
import {
  ENGAGE_WIX_PLAN_INFO,
  recommendedPlans,
  recommendedPlansNew,
} from '../../../constants/Pricing/Plans/constants';
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from '../../../utils/clevertapUtils';
import ContactUsModal from '../FAQ/ContactUsModal/ContactusModal';
import { sendCleverTapEvent_planUpdate } from '../../../common/helperFunctions';
import NewPlanComponent from './NewPlanComponent';
import { useShopStore } from '../../../hooks/shopStore';

const Plans = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [toast, setToast] = useState({ show: false, message: '' });
  const [errorToast, setErrorToast] = useState({ show: false, message: '' });
  const [currentPlanId, setCurrentPlanId] = useState(shopDetailsData?.planId);
  const [selectedPlanId, setSelectedPlanId] = useState(
    props.planId ? props.planId : shopDetailsData?.planId
  );
  const [availablePlans, setAvailablePlans] =
    useState<(typeof recommendedPlans)[keyof typeof recommendedPlans]>();
  const location = useLocation();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const queryParam = new URLSearchParams(location.search);
  const queryParamPlanId = queryParam.get('id');

  const [isLoading, setIsLoading] = useState(false);
  const [confirmation, setConfirmation] = useState({
    show: false,
    plan: 0,
  });

  let shop = shopDetailsData;

  useEffect(() => {
    const fetchPlans = () => {
      // Update planinfo based on API response
      getPlans(props.userId).then(
        (response: any) => {
          if (response.status === 'success') {
            let plans = response.plans.filter((p: any) => {
              return p.allowedPlatforms.split(',').includes(shop.platform);
            });

            let recommendedPlansPlatform: any = [];
            if (props?.page && props.page !== 'Plans') {
              recommendedPlansPlatform = [recommendedPlansNew[1]];
            } else if (shop.platform === PLATFORM.WIX) {
              recommendedPlansPlatform = [
                {
                  ...recommendedPlansNew[1],
                  planId: PLAN_ENGAGE_WIX.toString(),
                },
                {
                  ...recommendedPlansNew[2],
                  planId: PLAN_ENGAGE_PLUS.toString(),
                },
              ];
            } else {
              recommendedPlansPlatform = recommendedPlansNew;
            }

            // Update plan name if diffrent at DB
            recommendedPlansPlatform.forEach((rcmpNew: any) => {
              let tmpPlansData = plans.filter(
                (p: any) => p.id === parseInt(rcmpNew.planId)
              )[0];
              if (tmpPlansData && tmpPlansData.name !== rcmpNew.planName)
                rcmpNew.planName = tmpPlansData.name;
            });
            setAvailablePlans(recommendedPlansPlatform);
          } else {
            toggleErrorToast(response);
          }
        },
        (err) => {}
      );
    };
    setCurrentPlanId(shopDetailsData?.planId);
    fetchPlans();
    //eslint-disable-next-line
  }, [props.planId, props.userId]);

  useEffect(() => {
    if (queryParam.has('id')) {
      let planId: string = queryParam.get('id') || '';
      localStorage.setItem('currentPlan', planId);
      setCurrentPlanId(planId);
      setToast({ show: true, message: 'Plan Activated Successfully' });
      queryParam.delete('id');
      history.replace({
        search: queryParam.toString(),
      });
      props.setPlanId(currentPlanId);
      let callingPath = localStorage.getItem('callingPath');
      if (callingPath) history.push(callingPath);
      window.location.reload();
    }
    //eslint-disable-next-line
  }, [queryParamPlanId]);

  useEffect(() => {
    // To DO update based on new plans
    if ([PLAN_OLD_FREE, PLAN_NEW_FREE].includes(Number(currentPlanId))) {
      props.setShowUpgradePlanDiv(true);
    } else {
      props.setShowUpgradePlanDiv(false);
    }
    //eslint-disable-next-line
  }, [currentPlanId]);

  const handleUpdatePlan = (planId: number, page: any) => {
    if (Number(currentPlanId) === planId) return null;
    clevertapEventPush({
      eventId: CT_EVENT_IDS.SUBSCRIBE_CLICKED,
      eventData: {
        'Upgrading to': planId,
        page: page,
      },
    });
    setSelectedPlanId(planId);
    //setConfirmation({ show: true, plan: planId }); // to open popup
    activatePlan(planId);
  };

  useEffect(() => {
    if (props.upgradePlanButton === true) {
      const plan = recommendedPlansNew[1];
      if (plan && plan?.planId) handleUpdatePlan(Number(plan?.planId), 'plans');
    }
    //eslint-disable-next-line
  }, [props.upgradePlanButton]);

  const contactUs = () => {
    setShowModal(true);
  };

  const activatePlan = (planId: number) => {
    let params = {
      from: 'pricing',
      returnUrl: window.location.origin + '/confirmation',
    };
    localStorage.setItem('callingPath', window.location.pathname);
    setIsLoading(true);
    updatePlan(shop.userId, planId.toString(), params).then(
      async (res: any) => {
        if (res.status === 'success' || res === 202) {
          sendCleverTapEvent_planUpdate(planId, shop);
          if (res.charge && res.charge.confirmationUrl) {
            window.open(res.charge.confirmationUrl, '_self');
          } else {
            setToast({ show: true, message: 'Plan Activated Successfully' });
          }
          if (Number(planId) === PLAN_OLD_FREE) {
            localStorage.setItem('currentPlan', planId.toString());
            setCurrentPlanId(planId.toString());
            props.setPlanId(planId.toString());
          }
        } else {
          setErrorToast({ show: true, message: res });
        }
        setIsLoading(false);
        setConfirmation({ show: false, plan: 0 });
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };

  const toggleToast = (message: string) => {
    setToast({
      show: !toast.show,
      message: message,
    });
  };

  const toggleErrorToast = (message: string) => {
    setErrorToast({
      show: !errorToast.show,
      message: message,
    });
  };

  const findSelectedPlan = (planId: number) => {
    if (planId === PLAN_OLD_FREE) return 'Free Plan';
    if (planId === PLAN_OLD_PRO) return 'Pro Plan';
    if (planId === PLAN_OLD_ULTIMATE) return 'Ultimate Plan';
    if (planId === PLAN_NEW_FREE) return 'Free Plan';
    if (planId === PLAN_SMALL_BUSINESS) return 'Growth Plan';
    if (planId === PLAN_ENTERPRISE) return 'Engage Plan';
    if (planId === PLAN_ENGAGE_WIX) return 'Engage Plan';
  };
  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        toggleToast('');
      }}
    />
  ) : null;
  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        toggleErrorToast('');
      }}
    />
  ) : null;

  const getRecommendedPlan = () => {
    const rplans = recommendedPlans[Number(currentPlanId)];
    if (rplans.length) return rplans[1];
    if (
      Number(currentPlanId) == PLAN_OLD_FREE &&
      [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
    ) {
      return recommendedPlans[Number(PLAN_ENGAGE_WIX)][1];
    }
    let temp: any = {};
    return temp;
  };

  const termsConditionsText = () => {
    let termsList = TermsAndConditions.map((term, index) => (
      <li key={index}>{term}</li>
    ));
    return (
      <div className='tc'>
        *T&C
        <br />
        {termsList}
      </div>
    );
  };

  return (
    <Page title=''>
      {toastMarkup}
      {errorToastMarkup}
      {currentPlanId && availablePlans && (
        <div id='PlanSection' className='basic_info_plans'>
          <Layout>
            {availablePlans.map((plan: any, index: any) => {
              return (
                <NewPlanComponent
                  page={props.page || 'Plans'}
                  key={index}
                  index={index}
                  planDetails={plan}
                  planId={plan.planId}
                  selectedPlanId={selectedPlanId}
                  currentPlanId={currentPlanId}
                  isLoading={isLoading}
                  handleUpdatePlan={handleUpdatePlan}
                  contactUs={contactUs}
                  oldGrowthUser={
                    Number(currentPlanId) === PLAN_SMALL_BUSINESS &&
                    !shop.oldUserMigrateToEngage &&
                    !shop.newUser
                      ? true
                      : false
                  }
                />
              );
            })}
          </Layout>
        </div>
      )}

      {confirmation.show ? (
        <>
          <div className='modal-container'>
            <div
              className='Polaris-Modal-Dialog__Container'
              data-polaris-layer='true'
              data-polaris-overlay='true'
            >
              <div
                role='dialog'
                aria-modal='true'
                aria-labelledby='Polarismodal-header2'
                tabIndex={-1}
                className='Polaris-Modal-Dialog focus-visible'
                data-focus-visible-added=''
              >
                <div className='Polaris-Modal-Dialog__Modal modal-container'>
                  <div className='Polaris-Modal-Headers'>
                    <button
                      className='Polaris-Modal-CloseButton close-btn'
                      onClick={() => setConfirmation({ show: false, plan: 0 })}
                      aria-label='Close'
                    >
                      <span className='Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor'>
                        <svg
                          viewBox='0 0 20 20'
                          className='Polaris-Icon__Svg'
                          focusable='false'
                          aria-hidden='true'
                        >
                          <path d='m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414L10 8.586 3.707 2.293a1 1 0 0 0-1.414 1.414L8.586 10l-6.293 6.293a1 1 0 1 0 1.414 1.414L10 11.414l6.293 6.293A.998.998 0 0 0 18 17a.999.999 0 0 0-.293-.707L11.414 10z'></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className='Polaris-Modal__BodyWrapper'>
                    <div
                      className='Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical'
                      data-polaris-scrollable='true'
                    >
                      <section className='Polaris-Modal-Section'>
                        <div className='text-center'>
                          <p className='mt-2 fs-4'>
                            Are you sure you want to switch to
                          </p>
                          <br />
                          <h2 className='fs-1 strong'>
                            {findSelectedPlan(Number(confirmation.plan)) + '?'}
                          </h2>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className='Polaris-Modal-Footer no-border mb-2'>
                    <div className='Polaris-Modal-Footer__FooterContent d-content'>
                      <div className='Polaris-Stack Polaris-Stack--alignmentCenter'>
                        <div className='Polaris-Stack__Item Polaris-Stack__Item--fill'></div>
                        <div className='Polaris-Stack__Item'>
                          <div className='Polaris-ButtonGroup'>
                            <div className='Polaris-ButtonGroup__Item'>
                              <Button
                                destructive
                                outline
                                onClick={() =>
                                  setConfirmation({ show: false, plan: 0 })
                                }
                              >
                                Cancel
                              </Button>
                            </div>
                            <div className='Polaris-ButtonGroup__Item'>
                              <Button
                                primary
                                outline
                                loading={isLoading}
                                onClick={() => activatePlan(confirmation.plan)}
                              >
                                Continue
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='Polaris-Backdrop'></div>
        </>
      ) : null}
      {props.tc && termsConditionsText()}
      {showModal && (
        <ContactUsModal
          showModal={showModal}
          setShowModal={setShowModal}
          secondarySheet={true}
        />
      )}
    </Page>
  );
};

export default Plans;
