// @ts-nocheck
import { useState, useCallback, useEffect } from 'react';
import { Page, Layout, Tabs, Modal } from '@shopify/polaris';
import Tags from './Tags';
import QuickResponse from './QuickResponse';
import SetWorkingHours from './SetWorkingHours';
import {
  CONFIGURATIONSPAGE,
  SHOP_FEATURES,
} from '../../../constants/constants';
import WINudgeScreen from '../NudgeScreen';
import WabaNotProcured from '../Chat/WabaNotProcured';
import { useUsersContext } from '../../../context/usersContext';
import GuideModal from '../../../common/GuideModal';
import obj from '../../../content/AgentAssist/OverlaysContent/en.json';
import TooltipOverlay from '../../../common/Tooltip';
import { shopFeaturesFromStoreData } from '../../../utils/helper';
import { PlayCircleMajor } from '@shopify/polaris-icons';
import { useShopStore } from '../../../hooks/shopStore';

function Configurations(props: any) {
  const {
    toastMarkup,
    errorToastMarkup,
    showErrorToast,
    showToast,
  } = useUsersContext();
  const { shopDetailsData, agentPhone } = useShopStore();
  const [openTutorial, setOpenTutorial] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [showNudgeScreen, setShowNudgeScreen] = useState(false);
  const [showWabaNotProcuredScreen, setShowWabaNotProcuredScreen] =
    useState(false);
  const currentPlanId = shopDetailsData?.planId;
  useEffect(() => {
    if (shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.AGENT_ASSIST)) {
      setShowNudgeScreen(false);
    } else {
      setShowNudgeScreen(true);
    }
  }, [currentPlanId]);
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelectedTab(selectedTabIndex),
    []
  );

  useEffect(() => {
    try {
      let agent = agentPhone;
      if (agent) {
        setShowWabaNotProcuredScreen(false);
      }
    } catch (err) {
      console.log(err);
      setShowWabaNotProcuredScreen(true);
    }
  }, []);

  const runFunc = async (func: Function, params: any, successMessage: any) => {
    const res = await func(agentPhone, params);
    if (res.error) {
      if (res.error.message) {
        showErrorToast(res.error.message);
      } else {
        showErrorToast(res.error);
      }
    } else {
      showToast(successMessage);
    }
  };

  const tabContent = [
    <Tags runFunc={runFunc} />,
    <QuickResponse runFunc={runFunc} />,
    <SetWorkingHours runFunc={runFunc} />,
  ];

  const renderContent = () => {
    let totalTabs = CONFIGURATIONSPAGE.TABS.length;
    let template = tabContent[0];
    for (let i = 0; i <= totalTabs; i++) {
      if (selectedTab === i) {
        template = tabContent[selectedTab];
      }
    }
    return template;
  };

  return showNudgeScreen ? (
    <WINudgeScreen />
  ) : showWabaNotProcuredScreen ? (
    <WabaNotProcured from='config' />
  ) : (
    <>
      <Modal
        open={openTutorial}
        sectioned={true}
        title={'Tutorial video'}
        onClose={() => {
          setOpenTutorial(false);
          document.body.classList.remove('tutorial-modal');
        }}
      >
        <iframe
          id='ytplayer'
          width='100%'
          height='360'
          allow='fullscreen'
          src={CONFIGURATIONSPAGE.Tutorial_Video}
          frameBorder='0'
          title={'Tutorial Video'}
        ></iframe>
      </Modal>
      <TooltipOverlay btn1Action={() => setOpenGuideModal(true)} obj={obj} />
      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      ) : null}
      <Page
        title='Configuration'
        secondaryActions={[
          {
            content: 'Tutorial video',
            icon: PlayCircleMajor,
            accessibilityLabel: 'Tutorial Video',
            outline: true,
            onAction: () => {
              setOpenTutorial(true);
              document.body.classList.add('tutorial-modal');
            },
          },
        ]}
      >
        <Layout>
          <div className='w-100'>
            {toastMarkup}
            {errorToastMarkup}
            <Tabs
              tabs={CONFIGURATIONSPAGE.TABS}
              selected={selectedTab}
              onSelect={handleTabChange}
            >
              {renderContent()}
            </Tabs>
          </div>
        </Layout>
      </Page>
    </>
  );
}

export default Configurations;
