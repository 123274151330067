import { Stack, TextField, Toast } from "@shopify/polaris";
import { RefObject, useEffect, useState } from "react";
import { options } from "../../../../constants/constants";
import { COUNTRY_CODES } from "../../../../constants/countryCodes";
import { updateAbandonedCartSettings } from "../../../../redux/actions/abandonedCart";
import { updateCODSettings } from "../../../../redux/actions/codSettings";
import {
  updateOrderSettings,
  updatePhoneSettings,
} from "../../../../redux/actions/crmOrders";
import {
  updateOrderCancellationTemplateSettings,
  updatePaymentSuccessfullSettings,
} from "../../../../redux/actions/new-transaction-templates";
import {
  clevertapEventPush,
  constants,
  CT_EVENT_IDS,
} from "../../../../utils/clevertapUtils";
import "./WabaSetup.scss";
import { Select as Select1 } from "@shopify/polaris";
import Select from "react-select";
import getLocation from "../../../../common/Location";

interface WabaSetupProps {
  submitBtnRef: RefObject<HTMLButtonElement>;
  onSubmit: Function;
  userId: any;
}

const WabaSetup = ({ onSubmit, submitBtnRef, userId }: WabaSetupProps) => {
  const [countryCode, setCountryCode] = useState("");
  const [language, setLanguage] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCodePlaceholder, setCountryCodePlaceholder] = useState<any>();

  useEffect(() => {
    initProps();
  }, [countryCode]);

  const initProps = () => {
    if (countryCode == "") {
      let locationData = getLocation();
      if (locationData) {
        setCountryCode(locationData.value);
      }
    }
    setCountryCodePlaceholder(
      COUNTRY_CODES.find((entry) => entry.value === countryCode)
    );
  };

  const [errorToast, setErrorToast] = useState({ show: false, message: "" });

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  submitBtnRef.current &&
    (submitBtnRef.current.onclick = async () => {
      if (!countryCode) return showErrorToast("Country Code field is required");
      if (!phone) return showErrorToast("Phone field is required");
      if (!language) return showErrorToast("Language field is required");

      let phoneSettingsParams = new URLSearchParams();
      phoneSettingsParams.append("countryCode", countryCode);
      phoneSettingsParams.append("phone", phone);

      let params = new URLSearchParams();
      params.append("language", language);
      let isError = false;

      const [
        updatePhoneRes,
        updateAbandonedCartRes,
        updateCODRes,
        updateOrderCancellationTemplateRes,
        updateOrderRes,
        updatePaymentSuccessfullRes,
        ,
      ] = await Promise.all<any>([
        updatePhoneSettings(userId, phoneSettingsParams),
        updateAbandonedCartSettings(userId, params),
        updateCODSettings(userId, params),
        updateOrderCancellationTemplateSettings(userId, params),
        updateOrderSettings(userId, params),
        updatePaymentSuccessfullSettings(userId, params),
      ]);

      if (updatePhoneRes.status === "success") {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.WHATSAPP_SUPPORT_NUMBER_ADDED,
          eventData: {
            "Country code": countryCode,
          },
        });
      } else {
        showErrorToast(updatePhoneRes);
        isError = true;
      }

      if (updateAbandonedCartRes.status === "success") {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_LANGUAGE_CHANGED,
          eventData: {
            "Automated template message name": constants.abcart,
            Language: language,
            "Private waba":
              localStorage.getItem("private_waba") === "true" ? true : false,
          },
        });
      } else {
        showErrorToast(updateAbandonedCartRes);
        isError = true;
      }

      if (updateCODRes.status === "success") {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_LANGUAGE_CHANGED,
          eventData: {
            "Automated template message name": constants.cod,
            Language: language,
            "Private waba":
              localStorage.getItem("private_waba") === "true" ? true : false,
          },
        });
      } else {
        showErrorToast(updateCODRes);
        isError = true;
      }

      if (updateOrderCancellationTemplateRes.status === "success") {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_LANGUAGE_CHANGED,
          eventData: {
            "Automated template message name": constants.order_cancel,
            Language: language,
            "Private waba":
              localStorage.getItem("private_waba") === "true" ? true : false,
          },
        });
      } else {
        showErrorToast(updateOrderCancellationTemplateRes);
        isError = true;
      }

      if (updateOrderRes.status === "success") {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_LANGUAGE_CHANGED,
          eventData: {
            "Automated template message name": constants.order,
            Language: language,
            "Private waba":
              localStorage.getItem("private_waba") === "true" ? true : false,
          },
        });
        clevertapEventPush({
          eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_LANGUAGE_CHANGED,
          eventData: {
            "Automated template message name": constants.shipment,
            Language: language,
            "Private waba":
              localStorage.getItem("private_waba") === "true" ? true : false,
          },
        });
      } else {
        showErrorToast(updateOrderRes);
        isError = true;
      }

      if (updatePaymentSuccessfullRes.status === "success") {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_LANGUAGE_CHANGED,
          eventData: {
            "Automated template message name": constants.payment,
            Language: language,
            "Private waba":
              localStorage.getItem("private_waba") === "true" ? true : false,
          },
        });
      } else {
        showErrorToast(updatePaymentSuccessfullRes);
        isError = true;
      }

      if (!isError) {
        onSubmit({
          countryCode,
          language,
          number: phone,
        });
      }
    });

  const validateCountryCode = () => {
    var cc = /^(?:[1-9]\d{0,5})$/;
    if (!countryCode || countryCode.match(cc)) {
      return false;
    } else if (countryCode && countryCode[0] === "0") {
      return "cannot start with 0";
    } else {
      return "enter a valid country code";
    }
  };

  const validatePhoneNo = () => {
    var phoneno = /^(?:[0-9]\d{0,14})$/;
    if (!phone || phone.match(phoneno)) {
      return false;
    } else {
      return "enter a valid phone number";
    }
  };

  return (
    <div className="mainDiv">
      {errorToastMarkup}
      <div className="inMainDiv">
        <div style={{ marginBottom: -20, marginTop: 20 }}>
          <p>Please enter your WhatsApp support phone number</p>
        </div>
        <Stack spacing="loose">
          <Stack>
            <Select
              options={COUNTRY_CODES}
              value={countryCodePlaceholder}
              onChange={(option: any) => {
                setCountryCode(option?.value);
              }}
              onMenuOpen={() => {
                setCountryCodePlaceholder("");
              }}
              onMenuClose={() => {
                if (countryCodePlaceholder == "") {
                  setCountryCodePlaceholder(
                    COUNTRY_CODES.find((entry) => entry.value === countryCode)
                  );
                }
              }}
            />
            <TextField
              label=""
              value={phone}
              maxLength={15}
              type={"text"}
              placeholder={"Example: 9035026993"}
              error={validatePhoneNo()}
              onChange={(value) => {
                setPhone(value);
              }}
            />
          </Stack>
        </Stack>
      </div>
      <div className="secondMainDiv">
        <div className="secondDiv">
          <p className="secondText">
            Please select the language of your E-commerce website
          </p>
        </div>
        <Stack>
          <Select1
            label=""
            options={options["WABA_LANGUAGE"]}
            value={language}
            onChange={(val) => {
              setLanguage(val);
            }}
          />{" "}
        </Stack>
      </div>
    </div>
  );
};
export default WabaSetup;
