import { NEW_TRANSACTION_TEMPLATES_WHATSAPP } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

let config = {
  baseURL: NEW_TRANSACTION_TEMPLATES_WHATSAPP.baseUrl,
};

export const getPaymentSuccessfullSettings = (userId: string) => {
  return httpClient.get(
    NEW_TRANSACTION_TEMPLATES_WHATSAPP.api.PAYMENT_SUCCESSFUL.replace(
      "$userId",
      userId
    ),
    config
  );
};

export const updatePaymentSuccessfullSettings = (
  userId: string,
  params: any
) => {
  return httpClient.post(
    NEW_TRANSACTION_TEMPLATES_WHATSAPP.api.PAYMENT_SUCCESSFUL.replace(
      "$userId",
      userId
    ),
    params,
    config
  );
};
export const getOrderCancellationSettings = (userId: string) => {
  return httpClient.get(
    NEW_TRANSACTION_TEMPLATES_WHATSAPP.api.ORDER_CANCELLATION.replace(
      "$userId",
      userId
    ),
    config
  );
};

export const updateOrderCancellationTemplateSettings = (
  userId: string,
  params: any
) => {
  return httpClient.post(
    NEW_TRANSACTION_TEMPLATES_WHATSAPP.api.ORDER_CANCELLATION.replace(
      "$userId",
      userId
    ),
    params,
    config
  );
};
