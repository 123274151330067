import {
  Button,
  Card,
  FormLayout,
  Icon,
  Layout,
  Modal,
  Select,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { createTemplate, editTemplate } from "../../../redux/actions/templates";
import {
  ChevronLeftMinor,
  DraftOrdersMajor,
  CircleCancelMajor,
} from "@shopify/polaris-icons";
import { useHistory } from "react-router-dom";
import { handleNewLineCharacters } from "../../../utils/helper";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";

const CreateTemplate = (props: any) => {
  window.scrollTo({ top: 0, behavior: "smooth" });
  const history = useHistory();
  const [activateErrorCheckForName, setActivateErrorCheckForName] =
    useState(false);
  const [activateErrorCheckForContent, setActivateErrorCheckForContent] =
    useState(false);

  const [shop, setShop] = useState({
    id: "",
    url: "",
    userId: "",
  });
  const [initialTemplate, setInitialTemplate] = useState({
    text: "",
    name: "",
    category: "",
    id: "",
  });
  const [selectedTemplate, setSelectedTemplate] = useState({
    text: "",
    name: "",
    category: "",
    id: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [newTemplate, setNewTemplate] = useState({
    text: "",
    name: "",
    category: "",
  });
  const categories = [
    { label: "Select Category", value: "" },
    { label: "Order Template", value: "OTHERS" },
    { label: "Quick Reply", value: "GREETING" },
  ];
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [saveLoading, setSaveLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [action, setAction] = useState("");

  useEffect(() => {
    if (props.location.state) {
      if (props.location.state.template) {
        setInitialTemplate(props.location.state.template);
        setSelectedTemplate(props.location.state.template);
        setIsEdit(true);
      }

      if (props.location.state.shop) {
        setShop(props.location.state.shop);
      }
    } else {
      setIsEdit(false);
    }
  }, [props.location]);

  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const saveTemplate = () => {
    let params = new URLSearchParams();
    let text = isEdit ? selectedTemplate.text : newTemplate.text;
    let category = isEdit ? selectedTemplate.category : newTemplate.category;
    text = handleNewLineCharacters(text);
    params.append("name", isEdit ? selectedTemplate.name : newTemplate.name);
    params.append("category", category);
    params.append("text", text);

    let error = validateForm();
    if (error) {
      showErrorToast(error);
      return;
    } else {
      if (isEdit) {
        setAction("update");
        setOpenConfirmation(true);
      } else {
        createTemplate(shop.userId, params).then(
          (response: any) => {
            if (response.status === "success") {
              showToast("New Template created successfully");
              clevertapEventPush({
                eventId: CT_EVENT_IDS.EXTENSION_TEMPLATE_CREATED,
                eventData: {
                  Type:
                    category === "OTHERS" ? "Order Template" : "Quick Reply",
                },
              });
              setTimeout(() => {
                props.setSelectedTabForTemplate(3);
                history.push({
                  pathname: "/messaging",
                  state: { sourceTab: props.location.state.sourceTab },
                });
              }, 1000);
            } else {
              showErrorToast(response);
            }
          },
          (err) => {
            showErrorToast("Unable to create template");
          }
        );
      }
    }
  };

  const handleConfirmation = () => {
    switch (action) {
      case "update":
        let params = new URLSearchParams();
        let text = isEdit ? selectedTemplate.text : newTemplate.text;
        let category = isEdit
          ? selectedTemplate.category
          : newTemplate.category;
        text = handleNewLineCharacters(text);
        params.append(
          "name",
          isEdit ? selectedTemplate.name : newTemplate.name
        );
        params.append(
          "category",
          isEdit ? selectedTemplate.category : newTemplate.category
        );
        params.append("text", text);
        setSaveLoading(true);
        editTemplate(shop.userId, selectedTemplate.id, params).then(
          (response: any) => {
            if (response.status === "success") {
              showToast("Template edited successfully");
              clevertapEventPush({
                eventId: CT_EVENT_IDS.EXTENSION_TEMPLATE_EDITED,
                eventData: {
                  Type:
                    category === "OTHERS" ? "Order Template" : "Quick Reply",
                },
              });
              setOpenConfirmation(false);
              setTimeout(() => {
                props.setSelectedTabForTemplate(3);
                history.push({
                  pathname: "/messaging",
                  state: { sourceTab: props.location.state.sourceTab },
                });
              }, 1000);
            } else {
              showErrorToast(response);
            }
            setSaveLoading(false);
          },
          (err) => {
            showErrorToast("Something went wrong");
            setSaveLoading(false);
          }
        );
        break;
    }
  };

  const discardTemplate = () => {
    if (isEdit) {
      setSelectedTemplate(initialTemplate);
      setAction("");
      history.push({
        pathname: "/messaging",
        state: { sourceTab: props.location.state.sourceTab },
      });
    } else {
      setNewTemplate({
        text: "",
        name: "",
        category: "",
      });
    }
  };

  const handleChange = (val: any, field: string) => {
    if (!activateErrorCheckForName && field === "name") {
      setActivateErrorCheckForName(true);
    }
    if (!activateErrorCheckForContent && field === "text") {
      setActivateErrorCheckForContent(true);
    }
    return isEdit
      ? setSelectedTemplate((template: any) => ({ ...template, [field]: val }))
      : setNewTemplate((template: any) => ({ ...template, [field]: val }));
  };

  const validateForm = () => {
    let template = isEdit ? selectedTemplate : newTemplate;
    if (!template.name) {
      return "Template name can not be empty";
    } else if (!template.category) {
      return "Please select a category";
    } else if (!template.text) {
      return "Template content can not be empty";
    } else if (validateTemplateName() !== false) {
      return "Template name should be less than 50 characters";
    } else if (validateTemplateContent() !== false) {
      return "Template content should be less than 1000 characters";
    }
    return false;
  };

  const validateTemplateName = () => {
    if (activateErrorCheckForName) {
      let template = isEdit ? selectedTemplate : newTemplate;
      if (template.name.length > 50) {
        return "Template name should be less than 50 characters";
      }
      if (template.name.length <= 0) {
        return "Template name cannot be empty";
      }
    }
    return false;
  };
  const validateTemplateContent = () => {
    if (activateErrorCheckForContent) {
      let template = isEdit ? selectedTemplate : newTemplate;
      if (template.text.length > 1000) {
        return "Template content should be less than 1000 characters";
      }
      if (template.text.length <= 0) {
        return "Template content cannot be empty";
      }
    }
    return false;
  };

  const renderNameMarkup = () =>
    isEdit ? (
      <>
        {isNameEdit ? (
          <>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  label=""
                  value={selectedTemplate.name}
                  onChange={(val) => {
                    handleChange(val, "name");
                  }}
                  placeholder="Give a name to your template"
                  error={validateTemplateName()}
                />
                <button
                  className="Polaris-Button Polaris-Button--plain Polaris-Button--sizeSlim Polaris-Button--iconOnly"
                  type="button"
                  onClick={() => {
                    setIsNameEdit(false);
                  }}
                  title="Close"
                >
                  <span className="Polaris-Button__Content">
                    <span className="Polaris-Button__Icon mt-1">
                      <span className="Polaris-Icon">
                        <Icon source={CircleCancelMajor}></Icon>
                      </span>
                    </span>
                  </span>
                </button>
              </FormLayout.Group>
            </FormLayout>
          </>
        ) : (
          <h1 className="Polaris-Header-Title">
            <span className="mr-2">{selectedTemplate.name}</span>
            <button
              className="Polaris-Button Polaris-Button--plain Polaris-Button--sizeSlim Polaris-Button--iconOnly"
              type="button"
              onClick={() => {
                setIsNameEdit(true);
              }}
              title="Edit Template Name"
            >
              <span className="Polaris-Button__Content">
                <span className="Polaris-Button__Icon">
                  <span className="Polaris-Icon">
                    <Icon source={DraftOrdersMajor}></Icon>
                  </span>
                </span>
              </span>
            </button>
          </h1>
        )}
      </>
    ) : (
      <TextField
        label=""
        value={newTemplate.name}
        onChange={(val) => {
          handleChange(val, "name");
        }}
        placeholder="Give a name to your template"
        error={validateTemplateName()}
      />
    );

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  return (
    <>
      <div className="Polaris-Page">
        {toastMarkup}
        {errorToastMarkup}
        <div className="Polaris-Page-Header Polaris-Page-Header--noBreadcrumbs Polaris-Page-Header--mediumTitle">
          <div className="Polaris-Page-Header__Row">
            <Button
              plain
              monochrome
              removeUnderline
              icon={ChevronLeftMinor}
              size="slim"
              onClick={() => {
                props.setSelectedTabForTemplate(3);
                history.push({
                  pathname: "/messaging",
                  state: { sourceTab: props.location.state.sourceTab },
                });
              }}
            >
              Go Back
            </Button>
          </div>
          <div className="Polaris-Page-Header__Row mt-2">
            <div className="Polaris-Page-Header__TitleWrapper">
              <div>
                <div className="Polaris-Header-Title__TitleAndSubtitleWrapper">
                  {renderNameMarkup()}
                </div>
              </div>
            </div>
            <div className="Polaris-Page-Header__RightAlign">
              <div className="Polaris-Page-Header__PrimaryActionWrapper">
                <div className="template">
                  <Select
                    label=""
                    options={categories}
                    onChange={(val) => handleChange(val, "category")}
                    value={
                      isEdit ? selectedTemplate.category : newTemplate.category
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Polaris-Page__Content">
          <Card title="Your template content" sectioned>
            <Layout>
              <Layout.Section>
                <TextContainer>
                  <TextField
                    label=""
                    placeholder="Enter your content here"
                    value={isEdit ? selectedTemplate.text : newTemplate.text}
                    onChange={(val) => handleChange(val, "text")}
                    multiline={4}
                    error={validateTemplateContent()}
                  />
                </TextContainer>
              </Layout.Section>
              <div className="Polaris-Layout__Section Polaris-Layout__Section--secondary m-auto text-center pt-1">
                <Button
                  primary
                  onClick={() => {
                    saveTemplate();
                  }}
                >
                  {isEdit ? "Update" : "Submit"}
                </Button>
                <button
                  className="Polaris-Button Polaris-Button--outline outline ml-1"
                  type="button"
                  onClick={() => discardTemplate()}
                >
                  <span className="Polaris-Button__Content">
                    <span className="Polaris-Button__Text primary">
                      Discard
                    </span>
                  </span>
                </button>
              </div>
            </Layout>
          </Card>
          <div className="Polaris-TextContainer Polaris-TextContainer--spacingLoose fs-3 text-center my-3">
            <span className="Polaris-TextStyle--variationStrong text-primary">
              Once you add these, the template will be enabled directly
            </span>
          </div>
          <Layout.Section>
            <div
              className="Polaris-Banner Polaris-Banner--statusSuccess Polaris-Banner--withinPage"
              tabIndex={0}
              role="status"
              aria-live="polite"
              aria-labelledby="PolarisBanner2Heading"
              aria-describedby="PolarisBanner2Content"
            >
              <div className="Polaris-Banner__ContentWrapper">
                <div
                  className="Polaris-Banner__Content"
                  id="PolarisBanner2Content"
                >
                  <div className="Polaris-Stack Polaris-Stack--distributionEqualSpacing Polaris-Stack--alignmentCenter">
                    <div className="Polaris-Stack__Item">
                      <p>
                        <strong>Tip:</strong> While creating a template, if you
                        want to display a variable like "order id", e.g "Your
                        order with order ID : 123 is out for delivery"
                      </p>
                      <p className="mt-1">
                        For this, type "Your order with Order ID :
                        &#123;&#123;name&#125;&#125; is out for delivery"
                      </p>
                      <br />
                      <p style={{ color: "red" }}>
                        <span className="Polaris-TextStyle--variationSubdued">
                          <b>Note:</b>{" "}
                        </span>
                        The below listed tags are only for Order Templates and
                        should not be added in Quick Reply templates
                      </p>
                      <p className="mt-1">
                        Similar useful tags can be found below,
                      </p>
                      <br />
                      <div className="d-flex space-evenly">
                        <div className="mr-2">
                          <p>
                            <strong>1. For Orders : </strong>
                          </p>
                          <br />
                          <p>
                            &#123;&#123;cod&#125;&#125; -&gt; describes if its a
                            COD order
                          </p>
                          <p>
                            &#123;&#123;createdOn&#125;&#125; -&gt; timestamp of
                            Order Creation
                          </p>
                          <p>
                            &#123;&#123;currency&#125;&#125; -&gt; Currency of
                            Purchase
                          </p>
                          <p>
                            &#123;&#123;customerName&#125;&#125; -&gt; Customer
                            Name
                          </p>
                          <p>
                            &#123;&#123;discount&#125;&#125; -&gt; Discount
                            Amount
                          </p>
                          <p>
                            &#123;&#123;fulfillmentStatus&#125;&#125; -&gt;
                            Fulfillment Status
                          </p>
                          <p>
                            &#123;&#123;gateway&#125;&#125; -&gt; Payment
                            Gateway
                          </p>
                          <p>&#123;&#123;name&#125;&#125; -&gt; Order Id</p>
                          <p>
                            &#123;&#123;paymentStatus&#125;&#125; -&gt; Payment
                            Status
                          </p>
                          <p>
                            &#123;&#123;phone&#125;&#125; -&gt; Customer contact
                            number
                          </p>
                          <p>
                            &#123;&#123;processingMethod&#125;&#125; -&gt;
                            Payment Processing Method
                          </p>
                          <p>
                            &#123;&#123;status&#125;&#125; -&gt; Order Status
                          </p>
                          <p>
                            &#123;&#123;statusUrl&#125;&#125; -&gt; Order Status
                            URL
                          </p>
                          <p>&#123;&#123;tax&#125;&#125; -&gt; total tax</p>
                          <p>
                            &#123;&#123;token&#125;&#125; -&gt; Order Specific
                            token
                          </p>
                          <p>
                            &#123;&#123;total&#125;&#125; -&gt; total Amount
                          </p>
                          <p>
                            &#123;&#123;trackingNumber&#125;&#125; -&gt;
                            Tracking Number
                          </p>
                          <p>
                            &#123;&#123;trackingUrl&#125;&#125; -&gt; Tracking
                            URL
                          </p>
                        </div>
                        <div>
                          <p>
                            <strong>2. For Checkouts (AbCart) : </strong>
                          </p>
                          <br />
                          <p>
                            &#123;&#123;cancelledOn&#125;&#125; -&gt; timestamp
                            of cancellation
                          </p>
                          <p>
                            &#123;&#123;checkoutUrl&#125;&#125; -&gt; checkout
                            URL
                          </p>
                          <p>
                            &#123;&#123;completedOn&#125;&#125; -&gt; timestamp
                            of completion
                          </p>
                          <p>
                            &#123;&#123;createdOn&#125;&#125; -&gt; timestamp of
                            creation
                          </p>
                          <p>
                            &#123;&#123;currency&#125;&#125; -&gt; currecny of
                            payment
                          </p>
                          <p>
                            &#123;&#123;discount&#125;&#125; -&gt; Discount
                            Amount
                          </p>
                          <p>
                            &#123;&#123;modifiedOn&#125;&#125; -&gt; timestamp
                            of modification
                          </p>
                          <p>&#123;&#123;name&#125;&#125; -&gt; checkout id</p>
                          <p>
                            &#123;&#123;status&#125;&#125; -&gt; checkout status
                          </p>
                          <p>&#123;&#123;tax&#125;&#125; -&gt; tax amount</p>
                          <p>
                            &#123;&#123;total&#125;&#125; -&gt; total Amount
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout.Section>
        </div>
        <Modal
          open={openConfirmation}
          onClose={() => setOpenConfirmation(false)}
          title={`Are you sure you want to ${action}?`}
          primaryAction={{
            content: "Confirm",
            loading: saveLoading,
            onAction: () => handleConfirmation(),
          }}
        >
          <Modal.Section>
            <TextContainer>
              <p>Please click Confirm to proceed</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </div>
    </>
  );
};

export default CreateTemplate;
