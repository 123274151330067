import { useState } from "react";

import AbandonedCartSettingPage from "./Settings";
import PageMarkup from "../../common/PageMarkup";

const AutomatedAbandonedCart = () => {
  const [toast, setToast] = useState();
  const [errorToast, setErrorToast] = useState();
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const tabs = [
    {
      id: "Settings",
      content: "Settings",
      accessibilityLabel: "Settings",
      panelID: "settings",
    },
    // {
    //   id: "Message Logs",
    //   content: "Message Logs",
    //   panelID: "messagelogs",
    // },
  ];

  const tabContent = {
    0: (
      <AbandonedCartSettingPage
        setToast={setToast}
        setErrorToast={setErrorToast}
        setShowSuccessToast={setShowSuccessToast}
        setShowErrorToast={setShowErrorToast}
      ></AbandonedCartSettingPage>
    ),
    // 1: <MessageLogs></MessageLogs>,
    // 2: <AnalyticsPage></AnalyticsPage>
  };

  return (
    <PageMarkup
      title="Automated Abandoned Cart Recovery"
      tutorial
      tutorialSrc="https://www.youtube.com/embed/7vv80dmfSxc"
      tabs={tabs}
      tabContent={tabContent}
      toast={toast}
      errorToast={errorToast}
      showErrorToast={showErrorToast}
      showSuccessToast={showSuccessToast}
    ></PageMarkup>
  );
};
export default AutomatedAbandonedCart;
