// @ts-nocheck
import "../chat.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import ChatInput from "./ChatInput";
import { useUsersContext } from "../../../../context/usersContext";

import { AGENT_ASSIST } from "../../../../constants/constants";
import {
  getWhatsappMessages,
  sendWhatsappMessage,
} from "../../../../redux/actions/chats";
import ChatWindowHeader from "./ChatWindowHeader";
import Chats from "./Chats";
import { Spinner } from "@shopify/polaris";
import HSMTemplateModal from "../../../../common/HsmTemplates/TemplateSection";
import GenericImageToast from "../../../../common/GenericImageToast";
import { useShopStore } from '../../../../hooks/shopStore';

const ChatWindow = (props: any) => {
  const [userId, setUserId] = useState("");
  const {
    currentUser,
    newChatRoomMessage,
    setNewChatRoomMessage,
    agentPhone,
    mobileView,
    fetchedTemplates,
  } = useUsersContext();
  const { shopDetailsData } = useShopStore();
  const [showAttach, setShowAttach] = useState(false);
  const [chatRoomMessages, setChatRoomMessages] = useState({});
  const bottomRef = useRef("");
  const listInnerRef = useRef();
  const [currPage, setCurrPage] = useState(0);
  const [prevPage, setPrevPage] = useState(-1);
  const [last, setLast] = useState(false);
  const [currentChatTimestamp, setCurrentChatTimestamp] = useState(new Date());
  const [isLoading, setLoading] = useState(false);
  const [isHsmTemplateModalOpen, setHsmTemplateModalOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    let shop = shopDetailsData;
    if (shop && shop.userId) {
      setUserId(shop.userId);
    }
  }, []);

  useEffect(() => {
    setChatRoomMessages({});
    setCurrPage(0);
    setPrevPage(-1);
    setLast(false);
  }, [currentUser]);

  const processMessageData = async (data: any) => {
    const processedData = data.map((d: any) => {
      const chatRoomcustomerPhone =
        d.sender === agentPhone ? d.destination : d.sender;
      return {
        fromUser: !d.outgoing,
        message: d.text ? d.text : "",
        messageTime: d.timestamp,
        customerPhone: chatRoomcustomerPhone,
        mediaPath: d.mediaPath ? d.mediaPath : "",
      };
    });
    return processedData;
  };

  useEffect(() => {
    let data = {};
    if (currentUser.customerPhone) {
      const customerPhone = newChatRoomMessage["customerPhone"];
      if (
        chatRoomMessages &&
        chatRoomMessages[customerPhone] &&
        chatRoomMessages[customerPhone].length > 0
      ) {
        data = {
          ...chatRoomMessages,
          [customerPhone]: [
            ...chatRoomMessages[customerPhone],
            newChatRoomMessage,
          ],
        };
      } else {
        data = {
          ...chatRoomMessages,
          [customerPhone]: [newChatRoomMessage],
        };
      }
      setChatRoomMessages(data);
    }
  }, [newChatRoomMessage]);

  const fetchChatRoomMessages = async (pageNo: any, pageSize = 20) => {
    if (currentUser.customerPhone) {
      const whatsappMessages = await getWhatsappMessages(
        agentPhone,
        currentUser.customerPhone,
        pageNo,
        pageSize
      );
      const processedData = await processMessageData(whatsappMessages);
      let data = {};
      if (
        chatRoomMessages &&
        chatRoomMessages[currentUser.customerPhone] &&
        chatRoomMessages[currentUser.customerPhone].length > 0 &&
        pageNo > 0
      ) {
        data = {
          ...chatRoomMessages,
          [currentUser.customerPhone]: [
            ...processedData.reverse(),
            ...chatRoomMessages[currentUser.customerPhone],
          ],
        };
      } else {
        data = {
          [currentUser.customerPhone]: processedData.reverse(),
          ...chatRoomMessages,
        };
      }
      setChatRoomMessages(data);
      return whatsappMessages;
    }
  };

  const submitNewMessage = (message: string) => {
    if (userId && message.trim()) {
      const params = {
        userId: userId,
        destination: currentUser.customerPhone,
        destinationName: currentUser.customerName,
        text: message,
        mediaPath: "",
        mediaName: "",
        mediaType: AGENT_ASSIST["mediaTypes"]["TEXT"],
        senderName: "",
        sender: agentPhone,
        type: AGENT_ASSIST["type"]["TEXT"],
      };
      sendWhatsappMessage(params);
      scrollToLastMsg();
      setNewChatRoomMessage({
        fromUser: false,
        message: message,
        messageTime: new Date(),
        customerPhone: currentUser.customerPhone,
      });
    }
  };

  const scrollToLastMsg = () => {
    try {
      setTimeout(function () {
        if (bottomRef)
          bottomRef?.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (currentUser.customerPhone) {
      const fetchData = async () => {
        setLoading(true);
        const data = await fetchChatRoomMessages(
          prevPage === -1 ? 0 : currPage
        );
        if (data && data.length < 1) setLast(true);
        setPrevPage(prevPage === -1 ? 0 : currPage);
        if (
          currPage === 0 &&
          chatRoomMessages &&
          chatRoomMessages[currentUser.customerPhone] &&
          chatRoomMessages[currentUser.customerPhone].length
        )
          scrollToLastMsg();
        setLoading(false);
      };

      if (!last && prevPage !== currPage) {
        fetchData();
        if (prevPage === -1) setCurrPage(0);
      }
    }
  }, [currPage, prevPage, currentUser]);

  const [scrollheight, setScrollheight] = useState(1);

  const onScroll = () => {
    if (listInnerRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (!last && scrollTop === 0) {
        setTimeout(function () {
          listInnerRef?.current?.scrollTo({
            top: scrollHeight - scrollheight,
            behavior: "smooth",
          });
        }, 100);
        setScrollheight(scrollHeight);
        setCurrPage(currPage + 1);
      }
    }
  };

  const Loader = () => {
    return isLoading ? (
      <div>
        <div>
          <div className="flexCenter chat__spinLoader">
            <Spinner accessibilityLabel="Spinner example" size="large" />
          </div>
        </div>
      </div>
    ) : null;
  };

  return (
    <div className={mobileView.status ? `chat wid100` : `chat wid80`}>
      {!props.userChatSelected && (
        <div className="chat__body">
          <HSMTemplateModal
            fetchedTemplates={fetchedTemplates}
            customerPhone={currentUser.customerPhone}
            userId={userId}
            agentPhone={agentPhone}
            setHsmTemplateModalOpen={setHsmTemplateModalOpen}
            isHsmTemplateModalOpen={isHsmTemplateModalOpen}
            from={"AgentAssist"}
            showSuccess={showSuccess}
            setShowSuccess={setShowSuccess}
          />

          <GenericImageToast
            showSuccess={showSuccess}
            setShowSuccess={setShowSuccess}
            value="Message triggered successfully"
          />
          <ChatWindowHeader
            getRemainingTime={props.getRemainingTime}
            handleOpenUserInfoDetails={props.handleOpenUserInfoDetails}
            setShowUserInfoPanel={props.setShowUserInfoPanel}
            showUserInfoPanel={props.showUserInfoPanel}
            setSelectedTab={props.setSelectedTab}
          />
          <div className="chat__content" onScroll={onScroll} ref={listInnerRef}>
            <Loader />
            {chatRoomMessages[currentUser.customerPhone] &&
            chatRoomMessages[currentUser.customerPhone].length ? (
              <div>
                <Chats
                  chatRoomMessages={chatRoomMessages}
                  setCurrentChatTimestamp={setCurrentChatTimestamp}
                />
              </div>
            ) : null}
            <div ref={bottomRef} />
          </div>
          <footer className="chat__footer">
            {/* <button
              className='chat__scroll-btn'
              aria-label='scroll down'
              onClick={scrollToLastMsg}
            >
              <Icons id='downArrow' />
            </button>*/}
            <ChatInput
              showAttach={showAttach}
              setShowAttach={setShowAttach}
              submitNewMessage={submitNewMessage}
              getRemainingTime={props.getRemainingTime}
              setHsmTemplateModalOpen={setHsmTemplateModalOpen}
            />
          </footer>
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
