import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  ContextualSaveBar,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import {
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_DUTCH,
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_TURK,
  WABA_TEMPLATE_APPROVED,
  WABA_TEMPLATE_REJECTED,
} from "../../constants/constants";
import {
  getAutomatedTemplates,
  getProfileImage,
  saveProfileAboutDetails,
  uploadProfileImage,
} from "../../redux/actions/private-waba";
import WABATemplates from "../../types/PrivateWABA/WABATemplates.type";

const ProfileDetails = (props: any) => {
  const {
    setToast,
    setErrorToast,
    setShowErrorToast,
    setShowSuccessToast,
    shop,
  } = props;
  const [initialData, setInitialData] = useState(props.wabaProfile);
  const [wabaProfile, setWabaProfile] = useState(props.wabaProfile);
  const [profileImage, setProfileImage] = useState<string>();
  const [isProfileImagePresent, setIsProfileImagePresent] = useState<boolean>();
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [ctxBtnLoading, setCtxBtnLoading] = useState(false);
  const [automatedTemplates, setAutomatedTemplates] = useState<WABATemplates[]>(
    []
  );

  useEffect(() => {
    setInitialData(props.wabaProfile);
    setWabaProfile(props.wabaProfile);
    getProfileDetails();
  }, [props.wabaProfile]);

  useEffect(() => {
    // fetchAutomatedTemplates();
  }, []);

  const fetchAutomatedTemplates = () => {
    getAutomatedTemplates(shop.userId).then(
      (response: any) => {
        if (response.status === "success") {
          setAutomatedTemplates(response.templates);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      },
      (err) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Something went wrong");
      }
    );
  };

  const ctxSaveBarMarkup = unsavedChanges ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        onAction: () => saveSettings(),
        loading: ctxBtnLoading,
      }}
      discardAction={{
        onAction: () => {
          setWabaProfile(initialData);
          setUnsavedChanges(false);
        },
      }}
    />
  ) : null;

  const getProfileDetails = () => {
    getProfileImage(props.shop.userId).then(
      (response: any) => {
        if (response.status === "success") {
          setProfileImage(response.image || "");
          setIsProfileImagePresent(response.image ? true : false);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      },
      (err) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Something went wrong");
      }
    );
  };

  const templateStatusDescription = (
    <TextContainer spacing="loose">
      <p>
        Sometimes, automated message templates do not get approved by WhatsApp
        immediately. Until they get approved, messages for that template cannot
        be sent.
      </p>
      <p>You can view the status of message templates here.</p>
      <p>
        Later, you will be able to create your own message templates and apply
        for approval from here.
      </p>
    </TextContainer>
  );

  const getTemplateLanguage = () => {
    switch (wabaProfile.language) {
      case TEMPLATE_LANGUAGE_ENG:
        return "English";
      case TEMPLATE_LANGUAGE_PORT:
        return "Portuguese";
      case TEMPLATE_LANGUAGE_SPAN:
        return "Spanish";
      case TEMPLATE_LANGUAGE_ITAL:
        return "Italian";
      case TEMPLATE_LANGUAGE_FREN:
        return "French";
      case TEMPLATE_LANGUAGE_INDO:
        return "Indonesian";
      case TEMPLATE_LANGUAGE_GERM:
        return "German";
      case TEMPLATE_LANGUAGE_ARAB:
        return "Arabic";
      case TEMPLATE_LANGUAGE_TURK:
        return "Turkish";
      case TEMPLATE_LANGUAGE_HEBR:
        return "Hebrew";
      case TEMPLATE_LANGUAGE_DUTCH:
        return "Dutch";
    }
  };

  const getTemplateStatus = (status: string) => {
    switch (status) {
      case WABA_TEMPLATE_APPROVED:
        return "Approved";
      case WABA_TEMPLATE_REJECTED:
        return <TextStyle variation="negative">Rejected</TextStyle>;
      default:
        return <span className="text-warning">Pending</span>;
    }
  };

  const getTemplates = () => {
    if (automatedTemplates) {
      var templates = [];
      for (var i = 0; i < automatedTemplates.length; i++) {
        var template = automatedTemplates[i];
        templates.push(
          <p>
            {template.type + ": "} {getTemplateStatus(template.status)}
          </p>
        );
      }
      return <Stack vertical>{templates}</Stack>;
    } else {
      return <p>No templates applied yet</p>;
    }
  };

  const templateStatusMarkup = !props.isLoading ? (
    <Card>
      <Card.Section>
        <TextContainer spacing="loose">
          <p>Message templates language: {getTemplateLanguage()}</p>
          {getTemplates()}
        </TextContainer>
      </Card.Section>
      <Card.Section>
        <p>
          <strong>Note: </strong>Only templates which are approved can be
          enabled and used in sending Automated messages.
        </p>
      </Card.Section>
    </Card>
  ) : (
    <Card sectioned>
      <TextContainer>
        <SkeletonDisplayText size="small" />
        <SkeletonBodyText />
      </TextContainer>
    </Card>
  );

  const openMailClient = () => {
    window.open(
      "mailto:support@superlemon.xyz?subject=I%20need%20help%20with%20WhatsApp%20chat%20—%20" +
        props.shop.url,
      "_blank"
    );
  };

  const getUploadButtonClass = () => {
    if (isUploadInProgress) {
      return "Polaris-Button Polaris-Button--disabled Polaris-Button--loading";
    } else {
      return "Polaris-Button";
    }
  };

  const getUploadButtonText = () => {
    if (isUploadInProgress) {
      return (
        <span className="Polaris-Button__Spinner">
          <img
            src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuMjI5IDEuMTczYTkuMjUgOS4yNSAwIDEgMCAxMS42NTUgMTEuNDEyIDEuMjUgMS4yNSAwIDEgMC0yLjQtLjY5OCA2Ljc1IDYuNzUgMCAxIDEtOC41MDYtOC4zMjkgMS4yNSAxLjI1IDAgMSAwLS43NS0yLjM4NXoiIGZpbGw9IiM5MTlFQUIiLz48L3N2Zz4K"
            alt=""
            className="Polaris-Spinner Polaris-Spinner--colorBlack Polaris-Spinner--sizeSmall"
            draggable="false"
            role="status"
            aria-label="Loading"
          />
        </span>
      );
    } else {
      return "Upload image";
    }
  };

  const validatedInputs = () => {
    if (!wabaProfile.url) {
      return "Website link cannot be empty";
    } else if (!wabaProfile.about) {
      return "About cannot be empty";
    } else {
      return false;
    }
  };

  const saveSettings = () => {
    var error = validatedInputs();
    if (error) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast(error);
      return;
    }

    let params = new URLSearchParams();
    params.append("url", wabaProfile.url);
    params.append("about", wabaProfile.about);

    setCtxBtnLoading(true);
    saveProfileAboutDetails(shop.userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          setShowSuccessToast((successToast: any) => !successToast);
          setToast("Saved profile details");
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
        setCtxBtnLoading(false);
        setUnsavedChanges(false);
      },
      (err) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Something went wrong");
        setCtxBtnLoading(false);
        setUnsavedChanges(false);
      }
    );
  };

  const uploadImage = (data: any) => {
    var element: any = document.getElementById("profile-file-upload");
    if (element.files.length > 0) {
      var file = element.files[0];
      const data = new FormData();
      data.append("image", file);
      setIsUploadInProgress(true);
      setIsProfileImagePresent(false);
      uploadProfileImage(props.shop.userId, data).then(
        (response: any) => {
          if (response.status === "success") {
            var is_profile_image_present = response.appName ? true : false;
            setIsProfileImagePresent(is_profile_image_present);
            setShowSuccessToast((successToast: any) => !successToast);
            setToast("Uploaded Image successfully");
            getProfileDetails();
          } else {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(response);
          }
          setIsUploadInProgress(false);
        },
        (err: any) => {
          setIsUploadInProgress(false);
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast("Something went wrong");
        }
      );
    }
  };

  const customerSupportMarkup = !props.isLoading ? (
    <Card sectioned>
      <TextContainer spacing="loose">
        <p>Need help or have any questions?</p>
        <Button onClick={() => openMailClient()}>
          Contact Customer Support
        </Button>
      </TextContainer>
    </Card>
  ) : (
    <Card sectioned>
      <TextContainer>
        <SkeletonDisplayText size="small" />
        <SkeletonBodyText />
      </TextContainer>
    </Card>
  );

  const profileDetailsDescription = (
    <TextContainer spacing="loose">
      <p>
        Customise your WhatsApp sender profile details. These details affect how
        the WhatsApp profile appears to your customers.
      </p>
    </TextContainer>
  );

  const profileImageLink =
    isProfileImagePresent && !wabaProfile.is_upload_in_progress ? (
      <span className="Polaris-Thumbnail Polaris-Thumbnail--sizeLarge">
        <img src={profileImage} alt="Whatsapp Profile Image" />
      </span>
    ) : null;

  const profileImageMarkup = !props.isLoading ? (
    <Card sectioned>
      <TextContainer spacing="loose">
        <Stack vertical>
          <p>WhatsApp profile image</p>
          {profileImageLink}
          <span>
            <label
              className={getUploadButtonClass()}
              htmlFor="profile-file-upload"
            >
              {getUploadButtonText()}
            </label>
            <input
              id="profile-file-upload"
              type="file"
              hidden
              onChange={(val) => uploadImage(val)}
            />
          </span>
        </Stack>
      </TextContainer>
    </Card>
  ) : (
    <Card sectioned>
      <TextContainer>
        <SkeletonDisplayText size="small" />
        <SkeletonBodyText />
      </TextContainer>
    </Card>
  );

  const handleChange = (val: any, field: any) => {
    setWabaProfile((profile: any) => ({ ...profile, [field]: val }));
    setUnsavedChanges(true);
  };

  const profileDetailsMarkup = !props.isLoading ? (
    <Card sectioned>
      <TextField
        label="About"
        value={wabaProfile.about}
        onChange={(val) => handleChange(val, "about")}
      ></TextField>
      <br></br>
      <TextField
        label="Website link"
        value={wabaProfile.url}
        onChange={(val) => handleChange(val, "url")}
        type="url"
      ></TextField>
    </Card>
  ) : (
    <Card sectioned>
      <TextContainer>
        <SkeletonDisplayText size="small" />
        <SkeletonBodyText />
      </TextContainer>
    </Card>
  );

  return (
    <Fragment>
      {ctxSaveBarMarkup}
      <Layout.AnnotatedSection
        title="Message Template Status"
        description={templateStatusDescription}
      >
        <Stack vertical>
          {templateStatusMarkup}
          {customerSupportMarkup}
        </Stack>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title="WhatsApp Sender Profile Details"
        description={profileDetailsDescription}
      >
        <Stack vertical>
          {profileImageMarkup}
          {profileDetailsMarkup}
        </Stack>
      </Layout.AnnotatedSection>
    </Fragment>
  );
};

export default ProfileDetails;
