import {
  Card,
  ButtonGroup,
  Button,
  Stack,
  TextContainer,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import "./planstatus.scss";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CancelSmallMinor } from "@shopify/polaris-icons";
import {
  planStatusDetails,
  TILE,
} from "../../../constants/Pricing/Plans/constants";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";
import { updatePlan } from "../../../redux/actions/pricing";
import { updatePhoneSettings } from "../../../redux/actions/crmOrders";
import { getAgents, updateAgentDetails } from "../../../redux/actions/agents";
import { sendCleverTapEvent_planUpdate } from "../../../common/helperFunctions";
import { useShopStore } from '../../../hooks/shopStore';

const PlanStatus = (props: any) => {
  const [isPremium, setIsPremium] = useState(false);
  const history = useHistory();
  const { shopDetailsData } = useShopStore();
  const location = useLocation();
  const currentPlanId = parseInt(shopDetailsData.planId);
  const shop = shopDetailsData;
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const queryParam = new URLSearchParams(location.search);
  const queryParamPlanId = queryParam.get("id");
  const [agents, setAgents] = useState<any>();
  const [hideTile, setHideTile] = useState(false);

  useEffect(() => {
    getAgents(shop.userId).then(
      (response: any) => {
        setAgents(response.agents);
      },
      (err: any) => {}
    );
  }, []);

  useEffect(() => {
    setIsPremium(planStatusDetails[props.planId].premium);
  }, [props]);

  useEffect(() => {
    if (queryParam.has("id")) {
      let planId: string = queryParam.get("id") || "";
      localStorage.setItem("currentPlan", planId);
      setToast({ show: true, message: "Plan Activated Successfully" });
      queryParam.delete("id");
      history.replace({
        search: queryParam.toString(),
      });
      history.push("/home");
      window.location.reload();
    }
  }, [queryParamPlanId]);

  const handleUpdatePlan = (planId: number, page: any) => {
    if (currentPlanId === planId) return null;
    clevertapEventPush({
      eventId: CT_EVENT_IDS.SUBSCRIBE_CLICKED,
      eventData: {
        "Upgrading to": planId,
        page: page,
      },
    });
    activatePlan(planId);
  };

  const activatePlan = (planId: number) => {
    let params = {
      from: "pricing",
      returnUrl: window.location.origin + "/confirmation",
    };
    updatePlan(shop.userId, planId.toString(), params).then(
      async (res: any) => {
        if (res.status === "success" || res === 202) {
          sendCleverTapEvent_planUpdate(planId, shop);
          if (res.charge && res.charge.confirmationUrl) {
            window.open(res.charge.confirmationUrl, "_self");
          } else {
            setToast({ show: true, message: "Plan Activated Successfully" });
          }
        } else {
          setErrorToast({ show: true, message: res });
        }
      },
      () => {}
    );
  };

  const modifyAgentSettings = () => {
    let agentId = agents ? agents[0].id : -1;
    if (agentId === -1) {
      return;
    }

    let obj: any = {};
    if (agents[0].country_code != props.wabaNumber.slice(0, 2)) {
      obj.countryCode = props.wabaNumber.slice(0, 2);
    }
    if (agents[0].number != props.wabaNumber.slice(2)) {
      obj.number = props.wabaNumber.slice(2);
    }

    var params: any = new URLSearchParams();
    Object.keys(obj).forEach((key) => {
      params.append(key, obj[key]);
    });

    updateAgentDetails(shop.userId, agentId, params).then(
      (response: any) => {},
      (err) => {
        toggleErrorToast("Error while changing chat agent number");
      }
    );
  };

  const toggleToast = (message: string) => {
    setToast({
      show: !toast.show,
      message: message,
    });
  };

  const toggleErrorToast = (message: string) => {
    setErrorToast({
      show: !errorToast.show,
      message: message,
    });
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        toggleToast("");
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        toggleErrorToast("");
      }}
    />
  ) : null;

  const PlanDescription = () => {
    return props.planId === TILE ? (
      props.variation.admin ? (
        <TextStyle variation="subdued">
          You are currently using +{props.primaryNumber} as your primary contact
          number. This number is configured in chat widget and all automated
          templates. Click on the enable now button to setup your Whatsapp
          Business Account as Primary Contact.
        </TextStyle>
      ) : (
        <TextStyle variation="subdued">
          You are currently using +{props.primaryNumber} as your primary agent
          number. Click on the enable now button to setup your Whatsapp Business
          Account as Primary Agent Contact.
        </TextStyle>
      )
    ) : planStatusDetails[props.planId].description2 ? (
      <TextStyle variation="subdued">
        {planStatusDetails[props.planId].description1}
        <b>{planStatusDetails[props.planId].nextPlan}</b>
        {planStatusDetails[props.planId].description2}
      </TextStyle>
    ) : (
      <TextStyle variation="subdued">
        {planStatusDetails[props.planId].description1}
      </TextStyle>
    );
  };

  const PlanStatusText = () => {
    var planStatusText, planStatusColor;
    planStatusColor = planStatusDetails[props.planId]?.color;
    if (!isPremium) {
      planStatusText = "You are currently subscribed to ";
    } else {
      planStatusText = "Congrats, you have been upgraded to ";
    }

    if (props.planId === TILE) {
      planStatusText =
        "Set your Whatsapp Business Number +" +
        String(props.wabaNumber) +
        " as Your Primary Contact";
    }
    return props.planId === TILE ? (
      <TextContainer>
        {planStatusText}
        <br /> <br />
        {PlanDescription()}
      </TextContainer>
    ) : (
      <div className={isPremium ? "leftColumnFull" : "leftColumn"}>
        { planStatusDetails[props.planId]?.title ? 
            <TextContainer>
              <TextStyle variation="strong">
                { planStatusDetails[props.planId].title }
              </TextStyle>
              <br />
              <br />
              {PlanDescription()}
            </TextContainer>
          :
            <TextContainer>
              <TextStyle variation="strong">
                {planStatusText}
                <b
                  className={
                    planStatusDetails[props.planId].color
                      ? "planNameNew"
                      : "planNameOld"
                  }
                >
                  {" "}
                  {planStatusDetails[props.planId].name}{" "}
                </b>
                {planStatusDetails[props.planId].premium
                  ? " premium plan"
                  : " plan"}{" "}
                <br />
              </TextStyle>
              <br />
              {PlanDescription()}
            </TextContainer>
        }
      </div>
    );
  };

  return hideTile ? null : (
    <div
      className={
        planStatusDetails[props.planId]?.color
          ? "planStatusNew"
          : "planStatusOld"
      }
    >
      {toastMarkup}
      {errorToastMarkup}
      <Card>
        <div className={isPremium ? "planColorNew" : "planColorOld"}>
          {props.plaId === TILE && (
            <span className="close">
              <Button
                plain
                monochrome
                icon={CancelSmallMinor}
                onClick={() => {
                  props.setIsPlanStatusVisible(false);
                  localStorage.setItem("planStatus_clicked", "true");
                }}
              ></Button>
            </span>
          )}
          <Card.Section>
            <Stack alignment="center">
              <Stack.Item fill>{PlanStatusText()}</Stack.Item>
              {isPremium || props.planId === TILE ? null : (
                <Stack.Item>
                  <ButtonGroup>
                    <Button
                      primary
                      onClick={() => {
                        handleUpdatePlan(
                          planStatusDetails[props.planId].nextPlanId,
                          "Homepage"
                        );
                      }}
                    >
                      Upgrade Plan
                    </Button>
                    <Button
                      onClick={() => {
                        history.push("/pricing?plan=view");
                      }}
                    >
                      Know More
                    </Button>
                  </ButtonGroup>
                </Stack.Item>
              )}
              {props.planId === TILE ? (
                <Stack.Item>
                  <Button
                    primary
                    onClick={() => {
                      if (props.variation.admin) {
                        let params = new URLSearchParams();
                        params.append(
                          "countryCode",
                          props.wabaNumber.slice(0, 2)
                        );
                        params.append("phone", props.wabaNumber.slice(2));
                        updatePhoneSettings(shop.userId, params);
                      }
                      if (props.variation.agent) {
                        modifyAgentSettings();
                      }
                      clevertapEventPush({
                        eventId: CT_EVENT_IDS.WABA_NUM_SET_TO_PRIMARY_NUM,
                        eventData: {
                          "inbox phone": props.wabaNumber,
                        },
                      });
                      setHideTile(true);
                    }}
                  >
                    Enable Now
                  </Button>
                </Stack.Item>
              ) : null}
            </Stack>
          </Card.Section>
        </div>
      </Card>
    </div>
  );
};

export default PlanStatus;
