import {
  Card,
  Checkbox,
  Layout,
  Link,
  Stack,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import {
  nonOptinMessageKeys,
  nonOptinMessageLearnMoreURL,
} from "../constants/constants";

const NonOptinMessageSettings = (props: any) => {
  const [checked, setChecked] = useState(props.nonOptinMessageEnabled);
  const key = props.confirmation
    ? nonOptinMessageKeys.ORDER_CONFIRMATION
    : props.shipment
    ? nonOptinMessageKeys.ORDER_SHIPMENT
    : nonOptinMessageKeys.DEFAULT;

  useEffect(() => {
    props.handleChange(key, checked);
  }, [checked]);

  const getDescription = () => {
    return (
      <TextContainer>
        <TextStyle variation="subdued">
          {props.nonOptinMessage.description[0]}
        </TextStyle>
        <br /> <br />
        <TextStyle variation="subdued">
          {props.nonOptinMessage.description[1]}
          {"  "}
          <Link url={nonOptinMessageLearnMoreURL} external={true}>
            Learn More
          </Link>
        </TextStyle>
      </TextContainer>
    );
  };

  return (
    <Layout.AnnotatedSection
      title={props.nonOptinMessage.title}
      description={getDescription()}
    >
      <Stack vertical>
        <Card>
          <Card.Section>
            <Checkbox
              label={props.nonOptinMessage.label}
              checked={checked}
              onChange={() => {
                setChecked((prevState: any) => {
                  return !prevState;
                });
              }}
            />
          </Card.Section>
        </Card>
      </Stack>
    </Layout.AnnotatedSection>
  );
};

export default NonOptinMessageSettings;
