import { Page, Modal, Stack, TextContainer } from "@shopify/polaris";
import "./learnMoreModal.scss";

const LearnMoreModal = (props: any) => {
  const closeModal = () => {
    props.setShowModal(false);
  };

  return (
    <Page title="">
      <Modal
        open={props.showModal}
        sectioned={true}
        title={"Phone Number for Whatsapp Business Account "}
        onClose={() => {
          props.setShowModal(false);
        }}
        secondaryActions={[
          {
            content: "Close",
            onAction: () => {
              props.setShowModal(false);
            },
          },
        ]}
      >
        <Modal.Section>
          <div className="basic-learnMore-modal">
            <p>
              Please note that in order to register for Whatsapp Business
              account, you will need a phone number that is not registered on
              any Whatsapp application.{" "}
              <strong>
                It is highly recommended to use a fresh number that has never
                been registered for WhatsApp or WhatsApp Business.
              </strong>
              However if you want to use a phone number that is already
              registered in the Whatsapp or Whatsapp Business application on you
              Android/IOS device, you will need to first delete the application
              & then apply for Whatsapp Business API.
            </p>
            <br />
            <p>You may follow the steps to delete the number.</p>
            <br />
            <p>1. Open your WhatsApp.</p>
            <p>
              {
                "2. Tap on More options > Settings > Account > Delete my account."
              }
            </p>
            <p>3. Enter your phone number and tap on DELETE MY ACCOUNT.</p>
            <br />
            <p>
              Post deletion you can use the number for WABA registration. Please
              note that it can take upto 5 mins to update your phone number
              status on Whatsapp.
            </p>
            <br />
            <p>
              For any assistance reach out to us at{" "}
              <span style={{ color: "blue" }}>support@superlemon.xyz</span>{" "}
            </p>
            <br />
            <p>
              For more details{" "}
              <a
                href="https://developers.facebook.com/docs/whatsapp/phone-numbers/"
                target="_blank" rel="noreferrer"
              >
                click here
              </a>
            </p>
          </div>
        </Modal.Section>
      </Modal>
    </Page>
  );
};

export default LearnMoreModal;
