import { Heading, Link } from "@shopify/polaris";
import { useEffect, useState, useCallback } from "react";
import styles from "./analytics.module.scss";
import LineCharts from "./LineChart";
import DateFilter from "./DateFilter";
import moment from "moment";
import { getSpinWheelAnalyticsCount } from "../../../redux/actions/analytics";
import { ChartActiveProps } from "./common.interface";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";
import { useHistory } from "react-router-dom";
import { shopFeaturesFromStoreData } from "../../../utils/helper";
import { SHOP_FEATURES } from "../../../constants/constants";
import { useShopStore } from '../../../hooks/shopStore';

const lines = [
  {
    dataKey: "spins",
    label: "Total Spins",
    stroke: "#ad7ffd",
  },
  {
    dataKey: "discountShared",
    label: "Discount Shared",
    stroke: "#f2cc52",
  },
  // {
  //   dataKey: 'discountRedeemed',
  //   label: 'Discount Redeemed',
  //   stroke: '#f61613',
  // },
];
const xDataKey = "date";
const disabledLink = "/widget/settings";

type SpingWheelDataProps = {
  date: any;
  spins: number;
  discountShared: number;
  // discountRedeemed:number;
};

type SpinWheelAnalyticsProps = {
  chartStatus: ChartActiveProps;
};

function SpinWheelAnalytics({ chartStatus }: SpinWheelAnalyticsProps) {
  const { shopDetailsData } = useShopStore();
  const [showPicker, setShowPicker] = useState(false);
  const [shopId, setShopId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSpinWheelAnalytics, setIsSpinWheelAnalytics] = useState(false);
  const history = useHistory();

  const [data, setData] = useState([]);
  const togglePopoverActive = useCallback(
    () => setShowPicker((showPicker) => !showPicker),
    []
  );
  let shop = shopDetailsData;

  useEffect(() => {
    if (shop && shop.userId) {
      const start = moment().subtract(6, "days").format("YYYY-MM-DD");
      const end = moment().format("YYYY-MM-DD");
      fetchData(shop, start, end);
      setShopId(shop.id);
      setIsSpinWheelAnalytics(!shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.WIDGETS_RESTRICTED));
    }
  }, []);

  const fetchData = (shop: any, start?: string, end?: string) => {
    getSpinWheelAnalyticsCount(shop.userId, start, end).then((res: any) => {
      if (
        res.status === "success" &&
        res.message &&
        res.message.dayWiseAnalytics
      ) {
        res.message.dayWiseAnalytics.map((item: SpingWheelDataProps) => {
          item.date = moment(item.date).format("YYYY-MM-DD");
          return item;
        });
        setData(res.message.dayWiseAnalytics);
      }
    });
  };

  const handleSubmit = async (filter: any) => {
    const finalStart = moment(filter.startDate).format("YYYY-MM-DD");
    const finalEnd = moment(filter.endDate).format("YYYY-MM-DD");

    setIsSubmitting(true);
    if (shop.userId && finalStart && finalEnd) {
      fetchData(shop, finalStart, finalEnd);
      clevertapEventPush({
        eventId: CT_EVENT_IDS.ANALYTICS_DATE_SELECTED,
        eventData: {
          "Analytics name": "Spin Wheel Analytics",
        },
      });
      setIsSubmitting(false);
      togglePopoverActive();
    }
  };

  return (
    <>
      <div className={styles.analyticContainer}>
        <Heading>Spin Wheel Analytics</Heading>
        <DateFilter
          showPicker={showPicker}
          togglePopoverActive={togglePopoverActive}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </div>
      <div className={styles.lineChartLayoutContainer}>
        <LineCharts
          data={data}
          lines={lines}
          disabled={!chartStatus.spin_wheel || !isSpinWheelAnalytics}
          disabledRedirectLink={disabledLink}
          xDataKey={xDataKey}
          disableChartContent={
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              Currently Disabled.
              <br />
              <span
                onClick={() =>
                  history.push({
                    pathname: `${disabledLink}`,
                    state: { tab: "Optin Widgets" },
                  })
                }
              >
                <Link removeUnderline>Click Here</Link>
              </span>{" "}
              to enable
            </p>
          }
          disabledText={
            <span>
              Spin the wheel feature helps you boost user Opt-ins. In order to
              boost your business via Whatsapp messaging, user Opt-in is
              mandatory. To enable Opt-ins{" "}
              <span
                onClick={() =>
                  history.push({
                    pathname: `${disabledLink}`,
                    state: { tab: "Optin Widgets" },
                  })
                }
              >
                <Link removeUnderline>click here</Link>
              </span>{" "}
              to enable
            </span>
          }
        />
      </div>
    </>
  );
}

export default SpinWheelAnalytics;
