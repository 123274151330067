import CallManIcon from '../../../assets/icons/call_man.svg';
import BotIcon from '../../../assets/icons/bot.svg';
import ClickIcon from '../../../assets/icons/click.svg';
import FileTickIcon from '../../../assets/icons/file_tick.svg';
import SpeakerIcon from '../../../assets/icons/speaker.svg';
import AutoMsgIcon from '../../../assets/icons/automated_msg.svg';
import ChatWidget from '../../../assets/icons/chat-widget.svg';
import ShareWidget from '../../../assets/icons/share-widget.svg';
import AutomatedMessage from '../../../assets/icons/automated-messages.svg';
import SpinTheWheel from '../../../assets/icons/spin-the-wheel.svg';
import MarketingCampaigns from '../../../assets/icons/marketing-campaigns.svg';
import CustomerGroups from '../../../assets/icons/customer-groups.svg';
import WhatsAppInbox from '../../../assets/icons/team-inbox.svg';
import WABA from '../../../assets/icons/waba.svg';
import ChatBot from '../../../assets/icons/chatbot.svg';
import Extension from '../../../assets/icons/extension.svg';
import CRM from '../../../assets/icons/crm.svg';

export const PLAN_FEATURES = [
  {
    icon: ClickIcon,
    heading: 'Custom message templates',
    content:
      'Create and manage multiple message templates to send to your customers.',
  },
  {
    icon: FileTickIcon,
    heading: 'Transparent Pricing',
    content:
      'Clear and transparent pricing based on the number of messages you successfully send.',
  },
  {
    icon: SpeakerIcon,
    heading: 'Marketing Campaign',
    content:
      'Enhance sales, conversions and engagement with proven, tested templates.',
  },
  {
    icon: AutoMsgIcon,
    heading: 'Automated Messages',
    content:
      'Send order confirmation & delivery updates, ask for feedback or reviews, and engage with your paying customers on whatsapp.',
  },
  // {
  //   icon: BotIcon,
  //   heading: 'Whatsapp Bot',
  //   content:
  //     'Get your own custom and engaging chatbots that can double up as your support agent or sales executive.',
  // },
  {
    icon: CallManIcon,
    heading: 'Customer Support',
    content:
      'Talk to your customers via Whatsapp on a web dashboard. Support multiple chat agents.',
  },
];

export const SMALL_BUSINESS_PLAN_LIST = [
  {
    icon: MarketingCampaigns,
    heading: 'Marketing Campaigns',
    content:
      'Communicate with your customers at scale. Do promotion, product launches & offer announcements. ',
    path: '/campaigns',
  },
  {
    icon: CustomerGroups,
    heading: 'Customer Groups',
    content:
      'Create customer groups based on their purchasing behavior. Run targeted campaigns to enhance conversions.',
    path: '/optins',
    tab: 'createUserGroup',
  },
  {
    icon: AutomatedMessage,
    heading: 'Automated Messages',
    content:
      'Fully automated abandoned cart recovery, order confirmation, and delivery update messages sent via WhatsApp-approved SuperLemon WhatsApp business account.',
    path: '/templates',
  },
  {
    icon: SpinTheWheel,
    heading: 'Spin the wheel',
    content:
      'Drive Engagement on your store by Gamifying Offers. Spin-the wheel also helps you collect customer opt-in to boost the success rate of automated messages that get delivered to your customer.',
    path: '/widget/settings',
    tab: 'Optin Widgets',
  },
  {
    icon: WABA,
    heading: 'WhatsApp Business account with Green tick',
    content:
      'Own your own WhatsApp business account with your brand name & logo.',
    path: '/private-waba',
  },
  {
    icon: ChatWidget,
    heading: 'Chat Widget',
    content:
      'Help your customers interact with your support agents via WhatsApp. Choose from a wide range of WhatsApp widgets for your e-commerce store.',
    path: '/widget/settings',
    tab: 'Chat Widgets',
  },
  {
    icon: ShareWidget,
    heading: 'Share Widget',
    content:
      'Enable your store visitors and customers to easily share your products with their friends on WhatsApp.',
    path: '/widget/settings',
    tab: 'Share Widgets',
  },
  {
    icon: Extension,
    heading: 'Whatsapp Support Extension',
    content:
      'Talk to your customers via WhatsApp on a web dashboard. Supports multiple chat agents.',
    path: '/messaging',
    tab: 'home',
  }
];

export const NEW_USER_LIST = [
  {
    icon: ChatWidget,
    heading: 'Chat Widget',
    content:
      'Help your customers interact with your support agents via WhatsApp. Choose from a wide range of WhatsApp widgets for your e-commerce store.',
    path: '/widget/settings',
    tab: 'Chat Widgets',
  },
  {
    icon: ShareWidget,
    heading: 'Share Widget',
    content:
      'Enable your store visitors and customers to easily share your products with their friends on WhatsApp.',
    path: '/widget/settings',
    tab: 'Share Widgets',
  },
  {
    icon: AutomatedMessage,
    heading: 'Automated Messages',
    content:
      'Fully automated abandoned cart recovery, order confirmation, and delivery update messages sent via WhatsApp-approved SuperLemon WhatsApp business account.',
    path: '/templates',
  },
  {
    icon: SpinTheWheel,
    heading: 'Spin the wheel',
    content:
      'Drive Engagement on your store by Gamifying Offers. Spin-the wheel also helps you collect customer opt-in to boost the success rate of automated messages that get delivered to your customer.',
    path: '/widget/settings',
    tab: 'Optin Widgets',
  },
  {
    icon: Extension,
    heading: 'Whatsapp Support Extension',
    content:
      'Talk to your customers via WhatsApp on a web dashboard. Supports multiple chat agents.',
    path: '/messaging',
    tab: 'home',
  },
  {
    icon: WABA,
    heading: 'WhatsApp Business account with Green tick',
    content:
      'Own your own WhatsApp business account with your brand name & logo.',
    path: '/private-waba',
  },
  {
    icon: MarketingCampaigns,
    heading: 'Marketing Campaigns',
    content:
      'Communicate with your customers at scale. Do promotion, product launches & offer announcements. ',
    path: '/campaigns',
  },
  {
    icon: CustomerGroups,
    heading: 'Customer Groups',
    content:
      'Create customer groups based on their purchasing behavior. Run targeted campaigns to enhance conversions.',
    path: '/optins',
    tab: 'createUserGroup',
  },
  // {
  //   icon: CRM,
  //   heading: "Whatsapp CRM",
  //   content:
  //     "Create and manage multiple message templates to send Whatsapp messages to your customers manually - order confirmation, delivery update, ask for a review, COD order confirmation, and more.",
  //   path: "/manual-messaging",
  // }
  // {
  //   icon: ChatBot,
  //   heading: 'Whatsapp Chatbot',
  //   content:
  //     'Get your own custom and engaging chatbots for support, handle FAQs & drive commerce',
  //   path: '/chatbot',
  // },
  {
    icon: WhatsAppInbox,
    heading: 'WhatsApp Inbox',
    content:
      'Manage your customer chats at one place. Prioritize, tag & reply to customer chats from SuperLemon WhatsApp inbox.',
    path: '/chat',
  },
];

export const NEW_FREE_PLAN_FEATURE_LIST = [
  {
    icon: MarketingCampaigns,
    heading: 'Marketing Campaigns',
    content:
      'Communicate with your customers at scale. Do promotion, product launches & offer announcements. ',
    path: '/campaigns',
  },
  {
    icon: CustomerGroups,
    heading: 'Customer Groups',
    content:
      'Create customer groups based on their purchasing behavior. Run targeted campaigns to enhance conversions.',
    path: '/optins',
    tab: 'createUserGroup',
  },
  {
    icon: AutomatedMessage,
    heading: 'Automated Messages',
    content:
      'Fully automated abandoned cart recovery, order confirmation, and delivery update messages sent via WhatsApp-approved SuperLemon WhatsApp business account.',
    path: '/templates',
  },
  {
    icon: SpinTheWheel,
    heading: 'Spin the wheel',
    content:
      'Drive Engagement on your store by Gamifying Offers. Spin-the wheel also helps you collect customer opt-in to boost the success rate of automated messages that get delivered to your customer.',
    path: '/widget/settings',
    tab: 'Optin Widgets',
  },
  {
    icon: WABA,
    heading: 'WhatsApp Business account with Green tick',
    content:
      'Own your own WhatsApp business account with your brand name & logo.',
    path: '/private-waba',
  },
  {
    icon: ChatWidget,
    heading: 'Chat Widget',
    content:
      'Help your customers interact with your support agents via WhatsApp. Choose from a wide range of WhatsApp widgets for your e-commerce store.',
    path: '/widget/settings',
    tab: 'Chat Widgets',
  },
  {
    icon: ShareWidget,
    heading: 'Share Widget',
    content:
      'Enable your store visitors and customers to easily share your products with their friends on WhatsApp.',
    path: '/widget/settings',
    tab: 'Share Widgets',
  },
  {
    icon: Extension,
    heading: 'Whatsapp Support Extension',
    content:
      'Talk to your customers via WhatsApp on a web dashboard. Supports multiple chat agents.',
    path: '/messaging',
    tab: 'home',
  }
];

export const OLD_FREE_PLAN_FEATURE_LIST = [
  {
    icon: AutomatedMessage,
    heading: 'Automated Messages',
    content:
      'Fully automated abandoned cart recovery, order confirmation, and delivery update messages sent via your own WhatsApp business account.',
    path: '/templates',
  },
  {
    icon: SpinTheWheel,
    heading: 'Spin the wheel',
    content:
      'Drive Engagement on your store by Gamifying Offers. Spin-the wheel also helps you collect customer opt-in to boost the success rate of automated messages that get delivered to your customer.',
    path: '/widget/settings',
    tab: 'Optin Widgets',
  },
  {
    icon: ChatWidget,
    heading: 'Chat Widget',
    content:
      'Help your customers interact with your support agents via WhatsApp. Choose from a wide range of WhatsApp widgets for your e-commerce store.',
    path: '/widget/settings',
    tab: 'Chat Widgets',
  },
  {
    icon: ShareWidget,
    heading: 'Share Widget',
    content:
      'Enable your store visitors and customers to easily share your products with their friends on WhatsApp.',
    path: '/widget/settings',
    tab: 'Share Widgets',
  },
];
export const OLD_PRO_PLAN_FEATURE_LIST = [
  {
    icon: AutomatedMessage,
    heading: 'Automated Messages',
    content:
      'Fully automated abandoned cart recovery, order confirmation, and delivery update messages sent via your own WhatsApp business account.',
    path: '/templates',
  },
  {
    icon: WABA,
    heading: 'WhatsApp Business account with Green tick',
    content:
      'Own your own WhatsApp business account with your brand name & logo. We would also help you get a green tick for your Whatsapp Business Account. What are you waiting for?',
    path: '/private-waba',
  },
  {
    icon: MarketingCampaigns,
    heading: 'Marketing Campaigns',
    content:
      'Communicate with your customers at scale. Do promotions, product launches & offer announcements. ',
    path: '/campaigns',
  },
  {
    icon: CustomerGroups,
    heading: 'Customer Groups',
    content:
      'Create customer groups based on their purchasing behavior. Run targeted campaigns to enhance conversions.',
    path: '/optins',
    tab: 'createUserGroup',
  },
];

export const OLD_ULTIMATE_PLAN_FEATURE_LIST = [
  {
    icon: WABA,
    heading: 'WhatsApp Business account with Green tick',
    content:
      'Own your own WhatsApp business account with your brand name & logo. We would also help you get a green tick for your Whatsapp Business Account. What are you waiting for?',
    path: '/private-waba',
  },
  {
    icon: AutomatedMessage,
    heading: 'Automated Messages',
    content:
      'Fully automated abandoned cart recovery, order confirmation, and delivery update messages sent via your own WhatsApp business account.',
    path: '/templates',
  },
  {
    icon: MarketingCampaigns,
    heading: 'Marketing Campaigns',
    content:
      'Communicate with your customers at scale. Do promotions, product launches & offer announcements. ',
    path: '/campaigns',
  },
  {
    icon: CustomerGroups,
    heading: 'Customer Groups',
    content:
      'Create customer groups based on their purchasing behavior. Run targeted campaigns to enhance conversions.',
    path: '/optins',
    tab: 'createUserGroup',
  },
];

export const ENTERPRISE_PLAN_LIST = [
  {
    icon: WhatsAppInbox,
    heading: 'WhatsApp Inbox',
    content:
      'Manage your customer chats at one place. Prioritize, tag & reply to customer chats from SuperLemon WhatsApp inbox.',
    path: '/chat',
  },
  {
    icon: MarketingCampaigns,
    heading: 'Marketing Campaigns',
    content:
      'Communicate with your customers at scale. Do promotion, product launches & offer announcements. ',
    path: '/campaigns',
  },
  {
    icon: CustomerGroups,
    heading: 'Customer Groups',
    content:
      'Create customer groups based on their purchasing behavior. Run targeted campaigns to enhance conversions.',
    path: '/optins',
    tab: 'createUserGroup',
  },
  {
    icon: AutomatedMessage,
    heading: 'Automated Messages',
    content:
      'Fully automated abandoned cart recovery, order confirmation, and delivery update messages sent via WhatsApp-approved SuperLemon WhatsApp business account.',
    path: '/templates',
  },
  {
    icon: SpinTheWheel,
    heading: 'Spin the wheel',
    content:
      'Drive Engagement on your store by Gamifying Offers. Spin-the wheel also helps you collect customer opt-in to boost the success rate of automated messages that get delivered to your customer.',
    path: '/widget/settings',
    tab: 'Optin Widgets',
  },
  {
    icon: WABA,
    heading: 'WhatsApp Business account with Green tick',
    content:
      'Own your own WhatsApp business account with your brand name & logo.',
    path: '/private-waba',
  },
  {
    icon: ChatWidget,
    heading: 'Chat Widget',
    content:
      'Help your customers interact with your support agents via WhatsApp. Choose from a wide range of WhatsApp widgets for your e-commerce store.',
    path: '/widget/settings',
    tab: 'Chat Widgets',
  },
  {
    icon: ShareWidget,
    heading: 'Share Widget',
    content:
      'Enable your store visitors and customers to easily share your products with their friends on WhatsApp.',
    path: '/widget/settings',
    tab: 'Share Widgets',
  },
  {
    icon: Extension,
    heading: 'Whatsapp Support Extension',
    content:
      'Talk to your customers via WhatsApp on a web dashboard. Supports multiple chat agents.',
    path: '/messaging',
    tab: 'home',
  },
  // {
  //   icon: CRM,
  //   heading: "Whatsapp CRM",
  //   content:
  //     "Create and manage multiple message templates to send Whatsapp messages to your customers manually - order confirmation, delivery update, ask for a review, COD order confirmation, and more.",
  //   path: "/manual-messaging",
  // }
  // {
  //   icon: ChatBot,
  //   heading: 'Whatsapp Chatbot',
  //   content:
  //     'Get your own custom and engaging chatbots for support, handle FAQs & drive commerce',
  //   path: '/chatbot',
  // },
];

export const ENGAGE_WIX_FEATURE_LIST = [
  {
    icon: WhatsAppInbox,
    heading: 'WhatsApp Inbox',
    content:
      'Manage your customer chats at one place. Prioritize, tag & reply to customer chats from SuperLemon WhatsApp inbox.',
    path: '/chat',
  },
  {
    icon: MarketingCampaigns,
    heading: 'Marketing Campaigns',
    content:
      'Communicate with your customers at scale. Do promotion, product launches & offer announcements. ',
    path: '/campaigns',
  },
  {
    icon: CustomerGroups,
    heading: 'Customer Groups',
    content:
      'Create customer groups based on their purchasing behavior. Run targeted campaigns to enhance conversions.',
    path: '/optins',
    tab: 'createUserGroup',
  },
  {
    icon: AutomatedMessage,
    heading: 'Automated Messages',
    content:
      'Fully automated abandoned cart recovery, order confirmation, and delivery update messages sent via WhatsApp-approved SuperLemon WhatsApp business account.',
    path: '/templates',
  },
  // {
  //   icon: SpinTheWheel,
  //   heading: "Spin the wheel",
  //   content:
  //     "Drive Engagement on your store by Gamifying Offers. Spin-the wheel also helps you collect customer opt-in to boost the success rate of automated messages that get delivered to your customer.",
  //   path: "/widget/settings",
  //   tab: "Optin Widgets",
  // },
  {
    icon: WABA,
    heading: 'WhatsApp Business account with Green tick',
    content:
      'Own your own WhatsApp business account with your brand name & logo.',
    path: '/private-waba',
  },
  {
    icon: ChatWidget,
    heading: 'Chat Widget',
    content:
      'Help your customers interact with your support agents via WhatsApp. Choose from a wide range of WhatsApp widgets for your e-commerce store.',
    path: '/widget/settings',
    tab: 'Chat Widgets',
  },
  {
    icon: ShareWidget,
    heading: 'Share Widget',
    content:
      'Enable your store visitors and customers to easily share your products with their friends on WhatsApp.',
    path: '/widget/settings',
    tab: 'Share Widgets',
  },
  // {
  //   icon: Extension,
  //   heading: "Whatsapp Support Extension",
  //   content:
  //     "Talk to your customers via WhatsApp on a web dashboard. Supports multiple chat agents.",
  //   path: "/messaging",
  //   tab:"home"
  // },
  // {
  //   icon: CRM,
  //   heading: "Whatsapp CRM",
  //   content:
  //     "Create and manage multiple message templates to send Whatsapp messages to your customers manually - order confirmation, delivery update, ask for a review, COD order confirmation, and more.",
  //   path: "/manual-messaging",
  // }
  // {
  //   icon: ChatBot,
  //   heading: "Whatsapp Chatbot",
  //   content:
  //     "Get your own custom and engaging chatbots for support, handle FAQs & drive commerce",
  //   path: "/chatbot",
  // },
];

export const WIX_OLD_FREE_PLAN_FEATURE_LIST = [
  {
    icon: AutomatedMessage,
    heading: 'Automated Messages',
    content:
      'Fully automated abandoned cart recovery, order confirmation, and delivery update messages sent via your own WhatsApp business account.',
    path: '/templates',
  },
  // {
  //   icon: SpinTheWheel,
  //   heading: "Spin the wheel",
  //   content:
  //     "Drive Engagement on your store by Gamifying Offers. Spin-the wheel also helps you collect customer opt-in to boost the success rate of automated messages that get delivered to your customer.",
  //   path: "/widget/settings",
  //   tab: "Optin Widgets",
  // },
  {
    icon: ChatWidget,
    heading: 'Chat Widget',
    content:
      'Help your customers interact with your support agents via WhatsApp. Choose from a wide range of WhatsApp widgets for your e-commerce store.',
    path: '/widget/settings',
    tab: 'Chat Widgets',
  },
  {
    icon: ShareWidget,
    heading: 'Share Widget',
    content:
      'Enable your store visitors and customers to easily share your products with their friends on WhatsApp.',
    path: '/widget/settings',
    tab: 'Share Widgets',
  },
];

export const ENGAGE_PLUS_FEATURE_LIST = [
  {
    icon: MarketingCampaigns,
    heading: 'Marketing Campaigns',
    content:
      'Communicate with your customers at scale. Do promotion, product launches & offer announcements. ',
    path: '/campaigns',
  },
  {
    icon: CustomerGroups,
    heading: 'Customer Groups',
    content:
      'Create customer groups based on their purchasing behavior. Run targeted campaigns to enhance conversions.',
    path: '/optins',
    tab: 'createUserGroup',
  },
  {
    icon: AutomatedMessage,
    heading: 'Automated Messages',
    content:
      'Fully automated abandoned cart recovery, order confirmation, and delivery update messages sent via WhatsApp-approved SuperLemon WhatsApp business account.',
    path: '/templates',
  },
  {
    icon: SpinTheWheel,
    heading: 'Spin the wheel',
    content:
      'Drive Engagement on your store by Gamifying Offers. Spin-the wheel also helps you collect customer opt-in to boost the success rate of automated messages that get delivered to your customer.',
    path: '/widget/settings',
    tab: 'Optin Widgets',
  },
  {
    icon: WABA,
    heading: 'WhatsApp Business account with Green tick',
    content:
      'Own your own WhatsApp business account with your brand name & logo.',
    path: '/private-waba',
  },
  {
    icon: ChatWidget,
    heading: 'Chat Widget',
    content:
      'Help your customers interact with your support agents via WhatsApp. Choose from a wide range of WhatsApp widgets for your e-commerce store.',
    path: '/widget/settings',
    tab: 'Chat Widgets',
  },
  {
    icon: ShareWidget,
    heading: 'Share Widget',
    content:
      'Enable your store visitors and customers to easily share your products with their friends on WhatsApp.',
    path: '/widget/settings',
    tab: 'Share Widgets',
  },
  {
    icon: Extension,
    heading: 'Whatsapp Support Extension',
    content:
      'Talk to your customers via WhatsApp on a web dashboard. Supports multiple chat agents.',
    path: '/messaging',
    tab: 'home',
  }
];
