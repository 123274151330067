import { FormLayout, TextField, Toast } from "@shopify/polaris";

import { useState } from "react";
import "../../userGroup.scss";
import TextFieldSection from "./TextFieldSection";
import ConditionSet from "./ConditionSet";
import { parameterOption, conditionOption, typeOption } from "../../utils";

const CreateUserGroupModal = (props: any) => {
  const { initialConditionSet, groupData, setGroupData } = props;
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });

  const formValueValidation = (fieldName: string, fieldValue: string) => {
    if (fieldName === "name") {
      if (fieldValue) {
        let regX = /^[A-Za-z0-9_ ]*$/;
        if (!fieldValue.match(regX)) {
          return "Enter valid Group Name";
        } else if (fieldValue.length > 511) {
          return "Limit";
        }
      }
    }
  };

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const AddConditionTemplate = () => {
    if (groupData.conditions.length >= 3) return;
    if (groupData.conditions.length) {
      if (!groupData.conditions[groupData.conditions.length - 1]["param"])
        return setErrorToast({
          show: true,
          message:
            "Please select the parameter before adding new condition set",
        });
      if (!groupData.conditions[groupData.conditions.length - 1]["operation"])
        return setErrorToast({
          show: true,
          message:
            "Please select the condition before adding new condition set",
        });
      if (
        !groupData.conditions[groupData.conditions.length - 1]["conditionValue"]
      )
        return setErrorToast({
          show: true,
          message: "Please enter value before adding new condition set",
        });
    }
    initialConditionSet.index = groupData?.conditions?.length + 1;
    initialConditionSet.operator = "AND";
    groupData.conditions.push({ ...initialConditionSet });
    setGroupData({ ...groupData, conditions: [...groupData.conditions] });
  };

  return (
    <div>
      {toastMarkup}
      {errorToastMarkup}
      <div className="create-user-group">
        {/* <Form onSubmit=''> */}
        <div style={{ width: "50%", marginTop: "-10px" }}>
          <FormLayout.Group>
            <TextFieldSection
              id="name"
              fieldLabel="Group Name*"
              fieldMessage="Please provide the name for Customer Group. Eg. Users with high order value."
              isTooltip={false}
            >
              <TextField
                maxLength={512}
                value={groupData.name}
                name={`name`}
                placeholder="Enter Group Name"
                onChange={(val) => {
                  setGroupData({ ...groupData, name: val });
                }}
                label=""
                type="text"
                error={formValueValidation("name", groupData.name)}
              />
            </TextFieldSection>
          </FormLayout.Group>
        </div>
        <FormLayout.Group>
          <TextFieldSection
            id="setConditions"
            fieldLabel="Set Conditions*"
            fieldMessage="Apply Conditions on various parameters to create Customer Group. Eg. you can select the parameter as Number of orders, Condition as Greater than & Any numerical value as Value."
            isTooltip={false}
            children={""}
          />
        </FormLayout.Group>
        {groupData.conditions.length > 0 &&
          groupData.conditions.map((segment: any, index: any) => (
            <ConditionSet
              key={`condition_set_${index}`}
              index={index}
              groupData={groupData}
              setGroupData={setGroupData}
              parameterOption={parameterOption}
              conditionOption={conditionOption}
              typeOption={typeOption}
            />
          ))}
        {groupData.conditions.length < 3 && (
          <div className="mb-3 pb-1">
            <div
              className={`template Polaris-Layout__Section Polaris-Layout__Section--secondary m-auto text-center w-10 pull-right`}
            >
              <button
                className={
                  "Polaris-Button Polaris-Button--outline Polaris-Button--primary w-50 add-condition-btn pull-right"
                }
                type="button"
                onClick={() => AddConditionTemplate()}
              >
                <span className="Polaris-Button__Content">
                  <span className="Polaris-Button__Text">
                    <div className="d-flex-center">
                      <div>&nbsp;Add Condition</div>
                    </div>
                  </span>
                </span>
              </button>
            </div>
          </div>
        )}
        {/* </Form> */}
      </div>
    </div>
  );
};

export default CreateUserGroupModal;
