import { Heading } from "@shopify/polaris";
import { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { getClickCount } from "../../../redux/actions/analytics";
import styles from "./analytics.module.scss";
import LineCharts from "./LineChart";
import DateFilter from "./DateFilter";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";
import { useShopStore } from '../../../hooks/shopStore';

const lines = [
  {
    dataKey: "chatCount",
    label: "Chat Widget Click",
    stroke: "#ad7ffd",
  },
  {
    dataKey: "shareCount",
    label: "Share Widget Click",
    stroke: "#f2cc52",
  },
];
const disabledLink = "/widget/settings";
const xDataKey = "date";
const chartTitle = "Widget Analytics";

function WidgetAnalytics() {
  const { shopDetailsData } = useShopStore();
  const [showPicker, setShowPicker] = useState(false);
  const [shopId, setShopId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([
    {
      Date: "",
      chatCount: 0,
      shareCount: 0,
    },
  ]);

  const togglePopoverActive = useCallback(
    () => setShowPicker((showPicker) => !showPicker),
    []
  );

  let shop = shopDetailsData;

  useEffect(() => {
    if (shop && shop.userId) {
      const start = moment().subtract(6, "days").format("YYYY-MM-DD");
      const end = moment().format("YYYY-MM-DD");
      fetchData(shop, start, end);
      setShopId(shop.id);
    }
  }, []);

  const fetchData = (shop: any, start?: string, end?: string) => {
    if (shop.userId) {
      getClickCount(shop.userId, start, end).then((res: any) => {
        if (
          res.status === "success" &&
          res.message &&
          res.message.dayWiseAnalytics
        ) {
          setData(res.message.dayWiseAnalytics);
        }
      });
    }
  };

  const handleSubmit = async (filter: any) => {
    const finalStart = moment(filter.startDate).format("YYYY-MM-DD");
    const finalEnd = moment(filter.endDate).format("YYYY-MM-DD");

    setIsSubmitting(true);
    if (shopId && finalStart && finalEnd) {
      fetchData(shop, finalStart, finalEnd);
      clevertapEventPush({
        eventId: CT_EVENT_IDS.ANALYTICS_DATE_SELECTED,
        eventData: {
          "Analytics name": "Widget Analytics",
        },
      });
      setIsSubmitting(false);
      togglePopoverActive();
    }
  };

  return (
    <>
      <div className={styles.analyticContainer}>
        <Heading>Widget Analytics</Heading>
        <DateFilter
          showPicker={showPicker}
          togglePopoverActive={togglePopoverActive}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </div>
      <div className={styles.lineChartLayoutContainer}>
        <LineCharts
          data={data}
          xDataKey={xDataKey}
          lines={lines}
          disabled={false}
          disabledRedirectLink={disabledLink}
          disabledText={
            <span>
              Spin the wheel feature helps you boost user options. In order to
              boost your business via Whatsapp messaging, user Opt-in is
              mandatory. To enable Opt-in a{" "}
              <a href={`${disabledLink}`}><span className="automation__links">click here</span></a>
            </span>
          }
        />
      </div>
    </>
  );
}

export default WidgetAnalytics;
