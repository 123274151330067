import { useEffect, useState } from "react";
import {
  Layout,
  TextContainer,
  TextStyle,
  Heading,
  Icon,
  Link,
} from "@shopify/polaris";
import { SettingsMinor } from "@shopify/polaris-icons";
import Switch from "react-switch";
import {
  COD_TEMPLATE_DICT,
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_DUTCH,
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_TURK,
  TEMPLATE_LANGUAGE_RUSSIAN,
  OPTIN_ENTERPRISE_TEMPLATES,
} from "../../../../constants/constants";
import CashOnDeliveryModal from "./CashOnDeliveryModal";

import {
  fetchCODSettings,
  updateCODSettings,
} from "../../../../redux/actions/codSettings";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../../utils/clevertapUtils";

function CashOnDelivery({
  userId,
  showToast,
  showErrorToast,
  phoneData,
  checkForApproval,
  privateWabaDetails,
  optinEnterpriseTemplates,
}: any) {
  var TEMPLATE_DICT: any = COD_TEMPLATE_DICT;
  const [openCashOnDeliverySettingsModal, setOpenCashOnDeliverySettingsModal] =
    useState(false);
  const [isCodEnabled, setIsCodEnabled] = useState<boolean>(false);
  const [codLanguage, setCodLanguage] = useState<any>("ENGLISH");
  const [cashOnDeliveryData, setcashOnDeliveryData] = useState({});

  useEffect(() => {
    if (userId) {
      getCodData(userId);
    }
  }, [userId]);
  const getCodData = (userId: string) => {
    fetchCODSettings(userId).then((res: any) => {
      if (res.status === "success") {
        if (res.settings.confirmationLanguage === "NONE") {
          res.settings.confirmationLanguage = "ENGLISH";
        }
        if (res.settings.language === "NONE") {
          res.settings.language = "ENGLISH";
        }
        setIsCodEnabled(res.settings.enabled);
        setCodLanguage(res.settings.language);
        setcashOnDeliveryData(res.settings);
      }
    });
  };

  const getCashOnDeliveryPreview = () => {
    switch (codLanguage) {
      case TEMPLATE_LANGUAGE_ENG:
        return "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/eng_cash_on_delivery.png?v=1654670342";
      case TEMPLATE_LANGUAGE_PORT:
        return "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/por_cash_on_delivery.png?v=1654670342";
      case TEMPLATE_LANGUAGE_SPAN:
        return "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/spa_cash_on_delivery.png?v=1654670342";
      case TEMPLATE_LANGUAGE_ITAL:
        return "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/ita_cash_on_delivery.png?v=1654670342";
      case TEMPLATE_LANGUAGE_FREN:
        return "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/fra_cash_on_delivery.png?v=1654670342";
      case TEMPLATE_LANGUAGE_INDO:
        return "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/indo_cash_on_delivery.png?v=1654670342";
      case TEMPLATE_LANGUAGE_GERM:
        return "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/ger_cash_on_delivery.png?v=1654670342";
      case TEMPLATE_LANGUAGE_ARAB:
        return "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/arabic_cash_on_delivery.png?v=1654670342";
      case TEMPLATE_LANGUAGE_TURK:
        return "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/turkish_cash_on_delivery.png?v=1654670342";
      case TEMPLATE_LANGUAGE_HEBR:
        return "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/hebrew_cash_on_delivery.png?v=1654670342";
      case TEMPLATE_LANGUAGE_DUTCH:
        return "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/dutch_cash_on_delivery.png?v=1654670342";
      case TEMPLATE_LANGUAGE_RUSSIAN:
        return "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/russian_cash_on_delivery.png?v=1654670342";
    }
  };

  const enableCashOnDelivery = (enabled: boolean) => {
    if (enabled && !checkForApproval()) return;
    if (
      (!phoneData.phone ||
      !phoneData.countryCode ||
      phoneData.phone.length === 0 ||
      phoneData.countryCode.length === 0) &&
      enabled
    ) {
      showErrorToast(
        "Please add WhatsApp support number by going to Settings"
      );
      return;
    }
    var params: any = new URLSearchParams();
    params.append("enabled", enabled);
    updateCODSettings(userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          setIsCodEnabled(response.settings.enabled);
          showToast(
            "COD message template has been " +
              (enabled ? "ENABLED" : "DISABLED")
          );
          if (enabled) {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_ENABLED,
              eventData: {
                template_name: "Cash on Delivery",
                template_language: codLanguage,
                "Private waba":
                  localStorage.getItem("private_waba") === "true"
                    ? true
                    : false,
              },
            });
          } else {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_DISABLED,
              eventData: {
                template_name: "Cash on Delivery",
                template_language: codLanguage,
                "Private waba":
                  localStorage.getItem("private_waba") === "true"
                    ? true
                    : false,
              },
            });
          }
        } else {
          showErrorToast(response);
        }
      },
      (err: any) => {
        showErrorToast("Something went wrong");
      }
    );
  };

  const cashOnDeliveryMarkup = () => {
    return (
      <>
        <Layout.Section>
          <div className="template-info-container">
            <div className="Polaris-TextContainer Polaris-TextContainer--spacingTight template-info">
              <div className="templateSwitchMobCss">
                <Heading>Cash on Delivery</Heading>
                <Switch
                  onChange={() => enableCashOnDelivery(!isCodEnabled)}
                  checked={isCodEnabled}
                  onColor="#5DC001"
                  onHandleColor="#fff"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 6px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  handleDiameter={30}
                />
              </div>

              <>
                {isCodEnabled ? (
                  <p>
                    Verify and confirm COD orders using WhatsApp. Message is
                    sent immediately after a COD order is placed.
                  </p>
                ) : (
                  <p>
                    COD messages confirm and verify that the customer intends to
                    place an order. It's majorly focused towards saving your
                    shipping costs, and losses due to COD order cancellations.
                  </p>
                )}
                <br />
                {/* {"To learn about the cost of sending messages, view"}
                <Link
                  external={true}
                  url="https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0"
                >
                  {" pricing chart"}
                </Link> */}
              </>
            </div>
            <div className="Polaris-Card w-100">
              <div className="Polaris-Card__Section break-word">
                <TextContainer>
                  <TextStyle>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          optinEnterpriseTemplates && codLanguage === "ENGLISH"
                            ? OPTIN_ENTERPRISE_TEMPLATES.cod_confirmation_template
                            : TEMPLATE_DICT[codLanguage][
                                "cod_confirmation_template"
                              ],
                      }}
                    ></span>
                    <br />
                    <br />
                    <Link
                      external={true}
                      url={getCashOnDeliveryPreview()}
                      onClick={() => {
                        clevertapEventPush({
                          eventId:
                            CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_PREVIEWED,
                          eventData: {
                            template_name: "Cash on Delivery",
                            template_language: codLanguage,
                            "Private waba":
                              localStorage.getItem("private_waba") === "true"
                                ? true
                                : false,
                          },
                        });
                      }}
                    >
                      Preview
                    </Link>
                  </TextStyle>
                </TextContainer>
              </div>

              {isCodEnabled ? (
                <div className="w-100">
                  <div className="Polaris-Page--divider"></div>
                  <div className="Polaris-Card__Section break-word pt-0">
                    <TextContainer>
                      <TextStyle variation="subdued">
                        <span className="Polaris-TextStyle--variationSubdued">
                          <b>Note</b>: If both COD Order Confirmation and
                          regular Order Confirmation templates are enabled, then
                          the regular Order Confirmation message will be sent
                          after your customer confirms a COD order.
                        </span>
                      </TextStyle>
                    </TextContainer>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Layout.Section>
        <div className="template Polaris-Layout__Section Polaris-Layout__Section--secondary m-auto text-center">
          <div className="flex-col w-100">
            <div>
              <button
                className={
                  isCodEnabled
                    ? "Polaris-Button Polaris-Button--primary Polaris-Button--outline w-50"
                    : "Polaris-Button Polaris-Button--outline Polaris-Button--disabled w-50"
                }
                type="button"
                disabled={!isCodEnabled}
                onClick={() => setOpenCashOnDeliverySettingsModal(true)}
              >
                <span className="Polaris-Button__Content">
                  <span className="Polaris-Button__Text">
                    <div className="d-flex-center">
                      <div>
                        <Icon source={SettingsMinor} />
                      </div>
                      <div>&nbsp;Settings</div>
                    </div>
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {userId ? (
        <>
          {cashOnDeliveryMarkup()}
          <CashOnDeliveryModal
            open={openCashOnDeliverySettingsModal}
            closeModal={() => setOpenCashOnDeliverySettingsModal(false)}
            showToast={showToast}
            showErrorToast={showErrorToast}
            setCodLanguage={setCodLanguage}
            cashOnDeliveryData={cashOnDeliveryData}
          />
        </>
      ) : null}
    </>
  );
}

export default CashOnDelivery;
