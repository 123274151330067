import {
  Modal,
  Stack,
  TextContainer,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import WidgetPreview from "./WidgetPreview";

const ChangeWidgetTemplateModal = (props: any) => {
  const [selected_template_id, setselected_template_id] = useState(1);
  const [open, setOpen] = useState(props.open);
  const [toast, setToast] = useState({ show: false, message: "" });

  useEffect(() => {
    setselected_template_id(Number(props.selectedWheelID));
  }, []);

  useEffect(() => {
    setOpen(props.open);
    setselected_template_id(Number(props.selectedWheelID));
  }, [props?.selectedWheelID, props?.open]);

  const handleTemplateChange = (val: any) => {
    setselected_template_id(val.target.value);
  };

  const onSave = (wheelConfigTemplates: any) => {
    if (parseInt(props.selectedWheelID) !== selected_template_id) {
      let blank_template = [];
      if (props.wheelConfiguration && props.wheelConfiguration.length > 0) {
        props.wheelConfiguration.forEach((element: any, index: any) => {
          if (index % 2 === 0) {
            blank_template.push({
              text: element.text,
              code: element.code,
              color: wheelConfigTemplates[selected_template_id]["color1"],
            });
          } else {
            blank_template.push({
              text: element.text,
              code: element.code,
              color: wheelConfigTemplates[selected_template_id]["color2"],
            });
          }
        });
      } else {
        blank_template.push({
          text: "",
          code: "",
          color: wheelConfigTemplates[selected_template_id]["color1"],
        });
        blank_template.push({
          text: "",
          code: "",
          color: wheelConfigTemplates[selected_template_id]["color2"],
        });
        blank_template.push({
          text: "",
          code: "",
          color: wheelConfigTemplates[selected_template_id]["color1"],
        });
        blank_template.push({
          text: "",
          code: "",
          color: wheelConfigTemplates[selected_template_id]["color2"],
        });
      }
      props.setWheelConfig(blank_template);
      props.setSelectedWheel({
        id: selected_template_id,
        colorOdd: wheelConfigTemplates[selected_template_id]["color1"],
        colorEven: wheelConfigTemplates[selected_template_id]["color2"],
      });
      props.setSelectedWheelID(selected_template_id);
      props.onChanges({ spinTheWheel: blank_template });
    }
    props.onModalClose();
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const getWidgetPreview = (custom: any, id: any) => {
    return (
      <WidgetPreview
        wheelTemplates={props.wheelTemplates}
        wheelConfig={props.wheelConfig}
        custom={custom}
        id={id}
        selectedWheelID={props.selectedWheelID}
      />
    );
  };

  const getAvailableTemplates = () => {
    var customizable_templates = [];
    var available_templates = props.wheelTemplates;
    var custom = false;

    for (var i = 0; i < Object.keys(available_templates).length; i++) {
      var template = (
        <div className="templateItem share-template-btns" key={i}>
          {/*key added...*/}
          <label>
            <input
              type="radio"
              value={i}
              checked={Number(selected_template_id) === i}
              onChange={(val) => handleTemplateChange(val)}
            />
            <span className="tmplinput">{getWidgetPreview(custom, i)}</span>
          </label>
        </div>
      );
      customizable_templates.push(template);
    }

    return (
      <div>
        {toastMarkup}

        <Modal.Section>
          <TextContainer>
            <p>
              <b>Customizable Designs </b>
              <TextStyle variation="subdued">
                (select this to set custom colours)
              </TextStyle>
            </p>
          </TextContainer>
          <br />
          <div className="previewBackground">
            <Stack distribution="fillEvenly">{customizable_templates}</Stack>
          </div>
        </Modal.Section>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        props.onModalClose();
      }}
      title="Spin the Wheel Templates"
      primaryAction={{
        content: "Save",
        onAction: () => onSave(props.wheelTemplates),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpen(false);
            props.onModalClose();
          },
        },
      ]}
      limitHeight={false}
    >
      {getAvailableTemplates()}
    </Modal>
  );
};

export default ChangeWidgetTemplateModal;
