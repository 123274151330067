import {
  Card,
  Checkbox,
  FormLayout,
  RadioButton,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import {
  DEVICE_TYPE_DESKTOP_ONLY,
  DEVICE_TYPE_MOBILE_AND_DESKTOP,
  PLATFORM,
} from "../../../constants/constants";
import DevicesType from "../../../types/Devices.type";
import { objectDifference } from "../../../utils/helper";

const DesktopConfig = (props: any) => {
  const [initialData, setInitialData] = useState<DevicesType>(props.data);
  const [data, setData] = useState<DevicesType>(props.data);
  const [refData, setRefData] = useState<DevicesType>(props.data);

  const [
    activateErrorCheckForHeightOffset,
    setActivateErrorCheckForHeightOffset,
  ] = useState(false);
  const [activateErrorCheckForEdgeOffset, setActivateErrorCheckForEdgeOffset] =
    useState(false);
  const [
    activateErrorCheckForProductPageHeight,
    setActivateErrorCheckForProductPageHeight,
  ] = useState(false);

  const [updatedData, setUpdatedData] = useState({});

  useEffect(() => {
    setRefData(props.data);
    setData(props.data);
    setInitialData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (props.isDataSubmitted) {
      setRefData(data);
      setInitialData(data);
    }
  }, [props.isDataSubmitted]);

  useEffect(() => {
    var diffObj: any = objectDifference(refData, data);
    setUpdatedData({});
    if (Object.keys(diffObj).length !== 0) {
      switch (props.selected) {
        case DEVICE_TYPE_MOBILE_AND_DESKTOP:
        case DEVICE_TYPE_DESKTOP_ONLY:
          setDataToUpdate(diffObj);
          break;
      }
    } else {
      setUpdatedData({});
    }
  }, [data]);

  useEffect(() => {
    let errorMessage = getErrorMessage();
    if (errorMessage) {
      props.onError(errorMessage, "desktop");
      props.onChanges(null);
    } else {
      props.onError("", "desktop");
      props.onChanges(updatedData);
    }
  }, [updatedData]);

  useEffect(() => {
    if (props.reset) {
      setRefData(initialData);
      setData(initialData);
      props.setHasChanges(false);
    }
  }, [props.reset]);

  useEffect(() => {
    switch (props.selected) {
      case DEVICE_TYPE_MOBILE_AND_DESKTOP:
        props.onChanges(updatedData);
        break;
      case DEVICE_TYPE_DESKTOP_ONLY:
        props.onChanges(updatedData);
        break;
    }
  }, [props.selected]);

  const getErrorMessage = () => {
    if (!data.chatPosition) {
      return "Invalid Chat Button Position";
    } else if (validateVerticalOffset() !== false) {
      return "Invalid Height Offset";
    } else if (validateHorizontalOffset() !== false) {
      return "Invalid Edge Offset";
    } else if (validateProductOffset() !== false) {
      return "Invalid Product Height Offset";
    } else {
      return false;
    }
  };

  const setDataToUpdate = (diffObj: any) => {
    let deviceType = "desktop";
    if ("chatPosition" in diffObj) {
      setUpdatedDesktopData("deviceType", deviceType);
      setUpdatedDesktopData("chatPosition", diffObj.chatPosition);
    }
    if ("verticalOffset" in diffObj) {
      setUpdatedDesktopData("deviceType", deviceType);
      setUpdatedDesktopData("verticalOffset", diffObj.verticalOffset);
    }
    if ("horizontalOffset" in diffObj) {
      setUpdatedDesktopData("deviceType", deviceType);
      setUpdatedDesktopData("horizontalOffset", diffObj.horizontalOffset);
    }
    if ("productPageHeight" in diffObj) {
      setUpdatedDesktopData("deviceType", deviceType);
      setUpdatedDesktopData("productPageHeight", diffObj.productPageHeight);
    }
    if ("productPageOffset" in diffObj) {
      setUpdatedDesktopData("deviceType", deviceType);
      setUpdatedDesktopData("productPageOffset", diffObj.productPageOffset);
    }
  };

  const setUpdatedDesktopData = (field: string, value: any) => {
    setUpdatedData((mData: any) => ({ ...mData, [field]: value }));
  };

  const handleButtonPositionChange = (value: string) => {
    setData({ ...data, chatPosition: value });
  };

  const handleOffsetChange = (offsetType: string, value: string) => {
    if (offsetType === "vertical") {
      if (!activateErrorCheckForHeightOffset) {
        setActivateErrorCheckForHeightOffset(true);
      }
      setData({ ...data, verticalOffset: value });
    } else if (offsetType === "horizontal") {
      if (!activateErrorCheckForEdgeOffset) {
        setActivateErrorCheckForEdgeOffset(true);
      }
      setData({ ...data, horizontalOffset: value });
    }
  };

  const validateVerticalOffset = () => {
    if (data.verticalOffset && Number(data.verticalOffset) < 8) {
      return "Minimum offset is 8px";
    } else if (!data.verticalOffset) {
      return "Desktop height offset cannot be empty";
    }
    return false;
  };

  const validateHorizontalOffset = () => {
    if (data.horizontalOffset && Number(data.horizontalOffset) < 8) {
      return "Minimum offset is 8px";
    } else if (!data.horizontalOffset) {
      return "Desktop edge offset cannot be empty";
    }
    return false;
  };

  const handleProductHeightChange = (value: boolean) => {
    setData({ ...data, productPageHeight: value });
  };

  const handleProductOffsetChange = (value: string) => {
    if (!activateErrorCheckForProductPageHeight) {
      setActivateErrorCheckForProductPageHeight(true);
    }
    setData({ ...data, productPageOffset: value });
  };

  const validateProductOffset = () => {
    if (data.productPageHeight && Number(data.productPageOffset) < 8) {
      return "Minimum offset is 8px";
    } else if (data.productPageHeight && !data.productPageOffset) {
      return "Desktop height offset cannot be empty";
    } else if (
      data.productPageHeight &&
      Number(data.productPageOffset) > 600
    ) {
      return "Maximum Offset is 600px";
    }
    return false;
  };

  const renderMarkup = () => {
    switch (props.selected) {
      case DEVICE_TYPE_MOBILE_AND_DESKTOP:
      case DEVICE_TYPE_DESKTOP_ONLY:
        return (
          <Card sectioned title="Desktop">
            <FormLayout>
              <FormLayout.Group>
                <div className="mt-1">
                  <TextContainer>
                    <p>Chat Button Position</p>
                  </TextContainer>
                  <Stack>
                    <RadioButton
                      label="Left"
                      checked={data.chatPosition === "left"}
                      id="d-left"
                      name="btnpositiondesktop"
                      onChange={() => handleButtonPositionChange("left")}
                    />
                    <RadioButton
                      label="Right"
                      id="d-right"
                      name="btnpositiondesktop"
                      checked={data.chatPosition === "right"}
                      onChange={() => handleButtonPositionChange("right")}
                    />
                  </Stack>
                </div>
              </FormLayout.Group>
              <TextStyle variation="subdued">
                {
                  "You can select whether to display your chat button on the right/left side of your website. "
                }
                {"You can also set the position by configuring the right and left offset."}
              </TextStyle>

              <>
                  <TextField
                    label="HEIGHT offset"
                    type="number"
                    suffix="px"
                    value={data.verticalOffset}
                    onChange={(val) => handleOffsetChange("vertical", val)}
                    helpText={
                      "Set the height offset of the chat button on desktop devices."
                    }
                    error={validateVerticalOffset()}
                  />
                  <br />
                  <TextField
                    label="EDGE offset"
                    type="number"
                    suffix="px"
                    value={data.horizontalOffset}
                    onChange={(val) => handleOffsetChange("horizontal", val)}
                    helpText={
                      "Set the distance of the chat button from the edge of the desktop screen."
                    }
                    error={validateHorizontalOffset()}
                  />
                <br />
                <TextContainer>
                  <Checkbox
                    label="Set custom chat button height for /product pages"
                    checked={data.productPageHeight}
                    onChange={(val) => handleProductHeightChange(val)}
                  />
                </TextContainer>
                <TextContainer>
                  <TextStyle variation="subdued">
                    If you have a fixed ‘Add to cart’ or ‘Buy now’ buttons on
                    your product pages, use this setting to set a custom chat
                    button height.
                  </TextStyle>
                </TextContainer>

                {data.productPageHeight ? (
                  <>
                    <br />
                    <TextField
                      label="Product HEIGHT offset"
                      type="number"
                      suffix="px"
                      value={data.productPageOffset}
                      onChange={(val) => handleProductOffsetChange(val)}
                      helpText={
                        "Change the height of chat button on desktop /product pages"
                      }
                      error={validateProductOffset()}
                    />
                  </>
                ) : null}
              </>
            </FormLayout>
          </Card>
        );
    }
  };

  return <>{renderMarkup()}</>;
};

export default DesktopConfig;
