import { Fragment, useEffect, useState } from "react";
import {
  Card,
  Heading,
  Icon,
  Layout,
  Link,
  Modal,
  Select,
  Stack,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import {
  getAllTags,
  deleteTag,
} from "../../../../redux/actions/manual-messaging";
import Template from "../../../../types/Template.type";
import { EditMinor, DeleteMinor } from "@shopify/polaris-icons";
import EditTagModal from "./EditTagModal";
import styles from "../../manual-message.module.scss";

function ViewTagModal({
  showToast,
  showErrorToast,
  open,
  setactivateViewTagModal,
  closeModal,
  shopId,
  userId,
}: any) {
  const [openModal, setOpenModal] = useState(open);
  const [selected, setSelected] = useState<any>();
  const [tags, setTags] = useState<Template[]>([]);
  const [confirm, setDeleteConfirm] = useState(false);
  const [activateEditModal, setactivateEditModal] = useState(false);
  const [tag, setTag] = useState({});

  useEffect(() => {
    fetchAllTags(userId);
  }, [userId]);

  const fetchAllTags = (userId: string) => {
    if (userId) {
      getAllTags(userId).then((res: any) => {
        if (res.status === "success" && res.tags) {
          setTags(res.tags);
        }
      });
    }
  };

  const openEditMode = (template: any) => {
    setOpenModal(false);
    setTag(template);
    setactivateEditModal(true);
  };

  const handleDeleteTag = (userId: string) => {
    if (selected?.id) {
      deleteTag(userId, selected?.id).then(
        (response: any) => {
          if (response.status === "success") {
            setDeleteConfirm(false);
            showToast("Tag deleted successfully");
            fetchAllTags(userId);
          } else {
            showErrorToast(response);
          }
        },
        (err) => {
          showErrorToast("Unable to delete tag");
        }
      );
    }
  };
  const renderTemplatesMarkup = () => {
    var markup = [];
    for (let i = 0; i < tags.length; i++) {
      if (tags[i]) {
        markup.push(
          <Fragment key={i}>
            <Layout.Section>
              <div className="template-info-container">
                <div className="Polaris-TextContainer Polaris-TextContainer--spacingTight overflow-wrap width-30">
                  <Heading>
                    <TextStyle>Name : </TextStyle>
                    {tags[i].name}
                  </Heading>
                  <span>
                    <TextStyle>Category : </TextStyle>
                    <TextStyle variation="subdued">
                      {" "}
                      {tags[i].category}
                    </TextStyle>
                  </span>

                  <br></br>
                </div>
                <div className="template Polaris-Layout__Section Polaris-Layout__Section--secondary m-auto text-center w-10">
                  <div
                    className={`${styles.tagsButtonContainer} flex-col w-100`}
                  >
                    <div className="w-100">
                      <button
                        className={
                          "Polaris-Button Polaris-Button--outline Polaris-Button--primary"
                        }
                        type="button"
                        onClick={() => openEditMode(tags[i])}
                      >
                        <span className="Polaris-Button__Content">
                          <span className="Polaris-Button__Text">
                            <div className="d-flex-center">
                              <div>
                                <Icon source={EditMinor} />
                              </div>
                              <div>&nbsp;Edit</div>
                            </div>
                          </span>
                        </span>
                      </button>
                    </div>
                    <div className="delete-button-container mt-1-half">
                      <button
                        className="Polaris-Button Polaris-Button--outline outline Polaris-Button--destructive"
                        type="button"
                        onClick={() => {
                          setSelected(tags[i]);
                          setDeleteConfirm(true);
                        }}
                      >
                        <span className="Polaris-Button__Content">
                          <span className="Polaris-Button__Text">
                            <div className="d-flex-center">
                              <div>
                                <Icon source={DeleteMinor} />
                              </div>
                              <div>&nbsp;Delete</div>
                            </div>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Layout.Section>
          </Fragment>
        );
      }
    }
    return markup;
  };
  const getAbandonedCartSettingsMarkup = () => {
    return (
      <>
        <Stack vertical>
          {renderTemplatesMarkup()}
          {nudgeCheckoutOptinScrollSection}
        </Stack>
      </>
    );
  };

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  const nudgeCheckoutOptinScrollSection = (
    <div id="nudgeCheckoutOptin_anchor">
      <Card></Card>
    </div>
  );

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          closeModal();
          setOpenModal(false);
        }}
        title={"Tags"}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              closeModal();
              setOpenModal(false);
            },
          },
        ]}
      >
        <Modal.Section>
          {tags.length > 0 ? (
            getAbandonedCartSettingsMarkup()
          ) : (
            <p>No Tags found</p>
          )}
        </Modal.Section>
      </Modal>
      <Modal
        open={confirm}
        small
        onClose={() => setDeleteConfirm(false)}
        title={`Are you sure you want to delete "${
          selected?.name ? selected.name : ""
        }"?`}
        primaryAction={{
          content: "Confirm",
          onAction: () => handleDeleteTag(userId),
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>Please click Confirm to proceed</p>
          </TextContainer>
        </Modal.Section>
      </Modal>

      {activateEditModal ? (
        <EditTagModal
          open={activateEditModal}
          setactivateEditModal={setactivateEditModal}
          closeModal={() => {
            setactivateEditModal(false);
            setactivateViewTagModal(false);
          }}
          showToast={showToast}
          showErrorToast={showErrorToast}
          userId={userId}
          selectedTag={tag}
          fetchAllTags={() => fetchAllTags(userId)}
          setactivateViewTagModal={setactivateViewTagModal}
        />
      ) : null}
    </>
  );
}

export default ViewTagModal;
