import {
  AGENT_ASSIST_TAGS,
  AGENT_INFO,
  CHATROOM_INFO,
  GET_CHATS,
  GET_COUNT,
  GET_MESSAGES,
  SEND_MESSAGE,
  AGENT_ASSIST_QUICK_RESPONSE_UPDATE_DELETE,
  AGENT_ASSIST_QUICK_RESPONSE,
  AGENT_ASSIST_WORKING_HOURS,
  AGENT_ASSIST_AWAY_MESSAGE,
  CHAT_MEDIA,
  AGENT_ASSIST_SEND_TEMPLATE,
  AGENT_ASSIST_IMPORT_CHATS,
} from '../../constants/api-constants';
import httpClient from '../../utils/standard-http-client';

export const getChatRoomList = (params: any) => {
  return httpClient.get(`${GET_CHATS}${params}`);
};

export const getCount = (agentPhone: any, queryParams: any) => {
  return httpClient.get(
    `${GET_COUNT.replace('$agentPhone', agentPhone)}${queryParams}`
  );
};

export const getAgentInfo = (userId: any) => {
  return httpClient.get(AGENT_INFO.replace('$userId', userId));
};

export const getWhatsappMessages = (
  agentPhone: any,
  customerPhone: any,
  pageNo: any,
  pageSize: any
) => {
  return httpClient.get(
    GET_MESSAGES.replace('$customerPhone', customerPhone)
      .replace('$agentPhone', agentPhone)
      .replace('$pageNo', pageNo)
      .replace('$pageSize', pageSize)
  );
};

export const sendWhatsappMessage = (params: any) => {
  return httpClient.post(SEND_MESSAGE, params);
};

export const getChatTags = (agentPhone: any) => {
  return httpClient.get(AGENT_ASSIST_TAGS.replace('$agentPhone', agentPhone));
};

export const saveChatRoomInfo = (params: any) => {
  return httpClient.post(CHATROOM_INFO, params);
};

export const deleteTag = (agentPhone: any, params: any) => {
  return httpClient.delete(
    `${AGENT_ASSIST_TAGS.replace('$agentPhone', agentPhone)}?${params}`
  );
};

export const createTag = (agentPhone: any, params: any) => {
  return httpClient.post(
    `${AGENT_ASSIST_TAGS.replace('$agentPhone', agentPhone)}?${params}`
  );
};

export const updateTag = (agentPhone: any, params: any) => {
  return httpClient.put(
    `${AGENT_ASSIST_TAGS.replace('$agentPhone', agentPhone)}?${params}`
  );
};

export const getQuickResponses = (agentPhone: any) => {
  return httpClient.get(
    AGENT_ASSIST_QUICK_RESPONSE.replace('$agentPhone', agentPhone)
  );
};

export const getFilteredQuickResponses = (
  agentPhone: any,
  filterParams: any
) => {
  const params = `/search?keyword=${filterParams}`;
  return httpClient.get(
    `${AGENT_ASSIST_QUICK_RESPONSE.replace('$agentPhone', agentPhone)}${params}`
  );
};

export const createQuickResponse = (agentPhone: any, params: any) => {
  return httpClient.post(
    `${AGENT_ASSIST_QUICK_RESPONSE.replace('$agentPhone', agentPhone)}`,
    params
  );
};

export const updateQuickResponse = (agentPhone: any, params: any) => {
  return httpClient.put(
    `${AGENT_ASSIST_QUICK_RESPONSE_UPDATE_DELETE.replace(
      '$agentPhone',
      agentPhone
    ).replace('$quickResponseId', params.quickResponseId)}`,
    params
  );
};

export const deleteQuickResponse = (agentPhone: any, quickResponseId: any) => {
  return httpClient.delete(
    `${AGENT_ASSIST_QUICK_RESPONSE_UPDATE_DELETE.replace(
      '$agentPhone',
      agentPhone
    ).replace('$quickResponseId', quickResponseId)}`
  );
};

export const getWorkingHours = (agentPhone: any) => {
  return httpClient.get(
    AGENT_ASSIST_WORKING_HOURS.replace('$agentPhone', agentPhone)
  );
};

export const updateWorkingHours = (agentPhone: any, params: any) => {
  return httpClient.put(
    `${AGENT_ASSIST_WORKING_HOURS.replace('$agentPhone', agentPhone)}`,
    params
  );
};

export const getAwayMessage = (agentPhone: any) => {
  return httpClient.get(
    AGENT_ASSIST_AWAY_MESSAGE.replace('$agentPhone', agentPhone)
  );
};

export const updateAwayMessage = (agentPhone: any, params: any) => {
  return httpClient.put(
    AGENT_ASSIST_AWAY_MESSAGE.replace('$agentPhone', agentPhone),
    params
  );
};

export const getChatMedia = (userId: any, mediaPath: any) => {
  const params = `?path=${mediaPath}`;
  return httpClient.get(`${CHAT_MEDIA.replace('$userId', userId)}${params}`, {
    responseType: 'blob',
  });
};

export const sendHSMTemplate = (agentPhone: any, userId: any, params: any) => {
  return httpClient.post(
    `${AGENT_ASSIST_SEND_TEMPLATE.replace('$userId', userId).replace(
      '$agentPhone',
      agentPhone
    )}`,
    params
  );
};

export const uploadImportChats = (userId: any, params: any) => {
  return httpClient.post(
    AGENT_ASSIST_IMPORT_CHATS.replace('$userId', userId),
    params
  );
};
