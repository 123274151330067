import {
  PRIVATE_WABA_BUSINESS_DETAILS,
  PRIVATE_WABA_CONTACT_DETAILS,
  PRIVATE_WABA_FACEBOOK_DETAILS,
  PRIVATE_WABA_PROFILE_ABOUT_DETAILS,
  PRIVATE_WABA_PROFILE_DETAILS,
  PROFILE_IMAGE,
  SEND_OTP,
  SUBMIT_OTP,
  WABA,
  WABA_TEMPLATES,
  LOCALES_DETAILS,
  MESSAGING,
  COUNTRY_CODES,
  GET_PRIVATE_WABA,
  GET_PRIVATE_WABA_STATUS,
  WABA_PHOTO,
  WABA_LANGUAGE,
  WABA_BUSINESS_DETAILS,
  WABA_ABOUT,
  WABA_STATUS,
  WABA_RETRY,
  WABA_BUSINESS_VERIFICATION_DETAILS,
} from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

let config = {
  baseURL: MESSAGING.baseUrl,
};

export const loadWabaDetails = (userId: string) => {
  return httpClient.get(WABA.replace("$userId", userId));
};

export const getWabaDetailsbyShopId = (userId: string) => {
  return httpClient.get(GET_PRIVATE_WABA_STATUS.replace("$userId", userId));
};

export const retryWabaDetailsbyShopId = (
  userId: string,
  waba_id: any,
  params: any
) => {
  return httpClient.post(
    WABA_RETRY.replace("$userId", userId).replace("$wabaId", waba_id),
    params
  );
};

export const getWabaDetails = (userId: string, params: any) => {
  return httpClient.post(GET_PRIVATE_WABA.replace("$userId", userId), params);
};

export const launchWaba = (userId: string, params: any) => {
  return httpClient.post(
    GET_PRIVATE_WABA_STATUS.replace("$userId", userId),
    params
  );
};

export const getWabaDetailsbyId = (userId: string, waba_id: any) => {
  return httpClient.get(
    WABA_STATUS.replace("$userId", userId).replace("$wabaId", waba_id)
  );
};
export const loadAddress = (userId: string) => {
  return httpClient.get(WABA.replace("$userId", userId));
};
export const loadWABAImage = (userId: string) => {
  return httpClient.get(WABA.replace("$userId", userId));
};

export const createWABA = (userId: string, params: any) => {
  return httpClient.post(WABA.replace("$userId", userId), params);
};

export const sendOTP = (userId: string, params: any) => {
  return httpClient.post(SEND_OTP.replace("$userId", userId), params);
};

export const verifyOTP = (userId: string, params: any) => {
  return httpClient.put(SUBMIT_OTP.replace("$userId", userId), params);
};

export const getProfileImage = (userId: string) => {
  return httpClient.get(PROFILE_IMAGE.replace("$userId", userId));
};

export const uploadProfileImage = (userId: string, params: any) => {
  return httpClient.post(PROFILE_IMAGE.replace("$userId", userId), params);
};

export const getAutomatedTemplates = (userId: string) => {
  return httpClient.get(WABA_TEMPLATES.replace("$userId", userId));
};

export const saveBusinessDetails = (userId: string, params: any) => {
  return httpClient.post(
    PRIVATE_WABA_BUSINESS_DETAILS.replace("$userId", userId),
    params
  );
};

export const saveFacebookDetails = (userId: string, params: any) => {
  return httpClient.post(
    PRIVATE_WABA_FACEBOOK_DETAILS.replace("$userId", userId),
    params
  );
};

export const saveProfileDetails = (userId: string, params: any) => {
  return httpClient.post(
    PRIVATE_WABA_PROFILE_DETAILS.replace("$userId", userId),
    params
  );
};

export const saveContactDetails = (userId: string, params: any) => {
  return httpClient.post(
    PRIVATE_WABA_CONTACT_DETAILS.replace("$userId", userId),
    params
  );
};

export const saveProfileAboutDetails = (userId: string, params: any) => {
  return httpClient.post(
    PRIVATE_WABA_PROFILE_ABOUT_DETAILS.replace("$userId", userId),
    params
  );
};

export const getWhatsappPhoto = (userId: string, wabaId: string) => {
  return httpClient.get(
    WABA_PHOTO.replace("$userId", userId).replace("$wabaId", wabaId)
  );
};
export const deleteWhatsappPhoto = (userId: string, wabaId: string) => {
  return httpClient.delete(
    WABA_PHOTO.replace("$userId", userId).replace("$wabaId", wabaId)
  );
};
export const updateWhatsappPhoto = (
  userId: string,
  wabaId: string,
  params: any
) => {
  return httpClient.post(
    WABA_PHOTO.replace("$userId", userId).replace("$wabaId", wabaId),
    params
  );
};

export const updateWhatsappLanguage = (
  userId: string,
  wabaId: string,
  params: any
) => {
  return httpClient.post(
    WABA_LANGUAGE.replace("$userId", userId).replace("$wabaId", wabaId),
    params
  );
};

export const updateWhatsappBusinessDetails = (
  userId: string,
  wabaId: string,
  params: any
) => {
  return httpClient.post(
    WABA_BUSINESS_DETAILS.replace("$userId", userId).replace("$wabaId", wabaId),
    params
  );
};
export const getWhatsappBusinessDetails = (userId: string, wabaId: string) => {
  return httpClient.get(
    WABA_BUSINESS_DETAILS.replace("$userId", userId).replace("$wabaId", wabaId)
  );
};
export const getWhatsappBusinessVerificationDetails = (
  userId: string,
  wabaId: string
) => {
  return httpClient.get(
    WABA_BUSINESS_VERIFICATION_DETAILS.replace("$userId", userId).replace(
      "$wabaId",
      wabaId
    )
  );
};

export const updateWhatsappAbout = (
  userId: string,
  wabaId: string,
  params: any
) => {
  return httpClient.post(
    WABA_ABOUT.replace("$userId", userId).replace("$wabaId", wabaId),
    params
  );
};
// Supported Integrations

export const getAllIntegrations = () => {
  return httpClient.get(MESSAGING.api.INTEGRATION, config);
};

export const getIntegrationsListForShop = (userId: string) => {
  return httpClient.get(
    MESSAGING.api.SHOP_INTEGRATION.replace("$userId", userId),
    config
  );
};

export const addIntegration = (userId: string, params: any) => {
  return httpClient.post(
    MESSAGING.api.SHOP_INTEGRATION.replace("$userId", userId),
    params,
    config
  );
};

export const disableIntegration = (integrationId: string, params: any) => {
  return httpClient.put(
    MESSAGING.api.SHOP_INTEGRATION.replace("$userId", integrationId),
    params,
    config
  );
};

//Fetch Locales

export const getAllLocales = () => {
  return httpClient.get(LOCALES_DETAILS);
};

//Fetch Country Codes

export const getCountryCodes = () => {
  return httpClient.get(COUNTRY_CODES);
};
