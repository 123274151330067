//@ts-nocheck
import {
  Button,
  Heading,
  Icon,
  Layout,
  List,
  Modal,
  Stack,
  TextContainer,
  TextStyle,
} from '@shopify/polaris';
import { CampaignIcon } from '../../../common/SvgIcons';
import TemplateMessageCard from '../../../common/TemplateMessageCard';
import { DeleteMinor } from '@shopify/polaris-icons';
import Approved from '../../../assets/images/Approved';
import Pending from '../../../assets/images/Pending';
import Rejected from '../../../assets/images/Rejected';
import Notemplates from '../../../assets/images/notemplates.png';
import { useEffect, useState } from 'react';
import {
  deleteCustomTemplates,
  getCustomTemplates,
} from '../../../redux/actions/custom-templates';
import CustomTemplate from '../../../types/CustomTemplate.type.';
import { sortObj } from '../../../utils/common';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from '../../../utils/clevertapUtils';
import {
  options,
  CAMPAIGNS_TEMPLATES_PAGE,
} from '../../../constants/constants';
import {
  parameterKeysCleverTap,
  parameterKeysConst,
} from '../../OptinNumber/utils';

const constant = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  LANGUAGE: 'Language',
  BUTTONS: 'Buttons',
  TYPE: 'Type',
  CATEGORY: 'Category',
  DELETE: 'Delete',
};
const CustomTemplatesList = (props: any) => {
  const { privateWabaDetails } = props;
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);
  const [customTemplates, setCustomTemplates] = useState<CustomTemplate[]>([]);
  const [customTemplatesBackUp, setCustomTemplatesBackUp] = useState<
    CustomTemplate[]
  >([]);
  const [errorToast, setErrorToast] = useState({ show: false, message: '' });
  const [selected, setSelected] = useState<any>();
  const [confirm, setDeleteConfirm] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '' });
  const [loading, setLoading] = useState(false);
  const [defaultTemplateSelected, setDefaultTemplateSelected] = useState(false);
  const [pipeLineStage, setPipelineStage] = useState('');
  const history = useHistory();
  let { path } = useRouteMatch();

  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  useEffect(() => {
    if (props.shop && props.shop.userId) {
      setLoading(true);
      fetchCustomTemplates();
    }
  }, []);

  useEffect(() => {
    if (
      customTemplatesBackUp.length > 0 &&
      props.selectedTemplateCategory &&
      props.selectedTemplateCategory !==
        CAMPAIGNS_TEMPLATES_PAGE.TEMPLATE_CATEGORY[0].value
    ) {
      props.selectedTemplateCategory ===
      CAMPAIGNS_TEMPLATES_PAGE.TEMPLATE_CATEGORY[1].value
        ? setDefaultTemplateSelected(true)
        : setDefaultTemplateSelected(false);
      const filterTemplates = customTemplatesBackUp.filter((cusTempl: any) => {
        return cusTempl?.templateCategory === props.selectedTemplateCategory;
      });
      setCustomTemplates(filterTemplates);
    } else {
      setCustomTemplates(customTemplatesBackUp);
    }
  }, [props.selectedTemplateCategory]);

  useEffect(() => {
    if (privateWabaDetails?.pipeLineStage) {
      setPipelineStage(props.privateWabaDetails.pipeLineStage);
    } else if (props?.privateWabaDetails?.pipelineStage) {
      setPipelineStage(props.privateWabaDetails.pipelineStage);
    }
  }, [props.privateWabaDetails]);

  const filterTemplateBasedOnCategory = (tmplates: any, cat: any) => {
    const filterTemplates = tmplates.filter((tempCus: any) => {
      return tempCus?.templateCategory === cat;
    });
    setCustomTemplates(filterTemplates);
  };

  const fetchCustomTemplates = () => {
    getCustomTemplates(props.shop.userId).then(
      (response: any) => {
        setLoading(false);
        if (response.status === 'success') {
          setTemplatesPostFiltering(response);
        } else {
          showErrorToast(response);
        }
      },
      (err) => {
        setLoading(false);
        showErrorToast('Something went wrong');
      }
    );
  };

  const setTemplatesPostFiltering = (data: any) => {
    let tmplates = data.templates;
    let approvedDefaultTemplateCount = tmplates.filter((tmp: any) => {
      return (
        tmp.subtype === CAMPAIGNS_TEMPLATES_PAGE.TEMPLATE_SUB_TYPE &&
        tmp.status === constant.APPROVED
      );
    });
    // Default custom templates should not be visible if <3 default templates are approved.
    if (approvedDefaultTemplateCount.length < 3) {
      props.setShowTemplateFilter(false);
      tmplates = tmplates.filter((tmp: any) => {
        return tmp.subtype !== CAMPAIGNS_TEMPLATES_PAGE.TEMPLATE_SUB_TYPE;
      });
    }
    props.setTemplatesLength(tmplates.length || 0);
    sortObj(tmplates, 'createdOn');
    setCustomTemplates(tmplates);
    setCustomTemplatesBackUp(tmplates);
    if (
      props.selectedTemplateCategory !==
      CAMPAIGNS_TEMPLATES_PAGE.TEMPLATE_CATEGORY[0].value
    )
      filterTemplateBasedOnCategory(tmplates, props.selectedTemplateCategory);
  };

  const statusText = (status: string) => {
    if (status === constant.APPROVED) {
      return (
        <Stack>
          <Approved />
          <TextStyle variation='subdued'>{status}</TextStyle>
        </Stack>
      );
    } else if (status === constant.PENDING) {
      return (
        <Stack>
          <Pending />
          <TextStyle variation='subdued'>{status}</TextStyle>
        </Stack>
      );
    } else if (status === constant.REJECTED) {
      return (
        <Stack>
          <Rejected />
          <TextStyle variation='subdued'>{status}</TextStyle>
        </Stack>
      );
    }
  };

  const handleDeleteTemplate = (userId: string) => {
    if (selected?.id) {
      deleteCustomTemplates(userId, selected?.id).then(
        (response: any) => {
          if (response.status === 'success') {
            let regX = /^{{[a-zA-Z0-9 _#$%.]*}}$/;
            let variable = 'none';
            let defined = false,
              custom = false;
            let keys = Object.keys(parameterKeysCleverTap).map(
              (e) => `{{${parameterKeysCleverTap[e]}}}`
            );
            if (
              selected.body.split(' ').filter((e: any) => regX.test(e)).length
            ) {
              let variables = selected.body
                .split(' ')
                .filter((e: any) => regX.test(e));
              variables.forEach((element: any) => {
                if (keys.includes(element)) {
                  defined = true;
                } else {
                  custom = true;
                }
              });
            }
            if (defined && custom) {
              variable = 'both';
            } else if (defined) {
              variable = 'predefined';
            } else if (custom) {
              variable = 'custom';
            }
            let eventData = {
              'Template type': selected.type,
              Category: selected.templateCategory,
              Language: options.LANGUAGE.filter(
                (e: any) => e.value === selected.language
              )[0].label,
              Button: JSON.stringify(selected.buttons),
              'Number of variables': selected.body
                .split(' ')
                .filter((e: any) => regX.test(e)).length,
              'Message variables': variable,
              Status: selected.status,
            };
            clevertapEventPush({
              eventId: CT_EVENT_IDS.CUSTOM_TEMPLATE_DELETED,
              eventData: eventData,
            });
            setDeleteConfirm(false);
            showToast('Template deleted successfully');
            fetchCustomTemplates();
          } else {
            showErrorToast(response);
          }
        },
        (err) => {
          showErrorToast('Unable to delete template');
        }
      );
    }
  };

  const getTemplateButtonText = (tButtons: any) => {
    const types: any = [];
    if (tButtons && tButtons.length > 0) {
      tButtons.map((item: any) => types.push(item.type));
      return types.join() + '.';
    }
  };

  const handleRunCampaignClick = (templateId: number) => {
    history.push({ pathname: `/campaigns`, state: { templateId } });
  };

  const TemplateList = () => {
    return customTemplates.map((template, index) => {
      return (
        <div key={index}>
          <div className='mt-2 mb-2'>
            <Layout>
              <Layout.Section>
                <div className='template-info-container'>
                  <div className='Polaris-TextContainer Polaris-TextContainer--spacingTight template-info'>
                    <Heading>{template.name}</Heading>
                    {statusText(template.status)}
                    <TextContainer spacing='loose'>
                      <div className='list-unstyled'>
                        <List>
                          <List.Item>
                            <TextStyle variation='strong'>
                              {constant.CATEGORY} :
                            </TextStyle>{' '}
                            <TextStyle variation='subdued'>
                              {template.templateCategory}
                            </TextStyle>
                          </List.Item>
                          <List.Item>
                            {' '}
                            <TextStyle variation='strong'>
                              {constant.TYPE} :
                            </TextStyle>{' '}
                            <TextStyle variation='subdued'>
                              {template.type}
                            </TextStyle>
                          </List.Item>
                          <List.Item>
                            <TextStyle variation='strong'>
                              {constant.BUTTONS} :
                            </TextStyle>{' '}
                            {template.buttons.length > 0 ? (
                              <>
                                <TextStyle variation='subdued'>
                                  {getTemplateButtonText(template.buttons)}
                                </TextStyle>
                              </>
                            ) : (
                              'NONE'
                            )}
                          </List.Item>
                          <List.Item>
                            <TextStyle variation='strong'>
                              {constant.LANGUAGE} :
                            </TextStyle>{' '}
                            <TextStyle variation='subdued'>
                              {template.language}
                            </TextStyle>
                          </List.Item>
                        </List>
                      </div>
                    </TextContainer>
                  </div>
                  <TemplateMessageCard template={template} />
                </div>
              </Layout.Section>
              <div className='template Polaris-Layout__Section Polaris-Layout__Section--secondary m-auto text-center'>
                <div className='flex-col w-100 customButtonCss'>
                  <div className='run-campaign-button'>
                    {template.status === constant.APPROVED && (
                      <Button
                        monochrome
                        outline
                        icon={CampaignIcon}
                        onClick={() => {
                          handleRunCampaignClick(template?.id);
                        }}
                      >
                        {CAMPAIGNS_TEMPLATES_PAGE.RUN_CAMPAIGN}
                      </Button>
                    )}
                    {template?.subtype !== 'DEFAULT_CUSTOM' && (
                      <Button
                        plain
                        icon={DeleteMinor}
                        onClick={() => {
                          setSelected(template);
                          setDeleteConfirm(true);
                        }}
                        disabled={
                          !(
                            privateWabaDetails?.whatsappVerificationStatus ===
                            'WHATSAPP_VERIFICATION_DONE'
                          )
                        }
                      ></Button>
                    )}
                  </div>
                </div>
              </div>
            </Layout>
          </div>
          <br />
          <div className='Polaris-Page--divider'></div>
        </div>
      );
    });
  };

  return (
    <>
      <>
        {!privateWabaDetails && !loading ? (
          <Layout>
            <div style={{ padding: '45px 0' }}>
              <div className='flexCenter'>
                <img
                  src='https://cdn.shopify.com/s/files/1/0449/7794/6790/files/Screenshot_2022-01-07_112352_1mc.svg?v=1662704182'
                  width='200px'
                  alt='alternatetext'
                ></img>
              </div>
              <br />
              <div className='flexCenter helpText'>
                You don't have a private Whatsapp account
              </div>
              <div className='flexCenter helpText'>
                Let's create one & Start Messaging!
              </div>
              <div className='borderLine'></div>
              <div className='flexCenter customButton'>
                <Button onClick={() => history.push('/private-waba')}>
                  Create WhatsApp Business Account
                </Button>
              </div>
            </div>
          </Layout>
        ) : !loading && privateWabaDetails && pipeLineStage !== 'FINALIZE' ? (
          <Layout>
            <div style={{ padding: '45px 0' }}>
              <div className='flexCenter'>
                <img
                  src='https://cdn.shopify.com/s/files/1/0449/7794/6790/files/sand-clock-svgrepo-com_1timer.svg?v=1662704181'
                  width='100px'
                  alt='alternatetext'
                ></img>
              </div>
              <br />
              <div className='flexCenter helpText'>
                Hold tight! your private WhatsApp business<br></br> account is
                under review
              </div>
              <div className='borderLine'></div>
              <div className='flexCenter customButton'>
                <Button
                  onClick={() =>
                    history.push({
                      pathname: '/private-waba',
                      state: { modal: 'guide_modal' },
                    })
                  }
                >
                  Read the best practices to get you account approved
                </Button>
              </div>
            </div>
          </Layout>
        ) : (
          <>
            {customTemplates.length === 0 && !loading ? (
              <Layout>
                <div style={{ padding: '45px 0' }}>
                  {defaultTemplateSelected ? (
                    <>
                      <img
                        src={Notemplates}
                        width='100%'
                        alt='alternatetext'
                      ></img>
                      <div>{CAMPAIGNS_TEMPLATES_PAGE.NO_DEAFAULT_TEMPLATE}</div>
                    </>
                  ) : (
                    <>
                      <img
                        src='https://cdn.shopify.com/s/files/1/0449/7794/6790/files/Group_2364.png?v=1659444913'
                        width='100%'
                        alt='alternatetext'
                      ></img>
                      <div style={{ padding: '0px 0px 0px 122px' }}>
                        <Button primary onClick={() => props.handleClick()}>
                          Create Templates
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Layout>
            ) : (
              <>
                {!loading && <TemplateList />}
                <Modal
                  open={confirm}
                  small
                  onClose={() => setDeleteConfirm(false)}
                  title={`Are you sure you want to delete "${
                    selected?.name ? selected.name : ''
                  }"?`}
                  primaryAction={{
                    content: 'Confirm',
                    onAction: () => handleDeleteTemplate(props.shop.userId),
                  }}
                />
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

export default CustomTemplatesList;
