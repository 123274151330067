import { useEffect, useState } from "react";
import {
  Card,
  Layout,
  Page,
  TextStyle,
  Toast,
  ContextualSaveBar,
  Checkbox,
  RadioButton,
  TextField,
} from "@shopify/polaris";
import {
  getConfigurationSettings,
  updateConfigSettings,
} from "../../../redux/actions/email";
import { objectDifference } from "../../../utils/helper";
import { useShopStore } from '../../../hooks/shopStore';

const EmailConfiguration = () => {
  const { shopDetailsData } = useShopStore();
  const [
    activateErrorCheckForSupportEmail,
    setactivateErrorCheckForSupportEmail,
  ] = useState(false);
  const [
    activateErrorCheckForCustomBrandName,
    setactivateErrorCheckForCustomBrandName,
  ] = useState(false);
  const [initialBrandNameData, setinitialBrandNameData] = useState({
    brandNameEnabled: false,
    shopifyNameEnabled: true,
    customBrandName: "",
    supportMailEnabled: false,
    email: "",
  });
  const [brandNameData, setbrandNameData] = useState({
    brandNameEnabled: false,
    shopifyNameEnabled: true,
    customBrandName: "",
    supportMailEnabled: false,
    email: "",
  });
  const [refbrandNameData, setRefbrandNameData] = useState({
    brandNameEnabled: false,
    shopifyNameEnabled: true,
    customBrandName: "",
    supportMailEnabled: false,
    email: "",
  });

  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [dataChanges, setDataChanges] = useState<any>({
    changes: null,
  });
  const [hasChanges, setHasChanges] = useState(false);
  let shop = shopDetailsData;

  useEffect(() => {
    if (shop && shop.userId) {
      getConfigurationData(shop.userId);
    }
  }, []);

  useEffect(() => {
    var diffObj: any = objectDifference(refbrandNameData, brandNameData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        changes: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({ ...dataChanges, changes: null }));
    }
  }, [brandNameData]);
  useEffect(() => {
    if (dataChanges.changes) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [dataChanges]);

  const getConfigurationData = (userId: string) => {
    getConfigurationSettings(userId).then((res: any) => {
      if (res.status === "success") {
        setinitialBrandNameData(res);
        setRefbrandNameData(res);
        setbrandNameData(res);
      }
    });
  };

  const resetData = () => {
    setDataChanges({
      changes: null,
    });
    setbrandNameData(initialBrandNameData);
    setRefbrandNameData(initialBrandNameData);
    setHasChanges(false);
    setactivateErrorCheckForCustomBrandName(false);
    setactivateErrorCheckForSupportEmail(false);
  };

  const updateSettings = async () => {
    setactivateErrorCheckForSupportEmail(true);
    setactivateErrorCheckForCustomBrandName(true);
    if (brandNameData.supportMailEnabled && validateEmail(true)) {
      showErrorToast("A valid email is neccessary");
      return;
    }

    if (!brandNameData.shopifyNameEnabled && validateFixedCouponCode()) {
      showErrorToast("Name cannot be empty");
      return;
    }

    if (brandNameData.supportMailEnabled && !brandNameData.email) {
      showErrorToast("A valid email is neccessary");
      return;
    }

    if (!brandNameData.shopifyNameEnabled && !brandNameData.customBrandName) {
      showErrorToast("Name cannot be empty");
      return;
    }
    if (Object.keys(dataChanges.changes).length !== 0) {
      let params = new URLSearchParams();
      let data: any = dataChanges.changes;

      Object.keys(data).forEach((key) => {
        params.append(key, data[key]);
      });
      updateConfigSettings(shop.userId, params).then((response: any) => {
        if (response.status === "success") {
          showToast("Data Saved");
          setDataChanges({ ...dataChanges, changes: null });
          setinitialBrandNameData((data: any) => ({
            ...data,
            ...response.settings,
          }));
          setRefbrandNameData((data: any) => ({
            ...data,
            ...response.settings,
          }));
          setbrandNameData((data: any) => ({
            ...data,
            ...response.settings,
          }));
          setactivateErrorCheckForCustomBrandName(false);
          setactivateErrorCheckForSupportEmail(false);
        } else {
          showErrorToast(response);
        }
      });
    }
    setHasChanges(false);
  };

  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;
  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        content: "Save Settings",
        onAction: updateSettings,
      }}
      discardAction={{
        onAction: () => {
          resetData();
        },
      }}
    />
  ) : null;

  const handlePhoneChange = (field: string, value: any) => {
    if (!activateErrorCheckForSupportEmail && field === "email") {
      setactivateErrorCheckForSupportEmail(true);
    }
    setbrandNameData((data) => ({ ...data, [field]: value }));
  };

  const handleOptinChange = (field: string, value: any) => {
    if (!activateErrorCheckForCustomBrandName && field === "customBrandName") {
      setactivateErrorCheckForCustomBrandName(true);
    }
    setbrandNameData((data) => ({ ...data, [field]: value }));
  };

  const brandNameText = (
    <>
      <p>
        Your Brand/Store name will be appended at the beginning of Email Subject
        for all the Email Templates
      </p>
    </>
  );
  const supportEmailText = (
    <>
      <p>
        Add your Customer Support email at the end of the Email body for your
        customers to contact you for all Email Templates
      </p>
    </>
  );

  const storeNametext = (
    <div className="discountTypeHelpTextFontSize">
      <p>Use existing E-commerce Store Name</p>
    </div>
  );
  const supportEmailNametext = (
    <div className="discountTypeHelpTextFontSize">
      <p>Customer Support Email</p>
    </div>
  );
  const customNametext = (
    <div className="discountTypeHelpTextFontSize">
      <p>
        Use the Custom Brand/Store Name &nbsp;
        <TextStyle variation="subdued">(name provided by you)</TextStyle>
      </p>
    </div>
  );

  const validateFixedCouponCode = () => {
    if (activateErrorCheckForCustomBrandName) {
      if (!brandNameData.customBrandName && !brandNameData.shopifyNameEnabled) {
        return "Name cannot be empty";
      }
    } else {
      return false;
    }
  };
  const validateEmail = (enableCheck = false) => {
    //eslint-disable-next-line
    var emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (activateErrorCheckForSupportEmail || enableCheck) {
      if (
        !brandNameData.supportMailEnabled ||
        brandNameData.email.match(emailRegex)
      ) {
        return false;
      } else {
        return "Enter a valid email address";
      }
    } else {
      return false;
    }
  };

  const brandNameContent = (
    <>
      <Card sectioned>
        <Checkbox
          label={"Include Brand/Shop name"}
          checked={brandNameData.brandNameEnabled}
          onChange={(val) => handleOptinChange("brandNameEnabled", val)}
          disabled={false}
        />
        <br />
        <br />
        {brandNameData.brandNameEnabled && (
          <>
            <p>Select option</p>
            <RadioButton
              label={storeNametext}
              name="shopifyNameEnabled"
              checked={brandNameData.shopifyNameEnabled}
              onChange={(val) => handleOptinChange("shopifyNameEnabled", true)}
            />
            <RadioButton
              label={customNametext}
              name="shopifyNameEnabled"
              checked={!brandNameData.shopifyNameEnabled}
              onChange={(val) => handleOptinChange("shopifyNameEnabled", false)}
            />
            {!brandNameData.shopifyNameEnabled && (
              <TextField
                label=""
                name="brand_name"
                placeholder="Example: BEAUTY-STORE"
                disabled={false}
                value={brandNameData.customBrandName}
                error={validateFixedCouponCode()}
                onChange={(val) => handleOptinChange("customBrandName", val)}
              />
            )}
          </>
        )}
      </Card>
    </>
  );
  const supportEmailContent = (
    <>
      <Card sectioned>
        <Checkbox
          label={supportEmailNametext}
          checked={brandNameData.supportMailEnabled}
          onChange={(val) => handlePhoneChange("supportMailEnabled", val)}
          disabled={false}
        />
        <br />
        <br />
        {brandNameData.supportMailEnabled && (
          <TextField
            label=""
            name="email"
            placeholder="Example: beautystore@support.com"
            disabled={false}
            value={brandNameData.email}
            error={validateEmail()}
            onChange={(val) => handlePhoneChange("email", val)}
          />
        )}
      </Card>
    </>
  );

  return (
    <Page title="Email Configurations" divider>
      {toastMarkup}
      {errorToastMarkup}
      <>
        {ctxSaveBarMarkup}

        <Layout.AnnotatedSection
          title="Brand name in Email Subject"
          description={brandNameText}
        >
          {brandNameContent}
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Support Email"
          description={supportEmailText}
        >
          {supportEmailContent}
        </Layout.AnnotatedSection>
      </>
    </Page>
  );
};

export default EmailConfiguration;
