// @ts-nocheck
import { useUsersContext } from "../../../../context/usersContext";
import { useRef, useState } from "react";
import { ReactComponent as SendMessageIcon } from "../../../../assets/icons/sendButton.svg";

const ChatInput = (props: any) => {
  const { fetchQuickResponses, quickResponses, currentUser } =
    useUsersContext();
  const [showQuickReplies, setShowQuickReplies] = useState(false);
  const [message, setMessage] = useState("");

  const messageRef = useRef();

  const handleKeyUp = (e: any) => {
    if ((e?.key === "Enter" || e?.keyCode === 13) && !e.shiftKey) {
      submitMessage();
    } else if (message[0] === "/") {
      fetchQuickResponses(message.replace(/^./, ""));
      if (!showQuickReplies) {
        setShowQuickReplies(true);
      }
      return;
    }
    setShowQuickReplies(false);
  };

  const submitMessage = () => {
    setMessage("");
    setShowQuickReplies(false);
    props.submitNewMessage(message);
  };

  return props.getRemainingTime(true) ? (
    <div>
      {showQuickReplies && (
        <div className="chat__quickResponseContainer">
          {quickResponses.map((reply: any) => {
            return (
              <div
                className="chat__quickReply__card"
                onClick={() => {
                  setMessage(reply.text);
                  setShowQuickReplies(false);
                  messageRef?.current?.focus();
                }}
              >
                <div className="chat__quickReply__component">
                  <span style={{ fontWeight: "600" }}>{reply.keyword}</span>
                  {"    "}
                  {reply.text}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="chat__input-wrapper">
        {/*<div className='pos-rel'>
        <button
          aria-label='Attach'
          onClick={() => props.setShowAttach(!props.showAttach)}
        >
          <Icon
            id='attach'
            className={`chat__input-icon ${
              props.showAttach ? 'chat__input-icon--pressed' : ''
            }`}
          />
        </button>

        <div
          className={`chat__attach ${
            props.showAttach ? 'chat__attach--active' : ''
          }`}
        >
          {attachButtons.map((btn) => (
            <button
              className='chat__attach-btn'
              aria-label={btn.label}
              key={btn.label}
            >
              <Icon id={btn.icon} className='chat__attach-icon' />
            </button>
          ))}
          </div>
      </div>*/}
        <textarea
          className="chat__input"
          rows={1}
          placeholder="Type your message here"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyUp={handleKeyUp}
          ref={messageRef}
        />
        <button
          aria-label="Send message"
          onClick={() => {
            submitMessage();
          }}
        >
          <SendMessageIcon />
        </button>
      </div>
    </div>
  ) : (
    <div className="chat__input-wrapper-template">
      <p className="chat__sendTemplatesNotes">
        {`As 24 hour session is closed, you can only send template message to the customer, You can create a new template by going to campaigns > Templates & clicking on create new template button.`}{" "}
      </p>
      <div
        className="chat__sendTemplateButton"
        onClick={() => {
          if (currentUser && currentUser.customerPhone) {
            props.setHsmTemplateModalOpen(true);
          }
        }}
      >
        <button>
          <strong style={{ color: "#73d13f" }}>Select Template</strong>
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
