import { Card } from "@shopify/polaris";
import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import LightBulb from "../../../assets/images/light-bulb.png";

import styles from "./analytics.module.scss";

type BluckBucketProps = {
  link: any;
  disableChartContent?: any;
};

const BlurBucket = (props: BluckBucketProps) => (
  <div className={styles.blurBucket}>{props.disableChartContent}</div>
);

type DisableBlockProps = {
  disabledText: any;
};

export const DisableBlock = (props: DisableBlockProps) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      padding: "20px 20px  20px 0px",
    }}
  >
    <div>
      <img
        src={LightBulb}
        alt=""
        style={{
          height: "30px",
          width: "30px",
        }}
      />
    </div>
    <div style={{ paddingLeft: "15px" }}>{props.disabledText}</div>
  </div>
);

type Props = {
  title?: string;
  disabled?: boolean;
  disabledRedirectLink?: any;
  data?: any;
  lines: any[];
  className?: any;
  disableChartContent?: any;
  xDataKey?: any;
};
const Chart = ({
  title,
  disabled,
  disabledRedirectLink,
  data,
  lines,
  className,
  disableChartContent,
  xDataKey,
}: Props) => (
  <div className={className}>
    <Card title={title} sectioned>
      {disabled && (
        <BlurBucket
          link={disabledRedirectLink}
          disableChartContent={disableChartContent}
        />
      )}
      <ResponsiveContainer width="100%" height={250}>
        <LineChart
          data={data}
          className={disabled ? styles.blurContainer : ""}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3" />
          <XAxis dataKey={xDataKey} />
          <YAxis />
          <Tooltip />
          {lines.map((e: any, index: any) => {
            return (
              <Line
                key={index}
                dot={false}
                type="monotone"
                name={e.label}
                dataKey={e.dataKey}
                stroke={e.stroke}
                strokeWidth={2}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </Card>
  </div>
);

type ChartLegendsProps = {
  isHorizontal?: boolean;
  lines: any[];
};

export const ChartLegends = ({ isHorizontal, lines }: ChartLegendsProps) => (
  <div
    style={{
      display: isHorizontal ? "flex" : "",
      flexFlow: "wrap",
    }}
  >
    {lines.map((e: any, index) => {
      return (
        <div className={styles.lineChartVariables} key={index}>
          <div
            className={styles.lineChartColorBlock}
            style={{ background: e.stroke }}
          ></div>
          <div style={{ fontSize: "" }}>{e.label}</div>
        </div>
      );
    })}
  </div>
);

export default Chart;
