// @ts-nocheck
import { createContext, useContext, useEffect, useState } from 'react';
import cookie from 'react-cookies';
import {
  getChatMedia,
  getChatRoomList,
  getChatTags,
  getCount,
  getFilteredQuickResponses,
  getQuickResponses,
} from '../redux/actions/chats';
import { PriorityQueue } from '../utils/usersChatQueue';
import alertSound from '../assets/audio/sound.mp3';
import { Toast } from '@shopify/polaris';
import useWindowDimensions from '../hooks/useWindowDimensions';

import { getCustomTemplates } from '../redux/actions/custom-templates';
import {
  getMobileType,
  mobilecheck,
  shopFeaturesFromStoreData,
} from '../utils/helper';
import { useShopStore } from '../hooks/shopStore';
import { SHOP_FEATURES } from '../constants/constants';
import { useHistory, useLocation } from 'react-router-dom';
const SOCKET_URL = `wss://${process.env.REACT_APP_WEBSOCKET_BASE_URL}agentassist/chatconnect`;

const UsersContext = createContext({});

const useUsersContext = () => useContext(UsersContext);

const UsersProvider = ({ agent, children }: any) => {
  const { shopDetailsData } = useShopStore();
  const [mediaBlobs, setMediaBlobs] = useState({});
  let [priorityQueue, setPriorityQueue] = useState(new PriorityQueue());
  const [usersChatListItems, setUsersChatListItems] = useState([]);
  const [allChatsList, setAllChatsList] = useState([]);
  const [ticketStatus, setTicketStatus] = useState('ALL');
  const [agentInfo, setAgentInfo] = useState('');
  const [Tags, setTags] = useState([]);
  const [quickResponses, setQuickResponses] = useState([]);
  const [newChatRoomMessage, setNewChatRoomMessage] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const [agentPhone, setAgentPhone] = useState('');
  const [toast, setToast] = useState({ show: false, message: '' });
  const [errorToast, setErrorToast] = useState({ show: false, message: '' });
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(
    JSON.parse(localStorage.getItem('notificationEnabled') || 'false')
  );
  const [usersChatFilterTags, updateUserChatTag] = useState({
    ALL: {
      label: 'ALL',
      count: 0,
    },
    OPEN: {
      label: 'OPEN',
      count: 0,
    },
    CLOSED: {
      label: 'CLOSED',
      count: 0,
    },
  });
  const [userId, setUserId] = useState('');

  const [currentUser, setCurrentUser] = useState({
    customerPhone: '',
    customerName: '',
    ticketStatus: '',
    picture: '',
    tags: [],
    timestamp: '',
    lastMessage: '',
    lastMessageTimestamp: '',
  });

  const [onlyOnce, setOnlyOnce] = useState(false);
  const [socket, setSocket] = useState('');
  const { screenHeight, screenWidth } = useWindowDimensions();
  const [mobileView, setMobileView] = useState({
    status: false,
    screenHeight,
    screenWidth,
  });
  const [mobileType, setMobileType] = useState('');
  const [intervalObj, setIntervalObj] = useState('');
  const [fetchedTemplates, setFetchedTemplates] = useState([]);
  const [viewChatPhone, setViewChatPhone] = useState('');

  const location = useLocation();
  const history = useHistory();

  if (location?.state?.phone) {
    history.push({
      state: {
        ...location.state,
        phone: '',
      },
    });
  }

  useEffect(() => {
    if (mobilecheck()) {
      setMobileType(getMobileType());
    }
  }, []);

  useEffect(() => {
    if (screenWidth < 1025) {
      setMobileView({
        status: true,
        screenHeight,
        screenWidth,
      });
    } else {
      setMobileView({
        status: false,
        screenHeight,
        screenWidth,
      });
    }
  }, [screenWidth]);

  useEffect(() => {
    if (agentPhone && !onlyOnce) {
      let shop = shopDetailsData;
      setSocket(new WebSocket(SOCKET_URL));
      setUserId(shop.userId);
      fetchChatRoomList('create', 0, {});
      getChatCount();
      fetchChatTags();
      fetchQuickResponses();
      setOnlyOnce(true);
      fetchTemplates(shop.userId);
    }
  }, [agentPhone]);

  useEffect(() => {
    if (
      shopDetailsData &&
      shopFeaturesFromStoreData(shopDetailsData).includes(
        SHOP_FEATURES.AGENT_ASSIST
      )
    ) {
      try {
        if (agent) {
          setAgentPhone(agent);
        }
      } catch (err) {
        setAgentPhone('');
      }
    }
  }, [shopDetailsData, agent]);

  useEffect(() => {
    if (socket) {
      let myInterval = '';
      socket.onopen = (event) => {
        socket.send(`{'userid':'WHATSAPP:${agentPhone}','status':'open'}`);
        myInterval = setInterval(() => {
          if (socket && agentPhone && cookie.load('access_token')) {
            socket.send(
              `{'userid':'WHATSAPP:${agentPhone}','heartbeat':'true'}`
            );
          } else {
            try {
              socket.send(
                `{'userid':'WHATSAPP:${agentPhone}','status':'close'}`
              );
            } catch (err) {}
            clearInterval(myInterval);
          }
        }, 5000);
      };

      socket.onclose = () => {
        clearInterval(myInterval);
        if (socket && agentPhone) setSocket(new WebSocket(SOCKET_URL));
      };

      socket.onmessage = function (event: any) {
        try {
          const newChatRoom = JSON.parse(event.data);
          processData(newChatRoom);
          newMessageNotification();
          getChatCount();
        } catch (err) {
          console.log(err);
        }
      };
    }
  }, [socket]);

  const toggleNotification = async () => {
    localStorage.setItem(
      'notificationEnabled',
      JSON.stringify(isNotificationEnabled === 'false' ? 'true' : 'false')
    );
    setIsNotificationEnabled(
      isNotificationEnabled === 'false' ? 'true' : 'false'
    );
  };

  const newMessageNotification = () => {
    const notificationEnabled = JSON.parse(
      localStorage.getItem('notificationEnabled') || 'false'
    );
    if (notificationEnabled === 'true') {
      try {
        const chatNotificationSound = new Audio(alertSound);
        chatNotificationSound?.play();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const pushToQueue = (data: any, type: any) => {
    try {
      if (priorityQueue) {
        if (type === 'create') {
          priorityQueue.emptyQueue();
          if (Array.isArray(data)) {
            data.forEach((chat: any) => {
              priorityQueue.enqueueFunction(chat);
            });
          } else {
            priorityQueue.enqueueFunction(data);
          }
          priorityQueue?.removeDuplicates();
          setAllChatsList(priorityQueue?.queueItems);
          setUsersChatListItems(priorityQueue?.usersMessageList);
        } else if (type === 'append') {
          if (Array.isArray(data)) {
            data.forEach((chat: any) => {
              priorityQueue.enqueueFunction(chat);
            });
          } else {
            priorityQueue.enqueueFunction(data);
          }
          priorityQueue?.removeDuplicates();
          setAllChatsList(priorityQueue?.queueItems);
          setUsersChatListItems(priorityQueue?.usersMessageList);
        }
      }
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const fetchChatMedia = async (mediaPath: any) => {
    if (mediaBlobs && mediaBlobs[mediaPath]) {
      return mediaBlobs[mediaPath];
    } else {
      const mediaBlob = await getChatMedia(userId, mediaPath);
      setMediaBlobs({
        ...mediaBlobs,
        [mediaPath]: mediaBlob,
      });
      return mediaBlob;
    }
  };

  const processData = async (newChatRoom: any) => {
    const chatRoomcustomerPhone =
      newChatRoom.sender === agentPhone
        ? newChatRoom.destination
        : newChatRoom.sender;

    const dataForQueue = {
      customerPhone: chatRoomcustomerPhone,
      ticketStatus: 'OPEN',
      lastMessage: newChatRoom.text ? newChatRoom.text : '',
      lastMessageTimestamp: newChatRoom.timestamp,
      customerName: newChatRoom.senderName,
      chatRead: false,
    };

    const dataForMessageQueue = {
      fromUser: !newChatRoom.outgoing,
      message: newChatRoom.text ? newChatRoom.text : '',
      messageTime: newChatRoom.timestamp,
      customerPhone: chatRoomcustomerPhone,
      mediaPath: newChatRoom.mediaPath ? newChatRoom.mediaPath : '',
    };

    try {
      setNewChatRoomMessage(dataForMessageQueue);
      if (
        priorityQueue &&
        priorityQueue['queueItems'].length &&
        ['OPEN', 'ALL'].includes(priorityQueue['queueItems'][0].ticketStatus)
      ) {
        pushToQueue(dataForQueue, 'append');
      } else {
        priorityQueue.removeItem(chatRoomcustomerPhone);
        setAllChatsList(priorityQueue?.queueItems);
        setUsersChatListItems(priorityQueue?.usersMessageList);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChatCount = () => {
    if (!agentPhone) return;
    let queryParams = `?`;
    if (Object.keys(searchParams).length) {
      Object.keys(searchParams).forEach((q) => {
        if (queryParams.length === 1) {
          queryParams += `${q}=${searchParams[q]}`;
        } else {
          queryParams += `&${q}=${searchParams[q]}`;
        }
      });
    }
    if (ticketStatus !== 'ALL') {
      if (queryParams.length === 1) {
        queryParams += `ticketStatus=${ticketStatus}`;
      } else {
        queryParams += `&ticketStatus=${ticketStatus}`;
      }
    }
    getCount(agentPhone, queryParams)
      .then((res) => {
        if (res?.length) {
          let allCount = 0;
          let data = {}; //usersChatFilterTags;
          res.map((statusDetails: any) => {
            data[statusDetails['_id']] = {
              label: statusDetails['_id'],
              count: statusDetails['count'],
            };
            allCount += statusDetails['count'];
          });
          data['ALL'] = {
            label: 'ALL',
            count: allCount,
          };
          if (!data['OPEN']) {
            data['OPEN'] = {
              label: 'OPEN',
              count: 0,
            };
          }
          if (!data['CLOSED']) {
            data['CLOSED'] = {
              label: 'CLOSED',
              count: 0,
            };
          }
          updateUserChatTag(data);
        } else {
          let data = {
            ALL: {
              label: 'ALL',
              count: 0,
            },
            OPEN: {
              label: 'OPEN',
              count: 0,
            },
            CLOSED: {
              label: 'CLOSED',
              count: 0,
            },
          };
          updateUserChatTag(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchChatTags = async () => {
    if (!agentPhone) return;
    const tags = await getChatTags(agentPhone);
    setTags(tags);
  };

  const fetchQuickResponses = async (filterParams = '') => {
    if (!agentPhone) return;
    let res = [];
    if (filterParams) {
      res = await getFilteredQuickResponses(agentPhone, filterParams);
    } else {
      res = await getQuickResponses(agentPhone);
    }
    setQuickResponses(res);
  };

  const fetchChatRoomList = async (
    type: any,
    pageNo: any,
    optionalQueryParams: {}
  ) => {
    if (!agentPhone) return;
    let queryParams = `?agentPhone=${agentPhone}&pageNo=${pageNo}`;
    if (Object.keys(optionalQueryParams).length) {
      Object.keys(optionalQueryParams).forEach((q) => {
        queryParams += `&${q}=${optionalQueryParams[q]}`;
      });
    }
    if (ticketStatus !== 'ALL') {
      queryParams += `&ticketStatus=${ticketStatus}`;
    }

    const data = await getChatRoomList(queryParams);
    pushToQueue(data, type);
    return data;
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: '' });
      }}
      duration={2000}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const fetchTemplates = async (userId) => {
    let data = await getCustomTemplates(userId);
    setFetchedTemplates(data.templates);
  };

  return (
    <UsersContext.Provider
      value={{
        toastMarkup,
        errorToastMarkup,
        showToast,
        showErrorToast,
        usersChatListItems,
        setUsersChatListItems,
        allChatsList,
        setAllChatsList,
        currentUser,
        setCurrentUser,
        usersChatFilterTags,
        updateUserChatTag,
        fetchChatRoomList,
        setTicketStatus,
        ticketStatus,
        agentInfo,
        Tags,
        quickResponses,
        fetchQuickResponses,
        newChatRoomMessage,
        setNewChatRoomMessage,
        fetchChatTags,
        setSearchParams,
        searchParams,
        agentPhone,
        getChatCount,
        fetchChatMedia,
        mediaBlobs,
        toggleNotification,
        isNotificationEnabled,
        mobileView,
        fetchedTemplates,
        mobileType,
        viewChatPhone,
        setViewChatPhone,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export { useUsersContext, UsersProvider };
