// @ts-nocheck
import { Card, TextField } from "@shopify/polaris";
import "../chat.scss";
import { useUsersContext } from "../../../../context/usersContext";
import { saveChatRoomInfo } from "../../../../redux/actions/chats";
import { useEffect, useState } from "react";
import TagsDropdown from "./TagsDropdown";
import { ReactComponent as DropDownIcon } from "../../../../assets/icons/dropDownIcon.svg";
import { CHAT_USERPROFILE } from "../../../../constants/constants";

const TagsComponent = (props: any) => {
  const {
    currentUser,
    setCurrentUser,
    agentPhone,
    getChatCount,
    showToast,
    showErrorToast,
    Tags,
  } = useUsersContext();

  const [selectedTags, setSelectedTags] = useState("");
  const [tagInputValue, setTagInputValue] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);

  useEffect(() => {
    if (Tags.length) {
      if (tagInputValue) {
        let filteredTags = Tags.filter((t: any) =>
          t.name.toLowerCase().includes(tagInputValue.toLowerCase())
        );
        setPopoverActive(true);
        if (!filteredTags.length) showErrorToast(CHAT_USERPROFILE.NO_TAGS);
        setFilteredTags(filteredTags);
      } else {
        setPopoverActive(false);
        setFilteredTags(Tags);
      }
    }
  }, [Tags, tagInputValue]);

  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = () => {
    if (Tags.length) {
      setPopoverActive((popoverActive) => !popoverActive);
    } else {
      showErrorToast(CHAT_USERPROFILE.NO_TAGS);
    }
  };

  const saveInfo = async () => {
    if (currentUser && currentUser?.customerPhone) {
      const tags = Object.keys(selectedTags).filter((tag: any) => {
        return selectedTags[tag];
      });
      const data = {
        agentPhone: agentPhone,
        customerPhone: currentUser.customerPhone,
        tags: tags.length ? tags.join(",") : "",
        ticketStatus: currentUser.ticketStatus,
        notes: currentUser.notes,
        chatRead: currentUser?.chatRead,
      };
      const res = await saveChatRoomInfo(data);
      showToast(
        `${
          tags.length
            ? CHAT_USERPROFILE.TAGS_ASSOCIATED
            : CHAT_USERPROFILE.TAGS_REMOVED
        }`
      );
      setCurrentUser(res);
      getChatCount();
    }
    togglePopoverActive();
  };

  const ShowAssociatedTags = () => {
    if (!currentUser?.tags?.length) {
      return (
        <div className="chat__userProfile_noTags">
          {CHAT_USERPROFILE.NO_TAG_MESSAGE}
        </div>
      );
    } else {
      return (
        <div className="chat__userProfile_tagMainContainer">
          <div className="chat__userProfile_tagContainer">
            {currentUser.tags.map((tag: any, index: any) => {
              return (
                <div key={index} className="tagElementCss">
                  {tag}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div>
        <p className="chat__userprofile_tagsHeader">Tags</p>
        <TextField
          label=""
          value={tagInputValue}
          onChange={(e) => {
            setTagInputValue(e);
          }}
          autoComplete={false}
          placeholder="Start typing here..."
          suffix={
            <DropDownIcon
              onClick={() => setPopoverActive(!popoverActive)}
              className="chat__cursorPointer"
            />
          }
        />
      </div>
      {popoverActive ? (
        <Card>
          <div className="chat__userProfile_tagContainer">
            {filteredTags.length ? (
              <div className="w-100">
                <br />
                <TagsDropdown
                  setSelectedTags={setSelectedTags}
                  Tags={filteredTags}
                  saveInfo={saveInfo}
                />
                <div
                  className="chat__sendTemplateButton chat__associateButton"
                  onClick={saveInfo}
                >
                  <button>
                    <strong className="buttonColor--primary">
                      Associate tags
                    </strong>
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </Card>
      ) : (
        <ShowAssociatedTags />
      )}
    </>
  );
};

export default TagsComponent;
