import api from "../../utils/api";
import httpClient from "../../utils/http-client";
import {
  AGENT_TIMINGS,
  CHAT,
  CHAT_DEVICES,
  CHAT_BUTTON_TEMPLATES,
  STORE_TIMINGS,
  CUSTOM_THEMES,
  OPTIN_WIDGET,
  CHAT_DEVICES_DELETE,
} from "../../constants/api-constants";
import { CALL_OUT, GREETING_WIDGET } from "../../constants/api-constants";
import CallOut from "../../types/Callout.type";

export const getCallOutData = (userId: string) => {
  return new Promise<CallOut>((resolve, reject) => {
    api.get(CALL_OUT.replace("$userId", userId)).then((response: any) => {
      if (response.status === "success") {
        resolve(response.callout);
      } else {
        reject(response.message);
      }
    });
  });
};
export const getAgentTimings = (userId: string) => {
  return new Promise((resolve, reject) => {
    api.get(AGENT_TIMINGS.replace("$userId", userId)).then((response: any) => {
      if (response.status === "success") {
        resolve(response.agents);
      } else {
        reject(response.message);
      }
    });
  });
};
export const getGreetingWidgetData = (userId: string) => {
  return new Promise<CallOut>((resolve, reject) => {
    api
      .get(GREETING_WIDGET.replace("$userId", userId))
      .then((response: any) => {
        if (response.status === "success") {
          resolve(response.greeting);
        } else {
          reject(response.message);
        }
      });
  });
};

export const updateOptinWidgetData = (userId: string, params: any) => {
  return httpClient.post(OPTIN_WIDGET.replace("$userId", userId), params);
};

export const getOptinWidgetData = (userId: string) => {
  return new Promise((resolve, reject) => {
    api.get(OPTIN_WIDGET.replace("$userId", userId)).then((response: any) => {
      if (response.status === "success") {
        resolve(response.chatWidget);
      } else {
        reject(response.message);
      }
    });
  });
};

export const updateGreetingWidgetData = (userId: string, params: any) => {
  return httpClient.post(GREETING_WIDGET.replace("$userId", userId), params);
};

export const getChatDetails = (userId: string) => {
  return httpClient.get(CHAT.replace("$userId", userId));
};

export const getChatIconTemplates = (userId: string) => {
  return new Promise((resolve, reject) => {
    api.get(CHAT_BUTTON_TEMPLATES.replace("$userId", userId)).then(
      (response: any) => {
        if (response.status === "success") {
          resolve(response.templates);
        } else {
          reject(response.message);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
};

export const getChatDevices = (userId: string) => {
  return httpClient.get(CHAT_DEVICES.replace("$userId", userId));
};

export const updateChatDevices = (userId: string, params: any) => {
  return httpClient.post(CHAT_DEVICES.replace("$userId", userId), params);
};

export const deleteChatDevices = (userId: string, params: any) => {
  return httpClient.post(
    CHAT_DEVICES_DELETE.replace("$userId", userId),
    params
  );
};

export const updateChatDetails = (userId: string, params: any) => {
  return httpClient.post(CHAT.replace("$userId", userId), params);
};

export const updateCallout = (userId: string, params: any) => {
  return httpClient.post(CALL_OUT.replace("$userId", userId), params);
};

export const getStoreTimings = (userId: string) => {
  return httpClient.get(STORE_TIMINGS.replace("$userId", userId));
};

export const updateChatTheme = (
  userId: string,
  buttonId: string,
  params: any
) => {
  return httpClient.post(
    CUSTOM_THEMES.replace("$userId", userId).replace("$buttonId", buttonId),
    params
  );
};
