import { Link, TextContainer } from "@shopify/polaris";
import React from "react";

const MessageTemplateDescription = () => {
  return (
    <React.Fragment>
      <TextContainer spacing="loose">
        <p>
          Recover abandoned carts by reaching your customers on WhatsApp.
          Message is sent 15 minutes after customer abandons cart.
        </p>
        <p>
          <b>
            Automated WhatsApp message templates cannot be modified or edited
          </b>
          , because message templates need to be pre-approved by WhatsApp before
          they can be sent using the API.
        </p>
        <p>
          To learn about the cost of sending automated messages, view{" "}
          <Link
            external={true}
            url="https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0"
          >
            {"pricing chart"}
          </Link>
        </p>
      </TextContainer>
    </React.Fragment>
  );
};
export default MessageTemplateDescription;
