import { Icon, TextStyle } from "@shopify/polaris";
import { RiskMinor } from "@shopify/polaris-icons";
import { useEffect, useState } from "react";
import { upgradeCappedAmount } from "../redux/actions/shop";
import moment from "moment";

const BalanceUpdate = (props: any) => {
  let { shop, balance } = props;
  const [usagePercentage, setUsagePercentage] = useState(0);
  useEffect(() => {
    let usage: any = 100 * (balance.balanceUsed / balance.cappedAmount);
    setUsagePercentage(usage);
  }, [balance]);

  const upgradeCapAmount = () => {
    let amount = "1000.0";
    upgradeCappedAmount(shop.userId, amount)
      .then((res: any) => {
        if (res.status === "success") {
          let url = res?.update_capped_amount_url;
          window.open(url, "_blank");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const contactUs = () => {
    let subject =
      "I need to upgrade balance amount for triggering template messages";
    subject = encodeURIComponent(subject);
    window.open(
      `mailto:support@superlemon.xyz?subject=${subject}` + shop.url,
      "_blank"
    );
  };
  return (
    <>
      {Number(usagePercentage) >= 50 && Number(balance.cappedAmount) ? (
        <div className="balanceStatus">
          <div className="flexCenterAlign">
            <Icon source={RiskMinor} />
          </div>
          <div className="textBlack flexCenterAlign">
            {Number(usagePercentage) >= 100
              ? `You have exhausted your monthly usage limit, automated messages
            won't be sent until ${moment(
              new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
            ).format("Do-MMM-YY")}. Upgrade your usage limits to
            continue sending messages now.` //
              : `You have used ${usagePercentage
                  .toFixed(2)
                  .replace(/[.,]00$/, "")}% of
            your monthly usage limit, Upgrade your usage limit to send messages
            automatically without any uninterupption.`}
          </div>
          <div className="flexCenterAlign">
            {Number(usagePercentage) >= 50 &&
            Number(balance.cappedAmount) === 500 ? (
              <span
                className="balanceButtons"
                style={{ color: "#5ec127" }}
                onClick={upgradeCapAmount}
              >
                Upgrade
              </span>
            ) : (
              <span
                className="balanceButtons"
                style={{ width: "50px" }}
                onClick={contactUs}
              >
                Contact us
              </span>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default BalanceUpdate;
