import { useEffect, useState } from "react";
import {
  FormLayout,
  Link,
  Modal,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { PLATFORM, avatarDictionary } from "../../../constants/constants";
import { objectDifference } from "../../../utils/helper";
import {
  createAgent,
  updateAgentDetails,
  updateAgentTiming,
} from "../../../redux/actions/agents";
import { COUNTRY_CODES } from "../../../constants/countryCodes";
import Select from "react-select";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";
import getLocation from "../../../common/Location";
import { useShopStore } from '../../../hooks/shopStore';

const AgentInfoModal = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [openModal, setOpenModal] = useState(props.open);
  const [agent, setAgent] = useState({
    avatar_id: props.agent.avatar_id,
    avatar_url: props.agent.avatar_url,
    country_code: props.agent.country_code,
    id: props.agent.id,
    name: props.agent.name,
    number: props.agent.number,
    phone: props.agent.phone,
    role: props.agent.role,
  });
  const [refAgent, setRefAgent] = useState({
    avatar_id: props.agent.avatar_id,
    avatar_url: props.agent.avatar_url,
    country_code: props.agent.country_code,
    id: props.agent.id,
    name: props.agent.name,
    number: props.agent.number,
    phone: props.agent.phone,
    role: props.agent.role,
  });

  const [agentTimings, setAgentTimings] = useState(props.agentTimings);
  const [updatedObj, setUpdatedObject] = useState({});
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });

  let shop = shopDetailsData;

  useEffect(() => {
    setOpenModal(props.open);
    setRefAgent(props.agent);

    if (!props.agent.avatar_id) {
      props.agent.avatar_id = 1;
    }

    setAgent(props.agent);
    setAgentTimings(props.agentTimings);
  }, [props]);

  const [countryCodePlaceholder, setCountryCodePlaceholder] = useState<any>();

  useEffect(() => {
    if (agent.country_code == "") {
      let locationData = getLocation();
      if (locationData) {
        setAgent({ ...agent, country_code: locationData.value });
      }
    }
    setCountryCodePlaceholder(
      COUNTRY_CODES.find((entry) => entry.value === agent.country_code)
    );
  }, [agent.country_code]);

  useEffect(() => {
    var diffObj = objectDifference(refAgent, agent);

    if (Object.keys(diffObj).length > 0) {
      if (diffObj.country_code)
        setUpdatedAgentData("countryCode", diffObj.country_code);
      if (diffObj.name) setUpdatedAgentData("name", diffObj.name);
      if (diffObj.number) setUpdatedAgentData("number", diffObj.number);
      if (diffObj.role) setUpdatedAgentData("designation", diffObj.role);
      if (diffObj.avatar_id) setUpdatedAgentData("avatarId", diffObj.avatar_id);
      if (diffObj.avatar_url)
        setUpdatedAgentData("avatarUrl", diffObj.avatar_url);
    }
  }, [agent]);

  const setUpdatedAgentData = (field: string, val: any) => {
    setUpdatedObject((prevData) => ({
      ...prevData,
      [field]: val,
    }));
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const onAgentUpdate = () => {
    const countryCodeMessage = validateCountryCode();
    if (countryCodeMessage) {
      showErrorToast(countryCodeMessage);
      return;
    }

    if (!agent.avatar_id) {
      showErrorToast("Please select avatar");
      return;
    }
    if (!agent.country_code) {
      showErrorToast("Please add country code");
      return;
    }
    if (!agent.name) {
      showErrorToast("Please add agent name");
      return;
    }
    if (!agent.role) {
      showErrorToast("Please add agent role");
      return;
    }
    if (typeof validateAgentName() === "string") {
      showErrorToast("Please enter correct agent name");
      return;
    }
    if (typeof validateAgentRole() === "string") {
      showErrorToast("Please enter correct agent role");
      return;
    }

    for (const ele of props.agents) {
      if (ele.id === agent.id) continue;

      if (
        ele.number === agent.number &&
        ele.country_code === agent.country_code
      ) {
        showErrorToast("Agent contact number already exists");
        return;
      }
    }
    var phoneno = /^(?:[0-9]\d{0,14})$/;

    if (!agent.number.match(phoneno)) {
      showErrorToast("Invalid agent contact number");
      return;
    }

    for (const timing of agentTimings) {
      if (validateTime(timing.start_time)) {
        showErrorToast("Invalid start time format");
        return;
      }
      if (validateTime(timing.end_time)) {
        showErrorToast("Invalid end time format");
        return;
      }
      if (
        timing.start_time >= timing.end_time &&
        !(timing.start_time === "0000" && timing.end_time === "0000")
      ) {
        showErrorToast("End time should be greater than start time");
        return;
      }
    }

    if (Object.keys(updatedObj).length > 0) {
      if (props.isNewNumber) {
        createNewAgent(updatedObj);
      } else {
        modifyAgent(updatedObj);
      }
    }
    if (!props.isNewNumber) {
      updateAgentTimings(agent.id);
    }
  };
  const createNewAgent = (obj: any) => {
    var params: any = new URLSearchParams();
    params.append("userId", shop.userId);
    Object.keys(obj).forEach((key) => {
      params.append(key, obj[key]);
    });

    createAgent(shop.userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.WIDGET_EDITED,
            eventData: {
              "Widget Name": "Chat widget",
            },
          });
          updateAgentTimings(response.agent.id);
        } else {
          showErrorToast(response);
        }
      },
      (err) => {
        showErrorToast("Something went wrong");
      }
    );
  };

  const modifyAgent = (obj: any) => {
    var params: any = new URLSearchParams();

    Object.keys(obj).forEach((key) => {
      params.append(key, obj[key]);
    });

    updateAgentDetails(shop.userId, agent.id, params).then(
      (response: any) => {
        if (response.status === "success") {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.WIDGET_EDITED,
            eventData: {
              "Widget Name": "Chat widget",
            },
          });
          props.fetchAgents(shop.userId);
          setUpdatedObject({});
        } else {
          showErrorToast(response);
        }
      },
      (err) => {
        showErrorToast("Something went wrong");
      }
    );
  };

  const updateAgentTimings = (id: any) => {
    updateAgentTiming(shop.userId, id, agentTimings).then(
      (response: any) => {
        if (response.data.status === "success") {
          // refresh agent list
          props.fetchAgents(shop.userId);
          props.onModalClose();
        } else {
          showErrorToast(response);
        }
      },
      (err) => {
        showErrorToast("Something went wrong");
      }
    );
  };
  const handleFieldChange = (field: string, val: string) => {
    if (!props.agent.avatar_id) {
      props.agent.avatar_id = 1;
    }
    setAgent((agent: any) => ({ ...agent, [field]: val }));
  };
  const getAgentEditForm = () => {
    return (
      <FormLayout>
        <FormLayout.Group>
          <Stack>
            <div className="customSelect">
              <TextContainer>Please select your country code</TextContainer>
              <Select
                options={COUNTRY_CODES}
                value={countryCodePlaceholder}
                onChange={(option) => {
                  if (option) {
                    handleFieldChange("country_code", option?.value);
                  }
                }}
                onMenuOpen={() => {
                  setCountryCodePlaceholder("");
                }}
                onMenuClose={() => {
                  if (countryCodePlaceholder == "") {
                    setCountryCodePlaceholder(
                      COUNTRY_CODES.find(
                        (entry) => entry.value === agent.country_code
                      )
                    );
                  }
                }}
              />
            </div>
            <TextField
              label={
                <TextContainer>
                  WhatsApp Phone Number <div />
                </TextContainer>
              }
              value={agent.number}
              maxLength={15}
              type={"text"}
              placeholder={"Example: 9035026993"}
              error={validatePhoneNo()}
              onChange={(val) => handleFieldChange("number", val)}
              //   helpText={'WhatsApp phone number (should not start with +)'}
            />
          </Stack>
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            label="Agent Name"
            type="text"
            value={agent.name}
            placeholder={"Example: John Doe"}
            error={validateAgentName()}
            onChange={(val) => handleFieldChange("name", val)}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            label="Agent Role"
            type="text"
            value={agent.role}
            placeholder={"Example: Customer Support"}
            onChange={(val) => handleFieldChange("role", val)}
            error={validateAgentRole()}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <Stack vertical>
            <TextContainer>
              <p>Agent Avatars (Profile Image)</p>
            </TextContainer>
            <Stack>
              <label>
                <input
                  type="radio"
                  value={1}
                  checked={1 === agent.avatar_id}
                  onChange={(val) => handleAvatarChange(val)}
                />
                <span>
                  <img
                    className="tmplimg smallImg"
                    src={avatarDictionary[1]}
                    alt=""
                  />
                </span>
              </label>
              <label>
                <input
                  type="radio"
                  value={2}
                  checked={2 === agent.avatar_id}
                  onChange={(val) => handleAvatarChange(val)}
                />
                <span>
                  <img
                    className="tmplimg smallImg"
                    src={avatarDictionary[2]}
                    alt=""
                  />
                </span>
              </label>
              <label>
                <input
                  type="radio"
                  value={3}
                  checked={3 === agent.avatar_id}
                  onChange={(val) => handleAvatarChange(val)}
                />
                <span>
                  <img
                    className="tmplimg smallImg"
                    src={avatarDictionary[3]}
                    alt=""
                  />
                </span>
              </label>
              <label>
                <input
                  type="radio"
                  value={4}
                  checked={4 === agent.avatar_id}
                  onChange={(val) => handleAvatarChange(val)}
                />
                <span>
                  <img
                    className="tmplimg smallImg"
                    src={avatarDictionary[4]}
                    alt=""
                  />
                </span>
              </label>
            </Stack>

            <div className="custom-avatar-url-cont">
              <input
                type="radio"
                value={5}
                checked={5 === agent.avatar_id}
                onChange={() => handleAvatarUrlChange(5)}
              />
              <span className="padding-left"></span>
              <TextField
                label="Avatar URL"
                labelHidden={true}
                type="text"
                value={agent.avatar_url || ""}
                placeholder={"Add URL for custom avatar image"}
                onChange={(val) => handleChange("avatar_url", val)}
                onFocus={() => handleAvatarUrlChange(5)}
                helpText={
                  <p>
                    You can upload a custom avatar image to your
                    <Link
                      url={
                        [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
                          ? `https://manage.wix.com/dashboard/${shop.wixSiteId}/media-manager`
                          : `https://${shop.url}/admin/settings/files`
                      }
                      external={true}
                    >
                      {" "}
                      E-commerce manager &gt; Files{" "}
                    </Link>
                    section and paste the link here. For best results, use
                    images that are atleast 250x250 and square aspect ratio.
                  </p>
                }
              />
            </div>
          </Stack>
        </FormLayout.Group>

        <FormLayout.Group>
          <TextContainer>
            <br />
            <TextStyle variation="strong">
              Online Hours for Chat Agent
            </TextStyle>
            <br />
            <TextStyle variation="subdued">
              Use this if your Chat Agents work in shifts, and you want them to
              be displayed in the Greetings widget only during certain hours of
              the day.
            </TextStyle>
            <br />
            <br />
            <TextStyle variation="subdued">
              Time in 24h format (HHMM or hours minutes). Example - 8:30AM is
              0830 and 8:30PM is 2030. By default, hours are set from 0000 to
              2359 which means chat agent is active all the time. To disable the
              chat agent for an entire day, set 0000 to 0000. The app follows
              your
              <Link
                external={true}
                url={
                  [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
                    ? `https://manage.wix.com/dashboard/${shop.wixSiteId}/settings/language-and-region`
                    : `https://${shop.url}/admin/settings/general/`
                }
              >
                {" "}
                store’s timezone ({props.store_timezone}).
              </Link>
            </TextStyle>
            <br />
            <br />
            <TextStyle variation="subdued">
              <b>Note</b>: If all your Chat Agents are offline at any given
              time, the Chat Button will go into Off mode and won’t be displayed
              on the storefront.
            </TextStyle>
          </TextContainer>
        </FormLayout.Group>

        {renderStoreTimings()}
      </FormLayout>
    );
  };

  const validateCountryCode = () => {
    var cc = /^(?:[1-9]\d{0,5})$/;
    if (!agent.country_code || agent.country_code.match(cc)) {
      return false;
    } else if (agent.country_code && agent.country_code[0] === "0") {
      return "cannot start with 0";
    } else {
      return "Enter a valid country code";
    }
  };

  const validatePhoneNo = () => {
    var phoneno = /^(?:[0-9]\d{0,14})$/;
    if (!agent.number || agent.number.match(phoneno)) {
      return false;
    } else {
      return "Enter a valid phone number";
    }
  };

  const validateAgentName = () => {
    if (agent.name && agent.name.length > 30) {
      return "Maximum 30 characters";
    }
    return false;
  };

  const validateAgentRole = () => {
    if (agent.role && agent.role.length > 30) {
      return "Maximum 30 characters";
    }
    return false;
  };

  const handleChange = (field: any, val: any) => {
    if (!props.agent.avatar_id) {
      props.agent.avatar_id = 1;
    }
    setAgent((agent) => ({ ...agent, [field]: val }));
  };

  const handleAvatarChange = (changeEvent: any) => {
    if (agent.avatar_url && !agent.avatar_url.startsWith("https://")) {
      handleChange("avatar_id", parseInt(changeEvent.target.value));
      handleChange("avatar_url", "");
    } else {
      handleChange("avatar_id", parseInt(changeEvent.target.value));
    }
  };

  const handleAvatarUrlChange = (avatarId: any) => {
    if (!props.agent.avatar_id) {
      props.agent.avatar_id = 1;
    }
    setAgent({ ...agent, avatar_id: avatarId });
  };

  const getDay = (day: number) => {
    switch (day) {
      case 0:
        return "Monday";
      case 1:
        return "Tuesday";
      case 2:
        return "Wednesday";
      case 3:
        return "Thursday";
      case 4:
        return "Friday";
      case 5:
        return "Saturday";
      case 6:
        return "Sunday";
    }
  };
  const renderStoreTimings = () => {
    var timings = [];
    if (agentTimings) {
      for (var i = 0; i < agentTimings.length; i++) {
        timings.push(
          <FormLayout.Group key={i}>
            <Stack vertical spacing="extraTight">
              <TextContainer>{getDay(agentTimings[i].day)}</TextContainer>

              <Stack>
                <TextField
                  label="Start Time"
                  labelHidden={true}
                  value={agentTimings[i].start_time}
                  onChange={handleStartTimeChange(i)}
                  error={
                    validateTime(agentTimings[i].start_time) ||
                    validateStartAndEndTime(
                      agentTimings[i].start_time,
                      agentTimings[i].end_time
                    )
                  }
                />
                <span>To</span>
                <TextField
                  label="End Time"
                  labelHidden={true}
                  value={agentTimings[i].end_time}
                  onChange={handleEndTimeChange(i)}
                  error={validateTime(agentTimings[i].end_time)}
                />
              </Stack>
            </Stack>
          </FormLayout.Group>
        );
      }
    }

    return timings;
  };

  const handleStartTimeChange = (index: any) => {
    return (val: any) => {
      var timings = [...agentTimings];
      setAgentTimings([]);
      for (var i = 0; i < timings.length; i++) {
        if (i === index) {
          var timing = { ...timings[i] };
          timing["start_time"] = val;
          timings[i] = timing;
        }
      }

      setAgentTimings(timings);
    };
  };
  const handleEndTimeChange = (index: any) => {
    return (val: any) => {
      var timings = [...agentTimings];
      setAgentTimings([]);
      for (var i = 0; i < timings.length; i++) {
        if (i === index) {
          var timing = { ...timings[i] };
          timing["end_time"] = val;
          timings[i] = timing;
        }
      }

      setAgentTimings(timings);
    };
  };
  const validateTime = (time: any) => {
    if (time.length !== 4) {
      return "Enter time in format HHMM";
    }

    var err = "Enter time between 0000 and 2359";
    if (
      isNaN(time) ||
      parseInt(time.substring(0, 2)) > 23 ||
      parseInt(time.substring(2, 4)) > 59
    ) {
      return err;
    } else {
      return false;
    }
  };

  const validateStartAndEndTime = (start_time: any, end_time: any) => {
    if (start_time === "0000" && end_time === "0000") {
      return false;
    }
    if (start_time >= end_time)
      return "End time should be greater than start time";
    return false;
  };

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  return (
    <Modal
      open={openModal}
      onClose={() => {
        props.onModalClose();
        setUpdatedObject({});
        setOpenModal(false);
      }}
      title={
        props.isNewNumber
          ? "Add WhatsApp Phone Number"
          : "Edit WhatsApp Phone Number"
      }
      primaryAction={{
        content: "Save",
        onAction: onAgentUpdate,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setErrorToast({ show: false, message: "" });
            props.onModalClose();
            setUpdatedObject({});
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        {errorToastMarkup}
        {getAgentEditForm()}
      </Modal.Section>
    </Modal>
  );
};

export default AgentInfoModal;
