import { useCallback, useEffect, useState } from "react";
import {
  Page,
  Stack,
  TextStyle,
  TextContainer,
  Heading,
  List,
  Link,
  Button,
  Modal,
  TextField,
  RadioButton,
  InlineError,
  Toast,
  Spinner,
} from "@shopify/polaris";
import ChatbotDemoGIF from "../../assets/images/chatbot.gif";
import MobileOutline from "../../assets/images/android_frame.png";
import Sand from "../../assets/images/sand.png";
import Tick from "../../assets/icons/tick.svg";
import styles from "./chatbot.module.scss";
import {
  checkChatbotSubmission,
  submitChatbotForm,
} from "../../redux/actions/pricing";
import { clevertapEventPush, CT_EVENT_IDS } from "../../utils/clevertapUtils";
import { useShopStore } from '../../hooks/shopStore';

function Chatbot(props: any) {
  const { shopDetailsData, shopOwnerDetails } = useShopStore();  
  const [status, setStatus] = useState(false);
  const [statusFetched, setStatusFetched] = useState(false);
  const [active, setActive] = useState(true);
  const [botCapacity, setBotCapacity] = useState(0);
  const [requirementDate, setRequirement] = useState(0);
  const [botPurpopse, setBotPurpose] = useState("");
  const [isTextEmpty, setIsTextEmpty] = useState(true);
  const shop = shopDetailsData;
  const owner = shopOwnerDetails;
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [buttonPressed, setButtonPressed] = useState(false);

  var data = {
    botPurpose: "",
    botCapacity: "",
    requirementDate: "",
    shopName: shop.name,
    shopifyLink: shop.url,
    ownerName: owner.name,
    ownerEmail: owner.email,
  };

  const botCapacityOptions = [
    "0 - 5000",
    "5000 - 15000",
    "15000 - 45000",
    "45000 and above",
  ];

  const requirementDateOptions = [
    "Immediately",
    "2 - 3 Weeks",
    "4 - 5 Weeks",
    "No fixed timelines, I am just exploring",
  ];

  useEffect(() => {
    checkChatbotSubmission(shop.userId).then((res: any) => {
      if (res.status === "success") {
        setStatus(res.data);
      }
      setStatusFetched(true);
    });
    validateChatRequest();
    clevertapEventPush({
      eventId: CT_EVENT_IDS.CHATBOT_PAGE_VIEWED,
      eventData: {},
    });
  }, []);

  const handleChange = useCallback(() => setActive(!active), [active]);

  useEffect(() => {
    data["botPurpose"] = "";
    setBotPurpose("");
    setButtonPressed(false);
  }, [active]);

  const validateChatRequest = () => {
    if (botPurpopse.trim()) {
      setIsTextEmpty(false);
      return true;
    } else {
      setIsTextEmpty(true);
      return false;
    }
  };

  const handleChatbotRequest = () => {
    if (buttonPressed) {
      return;
    }
    validateChatRequest();
    setButtonPressed(true);

    if (validateChatRequest()) {
      data["botPurpose"] = botPurpopse;
      data["botCapacity"] = botCapacityOptions[botCapacity];
      data["requirementDate"] = requirementDateOptions[requirementDate];
      submitChatbotForm(shop.userId, data).then((res: any) => {
        if (res.status === "success") {
          setStatus(true);
          handleChange();
          setButtonPressed(false);
        }
      });
    } else {
      setButtonPressed(false);
      setErrorToast({ show: true, message: "Requirements cannot be empty." });
    }
  };

  const RequestStatus = (status: any) => {
    if (!statusFetched) {
      return (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      );
    }
    if (status) {
      return (
        <Stack wrap={false} alignment="center">
          <Stack.Item>
            <img src={Sand} height="45px" width="45px"></img>
          </Stack.Item>
          <Stack.Item>
            <TextContainer>
              Request Submitted ! Someone from our team will get in touch with
              you soon!{" "}
              <img
                src={Tick}
                height="15px"
                width="15px"
                className={styles.chatbotTickImage}
              ></img>
            </TextContainer>
          </Stack.Item>
        </Stack>
      );
    } else {
      return (
        <div className={styles.button}>
          <Button
            primary
            onClick={() => {
              handleChange();
            }}
          >
            Request Chatbot
          </Button>
        </div>
      );
    }
  };

  const toggleToast = (message: string) => {
    setToast({
      show: !toast.show,
      message: message,
    });
  };

  const toggleErrorToast = (message: string) => {
    setErrorToast({
      show: !errorToast.show,
      message: message,
    });
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        toggleToast("");
      }}
    />
  ) : null;
  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        toggleErrorToast("");
      }}
    />
  ) : null;

  return (
    <Page title="Business Chatbots">
      {toastMarkup}
      {errorToastMarkup}
      <Stack distribution="fill">
        <div className={styles.chatbotLeftColumn}>
          <TextContainer>
            <Heading>
              <TextStyle>
                Why you should consider chatbot for your Business?
              </TextStyle>
            </Heading>
            <TextStyle variation="subdued">
              <br />
              With the right features, chatbots can change the way you do
              business Today , companies have a choice; they can adapt or die
              out by not changing with the times. Some key benefits of
              integrating chatbot with your business are as follows:
              <br /> <br />
            </TextStyle>
          </TextContainer>

          <TextContainer>
            <TextStyle variation="subdued">
              <List type="number">
                <List.Item>Clear Customer Service</List.Item>
                <List.Item>Enormous savings in time and money</List.Item>
                <List.Item>
                  Elimination of tedious and time consuming tasks
                </List.Item>
                <List.Item>Smoother customer journey</List.Item>
                <List.Item>Improved Branding</List.Item>
              </List>
            </TextStyle>
          </TextContainer>

          <TextContainer>
            <TextStyle variation="subdued">
              <br />
              Chatbot takes care of following usecases prominently:
              <br /> <br />
            </TextStyle>
          </TextContainer>

          <TextContainer>
            <TextStyle variation="subdued">
              <List type="number">
                <List.Item>Frequently Asked Questions</List.Item>
                <List.Item>Support and Assistance</List.Item>
                <List.Item>Refund/Replacement Process</List.Item>
                <List.Item>Whatsapp based e-commerce</List.Item>
                <List.Item>Customer Feedback</List.Item>
              </List>
            </TextStyle>
          </TextContainer>

          <TextContainer>
            <TextStyle variation="subdued">
              <br />
              Today, chatbots are a winning formula for any small or big
              business and are an incredible addition to any technology stack.
            </TextStyle>
          </TextContainer>
        </div>
        <div className={styles.chatbotRightColumn}>
          <Stack vertical alignment="center" wrap={false}>
            <Stack.Item>
              <div className={styles.mobileFrame}>
                <img
                  className={styles.device}
                  src={MobileOutline}
                  height="480px"
                  width="255px"
                />
                <img
                  className={styles.screen}
                  src={ChatbotDemoGIF}
                  height="440px"
                  width="239px"
                />
              </div>
            </Stack.Item>
            <Stack.Item>
              <div className={styles.statusArea}>{RequestStatus(status)}</div>
              <br />
              <br />
            </Stack.Item>
          </Stack>
        </div>
      </Stack>
      <div className="modal">
        <Modal open={!active} onClose={handleChange} title="">
          <Modal.Section>
            <br></br>
            <div className="chatbot">
              <Stack vertical spacing="extraTight">
                <TextContainer>
                  How many customers would interact with the bot per month?*
                </TextContainer>
                <RadioButton
                  label={botCapacityOptions[0]}
                  checked={botCapacity === 0}
                  onChange={(_checked, newValue) => {
                    setBotCapacity(0);
                  }}
                />
                <RadioButton
                  label={botCapacityOptions[1]}
                  checked={botCapacity === 1}
                  onChange={(_checked, newValue) => {
                    setBotCapacity(1);
                  }}
                />
                <RadioButton
                  label={botCapacityOptions[2]}
                  checked={botCapacity === 2}
                  onChange={(_checked, newValue) => {
                    setBotCapacity(2);
                  }}
                />
                <RadioButton
                  label={botCapacityOptions[3]}
                  checked={botCapacity === 3}
                  onChange={(_checked, newValue) => {
                    setBotCapacity(3);
                  }}
                />
                <br />
                <TextContainer>
                  How soon you want the bot to be ready ?*
                </TextContainer>
                <RadioButton
                  label={requirementDateOptions[0]}
                  checked={requirementDate === 0}
                  onChange={(_checked, newValue) => {
                    setRequirement(0);
                  }}
                />
                <RadioButton
                  label={requirementDateOptions[1]}
                  checked={requirementDate === 1}
                  onChange={(_checked, newValue) => {
                    setRequirement(1);
                  }}
                />
                <RadioButton
                  label={requirementDateOptions[2]}
                  checked={requirementDate === 2}
                  onChange={(_checked, newValue) => {
                    setRequirement(2);
                  }}
                />
                <RadioButton
                  label={requirementDateOptions[3]}
                  checked={requirementDate === 3}
                  onChange={(_checked, newValue) => {
                    setRequirement(3);
                  }}
                />

                <br />
                <TextContainer>
                  What business problem are you trying to solve with Chatbot?
                  Eg. Product Inquiry, Customer Support, Whatsapp commerce etc.
                  You can have multiple use cases in the same chatbot. Feel free
                  to describe your requirements*
                </TextContainer>
                <TextField
                  label=""
                  value={botPurpopse}
                  multiline={4}
                  onChange={(value) => {
                    setBotPurpose(value);
                  }}
                />
              </Stack>
            </div>
            <br />
            <div className="ButtonFooter">
              <Stack>
                <button
                  className="Polaris-Button cancelButton"
                  type="button"
                  onClick={handleChange}
                >
                  <span className="Polaris-Button__Content">
                    <span className="Polaris-Button__Text">Cancel</span>
                  </span>
                </button>
                <button
                  className="Polaris-Button Polaris-Button--primary saveButton"
                  type="button"
                  onClick={() => handleChatbotRequest()}
                  disabled={buttonPressed}
                >
                  <span className="Polaris-Button__Content">
                    <span className="Polaris-Button__Text">Submit</span>
                  </span>
                </button>
              </Stack>
            </div>
          </Modal.Section>
        </Modal>
      </div>
    </Page>
  );
}

export default Chatbot;
