import { useEffect, useState } from "react";
import ProSupport from "./ProSupport";
import FreeSupport from "./FreeSupport";
import obj from "../../content/Extension/OverlaysContent/en.json";
import TooltipOverlay from "../../common/Tooltip";
import GuideModal from "../../common/GuideModal";
import {
  Page,
  TextContainer,
  TextStyle,
  List,
  Heading,
  Stack,
  Link,
  Layout,
} from "@shopify/polaris";
import Plans from "../Pricing/Plans";
import ExtensionPlanComponent from "../Pricing/Plans/ExtensionPlanComponent";
import {
  recommendedPlans,
  EXTENSION_ONLY_PLAN,
} from "../../constants/Pricing/Plans/constants";
import { PLAN_OLD_FREE, PLAN_NEW_FREE } from "../../constants/constants";
import { updateAddonPlan } from "../../redux/actions/pricing";
import { isHavePermission } from "../../utils/common";
import { clevertapEventPush, CT_EVENT_IDS } from "../../utils/clevertapUtils";
import { useShopStore } from '../../hooks/shopStore';

const Support = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [features] = useState(shopDetailsData?.featuresList);
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);
  const [isExtensionEnabled, setExtensionEnabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [currentPlanId, setCurrentPlanId] = useState(shopDetailsData?.planId);
  const [selectedPlanId, setSelectedPlanId] = useState(
    props.planId ? props.planId : shopDetailsData?.planId
  );
  const [confirmation, setConfirmation] = useState({
    show: false,
    plan: 0,
  });
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });

  useEffect(() => {
    let shop = shopDetailsData;
    if (shop && shop.userId) {
      setExtensionEnabled(isHavePermission(shop, "whatsapp_extension"));
    }
    clevertapEventPush({
      eventId: CT_EVENT_IDS.WHATSAPP_EXTENSION_PAGE_VIEWED,
      eventData: {},
    });
  }, []);

  const handleUpdateAddonPlan = (planId: any, page: any) => {
    activateAddonPlan(planId, page);
  };

  const contactUs = () => {
    setToast({
      show: true,
      message: "ChatBot Section implementation is pending!",
    });
  };

  let shop = shopDetailsData;
  const activateAddonPlan = (plan: any, page: any) => {
    let params = new URLSearchParams();
    params.append("from", "pricing");
    params.append("returnUrl", window.location.origin + "/confirmation");
    setIsLoading(true);
    let planId = Number(currentPlanId).toString();
    if (planId === PLAN_OLD_FREE.toString()) planId = PLAN_NEW_FREE.toString();
    updateAddonPlan(shop.userId, planId, "EXTENSION", params).then(
      async (res: any) => {
        if (res.status === "success" || res === 202) {
          if (res.charge && res.charge.confirmationUrl) {
            window.open(res.charge.confirmationUrl, "_self");
          } else {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.SUBSCRIBE_CLICKED,
              eventData: {
                "Upgrading to": "Extension",
                page: page,
              },
            });
            setToast({ show: true, message: "Plan Activated Successfully" });
          }
          if (Number(planId) === PLAN_OLD_FREE) {
            localStorage.setItem("currentPlan", planId);
            setCurrentPlanId(planId);
            props.setPlanId(planId);
          }
        } else {
          setErrorToast({ show: true, message: res });
        }
        setIsLoading(false);
        setConfirmation({ show: false, plan: 0 });
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };
  const [recommendedPlanPrice, setRecommendedPlanPrice] = useState(
    recommendedPlans[Number(currentPlanId)][1].price
  );
  const [recommendedPlanName, setRecommendedPlanName] = useState(
    recommendedPlans[Number(currentPlanId)][1].planName
  );

  const mobilecheck = () => {
    var check = false;
    //eslint-disable-next-line
    (function (a: any) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const [isMob, setIsMob] = useState(false);
  useEffect(() => {
    if (mobilecheck()) {
      setIsMob(true);
    } else {
      setIsMob(false);
    }
  });

  return (
    <>
      <TooltipOverlay btn1Action={() => setOpenGuideModal(true)} obj={obj} />
      {openGuideModal && (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      )}
      {isExtensionEnabled ? (
        features?.includes("EXTENSION") ? (
          <ProSupport></ProSupport>
        ) : (
          <FreeSupport></FreeSupport>
        )
      ) : isMob ? (
        <Page
          title="Whatsapp Extension"
          // secondaryActions={[
          //   {
          //     content: 'Tutorial video',
          //     icon: PlayCircleMajor,
          //     accessibilityLabel: 'Tutorial Video',
          //     outline: true,
          //     onAction: () => {
          //       console.log('Open Modal');
          //     },
          //   },
          // ]}
        >
          <div>
            <TextContainer>
              <Heading>
                <TextStyle variation="strong">
                  Why you should use Superlemon Chrome extension?
                </TextStyle>
              </Heading>
              <TextStyle variation="subdued">
                <br />
                Superlemon Chrome extension allows you to talk to your customers
                via Whatsapp on a web dashboard. With Chrome extension support
                tool, you can do the following things:
                <br />
                <br />
              </TextStyle>
            </TextContainer>

            <TextContainer>
              <TextStyle variation="subdued">
                <List type="bullet">
                  <List.Item>
                    View customer and order-related details in your Whatsapp
                    chat window for personalized chat support
                  </List.Item>
                  <List.Item>
                    Free-up your agents by increasing thier efficiency.
                    SuperLemon Extension pulls out every detail related to users
                    and their orders on dashboard
                  </List.Item>
                  <List.Item>
                    Minimize the response and resolution time by using handly
                    list of pre-written replies to choose from. You can also
                    create your own templates.
                  </List.Item>
                </List>
              </TextStyle>
            </TextContainer>

            <TextContainer>
              <TextStyle variation="subdued">
                <br />
                Whatsapp Support Extension is not included in your plan. Please
                upgrade to {recommendedPlanName} Plan at just {"$"}
                {recommendedPlanPrice} Or purchase the manual Whatsapp Customer
                Support Tool at just {EXTENSION_ONLY_PLAN.currency}
                {EXTENSION_ONLY_PLAN.price}. Take your business to next level by
                engaging with your customers like a pro!
                <br />
                <br />
                To learn more about plans &nbsp;
                <Link
                  onClick={() => {
                    window.location.href = "/pricing";
                  }}
                >
                  Click here
                </Link>
              </TextStyle>
            </TextContainer>
          </div>
          <div className="nudgeScreenExtensionMob">
            <div id="PlanSection" className="basic_info_plans child">
              <Layout>
                <Stack vertical alignment="center">
                  <ExtensionPlanComponent
                    index={1}
                    planDetails={EXTENSION_ONLY_PLAN}
                    planId={EXTENSION_ONLY_PLAN.planId}
                    selectedPlanId={selectedPlanId}
                    currentPlanId={currentPlanId}
                    isLoading={isLoading}
                    handleUpdatePlan={handleUpdateAddonPlan}
                    contactUs={contactUs}
                  />
                  <TextContainer>
                    Provides access to only <br /> support tool extension
                  </TextContainer>
                </Stack>
              </Layout>
            </div>
            <div className="orCSSMob">
              <Heading>
                <TextStyle variation="positive">OR</TextStyle>
              </Heading>
            </div>
            <div>
              <Plans
                planId={currentPlanId}
                setPlanId={props.setPlanId}
                upgradePlanButton={false}
                setShowUpgradePlanDiv={() => {}}
                shopId={shop.id}
                userId={shop.userId}
                recommendedPlanId={
                  recommendedPlans[Number(currentPlanId)][1].planId
                }
                setRecommendedPlanPrice={setRecommendedPlanPrice}
                setRecommendedPlanName={setRecommendedPlanName}
              />
              <TextContainer>
                Provides access to support tool extension <br />
                plus additional Superlemon features
              </TextContainer>
            </div>
          </div>
        </Page>
      ) : (
        <Page
          title="Whatsapp Extension"
          // secondaryActions={[
          //   {
          //     content: 'Tutorial video',
          //     icon: PlayCircleMajor,
          //     accessibilityLabel: 'Tutorial Video',
          //     outline: true,
          //     onAction: () => {
          //       console.log('Open Modal');
          //     },
          //   },
          // ]}
        >
          <Stack distribution="fillEvenly" wrap={false} alignment="center">
            <div className="child">
              <TextContainer>
                <Heading>
                  <TextStyle variation="strong">
                    Why you should use Superlemon Chrome extension?
                  </TextStyle>
                </Heading>
                <TextStyle variation="subdued">
                  <br />
                  Superlemon Chrome extension allows you to talk to your
                  customers via Whatsapp on a web dashboard. With Chrome
                  extension support tool, you can do the following things:
                  <br />
                  <br />
                </TextStyle>
              </TextContainer>

              <TextContainer>
                <TextStyle variation="subdued">
                  <List type="bullet">
                    <List.Item>
                      View customer and order-related details in your Whatsapp
                      chat window for personalized chat support
                    </List.Item>
                    <List.Item>
                      Free-up your agents by increasing thier efficiency.
                      SuperLemon Extension pulls out every detail related to
                      users and their orders on dashboard
                    </List.Item>
                    <List.Item>
                      Minimize the response and resolution time by using handly
                      list of pre-written replies to choose from. You can also
                      create your own templates.
                    </List.Item>
                  </List>
                </TextStyle>
              </TextContainer>

              <TextContainer>
                <TextStyle variation="subdued">
                  <br />
                  Whatsapp Support Extension is not included in your plan.
                  Please upgrade to
                  {recommendedPlanName} Plan at just {"$"}
                  {recommendedPlanPrice} Or purchase the manual Whatsapp
                  Customer Support Tool at just {EXTENSION_ONLY_PLAN.currency}
                  {EXTENSION_ONLY_PLAN.price}. Take your business to next level
                  by engaging with your customers like a pro!
                  <br />
                  <br />
                  To learn more about plans &nbsp;
                  <Link
                    onClick={() => {
                      window.location.href = "/pricing";
                    }}
                  >
                    Click here
                  </Link>
                </TextStyle>
              </TextContainer>
            </div>
            <div id="PlanSection" className="basic_info_plans child">
              <Layout>
                <Stack vertical alignment="center">
                  <ExtensionPlanComponent
                    page={"Whatsapp Extension"}
                    index={1}
                    planDetails={EXTENSION_ONLY_PLAN}
                    planId={EXTENSION_ONLY_PLAN.planId}
                    selectedPlanId={selectedPlanId}
                    currentPlanId={currentPlanId}
                    isLoading={isLoading}
                    handleUpdatePlan={handleUpdateAddonPlan}
                    contactUs={contactUs}
                  />
                  <TextContainer>
                    Provides access to only <br /> support tool extension
                  </TextContainer>
                </Stack>
              </Layout>
            </div>
            <div className="child">
              <Heading>
                <TextStyle variation="positive">OR</TextStyle>
              </Heading>
            </div>
            <div className="child">
              <Stack vertical alignment="center">
                <Plans
                  page="Whatsapp Extension"
                  planId={currentPlanId}
                  setPlanId={props.setPlanId}
                  upgradePlanButton={false}
                  setShowUpgradePlanDiv={() => {}}
                  recommendedPlanId={
                    recommendedPlans[Number(currentPlanId)][1].planId
                  }
                  userId={shop.userId}
                  setRecommendedPlanPrice={setRecommendedPlanPrice}
                  setRecommendedPlanName={setRecommendedPlanName}
                />
                <TextContainer>
                  Provides access to support <br /> tool extension plus
                  additional <br /> Superlemon features
                </TextContainer>
              </Stack>
            </div>
          </Stack>
        </Page>
      )}
    </>
  );
};

export default Support;
