import { AUTOMATED_PROMOTIONAL_TEMPLATES_WHATSAPP } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

let config = {
  baseURL: AUTOMATED_PROMOTIONAL_TEMPLATES_WHATSAPP.baseUrl,
};

export const getFeedbackTemplateSettings = (userId: string) => {
  return httpClient.get(
    AUTOMATED_PROMOTIONAL_TEMPLATES_WHATSAPP.api.FEEDBACK.replace(
      "$userId",
      userId
    ),
    config
  );
};

export const updateFeedbackTemplateSettings = (userId: string, params: any) => {
  return httpClient.post(
    AUTOMATED_PROMOTIONAL_TEMPLATES_WHATSAPP.api.FEEDBACK.replace(
      "$userId",
      userId
    ),
    params,
    config
  );
};
export const getCrossSellTemplateSettings = (userId: string) => {
  return httpClient.get(
    AUTOMATED_PROMOTIONAL_TEMPLATES_WHATSAPP.api.CROSS_SELL.replace(
      "$userId",
      userId
    ),
    config
  );
};

export const updateCrossSellTemplateSettings = (
  userId: string,
  params: any
) => {
  return httpClient.post(
    AUTOMATED_PROMOTIONAL_TEMPLATES_WHATSAPP.api.CROSS_SELL.replace(
      "$userId",
      userId
    ),
    params,
    config
  );
};
export const getWinbackTemplateSettings = (userId: string) => {
  return httpClient.get(
    AUTOMATED_PROMOTIONAL_TEMPLATES_WHATSAPP.api.WIN_BACK.replace(
      "$userId",
      userId
    ),
    config
  );
};

export const updateWinbackTemplateSettings = (userId: string, params: any) => {
  return httpClient.post(
    AUTOMATED_PROMOTIONAL_TEMPLATES_WHATSAPP.api.WIN_BACK.replace(
      "$userId",
      userId
    ),
    params,
    config
  );
};
