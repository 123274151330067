import {
  PLAN_CHECK,
  PLAN_DETAILS,
  UPDATE_PLAN,
  UPDATE_ADDON_PLAN,
  PLANS_FOR_SHOP,
  PLANS,
  FAQ_CONTACT_US,
  CHATBOT,
  CHATBOT_STATUS,
  UPDATE_PRO_PLAN,
} from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

export const updatePlan = (userId: string, id: string, planObj: any) => {
  return httpClient.post(
    UPDATE_PLAN.replace("$userId", userId).replace("$planId", id),
    planObj
  );
};
export const getPlans = (userId: string) => {
  if (userId) {
    return httpClient.get(PLANS_FOR_SHOP.replace("$userId", userId));
  } else {
    return httpClient.get(PLANS);
  }
};
export const updateAddonPlan = (
  userId: string,
  id: string,
  feature: string,
  planObj: any
) => {
  return httpClient.post(
    UPDATE_ADDON_PLAN.replace("$userId", userId)
      .replace("$planId", id)
      .replace("$feature", feature),
    planObj,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};
export const getPlanDetails = (planId: string) => {
  return httpClient.get(PLAN_DETAILS.replace("$planId", planId));
};
export const checkPlan = (userId: string, chargeId: any) => {
  return httpClient.get(
    PLAN_CHECK.replace("$userId", userId) + "?charge_id=" + chargeId
  );
};

export const submitFAQContactUsForm = (userId: string, params: any) => {
  return httpClient.post(FAQ_CONTACT_US.replace("$userId", userId), params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const submitChatbotForm = (userId: string, params: any) => {
  return httpClient.post(CHATBOT.replace("$userId", userId), params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const submitChatbotForm2 = (userId: string, params: any) => {
  return httpClient.post(CHATBOT_STATUS.replace("$userId", userId), params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const checkChatbotSubmission = (userId: string) => {
  return httpClient.get(CHATBOT_STATUS.replace("$userId", userId));
};

export const updateProPlan = (userId: string, params : any) => {
  return httpClient.post(
    UPDATE_PRO_PLAN.replace("$userId", userId), params
  );
};