import { TextStyle } from "@shopify/polaris";
import styles from "./wallet.module.scss";
import { getWalletInvoice } from "../../redux/actions/wallet";
import { useShopStore } from '../../hooks/shopStore';

const DownloadWalletInvoice = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const downloadWalletInvoice = async () => {
    let shop = shopDetailsData;
    const res: any = await getWalletInvoice(shop.userId);
    if (res?.params) {
      props.setInvoiceUIParams(res.params);
      props.setShowInvoiceUI(true);
    }
    // download(res as unknown as Blob);
  };

  function download(data: Blob) {
    const url = URL.createObjectURL(data);
    const a = document.createElement("a");
    a.download = "invoice.pdf";
    a.href = url;
    a.target = "_self";

    a.click();

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      a.remove();
      URL.revokeObjectURL(url);
    }, 100);
  }

  return (
    <div className={styles.wltBillingCard}>
      <div className={styles.wltBillingCardTitle}>
        <TextStyle variation="strong">{"Last Recharge"}</TextStyle>
      </div>
      <div className={styles.wltBillingCardTxt}>
        <TextStyle variation="subdued">
          {"You  can download your invoice of last month here"}
        </TextStyle>
      </div>
      <button
        className={styles.wltBillingBtn}
        onClick={() => {
          downloadWalletInvoice();
        }}
      >
        {"Download Invoice"}{" "}
      </button>
    </div>
  );
};

export default DownloadWalletInvoice;
