import {
  Card,
  ContextualSaveBar,
  Layout,
  Stack,
  Toast,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import OptinCheckboxesMarkup from "../../../../common/OptinCheckboxesMarkup";
import WidgetLanguageMarkup from "../../../../common/WidgetLanguageMarkup";
import {
  fetchOptinWidgetSettings,
  updateOptinWidgetSettins,
} from "../../../../redux/actions/crmOrders";
import { objectDifference } from "../../../../utils/helper";
import { useShopStore } from '../../../../hooks/shopStore';

const StoreFrontOptinWidget = (props: any) => {
  const { shopDetailsData } = useShopStore();
  let shop = shopDetailsData;

  const [isStoreFrontOptinEnabled, setIsStoreFrontOptinEnabled] =
    useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [allPlacesUnchecked, setAllPlacesUnchecked] = useState(false);

  const [initialData, setInitialData] = useState({
    enabled: false,
    language: "ENGLISH",
    places: [],
  });
  const [optinData, setOptinData] = useState({
    enabled: false,
    language: "ENGLISH",
    places: [],
  });
  const [refOptinData, setRefOptinData] = useState({
    enabled: false,
    language: "ENGLISH",
    places: [],
  });
  const [dataChanges, setDataChanges] = useState<any>({
    optin: null,
    phone: null,
    thresholdValue: null,
  });

  useEffect(() => {
    var diffObj: any = objectDifference(refOptinData, optinData);

    if (Object.keys(diffObj).length > 0) {
      if ("places" in diffObj) {
        diffObj.widgetPlaces = diffObj.places;
        delete diffObj.places;
        setDataChanges((dataChanges: any) => ({
          ...dataChanges,
          optin: diffObj,
        }));
      } else {
        setDataChanges((dataChanges: any) => ({
          ...dataChanges,
          optin: diffObj,
        }));
      }
    } else {
      setDataChanges((dataChanges: any) => ({ ...dataChanges, optin: null }));
    }
  }, [optinData]);

  useEffect(() => {
    if (dataChanges.phone || dataChanges.optin || dataChanges.thresholdValue) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [dataChanges]);

  useEffect(() => {
    if (shop && shop.userId) {
      getOptinSettingData(shop.userId);
    }
  }, []);

  const getOptinSettingData = (userId: string) => {
    fetchOptinWidgetSettings(userId).then((res: any) => {
      if (res.status === "success") {
        setInitialData(res.settings);
        setRefOptinData(res.settings);
        setOptinData(res.settings);
        setIsStoreFrontOptinEnabled(res.settings.enabled);
      } else {
        showErrorToast(res);
      }
    });
  };

  const isPlacesEmpty = () => {
    if (
      dataChanges?.optin?.enabled === true &&
      !dataChanges?.optin?.widgetPlaces
    ) {
      showErrorToast(
        "Since Storefront opt-in widget is enabled, at least one display option needs to be selected"
      );
      return true;
    }
    return false;
  };

  const resetData = () => {
    if (shop && shop.userId) {
      getOptinSettingData(shop.userId);
    }
    setDataChanges({
      phone: null,
      optin: null,
      thresholdValue: null,
    });
    setRefOptinData(initialData);
    setHasChanges(false);
  };

  const updateSettings = async () => {
    if (dataChanges.optin !== null) {
      if (isPlacesEmpty()) return;
      let params = new URLSearchParams();
      var data: any = dataChanges.optin;
      data = { ...data, widgetPlaces: optinData.places };
      Object.keys(data).forEach((key) => {
        params.append(key, data[key]);
      });

      updateOptinWidgetSettins(shop.userId, params).then((response: any) => {
        if (response.status === "success") {
          showToast("Data Saved");
          setInitialData(response.settings);
          setRefOptinData(response.settings);
          setOptinData(response.settings);
          setIsStoreFrontOptinEnabled(response.settings.enabled);
          setDataChanges({ ...dataChanges, optin: null });
          props.refresh();
        } else {
          showErrorToast(response);
        }
      });
    }

    setHasChanges(false);
  };

  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        content: "Save Settings",
        onAction: updateSettings,
      }}
      discardAction={{
        onAction: () => {
          resetData();
        },
      }}
    />
  ) : null;

  const handleOptinChange = (field: string, value: any) => {
    let data = optinData;
    if (field === "enabled") {
      setIsStoreFrontOptinEnabled(value);
      if (value === false) {
        data = { ...data, places: [] };
      }
    }
    data = { ...data, [field]: value };
    setOptinData(data);
  };

  const handlePlaceChange = (data: string[]) => {
    if (isStoreFrontOptinEnabled && data && data.length === 0) {
      showErrorToast(
        "Since Storefront opt-in widget is enabled, at least one display option needs to be selected"
      );
      setHasChanges(false);
      return;
    }
    if (data && data.length > 0) {
      setHasChanges(true);
      setOptinData((optin: any) => ({ ...optin, places: data }));
    }
  };

  return (
    <>
      {toastMarkup}
      {errorToastMarkup}
      {ctxSaveBarMarkup}
      <Card sectioned>
        <WidgetLanguageMarkup
          language={optinData.language}
          enabled={true}
          onLanguageChange={(val: any) => {
            handleOptinChange("language", val);
          }}
        ></WidgetLanguageMarkup>
      </Card>
      <Card sectioned>
        <OptinCheckboxesMarkup
          language={optinData.language}
          places={optinData.places}
          enabled={true}
          onPlaceChange={handlePlaceChange}
          showErrorToast={showErrorToast}
          setAllPlacesUnchecked={setAllPlacesUnchecked}
          isStoreFrontOptinEnabled={isStoreFrontOptinEnabled}
          allPlacesUnchecked={allPlacesUnchecked}
          setErrorToast={setErrorToast}
        ></OptinCheckboxesMarkup>
      </Card>
    </>
  );
};

export default StoreFrontOptinWidget;
