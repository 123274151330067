import { Heading, Modal, Stack, TextContainer } from "@shopify/polaris";

const Release_22_02_03_Modal = (props: any) => {
  const modalContentGuideStep = () => {
    return (
      <TextContainer>
        <p className="release-heading ">
          <Heading>1. Analytics is here!!!</Heading>
        </p>
        <p>
          New and revamped SuperLemon analytics is here!
          <br />
          <br />
          In this section, you will find analytics for all the different
          features of the WhatsApp communication channel.
          <br />
          <br />
          All three plan users will be able to view the analytics for the Share
          widget and Chat widget. Only Ultimate plan users will be able to view
          analytics for Message Log Status, Total Opt-in, and Total messages.
          <br />
          <br />
          You can find Analytics in the left menu bar as shown below:
        </p>
        <img
          src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/1.png?v=1645126382"
          alt="Analytics in Left Navigation"
        />
        <p>
          There are 5 different Analytics available, with more to come in
          future. To know more about the different SuperLemon Analytics, please
          navigate to the Guide in Analytics section as highlighted below:
        </p>
        <img
          className="checkoutOptinModalImage"
          src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/2.png?v=1645126382"
          alt="Analytics Guide Reference"
        />
      </TextContainer>
    );
  };

  const handleClose = () => {
    props.closeModal();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
      title={"Release - 22.02.03"}
      secondaryActions={[
        {
          content: "Close",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>{modalContentGuideStep()}</Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
export default Release_22_02_03_Modal;
