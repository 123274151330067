import { Modal } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';

const ReleaseNotesModal = (props: any) => {
  const history = useHistory();

  return (
    <Modal
      open={props.showReleaseNotes}
      sectioned={true}
      title={props.data.TITLE}
      onClose={() => {
        props.setShowReleaseNotes(false);
      }}
    >
      <Modal.Section>
        <div className={`display-flex-center release-notes d-flex-flow-col`}>
          <div
            dangerouslySetInnerHTML={{
              __html: `${props.data.BODY}`,
            }}
          ></div>
          <div>
            <p>
              We urge you to set up your own WABA soon to prevent disruptions in
              your automated messaging. We've prepared a comprehensive tutorial
              to guide you through the process:{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  color: '#1400ff',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  history.push(props.data.PATH);
                }}
              >
                Click here to view
              </span>{' '}
              📖
              <br />
              <br />
            </p>
            <p>
              Change can be challenging, but we're here to support you every
              step of the way. If you have questions or encounter any hurdles,
              don't hesitate to reach out to us at{' '}
              <a href='mailto:support@superlemon.xyz'>support@superlemon.xyz</a>
              .<br />
              <br />
              <br />
              <br />
            </p>
          </div>
          <div style={{ textAlign: 'center' }}>
            <button
              onClick={() => {
                history.push(props.data.PATH);
              }}
              style={{
                backgroundColor: '#5ec127',
                color: 'white',
                padding: '10px 35px',
                cursor: 'pointer',
              }}
            >
              Create WABA
            </button>
          </div>
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default ReleaseNotesModal;
