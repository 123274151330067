import {
  Checkbox,
  Link,
  RadioButton,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import React from "react";
import {
  COUPON_TYPE_PERCENT,
  COUPON_TYPE_STATIC,
} from "../../constants/constants";

const DiscountMarkup = (props: any) => {
  const {
    enable_discount,
    toggleDiscount,
    discount_percentage,
    handleDiscountChange,
    coupon_code,
  } = props;

  const autoDiscountText = (
    <div className="discountTypeHelpTextFontSize">
      <p>
        % discount &nbsp;
        <TextStyle variation="subdued">
          (coupon code automatically generated)
        </TextStyle>
      </p>
    </div>
  );

  const fixedDiscountText = (
    <div className="discountTypeHelpTextFontSize">
      <p>
        Fixed discount &nbsp;
        <TextStyle variation="subdued">(coupon code provided by you)</TextStyle>
      </p>
    </div>
  );

  return (
    <React.Fragment>
      <TextContainer>
        <Checkbox
          label="Include discount coupon"
          checked={enable_discount}
          onChange={toggleDiscount}
        />
      </TextContainer>

      {props.enable_discount ? (
        <div>
          <div className="verticalMarginTextContainer">
            <TextContainer>
              <p>Discount type</p>
            </TextContainer>
          </div>

          <div className="stackVerticalMarginTopAdjustment">
            <Stack vertical={true}>
              <RadioButton
                label={autoDiscountText}
                checked={props.discount_type === COUPON_TYPE_PERCENT}
                id="auto"
                name="automaticdiscount"
                onChange={() =>
                  props.handleDiscountTypeChange(COUPON_TYPE_PERCENT)
                }
              />
              <RadioButton
                label={fixedDiscountText}
                id="static"
                name="fixeddiscount"
                checked={props.discount_type === COUPON_TYPE_STATIC}
                onChange={() =>
                  props.handleDiscountTypeChange(COUPON_TYPE_STATIC)
                }
              />
            </Stack>
          </div>

          {props.discount_type === COUPON_TYPE_PERCENT ? (
            <div className="input-container inputContainerMarginTopAdjustment">
              <TextField
                label="Discount value (1-100)"
                suffix="%"
                type="number"
                name="discount_percentage"
                disabled={!enable_discount}
                value={discount_percentage.toString()}
                error={props.validateDiscountRatio()}
                onChange={(val) => handleDiscountChange(val)}
              />
              <br />
            </div>
          ) : null}

          {props.discount_type === COUPON_TYPE_STATIC ? (
            <div className="input-container inputContainerMarginTopAdjustment">
              <TextField
                label="Coupon code"
                name="coupon_code"
                placeholder="Example: WHATSAPP20"
                disabled={!enable_discount}
                value={coupon_code}
                error={props.validateFixedCouponCode()}
                onChange={(val) => props.handleCouponChange(val)}
              />
              <div className="couponCodeHelpTextMarginTopAdjustment">
                <p>
                  <TextStyle variation="subdued">
                    Create coupon codes from your{" "}
                    <Link
                      external={true}
                      url={`https://${props.shop.url}/admin/discounts/`}
                    >
                      E-commerce manager {">"} Discounts
                    </Link>
                  </TextStyle>
                </p>
              </div>
              <br />
            </div>
          ) : null}
        </div>
      ) : null}
    </React.Fragment>
  );
};
export default DiscountMarkup;
