import {
  GET_SHARE_THEMES,
  WHEEL,
  WHEEL_DEVICES,
  SHARE_THEME,
  WHEEL_TEMPLATES,
  WHEEL_DATA,
  WHEEL_DEVICES_DELETE,
} from "../../constants/api-constants";
import api from "../../utils/api";
import httpClient from "../../utils/http-client";

export const getWheelDetails = (userId: string) => {
  return new Promise((resolve, reject) => {
    api.get(WHEEL.replace("$userId", userId)).then((response: any) => {
      if (response.status === "success") {
        resolve(response.spinWheel);
      } else {
        reject(response.message);
      }
    });
  });
};

export const updateSelectedWheel = (userId: string, params: any) => {
  return httpClient.post(WHEEL.replace("$userId", userId), params);
};

export const updateWheelDetails = (userId: string, params: any) => {
  return httpClient.post(WHEEL.replace("$userId", userId), params);
};

export const getShareCustomThemes = (userId: string) => {
  return httpClient.get(GET_SHARE_THEMES.replace("$userId", userId));
};

export const updateShareTheme = (
  userId: string,
  buttonId: string,
  params: any
) => {
  return httpClient.post(
    SHARE_THEME.replace("$userId", userId).replace("$buttonId", buttonId),
    params
  );
};

export const getWheelDevices = (userId: string) => {
  return httpClient.get(WHEEL_DEVICES.replace("$userId", userId));
};

export const updateWheelDevices = (userId: string, params: any) => {
  return httpClient.post(WHEEL_DEVICES.replace("$userId", userId), params);
};

export const deleteWheelDevices = (userId: string, params: any) => {
  return httpClient.post(
    WHEEL_DEVICES_DELETE.replace("$userId", userId),
    params
  );
};

export const getWheelTemplates = (userId: string) => {
  return httpClient.get(WHEEL_TEMPLATES.replace("$userId", userId));
};

export const getWheelConfig = (userId: string) => {
  return httpClient.get(WHEEL_DATA.replace("$userId", userId));
};

export const setWheelConfigData = (userId: string, params: any) => {
  return httpClient.post(WHEEL_DATA.replace("$userId", userId), params);
};

export const updateWheelTemplates = (userId: string, params: any) => {
  return httpClient.get(WHEEL_TEMPLATES.replace("$userId", userId), {
    data: params,
  });
};
