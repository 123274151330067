import { useEffect, useState } from "react";
import { Frame, Card, Button, Icon } from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import useToken from "../../hooks/useToken";
import { ONBOARD } from "../../constants/onboarding-constants";
import SuperlemonLogo from "../../assets/images/superlemon-logo_2x.png";
import OnboardingLogo from "../../assets/images/onboarding-one.png";

const OnBoardingFlow = () => {
  const { token } = useToken();
  const history = useHistory();
  const featuresList = ONBOARD.FEATURES_GIF.FEATURES;
  const [showOnboardingMain, setShowOnboardingMain] = useState(true);
  const [showOnboardingFeatures, setShowOnboardingFeatures] = useState(1);

  useEffect(() => {
    if (!token) history.push("/login");
  }, []);

  const startAnimateFeaturesList = () => {
    setShowOnboardingMain(false);
    let i = showOnboardingFeatures + 1;
    let interval = setInterval(() => {
      showFeatureUI(i, interval);
      i++;
    }, 5000);
  };

  const showFeatureUI = (slideNumber: number, interval: any) => {
    if (slideNumber < featuresList.length + 1) {
      setShowOnboardingFeatures(slideNumber);
    } else {
      clearInterval(interval);
      setShowOnboardingFeatures(1);
      startAnimateFeaturesList();
    }
  };

  const stopAnimateFeaturesListAndContinue = () => {
    sessionStorage.setItem("onboarding_flow_seen", "true");
    history.push("/home");
  };

  const onBoardingMain = () => {
    return (
      <>
        <div className="onboarding-main">
          <div className="logo-div">
            <img
              src={SuperlemonLogo}
              alt="Superlemon Logo"
              className="sl-logo"
            />
          </div>
          <div className="fc-card-container">
            <Card>
              <div className="onboarding-container">
                <div className="onbrd-left">
                  <img
                    src={OnboardingLogo}
                    alt="OnboardingLogo"
                    className="onboarding-logo"
                  />
                </div>
                <div className="onbrd-right">
                  <h1 className="onbrd-title">{ONBOARD.LANDING.TITLE}</h1>
                  <p className="onbrd-descp">{ONBOARD.LANDING.DESCPRIPTION}</p>
                  <div className="onbrd-button">
                    <Button
                      onClick={() => startAnimateFeaturesList()}
                      fullWidth
                    >
                      {ONBOARD.LANDING.BUTTON}
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </>
    );
  };

  const featuresView = () => {
    return (
      <>
        <div className="onboarding-main">
          <div className="logo-div">
            <img
              src={SuperlemonLogo}
              alt="Superlemon Logo"
              className="sl-logo"
            />
          </div>
          <div className="fc-card-container">
            <Card>
              <div className="fc-title">{ONBOARD.FEATURES_GIF.TITLE}</div>
              <div className="features-container">
                <div className="fc-left">
                  {ONBOARD.FEATURES_GIF.FEATURES.map((feature: any, index) => {
                    return (
                      <div className="fc-feature" key={index}>
                        <div
                          className={
                            index + 1 === showOnboardingFeatures
                              ? "flexCenterAlign fc-selected-icon"
                              : "flexCenterAlign"
                          }
                        >
                          <Icon source={feature.ICON} />
                        </div>
                        <div className="flexCenterAlign">
                          <div className="fc-name-descp">
                            <span
                              className={
                                index + 1 === showOnboardingFeatures
                                  ? "fc-selected-name"
                                  : ""
                              }
                            >
                              {feature.NAME}
                            </span>
                            {index + 1 === showOnboardingFeatures ? (
                              <span className="fc-selected-descp">
                                {feature.DESCPRIPTION}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="fc-right">
                  <img
                    src={featuresList[showOnboardingFeatures - 1].GIF_FILE}
                    alt="WAChat"
                  />
                </div>
              </div>
              <div className="fc-button">
                <Button onClick={stopAnimateFeaturesListAndContinue}>
                  {ONBOARD.FEATURES_GIF.BUTTON}
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </>
    );
  };

  return (
    <Frame>
      {showOnboardingMain && onBoardingMain()}
      {!showOnboardingMain && featuresView()}
    </Frame>
  );
};

export default OnBoardingFlow;
