// @ts-nocheck
import { Controller, useForm } from 'react-hook-form';
import { FormLayout, TextField, Button, Select } from '@shopify/polaris';
import { Fragment } from 'react';

const CustomForm = (props: any) => {
  let { control, handleSubmit, formState } = useForm();
  if (props.control) control = props.control;

  let { errors } = formState;
  if (props.errors) errors = props.errors;

  const isEmailValid = (email: any) => {
    const regx = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$');
    if (regx.test(email) === true) {
      return true;
    } else {
      return 'Please provide a valid email Id';
    }
  };

  const isTextValid = (value: any) => {
    if (!value || !value.trim()) return 'Cannot be empty string';
    return true;
  };

  const isPasswordValid = (password: any) => {
    const regx = new RegExp(
      '(^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,64}$)'
    );
    if (regx.test(password) === true) {
      return true;
    } else {
      return 'Password must be between 8 to 64 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character';
    }
  };

  const isDropDownValueValid = (value: any) => {
    if (!value || !value.trim()) return 'Please select valid option';
    return true;
  };

  const isAddressValid = (value: any) => {
    if (!value || !value.trim()) return 'Please provide a valid address';
    return true;
  };

  const isPhoneValid = (value: any) => {
    let phoneRegex = new RegExp('^[0-9()+ ]+$');
    if (!value.trim() || !phoneRegex.test(value) || !value)
      return 'Please provide a valid phone number';
    return true;
  };

  const isNameValid = (value: any) => {
    if (!value || !value.trim()) return 'Name is required';
    return true;
  };

  const isStateValid = (value: any) => {
    if (!value || !value.trim()) return 'State is required';
    return true;
  };

  const isCityValid = (value: any) => {
    if (!value || !value.trim()) return 'City is required';
    return true;
  };

  const isZipValid = (value: any) => {
    if (!value || !value.trim()) return 'Zip Code is required';
    return true;
  };

  const validations = {
    text: isTextValid,
    email: isEmailValid,
    password: isPasswordValid,
    dropDown: isDropDownValueValid,
    address: isAddressValid,
    phone: isPhoneValid,
    name: isNameValid,
    state: isStateValid,
    city: isCityValid,
    zip: isZipValid,
  };

  const getFieldElements = () => {
    return props.data.FIELDS.map((inputField: any, index: number) => {
      return (
        <Fragment key={index}>
          {inputField.inputType === 'dropDown' ? (
            <Controller
              name={inputField.name}
              control={control}
              render={({ field }) => (
                <Select
                  label={`${inputField.label} *`}
                  options={inputField.options}
                  {...field}
                />
              )}
              rules={{
                validate: {
                  required:
                    validations[
                      inputField.inputType as keyof typeof validations
                    ],
                },
              }}
            />
          ) : inputField.inputType === 'checkBox' ? (
            <Controller
              name={inputField.name}
              control={control}
              render={({ field }) => (
                <div className='walletAddBalance_checkbox'>
                  <input type='checkbox' {...field} height={20} width={20} />
                  {inputField.label}
                </div>
              )}
              key={inputField.name}
            />
          ) : (
            <Controller
              name={inputField.name}
              control={control}
              render={({ field }) => (
                <TextField
                  label={`${inputField.label} *`}
                  type={inputField?.type ? inputField?.type : 'text'}
                  {...field}
                />
              )}
              rules={{
                validate: {
                  required:
                    validations[
                      inputField.type
                        ? (inputField.type as keyof typeof validations)
                        : 'text'
                    ],
                },
              }}
              key={inputField.name}
            />
          )}
          {errors[inputField.name] && (
            <p className='customForm__ErrorMessage'>
              {errors[inputField.name]?.message}
            </p>
          )}
        </Fragment>
      );
    });
  };

  return (
    <FormLayout>
      {getFieldElements()}
      {props?.elementsBeforeSubmitButton
        ? props?.elementsBeforeSubmitButton()
        : null}
      {props?.data?.BUTTON && (
        <>
          <br />
          <div className={props.data.BUTTON.className}>
            <Button
              primary
              fullWidth
              onClick={handleSubmit(props.handleFormSubmit)}
            >
              {props.data.BUTTON.label}
            </Button>
          </div>
          <br />
        </>
      )}
    </FormLayout>
  );
};

export default CustomForm;
