import { useEffect, useState } from 'react';
import {
  Button,
  Page,
  Modal,
  TextContainer,
  TextStyle,
  List,
  Heading,
  Link,
  RadioButton,
} from '@shopify/polaris';
import { PlusMinor } from '@shopify/polaris-icons';
import CreateCustomTemplate from '../Templates/CustomTemplates/CreateCustomTemplates';
import CustomTemplatesList from '../Templates/CustomTemplates/CustomTemplatesList';
import '../Templates/CustomTemplates/customTemplate.scss';
import { recommendedPlans } from '../../constants/Pricing/Plans/constants';
import Plans from '../Pricing/Plans/index';
import { clevertapEventPush, CT_EVENT_IDS } from '../../utils/clevertapUtils';
import {
  CAMPAIGNS_TEMPLATES_PAGE,
  PLATFORM,
  SHOP_FEATURES,
} from '../../constants/constants';
import NudgeScreenContent from '../../common/NudgeScreen';
import { shopFeaturesFromStoreData } from '../../utils/helper';
import { useShopStore } from '../../hooks/shopStore';

const CampaignsTemplates = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const { privateWabaDetails } = props;
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);
  const [showTemplateFilter, setShowTemplateFilter] = useState(true);
  const [isCustomTemplateEnabled, setCustomTemplateEnabled] = useState(false);
  const [customTemplateLength, setCustomTemplateLength] = useState(0);
  const [openTutorial, setopenTutorial] = useState(false);
  const [currentPlanId, setCurrentPlanId] = useState(shopDetailsData?.planId);
  const [selectedTemplateCategory, setSelectedTemplateCategory] = useState(
    CAMPAIGNS_TEMPLATES_PAGE.TEMPLATE_CATEGORY[0].value
  );
  const [templatecategory, setTemplatecategory] = useState(
    CAMPAIGNS_TEMPLATES_PAGE.TEMPLATE_CATEGORY
  );

  let shop = shopDetailsData || {};

  const handleTemplateCatChangeClick = (curTemplate: string) => {
    setSelectedTemplateCategory(curTemplate);
    templatecategory.map((t) => {
      return curTemplate === t.value ? (t.checked = true) : (t.checked = false);
    });
  };

  useEffect(() => {
    if (shop && shop.id) {
      setCustomTemplateEnabled(shopFeaturesFromStoreData(shop).includes(SHOP_FEATURES.TEMPLATE));
    }
    clevertapEventPush({
      eventId: CT_EVENT_IDS.CUSTOM_TEMPLATE_PAGE_VIEWED,
      eventData: {},
    });
    handleTemplateCatChangeClick(selectedTemplateCategory);
  }, []);

  const handleClick = () => {
    setIsCreateTemplate(!isCreateTemplate);
    handleTemplateCatChangeClick(
      CAMPAIGNS_TEMPLATES_PAGE.TEMPLATE_CATEGORY[0].value
    );
  };

  const setTemplatesLength = (length: any) => {
    setCustomTemplateLength(length);
  };

  const [recommendedPlanPrice, setRecommendedPlanPrice] = useState(
    [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? recommendedPlans[7][1].price
      : recommendedPlans[Number(currentPlanId)][1].price
  );
  const [recommendedPlanName, setRecommendedPlanName] = useState(
    [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? recommendedPlans[7][1].planName
      : recommendedPlans[Number(currentPlanId)][1].planName
  );

  return isCustomTemplateEnabled ? (
    <Page>
      <div>
        WhatsApp templates are essential for businesses to send bulk campaign
        messages and communicate with their customers after the 24-hour
        communication window.
      </div>
      <br />
      <br />
      <div className='flexSpaceBetween'>
        <div className='template-filter'>
          <div style={{ fontWeight: '600' }} className='mr-1rem'>
            {!isCreateTemplate ? CAMPAIGNS_TEMPLATES_PAGE.TITLE : ''}{' '}
          </div>
          <div className='radioButtonBorderFilter'>
            {!isCreateTemplate &&
              templatecategory.map((templateCategory: any, index) => {
                return (
                  <RadioButton
                    label={templateCategory.label}
                    checked={templateCategory?.checked}
                    name='template-select'
                    onChange={() => {
                      handleTemplateCatChangeClick(templateCategory.value);
                    }}
                    key={index}
                  />
                );
              })}
          </div>
        </div>

        <div className='button-design-new'>
          {isCreateTemplate ? (
            <Button
              primary
              onClick={() => handleClick()}
              disabled={
                !(
                  privateWabaDetails?.whatsappVerificationStatus ===
                  'WHATSAPP_VERIFICATION_DONE'
                )
              }
            >
              {CAMPAIGNS_TEMPLATES_PAGE.VIEW_TEMPLATES_BTN}
            </Button>
          ) : (
            <Button
              primary
              icon={PlusMinor}
              onClick={() => {
                clevertapEventPush({
                  eventId: CT_EVENT_IDS.CREATE_CUSTOM_TEMPLATE_CLICKED,
                  eventData: {
                    'Number of templates created': customTemplateLength,
                  },
                });
                handleClick();
              }}
              disabled={
                !(
                  privateWabaDetails?.whatsappVerificationStatus ===
                  'WHATSAPP_VERIFICATION_DONE'
                )
              }
            >
              {CAMPAIGNS_TEMPLATES_PAGE.CREATE_TEMPLATES_BTN}
            </Button>
          )}
        </div>
      </div>
      {isCreateTemplate ? (
        <CreateCustomTemplate
          shop={shop}
          setIsCreateTemplate={setIsCreateTemplate}
        />
      ) : (
        <CustomTemplatesList
          shop={shop}
          privateWabaDetails={privateWabaDetails}
          handleClick={handleClick}
          setTemplatesLength={setTemplatesLength}
          selectedTemplateCategory={selectedTemplateCategory}
          setShowTemplateFilter={setShowTemplateFilter}
          setSelectedTemplateCategory={setSelectedTemplateCategory}
        />
      )}
    </Page>
  ) : (
    <Page>
      <Modal
        open={openTutorial}
        sectioned={true}
        title={'Tutorial video'}
        onClose={() => {
          setopenTutorial(false);
          document.body.classList.remove('tutorial-modal');
        }}
      >
        <iframe
          id='ytplayer'
          width='100%'
          height='360'
          allow='fullscreen'
          src={'https://www.youtube.com/embed/8ug_qyXxqAs'}
          frameBorder='0'
          title={'Extension Tutorial Video'}
        ></iframe>
      </Modal>

      <div className='nudgeScreen-singlebox'>
        <div>
          <TextContainer>
            <Heading>
              <TextStyle variation='strong'>
                Why you should use custom templates?
              </TextStyle>
            </Heading>
            <TextStyle variation='subdued'>
              <br />
              <br />
              With custom template messages, you can send customized WhatApp{' '}
              Messages to your customer. Messages can include:
              <br />
              <br />
            </TextStyle>
          </TextContainer>

          <TextContainer>
            <TextStyle variation='subdued'>
              <List type='bullet'>
                <List.Item>Personalized order updates and reminders</List.Item>
                <List.Item>
                  Promotional & Marketing messages for your existing products
                </List.Item>
                <List.Item>
                  Promotional and Informative messages about your new products
                </List.Item>
                <List.Item>Issue resolution messages </List.Item>
                <List.Item>Payment updates</List.Item>
              </List>
            </TextStyle>
          </TextContainer>

          <TextContainer>
            <TextStyle variation='subdued'>
              <br />
              <br />
              Custom template is not included in your plan. Please upgrade to{' '}
              {recommendedPlanName} Plan at just ${recommendedPlanPrice}. Take
              your business to next level by engaging with your customers like a
              pro!
              <br />
              <br />
              To learn more about plans &nbsp;
              <Link
                onClick={() => {
                  window.location.href = '/pricing';
                }}
              >
                Click here
              </Link>
            </TextStyle>
          </TextContainer>
        </div>
        {[PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform) ? (
          <NudgeScreenContent />
        ) : (
          <Plans
            page='Custom Template'
            planId={currentPlanId}
            setPlanId={props.setPlanId}
            upgradePlanButton={false}
            setShowUpgradePlanDiv={() => {}}
            shopId={shop.id}
            recommendedPlanId={
              recommendedPlans[Number(currentPlanId)][1].planId
            }
            setRecommendedPlanPrice={setRecommendedPlanPrice}
            setRecommendedPlanName={setRecommendedPlanName}
            userId={shop.userId}
          />
        )}
      </div>
    </Page>
  );
};

export default CampaignsTemplates;
