import PageMarkup from "../../common/PageMarkup";
import Analytics from "./Analytics/index";
import MessageLogs from "./MessageLogs/MessageLogs";
import { SHOP_FEATURES } from "../../constants/constants";
import { shopFeaturesFromStoreData } from "../../utils/helper";
import { useShopStore } from '../../hooks/shopStore';

const AnalyticsMessageLogs = () => {
  const tabs = [];
  const tabContent = [];
  const { shopDetailsData } = useShopStore();

  if (shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.ANALYTICS)) {
    tabs.push({
      id: "Analytics",
      content: "Analytics",
      accessibilityLabel: "Analytics",
      panelID: "Analytics",
    });
    tabContent.push(<Analytics />);
  }

  if (shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.LOGS)) {
    tabs.push({
      id: 'MessageLogs',
      content: 'Message Logs',
      accessibilityLabel: 'MessageLogs',
      panelID: 'MessageLogs',
    });
    tabContent.push(<MessageLogs />);
  }

  return (
    <>
      <PageMarkup
        title='Analytics & Logs'
        tabs={tabs}
        tabContent={tabContent}
        selected={0}
      ></PageMarkup>
    </>
  );
};
export default AnalyticsMessageLogs;
