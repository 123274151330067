import { TextContainer, TextStyle } from "@shopify/polaris";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  OPTIN_WIDGETS_PAGE,
  SHOP_FEATURES,
} from "../../../constants/constants";
import { getOptinAnalyticsCount } from "../../../redux/actions/analytics";
import { getOptinWidgetData } from "../../../redux/actions/chat-settings";
import {
  fetchOptinWidgetSettings,
  updateOptinWidgetSettins,
} from "../../../redux/actions/crmOrders";
import { fetchOptinAdminDetails } from "../../../redux/actions/optIn";
import { getWheelDetails } from "../../../redux/actions/spin-the-wheel";
import OptinWidget from "../Optins/OptinsWidget";
import SpinTheWheel from "../SpinTheWheel";
import CheckoutOptinWidget from "./Checkout";
import SettingCard from "./SettingCard";
import StoreFrontOptinWidget from "./StoreFront";
import NoScriptModal from "../../Modals/NoScriptTagModal/NoScriptModal";
import { shopFeaturesFromStoreData } from "../../../utils/helper";
import { useShopStore } from '../../../hooks/shopStore';

const OptinWidgets = (props: any) => {
  const location: any = useLocation();
  const { shopDetailsData } = useShopStore();
  const shop = shopDetailsData;
  const [selectedWidget, setSelectedWidget] = useState(-1);
  const [checkoutOptinRequest, setCheckoutOptinRequest] = useState({
    status: null,
    responseDetails: null,
  });
  const [optinData, setOptinData] = useState({
    enabled: false,
    language: "ENGLISH",
    places: [] as any,
  });
  const [isStoreFrontOptinEnabled, setIsStoreFrontOptinEnabled] =
    useState(true);
  const [storeFrontOptinLanguage, setStoreFrontOptinLanguage] =
    useState("ENGLISH");
  const [isWheelEnabled, setisWheelEnabled] = useState(false);
  const [isOptinEnabled, setIsOptinEnabled] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [data, setData] = useState([]);
  const [stats, setStats] = useState({
    widget: 0,
    checkout: 0,
    spin_wheel: 0,
  });
  const [showNoScriptModal, setShowNoScriptModal] = useState(false);

  const getOptinSettingData = (userId: string) => {
    fetchOptinWidgetSettings(userId).then((res: any) => {
      if (res.status === "success") {
        setIsStoreFrontOptinEnabled(res.settings.enabled);
        setStoreFrontOptinLanguage(res.settings.language);
        setOptinData(res.settings);
      } else {
        props.showErrorToast(res);
      }
    });
  };

  const getOptinAdminDetails = (userId: string) => {
    fetchOptinAdminDetails(userId).then((res: any) => {
      if (res.status === "success") {
        setCheckoutOptinRequest(res.optin);
      } else if (res !== 204) {
        props.showErrorToast(res);
      }
    });
  };

  const fetchWheelData = (userId: string) => {
    getWheelDetails(userId).then(
      (data: any) => {
        setisWheelEnabled(data.is_wheel_enabled);
      },
      (err) => {}
    );
  };

  const fetchOptinWidgetData = (userId: string) => {
    getOptinWidgetData(userId).then(
      (data: any) => {
        setIsOptinEnabled(data.optinEnabled);
      },
      (err) => {}
    );
  };

  const fetchData = (userId: string, start?: string, end?: string) => {
    getOptinAnalyticsCount(userId, start, end).then((res: any) => {
      if (res.status === "success" && res.message) {
        const result: any = {};
        res.message.map((item: any) => {
          const date = moment(item.date).format("YYYY-MM-DD");
          if (!result[date]) {
            result[date] = {
              date: date,
              WIDGET: 0,
              CHECKOUT: 0,
              SPIN_WHEEL: 0,
            };
          }
          result[date][item.source] += item.count;
        });
        setData(Object.values(result));
      }
    });
  };

  useEffect(() => {
    refreshData();
    if (location?.state?.tab === "Optin Widgets") {
      setSelectedWidget(3);
    }
  }, []);

  useEffect(() => {
    if (props.isDataSubmitted) {
      fetchOptinWidgetData(shop.userId);
    }
  }, [props.isDataSubmitted]);

  useEffect(() => {
    props.onError("", "optin");
    props.onChanges(updateData);
  }, [updateData]);

  useEffect(() => {
    setStats({
      widget: data
        .map((element) => element["WIDGET"])
        .reduce((a, b) => a + b, 0),
      checkout: data
        .map((element) => element["CHECKOUT"])
        .reduce((a, b) => a + b, 0),
      spin_wheel: data
        .map((element) => element["SPIN_WHEEL"])
        .reduce((a, b) => a + b, 0),
    });
  }, [data]);

  useEffect(() => {
    if (props.fetchOptinData) {
      refreshData();
      props.setFetchOptinData(false);
    }
  }, [props.fetchOptinData]);

  const refreshData = () => {
    if (shop && shop.userId) {
      getOptinSettingData(shop.userId);
      getOptinAdminDetails(shop.userId);
      fetchWheelData(shop.userId);
      fetchOptinWidgetData(shop.userId);
      let start = moment().subtract(6, "months").format("YYYY-MM-DD");
      let end = moment().format("YYYY-MM-DD");
      fetchData(shop.userId, start, end);
    }
  };

  const renderConfigurationPage = () => {
    switch (selectedWidget) {
      case -1:
        return null;
      case 0:
        return renderStorefrontWidget();
      case 2:
        return renderCheckoutWidget();
      case 3:
        return renderSpinTheWheel();
      case 4:
        return renderOptInViaChatWidget();
    }
  };

  const toggleOptinEnabled = () => {
    if (!shop.scriptTagAdded) {
      setShowNoScriptModal(true);
      return;
    }
    let optinEnabled = isOptinEnabled ? false : true;
    setUpdateData(() => ({
      enabled: optinEnabled,
    }));
    setIsOptinEnabled(optinEnabled);
  };

  const handleOptinChange = (field: string, value: any) => {
    let params = new URLSearchParams();
    params.append("enabled", value);
    params.append("widgetPlaces", optinData.places);

    updateOptinWidgetSettins(shop.userId, params).then((response: any) => {
      if (response.status === "success") {
        setOptinData(response.settings);
        setIsStoreFrontOptinEnabled(response.settings.enabled);
      }
    });
  };

  const renderStorefrontWidget = () => {
    return (
      <>
        <br />
        <div className="topBar">
          <div className="leftTopBar">
            <TextContainer>
              <TextStyle variation="subdued">
                <span
                  onClick={() => {
                    setSelectedWidget(-1);
                  }}
                  className="clickable"
                >
                  {"Storefront Widget"}
                </span>
              </TextStyle>
              {" > "}
              <TextStyle variation="strong">{"Configuration"}</TextStyle>
            </TextContainer>
          </div>
          <div
            className="rightTopBar"
            onClick={() => {
              setSelectedWidget(-1);
            }}
          >
            {"Back"}
          </div>
        </div>
        <br />
        <StoreFrontOptinWidget refresh={refreshData} />
      </>
    );
  };

  const renderSpinTheWheel = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return (
      <>
        <br />
        <div className="topBar">
          <div className="leftTopBar">
            <TextContainer>
              <TextStyle variation="subdued">
                <span
                  onClick={() => {
                    setSelectedWidget(-1);
                  }}
                  className="clickable"
                >
                  {"Spin the Wheel"}
                </span>
              </TextStyle>
              {" > "}
              <TextStyle variation="strong">{"Configuration"}</TextStyle>
            </TextContainer>
          </div>
          <div
            className="rightTopBar"
            onClick={() => {
              setSelectedWidget(-1);
            }}
          >
            {"Back"}
          </div>
        </div>
        <SpinTheWheel
          setToast={props.setToast}
          setErrorToast={props.setErrorToast}
          setShowSuccessToast={props.setShowSuccessToast}
          setShowErrorToast={props.setShowErrorToast}
          refresh={refreshData}
        ></SpinTheWheel>
      </>
    );
  };

  const renderCheckoutWidget = () => {
    return (
      <>
        <br />
        <div className="topBar">
          <div className="leftTopBar">
            <TextContainer>
              <TextStyle variation="subdued">
                <span
                  onClick={() => {
                    setSelectedWidget(-1);
                  }}
                  className="clickable"
                >
                  {"Checkout Widget"}
                </span>
              </TextStyle>
              {" > "}
              <TextStyle variation="strong">{"Configuration"}</TextStyle>
            </TextContainer>
          </div>
          <div
            className="rightTopBar"
            onClick={() => {
              setSelectedWidget(-1);
            }}
          >
            {"Back"}
          </div>
        </div>
        <br />
        <CheckoutOptinWidget
          refresh={refreshData}
          data={checkoutOptinRequest}
          showErrorToast={props.showErrorToast}
        />
      </>
    );
  };

  const renderOptInViaChatWidget = () => {
    return (
      <>
        <br />
        <div className="topBar">
          <div className="leftTopBar">
            <TextContainer>
              <TextStyle variation="subdued">
                <span
                  onClick={() => {
                    setSelectedWidget(-1);
                  }}
                  className="clickable"
                >
                  {"Opt-in via Chat Widget"}
                </span>
              </TextStyle>
              {" > "}
              <TextStyle variation="strong">{"Configuration"}</TextStyle>
            </TextContainer>
          </div>
          <div
            className="rightTopBar"
            onClick={() => {
              setSelectedWidget(-1);
            }}
          >
            {"Back"}
          </div>
        </div>
        <br />
        <OptinWidget
          onChanges={props.onChanges}
          setToast={props.setToast}
          setErrorToast={props.setErrorToast}
          setShowSuccessToast={props.setShowSuccessToast}
          setShowErrorToast={props.setShowErrorToast}
          setHasChanges={props.setHasChanges}
          isDataSubmitted={props.isDataSubmitted}
          reset={props.reset}
          onError={props.onError}
        />
      </>
    );
  };

  return (
    <>
      {selectedWidget === -1 && (
        <>
          <TextContainer>
            <br />
            <TextStyle variation="subdued">
              {OPTIN_WIDGETS_PAGE.header_1}
            </TextStyle>
            <br />
            <TextStyle variation="subdued">
              {OPTIN_WIDGETS_PAGE.header_2}
            </TextStyle>
          </TextContainer>
          <br />
          {!shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.WIDGETS_RESTRICTED) &&
            (
              <>
                <SettingCard
                  type={OPTIN_WIDGETS_PAGE.TYPES.STORE_FRONT}
                  setPage={setSelectedWidget}
                  language={storeFrontOptinLanguage}
                  enabled={isStoreFrontOptinEnabled}
                  disabled={
                    checkoutOptinRequest &&
                    checkoutOptinRequest.status === "APPROVED"
                      ? false
                      : true
                  }
                  stats={stats.widget}
                  handleOptinChange={handleOptinChange}
                />
                <SettingCard
                  type={OPTIN_WIDGETS_PAGE.TYPES.THANK_YOU}
                  enabled={true}
                  language={storeFrontOptinLanguage}
                  disabled={true}
                />
              </>
            )}
          {!shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.WIDGETS_RESTRICTED) && (
            <SettingCard
              type={OPTIN_WIDGETS_PAGE.TYPES.CHECKOUT}
              setPage={setSelectedWidget}
              configured={
                checkoutOptinRequest &&
                checkoutOptinRequest.status === "APPROVED"
                  ? true
                  : false
              }
              stats={stats.checkout}
            />
          )}
          
          <SettingCard
            type={OPTIN_WIDGETS_PAGE.TYPES.SPIN_THE_WHEEL}
            setPage={setSelectedWidget}
            configured={isWheelEnabled}
            stats={stats.spin_wheel}
          />
          <SettingCard
            type={OPTIN_WIDGETS_PAGE.TYPES.OPTIN_VIA_CHAT}
            setPage={setSelectedWidget}
            enabled={isOptinEnabled}
            toggleOptinEnabled={toggleOptinEnabled}
          />
        </>
      )}
      {renderConfigurationPage()}
      <NoScriptModal
        showModal={showNoScriptModal}
        setShowModal={setShowNoScriptModal}
        wixSiteId={shop.wixSiteId}
      />
    </>
  );
};

export default OptinWidgets;
