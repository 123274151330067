import { useEffect, useState } from "react";
import ExportPage from "./ExportPage";
import obj from "../../../content/OptinNumber/OverlaysContent/en.json";
import TooltipOverlay from "../../../common/Tooltip";
import GuideModal from "../../../common/GuideModal";
import LoadingUI from "../../../common/LoadingUI";
import { Button, Page, Toast } from "@shopify/polaris";
import { ExportToExcel } from "../../../common/ExportToExcel";
import { getCustomerOptins } from "../../../redux/actions/optIn";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";
import {
  getWabaDetailsbyShopId,
  loadWabaDetails,
} from "../../../redux/actions/private-waba";
import { CUSTOMER_DOWNLOAD_SENT_VIA_EMAIL, LOADING_SPINNER } from "../../../constants/constants";
import { useShopStore } from '../../../hooks/shopStore';

const AllUsers = ({ showPricingBanner }: any) => {
  
  const { shopDetailsData } = useShopStore();
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [downloadLoaidng, setDownloadLoaidng] = useState(false);
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const [privateWabaDetails, setPvtWabaDetails] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };

  let shop = shopDetailsData;

  useEffect(() => {
    if (shop && shop.userId) {
      loadWabaDetails(shop.userId).then((res: any) => {
        if (res && res.appId && res.whatsappVerificationStatus) {
          setPvtWabaDetails(!!res.appId);
        } else {
          getWabaDetailsbyShopId(shop.userId).then((res: any) => {
            if (res && res.appId && res.whatsappVerificationStatus) {
              setPvtWabaDetails(!!res);
            }
          });
        }
      });
    }
    clevertapEventPush({
      eventId: CT_EVENT_IDS.ALL_CUSTOMERS_PAGE_VIEWED,
      eventData: {},
    });
  }, []);
  
  const getOptinData = () => {
    if (!shop.userId) return;
    setDownloadLoaidng(true);
    getCustomerOptins(shop.userId)
      .then((res: any) => {

      if ( res.status === "success" && res.message && res.message.length > 0){
        setDownloadLoaidng(false);
        ExportToExcel(res.message, "customers");
        clevertapEventPush({
          eventId: CT_EVENT_IDS.OPTED_IN_USER_LIST_DOWNLOADED,
          eventData: {
            "Number of contacts": res.message.length,
            "Private waba": privateWabaDetails,
            "Sent Via Email": false
          },
        });
      } else if ( res.status === "success" && res.email ){
        setDownloadLoaidng(false);
        setToast({
          show: true,
          message: CUSTOMER_DOWNLOAD_SENT_VIA_EMAIL.replace('$email', res.email)
        });
        clevertapEventPush({
          eventId: CT_EVENT_IDS.OPTED_IN_USER_LIST_DOWNLOADED,
          eventData: {
            "Number of contacts": 0,
            "Private waba": privateWabaDetails,
            "Sent Via Email" : true
          },
        });
      } else {
        setDownloadLoaidng(false);
        setErrorToast({
          show: true,
          message: "Customer details not found",
        });
      }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  return (
    <>
      <Page>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px",
            }}
          >
            <div style={{ width: "75%" }}>
              Here is a list of all your customer. Engage, communicate, promote
              and reach out to them to drive sales and customer loyalty like
              never before. To get started, download the list of opted-in users!
            </div>
            <div>
              <Button onClick={getOptinData} primary loading={downloadLoaidng}>
                Download opted-in user list
              </Button>
            </div>
          </div>
          <ExportPage
            setToast={setToast}
            setErrorToast={setErrorToast}
            setShowErrorToast={setErrorToast}
            setShowSuccessToast={setToast}
          ></ExportPage>
        </>{" "}
        {toastMarkup}
        {errorToastMarkup}
      </Page>
      <TooltipOverlay
        btn1Action={() => setOpenGuideModal(true)}
        obj={obj}
        showPricingBanner={showPricingBanner}
      />
      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      ) : null}

      {downloadLoaidng && (
        <LoadingUI note={LOADING_SPINNER.CUSTOMER_DETAILS_NOTE} />
      )}
    </>
  );
};
export default AllUsers;
