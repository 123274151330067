// @ts-nocheck
import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Heading,
  Link,
  Modal,
  Page,
  ProgressBar,
  Spinner,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
  Popover,
  ActionList,
} from '@shopify/polaris';
import { PlayCircleMajor } from '@shopify/polaris-icons';
import {
  PLANS,
  recommendedPlans,
  TILE,
} from '../../constants/Pricing/Plans/constants';
import Plans from '../Pricing/Plans';
import {
  PLAN_ENGAGE_WIX,
  PLAN_ENTERPRISE,
  PLATFORM,
  TEAM_INBOX,
  TUTORIAL_VIDEOS,
} from '../../constants/constants';
import './team-inbox.scss';
import Sand from '../../../src/assets/images/sand.png';
import Tick from '../../../src/assets/icons/tick.svg';
import {
  getTeamInboxStatus,
  sendTeamInboxOTP,
  verifyTeamInboxOTP,
} from '../../redux/actions/teamInbox';
import qs from 'qs';
import {
  getWabaDetailsbyShopId,
  loadWabaDetails,
} from "../../redux/actions/private-waba";
import { useHistory } from "react-router-dom";
import PlanStatus from "../HomePage/PlanStatus";
import { fetchPhoneSettings } from "../../redux/actions/crmOrders";
import { clevertapEventPush, CT_EVENT_IDS } from "../../utils/clevertapUtils";
import { getAgents } from "../../redux/actions/agents";
import { getplanPaymentInfo } from "../../utils/helper";
import PaymentFailed from "../../common/PaymentFailed";
import NudgeScreenContent from "../../common/NudgeScreen";
import { useShopStore } from '../../hooks/shopStore';

function TeamInbox(props: any) {
  const { shopDetailsData, shopOwnerDetails, planPaymentInfo } = useShopStore();
  const shop = shopDetailsData;
  const owner = shopOwnerDetails;
  const showPaymentFailedUI = getplanPaymentInfo(planPaymentInfo);

  const currentPlanId = shopDetailsData?.planId;
  const [recommendedPlanPrice, setRecommendedPlanPrice] = useState(
    [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? recommendedPlans[7][1].price
      : PLANS[5].price
  );
  const [recommendedPlanName, setRecommendedPlanName] = useState(
    [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? recommendedPlans[7][1].planName
      : PLANS[5].planName
  );
  const [status, setStatus] = useState(TEAM_INBOX.INITIAL_STATE);
  const [emailField, setEmailField] = useState('');
  const [optStep, setOtpStep] = useState(0);
  const [otp, setOtp] = useState({
    first: '',
    second: '',
    third: '',
    fourth: '',
  });
  const history = useHistory();
  const [pvtWabaDetails, setPvtWabaDetails]: any = useState();
  const [url, setUrl] = useState('');
  const [agents, setAgents] = useState<any>();
  const [tutorialVideos, setTutorialVideos] = useState([]);
  const [popoverActive, setPopoverActive] = useState(false);
  const [videoTutorial, setVideoTutorial] = useState({
    open: false,
    videoSrc: '',
  });
  const togglePopoverActive = () => {
    setPopoverActive(!popoverActive);
  };

  const [errorToast, setErrorToast] = useState({ show: false, message: '' });
  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const handleEmailFieldChange = useCallback(
    (value) => setEmailField(value),
    []
  );
  const handleClearButtonClick = useCallback(() => setEmailField(''), []);

  const [timer, setTimer] = useState(0);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const resetTimer = function () {
    if (timer <= 0) {
      setTimer(45);
    }
  };

  const [modalActive, setModalActive] = useState(true);
  const handleChange = useCallback(
    () => setModalActive(!modalActive),
    [modalActive]
  );
  const [emailButtonPressed, setEmailButtonPressed] = useState(false);
  const [otpButtonPressed, setOtpButtonPressed] = useState(false);
  const [loading, setLoading] = useState(
    [PLAN_ENTERPRISE, PLAN_ENGAGE_WIX].includes(currentPlanId) ? true : false
  );

  const showVideoModal = (videoLinks: string) => {
    setVideoTutorial({ open: true, videoSrc: videoLinks });
  };

  useEffect(() => {
    let tmpData: any = [];
    TUTORIAL_VIDEOS.SHARED_INBOX_PAGE.forEach((video: any) => {
      let tmpStep = {
        content: video.content,
        onAction: () => showVideoModal(video.videoSrc),
      };
      tmpData.push(tmpStep);
    });
    setTutorialVideos(tmpData);
  }, []);

  useEffect(() => {
    if ([PLAN_ENTERPRISE, PLAN_ENGAGE_WIX].includes(currentPlanId)) {
      getTeamInboxStatus(shop.userId).then((res: any) => {
        if (
          res === TEAM_INBOX.APPLICATION_NOT_PRESENT ||
          [
            TEAM_INBOX.DELETION_SHEET_UPDATED,
            TEAM_INBOX.OTP_SENT,
            TEAM_INBOX.ACCOUNT_DELETED,
          ].includes(res.data.status)
        ) {
          setStatus(TEAM_INBOX.APPLICATION_NOT_SUBMITTED);
        } else if (
          [TEAM_INBOX.LEADS_SHEET_UPDATED, TEAM_INBOX.OTP_VERIFIED].includes(
            res.data.status
          )
        ) {
          setStatus(TEAM_INBOX.APPLICATION_PROCESSING);
        } else if (res.data.status === TEAM_INBOX.ACCOUNT_LIVE) {
          setStatus(TEAM_INBOX.APPLICATION_PROCESSED);
          setUrl(res.data.userLoginLink);
        }
      });
      setOtpStep(0);
      getEmbeddedWabaDetails();
      getPhoneData(shop.userId);
      getAgents(shop.userId).then((response: any) => {
        setAgents(response.agents);
        setLoading(false);
      });
    }
    clevertapEventPush({
      eventId: CT_EVENT_IDS.SHARED_TEAM_INBOX_PAGE_VIEWED,
      eventData: {
        accessible: [PLAN_ENTERPRISE, PLAN_ENGAGE_WIX].includes(currentPlanId)
          ? true
          : false,
      },
    });
  }, []);

  const getEmbeddedWabaDetails = () => {
    if (shop && shop.userId) {
      loadWabaDetails(shop.userId).then((res: any) => {
        if (res && res.appId && res.whatsappVerificationStatus) {
          setPvtWabaDetails(res);
        } else {
          getWabaDetailsbyShopId(shop.userId).then((res: any) => {
            if (res && res.appId && res.whatsappVerificationStatus) {
              setPvtWabaDetails(res);
            }
          });
        }
      });
    }
  };

  const [phoneData, setPhoneData] = useState({
    countryCode: '',
    phone: '',
  });

  const getPhoneData = (userId: string) => {
    fetchPhoneSettings(userId).then((res: any) => {
      if (res.status === 'success') {
        setPhoneData(res.settings);
      }
    });
  };

  const checkTile1 = () => {
    let phone;
    if (
      pvtWabaDetails &&
      phoneData.hasOwnProperty('countryCode') &&
      phoneData?.countryCode
    ) {
      if (pvtWabaDetails.hasOwnProperty('number')) {
        phone = pvtWabaDetails.number;
      } else if (pvtWabaDetails.hasOwnProperty('wabaNumber')) {
        phone = pvtWabaDetails.wabaNumber;
      }
      return Number(phoneData?.countryCode.concat(phoneData?.phone)) ===
        Number(phone)
        ? false
        : true;
    } else {
      return false;
    }
  };

  const checkTile2 = () => {
    var res = true;
    if (pvtWabaDetails) {
      var phone = pvtWabaDetails.hasOwnProperty('number')
        ? pvtWabaDetails.number
        : pvtWabaDetails.wabaNumber;
      agents.forEach((element: any) => {
        if (element.phone == phone) {
          res = false;
        }
      });
      return res;
    } else {
      return false;
    }
  };

  const populateRightContent = () => {
    if (
      ![PLAN_ENGAGE_WIX].includes(currentPlanId) &&
      [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
    ) {
      return <NudgeScreenContent />;
    }
    if (
      ![PLAN_ENTERPRISE].includes(currentPlanId) &&
      shop.platform === PLATFORM.SHOPIFY
    ) {
      return (
        <Plans
          page='Shared Team Inbox'
          planId={currentPlanId}
          setPlanId={props.setPlanId}
          upgradePlanButton={false}
          setShowUpgradePlanDiv={() => {}}
          shopId={shop.id}
          userId={shop.userId}
          recommendedPlanId={PLAN_ENTERPRISE}
          setRecommendedPlanPrice={setRecommendedPlanPrice}
          setRecommendedPlanName={setRecommendedPlanName}
        />
      );
    } else if (status === TEAM_INBOX.APPLICATION_NOT_SUBMITTED) {
      return optStep === 0 ? (
        <div className='rightColumnContent'>
          <TextField
            label='Email ID of Admin'
            type='email'
            value={emailField}
            onChange={handleEmailFieldChange}
            onClearButtonClick={handleClearButtonClick}
            helpText='Admin would have access to add people & do various configuration for shared team inbox tool'
            clearButton
            autoComplete='email'
          />
          <br />
          <Button
            primary
            disabled={emailButtonPressed}
            onClick={() => {
              if (emailButtonPressed) {
                return;
              }
              setEmailButtonPressed(true);
              let isPrivateWABAEnabled =
                localStorage.getItem('private_waba') === 'true' ? true : false;
              if (!isPrivateWABAEnabled) {
                handleChange();
                setEmailButtonPressed(false);
                return;
              }
              if (emailField == '') {
                showErrorToast('Please enter Admin Email ID');
                setEmailButtonPressed(false);
                return;
              }
              // Email Regex Check
              // if( ! /(.+)@(.+){2,}\.(.+){2,}/.test(emailField) ){
              //   showErrorToast("Please enter a valid email address");
              //   setEmailButtonPressed(false);
              //   return ;
              // }
              let data = {
                adminEmail: emailField,
              };
              sendTeamInboxOTP(shop.userId, qs.stringify(data)).then(
                (res: any) => {
                  if (res) {
                    clevertapEventPush({
                      eventId: CT_EVENT_IDS.SHARED_TEAM_INBOX_APPLIED,
                      eventData: { state: 'Submitted Email ID' },
                    });
                    resetTimer();
                    setOtpStep(1);
                  }
                }
              );
            }}
          >
            Submit
          </Button>
        </div>
      ) : (
        <div className='rightColumnContent-otp'>
          <Stack vertical alignment='center'>
            <div>
              Enter OTP
              <br />
              <br />
            </div>
            <div className='otpField'>
              <TextField
                label=''
                id='first'
                value={otp.first}
                onChange={(value) => {
                  if (value.length == 4) {
                    setOtp({
                      first: String(Array.from(value)[0]),
                      second: String(Array.from(value)[1]),
                      third: String(Array.from(value)[2]),
                      fourth: String(Array.from(value)[3]),
                    });
                  } else {
                    value = String(Array.from(value)[0]);
                    if (value == 'undefined') {
                      setOtp((otp) => ({
                        ...otp,
                        first: '',
                      }));
                    } else {
                      setOtp((otp) => ({
                        ...otp,
                        first: value,
                      }));
                      const element = document.getElementById('second');
                      element?.focus();
                    }
                  }
                }}
                autoFocus={true}
                connectedRight={
                  <TextField
                    label=''
                    id='second'
                    value={otp.second}
                    onChange={(value) => {
                      value = String(Array.from(value)[0]);
                      if (value == 'undefined') {
                        setOtp((otp) => ({
                          ...otp,
                          second: '',
                        }));
                        const element = document.getElementById('first');
                        element?.focus();
                      } else {
                        setOtp((otp) => ({
                          ...otp,
                          second: value,
                        }));
                        const element = document.getElementById('third');
                        element?.focus();
                      }
                    }}
                    connectedRight={
                      <TextField
                        label=''
                        id='third'
                        value={otp.third}
                        onChange={(value) => {
                          value = String(Array.from(value)[0]);
                          if (value == 'undefined') {
                            setOtp((otp) => ({
                              ...otp,
                              third: '',
                            }));
                            const element = document.getElementById('second');
                            element?.focus();
                          } else {
                            setOtp((otp) => ({
                              ...otp,
                              third: value,
                            }));
                            const element = document.getElementById('fourth');
                            element?.focus();
                          }
                        }}
                        connectedRight={
                          <TextField
                            label=''
                            id='fourth'
                            value={otp.fourth}
                            onChange={(value) => {
                              value = String(Array.from(value)[0]);
                              if (value == 'undefined') {
                                setOtp((otp) => ({
                                  ...otp,
                                  fourth: '',
                                }));
                                const element =
                                  document.getElementById('third');
                                element?.focus();
                              } else {
                                setOtp((otp) => ({
                                  ...otp,
                                  fourth: value,
                                }));
                              }
                            }}
                          />
                        }
                      />
                    }
                  />
                }
              />
              <br />
            </div>
            <Button
              primary
              disabled={otpButtonPressed}
              onClick={() => {
                if (otpButtonPressed) {
                  return;
                }
                setOtpButtonPressed(true);
                let data = {
                  otp: Number(otp.first + otp.second + otp.third + otp.fourth),
                  // 'emailIdAdmin' : emailField ,
                  // 'userId' : shop.id,
                  // 'apiKey' : pvtWabaDetails.apiKey ?  pvtWabaDetails.apiKey : '9g2jrc9ftq3iyrv42yhybg68bnthofl8',
                  // 'shopifyShopName' : shop.name,
                  // 'urlPreference' : shop.name + "-" + shop.userId ,
                  // 'wabaNumber' : pvtWabaDetails.number,
                  // 'wabaId' : pvtWabaDetails.appId ,
                  // 'appName' : pvtWabaDetails.appName ? pvtWabaDetails.appName : "SuperLemon"
                };
                if (data.otp === 0) {
                  showErrorToast('Please enter OTP');
                  setOtpButtonPressed(false);
                  return;
                }
                verifyTeamInboxOTP(shop.userId, qs.stringify(data)).then(
                  (res: any) => {
                    if (res === TEAM_INBOX.INCORRECT_OTP) {
                      showErrorToast('Incorrect OTP. Please try again');
                      setOtpButtonPressed(false);
                    } else {
                      clevertapEventPush({
                        eventId: CT_EVENT_IDS.SHARED_TEAM_INBOX_APPLIED,
                        eventData: { state: 'OTP Verified' },
                      });
                      setStatus(TEAM_INBOX.APPLICATION_PROCESSING);
                    }
                  }
                );
              }}
            >
              Submit
            </Button>
            <br />
            <div className='bottomRow-button'>
              <Button
                plain
                textAlign='center'
                onClick={() => {
                  let data = {
                    adminEmail: emailField,
                    userId: shop.id,
                  };
                  sendTeamInboxOTP(shop.userId, qs.stringify(data));
                  resetTimer();
                }}
                disabled={timer > 0}
              >
                Resend OTP {timer > 0 ? '(' + String(timer) + ')' : ' '}
              </Button>
              <br />
              <Button
                plain
                textAlign='center'
                onClick={() => {
                  setOtpStep(0);
                  setOtp({ first: '', second: '', third: '', fourth: '' });
                  setEmailButtonPressed(false);
                  handleClearButtonClick();
                  setTimer(0);
                }}
              >
                Re-enter Email ID
              </Button>
            </div>
          </Stack>
        </div>
      );
    }
  };

  const populatePageContent = () => {
    return status === TEAM_INBOX.APPLICATION_PROCESSED ? (
      <Stack vertical alignment='center'>
        <br />
        <br />
        <img src={Tick} height={'100px'} width={'100px'}></img>
        <div className='requestSubmitted_text'>
          <TextContainer>
            Shared Team Inbox created successfully. Please note that User ID and
            Password are sent to the Admin Email ID.
          </TextContainer>
        </div>
        <div className='greenLine'>
          <ProgressBar
            progress={100}
            size='small'
            color='success'
            animated={false}
          />
        </div>
        <Button
          primary
          onClick={() => {
            window.open('https://' + url, '_blank');
          }}
        >
          Try Now
        </Button>
        <br />
        {phoneData.hasOwnProperty('countryCode') &&
          (checkTile1() || checkTile2()) && (
            <PlanStatus
              planId={TILE}
              setIsPlanStatusVisible={true}
              wabaNumber={
                pvtWabaDetails.number
                  ? pvtWabaDetails.number
                  : pvtWabaDetails.wabaNumber
              }
              primaryNumber={
                checkTile1()
                  ? phoneData.countryCode.concat(phoneData.phone)
                  : agents[0].phone
              }
              variation={{ admin: checkTile1(), agent: checkTile2() }}
            />
          )}
      </Stack>
    ) : (
      <Stack vertical alignment='center'>
        <br />
        <br />
        <img src={Sand}></img>
        <div className='requestSubmitted_text'>
          <TextContainer>
            Hold tight ! your shared team inbox request has been submitted. You
            will receive an email from support@superlemon.xyz in the next 24
            hours regarding the steps for activation.
          </TextContainer>
        </div>
        <div style={{ width: 600 }}>
          <ProgressBar progress={100} size='small' color='success' />
        </div>
      </Stack>
    );
  };

  const activator = (
    <div className='videoTutsButton'>
      <Button icon={PlayCircleMajor} onClick={togglePopoverActive} disclosure>
        {TUTORIAL_VIDEOS.TITLE}
      </Button>
    </div>
  );

  return (
    <>
      {showPaymentFailedUI.downgraded ? (
        <PaymentFailed failedOn={showPaymentFailedUI.downgradedOn} />
      ) : (
        <>
          <div className='flexrightAlign'>
            <Popover
              active={popoverActive}
              activator={activator}
              onClose={togglePopoverActive}
            >
              <ActionList actionRole='tutorialVideo' items={tutorialVideos} />
            </Popover>
          </div>
          {(status === TEAM_INBOX.INITIAL_STATE || loading) &&
          [PLAN_ENTERPRISE, PLAN_ENGAGE_WIX].includes(currentPlanId) ? (
            <Page title='Shared Team Inbox'>
              <br /> <br /> <br /> <br />
              <div className='spinnerMid'>
                <Spinner />
              </div>
            </Page>
          ) : [
              TEAM_INBOX.APPLICATION_PROCESSED,
              TEAM_INBOX.APPLICATION_PROCESSING,
            ].includes(status) ? (
            <Page title='Shared Team Inbox'>
              {errorToastMarkup}
              {populatePageContent()}
            </Page>
          ) : (
            <Page title='Shared Team Inbox'>
              {errorToastMarkup}
              <div
                className={
                  [PLAN_ENTERPRISE, PLAN_ENGAGE_WIX].includes(currentPlanId)
                    ? 'nudgeScreen-teaminbox-enterprise'
                    : 'nudgeScreen-teaminbox-adjusted'
                }
              >
                <div
                  className={
                    status == 'APPLICATION_NOT_SUBMITTED'
                      ? 'nudgeScreen-leftColumn'
                      : 'nudgeScreen-leftColumn-padded'
                  }
                >
                  <TextContainer>
                    <Heading>
                      <TextStyle variation='strong'>
                        Why you should use Shared Team Inbox?
                      </TextStyle>
                    </Heading>
                    <TextStyle variation='subdued'>
                      <br />
                      Managing your customers approaching you over various
                      channels can be overwhelming. Streamline all your customer
                      chats to a single platform with Superlemon shared team
                      inbox. Let your support team members collaborate from
                      multiple devices over a single platform.
                    </TextStyle>
                  </TextContainer>

                  <TextContainer>
                    <TextStyle variation='subdued'>
                      <br />
                      You can assign, prioritize, tag & reply to customer chats
                      from Superlemon shared team inbox. Use auto-reply & quick
                      reply messages to boost efficiency of support team. Manage
                      thousands of chats with ease with Superlemon ! You also
                      get access to detailed reports on customer chats
                    </TextStyle>
                    <br />
                    <br />
                    {[PLAN_ENTERPRISE, PLAN_ENGAGE_WIX].includes(
                      currentPlanId
                    ) ? null : (
                      <TextStyle variation='subdued'>
                        Please upgrade to {recommendedPlanName} Plan to get
                        started with Shared Team Inbox.
                        <br />
                        <br />
                        To learn more about plans &nbsp;
                        <Link
                          onClick={() => {
                            window.location.href = '/pricing';
                          }}
                        >
                          Click here
                        </Link>
                        <br />
                        <br />
                      </TextStyle>
                    )}
                  </TextContainer>
                </div>
                <div className='nudgeScreen-rightColumn'>
                  {populateRightContent()}
                </div>
              </div>
              <Modal open={!modalActive} onClose={handleChange} title='' small>
                <Modal.Section>
                  <br />
                  <TextContainer>
                    <Stack vertical alignment='center'>
                      <div className='modalHeading'>
                        Oops ! Whatsapp Business Account required
                      </div>
                      <div>
                        Please note that to use Shared Team Inbox, Whatsapp
                        Business Account is mandatory. You have not created your
                        Whatsapp Business Account yet.
                      </div>
                      <div>
                        <Button
                          primary
                          onClick={() => {
                            history.push('/private-waba');
                          }}
                        >
                          Create Now
                        </Button>
                      </div>
                    </Stack>
                  </TextContainer>
                </Modal.Section>
              </Modal>
            </Page>
          )}

          <Modal
            open={videoTutorial.open}
            sectioned={true}
            title={TUTORIAL_VIDEOS.TITLE}
            onClose={() => {
              setVideoTutorial({ open: false, videoSrc: '' });
            }}
          >
            <iframe
              id='ytplayer'
              width='100%'
              height='360'
              allow='fullscreen'
              src={videoTutorial.videoSrc}
              frameBorder='0'
              title={TUTORIAL_VIDEOS.TITLE}
            ></iframe>
          </Modal>
        </>
      )}
    </>
  );
}

export default TeamInbox;
