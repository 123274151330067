export const CT_EVENT_IDS = {
  APP_LAUNCHED: 'App launched',
  ANALYTICS_PAGE_VIEWED: 'Analytics page viewed',
  CHAT_SETTINGS_PAGE_VIEWED: 'Chat settings page viewed',
  SHARE_SETTINGS_PAGE_VIEWED: 'Share settings page viewed',
  SPIN_THE_WHEEL_SETTINGS_PAGE_VIEWED: 'Spin the Wheel settings page viewed',
  AUTOMATED_TEMPLATES_PAGE_VIEWED: 'Automated templates page viewed',
  AUTOMATED_PROMOTIONAL_TEMPLATES_PAGE_VIEWED:
    'Automated promotional templates page viewed',
  CUSTOM_TEMPLATE_PAGE_VIEWED: 'Custom template page viewed',
  EXTENSION_TEMPLATE_PAGE_VIEWED: 'Extension template page viewed',
  ABANDONED_CART_MANUAL_MESSAGING_PAGE_VIEWED:
    'Abandoned Cart Manual Messaging page viewed',
  ORDERS_MANUAL_MESSAGING_PAGE_VIEWED: 'Orders Manual Messaging page viewed',
  MANUAL_MESSAGING_SETTINGS_PAGE_VIEWED:
    'Manual Messaging Settings page viewed',
  MESSAGE_LOGS_PAGE_VIEWED: 'Message logs page viewed',
  ADMIN_CONFIGURATIONS_PAGE_VIEWED: 'Admin Configurations page viewed',
  WHATSAPP_BUSINESS_ACCOUNT_PAGE_VIEWED:
    'Whatsapp business account page viewed',
  WHATSAPP_APPLICATION_PAGE_VIEWED: 'WABA Application Page viewed',
  WHATSAPP_EXTENSION_PAGE_VIEWED: 'Whatsapp Extension page viewed',
  ALL_CUSTOMERS_PAGE_VIEWED: 'All Customers page viewed',
  CUSTOMER_SEGMENT_PAGE_VIEWED: 'Customer segment page viewed',
  PLANS_PAGE_VIEWED: 'Plans page viewed',
  CHATBOT_PAGE_VIEWED: 'Chatbot page viewed',
  WRITE_A_REVIEW_CLICKED: 'Write a review clicked',
  LOGGED_OUT: 'Logged out',
  EMAIL_SUPPORT_CLICKED: 'Email support clicked',
  APP_LANGUAGE_CHANGED: 'App language changed',
  GUIDE_STARTED: 'Guide started',
  GUIDE_COMPLETED: 'Guide completed',
  GUIDE_CLOSED: 'Guide closed',
  WIDGET_ENABLED: 'Widget enabled',
  WIDGET_DISABLED: 'Widget disabled',
  TUTORIAL_VIDEO_VIEWED: 'Tutorial video viewed',
  TUTORIAL_VIDEO_CLOSED: 'Tutorial video closed',
  AUTOMATED_TEMPLATE_MESSAGE_ENABLED: 'Automated template message enabled',
  AUTOMATED_TEMPLATE_MESSAGE_DISABLED: 'Automated template message disabled',
  AUTOMATED_TEMPLATE_MESSAGE_PREVIEWED: 'Automated template message previewed',
  AUTOMATED_TEMPLATE_MESSAGE_LANGUAGE_CHANGED:
    'Automated template message language changed',
  EXTENSION_TEMPLATE_CREATED: 'Extension template created',
  EXTENSION_TEMPLATE_EDITED: 'Extension template edited',
  EXTENSION_TEMPLATE_DELETED: 'Extension template deleted',
  OPTED_IN_USER_LIST_DOWNLOADED: 'Opted in user list downloaded',
  SEGMENT_CREATED: 'Segment created',
  '': '',
  SEGMENT_DOWNLOADED: 'Segment downloaded',
  SEGMENT_EDITED: 'Segment edited',
  SEGMENT_DELETED: 'Segment deleted',
  CREATE_CUSTOM_TEMPLATE_CLICKED: 'Create Custom template clicked',
  CUSTOM_TEMPLATE_CREATED: 'Custom template created',
  CUSTOM_TEMPLATE_DELETED: 'Custom template deleted',
  MARKETING_CAMPAIGN_PAGE_VIEWED: 'Marketing Campaign page viewed',
  MARKETING_CAMPAIGN_CREATED: 'Marketing Campaign created',
  APP_INSTALLED: 'App installed',
  APP_UNINSTALLED: 'App uninstalled',
  CHAT_WIDGET_ENABLED: 'Chat widget enabled',
  CHAT_WIDGET_DISABLED: 'Chat widget disabled',
  SHARE_WIDGET_ENABLED: 'Share widget enabled',
  SHARE_WIDGET_DISABLED: 'Share widget disabled',
  SPIN_WHEEL_WIDGET_ENABLED: 'Spin wheel widget enabled',
  SPIN_WHEEL_WIDGET_DISABLED: 'Spin wheel widget disabled',
  PLAN_CHANGED: 'Plan changed',
  SEND_MESSAGE_TO_SEGMENT_CLICKED: 'Send message to Segment clicked',
  CREATE_NEW_CAMPAIGN_CLICKED: 'Create new campaign clicked',
  WIDGET_EDITED: 'Widget edited',
  ANALYTICS_DATE_SELECTED: 'Analytics date selected',
  SUBSCRIBE_CLICKED: 'Subscribe clicked',
  SUBSCRIBED_TO_PLAN: 'Subscribed to new plan',
  VIEW_ALL_PLANS_CLICKED: 'View all plans clicked',
  FAQ_VIEWED: 'FAQ viewed',
  WHATSAPP_BUSINESS_ACCOUNT_VERIFICATION_STATUS:
    'Whatsapp business account verification status',
  OPTIN_FROM_CHECKOUT_PAGE_ENABLED: 'Optin from checkout page enabled',
  OPTIN_FROM_CHECKOUT_PAGE_DISABLED: 'Optin from checkout page disabled',
  WHATSAPP_SUPPORT_NUMBER_ADDED: 'Whatsapp support number added',
  ESTABLISHMENT_ID: 'Establishment ID',
  USER_PUSH: 'User push',
  USER_UPDATE_PUSH: 'User properties update',
  FACEBOOK_EMBEDDED_FLOW_STARTED: 'Facebook Embedded Flow Started',
  SHARED_TEAM_INBOX_PAGE_VIEWED: 'Shared team inbox page viewed',
  SHARED_TEAM_INBOX_APPLIED: 'Shared team inbox applied',
  WABA_NUM_SET_TO_PRIMARY_NUM: 'WABA number set as primary number',
  CAMPAIGN_REPORT_DOWNLOADED: 'Campaign report downloaded',
  RODUCT_TOUR_STARTED: 'Product tour started',
  RODUCT_TOUR_STEP_VIWED: 'Product tour step viewed',
  RODUCT_TOUR_COMPLETED: 'Product tour completed',
  RODUCT_TOUR_SKIPPED: 'Product tour skipped',
  RODUCT_TOUR_FEEDBACK: 'Product tour feedback',
  WHATSAPP_CHAT_IMPORT: 'Whatsapp chat import',
};

export const constants = {
  abcart: 'Abandoned Cart',
  order: 'Order Confirmation',
  shipment: 'Order Shipment',
  cod: 'Cash on Delivery',
  payment: 'Payment Successful',
  order_cancel: 'Order Cancellation',
};

export const clevertapEventPush = ({ eventId = '', eventData = {} }) => {
  const isDevEnv =
    process.env.REACT_APP_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'staging';
  let shop = JSON.parse(localStorage.getItem('shopId'));
  let owner = JSON.parse(localStorage.getItem('ownerId'));
  let userId = shop?.userId || '';
  let planId = shop?.planId || '';
  let email = owner?.email;
  let ownerPhoneNumber = `+${owner?.phone}` || '';
  eventData = {
    ...eventData,
    'User id': userId,
    'Plan id': planId,
    Email: email,
    Phone: ownerPhoneNumber,
  };
  const cleverTapObj = window?.clevertap;
  const profileObj = {
    Site: {
      Name: owner?.name,
      Identity: userId,
      Email: email,
      Phone: ownerPhoneNumber,
      // 'MSG-whatsapp': true,
      // 'MSG-email': true,
    },
  };
  if (cleverTapObj) {
    if (eventId === CT_EVENT_IDS.USER_PUSH) {
      cleverTapObj.onUserLogin.push(profileObj);
      isDevEnv && console.log('clevertap User : ', profileObj);
    } else if (eventId === CT_EVENT_IDS.USER_UPDATE_PUSH) {
      cleverTapObj.profile.push(profileObj);
      isDevEnv && console.log('clevertap User Properties : ', profileObj);
    } else {
      isDevEnv &&
        console.log('clevertap : ', eventId, {
          [CT_EVENT_IDS.ESTABLISHMENT_ID]: userId,
          ...eventData,
        });
      cleverTapObj.event.push(eventId, {
        [CT_EVENT_IDS.ESTABLISHMENT_ID]: userId,
        ...eventData,
      });
    }
  }
};
