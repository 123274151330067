// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import {
  Card,
  DataTable,
  Link,
  Pagination,
  Stack,
  TextStyle,
  Filters,
  ChoiceList,
  TextField,
  Button
} from "@shopify/polaris";
import { handleFilters } from "../../../redux/actions/message-logs";
import { capitalize, sortObj } from "../../../utils/common";
import styles from "../MessageLogs/message-logs.module.css";
import obj from "../../../content/MessageLogs/OverlaysContent/en.json";
import TooltipOverlay from "../../../common/Tooltip";
import GuideModal from "../../../common/GuideModal";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";
import { getOrderURL } from "../../../utils/helper";
import LoadingUI from "../../../common/LoadingUI";
import { LOADING_SPINNER, SEARCH } from "../../../constants/constants";
import { useShopStore } from '../../../hooks/shopStore';

const MessageLogs = ({ showPricingBanner }: any) => {
  const { shopDetailsData } = useShopStore();
  const [templateType, setTemplateType] = useState(null);
  const [status, setStatus] = useState(null);
  const [name, setName] = useState("");
  const [tmpName, setTmpName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [orders, setOrders] = useState<any>([]);
  const [pageNum, setPageNum] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [descending, setDescending] = useState(true);
  const [orderBy, setOrderBy] = useState("entityId");
  const pageSize = 10;
  const [noDataFound, setNoDataFound] = useState(false);
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);
  const [showLoadingUI, setShowLoadingUI] = useState(false);

  let shop = shopDetailsData;
  useEffect(() => {
    if (shop && shop.userId) {
      loadMessageLogs(shop, pageNum);
    }
    clevertapEventPush({
      eventId: CT_EVENT_IDS.MESSAGE_LOGS_PAGE_VIEWED,
      eventData: {},
    });
  }, []);

  useEffect(() => {
    loadMessageLogs(shop, pageNum);
  }, [pageNum]);

  useEffect(() => {
    setPageNum(0);
    loadMessageLogs(shop, pageNum);
  }, [name, templateType, orderId, orderBy, descending]);

  const loadMessageLogs = (shop: any, page_num: any) => {
    handleSearchFilters(shop.userId, page_num);
  };

  const handleSearchFilters = (userId: string, page_num: number) => {
    if (userId) {
      setShowLoadingUI(true);
      handleFilters(
        userId,
        templateType,
        orderId,
        name,
        orderBy,
        descending,
        pageSize,
        page_num
      ).then((res: any) => {
        setDisabled(false);
        setShowLoadingUI(false);
        if (res.messages) {
          setOrders(res.messages);
          if (res.messages.length === 0) {
            setNoDataFound(true);
          } else {
            setNoDataFound(false);
          }
        }
      });
    }
  };
  const handleTemplateTypeChange = useCallback((value) => {
    setTemplateType(value);
  }, []);
  const handleStatusChange = useCallback((value) => {
    setStatus(value);
  }, []);
  const handleNameChange = () => {
    setName(tmpName);
  };
  const handleTempNameChange = (value: string) => {
    setTmpName(value);
  };
  const handleFiltersQueryChange = useCallback(
    (value) => setOrderId(value),
    []
  );
  const handleTemplateTypeRemove = useCallback(() => {
    setTemplateType(null);
  }, []);
  const handleStatusRemove = useCallback(() => {
    setStatus(null);
  }, []);
  const handleNameRemove = useCallback(() => {
    setName("");
    setTmpName("");
  }, []);

  const handleOrderIdRemove = useCallback(() => setOrderId(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleTemplateTypeRemove();
    handleStatusRemove();
    handleOrderIdRemove();
  }, [handleTemplateTypeRemove, handleStatusRemove, handleOrderIdRemove]);

  const filters = [
    {
      key: "templateType",
      label: "Type",
      filter: (
        <ChoiceList
          title="Type"
          titleHidden
          choices={[
            { label: "Cart Recovery", value: "CART_RECOVERY" },
            { label: "Cash on Delivery", value: "CASH_ON_DELIVERY" },
            { label: "Order Confirmation", value: "ORDER_CONFIRMATION" },
            { label: "Order Tracking", value: "ORDER_TRACKING" },
            { label: "Order Cancellation", value: "ORDER_CANCELLATION" },
            { label: "Payment Confirmation", value: "PAYMENT_CONFIRMATION" },
            { label: "Cross Sell", value: "CROSS_SELL" },
            { label: "Feedback", value: "FEEDBACK" },
            { label: "Win Back", value: "WIN_BACK" },
            { label: "Spin the Wheel", value: "SPIN_THE_WHEEL" },
            // { label: 'WhatsApp Campaign', value: 'WHATSAPP_CAMPAIGN' },
          ]}
          selected={templateType || []}
          onChange={handleTemplateTypeChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "name",
      label: "Name",
      filter: (
        <>
          <TextField
            label="Search by name"
            value={tmpName}
            onChange={(val) => handleTempNameChange(val)}
            autoComplete="off"
            placeholder="eg. John Doe"
          />
          <div className={styles.searchFilter}>
            <Button primary onClick={() => handleNameChange()}>{SEARCH.BUTTON}</Button>
          </div>
        </>
      ),
      shortcut: true,
    },
    //TBD to enable it or not
    // {
    //   key: 'status',
    //   label: 'Status',
    //   filter: (
    //     <ChoiceList
    //       title="Status"
    //       titleHidden
    //       choices={[
    //         { label: 'PHONE_NO_NOT_FOUND_IN_REQUEST', value: 'PHONE_NO_NOT_FOUND_IN_REQUEST' },
    //         { label: 'AUTOMATED_MESSAGE_DISABLED', value: 'AUTOMATED_MESSAGE_DISABLED' },
    //       ]}
    //       selected={status || []}
    //       onChange={handleStatusChange}
    //       allowMultiple
    //     />
    //   ),
    //   shortcut: true,
    // },
  ];

  const appliedFilters: any = [];
  if (!isEmpty(templateType)) {
    const key = "templateType";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, templateType),
      onRemove: handleTemplateTypeRemove,
    });
  }
  if (!isEmpty(status)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status),
      onRemove: handleStatusRemove,
    });
  }
  if (!isEmpty(name)) {
    const key = "name";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, name),
      onRemove: handleNameRemove,
    });
  }
  function disambiguateLabel(key: any, value: any) {
    switch (key) {
      case "templateType":
        return value.map((val: any) => `${val}`).join(", ");
      case "status":
        return value.map((val: any) => `${val}`).join(",  ");
      case "name":
        return `Name - ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value: any) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  useEffect(() => {
    getRows();
  }, [orders]);

  function formatMessageTemplateType(template_type: String) {
    //eg,
    //Input - CRM_ORDER
    //Output - Crm Order
    const letters = template_type.split("_");
    const templateString = letters
      .map((el) => {
        return el.charAt(0).toUpperCase() + el.slice(1).toLocaleLowerCase();
      })
      .join(" ");
    if (templateString) {
      return templateString;
    } else {
      return "-";
    }
  }

  const getMessageTemplate = (template_type: String) => {
    const formattedString = formatMessageTemplateType(template_type);
    return formattedString;
  };

  const getMessageStatus = (status: string) => {
    status = capitalize(status.replace(/_/g, " "));
    return <TextStyle variation="subdued">{status}</TextStyle>;
  };
  const getAttemptedDate = (date: number) => {
    if (date === 0) {
      return "-";
    } else {
      const optionsDate: any = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const optionsTime: any = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const convertedDate = new Date(Number(date));
      const formattedDate = convertedDate.toLocaleDateString(
        "en-US",
        optionsDate
      );
      const formattedTime = convertedDate.toLocaleString("en-US", optionsTime);
      const finalDate = formattedDate + " " + formattedTime;
      return <TextStyle variation="subdued">{finalDate}</TextStyle>;
    }
  };

  const getAmountText = (amount: string, currency: string) => {
    if (currency) {
      return `${amount} ${currency}`;
    } else {
      return amount;
    }
  };

  const getRows = () => {
    var rows = [];
    const localOrders = sortObj([...orders], "attempted");
    if (localOrders) {
      for (var i = 0; i < localOrders.length; i++) {
        let id =
          localOrders[i].type === "WHATSAPP_CAMPAIGN" ||
          localOrders[i].type === "SPIN_THE_WHEEL" ? (
            <>
              {localOrders[i].entityId
                ? localOrders[i].entityId
                : localOrders[i].readableId}
            </>
          ) : (
            <Link
              external={true}
              url={getOrderURL(
                shop,
                localOrders[i].category,
                localOrders[i].entityId.toString()
              )}
            >
              {localOrders[i].entityId
                ? localOrders[i].entityId
                : localOrders[i].readableId}
            </Link>
          );
        rows.push([
          id,
          localOrders[i].name,
          getAmountText(
            localOrders[i].total.toString(),
            localOrders[i].currency
          ),
          getMessageTemplate(localOrders[i].type),
          getMessageStatus(localOrders[i].status),
          localOrders[i].ts && localOrders[i].ts !== "0"
            ? getAttemptedDate(localOrders[i].ts)
            : getAttemptedDate(localOrders[i].attempted),
        ]);
      }
    }
    return rows;
  };
  const footerMarkup =
    orders && orders.length > 0 ? (
      <Stack vertical>
        <p>{"Page " + (pageNum + 1)}</p>
        <p>{"Showing " + orders.length + " results"}</p>
      </Stack>
    ) : null;

  const handleSort = (headingIndex: number, direction: string) => {
    direction === "descending" ? setDescending(true) : setDescending(false);
    if (headingIndex === 0) {
      setOrderBy("entityId");
    }
    if (headingIndex === 1) {
      setOrderBy("name");
    }
    if (headingIndex === 2) {
      setOrderBy("total");
    }
  };

  const crmLogsMarkup = (
    <Card>
      <Card.Section>
        <Filters
          queryValue={orderId}
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={handleOrderIdRemove}
          onClearAll={handleFiltersClearAll}
          hideQueryField={false}
          queryPlaceholder={"Search by order id"}
        />
      </Card.Section>
      <DataTable
        columnContentTypes={["text", "text", "text", "text", "text", "text"]}
        headings={["Order", "Name", "Amount", "Type", "Status", "Attempted"]}
        sortable={[true, true, true, false, false]}
        defaultSortDirection="descending"
        initialSortColumnIndex={0}
        hoverable={false}
        onSort={(index, direction) => handleSort(index, direction)}
        rows={getRows()}
        footerContent={
          <Stack vertical>
            {footerMarkup}
            <Pagination
              hasPrevious={pageNum > 0}
              onPrevious={() => {
                if (!disabled) {
                  setDisabled(true);
                  setPageNum((pageNum) => pageNum - 1);
                }
              }}
              hasNext={orders?.length === pageSize}
              onNext={() => {
                if (!disabled) {
                  setDisabled(true);
                  setPageNum((pageNum) => pageNum + 1);
                }
              }}
            />
          </Stack>
        }
      />
      <Card.Section>
        {noDataFound ? (
          <h1 className="Polaris-TextStyle text-center">No result found</h1>
        ) : null}
      </Card.Section>
    </Card>
  );

  return showLoadingUI ? (
    <LoadingUI note={LOADING_SPINNER.MESSAGE_LOGS} />
  ) : (
    <div>
      <TooltipOverlay
        btn1Action={() => setOpenGuideModal(true)}
        obj={obj}
        showPricingBanner={showPricingBanner}
      />
      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      ) : null}
      <div className="Polaris-Page mt-2">
        <div className="Polaris-Page-Header__TitleWrapper">
          <div className="Polaris-Header-Title__TitleAndSubtitleWrapper">
            <div className="Polaris-Header-Title__SubTitle-light Polaris-Header-Title__SubtitleCompact mt-2">
              <p>
                <b>Note:</b> Automated messages can only be sent if a customer
                has opted-in. If a message doesn’t get sent, it could be
                because,
                <br />
                (1) customer is not registered on WhatsApp, or (2) customer did
                not OPT-IN to receive communication over WhatsApp.
              </p>
            </div>
            <div className="Polaris-Header-Title__SubTitle-light Polaris-Header-Title__SubtitleCompact mt-1">
              <p>
                Only the recent 7 days data is displayed below. Write to
                (support@superlemon.xyz) to get older data.
              </p>
            </div>
          </div>
        </div>
        <div className="Polaris-Page--divider"></div>
      </div>
      <div className={styles.outerContainerTable}>
        {crmLogsMarkup}
        <div />
      </div>
    </div>
  );
};

export default MessageLogs;
