import { useState, useEffect } from "react";
import {
  Card,
  FormLayout,
  Layout,
  Link,
  SettingToggle,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import OptinTemplatePreview from "./OptinTemplatePreview";
import { OPTINS_TEMPLATE_DICT } from "../../../constants/constants";
import CustomColorPicker from "../../../common/CustomColorPicker";
import {
  getOptinWidgetData,
  updateOptinWidgetData,
} from "../../../redux/actions/chat-settings";
import ChangeOptinModal from "./ChangeOptinModal";
import { isValidHex, objectDifference } from "../../../utils/helper";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";
import NoScriptModal from "../../Modals/NoScriptTagModal/NoScriptModal";
import { useShopStore } from '../../../hooks/shopStore';

const OptinWidget = (props: any) => {
  const { shopDetailsData } = useShopStore();
  let templetes: any = OPTINS_TEMPLATE_DICT;
  const [openChangeOptinWidgetModal, setOpenChangeOptinWidgetModal] =
    useState(false);
  const [isOptinEnabled, setIsOptinEnabled] = useState(false);
  const [showNoScriptModal, setShowNoScriptModal] = useState(false);

  const shop = shopDetailsData;

  const [initialData, setInitialData] = useState({
    optin_template: "",
    optin_template_bg_color_1: "",
    optin_template_bg_color_2: "",
    optin_template_desc_text_color: "",
    optin_template_head_text_color: "",
    is_optin_template_color_custom: false,
    is_optin_template_solid_background: false,
    optin_title: "",
    optin_helpText: "",
    optinEnabled: false,
  });
  const [optinWidgetData, setOptinWidgetData] = useState({
    optin_template: "",
    optin_template_bg_color_1: "",
    optin_template_bg_color_2: "",
    optin_template_desc_text_color: "",
    optin_template_head_text_color: "",
    is_optin_template_color_custom: false,
    is_optin_template_solid_background: false,
    optin_title: "",
    optin_helpText: "",
    optinEnabled: false,
  });
  const [refOptinWidgetData, setRefOptinWidgetData] = useState({
    optin_template: "",
    optin_template_bg_color_1: "",
    optin_template_bg_color_2: "",
    optin_template_desc_text_color: "",
    optin_template_head_text_color: "",
    is_optin_template_color_custom: false,
    is_optin_template_solid_background: false,
    optin_title: "",
    optin_helpText: "",
    optinEnabled: false,
  });
  const [updateData, setUpdateData] = useState({});
  const [plan] = useState("");

  useEffect(() => {
    if (shop && shop.userId) {
      fetchOptinWidgetData(shop.userId);
    }
  }, []);

  useEffect(() => {
    if (props.isDataSubmitted) {
      fetchOptinWidgetData(shop.userId);
    }
  }, [props.isDataSubmitted]);

  useEffect(() => {
    let diffObj: any = objectDifference(refOptinWidgetData, optinWidgetData);
    setUpdateData({});
    if (Object.keys(diffObj).length > 0) {
      if (diffObj.optin_title)
        setUpdatedWidgetData("title", diffObj.optin_title);
      if (diffObj.optin_helpText)
        setUpdatedWidgetData("text", diffObj.optin_helpText);
      if (diffObj.optin_template_bg_color_1)
        setUpdatedWidgetData("bgcolor1", diffObj.optin_template_bg_color_1);
      if (diffObj.optin_template_bg_color_2)
        setUpdatedWidgetData("bgcolor2", diffObj.optin_template_bg_color_2);
      if (diffObj.optin_template_head_text_color)
        setUpdatedWidgetData(
          "textColor",
          diffObj.optin_template_head_text_color
        );
      if (diffObj.optin_template_desc_text_color)
        setUpdatedWidgetData(
          "descColor",
          diffObj.optin_template_desc_text_color
        );
      if ("is_optin_template_solid_background" in diffObj)
        setUpdatedWidgetData(
          "solidBg",
          diffObj.is_optin_template_solid_background
        );
      if (diffObj.optinEnabled)
        setUpdatedWidgetData("enabled", diffObj.optinEnabled);
    }
  }, [optinWidgetData]);

  useEffect(() => {
    let errorMessage = getErrorMessage();
    if (errorMessage) {
      props.onError(errorMessage, "optin");
      props.onChanges({});
    } else {
      props.onError("", "optin");
      props.onChanges(updateData);
    }
  }, [updateData]);

  useEffect(() => {
    if (props.reset) {
      setRefOptinWidgetData(initialData);
      setOptinWidgetData(initialData);
      setIsOptinEnabled(initialData.optinEnabled);
    }
  }, [props.reset]);

  const getErrorMessage = () => {
    if (validateOptinWidgetTitle() !== false) {
      return "Invalid Optin Title";
    } else if (validateHelpTextMsg() !== false) {
      return "Invalid Optin Help Text";
    } else {
      return false;
    }
  };

  const fetchOptinWidgetData = (userId: string) => {
    getOptinWidgetData(userId).then(
      (data: any) => {
        setInitialData(data);
        setRefOptinWidgetData(data);
        setOptinWidgetData(data);
        setIsOptinEnabled(data.optinEnabled);
      },
      (err) => {}
    );
  };

  const handleChange = (val: any, field: string) => {
    setOptinWidgetData((widgetData) => ({ ...widgetData, [field]: val }));
  };

  const optinsWidgetDescription = (
    <span>
      <p>
        Customer opt-in is a mandate by Whatsapp in order to have successful
        message deliveries.
      </p>
      <br />
      <p>
        Enabling customer opt-in via chat widget ensures higher success rate of
        delivered automated messages.
      </p>
    </span>
  );

  const getOptinWidgetBtnContent = () => {
    if (isOptinEnabled) {
      return "Disable";
    } else {
      return "Enable";
    }
  };

  const toggleOptinEnabled = () => {
    if (!shop.scriptTagAdded) {
      setShowNoScriptModal(true);
      return;
    }
    let optinEnabled = isOptinEnabled ? false : true;
    setUpdateData((widgetData) => ({
      ...widgetData,
      enabled: optinEnabled,
    }));
    setIsOptinEnabled(optinEnabled);
  };

  const getOptinWidgetVariation = () => {
    if (isOptinEnabled) {
      return "strong";
    } else {
      return "negative";
    }
  };

  const getOptinWidgetStatus = () => {
    if (isOptinEnabled) {
      return "enabled";
    } else {
      return "disabled";
    }
  };

  const validateOptinWidgetTitle = () => {
    if (
      optinWidgetData.optin_title &&
      optinWidgetData.optin_title.length > 12
    ) {
      return "Maximum 12 characters";
    }
    if (!optinWidgetData.optin_title?.trim()) {
      return "Field can't be empty";
    }
    return false;
  };

  const validateHelpTextMsg = () => {
    if (
      optinWidgetData.optin_helpText &&
      optinWidgetData.optin_helpText.length > 120
    ) {
      return "Maximum 120 characters";
    }
    if (!optinWidgetData.optin_helpText?.trim()) {
      return "Field can't be empty";
    }
    return false;
  };

  const getCurrentSelectedOptinTemplateUrl = () => {
    let template = getCurrentSelectedOptinTemplate();
    return template ? template["preview_url"] : "";
  };

  const getCurrentSelectedOptinTemplate = () => {
    for (let i = 1; i <= Object.keys(templetes).length; i++) {
      if (templetes[i].id === optinWidgetData.optin_template) {
        return templetes[i];
      }
    }
  };

  const customOptinColorSettings = () => {
    let optin_title = optinWidgetData.optin_title;
    let optin_helpText = optinWidgetData.optin_helpText;
    let is_optin_template_solid_background =
      optinWidgetData.is_optin_template_solid_background;
    let bgColor1 = optinWidgetData.optin_template_bg_color_1 || "#20802C";
    let bgColor2 = optinWidgetData.optin_template_bg_color_2 || "#30BF42";
    let buttonTextColor =
      optinWidgetData.optin_template_head_text_color || "#ffffff";
    let descTextColor =
      optinWidgetData.optin_template_desc_text_color || "#ffffff";

    return {
      is_optin_template_solid_background,
      bgColor1,
      bgColor2,
      buttonTextColor,
      optin_title,
      optin_helpText,
      descTextColor,
    };
  };

  const initCustomOptin = {
    template: getCurrentSelectedOptinTemplate() || {},
    type: "optin",
    isCustomColors: optinWidgetData.is_optin_template_color_custom,
    isBgStyleColorSolid: optinWidgetData.is_optin_template_solid_background,
    bgColor1: isValidHex(optinWidgetData.optin_template_bg_color_1)
      ? optinWidgetData.optin_template_bg_color_1
      : "#20802C",
    bgColor2: isValidHex(optinWidgetData.optin_template_bg_color_2)
      ? optinWidgetData.optin_template_bg_color_2
      : "#30BF42",
    buttonTextColor: isValidHex(optinWidgetData.optin_template_head_text_color)
      ? optinWidgetData.optin_template_head_text_color
      : "#ffffff",
    descTextColor: isValidHex(optinWidgetData.optin_template_desc_text_color)
      ? optinWidgetData.optin_template_desc_text_color
      : "#ffffff",
    allowDescColor: true,
    textColorLabel1: "Heading text colour",
    mainLabel: "Use custom Optins Widget colours",
  };
  const onCustomOptinBtnChange = (obj: any) => {
    if (obj.bgColor1) handleChange(obj.bgColor1, "optin_template_bg_color_1");
    if (obj.bgColor2) handleChange(obj.bgColor2, "optin_template_bg_color_2");
    if (obj.buttonTextColor)
      handleChange(obj.buttonTextColor, "optin_template_head_text_color");
    if (obj.descTextColor)
      handleChange(obj.descTextColor, "optin_template_desc_text_color");
    if ("isBgStyleColorSolid" in obj)
      handleChange(
        obj.isBgStyleColorSolid,
        "is_optin_template_solid_background"
      );
  };

  const getOptinTemplatePreview = () => {
    let template = getCurrentSelectedOptinTemplate();
    return OptinTemplatePreview({
      template: template,
      custom: customOptinColorSettings(),
    });
  };

  const changeOptinTemplate = (selected: any, data: any = {}) => {
    let updatedData: any = {
      optin_template: selected,
    };

    for (let key in data) {
      updatedData[key] = data[key];
      setOptinWidgetData((widgetData) => ({
        ...widgetData,
        [key]: data[key],
      }));
    }

    if (selected) {
      let params = new URLSearchParams();
      params.append("templateId", selected.toString());
      params.append("custom", data.is_optin_template_color_custom);
      updateOptinWidgetData(shop.userId, params).then((response: any) => {
        if (response.status === "success") {
          props.setShowSuccessToast((successToast: any) => !successToast);
          clevertapEventPush({
            eventId: CT_EVENT_IDS.WIDGET_EDITED,
            eventData: {
              "Widget Name": "Chat widget",
            },
          });
          props.setToast("Optins Widget Design Saved");
          props.setHasChanges(false);
        } else {
          props.setShowErrorToast((errorToast: any) => !errorToast);
          props.setErrorToast(response);
        }

        setOpenChangeOptinWidgetModal(false);
        document.body.classList.remove("settings-page");
      });
    }
  };
  const setUpdatedWidgetData = (field: string, value: any) => {
    setUpdateData((widgetData) => ({ ...widgetData, [field]: value }));
  };

  const openOptinsChangeDesignModal = () => {
    setOpenChangeOptinWidgetModal(true);
    document.body.classList.add("settings-page");
  };

  return (
    <Layout.AnnotatedSection
      title="Opt-in Settings"
      description={optinsWidgetDescription}
    >
      <SettingToggle
        action={{
          content: getOptinWidgetBtnContent(),
          onAction: toggleOptinEnabled,
        }}
        enabled={isOptinEnabled}
      >
        <Stack vertical>
          <p>
            {"Whatsapp opt-in via Chat Widget is currently "}
            <TextStyle variation={getOptinWidgetVariation()}>
              {getOptinWidgetStatus()}
            </TextStyle>{" "}
            <Link
              url={
                "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Optin_Via_Chat_Preview.png?v=1672224796"
              }
              external={true}
            >
              Preview
            </Link>
          </p>
        </Stack>
      </SettingToggle>
      <br />
      <div className="optins-widget-setting-toggle">
        <SettingToggle
          action={{
            content: "Select Design",
            onAction: () => openOptinsChangeDesignModal(),
          }}
          enabled={true}
        >
          <div className="previewBtnBackground">
            {getCurrentSelectedOptinTemplate() &&
            getCurrentSelectedOptinTemplate()["is_customizable"] ? (
              <div className="templateItem optins">
                {getOptinTemplatePreview()}
              </div>
            ) : (
              <img
                className="previewBtnBackground"
                src={getCurrentSelectedOptinTemplateUrl()}
                alt="Opt-in via Chat Widget icon"
              />
            )}
          </div>
        </SettingToggle>
      </div>
      <br />
      <CustomColorPicker
        {...initCustomOptin}
        onCustomChange={onCustomOptinBtnChange}
      />
      <ChangeOptinModal
        available_templates={OPTINS_TEMPLATE_DICT}
        plan={plan}
        customSettings={customOptinColorSettings()}
        selected_template_id={optinWidgetData.optin_template}
        changeTemplate={changeOptinTemplate}
        onModalClose={() => {
          setOpenChangeOptinWidgetModal(false);
          document.body.classList.remove("settings-page");
        }}
        open={openChangeOptinWidgetModal}
      />
      {isOptinEnabled ? (
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                label="Text to be displayed on opt-in chat window"
                type="text"
                value={optinWidgetData.optin_title}
                onChange={(val) => handleChange(val, "optin_title")}
                error={validateOptinWidgetTitle()}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                label="Help text to be displayed inside the opt-in chat window"
                type="text"
                value={optinWidgetData.optin_helpText}
                onChange={(val) => handleChange(val, "optin_helpText")}
                error={validateHelpTextMsg()}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card>
      ) : null}
      <NoScriptModal
        showModal={showNoScriptModal}
        setShowModal={setShowNoScriptModal}
        wixSiteId={shop.wixSiteId}
      />
    </Layout.AnnotatedSection>
  );
};

export default OptinWidget;
