import { Card, Stack, TextContainer, TextField } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { COUNTRY_CODES } from "../constants/countryCodes";
import Select from "react-select";
import getLocation from "./Location";

const SupportNumberMarkup = (props: any) => {
  const [countryCode, setCountryCode] = useState(props.countryCode);
  const [number, setNumber] = useState(props.phoneNumber);
  const [countryCodePlaceholder, setCountryCodePlaceholder] = useState<any>();

  useEffect(() => {
    setCountryCode(props.countryCode);
    setNumber(props.phoneNumber);
    initProps();
  }, [props]);

  // const validateCountryCode = () => {
  //   var cc = /^(?:[1-9]\d{0,5})$/;
  //   if (!countryCode || countryCode.match(cc)) {
  //     return false;
  //   } else if (countryCode && countryCode[0] === '0') {
  //     return 'cannot start with 0';
  //   } else {
  //     return 'enter a valid country code';
  //   }
  // };

  const validatePhoneNo = () => {
    var phoneno = /^(?:[0-9]\d{0,14})$/;
    if (!number || number.match(phoneno)) {
      return false;
    } else {
      return "enter a valid phone number";
    }
  };

  const initProps = () => {
    if (!props.countryCode) {
      let locationData = getLocation();
      if (locationData) {
        setCountryCode(locationData.value);
      }
    }
    setCountryCodePlaceholder(
      COUNTRY_CODES.find((entry) => entry.value === countryCode)
    );
  };

  return (
    <Card sectioned>
      <Stack vertical>
        <p>
          Please note that it is mandatory to include a link to your WhatsApp
          support number in every message.
        </p>
        <Stack>
          <div className="customSelect">
            <TextContainer>Please select your country code</TextContainer>
            <Select
              options={COUNTRY_CODES}
              value={countryCodePlaceholder}
              onChange={(option) => {
                props.onCountryChange(option?.value);
                setCountryCode(option?.value);
              }}
              onMenuOpen={() => {
                setCountryCodePlaceholder("");
              }}
              onMenuClose={() => {
                if (countryCodePlaceholder == "") {
                  setCountryCodePlaceholder(
                    COUNTRY_CODES.find((entry) => entry.value === countryCode)
                  );
                }
              }}
            />
          </div>
          <TextField
            label={
              <TextContainer>
                Please enter your WhatsApp support number
              </TextContainer>
            }
            value={number}
            maxLength={15}
            type={"text"}
            placeholder={"Example: 9035026993"}
            error={validatePhoneNo()}
            onChange={(value) => {
              props.onNumberChange(value);
              setNumber(value);
              if (!props.countryCode) {
                props.onCountryChange(countryCode);
              }
            }}
          />
        </Stack>
      </Stack>
    </Card>
  );
};
export default SupportNumberMarkup;
