import {
  Button,
  Card,
  ContextualSaveBar,
  Layout,
  Link,
  RadioButton,
  Stack,
  TextContainer,
  Toast,
} from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import {
  settings,
  updateSettings,
} from '../../../redux/actions/manual-messaging';
import { objectDifference } from '../../../utils/helper';
import CreateTemplateModal from './MessagingTemplates/CreateTemplateModal';
import ViewTemplatesModal from './MessagingTemplates/ViewTemplatesModal';
import CreateTagModal from './Tags/CreateTagModal';
import ViewTagModal from './Tags/ViewTagModal';
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from '../../../utils/clevertapUtils';
import { PLATFORM } from '../../../constants/constants';
import { useShopStore } from '../../../hooks/shopStore';

function Settings(props: any) {
  const { shopDetailsData } = useShopStore();
  const [activateViewTemplate, setactivateViewTemplate] = useState(false);
  const [activateCreateTemplate, setactivateCreateTemplate] = useState(false);
  const [activateViewTagModal, setactivateViewTagModal] = useState(false);
  const [activateCreateTagModal, setactivateCreateTagModal] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '' });
  const [errorToast, setErrorToast] = useState({ show: false, message: '' });

  const [hasChanges, setHasChanges] = useState(false);
  const [dataChanges, setDataChanges] = useState<any>({
    whatsappWeb: null,
  });

  const [initialData, setInitialData] = useState({
    whatsappWeb: null,
  });
  const [settingsData, setSettingsData] = useState({
    whatsappWeb: null,
  });
  const [refSettingsData, setRefSettingsData] = useState({
    whatsappWeb: null,
  });

  let shop = shopDetailsData;

  useEffect(() => {
    if (shop && shop.userId) {
      getSettings(shop.userId);
    }
    clevertapEventPush({
      eventId: CT_EVENT_IDS.MANUAL_MESSAGING_SETTINGS_PAGE_VIEWED,
      eventData: {},
    });
  }, []);

  useEffect(() => {
    var diffObj: any = objectDifference(refSettingsData, settingsData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        whatsappWeb: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        whatsappWeb: null,
      }));
    }
  }, [settingsData]);

  useEffect(() => {
    if (dataChanges.whatsappWeb !== null) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [dataChanges]);

  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const resetData = () => {
    setDataChanges({
      whatsappWeb: null,
    });
    setRefSettingsData(initialData);
    setSettingsData(initialData);
    setHasChanges(false);
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: '' });
      }}
      duration={2000}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const getSettings = (userId: string) => {
    if (userId) {
      settings(userId).then((res: any) => {
        if (res.status === 'success') {
          setInitialData(res.settings);
          setRefSettingsData(res.settings);
          setSettingsData(res.settings);
        }
      });
    }
  };
  const updateSettingsData = () => {
    if (settingsData.whatsappWeb !== null) {
      let params = new URLSearchParams();
      // @ts-ignore
      params.append('whatsappWeb', settingsData.whatsappWeb);
      updateSettings(shop.userId, params).then((response: any) => {
        if (response.status === 'success') {
          showToast('Data Saved Successfully');
          setDataChanges({ ...dataChanges, whatsappWeb: null });
          setInitialData(response.settings);
          setRefSettingsData(response.settings);
          setSettingsData(response.settings);
        } else {
          showErrorToast(response);
        }
      });
    }
    setHasChanges(false);
  };

  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message='Unsaved changes'
      saveAction={{
        content: 'Save Settings',
        onAction: updateSettingsData,
      }}
      discardAction={{
        onAction: () => {
          resetData();
        },
      }}
    />
  ) : null;

  const order_shipment_message_template_description = (
    <span>
      <p>
        Send messages from your computer using WhatsApp Web, or send them faster
        using the{' '}
        <a
          target='_blank'
          className='Polaris-Link'
          href='https://www.whatsapp.com/download'
          rel='noopener noreferrer'
          data-polaris-unstyled='true'
        >
          WhatsApp Desktop application.
          <span className='Polaris-Link__IconLockup'>
            <span className='Polaris-Link__IconLayout'>
              <span className='Polaris-Icon' aria-label='(opens a new window)'>
                <svg
                  viewBox='0 0 20 20'
                  className='Polaris-Icon__Svg'
                  focusable='false'
                  aria-hidden='true'
                >
                  <path d='M13 12a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6c-.575 0-1-.484-1-1V7a1 1 0 0 1 1-1h1a1 1 0 0 1 0 2v5h5a1 1 0 0 1 1-1zm-2-7h4v4a1 1 0 1 1-2 0v-.586l-2.293 2.293a.999.999 0 1 1-1.414-1.414L11.586 7H11a1 1 0 0 1 0-2z'></path>
                </svg>
              </span>
            </span>
          </span>
        </a>
      </p>
      <br />
      <p>
        WhatsApp Desktop is an experimental feature, it may not work with all
        browsers. If your default browser doesn't work, please try Microsoft
        Edge / Safari / Firefox / Google Chrome.
      </p>
    </span>
  );
  const tags_description = (
    <>
      <p>
        If you want to view/edit/delete existing tags, please click on "View
        Tags" button.
      </p>
      <br />

      <p>If you want to create a new tag click on "Create Tag" button</p>
    </>
  );
  const message_description = (
    <>
      <p>
        If you want to view/edit/delete existing templates, please click on
        "View Templates" button.
      </p>
      <br />

      <p>
        If you want to create a new template click on "Create Templates" button
      </p>
    </>
  );
  const handleChange = (field: string, value: any) => {
    setSettingsData((data) => ({ ...data, [field]: value }));
  };
  const cartRecoveryTemplateMarkup = (
    <div className='mt-2'>
      <Card sectioned>
        <div className='verticalMarginTextContainer mb-1'>
          <TextContainer>
            <p>Send messages from your computer using</p>
          </TextContainer>
        </div>

        <div className='stackVerticalMarginTopAdjustment'>
          <Stack vertical={true}>
            <RadioButton
              label={'Whatsapp Web'}
              checked={settingsData.whatsappWeb === true}
              id='tracking1'
              name='shippingurl'
              onChange={() => {
                handleChange('whatsappWeb', true);
              }}
            />
            <RadioButton
              label={'Whatsapp Desktop App'}
              id='tracking2'
              name='thankyoupageurl'
              checked={settingsData.whatsappWeb === false}
              onChange={() => {
                handleChange('whatsappWeb', false);
              }}
            />
          </Stack>
        </div>
      </Card>
      {shop.platform === PLATFORM.SHOPIFY && (
        <Card sectioned>
          <div className='verticalMarginTextContainer'>
            <TextContainer>
              <p>
                You can also send manual messages from your mobile phone by
                opening our app <br />{' '}
                <Link url='https://beta-app.superlemon.xyz/' external>
                  beta-app.superlemon.xyz
                </Link>
                on your phone browser. Sending messages from your phone is very
                fast and easy to do.
              </p>
            </TextContainer>
          </div>
        </Card>
      )}
    </div>
  );
  const TagMarkup = (
    <div className='mt-2'>
      <Card sectioned>
        <div className='verticalMarginTextContainer d-flex-center space-evenly'>
          <div>
            <Button primary onClick={() => setactivateViewTagModal(true)}>
              View Tags
            </Button>
          </div>
          <div>
            <Button primary onClick={() => setactivateCreateTagModal(true)}>
              Create Tag
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
  const MessageMarkup = (
    <div className='mt-2'>
      <Card sectioned>
        <div className='verticalMarginTextContainer d-flex-center space-evenly'>
          <div>
            <Button primary onClick={() => setactivateViewTemplate(true)}>
              View Templates
            </Button>
          </div>
          <div>
            <Button primary onClick={() => setactivateCreateTemplate(true)}>
              Create Template
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
  return (
    <>
      {ctxSaveBarMarkup}
      {toastMarkup}
      {errorToastMarkup}
      {activateViewTemplate ? (
        <ViewTemplatesModal
          open={activateViewTemplate}
          setactivateViewTemplate={setactivateViewTemplate}
          closeModal={() => setactivateViewTemplate(false)}
          shopId={shop.id}
          showToast={showToast}
          showErrorToast={showErrorToast}
          userId={shop.userId}
        />
      ) : null}
      <CreateTemplateModal
        open={activateCreateTemplate}
        setactivateCreateTemplate={setactivateCreateTemplate}
        closeModal={() => setactivateCreateTemplate(false)}
        showToast={showToast}
        showErrorToast={showErrorToast}
        userId={shop.userId}
      />
      {activateViewTagModal ? (
        <ViewTagModal
          open={activateViewTagModal}
          setactivateViewTagModal={setactivateViewTagModal}
          closeModal={() => setactivateViewTagModal(false)}
          shopId={shop.id}
          showToast={showToast}
          showErrorToast={showErrorToast}
          userId={shop.userId}
        />
      ) : null}
      <CreateTagModal
        open={activateCreateTagModal}
        setactivateViewTemplate={setactivateCreateTemplate}
        closeModal={() => setactivateCreateTagModal(false)}
        showToast={showToast}
        showErrorToast={showErrorToast}
        shopId={shop.id}
        userId={shop.userId}
      />
      {!props.isCrmToolEnabled && (
        <Layout.AnnotatedSection
          title='WhatsApp Configuration'
          description={order_shipment_message_template_description}
        >
          <Stack vertical>{cartRecoveryTemplateMarkup}</Stack>
        </Layout.AnnotatedSection>
      )}
      <Layout.AnnotatedSection title='Tags' description={tags_description}>
        <Stack vertical>{TagMarkup}</Stack>
      </Layout.AnnotatedSection>
      {/* <Layout.AnnotatedSection
        title="Messaging Templates"
        description={message_description}
      >
        <Stack vertical>{MessageMarkup}</Stack>
      </Layout.AnnotatedSection> */}
    </>
  );
}

export default Settings;
