// @ts-nocheck
import { useUsersContext } from '../../../../context/usersContext';
import { useEffect, useRef, useState } from 'react';
import ChatElement from './ChatElement';

const ChatList = (props: any) => {
  const {
    fetchChatRoomList,
    usersChatListItems,
    currentUser,
    ticketStatus,
    searchParams,
    agentPhone,
    getChatCount,
    mobileView,
  } = useUsersContext();
  const listInnerRef = useRef();

  const [currPage, setCurrPage] = useState(0);
  const [prevPage, setPrevPage] = useState(-1);
  const [last, setLast] = useState(false);
  const [fetchList, toggleFetchList] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let type = 'append';
      if (currPage === 0) type = 'create';
      const data = await fetchChatRoomList(type, currPage, searchParams);
      if (data?.length < 1) setLast(true);
      setPrevPage(currPage);
    };

    if (!last && prevPage !== currPage) {
      fetchData();
    }
  }, [currPage, prevPage, fetchList]);

  useEffect(() => {
    setCurrPage(0);
    setPrevPage(-1);
    setLast(false);
    toggleFetchList(true);
    getChatCount();
  }, [ticketStatus, currentUser, agentPhone]);

  useEffect(() => {
    setCurrPage(0);
    setPrevPage(-1);
    setLast(false);
    toggleFetchList(true);
    getChatCount();
  }, [searchParams]);

  useEffect(() => {
    if (
      !mobileView.status &&
      !currentUser?.customerPhone &&
      usersChatListItems?.length
    ) {
      props.handleOpenChatUser(usersChatListItems[0]?.customerPhone);
    }
  }, [usersChatListItems]);

  const onScroll = () => {
    if (listInnerRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      let height = scrollTop + clientHeight;
      if (height % 1 !== 0) height = Number(height.toFixed(0));
      const lowerH = height - 5;
      const higherH = height + 5;
      if (!last && (lowerH > scrollHeight || scrollHeight < higherH)) {
        setCurrPage(currPage + 1);
      }
    }
  };

  const GetChatList = () => {
    return (
      <div>
        {usersChatListItems.map((data: any, index: any) => {
          return (
            <ChatElement
              data={data}
              index={index}
              handleOpenChatUser={props.handleOpenChatUser}
              getRemainingTime={props.getRemainingTime}
            />
          );
        })}
      </div>
    );
  };

  return usersChatListItems.length ? (
    <div onScroll={onScroll} ref={listInnerRef} className='chatList'>
      <GetChatList />
    </div>
  ) : (
    <p className='chat__NoChatsFound'>No Chats found</p>
  );
};

export default ChatList;
