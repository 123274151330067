import { Heading } from '@shopify/polaris';
import { useEffect, useState, useCallback } from 'react';
import styles from './analytics.module.scss';
import LineCharts from './LineChart';
import Cart from '../../../assets/images/cart.png';
import ChatMessage from '../../../assets/images/chat-message.png';
import Money from '../../../assets/images/money.png';
import DateFilter from './DateFilter';
import Chart, { ChartLegends, DisableBlock } from './Chart';
import moment from 'moment';
import {
  getAbandonedCartAnalyticsCount,
  getMessageAnalyticsCount,
} from '../../../redux/actions/analytics';
import { ChartActiveProps } from './common.interface';
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from '../../../utils/clevertapUtils';
import {
  PLAN_ENGAGE_PLUS,
  PLATFORM,
  SHOP_FEATURES,
} from '../../../constants/constants';
import { Link } from 'react-router-dom';
import { shopFeaturesFromStoreData } from '../../../utils/helper';
import { useShopStore } from '../../../hooks/shopStore';

type AutomatedMessageReportProps = {
  chartStatus: ChartActiveProps;
};

type AbandonedCartProps = {
  date: any;
  read: number;
  sent: number;
  delivered: number;
};

type ContentValues = {
  text: string;
  subText: string;
  icon: any;
};

enum MESSAGE_TYPE {
  CART_RECOVERY = 0,
  ORDER_CONFIRMATION = 1,
  ORDER_TRACKING = 2,
  CASH_ON_DELIVERY = 3,
  BOLETO_ORDER_CONFIRMATION = 4,
  BOLETO_PAYMENT_REMINDER = 5,
  FEEDBACK = 6,
  CROSS_SELL = 7,
  WIN_BACK = 8,
  ORDER_CANCELLATION = 9,
  PAYMENT_CONFIRMATION = 10,
}

const contentValue: ContentValues[] = [
  {
    text: 'No. of abandoned Carts Converted',
    subText: '0',
    icon: Cart,
  },
  {
    text: 'Revenue Generated from abandoned Carts',
    subText: '$0',
    icon: Money,
  },
];

const disabledLink = '/templates';

function AutomatedMessageReport({ chartStatus }: AutomatedMessageReportProps) {
  const { shopDetailsData } = useShopStore();
  const [showPicker, setShowPicker] = useState(false);
  const [contentValues, setContentValues] = useState(contentValue);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clickButtonAnalytics, setClickButtonAnalytics] = useState(
    [] as AbandonedCartProps[]
  );
  const [orderConfirmation, setOrderConfirmation] = useState(
    [] as AbandonedCartProps[]
  );
  const [orderShipment, setOrderShipment] = useState(
    [] as AbandonedCartProps[]
  );
  const [cashOnDelivery, setCashOnDelivery] = useState(
    [] as AbandonedCartProps[]
  );
  const [paymentSuccess, setPaymentSuccess] = useState(
    [] as AbandonedCartProps[]
  );
  const [orderCancellation, setOrderCancellation] = useState(
    [] as AbandonedCartProps[]
  );
  const [crossSell, setCrossSell] = useState([] as AbandonedCartProps[]);
  const [customerWinBack, setCustomerWinBack] = useState(
    [] as AbandonedCartProps[]
  );
  const [feedback, setFeedback] = useState([] as AbandonedCartProps[]);
  const [isAutomatedMessageReport, setIsAutomatedMessageReport] =
    useState(false);
  const togglePopoverActive = useCallback(
    () => setShowPicker((showPicker) => !showPicker),
    []
  );

  const lines = [
    {
      dataKey: 'read',
      label: 'Total Read',
      stroke: '#ad7ffd',
    },
    {
      dataKey: 'sent',
      label: 'Total Sent',
      stroke: '#f2cc52',
    },
    {
      dataKey: 'delivered',
      label: 'Total Received',
      stroke: '#f61613',
    },
    ...(Number(shopDetailsData.planId) === PLAN_ENGAGE_PLUS
      ? [
          {
            dataKey: 'linkClicked',
            label: 'Link Clicked',
            stroke: '#5ec127',
          },
        ]
      : []),
  ];

  let shop = shopDetailsData;

  useEffect(() => {
    if (shop && shop.userId) {
      const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
      const end = moment().format('YYYY-MM-DD');
      fetchData(shop, start, end);
      setIsAutomatedMessageReport(
        !shopFeaturesFromStoreData(shopDetailsData).includes(
          SHOP_FEATURES.AUTOMATION_BLOCKED
        )
      );
    }
  }, []);

  const fetchData = async (shop: any, start?: string, end?: string) => {
    const [
      abandonedCartAnalyticsRes,
      orderConfirmationRes,
      orderTrackingRes,
      cashOnDeliveryRes,
      paymentSuccessRes,
      orderCancellationRes,
      crossSellRes,
      winBackRes,
      feedbackRes,
    ]: any = await Promise.all([
      getAbandonedCartAnalyticsCount(shop.userId, start, end).catch(
        console.log
      ),
      getMessageAnalyticsCount(
        shop.userId,
        start,
        end,
        MESSAGE_TYPE.ORDER_CONFIRMATION
      ).catch(console.log),
      getMessageAnalyticsCount(
        shop.userId,
        start,
        end,
        MESSAGE_TYPE.ORDER_TRACKING
      ).catch(console.log),
      getMessageAnalyticsCount(
        shop.userId,
        start,
        end,
        MESSAGE_TYPE.CASH_ON_DELIVERY
      ).catch(console.log),
      getMessageAnalyticsCount(
        shop.userId,
        start,
        end,
        MESSAGE_TYPE.PAYMENT_CONFIRMATION
      ).catch(console.log),
      getMessageAnalyticsCount(
        shop.userId,
        start,
        end,
        MESSAGE_TYPE.ORDER_CANCELLATION
      ).catch(console.log),
      getMessageAnalyticsCount(
        shop.userId,
        start,
        end,
        MESSAGE_TYPE.CROSS_SELL
      ).catch(console.log),
      getMessageAnalyticsCount(
        shop.userId,
        start,
        end,
        MESSAGE_TYPE.WIN_BACK
      ).catch(console.log),
      getMessageAnalyticsCount(
        shop.userId,
        start,
        end,
        MESSAGE_TYPE.FEEDBACK
      ).catch(console.log),
    ]);

    if (
      abandonedCartAnalyticsRes &&
      abandonedCartAnalyticsRes.status === 'success'
    ) {
      setClickButtonAnalytics(_setDateFormate(abandonedCartAnalyticsRes));
      if (abandonedCartAnalyticsRes.message) {
        contentValues[0].subText =
          abandonedCartAnalyticsRes.message.cartRecovered;
        let roundedValue =
          Math.round(abandonedCartAnalyticsRes.message.revenueGenerated * 100) /
          100;
        contentValues[1].subText = `${abandonedCartAnalyticsRes?.message?.currency}${roundedValue}`;
      }
      setContentValues([...contentValues]);
    }

    if (orderConfirmationRes && orderConfirmationRes.status === 'success') {
      setOrderConfirmation(_setDateFormate(orderConfirmationRes));
    }
    if (orderTrackingRes && orderTrackingRes.status === 'success') {
      setOrderShipment(_setDateFormate(orderTrackingRes));
    }
    if (cashOnDeliveryRes && cashOnDeliveryRes.status === 'success') {
      setCashOnDelivery(_setDateFormate(cashOnDeliveryRes));
    }
    if (paymentSuccessRes && paymentSuccessRes.status === 'success') {
      setPaymentSuccess(_setDateFormate(paymentSuccessRes));
    }

    if (orderCancellationRes && orderCancellationRes.status === 'success') {
      setOrderCancellation(_setDateFormate(orderCancellationRes));
    }
    if (crossSellRes && crossSellRes.status === 'success') {
      setCrossSell(_setDateFormate(crossSellRes));
    }
    if (winBackRes && winBackRes.status === 'success') {
      setCustomerWinBack(_setDateFormate(winBackRes));
    }
    if (feedbackRes && feedbackRes.status === 'success') {
      setFeedback(_setDateFormate(feedbackRes));
    }
  };

  const _setDateFormate = (analyticsData: any) => {
    if (
      analyticsData &&
      analyticsData.message &&
      analyticsData.message.dayWiseAnalytics
    ) {
      analyticsData.message.dayWiseAnalytics.map((item: AbandonedCartProps) => {
        item.date = moment(item.date).format('YYYY-MM-DD');
        return item;
      });
      return analyticsData.message.dayWiseAnalytics;
    }
  };

  const handleSubmit = async (filter: any) => {
    const finalStart = moment(filter.startDate).format('YYYY-MM-DD');
    const finalEnd = moment(filter.endDate).format('YYYY-MM-DD');

    setIsSubmitting(true);
    if (shop.userId && finalStart && finalEnd) {
      await fetchData(shop, finalStart, finalEnd);
      clevertapEventPush({
        eventId: CT_EVENT_IDS.ANALYTICS_DATE_SELECTED,
        eventData: {
          'Analytics name': 'Automated Message Report Analytics',
        },
      });
      setIsSubmitting(false);
      togglePopoverActive();
    }
  };

  return (
    <>
      <div className={styles.analyticContainer}>
        <Heading>{'Automated Message Report'}</Heading>
        <DateFilter
          showPicker={showPicker}
          togglePopoverActive={togglePopoverActive}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </div>
      <div className={styles.lineChartLayoutContainer}>
        {shop.platform == PLATFORM.SHOPIFY ? (
          <>
            <Heading>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {'Abandoned Cart'}{' '}
                <img
                  src={Cart}
                  style={{ height: '18px', width: '18px', marginLeft: '10px' }}
                  alt=''
                />
              </div>
            </Heading>
            <LineCharts
              data={clickButtonAnalytics}
              lines={lines}
              hasContent={true}
              xDataKey={'date'}
              disabled={
                !chartStatus.abandoned_cart || !isAutomatedMessageReport
              }
              disabledRedirectLink={disabledLink}
              disableChartContent={
                <p style={{ fontWeight: 'bold' }}>
                  Currently Disabled.
                  <br />
                  <span className={styles.automation__links}>
                    <Link to={`${disabledLink}`}>Click here</Link>
                  </span>{' '}
                  to enable
                </p>
              }
              disabledText={
                <span>
                  <b>Did you know?</b> In 2021, We helped 15K merchants to
                  generate additional $ 15 million by simply converting
                  abandoned cart into successful orders. To activate abandoned
                  cart notifications for your customer,{' '}
                  <span className={styles.automation__links}>
                    <Link to={`${disabledLink}`}>Click here</Link>
                  </span>
                  <Link to={`${disabledLink}`}>Click here</Link>
                </span>
              }
              children={
                <div className={styles.contentContainer}>
                  {contentValues.map((item, id) => (
                    <div className={styles.contentRow} key={id}>
                      <img
                        src={item.icon}
                        className={`${styles.contentIcon} ${
                          !chartStatus.abandoned_cart ? styles.conentOpc : ''
                        }`}
                        alt=''
                      />
                      <div>
                        <p
                          className={`${styles.contentText} ${
                            !chartStatus.abandoned_cart
                              ? styles.contentDisableText
                              : ''
                          }`}
                        >
                          {item.text}
                        </p>
                        <p
                          className={`${styles.contentSubText} ${
                            !chartStatus.abandoned_cart
                              ? styles.contentDisableText
                              : ''
                          }`}
                        >
                          {item.subText}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
            <br />
            <Heading>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {'Other messages'}{' '}
                <img
                  src={ChatMessage}
                  style={{ height: '18px', width: '18px', marginLeft: '10px' }}
                  alt=''
                />
              </div>
            </Heading>
            <br />
            <ChartLegends isHorizontal={true} lines={lines} />
          </>
        ) : (
          <>
            <br />
            <Heading>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {'Message Analytics'}{' '}
                <img
                  src={ChatMessage}
                  style={{ height: '18px', width: '18px', marginLeft: '10px' }}
                  alt=''
                />
              </div>
            </Heading>
            <br />
            <div>
              <div
                className={`${styles.otherMessagesChartContainer} ${styles.otherMessagesChartMobileContainer}`}
              >
                <LineCharts
                  title='Abandoned Cart'
                  data={clickButtonAnalytics}
                  lines={lines}
                  hasContent={true}
                  xDataKey={'date'}
                  disabled={
                    !chartStatus.abandoned_cart || !isAutomatedMessageReport
                  }
                  disabledRedirectLink={disabledLink}
                  disableChartContent={
                    <p style={{ fontWeight: 'bold' }}>
                      Currently Disabled.
                      <br />
                      <span className={styles.automation__links}>
                        <Link to={`${disabledLink}`}>Click here</Link>
                      </span>{' '}
                      to enable
                    </p>
                  }
                  disabledText={
                    <span>
                      <b>Did you know?</b> In 2021, We helped 15K merchants to
                      generate additional $ 15 million by simply converting
                      abandoned cart into successful orders. To activate
                      abandoned cart notifications for your customer,{' '}
                      <span className={styles.automation__links}>
                        <Link to={`${disabledLink}`}>Click here</Link>
                      </span>
                    </span>
                  }
                />
              </div>
              <br />
              <ChartLegends isHorizontal={true} lines={lines} />
              <br />
            </div>
          </>
        )}
        <div>
          <div
            className={`${styles.otherMessagesChartContainer} ${styles.otherMessagesChartMobileContainer}`}
          >
            <Chart
              title={'Order Confirmation'}
              className={`${styles.otherMessagesChart} ${styles.otherMessagesChartMobile}`}
              disableChartContent={
                <DisableBlock
                  disabledText={
                    <p>
                      Currently disabled, Order Confirmation messages provides
                      brand assurances to your customers & imbues a sense of
                      trust. To enable,{' '}
                      <span className={styles.automation__links}>
                        <Link to={`${disabledLink}`}>Click here</Link>
                      </span>
                    </p>
                  }
                />
              }
              disabled={
                !chartStatus.order_confirmation || !isAutomatedMessageReport
              }
              xDataKey={'date'}
              disabledRedirectLink={disabledLink}
              data={orderConfirmation}
              lines={lines}
            />
            <Chart
              title={'Order Shipment'}
              className={`${styles.otherMessagesChart} ${styles.otherMessagesChartMobile}`}
              disabledRedirectLink={disabledLink}
              data={orderShipment}
              xDataKey={'date'}
              disabled={
                !chartStatus.order_tracking || !isAutomatedMessageReport
              }
              lines={lines}
              disableChartContent={
                <DisableBlock
                  disabledText={
                    <p>
                      Currently disabled. Order Shipment messages get customers
                      excited about their purchase and boosts customer
                      gratification. To enable,{' '}
                      <span className={styles.automation__links}>
                        <Link to={`${disabledLink}`}>Click here</Link>
                      </span>
                    </p>
                  }
                />
              }
            />
          </div>
          <div
            className={`${styles.otherMessagesChartContainer} ${styles.otherMessagesChartMobileContainer}`}
          >
            <Chart
              title={'Cash on Delivery'}
              className={`${styles.otherMessagesChart} ${styles.otherMessagesChartMobile}`}
              disabled={
                !chartStatus.cash_on_delivery || !isAutomatedMessageReport
              }
              disableChartContent={
                <DisableBlock
                  disabledText={
                    <p>
                      COD messages confirm and verify that the customer intends
                      to place order. Majorly focused towards saving your
                      shipping cost, loss due to COD order cancellation. To
                      enable,{' '}
                      <span className={styles.automation__links}>
                        <Link to={`${disabledLink}`}>Click here</Link>
                      </span>
                    </p>
                  }
                />
              }
              xDataKey={'date'}
              disabledRedirectLink={disabledLink}
              data={cashOnDelivery}
              lines={lines}
            />
            <Chart
              title={'Payment Successful'}
              className={`${styles.otherMessagesChart} ${styles.otherMessagesChartMobile}`}
              disabled={
                !chartStatus.payment_confirmation || !isAutomatedMessageReport
              }
              disableChartContent={
                <DisableBlock
                  disabledText={
                    <p>
                      Currently disabled. Payment successful messages provide
                      assurance to your customer about the purchase. To enable,{' '}
                      <span className={styles.automation__links}>
                        <Link to={`${disabledLink}`}>Click here</Link>
                      </span>
                    </p>
                  }
                />
              }
              disabledRedirectLink={disabledLink}
              xDataKey={'date'}
              data={paymentSuccess}
              lines={lines}
            />
          </div>
          <div
            className={`${styles.otherMessagesChartContainer} ${styles.otherMessagesChartMobileContainer}`}
          >
            <Chart
              title={'Order Cancellation'}
              className={`${styles.otherMessagesChart} ${styles.otherMessagesChartMobile}`}
              disabledRedirectLink={disabledLink}
              data={orderCancellation}
              xDataKey={'date'}
              disabled={
                !chartStatus.order_cancellation || !isAutomatedMessageReport
              }
              disableChartContent={
                <DisableBlock
                  disabledText={
                    <p>
                      Currently disabled. Order cancellation messages provide
                      cancellation info to keep your customers updated and helps
                      you maintain a long-lasting relationship with the
                      customers. To enable,{' '}
                      <span className={styles.automation__links}>
                        <Link to={`${disabledLink}`}>Click here</Link>
                      </span>
                    </p>
                  }
                />
              }
              lines={lines}
            />
            <Chart
              title={'Cross Sell'}
              className={`${styles.otherMessagesChart} ${styles.otherMessagesChartMobile}`}
              disabledRedirectLink={disabledLink}
              data={crossSell}
              xDataKey={'date'}
              lines={lines}
              disabled={!chartStatus.cross_sell || !isAutomatedMessageReport}
              disableChartContent={
                <DisableBlock
                  disabledText={
                    <p>
                      Cross sell messages helps you increase the revenue and
                      increase the life time value of customer.. To enable,{' '}
                      <span className={styles.automation__links}>
                        <Link to={`${disabledLink}`}>Click here</Link>
                      </span>
                    </p>
                  }
                />
              }
            />
          </div>
          <div
            className={`${styles.otherMessagesChartContainer} ${styles.otherMessagesChartMobileContainer}`}
          >
            <Chart
              title={'Customer Winback'}
              className={`${styles.otherMessagesChart} ${styles.otherMessagesChartMobile}`}
              disabledRedirectLink={disabledLink}
              data={customerWinBack}
              xDataKey={'date'}
              lines={lines}
              disabled={!chartStatus.winback || !isAutomatedMessageReport}
              disableChartContent={
                <DisableBlock
                  disabledText={
                    <p>
                      Win back messages helps you increase customer loyalty and
                      increase the life time value of customer. To enable,{' '}
                      <span className={styles.automation__links}>
                        <Link to={`${disabledLink}`}>Click here</Link>
                      </span>
                    </p>
                  }
                />
              }
            />
            <Chart
              title={'Feedback'}
              className={`${styles.otherMessagesChart} ${styles.otherMessagesChartMobile}`}
              disabledRedirectLink={disabledLink}
              data={feedback}
              xDataKey={'date'}
              disabled={!chartStatus.feedback || !isAutomatedMessageReport}
              lines={lines}
              disableChartContent={
                <DisableBlock
                  disabledText={
                    <p>
                      Feedback messages helps you understand your customers in a
                      better manner and make informed business decisions. To
                      enable,{' '}
                      <span className={styles.automation__links}>
                        <Link to={`${disabledLink}`}>Click here</Link>
                      </span>
                    </p>
                  }
                />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AutomatedMessageReport;
