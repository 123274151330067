import { Card, RadioButton, Stack, TextContainer } from "@shopify/polaris";
import React, { useEffect, useState } from "react";

import {
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_TURK,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_DUTCH,
  TEMPLATE_LANGUAGE_RUSSIAN,
} from "../constants/constants";

const TemplateLanguageSettingMarkup = (props: any) => {
  const [templatLanguage, setTemplateLanguage] = useState(props.language);
  const handleLanguageChange = (newValue: any) => {
    return () => {
      props.onChange(newValue);
      setTemplateLanguage(newValue);
    };
  };
  useEffect(() => {
    setTemplateLanguage(props.language);
  }, [props]);
  return props.optinEnterpriseTemplates ? (
    <div>
      <Card sectioned>
        <Stack vertical>
          <TextContainer>
            {"Message Language (all messages will be sent in this language)"}
          </TextContainer>
          <Stack>
            <RadioButton
              label="English"
              checked={templatLanguage === TEMPLATE_LANGUAGE_ENG}
              name="language"
              onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ENG)}
            />
          </Stack>
        </Stack>
      </Card>
    </div>
  ) : (
    <div>
      <Card sectioned>
        <Stack vertical>
          <TextContainer>
            {"Message Language (all messages will be sent in this language)"}
          </TextContainer>

          <Stack>
            <RadioButton
              label="English"
              checked={templatLanguage === TEMPLATE_LANGUAGE_ENG}
              name="language"
              onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ENG)}
            />
            <RadioButton
              label="Portuguese"
              checked={templatLanguage === TEMPLATE_LANGUAGE_PORT}
              name="language"
              onChange={handleLanguageChange(TEMPLATE_LANGUAGE_PORT)}
            />
            <RadioButton
              label="Spanish"
              checked={templatLanguage === TEMPLATE_LANGUAGE_SPAN}
              name="language"
              onChange={handleLanguageChange(TEMPLATE_LANGUAGE_SPAN)}
            />
            <RadioButton
              label="Italian"
              checked={templatLanguage === TEMPLATE_LANGUAGE_ITAL}
              name="language"
              onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ITAL)}
            />
            <RadioButton
              label="French"
              checked={templatLanguage === TEMPLATE_LANGUAGE_FREN}
              name="language"
              onChange={handleLanguageChange(TEMPLATE_LANGUAGE_FREN)}
            />
            <RadioButton
              label="Indonesian"
              checked={templatLanguage === TEMPLATE_LANGUAGE_INDO}
              name="language"
              onChange={handleLanguageChange(TEMPLATE_LANGUAGE_INDO)}
            />
            <RadioButton
              label="German"
              checked={templatLanguage === TEMPLATE_LANGUAGE_GERM}
              name="language"
              onChange={handleLanguageChange(TEMPLATE_LANGUAGE_GERM)}
            />
            <RadioButton
              label="Arabic"
              checked={templatLanguage === TEMPLATE_LANGUAGE_ARAB}
              name="language"
              onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ARAB)}
            />
            <RadioButton
              label="Turkish"
              checked={templatLanguage === TEMPLATE_LANGUAGE_TURK}
              name="language"
              onChange={handleLanguageChange(TEMPLATE_LANGUAGE_TURK)}
            />
            <RadioButton
              label="Hebrew"
              checked={templatLanguage === TEMPLATE_LANGUAGE_HEBR}
              name="language"
              onChange={handleLanguageChange(TEMPLATE_LANGUAGE_HEBR)}
            />
            <RadioButton
              label="Dutch"
              checked={templatLanguage === TEMPLATE_LANGUAGE_DUTCH}
              name="language"
              onChange={handleLanguageChange(TEMPLATE_LANGUAGE_DUTCH)}
            />
            <RadioButton
              label="Russian"
              checked={templatLanguage === TEMPLATE_LANGUAGE_RUSSIAN}
              name="language"
              onChange={handleLanguageChange(TEMPLATE_LANGUAGE_RUSSIAN)}
            />
          </Stack>
        </Stack>
      </Card>
    </div>
  );
};
export default TemplateLanguageSettingMarkup;
