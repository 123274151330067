import { Heading, Stack, TextStyle } from "@shopify/polaris";

const toltipContent: any = {
  category: {
    tooltip_card: {
      heading: "Template Category",
      description:
        "Pick a category that best describes the kind of template you are creating.",
      content: [
        {
          title: "Promotional: ",
          text: "Ex .Marketing new products and discounts.",
        },
        {
          title: "Transactional: ",
          text: "Ex. Order and delivery updates.",
        },
      ],
    },
  },

  /*

URL: This is used for sending your customers to a website URL.

Phone Number: This is used for initiating a phone call.
    */

  type: {
    tooltip_card: {
      heading: "Template Type",
      content: [
        {
          title: "Text:",
          text: "Select this option if you want your messages to be a text message with/without emojis",
        },
        {
          title: "Image:",
          text: "Select this option if you want to send an image",
        },
        {
          title: "Video:",
          text: "Select this option if you want to send a video",
        },
        {
          title: "Document:",
          text: "Select this option if you want to send a document",
        },
      ],
    },
  },
  body: {
    tooltip_card: {
      heading: "Message Body",
      description:
        "Enter the text for your message in the language you've selected.",
      description_1:
        "To personalize the message for your customers you can specify dynamic variables as a number enclosed in double curly braces {{#}}.",
      description_2: "Ex. Hi {{1}}, your order no. {{2}} has been placed.",
      content: [
        {
          title: "Note",
          text: "Whatsapp doesn’t allow consecutive variables, Example - “Hi {{1}} {{2}} Please confirm your order” is not a valid format.",
        },
      ],
    },
  },
  call_to_action_button_type: {
    tooltip_card: {
      heading: "Template Buttons",
      content: [
        {
          title: "URL: ",
          text: "This is used for sending your customers to a website URL.",
        },
        {
          title: "Phone Number: ",
          text: "This is used for initiating a phone call.",
        },
      ],
    },
  },
  call_to_action_button_url_type: {
    tooltip_card: {
      heading: "Value Type",
      content: [
        {
          title: "Dynamic: ",
          text: "The value should change for different contacts.",
        },
        {
          title: "Static: ",
          text: "The value will be the same for all the contacts.",
        },
        {
          title: "Note: ",
          text: "Dynamic and static values should to be provided with the contacts via file upload.",
        },
      ],
    },
  },
  facebook_business: {
    tooltip_card: {
      heading: "Template Buttons",
      content: [
        {
          title: " URL:",
          text: "This is used for sending your customers to a website URL",
        },
        {
          title: "Phone Number: ",
          text: "This is used for initiating a phone call",
        },
      ],
    },
  },
};

type TooltipContentProps = {
  fieldName: string;
};

const TooltipContent = ({ fieldName }: TooltipContentProps) => {
  return (
    <div className="custom-tooltip" id={fieldName} style={{ color: "black" }}>
      <div id="content-wrapper">
        <Heading>{toltipContent[fieldName]?.tooltip_card.heading}</Heading>
        <br />
        {toltipContent[fieldName]?.tooltip_card?.description && (
          <>
            <TextStyle>
              {toltipContent[fieldName]?.tooltip_card?.description}
            </TextStyle>
            <br />
          </>
        )}
        {toltipContent[fieldName]?.tooltip_card?.description_1 && (
          <>
            <TextStyle>
              {toltipContent[fieldName]?.tooltip_card?.description_1}
            </TextStyle>
            <br />
          </>
        )}
        {toltipContent[fieldName]?.tooltip_card?.description_2 && (
          <>
            <TextStyle>
              {toltipContent[fieldName]?.tooltip_card?.description_2}
            </TextStyle>
            <br />
          </>
        )}
        <br />
        <ul className="tooltip-list">
          {toltipContent[fieldName]?.tooltip_card.content.map(
            (data: any, index: number) => (
              <li key={index}>
                <TextStyle variation="strong">{data.title}</TextStyle>
                <TextStyle>{data.text}</TextStyle>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default TooltipContent;
