import { Modal, Stack, TextContainer } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useShopStore } from '../../hooks/shopStore';

const MyWhatsAppModalGuide = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [guideStep, setGuideStep] = useState(0);
  const [shop, setShop] = useState({ id: '', url: '' });
  const history = useHistory();

  useEffect(() => {
    let shop = shopDetailsData;
    if (shop && shop.id) {
      setShop(shop);
    }
  }, []);

  useEffect(() => {
    props.setGuideStep(guideStep);
  }, [guideStep]);

  const modalContentGuideStep = () => {
    switch (guideStep) {
      case 0:
        return (
          <TextContainer>
            <p>
              {`First, you need to log in to SuperLemon > Navigate to “My WhatsApp” section & click on “Setup Now”`}
            </p>
            <br></br>
            <div style={{ width: '100%', height: '100%' }}>
              <img
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                }}
                className=''
                src='https://cdn.shopify.com/s/files/1/0683/1371/0892/files/image10.png?v=1691471408'
                alt=''
              />
            </div>
          </TextContainer>
        );

      case 1:
        return (
          <TextContainer>
            <p>{`Agree to the terms & conditions and click on “Proceed” button.`}</p>

            <p>
              {`If you are using a an existing Phone number for WhatsApp business API that is present on WhatsApp App or WhatsApp bsuiness app. Make sure you have imported your existing chats.`}
              <span
                onClick={() => {
                  window.open(
                    'https://www.superlemon.xyz/help-center/business-api/33/',
                    '_blank'
                  );
                }}
                style={{ color: 'blue', cursor: 'pointer' }}
              >
                {` Learn more about importing WhatsApp chat here.`}
              </span>
            </p>

            <p>
              {`Once you have imported the backup chat. You need to log in to your Meta account to get started. If you don't have a Meta account, you can create a new one`}
              <span
                onClick={() => {
                  window.open(
                    'https://www.superlemon.xyz/help-center/business-api/11/',
                    '_blank'
                  );
                }}
                style={{ color: 'blue', cursor: 'pointer' }}
              >
                {` Learn more`}
              </span>
            </p>
            <br></br>
            <div style={{ width: '100%', height: '100%' }}>
              <img
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                }}
                className=''
                src='https://cdn.shopify.com/s/files/1/0683/1371/0892/files/Frame_42.png?v=1692121568'
                alt=''
              />
            </div>
          </TextContainer>
        );
      case 2:
        return (
          <TextContainer>
            <p>{`Once you are logged in, you can connect your existing Facebook(Meta) Business Account or create a new one if you don't have one.`}</p>
            <br></br>
            <div style={{ width: '100%', height: '100%' }}>
              <img
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                }}
                className=''
                src='https://cdn.shopify.com/s/files/1/0683/1371/0892/files/image26.png?v=1691471407'
                alt=''
              />
            </div>
            <p>{`After you have connected or created a Facebook Business Account, you need to submit the required details for your WhatsApp Business API account, including your phone number and display name.`}</p>
            <br></br>
            <div style={{ width: '100%', height: '100%' }}>
              <img
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                }}
                className=''
                src='https://cdn.shopify.com/s/files/1/0683/1371/0892/files/image20.png?v=1691471407'
                alt=''
              />
            </div>
          </TextContainer>
        );
      case 3:
        return (
          <TextContainer>
            <p>{`Once you have submitted the details, you need to verify your phone number using OTP or voice call.`}</p>
            <p>
              {`During the sign-up process, you may encounter various errors. You can`}
              <span
                onClick={() => {
                  window.open(
                    'https://www.superlemon.xyz/help-center/business-api/12/',
                    '_blank'
                  );
                  stepsCompletedActions();
                }}
                style={{ color: 'blue', cursor: 'pointer' }}
              >
                {` learn how to tackle them`}
              </span>
              {` to ensure a smooth sign-up process.`}
            </p>
            <p>{`Once you have successfully verified your phone number, your WhatsApp Business account will be ready to use. However, please note that initially, you can only send messages to 50 unique users in a 24-hour session.`}</p>
            <p>
              {`To increase this limit, it is recommended to get your Facebook Business ID verified. You can check the tutorial on`}
              <span
                onClick={() => {
                  window.open(
                    'https://www.superlemon.xyz/help-center/business-api/11/',
                    '_blank'
                  );
                  stepsCompletedActions();
                }}
                style={{ color: 'blue', cursor: 'pointer' }}
              >
                {` how to verify your Facebook Business ID`}
              </span>

              {` to increase the message sending limit.`}
            </p>
          </TextContainer>
        );
    }
  };

  const incrementGuideStep = () => {
    setGuideStep(guideStep + 1);
  };

  const decrementGuideStep = () => {
    setGuideStep(guideStep - 1);
  };

  const stepsCompletedActions = () => {
    props.stepsCompleted();
    props.closeModal();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
      title={'My WhatsApp'}
      primaryAction={{
        content: guideStep === 3 ? 'Guide Completed' : 'Next step',
        onAction: guideStep === 3 ? stepsCompletedActions : incrementGuideStep,
      }}
      secondaryActions={
        guideStep >= 1
          ? [
              {
                content: 'Back',
                onAction: decrementGuideStep,
              },
            ]
          : undefined
      }
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>{modalContentGuideStep()}</Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
export default MyWhatsAppModalGuide;
