export class QueueElement {
  constructor(
    customerPhone,
    customerName,
    ticketStatus,
    tags,
    lastMessage,
    lastMessageTimestamp,
    timestamp,
    notes,
    chatRead
  ) {
    this.customerPhone = customerPhone;
    this.customerName = customerName;
    this.ticketStatus = ticketStatus;
    this.tags = tags;
    this.lastMessage = lastMessage;
    this.lastMessageTimestamp = lastMessageTimestamp;
    this.timestamp = timestamp;
    this.notes = notes;
    this.chatRead = chatRead;
  }
}

export class PriorityQueue {
  constructor() {
    if (!(this.queueItems && this.usersMessageList)) {
      this.queueItems = [];
      this.usersMessageList = [];
    }
  }
  enqueueFunction(obj) {
    let queueElement = new QueueElement(
      obj?.customerPhone,
      obj?.customerName,
      obj?.ticketStatus,
      obj?.tags,
      obj?.lastMessage,
      obj?.lastMessageTimestamp,
      obj?.timestamp,
      obj?.notes,
      obj?.chatRead
    );
    if (this.queueItems.length === 0) {
      this.queueItems.push(queueElement);
    } else {
      let contain = false;
      for (let i = 0; i < this.queueItems.length; i++) {
        if (
          queueElement.lastMessageTimestamp >
          this.queueItems[i].lastMessageTimestamp
        ) {
          this.queueItems.splice(i, 0, queueElement);
          contain = true;
          break;
        }
      }

      /* if the input object has the highest lastMesssageTime push it to end of the queue */
      if (!contain) {
        this.queueItems.push(queueElement);
      }
    }
  }
  dequeueFunction() {
    /* returns the removed object from priority queue. */
    if (this.isPriorityQueueEmpty()) {
      return 'No elements present in Queue';
    }
    return this.queueItems.shift();
  }
  front() {
    /* returns the highest priority queue object without removing it. */
    if (this.isPriorityQueueEmpty()) {
      return 'No elements present in Queue';
    }
    return this.queueItems[0];
  }
  rear() {
    /* returns the lowest priority queue object without removing it. */
    if (this.isPriorityQueueEmpty()) {
      return 'No elements present in Queue';
    }
    return this.queueItems[this.queueItems.length - 1];
  }
  isPriorityQueueEmpty() {
    /* Checks the length of an queue */
    return this.queueItems.length === 0;
  }
  /* prints all the elements of the priority queue */
  printPriorityQueue() {
    return this.queueItems;
  }
  removeDuplicates() {
    this.usersMessageList = [];
    this.queueItems.forEach((item) => {
      let filter = this.queueItems.filter(
        (uD) => uD.customerPhone === item.customerPhone
      );
      let ufilter = this.usersMessageList.filter(
        (uD) => uD.customerPhone === item.customerPhone
      );

      if (filter.length > 1 && ufilter.length < 1) {
        const filterLength = filter.length;
        const newData = {
          ...item,
          count: filterLength,
          tags: filter[filterLength - 1]?.tags,
          timestamp: filter[filterLength - 1]?.timestamp,
          notes: filter[filterLength - 1]?.notes,
        };
        this.usersMessageList.push(newData);
      } else if (filter.length < 2) {
        item.count = 1;
        this.usersMessageList.push(item);
      }
    });
    return this.usersMessageList;
  }
  emptyQueue() {
    this.queueItems = [];
    this.usersMessageList = [];
  }
  getQueueItems() {
    return this.queueItems;
  }
  getusersMessageList() {
    return this.usersMessageList;
  }
  removeItem(customerPhone) {
    this.usersMessageList = [];
    this.usersMessageList = this.queueItems.filter((item) => {
      return item.customerPhone !== customerPhone;
    });
    return this.usersMessageList;
  }
}
