import FAQComponent from './FAQComponent';
import { FAQS } from '../../../constants/Pricing/FAQ/constants';
import FaqIcon from '../../../assets/icons/faq.svg';
import './faq.scss';
import { Fragment, useState } from 'react';
import { PLATFORM } from '../../../constants/constants';

const FAQ = (props: any) => {
  const [openIndex, setOpenIndex] = useState(-1);
  let faqObj = [PLATFORM.WIX, PLATFORM.NONE].includes(props.platform)
    ? FAQS.slice(1)
    : FAQS;

  return (
    <Fragment>
      <div className='fadHeading'>{`FAQ`}</div>
      <div className='Polaris-Stack Polaris-Stack--alignmentCenter Polaris-Stack--distributionFillEvenly w-100 centerAlignment'>
        <div className='Polaris-Stack__Item basic-faq-card'>
          {faqObj.map((faq: any, index) => {
            return (
              <FAQComponent
                faqObj={faq}
                index={index}
                key={index}
                openIndex={openIndex}
                setOpenIndex={setOpenIndex}
              />
            );
          })}
        </div>
        <div className='Polaris-Stack__Item'>
          <div className='d-flex-center '>
            <img src={FaqIcon} alt='FAQ Icon' className='faqIconCss' />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FAQ;
