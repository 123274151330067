import { useEffect, useState } from "react";
import {
  Card,
  Layout,
  Link,
  Modal,
  RadioButton,
  Select,
  Stack,
  TextContainer,
} from "@shopify/polaris";
import { objectDifference } from "../../../../utils/helper";
import { updateFeedbackTemplateSettings } from "../../../../redux/actions/whatsappPromotional";
import {
  AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW,
  TEMPLATE_LANGUAGE_ENG,
  nonOptinMessage,
} from "../../../../constants/constants";
import NonOptinMessageSettings from "../../../../common/NonOptinMessage";
import { useShopStore } from '../../../../hooks/shopStore';

const SettingsModal = (
  {
    showToast,
    showErrorToast,
    open,
    closeModal,
    setTemplateLanguage,
    templateData,
    enabled,
    optinEnterpriseTemplates,
  }: any,
  props: any
) => {
  const { shopDetailsData } = useShopStore();
  const [openModal, setOpenModal] = useState(open);
  const [initialOrderData, setInitialOrderData] = useState({
    language: "ENGLISH",
    interval: 7,
    nonOptinEnabled: true,
  });
  const [orderData, setOrderData] = useState({
    language: "ENGLISH",
    interval: 7,
    nonOptinEnabled: true,
  });
  const [refOrderData, setRefOrderData] = useState({
    language: "ENGLISH",
    interval: 7,
    nonOptinEnabled: true,
  });
  const isPrivateWABAPresent = JSON.parse(
    localStorage.getItem("private_waba") || "{}"
  );

  const [shop, setShop] = useState({
    id: "",
    url: "",
    userId: "",
  });
  const [dataChanges, setDataChanges] = useState<any>({
    template: null,
  });

  useEffect(() => {
    setInitialOrderData(templateData);
    setRefOrderData(templateData);
    setOrderData(templateData);
  }, [templateData]);

  useEffect(() => {
    let shop = shopDetailsData;
    if (shop && shop.id) {
      setShop(shop);
    }
    setOpenModal(open);
  }, [props, open]);

  useEffect(() => {
    var diffObj: any = objectDifference(refOrderData, orderData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        template: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        template: null,
      }));
    }
  }, [orderData, refOrderData]);

  const resetData = () => {
    setDataChanges({
      template: null,
    });
    setRefOrderData(initialOrderData);
    setOrderData(initialOrderData);
  };

  const intervalDescription = (
    <TextContainer spacing="loose">
      <p>
        Select the number of days after which you want the template to get
        triggered.
      </p>
    </TextContainer>
  );
  const discountDescription = (
    <TextContainer spacing="loose">
      <p>You can enable and select the discount type.</p>
    </TextContainer>
  );
  const daysOptions = [
    { label: "3 Days", value: "3" },
    { label: "4 Days", value: "4" },
    { label: "5 Days", value: "5" },
    { label: "6 Days", value: "6" },
    { label: "7 Days", value: "7" },
  ];
  const intervalHelpText = (
    <TextContainer>Maximum days can be 7 and minimum is 3 days.</TextContainer>
  );
  const description = (
    <span>
      <p>
        <b>
          {
            "Automated WhatsApp messages are sent from our official WhatsApp Business API approved "
          }
        </b>
        <Link external={true} url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}>
          {"phone number"}
        </Link>
        {" using message templates approved by WhatsApp."}
        <br />
        <br />
        We include your{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          business name
        </Link>{" "}
        and{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          WhatsApp support number
        </Link>{" "}
        in every automated message. Your customers can recognise you and contact
        you easily if they need help.
      </p>
    </span>
  );
  const nudgeCheckoutOptinScrollSection = (
    <div id="nudgeCheckoutOptin_anchor">
      <Card></Card>
    </div>
  );
  const handleLanguageChange = (newValue: any) => {
    return () => {
      handleTemplateValueChange("language", newValue);
    };
  };

  const handleTemplateValueChange = (field: string, value: any) => {
    setOrderData((data: any) => ({ ...data, [field]: value }));
  };

  const languageMarkup = (
    <Card sectioned>
      <Stack vertical>
        <TextContainer>
          Message Language (all messages will be sent in this language)
        </TextContainer>
        <Stack>
          <RadioButton
            label="English"
            checked={orderData.language === TEMPLATE_LANGUAGE_ENG}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ENG)}
            name="language"
          />
          {/* Below languages not supported for now */}
          {/* <RadioButton
            label="Portugese"
            checked={orderData.language === TEMPLATE_LANGUAGE_PORT}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_PORT)}
            name="language"
          />
          <RadioButton
            label="Spanish"
            checked={orderData.language === TEMPLATE_LANGUAGE_SPAN}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_SPAN)}
            name="language"
          />
          <RadioButton
            label="Italian"
            checked={orderData.language === TEMPLATE_LANGUAGE_ITAL}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ITAL)}
            name="language"
          />
          <RadioButton
            label="French"
            checked={orderData.language === TEMPLATE_LANGUAGE_FREN}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_FREN)}
            name="language"
          />
          <RadioButton
            label="Indonesian"
            checked={orderData.language === TEMPLATE_LANGUAGE_INDO}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_INDO)}
            name="language"
          />
          <RadioButton
            label="German"
            checked={orderData.language === TEMPLATE_LANGUAGE_GERM}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_GERM)}
            name="language"
          />
          <RadioButton
            label="Arabic"
            checked={orderData.language === TEMPLATE_LANGUAGE_ARAB}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ARAB)}
            name="language"
          />
          <RadioButton
            label="Turkish"
            checked={orderData.language === TEMPLATE_LANGUAGE_TURK}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_TURK)}
            name="language"
          />
          <RadioButton
            label="Hebrew"
            checked={orderData.language === TEMPLATE_LANGUAGE_HEBR}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_HEBR)}
            name="language"
          />
          <RadioButton
            label="Dutch"
            checked={orderData.language === TEMPLATE_LANGUAGE_DUTCH}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_DUTCH)}
            name="language"
          />
          <RadioButton
            label="Russian"
            checked={orderData.language === TEMPLATE_LANGUAGE_RUSSIAN}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_RUSSIAN)}
            name="language"
          /> */}
        </Stack>
      </Stack>
    </Card>
  );
  const intervalMarkup = (
    <Card>
      <Card.Section>
        <Select
          label=""
          value={String(orderData.interval)}
          onChange={(val) => handleTemplateValueChange("interval", Number(val))}
          options={daysOptions}
          helpText={intervalHelpText}
          disabled={!enabled}
          placeholder={"Select interval..."}
        />
      </Card.Section>
    </Card>
  );

  const getTemplateMarkup = () => {
    return (
      <>
        <Layout.AnnotatedSection
          title="WhatsApp Message"
          description={description}
        >
          <Stack vertical>{languageMarkup}</Stack>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Interval"
          description={intervalDescription}
        >
          <Stack vertical>
            {intervalMarkup}
            {nudgeCheckoutOptinScrollSection}
          </Stack>
        </Layout.AnnotatedSection>
        {isPrivateWABAPresent && (
          <NonOptinMessageSettings
            nonOptinMessage={nonOptinMessage.FEEDBACK}
            handleChange={handleTemplateValueChange}
            nonOptinMessageEnabled={orderData.nonOptinEnabled}
          />
        )}
      </>
    );
  };
  const updateSettings = () => {
    if (dataChanges.template) {
      let params = new URLSearchParams();
      let data: any = dataChanges.template;

      Object.keys(data).forEach((key) => {
        params.append(key, data[key]);
      });

      updateFeedbackTemplateSettings(shop.userId, params).then(
        (response: any) => {
          if (response.status === "success") {
            showToast("Data Saved");
            setDataChanges({ ...dataChanges, template: null });
            setInitialOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            setRefOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            setOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            setTemplateLanguage("ENGLISH"); // Forcing English as other languages are not supported
          } else {
            showErrorToast(response);
          }
        }
      );
    }
    closeModal();
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        resetData();
        closeModal();
        setOpenModal(false);
      }}
      title={"Feedback Settings"}
      primaryAction={{
        content: "Save",
        onAction: updateSettings,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            resetData();
            closeModal();
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>{getTemplateMarkup()}</Modal.Section>
    </Modal>
  );
};

export default SettingsModal;
