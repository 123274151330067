import {
  Page,
  Modal,
  Stack,
  TextContainer,
  RadioButton,
  Toast,
  TextField,
} from "@shopify/polaris";
import { useState } from "react";
import "./contactUs.scss";
import {
  submitChatbotForm2,
  submitFAQContactUsForm,
} from "../../../../redux/actions/pricing";
import { useShopStore } from '../../../../hooks/shopStore';

const ContactUsModal = (props: any) => {
  const CustomerCount = [
    "0 - 5000",
    "5000 - 15000",
    "15000 - 45000",
    "45000 and above",
  ];
  const { shopDetailsData, shopOwnerDetails } = useShopStore();
  const [requirements, setRequirements] = useState("");
  const [customerCount, setCustomerCount] = useState(CustomerCount[0]);

  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [buttonPressed, setButtonPressed] = useState(false);

  const shop = shopDetailsData;
  const owner = shopOwnerDetails;

  var data = {
    customerCount: "",
    requirements: "",
    shopName: shop.name,
    shopifyLink: shop.url,
    ownerName: owner.name,
    ownerEmail: owner.email,
  };

  var data2 = {
    botPurpose: "",
    botCapacity: "",
    shopName: shop.name,
    shopifyLink: shop.url,
    ownerName: owner.name,
    ownerEmail: owner.email,
  };

  const submitForm = function () {
    if (buttonPressed) {
      return;
    }
    setButtonPressed(true);
    if (shop && shop.id) {
      if (!requirements.trim()) {
        setErrorToast({ show: true, message: "Requirements cannot be empty." });
        setRequirements("");
        setButtonPressed(false);
      } else {
        if (props.secondarySheet) {
          data2["botCapacity"] = customerCount;
          data2["botPurpose"] = requirements;
          submitChatbotForm2(shop.userId, data2).then((res: any) => {
            if (res.status === "success") {
              props.setShowModal(false);
              toggleToast("Submitted successfully");
              setButtonPressed(false);
            } else {
              toggleErrorToast("Error in submitting, Please try again later.");
              setButtonPressed(false);
            }
          });
        } else {
          data["customerCount"] = customerCount;
          data["requirements"] = requirements;
          submitFAQContactUsForm(shop.userId, data).then((res: any) => {
            if (res.status === "success") {
              props.setShowModal(false);
              toggleToast("Submitted successfully");
              setButtonPressed(false);
            } else {
              toggleErrorToast("Error in submitting, Please try again later.");
              setButtonPressed(false);
            }
          });
        }
      }
    }
  };

  const toggleToast = (message: string) => {
    setToast({
      show: !toast.show,
      message: message,
    });
  };

  const toggleErrorToast = (message: string) => {
    setErrorToast({
      show: !errorToast.show,
      message: message,
    });
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        toggleToast("");
      }}
    />
  ) : null;
  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        toggleErrorToast("");
      }}
    />
  ) : null;

  return (
    <Page title="">
      {toastMarkup}
      {errorToastMarkup}
      <Modal
        open={props.showModal}
        sectioned={true}
        title={""}
        onClose={() => {
          props.setShowModal(false);
        }}
      >
        <Modal.Section>
          <div className="basic-contactUs-modal">
            <p>
              What is the feature you are looking for? Eg. Chatbot, Omnichannel
              Presence, WhatsApp Inbox etc. Feel free to describe your
              requirements*
            </p>
            <br />
            <TextField
              label=""
              value={requirements}
              multiline={4}
              onChange={(value) => {
                setRequirements(value);
              }}
            />
            <br />
            <div className="chatbot">
              <Stack vertical>
                <TextContainer>
                  {"How many customers do you serve per month?"}
                </TextContainer>
                {CustomerCount.map((count, index) => {
                  return (
                    <Stack>
                      <div className="contactUsRadioButton">
                        <RadioButton
                          label={count}
                          checked={count === customerCount}
                          onChange={() =>
                            setCustomerCount(CustomerCount[index])
                          }
                        />
                      </div>
                    </Stack>
                  );
                })}
              </Stack>
            </div>

            <br />
            <div className="ButtonFooter">
              <Stack>
                <button
                  className="Polaris-Button cancelButton"
                  type="button"
                  onClick={() => props.setShowModal(false)}
                >
                  <span className="Polaris-Button__Content">
                    <span className="Polaris-Button__Text">Cancel</span>
                  </span>
                </button>
                <button
                  className="Polaris-Button Polaris-Button--primary saveButton"
                  type="button"
                  onClick={() => submitForm()}
                  disabled={buttonPressed}
                >
                  <span className="Polaris-Button__Content">
                    <span className="Polaris-Button__Text">Submit</span>
                  </span>
                </button>
              </Stack>
            </div>
          </div>
        </Modal.Section>
      </Modal>
    </Page>
  );
};

export default ContactUsModal;
