import { COUNTRIES } from "./countries";

export const WALLET_BILLING_ADDRESS = {
  FIELDS: [
    {
      name: "name",
      label: "Name",
      type: "name",
    },
    {
      name: "phone",
      label: "Phone Number",
      type: "phone",
    },
    {
      name: "email",
      label: "Email Address",
      type: "email",
    },
    {
      name: "address1",
      label: "Address Line 1",
      type: "address",
    },
    {
      name: "address2",
      label: "Address Line 2",
      type: "address",
    },
    {
      name: "country",
      label: "Country",
      inputType: "dropDown",
      options: COUNTRIES,
    },
    {
      name: "state",
      label: "State",
      type: "state",
    },
    {
      name: "city",
      label: "City",
      type: "city",
    },
    {
      name: "zipcode",
      label: "Zip Code",
      type: "zip",
    },
    {
      name: "saveBillingAddress",
      label: "Save billing details for future",
      inputType: "checkBox",
    },
  ],
};
