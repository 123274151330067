import { useState } from "react";

const useCustomForm = (callback: any, fields: any) => {
  var [inputsShopify, setInputsShopify] = useState(fields);
  var [inputsWix, setInputsWix] = useState(fields);
  var [inputs, setInputs] = useState(fields);

  const handleSubmitShopify = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };

  const handleInputChangeShopify = (name: string, value: string) => {
    setInputsShopify((inputs: any) => ({ ...inputs, [name]: value }));
  };

  const handleSubmitWix = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };

  const handleInputChangeWix = (name: string, value: string) => {
    setInputsWix((inputsWix: any) => ({ ...inputsWix, [name]: value }));
  };

  const handleInputChange = (name: string, value: string) => {
    setInputs((inputs: any) => ({ ...inputs, [name]: value }));
  };

  return {
    handleSubmitShopify,
    handleInputChangeShopify,
    inputsShopify,
    handleSubmitWix,
    handleInputChangeWix,
    inputsWix,
    handleInputChange,
    inputs,
  };
};

export default useCustomForm;
