// @ts-nocheck
import { Card, TextContainer, TextStyle } from "@shopify/polaris";
import "./enablefeatures.scss";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import obj from "../../../content/WhatsappApi/OverlaysContent/en.json";
import GuideModal from "../../../common/GuideModal";
import {
  applyOptin,
  fetchOptinAdminDetails,
} from "../../../redux/actions/optIn";
import { PLATFORM } from "../../../constants/constants";
import CheckoutOptinGuideModalShopify from "../../AbandonedCart/CheckoutOptinGuideShopify";
import CheckoutOptinGuideModalWiX from "../../AbandonedCart/CheckoutOptinGuideWiX";
import { useShopStore } from '../../../hooks/shopStore';

const EnableFeatures = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [carouselItems, setCarouselItems] = useState(props.item);
  const [open_checkout_optin_modal, setopen_checkout_optin_modal] =
    useState(false);
  const [checkout_optin_modal_steps_completed] = useState(false);
  const history = useHistory();
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);
  const shop = shopDetailsData;

  const [checkoutOptinRequest, setCheckoutOptinRequest] = useState({
    status: null,
    responseDetails: null,
  });

  useEffect(() => {
    getOptinAdminDetails(shop.userId);
  }, []);

  useEffect(() => {
    addButtonListeners();
    setCarouselItems(props.item);
  }, [props]);

  const constructCard = (item: {
    optinTutorial: any;
    tutorial: any;
    secondaryButton: any;
    secondary: any;
    name: {} | null | undefined;
    primary: any;
    primaryPath: string;
    secondaryPath: string;
    description: {} | null | undefined;
    action: any;
  }) => {
    if (item?.optinTutorial) {
      if (checkoutOptinRequest.status) {
        return null;
      }
    }
    return item?.tutorial ? (
      <Card
        title={item?.name}
        primaryFooterAction={{
          content: item?.primary,
          onAction: () => {
            if (item?.optinTutorial) {
              setopen_checkout_optin_modal(true);
            } else {
              setOpenGuideModal(true);
            }
          },
        }}
        secondaryFooterActions={
          item?.secondaryButton
            ? [
                {
                  content: item?.secondaryButton,
                  onAction: () => {
                    localStorage.setItem("fbVerifiedStatus", "true");
                    props.setFBVerifiedStatus(true);
                  },
                },
              ]
            : undefined
        }
        footerActionAlignment="left"
      >
        <Card.Section>
          <TextContainer>
            <TextStyle variation="subdued">{item?.description}</TextStyle>
          </TextContainer>
        </Card.Section>
      </Card>
    ) : item?.secondary ? (
      <Card
        title={item?.name}
        primaryFooterAction={{
          content: item?.primary,
          onAction: () => {
            var url =
              "https://chrome.google.com/webstore/detail/superlemon-a-complete-crm/paknhbpffcejbimandchnlbgcbbclabd?hl=en&authuser=2";
            window.open(url, "_blank");
          },
        }}
        secondaryFooterActions={[
          {
            content: item?.secondary,
            onAction: () => {
              history.push({
                pathname: item?.secondaryPath,
                state: { from: "enableFeatures" },
              });
            },
          },
        ]}
        footerActionAlignment="left"
      >
        <Card.Section>
          <TextContainer>
            <TextStyle variation="subdued">{item?.description}</TextStyle>
          </TextContainer>
        </Card.Section>
      </Card>
    ) : item?.action ? (
      <Card
        title={item?.name}
        primaryFooterAction={{
          content: item?.primary,
          onAction: () => {props.updatePhoneNumberAsWabaNumber()},
        }}
        footerActionAlignment="left"
      >
        <Card.Section>
          <TextContainer>
            <TextStyle variation="subdued">{props?.phoneNumber ? item?.description.replace("##phoneNumber##", props?.phoneNumber) : item?.description}</TextStyle>
          </TextContainer>
        </Card.Section>
      </Card>
    ) : (
      <Card
        title={item?.name}
        primaryFooterAction={{
          content: item?.primary,
          onAction: () => {
            history.push(item?.primaryPath);
          },
        }}
        footerActionAlignment="left"
      >
        <Card.Section>
          <TextContainer>
            <TextStyle variation="subdued">{item?.description}</TextStyle>
          </TextContainer>
        </Card.Section>
      </Card>
    );
  };

  const constructRow = (index: number) => {
    return (
      <div className="card" key={index}>
        {constructCard(carouselItems[index])}
      </div>
    );
  };

  const constructDotRow = (index: number) => {
    return index == 0 ? (
      <button className="dot active" key={index}></button>
    ) : (
      <button className="dot" key={index}></button>
    );
  };

  const constructCardsWrapper = () => {
    var itemList: JSX.Element[] = [];
    carouselItems.forEach(function (item: any, index: any) {
      var row = constructRow(index);
      if (row) {
        itemList.push(row);
      }
    });
    return itemList;
  };

  const constructDotsWrapper = () => {
    var itemList: JSX.Element[] = [];
    carouselItems.forEach(function (item: any, index: any) {
      var dot = constructDotRow(index);
      if (dot) {
        itemList.push(dot);
      }
    });
    return itemList;
  };

  const constructCarousel = () => {
    return (
      <div>
        <div className="display-area">
          <div className="cards-wrapper">{constructCardsWrapper()}</div>
        </div>
        <div className="dots-wrapper">{constructDotsWrapper()}</div>
      </div>
    );
  };

  const addButtonListeners = () => {
    const wrapper = document.querySelector<HTMLElement>(".cards-wrapper");
    const dots = document.querySelectorAll(".dot");
    let activeDotNum = 0;

    dots.forEach((dot, idx) => {
      dot.setAttribute("data-num", String(idx));
      dot.addEventListener("click", (e) => {
        let clickedDotNum = Number((e.target as HTMLElement).dataset.num!);
        if (clickedDotNum == activeDotNum) {
          return;
        } else {
          let displayArea = wrapper!.parentElement!.clientWidth;
          let pixels = -displayArea * clickedDotNum;
          wrapper!.style.transform = "translateX(" + pixels + "px)";
          dots[activeDotNum].classList.remove("active");
          dots[clickedDotNum].classList.add("active");
          activeDotNum = clickedDotNum;
        }
      });
    });

    return (null);
  };

  const closeCheckoutOptinGuideModal = () => {
    setopen_checkout_optin_modal(false);
  };

  const checkoutOptinModalStepsCompleted = () => {
    setopen_checkout_optin_modal(false);
    props.setOptin(true);
    ApplyForApprovalClick();
  };

  const getOptinAdminDetails = (userId: string) => {
    fetchOptinAdminDetails(userId).then((res: any) => {
      if (res.status === "success") {
        setCheckoutOptinRequest(res.optin);
      }
    });
  };

  const ApplyForApprovalClick = () => {
    const shop_origin = shop.url || "";
    var data = {};

    if (!checkoutOptinRequest.status) {
      data = {
        shop: shop_origin,
      };
    } else {
      data = {
        status: 1,
        is_email_sent: false,
      };
    }

    applyOptin(shop.userId).then((res: any) => {
      setCheckoutOptinRequest(res);
      getOptinAdminDetails(shop.userId);
    });
  };

  return carouselItems.length > 0 ? (
    <>
      {props.carousel ? (
        <div className="enableContainer">
          {constructCarousel()}
          {addButtonListeners()}
          {openGuideModal ? (
            <GuideModal
              open={true}
              closeModal={closeGuideModal}
              obj={obj}
              tabIndex={tabIndex}
            />
          ) : null}
        </div>
      ) : (
        <div className="enableContainer">
          {constructCard(carouselItems[0])}
          {openGuideModal ? (
            <GuideModal
              open={true}
              closeModal={closeGuideModal}
              obj={obj}
              tabIndex={tabIndex}
            />
          ) : null}
        </div>
      )}
      {open_checkout_optin_modal ? (
        shop.platform == PLATFORM.SHOPIFY ? (
          <CheckoutOptinGuideModalShopify
            open={true}
            completed={checkout_optin_modal_steps_completed}
            closeModal={closeCheckoutOptinGuideModal}
            stepsCompleted={checkoutOptinModalStepsCompleted}
          />
        ) : (
          <CheckoutOptinGuideModalWiX
            open={true}
            completed={checkout_optin_modal_steps_completed}
            closeModal={closeCheckoutOptinGuideModal}
            stepsCompleted={checkoutOptinModalStepsCompleted}
          />
        )
      ) : null}
    </>
  ) : (
    <></>
  );
};

export default EnableFeatures;
