//@ts-nocheck
import { useEffect, useState } from 'react';
import PageMarkup from '../../common/PageMarkup';
import { getOwnerDetails } from '../../redux/actions/shop';
import AbandonedCart from './AbandonedCart/AbandonedCart';
import OrderCrm from './OrderCrm/OrderCrm';
import Settings from './Settings/Settings';
import ExtensionTemplates from './ExtensionTemplates/Templates';
import { Link, useLocation } from 'react-router-dom';
import { shopFeaturesFromStoreData } from '../../utils/helper';
import { CRM_TOOL, PLATFORM, SHOP_FEATURES } from '../../constants/constants';
import { Heading, Page, TextContainer, TextStyle } from '@shopify/polaris';
import {
  recommendedPlans,
  recommendedPlansNew,
} from '../../constants/Pricing/Plans/constants';
import NudgeScreenContent from '../../common/NudgeScreen';
import Plans from '../Pricing/Plans';
import GenericImageToast from '../../common/GenericImageToast';
import {
  getCRMToolSettings,
  saveCRMToolSettings,
} from "../../redux/actions/crmOrders";
import { useShopStore } from '../../hooks/shopStore';

const Messaging = () => {
  const { shopDetailsData, agentPhone } = useShopStore();
  const shop = shopDetailsData;
  const [showSuccess, setShowSuccess] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [ownerDetails, setownerDetails] = useState();
  const location: any = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentPlanId, setCurrentPlanId] = useState(shopDetailsData.planId);
  const [isCrmToolEnabled, setCrmToolEnabled] = useState(false);
  const [isCrmToolShown, setCrmToolShown] = useState(false);

  useEffect(() => {
    if (shop.userId) {
      getCRMToolSettings(shop.userId).then((res: any) => {
        if (res && res.settings) {
          setCrmToolEnabled(res.settings.enabled);
          setCrmToolShown(!res.settings.createdAfterCrmToolRelease);
        }
      });
    }
  }, [shop]);

  const [tabs, setTabs] = useState([]);
  const [tabContent, setTabContent] = useState({});

  const [recommendedPlanPrice, setRecommendedPlanPrice] = useState(
    [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? recommendedPlans[7][1].price
      : recommendedPlansNew[1].price
  );
  const [recommendedPlanName, setRecommendedPlanName] = useState(
    [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? recommendedPlans[7][1].planName
      : recommendedPlansNew[1].planName
  );

  const toggleCrmToolSettings = async (valueToBeSet: any) => {
    const res = await saveCRMToolSettings(shop.userId, {
      enabled: valueToBeSet,
    });
    if (res && res.settings) {
      setCrmToolEnabled(res.settings.enabled);
    }
  };

  const fetchOwnerDetails = (userId: string) => {
    getOwnerDetails(userId).then(
      (response: any) => {
        if (response.status === 'success') {
          setownerDetails(response.owner);
        }
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.EXTENSION)) {
      setIsEnabled(true);
    }
    fetchOwnerDetails(shop.userId);
    if (location?.state?.sourceTab) {
      setSelectedTab(2);
    } else if (location?.state?.tab === 'home') {
      setSelectedTab(0);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (isCrmToolEnabled) {
      setTabs([
        {
          id: 'Abandoned Cart',
          content: 'Abandoned Cart',
          accessibilityLabel: 'Abandoned Cart',
          panelID: 'abandonedCart',
        },
        {
          id: 'Order CRM',
          content: 'Order CRM',
          accessibilityLabel: 'Order CRM',
          panelID: 'OrderCrm',
        },
        {
          id: 'settings',
          content: 'Settings',
          accessibilityLabel: 'Settings',
          panelID: 'settings',
        },
      ]);
      setTabContent({
        0: (
          <AbandonedCart
            owner={ownerDetails}
            isCrmToolEnabled={isCrmToolEnabled}
            agentPhone={agentPhone}
            showSuccess={showSuccess}
            setShowSuccess={setShowSuccess}
          />
        ),
        1: (
          <OrderCrm
            owner={ownerDetails}
            isCrmToolEnabled={isCrmToolEnabled}
            agentPhone={agentPhone}
            showSuccess={showSuccess}
            setShowSuccess={setShowSuccess}
          />
        ),
        2: <Settings isCrmToolEnabled={isCrmToolEnabled} />,
      });
    } else {
      setTabs([
        {
          id: 'Abandoned Cart',
          content: 'Abandoned Cart',
          accessibilityLabel: 'Abandoned Cart',
          panelID: 'abandonedCart',
        },
        {
          id: 'Order CRM',
          content: 'Order CRM',
          accessibilityLabel: 'Order CRM',
          panelID: 'OrderCrm',
        },
        {
          id: 'Message Templates',
          content: 'Message Templates',
          accessibilityLabel: 'Message Templates',
          panelID: 'messageTemplates',
        },
        {
          id: 'settings',
          content: 'Settings',
          accessibilityLabel: 'Settings',
          panelID: 'settings',
        },
      ]);
      setTabContent({
        0: (
          <AbandonedCart
            owner={ownerDetails}
            isCrmToolEnabled={isCrmToolEnabled}
            agentPhone={agentPhone}
          />
        ),
        1: (
          <OrderCrm
            owner={ownerDetails}
            isCrmToolEnabled={isCrmToolEnabled}
            agentPhone={agentPhone}
          />
        ),
        2: (
          <ExtensionTemplates
            mode={location?.state?.sourceTab ? location.state.sourceTab : null}
          ></ExtensionTemplates>
        ),
        3: <Settings isCrmToolEnabled={isCrmToolEnabled} />,
      });
    }
  }, [isCrmToolEnabled]);

  return !isEnabled ? (
    <Page title={'CRM Tool'}>
      <div className='nudgeScreen-singlebox'>
        <div>
          <TextContainer>
            <Heading>
              <TextStyle variation='strong'>{CRM_TOOL.WHY_CRM_TOOL}</TextStyle>
            </Heading>
            <TextStyle variation='subdued'>
              <br />
              <br />
              {CRM_TOOL.WHY_CRM_TOOL_DESCP}
            </TextStyle>
          </TextContainer>

          <TextContainer>
            <TextStyle variation='subdued'>
              <br />
              <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: `${CRM_TOOL.EXAMPLE}`,
                }}
              ></div>
              <br />
              <br />
              {CRM_TOOL.UPGRADE_MSG.replace(
                '{recommendedPlanName}',
                `${recommendedPlanName}`
              ).replace('{recommendedPlanPrice}', `${recommendedPlanPrice}`)}
              <br />
              <br />
              {CRM_TOOL.LEARN_MORE_TXT} &nbsp;
              <Link to={'/pricing'}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${CRM_TOOL.LEARN_MORE_BUTTON}`,
                  }}
                ></span>
              </Link>
            </TextStyle>
          </TextContainer>
        </div>
        {[PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform) ? (
          <NudgeScreenContent />
        ) : (
          <Plans
            page={CRM_TOOL.HEADER}
            planId={currentPlanId}
            setPlanId={setCurrentPlanId}
            upgradePlanButton={false}
            setShowUpgradePlanDiv={() => {}}
            shopId={shop.id}
            recommendedPlanId={""}
            setRecommendedPlanPrice={setRecommendedPlanPrice}
            setRecommendedPlanName={setRecommendedPlanName}
            userId={shop.userId}
          />
        )}
      </div>
    </Page>
  ) : (
    <>
      <GenericImageToast
        showSuccess={showSuccess}
        setShowSuccess={setShowSuccess}
        value='Message triggered successfully'
      />
      <PageMarkup
        title='CRM Tool'
        tabs={tabs}
        tabContent={tabContent}
        clean={true}
        messaging={isEnabled}
        selected={selectedTab}
        isCrmToolEnabled={isCrmToolEnabled}
        isCrmToolShown={isCrmToolShown}
        toggleCrmToolSettings={toggleCrmToolSettings}
      ></PageMarkup>
    </>
  );
};

export default Messaging;
