import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import SetupWarnCard from "../SetupWarnCard/SetupWarnCard";
import Title from "../SetupWarnCard/Title";
import WabaSetup from "./WabaSetup/WabaSetup";
import { Toast } from "@shopify/polaris";
import {
  EnableFeaturesInfo,
  PLAN_NEW_FREE,
  PLAN_ENGAGE_WIX,
  PLAN_ENTERPRISE,
  PLAN_SMALL_BUSINESS,
  PLAN_OLD_ULTIMATE,
  PLAN_OLD_PRO,
  SHOP_FEATURES,
  PLAN_ENGAGE_PLUS,
} from "../../../constants/constants";
import {
  updatePhoneSettings,
  fetchOrderSettings,
  fetchPhoneSettings,
} from "../../../redux/actions/crmOrders";
import EnableFeatures from "../EnableFeatures";
import { getAbondonedCartSettings } from "../../../redux/actions/abandonedCart";
import {
  getOrderCancellationSettings,
  getPaymentSuccessfullSettings,
} from "../../../redux/actions/new-transaction-templates";
import { fetchCODSettings } from "../../../redux/actions/codSettings";
import { fetchOptinAdminDetails } from "../../../redux/actions/optIn";
import { getTemplates } from "../../../redux/actions/templates";
import { getTeamInboxStatus } from "../../../redux/actions/teamInbox";
import { shopFeaturesFromStoreData } from "../../../utils/helper";
import { useShopStore } from '../../../hooks/shopStore';

const WabaConfiguration = (props: any) => {
  const history = useHistory();
  const { shopDetailsData } = useShopStore();
  const wabaSubmitBtnRef = useRef<HTMLButtonElement>(null);
  const [step, setStep] = useState(0);
  const [phone, setPhone] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [optin, setOptin] = useState(false);
  const [extTemplates, setExtTemplates] = useState(false);
  const [language, setLanguage] = useState(false);
  const [wabaEnabled, setWabaEnabled] = useState(false);
  const [templateEnabled, setTemplateEnabled] = useState(false);
  const [optinFromCheckout, setOptinFromCheckout] = useState(false);
  const [phoneNumberUpdated, setPhoneNumberUpdated] = useState(false);
  const [fbVerifiedStatus, setFBVerifiedStatus] = useState(false);
  const [inboxActivated, setInboxActivated] = useState(false);
  const planId = parseInt(shopDetailsData.planId);
  const shop = shopDetailsData;
  const [toast, setToast] = useState({ show: false, message: "" });

  const [userId, setUserId] = useState("");

  let getSettings = async (userId: any) => {
    let promises = [];
    promises.push(getAbondonedCartSettings(shop.userId));
    promises.push(fetchOrderSettings(shop.userId));
    promises.push(getPaymentSuccessfullSettings(shop.userId));
    promises.push(getOrderCancellationSettings(shop.userId));
    promises.push(fetchCODSettings(shop.userId));
    promises.push(fetchPhoneSettings(shop.userId));
    promises.push(fetchOptinAdminDetails(shop.userId));
    promises.push(getTemplates(shop.userId));
    promises.push(getTeamInboxStatus(shop.userId));

    let resp = await Promise.all(promises);
    let templatesStatusArray = resp.filter((e: any) => {
      return (
        e.status === "success" &&
        (e?.settings?.enabled ||
          e?.settings?.confirmationEnabled ||
          e?.settings?.shipmentEnabled)
      );
    });
    let languageArray = resp.filter((e: any) => {
      return e.status === "success" && e?.settings?.language;
    });
    let phoneArray: any = resp.filter((e: any) => {
      return (
        e.status === "success" && e?.settings?.phone && !e?.settings?.email
      );
    });
    let optinArray = resp.filter((e: any) => {
      return e.status === "success" && e?.optin?.requestedOn;
    });
    let extensionTemplateArray = resp.filter((e: any) => {
      return e.status === "success" && e?.template?.length > 0;
    });
    let inboxResponseArray = resp.filter((e: any) => {
      return (
        e.status === "success" &&
        ["LEADS_SHEET_UPDATED", "ACCOUNT_LIVE", "OTP_VERIFIED"].includes(
          e?.data?.status
        )
      );
    });

    if (templatesStatusArray.length > 0) {
      setTemplateEnabled(true);
    }
    if (languageArray.length > 0) {
      setLanguage(true);
    }
    if (phoneArray.length > 0) {
      setPhone(true);
      if (
        phoneArray[0]?.settings?.countryCode &&
        phoneArray[0]?.settings?.phone
      )
        setPhoneNumber(
          phoneArray[0]?.settings?.countryCode.concat(
            phoneArray[0]?.settings?.phone
          )
        );
    }
    if (optinArray.length > 0) {
      setOptin(true);
    }
    if (extensionTemplateArray.length > 0) {
      setExtTemplates(true);
    }
    if (inboxResponseArray.length > 0) {
      setInboxActivated(true);
    }
  };

  useEffect(() => {
    if (shop) {
      setOptinFromCheckout(shop.optinFromCheckout);
      setUserId(shop.userId);
      getSettings(shop.userId);
    }
  }, []);

  useEffect(() => {
    if (shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.PRIVATE) && props.isWABAEnabled) {
      setWabaEnabled(true);
    }
  }, [props]);

  useEffect(() => {
    if (planId === Number(PLAN_SMALL_BUSINESS)) {      
      if (phone && templateEnabled && wabaEnabled) {
        setStep(4);
      } else if (phone && !templateEnabled && wabaEnabled) {
        setStep(3);
      } else if (!phone && wabaEnabled) {
        setStep(2);
      } else if (!wabaEnabled) {
        setStep(1);
      }
    }
    if (planId === Number(PLAN_NEW_FREE)) {
      if (phone && templateEnabled) {
        setStep(4);
      } else if (phone && !templateEnabled) {
        setStep(3);
      } else if (!phone && wabaEnabled) {
        setStep(2);
      } else if (!wabaEnabled) {
        setStep(1);
      }
    }
    if ([Number(PLAN_OLD_PRO)].includes(planId)) {
      if (phone && templateEnabled && wabaEnabled) {
        setStep(4);
      } else if (phone && !templateEnabled && wabaEnabled) {
        setStep(3);
      } else if (!phone && wabaEnabled) {
        setStep(2);
      } else if (!wabaEnabled) {
        setStep(1);
      }
    }
    if (
      [
        Number(PLAN_ENTERPRISE),
        Number(PLAN_ENGAGE_WIX),
        Number(PLAN_ENGAGE_PLUS),
        Number(PLAN_OLD_ULTIMATE),
      ].includes(planId)
    ) {
      if (phone && templateEnabled && wabaEnabled && inboxActivated) {
        setStep(4);
      } else if (
        phone &&
        templateEnabled &&
        wabaEnabled &&
        !inboxActivated &&
        !extTemplates
      ) {
        setStep(4);
      } else if (phone && templateEnabled && wabaEnabled && !inboxActivated) {
        setStep(5);
      } else if (phone && !templateEnabled && wabaEnabled) {
        setStep(3);
      } else if (!phone && wabaEnabled) {
        setStep(2);
      } else if (!wabaEnabled) {
        setStep(1);
      }
    }
  }, [
    language,
    phone,
    wabaEnabled,
    optinFromCheckout,
    templateEnabled,
    inboxActivated,
  ]);

  useEffect(() => {
    if (step === 4) {
      getFeatureItems(step);
    }
  }, [step, fbVerifiedStatus]);

  useEffect(() => {
    if (phoneNumberUpdated) getSettings(userId);
  }, [phoneNumberUpdated]);

  const getFeatureItems = (step: any) => {
    let fbStatus =
      localStorage.getItem("fbVerifiedStatus") === "false" ? false : true;
    var features = [];
    if (step === 3) {
      features.push(EnableFeaturesInfo["AUTOMATED"]);
    } else if (step === 4) {
      if (
        props.wabaNumber &&
        parseInt(props.wabaNumber) !== parseInt(phoneNumber)
      ) {
        features.push(EnableFeaturesInfo["WA_PRIMARY_CONTACT"]);
      }
      if (!optin) {
        features.push(EnableFeaturesInfo["OPTIN"]);
      }
      if (
        (!fbStatus || !props?.privateWabaDetails?.fbIdVerificationCompleted) &&
        planId !== Number(PLAN_NEW_FREE)
      ) {
        features.push(EnableFeaturesInfo["WABA"]);
      }
    }
    return features;
  };

  const updatePhoneNumberAsWabaNumber = () => {
    let params = new URLSearchParams();
    params.append("phone", props.wabaNumber.slice(2));
    updatePhoneSettings(shop.userId, params).then(() => {
      showToast("Number configured successfully");
      setPhoneNumberUpdated(true);
    });
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  return (
    <>
      {toastMarkup}
      {step === 1 ? (
        <SetupWarnCard
          title={
            <Title title={"Let's set up your WhatsApp business Account"} />
          }
          description={
            "You are all set to use automated template messages. You can also start off by Creating your own Whatsapp Business account with your brand name & brand logo. With the all new small business plan you can Drive Marketing Campaigns, Create Customer Groups & much more! What are you waiting for?"
          }
          onSubmit={() => history.push("/private-waba")}
          btnText={"Get Started"}
        />
      ) : (
        ""
      )}
      {step === 2 ? (
        <SetupWarnCard
          title={<Title title={"Complete Whatsapp Configuration"} />}
          description={
            "SuperLemon includes your WhatsApp support phone number in every automated message, so that your customers can recognise you and contact you easily if they need help. You can always change these details later by going to the configuration section of superlemon app"
          }
          btnText={"Submit"}
          btnRef={wabaSubmitBtnRef}
        >
          <WabaSetup
            submitBtnRef={wabaSubmitBtnRef}
            onSubmit={() => {
              setStep(3);
            }}
            userId={shop.userId}
          />
        </SetupWarnCard>
      ) : (
        ""
      )}
      {step === 3 && (
        <EnableFeatures
          item={getFeatureItems(3)}
          carousel={getFeatureItems(3).length > 1 ? true : false}
          setFBVerifiedStatus={setFBVerifiedStatus}
          setOptin={setOptin}
        />
      )}
      {step === 4 && (
        <EnableFeatures
          item={getFeatureItems(4)}
          carousel={getFeatureItems(4).length > 1 ? true : false}
          setFBVerifiedStatus={setFBVerifiedStatus}
          setOptin={setOptin}
          updatePhoneNumberAsWabaNumber={updatePhoneNumberAsWabaNumber}
          phoneNumber={phoneNumber}
        />
      )}
      {step === 5 && (
        <EnableFeatures
          item={getFeatureItems(5)}
          carousel={getFeatureItems(5).length > 1 ? true : false}
          setFBVerifiedStatus={setFBVerifiedStatus}
          setOptin={setOptin}
        />
      )}
    </>
  );
};

export default WabaConfiguration;
