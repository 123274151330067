import React from "react";
import "./ChatButtonTheme.scss";

const ChatBtnPreview = (props: any) => {
  const template = props.template;
  let custom = props.custom;
  const selected = props.selected;

  if (selected && selected.template) {
    custom["is_chat_btn_solid_background"] = selected.isBgStyleColorSolid;
    custom["chat_btn_bg_color_1"] = selected.bgColor1;
    custom["chat_btn_bg_color_2"] = selected.bgColor2;
    custom["iconColor"] = selected.iconColor;
    custom["chat_btn_text_color"] = selected.buttonTextColor;
  }

  if (!template.iconUrl) {
    template.iconUrl =
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/wa.svg?v=1586952948";
  }

  var classNames =
    "wa-custom-chat-btn " +
    (`${template.buttonCustom} ` || " wa-chat-btn-default");

  var btn_cta = custom.btn_cta;

  var is_chat_btn_solid_background = custom
    ? custom.is_chat_btn_solid_background
    : template.solidBg;
  var bgColor1 = custom.chat_btn_bg_color_1 || template.bgColor1 || "#20802C";
  var bgColor2 = custom.chat_btn_bg_color_2 || template.bgColor2 || "#30BF42";
  var iconColor = custom.iconColor || template.iconColor || "#FFFFFF";
  var chat_btn_text_color =
    custom.chat_btn_text_color || template.textColor || "#ffffff";

  var mainStyle: any = {};
  var imageStyle = {};
  var textStyle = { color: chat_btn_text_color };
  var iconClass = "";
  var iconStyle = {
    background: iconColor,
    WebkitMaskImage: "url(" + template.iconUrl + ")",
    WebkitMaskSize: "cover",
  };

  if (template.buttonCustom.includes("wa-chat-btn-base-cta-with-icon")) {
    if (is_chat_btn_solid_background) {
      imageStyle = { background: bgColor1 };
    } else {
      imageStyle = {
        backgroundImage: `linear-gradient(112.42deg, ${bgColor1} 0%, ${bgColor1} 0.01%, ${bgColor2} 100%)`,
      };
    }

    if (template.iconCustom.includes("no-box-shadow")) {
      iconClass += "no-box-shadow";
    }
  } else {
    if (is_chat_btn_solid_background) {
      mainStyle = { background: bgColor1 };
    } else {
      mainStyle = {
        backgroundImage: `linear-gradient(112.42deg, ${bgColor1} 0%, ${bgColor1} 0.01%, ${bgColor2} 100%)`,
      };
    }

    imageStyle = iconStyle;

    if (template.iconCustom.includes("no-box-shadow")) {
      classNames += "no-box-shadow";
      iconClass += "no-box-shadow";
    }
  }

  if (template.buttonCustom.includes("bordered")) {
    mainStyle["border"] = "1px solid " + iconColor;
  }

  var ctaMarkup =
    template.ctaEnabled && btn_cta ? (
      <div className="wa-chat-button-cta-text" style={textStyle}>
        {btn_cta}
      </div>
    ) : (
      <span></span>
    );

  return (
    <div id="wa-chat-btn-root" className={`${classNames}`} style={mainStyle}>
      {template.buttonCustom.includes("wa-chat-btn-base-cta-with-icon")
        ? ctaMarkup
        : null}
      {template.buttonCustom.includes("wa-chat-btn-base-cta-with-icon") ? (
        <div
          className={`wa-chat-btn-base-icon ${iconClass}`}
          style={imageStyle}
        >
          <div style={iconStyle} className={`${template.iconCustom}`}></div>
        </div>
      ) : (
        <div
          className={`${template.iconCustom} wa-custom-icon`}
          style={imageStyle}
        ></div>
      )}
      {template.buttonCustom.includes("wa-chat-btn-base-cta-with-icon")
        ? null
        : ctaMarkup}
    </div>
  );
};

export default ChatBtnPreview;
