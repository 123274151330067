export const COUNTRY_CODES = [
  {
    value: "93",
    label: "🇦🇫 (AF) +93",
    code: "AF",
    country: "Afghanistan",
  },
  {
    value: "358",
    label: "🇦🇽 (AX) +358",
    code: "AX",
    country: "Aland Islands",
  },
  {
    value: "355",
    label: "🇦🇱 (AL) +355",
    code: "AL",
    country: "Albania",
  },
  {
    value: "213",
    label: "🇩🇿 (DZ) +213",
    code: "DZ",
    country: "Algeria",
  },
  {
    value: "1684",
    label: "🇦🇸 (AS) +1684",
    code: "AS",
    country: "AmericanSamoa",
  },
  {
    value: "376",
    label: "🇦🇩 (AD) +376",
    code: "AD",
    country: "Andorra",
  },
  {
    value: "244",
    label: "🇦🇴 (AO) +244",
    code: "AO",
    country: "Angola",
  },
  {
    value: "1264",
    label: "🇦🇮 (AI) +1264",
    code: "AI",
    country: "Anguilla",
  },
  {
    value: "672",
    label: "🇦🇶 (AQ) +672",
    code: "AQ",
    country: "Antarctica",
  },
  {
    value: "1268",
    label: "🇦🇬 (AG) +1268",
    code: "AG",
    country: "Antigua and Barbuda",
  },
  {
    value: "54",
    label: "🇦🇷 (AR) +54",
    code: "AR",
    country: "Argentina",
  },
  {
    value: "374",
    label: "🇦🇲 (AM) +374",
    code: "AM",
    country: "Armenia",
  },
  {
    value: "297",
    label: "🇦🇼 (AW) +297",
    code: "AW",
    country: "Aruba",
  },
  {
    value: "61",
    label: "🇦🇺 (AU) +61",
    code: "AU",
    country: "Australia",
  },
  {
    value: "43",
    label: "🇦🇹 (AT) +43",
    code: "AT",
    country: "Austria",
  },
  {
    value: "994",
    label: "🇦🇿 (AZ) +994",
    code: "AZ",
    country: "Azerbaijan",
  },
  {
    value: "1242",
    label: "🇧🇸 (BS) +1242",
    code: "BS",
    country: "Bahamas",
  },
  {
    value: "973",
    label: "🇧🇭 (BH) +973",
    code: "BH",
    country: "Bahrain",
  },
  {
    value: "880",
    label: "🇧🇩 (BD) +880",
    code: "BD",
    country: "Bangladesh",
  },
  {
    value: "1246",
    label: "🇧🇧 (BB) +1246",
    code: "BB",
    country: "Barbados",
  },
  {
    value: "375",
    label: "🇧🇾 (BY) +375",
    code: "BY",
    country: "Belarus",
  },
  {
    value: "32",
    label: "🇧🇪 (BE) +32",
    code: "BE",
    country: "Belgium",
  },
  {
    value: "501",
    label: "🇧🇿 (BZ) +501",
    code: "BZ",
    country: "Belize",
  },
  {
    value: "229",
    label: "🇧🇯 (BJ) +229",
    code: "BJ",
    country: "Benin",
  },
  {
    value: "1441",
    label: "🇧🇲 (BM) +1441",
    code: "BM",
    country: "Bermuda",
  },
  {
    value: "975",
    label: "🇧🇹 (BT) +975",
    code: "BT",
    country: "Bhutan",
  },
  {
    value: "591",
    label: "🇧🇴 (BO) +591",
    code: "BO",
    country: "Bolivia, Plurinational State of",
  },
  {
    value: "387",
    label: "🇧🇦 (BA) +387",
    code: "BA",
    country: "Bosnia and Herzegovina",
  },
  {
    value: "267",
    label: "🇧🇼 (BW) +267",
    code: "BW",
    country: "Botswana",
  },
  {
    value: "55",
    label: "🇧🇷 (BR) +55",
    code: "BR",
    country: "Brazil",
  },
  {
    value: "246",
    label: "🇮🇴 (IO) +246",
    code: "IO",
    country: "British Indian Ocean Territory",
  },
  {
    value: "673",
    label: "🇧🇳 (BN) +673",
    code: "BN",
    country: "Brunei Darussalam",
  },
  {
    value: "359",
    label: "🇧🇬 (BG) +359",
    code: "BG",
    country: "Bulgaria",
  },
  {
    value: "226",
    label: "🇧🇫 (BF) +226",
    code: "BF",
    country: "Burkina Faso",
  },
  {
    value: "257",
    label: "🇧🇮 (BI) +257",
    code: "BI",
    country: "Burundi",
  },
  {
    value: "855",
    label: "🇰🇭 (KH) +855",
    code: "KH",
    country: "Cambodia",
  },
  {
    value: "237",
    label: "🇨🇲 (CM) +237",
    code: "CM",
    country: "Cameroon",
  },
  {
    value: "1",
    label: "🇨🇦 (CA) +1",
    code: "CA",
    country: "Canada",
  },
  {
    value: "238",
    label: "🇨🇻 (CV) +238",
    code: "CV",
    country: "Cape Verde",
  },
  {
    value: "345",
    label: "🇰🇾 (KY) + 345",
    code: "KY",
    country: "Cayman Islands",
  },
  {
    value: "236",
    label: "🇨🇫 (CF) +236",
    code: "CF",
    country: "Central African Republic",
  },
  {
    value: "235",
    label: "🇹🇩 (TD) +235",
    code: "TD",
    country: "Chad",
  },
  {
    value: "56",
    label: "🇨🇱 (CL) +56",
    code: "CL",
    country: "Chile",
  },
  {
    value: "86",
    label: "🇨🇳 (CN) +86",
    code: "CN",
    country: "China",
  },
  {
    value: "61",
    label: "🇨🇽 (CX) +61",
    code: "CX",
    country: "Christmas Island",
  },
  {
    value: "61",
    label: "🇨🇨 (CC) +61",
    code: "CC",
    country: "Cocos (Keeling) Islands",
  },
  {
    value: "57",
    label: "🇨🇴 (CO) +57",
    code: "CO",
    country: "Colombia",
  },
  {
    value: "269",
    label: "🇰🇲 (KM) +269",
    code: "KM",
    country: "Comoros",
  },
  {
    value: "242",
    label: "🇨🇬 (CG) +242",
    code: "CG",
    country: "Congo",
  },
  {
    value: "243",
    label: "🇨🇩 (CD) +243",
    code: "CD",
    country: "Congo, The Democratic Republic of the Congo",
  },
  {
    value: "682",
    label: "🇨🇰 (CK) +682",
    code: "CK",
    country: "Cook Islands",
  },
  {
    value: "506",
    label: "🇨🇷 (CR) +506",
    code: "CR",
    country: "Costa Rica",
  },
  {
    value: "225",
    label: "🇨🇮 (CI) +225",
    code: "CI",
    country: "Cote d'Ivoire",
  },
  {
    value: "385",
    label: "🇭🇷 (HR) +385",
    code: "HR",
    country: "Croatia",
  },
  {
    value: "53",
    label: "🇨🇺 (CU) +53",
    code: "CU",
    country: "Cuba",
  },
  {
    value: "357",
    label: "🇨🇾 (CY) +357",
    code: "CY",
    country: "Cyprus",
  },
  {
    value: "420",
    label: "🇨🇿 (CZ) +420",
    code: "CZ",
    country: "Czech Republic",
  },
  {
    value: "45",
    label: "🇩🇰 (DK) +45",
    code: "DK",
    country: "Denmark",
  },
  {
    value: "253",
    label: "🇩🇯 (DJ) +253",
    code: "DJ",
    country: "Djibouti",
  },
  {
    value: "1767",
    label: "🇩🇲 (DM) +1767",
    code: "DM",
    country: "Dominica",
  },
  {
    value: "1849",
    label: "🇩🇴 (DO) +1849",
    code: "DO",
    country: "Dominican Republic",
  },
  {
    value: "593",
    label: "🇪🇨 (EC) +593",
    code: "EC",
    country: "Ecuador",
  },
  {
    value: "20",
    label: "🇪🇬 (EG) +20",
    code: "EG",
    country: "Egypt",
  },
  {
    value: "503",
    label: "🇸🇻 (SV) +503",
    code: "SV",
    country: "El Salvador",
  },
  {
    value: "240",
    label: "🇬🇶 (GQ) +240",
    code: "GQ",
    country: "Equatorial Guinea",
  },
  {
    value: "291",
    label: "🇪🇷 (ER) +291",
    code: "ER",
    country: "Eritrea",
  },
  {
    value: "372",
    label: "🇪🇪 (EE) +372",
    code: "EE",
    country: "Estonia",
  },
  {
    value: "251",
    label: "🇪🇹 (ET) +251",
    code: "ET",
    country: "Ethiopia",
  },
  {
    value: "500",
    label: "🇫🇰 (FK) +500",
    code: "FK",
    country: "Falkland Islands (Malvinas)",
  },
  {
    value: "298",
    label: "🇫🇴 (FO) +298",
    code: "FO",
    country: "Faroe Islands",
  },
  {
    value: "679",
    label: "🇫🇯 (FJ) +679",
    code: "FJ",
    country: "Fiji",
  },
  {
    value: "358",
    label: "🇫🇮 (FI) +358",
    code: "FI",
    country: "Finland",
  },
  {
    value: "33",
    label: "🇫🇷 (FR) +33",
    code: "FR",
    country: "France",
  },
  {
    value: "594",
    label: "🇬🇫 (GF) +594",
    code: "GF",
    country: "French Guiana",
  },
  {
    value: "689",
    label: "🇵🇫 (PF) +689",
    code: "PF",
    country: "French Polynesia",
  },
  {
    value: "241",
    label: "🇬🇦 (GA) +241",
    code: "GA",
    country: "Gabon",
  },
  {
    value: "220",
    label: "🇬🇲 (GM) +220",
    code: "GM",
    country: "Gambia",
  },
  {
    value: "995",
    label: "🇬🇪 (GE) +995",
    code: "GE",
    country: "Georgia",
  },
  {
    value: "49",
    label: "🇩🇪 (DE) +49",
    code: "DE",
    country: "Germany",
  },
  {
    value: "233",
    label: "🇬🇭 (GH) +233",
    code: "GH",
    country: "Ghana",
  },
  {
    value: "350",
    label: "🇬🇮 (GI) +350",
    code: "GI",
    country: "Gibraltar",
  },
  {
    value: "30",
    label: "🇬🇷 (GR) +30",
    code: "GR",
    country: "Greece",
  },
  {
    value: "299",
    label: "🇬🇱 (GL) +299",
    code: "GL",
    country: "Greenland",
  },
  {
    value: "1473",
    label: "🇬🇩 (GD) +1473",
    code: "GD",
    country: "Grenada",
  },
  {
    value: "590",
    label: "🇬🇵 (GP) +590",
    code: "GP",
    country: "Guadeloupe",
  },
  {
    value: "1671",
    label: "🇬🇺 (GU) +1671",
    code: "GU",
    country: "Guam",
  },
  {
    value: "502",
    label: "🇬🇹 (GT) +502",
    code: "GT",
    country: "Guatemala",
  },
  {
    value: "44",
    label: "🇬🇬 (GG) +44",
    code: "GG",
    country: "Guernsey",
  },
  {
    value: "224",
    label: "🇬🇳 (GN) +224",
    code: "GN",
    country: "Guinea",
  },
  {
    value: "245",
    label: "🇬🇼 (GW) +245",
    code: "GW",
    country: "Guinea-Bissau",
  },
  {
    value: "595",
    label: "🇬🇾 (GY) +595",
    code: "GY",
    country: "Guyana",
  },
  {
    value: "509",
    label: "🇭🇹 (HT) +509",
    code: "HT",
    country: "Haiti",
  },
  {
    value: "379",
    label: "🇻🇦 (VA) +379",
    code: "VA",
    country: "Holy See (Vatican City State)",
  },
  {
    value: "504",
    label: "🇭🇳 (HN) +504",
    code: "HN",
    country: "Honduras",
  },
  {
    value: "852",
    label: "🇭🇰 (HK) +852",
    code: "HK",
    country: "Hong Kong",
  },
  {
    value: "36",
    label: "🇭🇺 (HU) +36",
    code: "HU",
    country: "Hungary",
  },
  {
    value: "354",
    label: "🇮🇸 (IS) +354",
    code: "IS",
    country: "Iceland",
  },
  {
    value: "91",
    label: "🇮🇳 (IN) +91",
    code: "IN",
    country: "India",
  },
  {
    value: "62",
    label: "🇮🇩 (ID) +62",
    code: "ID",
    country: "Indonesia",
  },
  {
    value: "98",
    label: "🇮🇷 (IR) +98",
    code: "IR",
    country: "Iran, Islamic Republic of Persian Gulf",
  },
  {
    value: "964",
    label: "🇮🇶 (IQ) +964",
    code: "IQ",
    country: "Iraq",
  },
  {
    value: "353",
    label: "🇮🇪 (IE) +353",
    code: "IE",
    country: "Ireland",
  },
  {
    value: "44",
    label: "🇮🇲 (IM) +44",
    code: "IM",
    country: "Isle of Man",
  },
  {
    value: "972",
    label: "🇮🇱 (IL) +972",
    code: "IL",
    country: "Israel",
  },
  {
    value: "39",
    label: "🇮🇹 (IT) +39",
    code: "IT",
    country: "Italy",
  },
  {
    value: "1876",
    label: "🇯🇲 (JM) +1876",
    code: "JM",
    country: "Jamaica",
  },
  {
    value: "81",
    label: "🇯🇵 (JP) +81",
    code: "JP",
    country: "Japan",
  },
  {
    value: "44",
    label: "🇯🇪 (JE) +44",
    code: "JE",
    country: "Jersey",
  },
  {
    value: "962",
    label: "🇯🇴 (JO) +962",
    code: "JO",
    country: "Jordan",
  },
  {
    value: "77",
    label: "🇰🇿 (KZ) +77",
    code: "KZ",
    country: "Kazakhstan",
  },
  {
    value: "254",
    label: "🇰🇪 (KE) +254",
    code: "KE",
    country: "Kenya",
  },
  {
    value: "686",
    label: "🇰🇮 (KI) +686",
    code: "KI",
    country: "Kiribati",
  },
  {
    value: "850",
    label: "🇰🇵 (KP) +850",
    code: "KP",
    country: "Korea, Democratic People's Republic of Korea",
  },
  {
    value: "82",
    label: "🇰🇷 (KR) +82",
    code: "KR",
    country: "Korea, Republic of South Korea",
  },
  {
    value: "965",
    label: "🇰🇼 (KW) +965",
    code: "KW",
    country: "Kuwait",
  },
  {
    value: "996",
    label: "🇰🇬 (KG) +996",
    code: "KG",
    country: "Kyrgyzstan",
  },
  {
    value: "856",
    label: "🇱🇦 (LA) +856",
    code: "LA",
    country: "Laos",
  },
  {
    value: "371",
    label: "🇱🇻 (LV) +371",
    code: "LV",
    country: "Latvia",
  },
  {
    value: "961",
    label: "🇱🇧 (LB) +961",
    code: "LB",
    country: "Lebanon",
  },
  {
    value: "266",
    label: "🇱🇸 (LS) +266",
    code: "LS",
    country: "Lesotho",
  },
  {
    value: "231",
    label: "🇱🇷 (LR) +231",
    code: "LR",
    country: "Liberia",
  },
  {
    value: "218",
    label: "🇱🇾 (LY) +218",
    code: "LY",
    country: "Libyan Arab Jamahiriya",
  },
  {
    value: "423",
    label: "🇱🇮 (LI) +423",
    code: "LI",
    country: "Liechtenstein",
  },
  {
    value: "370",
    label: "🇱🇹 (LT) +370",
    code: "LT",
    country: "Lithuania",
  },
  {
    value: "352",
    label: "🇱🇺 (LU) +352",
    code: "LU",
    country: "Luxembourg",
  },
  {
    value: "853",
    label: "🇲🇴 (MO) +853",
    code: "MO",
    country: "Macao",
  },
  {
    value: "389",
    label: "🇲🇰 (MK) +389",
    code: "MK",
    country: "Macedonia",
  },
  {
    value: "261",
    label: "🇲🇬 (MG) +261",
    code: "MG",
    country: "Madagascar",
  },
  {
    value: "265",
    label: "🇲🇼 (MW) +265",
    code: "MW",
    country: "Malawi",
  },
  {
    value: "60",
    label: "🇲🇾 (MY) +60",
    code: "MY",
    country: "Malaysia",
  },
  {
    value: "960",
    label: "🇲🇻 (MV) +960",
    code: "MV",
    country: "Maldives",
  },
  {
    value: "223",
    label: "🇲🇱 (ML) +223",
    code: "ML",
    country: "Mali",
  },
  {
    value: "356",
    label: "🇲🇹 (MT) +356",
    code: "MT",
    country: "Malta",
  },
  {
    value: "692",
    label: "🇲🇭 (MH) +692",
    code: "MH",
    country: "Marshall Islands",
  },
  {
    value: "596",
    label: "🇲🇶 (MQ) +596",
    code: "MQ",
    country: "Martinique",
  },
  {
    value: "222",
    label: "🇲🇷 (MR) +222",
    code: "MR",
    country: "Mauritania",
  },
  {
    value: "230",
    label: "🇲🇺 (MU) +230",
    code: "MU",
    country: "Mauritius",
  },
  {
    value: "262",
    label: "🇾🇹 (YT) +262",
    code: "YT",
    country: "Mayotte",
  },
  {
    value: "52",
    label: "🇲🇽 (MX) +52",
    code: "MX",
    country: "Mexico",
  },
  {
    value: "691",
    label: "🇫🇲 (FM) +691",
    code: "FM",
    country: "Micronesia, Federated States of Micronesia",
  },
  {
    value: "373",
    label: "🇲🇩 (MD) +373",
    code: "MD",
    country: "Moldova",
  },
  {
    value: "377",
    label: "🇲🇨 (MC) +377",
    code: "MC",
    country: "Monaco",
  },
  {
    value: "976",
    label: "🇲🇳 (MN) +976",
    code: "MN",
    country: "Mongolia",
  },
  {
    value: "382",
    label: "🇲🇪 (ME) +382",
    code: "ME",
    country: "Montenegro",
  },
  {
    value: "1664",
    label: "🇲🇸 (MS) +1664",
    code: "MS",
    country: "Montserrat",
  },
  {
    value: "212",
    label: "🇲🇦 (MA) +212",
    code: "MA",
    country: "Morocco",
  },
  {
    value: "258",
    label: "🇲🇿 (MZ) +258",
    code: "MZ",
    country: "Mozambique",
  },
  {
    value: "95",
    label: "🇲🇲 (MM) +95",
    code: "MM",
    country: "Myanmar",
  },
  {
    value: "264",
    label: "🇳🇦 (NA) +264",
    code: "NA",
    country: "Namibia",
  },
  {
    value: "674",
    label: "🇳🇷 (NR) +674",
    code: "NR",
    country: "Nauru",
  },
  {
    value: "977",
    label: "🇳🇵 (NP) +977",
    code: "NP",
    country: "Nepal",
  },
  {
    value: "31",
    label: "🇳🇱 (NL) +31",
    code: "NL",
    country: "Netherlands",
  },
  {
    value: "599",
    label: "undefined (AN) +599",
    code: "AN",
    country: "Netherlands Antilles",
  },
  {
    value: "687",
    label: "🇳🇨 (NC) +687",
    code: "NC",
    country: "New Caledonia",
  },
  {
    value: "64",
    label: "🇳🇿 (NZ) +64",
    code: "NZ",
    country: "New Zealand",
  },
  {
    value: "505",
    label: "🇳🇮 (NI) +505",
    code: "NI",
    country: "Nicaragua",
  },
  {
    value: "227",
    label: "🇳🇪 (NE) +227",
    code: "NE",
    country: "Niger",
  },
  {
    value: "234",
    label: "🇳🇬 (NG) +234",
    code: "NG",
    country: "Nigeria",
  },
  {
    value: "683",
    label: "🇳🇺 (NU) +683",
    code: "NU",
    country: "Niue",
  },
  {
    value: "672",
    label: "🇳🇫 (NF) +672",
    code: "NF",
    country: "Norfolk Island",
  },
  {
    value: "1670",
    label: "🇲🇵 (MP) +1670",
    code: "MP",
    country: "Northern Mariana Islands",
  },
  {
    value: "47",
    label: "🇳🇴 (NO) +47",
    code: "NO",
    country: "Norway",
  },
  {
    value: "968",
    label: "🇴🇲 (OM) +968",
    code: "OM",
    country: "Oman",
  },
  {
    value: "92",
    label: "🇵🇰 (PK) +92",
    code: "PK",
    country: "Pakistan",
  },
  {
    value: "680",
    label: "🇵🇼 (PW) +680",
    code: "PW",
    country: "Palau",
  },
  {
    value: "970",
    label: "🇵🇸 (PS) +970",
    code: "PS",
    country: "Palestinian Territory, Occupied",
  },
  {
    value: "507",
    label: "🇵🇦 (PA) +507",
    code: "PA",
    country: "Panama",
  },
  {
    value: "675",
    label: "🇵🇬 (PG) +675",
    code: "PG",
    country: "Papua New Guinea",
  },
  {
    value: "595",
    label: "🇵🇾 (PY) +595",
    code: "PY",
    country: "Paraguay",
  },
  {
    value: "51",
    label: "🇵🇪 (PE) +51",
    code: "PE",
    country: "Peru",
  },
  {
    value: "63",
    label: "🇵🇭 (PH) +63",
    code: "PH",
    country: "Philippines",
  },
  {
    value: "872",
    label: "🇵🇳 (PN) +872",
    code: "PN",
    country: "Pitcairn",
  },
  {
    value: "48",
    label: "🇵🇱 (PL) +48",
    code: "PL",
    country: "Poland",
  },
  {
    value: "351",
    label: "🇵🇹 (PT) +351",
    code: "PT",
    country: "Portugal",
  },
  {
    value: "1939",
    label: "🇵🇷 (PR) +1939",
    code: "PR",
    country: "Puerto Rico",
  },
  {
    value: "974",
    label: "🇶🇦 (QA) +974",
    code: "QA",
    country: "Qatar",
  },
  {
    value: "40",
    label: "🇷🇴 (RO) +40",
    code: "RO",
    country: "Romania",
  },
  {
    value: "7",
    label: "🇷🇺 (RU) +7",
    code: "RU",
    country: "Russia",
  },
  {
    value: "250",
    label: "🇷🇼 (RW) +250",
    code: "RW",
    country: "Rwanda",
  },
  {
    value: "262",
    label: "🇷🇪 (RE) +262",
    code: "RE",
    country: "Reunion",
  },
  {
    value: "590",
    label: "🇧🇱 (BL) +590",
    code: "BL",
    country: "Saint Barthelemy",
  },
  {
    value: "290",
    label: "🇸🇭 (SH) +290",
    code: "SH",
    country: "Saint Helena, Ascension and Tristan Da Cunha",
  },
  {
    value: "1869",
    label: "🇰🇳 (KN) +1869",
    code: "KN",
    country: "Saint Kitts and Nevis",
  },
  {
    value: "1758",
    label: "🇱🇨 (LC) +1758",
    code: "LC",
    country: "Saint Lucia",
  },
  {
    value: "590",
    label: "🇲🇫 (MF) +590",
    code: "MF",
    country: "Saint Martin",
  },
  {
    value: "508",
    label: "🇵🇲 (PM) +508",
    code: "PM",
    country: "Saint Pierre and Miquelon",
  },
  {
    value: "1784",
    label: "🇻🇨 (VC) +1784",
    code: "VC",
    country: "Saint Vincent and the Grenadines",
  },
  {
    value: "685",
    label: "🇼🇸 (WS) +685",
    code: "WS",
    country: "Samoa",
  },
  {
    value: "378",
    label: "🇸🇲 (SM) +378",
    code: "SM",
    country: "San Marino",
  },
  {
    value: "239",
    label: "🇸🇹 (ST) +239",
    code: "ST",
    country: "Sao Tome and Principe",
  },
  {
    value: "966",
    label: "🇸🇦 (SA) +966",
    code: "SA",
    country: "Saudi Arabia",
  },
  {
    value: "221",
    label: "🇸🇳 (SN) +221",
    code: "SN",
    country: "Senegal",
  },
  {
    value: "381",
    label: "🇷🇸 (RS) +381",
    code: "RS",
    country: "Serbia",
  },
  {
    value: "248",
    label: "🇸🇨 (SC) +248",
    code: "SC",
    country: "Seychelles",
  },
  {
    value: "232",
    label: "🇸🇱 (SL) +232",
    code: "SL",
    country: "Sierra Leone",
  },
  {
    value: "65",
    label: "🇸🇬 (SG) +65",
    code: "SG",
    country: "Singapore",
  },
  {
    value: "421",
    label: "🇸🇰 (SK) +421",
    code: "SK",
    country: "Slovakia",
  },
  {
    value: "386",
    label: "🇸🇮 (SI) +386",
    code: "SI",
    country: "Slovenia",
  },
  {
    value: "677",
    label: "🇸🇧 (SB) +677",
    code: "SB",
    country: "Solomon Islands",
  },
  {
    value: "252",
    label: "🇸🇴 (SO) +252",
    code: "SO",
    country: "Somalia",
  },
  {
    value: "27",
    label: "🇿🇦 (ZA) +27",
    code: "ZA",
    country: "South Africa",
  },
  {
    value: "211",
    label: "🇸🇸 (SS) +211",
    code: "SS",
    country: "South Sudan",
  },
  {
    value: "500",
    label: "🇬🇸 (GS) +500",
    code: "GS",
    country: "South Georgia and the South Sandwich Islands",
  },
  {
    value: "34",
    label: "🇪🇸 (ES) +34",
    code: "ES",
    country: "Spain",
  },
  {
    value: "94",
    label: "🇱🇰 (LK) +94",
    code: "LK",
    country: "Sri Lanka",
  },
  {
    value: "249",
    label: "🇸🇩 (SD) +249",
    code: "SD",
    country: "Sudan",
  },
  {
    value: "597",
    label: "🇸🇷 (SR) +597",
    code: "SR",
    country: "Suriname",
  },
  {
    value: "47",
    label: "🇸🇯 (SJ) +47",
    code: "SJ",
    country: "Svalbard and Jan Mayen",
  },
  {
    value: "268",
    label: "🇸🇿 (SZ) +268",
    code: "SZ",
    country: "Swaziland",
  },
  {
    value: "46",
    label: "🇸🇪 (SE) +46",
    code: "SE",
    country: "Sweden",
  },
  {
    value: "41",
    label: "🇨🇭 (CH) +41",
    code: "CH",
    country: "Switzerland",
  },
  {
    value: "963",
    label: "🇸🇾 (SY) +963",
    code: "SY",
    country: "Syrian Arab Republic",
  },
  {
    value: "886",
    label: "🇹🇼 (TW) +886",
    code: "TW",
    country: "Taiwan",
  },
  {
    value: "992",
    label: "🇹🇯 (TJ) +992",
    code: "TJ",
    country: "Tajikistan",
  },
  {
    value: "255",
    label: "🇹🇿 (TZ) +255",
    code: "TZ",
    country: "Tanzania, United Republic of Tanzania",
  },
  {
    value: "66",
    label: "🇹🇭 (TH) +66",
    code: "TH",
    country: "Thailand",
  },
  {
    value: "670",
    label: "🇹🇱 (TL) +670",
    code: "TL",
    country: "Timor-Leste",
  },
  {
    value: "228",
    label: "🇹🇬 (TG) +228",
    code: "TG",
    country: "Togo",
  },
  {
    value: "690",
    label: "🇹🇰 (TK) +690",
    code: "TK",
    country: "Tokelau",
  },
  {
    value: "676",
    label: "🇹🇴 (TO) +676",
    code: "TO",
    country: "Tonga",
  },
  {
    value: "1868",
    label: "🇹🇹 (TT) +1868",
    code: "TT",
    country: "Trinidad and Tobago",
  },
  {
    value: "216",
    label: "🇹🇳 (TN) +216",
    code: "TN",
    country: "Tunisia",
  },
  {
    value: "90",
    label: "🇹🇷 (TR) +90",
    code: "TR",
    country: "Turkey",
  },
  {
    value: "993",
    label: "🇹🇲 (TM) +993",
    code: "TM",
    country: "Turkmenistan",
  },
  {
    value: "1649",
    label: "🇹🇨 (TC) +1649",
    code: "TC",
    country: "Turks and Caicos Islands",
  },
  {
    value: "688",
    label: "🇹🇻 (TV) +688",
    code: "TV",
    country: "Tuvalu",
  },
  {
    value: "256",
    label: "🇺🇬 (UG) +256",
    code: "UG",
    country: "Uganda",
  },
  {
    value: "380",
    label: "🇺🇦 (UA) +380",
    code: "UA",
    country: "Ukraine",
  },
  {
    value: "971",
    label: "🇦🇪 (AE) +971",
    code: "AE",
    country: "United Arab Emirates",
  },
  {
    value: "44",
    label: "🇬🇧 (GB) +44",
    code: "GB",
    country: "United Kingdom",
  },
  {
    value: "1",
    label: "🇺🇸 (US) +1",
    code: "US",
    country: "United States",
  },
  {
    value: "598",
    label: "🇺🇾 (UY) +598",
    code: "UY",
    country: "Uruguay",
  },
  {
    value: "998",
    label: "🇺🇿 (UZ) +998",
    code: "UZ",
    country: "Uzbekistan",
  },
  {
    value: "678",
    label: "🇻🇺 (VU) +678",
    code: "VU",
    country: "Vanuatu",
  },
  {
    value: "58",
    label: "🇻🇪 (VE) +58",
    code: "VE",
    country: "Venezuela, Bolivarian Republic of Venezuela",
  },
  {
    value: "84",
    label: "🇻🇳 (VN) +84",
    code: "VN",
    country: "Vietnam",
  },
  {
    value: "1284",
    label: "🇻🇬 (VG) +1284",
    code: "VG",
    country: "Virgin Islands, British",
  },
  {
    value: "1340",
    label: "🇻🇮 (VI) +1340",
    code: "VI",
    country: "Virgin Islands, U.S.",
  },
  {
    value: "681",
    label: "🇼🇫 (WF) +681",
    code: "WF",
    country: "Wallis and Futuna",
  },
  {
    value: "967",
    label: "🇾🇪 (YE) +967",
    code: "YE",
    country: "Yemen",
  },
  {
    value: "260",
    label: "🇿🇲 (ZM) +260",
    code: "ZM",
    country: "Zambia",
  },
  {
    value: "263",
    label: "🇿🇼 (ZW) +263",
    code: "ZW",
    country: "Zimbabwe",
  },
];
