import { useEffect } from 'react';
import successImage from '../assets/images/success.gif';

const GenericImageToast = ({ showSuccess, setShowSuccess, value }: any) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (showSuccess) {
        setShowSuccess(false);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [showSuccess]);

  return (
    showSuccess && (
      <div className='genericToastContainer'>
        <div className='genericToastContainer--inner'>
          <img width={300} height={250} src={successImage} alt='test' />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: 'green',
            }}
          >
            <p>{value}</p>
          </div>
        </div>
      </div>
    )
  );
};
export default GenericImageToast;
