import React, { useEffect, useState } from "react";
import {
  Card,
  Checkbox,
  FormLayout,
  Layout,
  Stack,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { PLATFORM } from "../../constants/constants";

const PagesToDisplay = (props: any) => {
  const [fields, setFields] = useState<any>({
    HOME: false,
    COLLECTION: false,
    PRODUCT: false,
    CART_DESKTOP: false,
    CART_MOBILE: false,
    THANKYOU: false,
    BLOGPOST: false,
    PAGE: false,
    ACCOUNT: false,
  });
  const [refFields, setRefFields] = useState<any>({
    HOME: false,
    COLLECTION: false,
    PRODUCT: false,
    CART_DESKTOP: false,
    CART_MOBILE: false,
    THANKYOU: false,
    BLOGPOST: false,
    PAGE: false,
    ACCOUNT: false,
  });
  const [initialData, setInitialData] = useState<any>({
    HOME: false,
    COLLECTION: false,
    PRODUCT: false,
    CART_DESKTOP: false,
    CART_MOBILE: false,
    THANKYOU: false,
    BLOGPOST: false,
    PAGE: false,
    ACCOUNT: false,
  });
  const [updatedData, setUpdatedData] = useState<string[]>();
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };
  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  useEffect(() => {
    if (props.pages) setPagesToDisplay(props.pages);
  }, [props.pages]);

  useEffect(() => {
    if (props.isDataSubmitted) {
      setRefFields(fields);
      setInitialData(fields);
    }
  }, [props.isDataSubmitted]);

  useEffect(() => {
    if (props.reset) {
      setFields(initialData);
      setRefFields(initialData);
    }
  }, [props.reset]);

  useEffect(() => {
    if (updatedData) {
      props.onFieldChange(updatedData);
    }
  }, [updatedData]);

  const setPagesToDisplay = (pages: any) => {
    for (let i = 0; i < pages.length; i++) {
      referenceObj(pages[i], true);
    }

    for (let key in fields) {
      let exists = pages.includes(key);

      if (!exists) {
        referenceObj(key, false);
      }
    }
  };
  const referenceObj = (name: string, value: boolean) => {
    setRefFields((refFields: any) => ({ ...refFields, [name]: value }));
    setFields((fields: any) => ({ ...fields, [name]: value }));
    setInitialData((fields: any) => ({ ...fields, [name]: value }));
  };

  const handleToggle = (name: string, value: boolean) => {
    setUpdatedData([]);
    var onePageStatus = true;
    var isSameField: any = false;
    if (props.isWAEnabled) {
      if (!haveSameData(fields, refFields)) {
        var filtered = Object.keys(fields).filter((key) => {
          return fields[key];
        });
        isSameField = Object.keys(fields).find((key) => {
          return key === name && fields[key];
        });
        if (filtered && filtered.length === 1 && isSameField) {
          onePageStatus = false;
          showErrorToast(props.errorText);
        }
        setUpdatedData(filtered);
      }
    }
    if (onePageStatus || !isSameField) {
      let data = { ...fields, [name]: value };
      if (
        Object.keys(data).filter((key: any) => data[key] === true).length === 0
      ) {
        showErrorToast(props.errorText);
        return;
      }
      setFields(data);
      setUpdatedData(
        Object.keys(data).filter((key: any) => data[key] === true)
      );
    }
  };

  const haveSameData = (obj1: any, obj2: any) => {
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;

    if (obj1Length === obj2Length) {
      return Object.keys(obj1).every(
        (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
      );
    }
    return false;
  };

  return (
    <Layout.AnnotatedSection title={props.title} description={props.subTitle}>
      {errorToastMarkup}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <div>
              <Stack vertical={true}>
                <Checkbox
                  label="Homepage"
                  checked={fields.HOME}
                  onChange={(val) => handleToggle("HOME", val)}
                />
                { props.platform === PLATFORM.SHOPIFY && <Checkbox
                  label="Collections"
                  checked={fields.COLLECTION}
                  onChange={(val) => handleToggle("COLLECTION", val)}
                />}
                <Checkbox
                  label="Product pages"
                  checked={fields.PRODUCT}
                  onChange={(val) => handleToggle("PRODUCT", val)}
                />
                <Checkbox
                  label="Cart Desktop"
                  checked={fields.CART_DESKTOP}
                  onChange={(val) => handleToggle("CART_DESKTOP", val)}
                />
                <Checkbox
                  label="Cart Mobile"
                  checked={fields.CART_MOBILE}
                  onChange={(val) => handleToggle("CART_MOBILE", val)}
                />
                <Checkbox
                  label="Thank You page"
                  checked={fields.THANKYOU}
                  onChange={(val) => handleToggle("THANKYOU", val)}
                />
                { props.platform === PLATFORM.SHOPIFY && <Checkbox
                  label="Blog post pages"
                  checked={fields.BLOGPOST}
                  onChange={(val) => handleToggle("BLOGPOST", val)}
                /> }
                <Checkbox
                  label="URLs ending with /pages"
                  checked={fields.PAGE}
                  onChange={(val) => handleToggle("PAGE", val)}
                />
                { props.platform === PLATFORM.SHOPIFY && <Checkbox
                  label="Account pages"
                  checked={fields.ACCOUNT}
                  onChange={(val) => handleToggle("ACCOUNT", val)}
                /> }
                <TextStyle variation="subdued">{props.footerText}</TextStyle>
              </Stack>
            </div>
          </FormLayout.Group>
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default PagesToDisplay;
