import { Page, Modal, Link } from "@shopify/polaris";
import "./insufficientBalModal.scss";
import highPriorityicon from "../../assets/icons/highPriority.svg";
import { useHistory } from "react-router-dom";

const InsufficientBalModal = (props: any) => {
  const history = useHistory();

  return (
    <Page>
      <Modal
        open={props.showModal}
        sectioned={true}
        title={""}
        onClose={() => {
          props.setShowModal(false);
        }}
        small
      >
        <Modal.Section>
          <div className="basic-insufficientBal-modal">
            <div className="insufficientBalModalHeading">
              <img src={highPriorityicon} alt="ErrorIcon"></img>
              Insufficient Balance
            </div>
            <div className="insufficientBalModalContent">
              {!props?.errorDetails || props?.walletBalance === 0 ? (
                <div>
                  <p>You do not have enough wallet balance.</p>
                  <p>Please recharge.</p>
                </div>
              ) : (
                <p>
                  {`You are trying to send WhatsApp Campaign to ${props?.errorDetails?.size} customers.
                  However, you do not have enough wallet balance to trigger the
                  campaign.`}
                </p>
              )}

              <br />
              <p>
                Please note that charge per message ={" "}
                <Link
                  url={
                    "https://docs.google.com/spreadsheets/d/14Wc_J09VwCZ3LaIzVfsCs5bySHTVSITk2sk0JsEjzAE"
                  }
                  external={true}
                >
                  WhatsApp cost
                </Link>{" "}
                + $ 0.004
              </p>

              <br />
              <br />
            </div>
            <div className="insufficientBalModalFooter">
              <button
                className="insufficientBalModalButtonCss"
                type="button"
                onClick={() => {
                  props.setShowModal(false);
                  history.push({
                    pathname: "/wallet",
                    state: { page: 1 },
                  });
                }}
              >
                Recharge Wallet
              </button>
            </div>
          </div>
        </Modal.Section>
      </Modal>
    </Page>
  );
};

export default InsufficientBalModal;
