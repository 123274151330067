import {
  Heading,
  Link,
  Page,
  TextContainer,
  TextStyle,
} from '@shopify/polaris';
import { PLAN_ENTERPRISE, PLATFORM } from '../../constants/constants';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import {
  PLANS,
  recommendedPlans,
} from '../../constants/Pricing/Plans/constants';
import Plans from '../Pricing/Plans';
import { useShopStore } from '../../hooks/shopStore';

const WINudgeScreen = () => {
  const history = useHistory();
  const { shopDetailsData } = useShopStore();
  const currentPlanId = shopDetailsData?.planId;
  const shop = shopDetailsData;
  const [recommendedPlanPrice, setRecommendedPlanPrice] = useState(
    [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? recommendedPlans[7][1].price
      : PLANS[5].price
  );
  const [recommendedPlanName, setRecommendedPlanName] = useState(
    [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)
      ? recommendedPlans[7][1].planName
      : PLANS[5].planName
  );

  return (
    <Page title='WhatsApp Inbox'>
      <div className={'nudgeScreen-teaminbox-adjusted'}>
        <div className={'nudgeScreen-leftColumn-padded'}>
          <TextContainer>
            <Heading>
              <TextStyle variation='strong'>
                Why you should use WhatsApp Inbox?
              </TextStyle>
            </Heading>
            <TextStyle variation='subdued'>
              <br />
              Inbox tool offers an inbox feature for businesses that use the
              WhatsApp Business API. This Inbox tool allows businesses to
              efficiently manage and respond to customer messages from multiple
              WhatsApp accounts in one place. This feature offers a
              comprehensive view of all the conversations that a business has
              had with its customers, allowing them to keep track of previous
              conversations and follow up with customers in a timely manner.
            </TextStyle>
          </TextContainer>

          <TextContainer>
            <TextStyle variation='subdued'>
              <br />
              Moreover, the Inbox tool provides the ability to filter, tag and
              prioritize messages based on their urgency and relevance, ensuring
              that businesses never miss important customer inquiries.
            </TextStyle>
            <br />
          </TextContainer>
          <TextContainer>
            <TextStyle variation='subdued'>
              <br />
              It also offers features like quick responses, which enables
              businesses to save frequently used messages as templates, and
              quick replies that help in responding to common customer queries
              quickly.
            </TextStyle>
          </TextContainer>
        </div>
        <div className='nudgeScreen-rightColumn'>
          <Plans
            page='WhatsApp Inbox'
            planId={currentPlanId}
            setPlanId={() => {}}
            upgradePlanButton={false}
            setShowUpgradePlanDiv={() => {}}
            shopId={shop.id}
            userId={shop.userId}
            recommendedPlanId={PLAN_ENTERPRISE}
            setRecommendedPlanPrice={setRecommendedPlanPrice}
            setRecommendedPlanName={setRecommendedPlanName}
          />
        </div>
      </div>
    </Page>
  );
};

export default WINudgeScreen;
