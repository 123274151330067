import { TEMPLATES } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

export const getTemplates = (userId: string) => {
  return httpClient.get(TEMPLATES.replace("$userId", userId));
};

export const createTemplate = (userId: string, params: any) => {
  return httpClient.post(TEMPLATES.replace("$userId", userId), params);
};

export const editTemplate = (
  userId: string,
  templateId: string,
  params: any
) => {
  return httpClient.post(
    `${TEMPLATES.replace("$userId", userId)}/${templateId}`,
    params
  );
};

export const deleteTemplate = (userId: string, templateId: string) => {
  return httpClient.delete(
    `${TEMPLATES.replace("$userId", userId)}/${templateId}`
  );
};
