import httpClient from "../../utils/http-client";
import {
  TEAM_INBOX_SEND_OTP,
  TEAM_INBOX_STATUS,
  TEAM_INBOX_VERIFY_OTP,
} from "../../constants/api-constants";

export const getTeamInboxStatus = (userId: string) => {
  return httpClient.get(TEAM_INBOX_STATUS.replace("$userId", userId));
};

export const sendTeamInboxOTP = (userId: string, params: any) => {
  return httpClient.post(
    TEAM_INBOX_SEND_OTP.replace("$userId", userId),
    params
  );
};

export const verifyTeamInboxOTP = (userId: string, params: any) => {
  return httpClient.post(
    TEAM_INBOX_VERIFY_OTP.replace("$userId", userId),
    params
  );
};
