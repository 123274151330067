import {
  ContextualSaveBar,
  Layout,
  Link,
  SettingToggle,
  TextStyle,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import {
  getShareDetails,
  updateShareDetails,
  updateShareTheme,
} from "../../redux/actions/share-settings";
import ShareDevices from "./Share/Devices/index";
import ShareButtonTheme from "./Share/ShareButtonTheme";
import SharePagesToDisplay from "./Share/SharePagesToDisplay";
import {
  deleteShareDevices,
  updateShareDevices,
} from "../../redux/actions/share-settings";
import { isNullOrEmpty } from "../../utils/helper";
import React from "react";
import PagesToDisplay from "./PagesToDisplay";
import {
  DEVICE_TYPE_DESKTOP_ONLY,
  DEVICE_TYPE_MOBILE_AND_DESKTOP,
  DEVICE_TYPE_MOBILE_ONLY,
  PLATFORM,
  SHOP_FEATURES,
  PLANS_MODAL_TITLE
} from "../../constants/constants";
import { clevertapEventPush, CT_EVENT_IDS } from "../../utils/clevertapUtils";
import AppEmbedDeepLink from "../../common/AppEmbedDeepLink";
import { shopFeaturesFromStoreData } from "../../utils/helper";
import NoScriptModal from "../Modals/NoScriptTagModal/NoScriptModal";
import PlansModal from "../Modals/PlansModal";
import { useShopStore } from '../../hooks/shopStore';

const ShareSettings = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const { setToast, setErrorToast, setShowErrorToast, setShowSuccessToast } =
    props;
  const [isWAShareEnabled, setIsWAShareEnabled] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const [shareData, setShareData] = useState({
    btn_cta: "",
    buttonId: "",
    buttonPages: [],
    include_product_link: false,
    is_share_btn_color_custom: false,
    is_share_btn_solid_background: false,
    is_share_enabled: true,
    prefilled_msg: "Please share about us",
    share_btn_bg_color_1: "#ffff",
    share_btn_bg_color_2: "#ffff",
    share_btn_icon_color: "#ffff",
    share_btn_text_color: "#ffff",
  });
  const [dataChanges, setDataChanges] = useState<any>({
    share: null,
    shareTheme: null,
    pagesToDisplay: "",
    sharePagesToDisplay: "",
    devices: null,
  });
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDeviceType, setSelectedDeviceType] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<any>({
    shareTheme: "",
    mobile: "",
    desktop: "",
  });

  const [devicesData, setDevicesData] = useState({
    deviceType: null,
    mobileSelector: "",
    desktopSelector: "",
  });

  const getDeviceData = (data: any) => {
    setDevicesData(data);
  };

  const [showNoScriptModal, setShowNoScriptModal] = useState(false);
  let shop = shopDetailsData;
  let planId = shopDetailsData?.planId;

  useEffect(() => {
    if (shop && shop.userId) {
      fetchShareData(shop.userId);
    }
  }, []);

  useEffect(() => {
    if (hasChanges) {
      setDiscardChanges(false);
    }
  }, [hasChanges]);

  const fetchShareData = (userId: string) => {
    getShareDetails(userId).then(
      (data: any) => {
        setIsWAShareEnabled(data.is_share_enabled);
        setShareData(data);
        clevertapEventPush({
          eventId: CT_EVENT_IDS.SHARE_SETTINGS_PAGE_VIEWED,
          eventData: { widgetEnabled: data.is_share_enabled },
        });
      },
      (err) => {}
    );
  };

  const getToggleSwitchShareBtnContent = () => {
    return isWAShareEnabled ? "Disable" : "Enable";
  };
  const getToggleSwitchshareTxtContent = () => {
    return isWAShareEnabled ? "enabled" : "disabled";
  };
  const getToggleSwitchShareVariationStyle = () => {
    return isWAShareEnabled ? "strong" : "negative";
  };
  const checkForApproval = () => {
    if (shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.WIDGETS_RESTRICTED)) {
      setShowModal(true);
      return true;
    } else {
      setShowModal(false);
      return false;
    }
  };
  const enableWhatsAppShare = () => {
    if (!shop.scriptTagAdded) {
      setShowNoScriptModal(true);
      return;
    }
    if (checkForApproval()) {
      return;
    }
    let isShareEnabled = isWAShareEnabled ? false : true;
    const params = new URLSearchParams();
    params.append("enabled", isShareEnabled.toString());
    updateShareDetails(shop.userId, params).then((share: any) => {
      let toastMessage = isShareEnabled ? "Enabled Share" : "Disabled Share";
      clevertapEventPush({
        eventId: isShareEnabled
          ? CT_EVENT_IDS.SHARE_WIDGET_ENABLED
          : CT_EVENT_IDS.SHARE_WIDGET_DISABLED,
        eventData: {},
      });
      setToast(toastMessage);
      setShareData(share.share);
      setIsWAShareEnabled(isShareEnabled);
    });
  };

  const shareButtonThemeChanges = (data: any) => {
    if (data && Object.keys(data).length > 0) {
      setHasChanges(true);
      setDataChanges({ ...dataChanges, share: data });
    }
  };

  const devicesChanges = (data: any) => {
    if (Object.keys(data).length > 0) {
      if (Object.keys(data.mobile).length > 0) {
        setDataChanges({
          ...dataChanges,
          devices: {
            ...dataChanges.devices,
            mobile: data.mobile,
          },
        });
        setHasChanges(true);
      }

      if (Object.keys(data.desktop).length > 0) {
        setDataChanges({
          ...dataChanges,
          devices: {
            ...dataChanges.devices,
            desktop: data.desktop,
          },
        });
        setHasChanges(true);
      }
    }
  };

  const sharePagesToDisplayChanges = (data: any) => {
    if (data && data.length > 0) {
      setHasChanges(true);
      setDataChanges({ ...dataChanges, sharePagesToDisplay: data.toString() });
    } else {
      setHasChanges(false);
    }
  };

  const isFormValid = () => {
    // here
    let valid = true,
      message: string = "";
    for (const item of Object.keys(errorMessage)) {
      if (item === "mobile" && selectedDeviceType === DEVICE_TYPE_DESKTOP_ONLY)
        continue;
      if (item === "desktop" && selectedDeviceType === DEVICE_TYPE_MOBILE_ONLY)
        continue;

      if (errorMessage[item]) {
        valid = false;
        message = errorMessage[item];
      }
    }

    return { valid: valid, message: message };
  };

  const updateShare = () => {
    if (devicesData.deviceType === 1) {
      if (!devicesData.mobileSelector) {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Invalid Mobile Share Button Position");
        return;
      }
    }

    if (devicesData.deviceType === 2) {
      if (!devicesData.desktopSelector) {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Invalid Desktop Share Button Position");
        return;
      }
    }

    if (devicesData.deviceType === 3) {
      if (!devicesData.desktopSelector) {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Invalid Desktop Share Button Position");
        return;
      }
      if (!devicesData.mobileSelector) {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Invalid Mobile Share Button Position");
        return;
      }
    }

    if (
      dataChanges.share &&
      "buttonCta" in dataChanges.share &&
      isNullOrEmpty(dataChanges.share.buttonCta)
    ) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("Share button text cannot be empty");
      return;
    }
    if (
      dataChanges.share &&
      "shareMessage" in dataChanges.share &&
      isNullOrEmpty(dataChanges.share.shareMessage)
    ) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("Share message text cannot be empty");
      return;
    }

    let form = isFormValid();
    if (!form.valid) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast(form.message);
    } else {
      setIsDataSubmitted(false);
      if (dataChanges.share) {
        var params: any = new URLSearchParams();
        var customParams = new URLSearchParams();
        var data: any = dataChanges.share;

        Object.keys(data).forEach((key) => {
          switch (key) {
            case "templateId":
              customParams.append(key, data[key]);
              params.append(key, data[key]);
              break;
            case "bgColor1":
            case "bgColor2":
            case "iconColor":
            case "textColor":
            case "solidBg":
              customParams.append(key, data[key]);
              break;
            default:
              params.append(key, data[key]);
              break;
          }
        });

        if (shareData.is_share_btn_color_custom) {
          if (data.templateId) {
            updateShareTheme(shop.userId, data.templateId, customParams).then(
              (response: any) => {
                if (response.status === "success") {
                  clevertapEventPush({
                    eventId: CT_EVENT_IDS.WIDGET_EDITED,
                    eventData: {
                      "Widget Name": "Share widget",
                    },
                  });
                  updateShareButtonDetails(params);
                  setShowSuccessToast((successToast: any) => !successToast);
                  setToast("Custom Changes Saved");
                  setDataChanges({ ...dataChanges, share: null });
                  setIsDataSubmitted(true);
                } else {
                  setShowErrorToast((errorToast: any) => !errorToast);
                  setErrorToast(response);
                }
              },
              (err: any) => {
                setShowErrorToast((errorToast: any) => !errorToast);
                setErrorToast("Something went wrong");
              }
            );
          }
        } else {
          updateShareButtonDetails(params);
        }
      }

      if (devicesData.deviceType === DEVICE_TYPE_MOBILE_ONLY) {
        updateMobileData();
        deleteDesktopData();
      } else if (devicesData.deviceType === DEVICE_TYPE_DESKTOP_ONLY) {
        updateDesktopData();
        deleteMobileData();
      } else if (devicesData.deviceType === DEVICE_TYPE_MOBILE_AND_DESKTOP) {
        updateMobileData();
        updateDesktopData();
      }

      if (dataChanges.sharePagesToDisplay) {
        const params = new URLSearchParams();
        params.append("buttonPages", dataChanges.sharePagesToDisplay);
        updateShareDetails(shop.userId, params).then((response: any) => {
          if (response.status === "success") {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.WIDGET_EDITED,
              eventData: {
                "Widget Name": "Share widget",
              },
            });
            setShowSuccessToast((successToast: any) => !successToast);
            setToast("Data Saved Successfully");
            setDataChanges({ ...dataChanges, pagesToDisplay: "" });
            setIsDataSubmitted(true);
          } else {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(response);
          }
        });
      }

      setHasChanges(false);
    }
  };

  const updateShareButtonDetails = (params: any) => {
    updateShareDetails(shop.userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.WIDGET_EDITED,
            eventData: {
              "Widget Name": "Share widget",
            },
          });
          setShowSuccessToast((successToast: any) => !successToast);
          setToast("Share Data Saved");
          setDataChanges({ ...dataChanges, share: null });
          setIsDataSubmitted(true);
          setShareData({ ...shareData, ...response.share });
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      },
      (err: any) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Something went wrong");
      }
    );
  };

  const updateMobileData = () => {
    if (devicesData.mobileSelector) {
      const params = new URLSearchParams();
      var mData: any = {
        deviceType: "mobile",
        sharePosition: devicesData.mobileSelector,
        ...dataChanges.devices?.mobile,
      };

      Object.keys(mData).forEach((key) => {
        if (key !== "userId") params.append(key, mData[key]);
      });
      updateShareDevices(shop.userId, params).then((response: any) => {
        if (response.status === "success") {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.WIDGET_EDITED,
            eventData: {
              "Widget Name": "Share widget",
            },
          });
          setShowSuccessToast((successToast: any) => !successToast);
          setToast("Data Saved Successfully");
          setDataChanges({
            ...dataChanges,
            devices: { ...dataChanges.devices, mobile: null },
          });
          setIsDataSubmitted(true);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      });
    }
  };

  const deleteMobileData = () => {
    const params = new URLSearchParams();
    params.append("deviceType", "mobile");
    deleteShareDevices(shop.userId, params).then((response: any) => {
      if (response.status === "success") {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.WIDGET_EDITED,
          eventData: {
            "Widget Name": "Share widget",
          },
        });
        setDataChanges({
          ...dataChanges,
          devices: { ...dataChanges.devices, mobile: null },
        });
      }
    });
  };

  const updateDesktopData = () => {
    if (devicesData.desktopSelector) {
      const params = new URLSearchParams();
      var dData: any = {
        deviceType: "desktop",
        sharePosition: devicesData.desktopSelector,
        ...dataChanges.devices?.desktop,
      };

      Object.keys(dData).forEach((key) => {
        if (key !== "userId") params.append(key, dData[key]);
      });
      updateShareDevices(shop.userId, params).then((response: any) => {
        if (response.status === "success") {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.WIDGET_EDITED,
            eventData: {
              "Widget Name": "Share widget",
            },
          });
          setShowSuccessToast((successToast: any) => !successToast);
          setToast("Data Saved Successfully");
          setDataChanges({
            ...dataChanges,
            devices: { ...dataChanges.devices, desktop: null },
          });
          setIsDataSubmitted(true);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      });
    }
  };

  const deleteDesktopData = () => {
    const params = new URLSearchParams();
    params.append("deviceType", "desktop");
    deleteShareDevices(shop.userId, params).then((response: any) => {
      if (response.status === "success") {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.WIDGET_EDITED,
          eventData: {
            "Widget Name": "Share widget",
          },
        });
        setDataChanges({
          ...dataChanges,
          devices: { ...dataChanges.devices, desktop: null },
        });
      }
    });
  };

  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        content: "Save Settings",
        onAction: updateShare,
      }}
      discardAction={{
        onAction: () => {
          setHasChanges(false);
          setDiscardChanges(true);
        },
      }}
    />
  ) : null;

  const handleDeviceTypeChange = (type: number, showCTX: boolean) => {
    setSelectedDeviceType(type);
    setHasChanges(showCTX);
  };

  const handleErrorMessage = (message: string, key: string) => {
    let obj: any = errorMessage;
    obj[key] = message;
    setErrorMessage(obj);
  };

  return (
    <React.Fragment>
      {ctxSaveBarMarkup}
      <Layout.AnnotatedSection
        title="WhatsApp Share Widget"
        description="Enable or Disable the WhatsApp Share button on your store to let customers share your store with their friends."
      >
        <div className="pt-2">
          <SettingToggle
            action={{
              content: getToggleSwitchShareBtnContent(),
              onAction: enableWhatsAppShare,
            }}
            enabled={isWAShareEnabled}
          >
            WhatsApp Share Widget is currently{" "}
            <TextStyle variation={getToggleSwitchShareVariationStyle()}>
              {getToggleSwitchshareTxtContent()}
            </TextStyle>{" "}
            <Link url={"https://" + shop.url} external={true}>
              {" "}
              Click here to view it on your store
            </Link>
          </SettingToggle>
        </div>
      </Layout.AnnotatedSection>

      <ShareButtonTheme
        shareData={shareData}
        onChanges={shareButtonThemeChanges}
        setToast={setToast}
        setErrorToast={setErrorToast}
        setShowErrorToast={setShowErrorToast}
        setShowSuccessToast={setShowSuccessToast}
        isDataSubmitted={isDataSubmitted}
        reset={discardChanges}
        onError={handleErrorMessage}
        fetchShareData={fetchShareData}
      />

      <ShareDevices
        onChanges={devicesChanges}
        isDataSubmitted={isDataSubmitted}
        onTypeChange={handleDeviceTypeChange}
        reset={discardChanges}
        onError={handleErrorMessage}
        getDeviceData={getDeviceData}
      />
      <PagesToDisplay
        title="Pages to display"
        subTitle="Select the pages where you want to show the Whatsapp Share widget"
        footerText="Please note that by default we show the Whatsapp Share widget on all other pages that aren’t listed above."
        errorText="Atleast one page needs to be selected if Whatsapp Share widget is enabled"
        pages={shareData?.buttonPages}
        onFieldChange={sharePagesToDisplayChanges}
        isDataSubmitted={isDataSubmitted}
        isWAEnabled={isWAShareEnabled}
        reset={discardChanges}
        platform={shop.platform}
      />
      <AppEmbedDeepLink source="share" />
      <NoScriptModal
        showModal={showNoScriptModal}
        setShowModal={setShowNoScriptModal}
        wixSiteId={shop.wixSiteId}
      />
      <PlansModal
        title={PLANS_MODAL_TITLE.WIDGET_PAGE}
        currentPlanId={planId}
        shop={shop}
        showPricingPlansModal={showModal}
        setShowPricingPlansModal={setShowModal}
      />
    </React.Fragment>
  );
};
export default ShareSettings;
