import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useToken from "./useToken";
import { getOwnerDetails, getShopDetails } from '../redux/actions/shop';
import { getShopSettings } from '../redux/actions/shop';
import { getWalletRenewalInfo } from '../redux/actions/wallet';
import { getTeamInboxStatus } from '../redux/actions/teamInbox';
import {
  getWabaDetailsbyShopId,
  getWhatsappBusinessVerificationDetails,
  loadWabaDetails,
} from '../redux/actions/private-waba';
import { useShopStore } from "./shopStore";

const ProtectedRoute = ({ children }: any) => {
  const { token } = useToken();
  const history = useHistory();
  const {
    shopDetailsData,
    shopOwnerDetails,
    setStoreShopDetails,
    setShopSettings,
    setShopWABADetails,
    setShopPrivateWABADetails,
    setShopWABAVerificationDetails,
    setShopOwnerDetails,
    setAgentPhone,
    setPlanPaymentInfo,
    setWalletRenewalInfo,
    setShowSharedTeamInbox
  } = useShopStore();

  const fetchShopDetails = async () => {
    const notificationEnabled = JSON.parse(
      localStorage.getItem('notificationEnabled') || 'false'
    );
    localStorage.setItem(
      'notificationEnabled',
      JSON.stringify(notificationEnabled === 'false' ? 'false' : 'true')
    );
    await getShopDetails().then(async (response: any) => {
      if (response.status === 'success') {
        localStorage.setItem('shopId', response.shop?.id);
        localStorage.setItem('ownerId', response.shop?.userId);
        setStoreShopDetails(response.shop);
        setPlanPaymentInfo({
          downgraded: response.shop.downgraded,
          downgradedOn: response.shop.downgradedOn,  
        })
        
        await getShopSettingsCall(response);
        await loadWabaDetailsCall(response);
        await getWabaDetailsbyShopIdCall(response);
        await fetchOwnerDetails(response.shop.userId);
        if (response?.shop?.platform !== 'SHOPIFY') {
          await getRenewalStatus(response.shop.userId);
        }
        await getTeamInboxStatusCall(response);
      } else if (response.status === 'error'){
        history.push("/login");
      }
    });
  };

  const getShopSettingsCall = async (response: any) => {
    await getShopSettings(response.shop.userId).then((res: any) => {
      setShopSettings(res && res?.settings);
    });
  };

  const loadWabaDetailsCall = async (response: any) => {
    await loadWabaDetails(response.shop.userId).then((res: any) => {
      setShopWABADetails(res);
      if (res && res.appId && res.whatsappVerificationStatus) {
        localStorage.setItem('private_waba', 'true');
        localStorage.setItem(
          'whatsappVerificationStatus',
          res.whatsappVerificationStatus
        );
        setAgentPhone(res.phone);
      }
    });
  };

  const getWabaDetailsbyShopIdCall = async (response: any) => {
    await getWabaDetailsbyShopId(response.shop.userId).then(
      async (res: any) => {
        if (res && res.appId && res.whatsappVerificationStatus) {
          setShopPrivateWABADetails(res);
          localStorage.setItem('private_waba', 'true');
          localStorage.setItem(
            'whatsappVerificationStatus',
            res.whatsappVerificationStatus
          );
          setAgentPhone(res.wabaNumber);
          await getWhatsappBusinessVerificationDetailsCall(response, res);
        }
      }
    );
  };

  const getWhatsappBusinessVerificationDetailsCall = async (response: any, res: any) => {
    await getWhatsappBusinessVerificationDetails(
      response.shop.userId,
      res.id
    ).then((res: any) => {
      setShopWABAVerificationDetails(res);
      localStorage.setItem(
        'fbVerifiedStatus',
        res.message.business_verification_status === 'verified' ? 'true' : 'false'
      );
    });
  };

  const fetchOwnerDetails = async (userId: string) => {
    await getOwnerDetails(userId).then((response: any) => {
      if (response.status === 'success') {
        setShopOwnerDetails(response.owner);
      }
    });
  };

  const getTeamInboxStatusCall = async (response: any) => {
    await getTeamInboxStatus(response.shop.userId).then((res: any) => {
      if (res?.data?.status === 'ACCOUNT_LIVE') {
        setShowSharedTeamInbox(true);
      } else {
        setShowSharedTeamInbox(false);
      }
    });
  };

  const getRenewalStatus = async (userId: any) => {
    getWalletRenewalInfo(userId)
      .then((response: any) => {
        if (response.status === 'success') {
          setWalletRenewalInfo(response);
        }
      })
      .catch((err) => {
        console.log('Error in fetching wallet renewal status: ', err);
      });
  };

  useEffect(() => {
    if (token) {
      if(!shopOwnerDetails) fetchShopDetails();
    }
    //eslint-disable-next-line
  }, [token])

  if (!token) {
    history.push("/login");
  }

  if (token && shopDetailsData && shopOwnerDetails) {
    return children;    
  }
  
};

export default ProtectedRoute;
