import { useEffect, useState } from "react";
import { Layout, Link, Modal, Stack } from "@shopify/polaris";
import { objectDifference } from "../../../../utils/helper";
import { updateOrderCancellationTemplateSettings } from "../../../../redux/actions/new-transaction-templates";
import TemplateLanguageSettingMarkup from "../../../../common/TemplateLanguageSettingMarkup";
import {
  AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW,
  nonOptinMessage,
} from "../../../../constants/constants";
import {
  clevertapEventPush,
  constants,
  CT_EVENT_IDS,
} from "../../../../utils/clevertapUtils";
import NonOptinMessageSettings from "../../../../common/NonOptinMessage";
import { useShopStore } from '../../../../hooks/shopStore';

const SettingsModal = (
  {
    showToast,
    showErrorToast,
    open,
    closeModal,
    setTemplateLanguage,
    templateData,
    enabled,
  }: any,
  props: any
) => {
  const { shopDetailsData } = useShopStore();
  const [openModal, setOpenModal] = useState(open);
  const [initialOrderData, setInitialOrderData] = useState({
    language: "ENGLISH",
    nonOptinEnabled: true,
  });
  const [orderData, setOrderData] = useState({
    language: "ENGLISH",
    nonOptinEnabled: true,
  });
  const [refOrderData, setRefOrderData] = useState({
    language: "ENGLISH",
    nonOptinEnabled: true,
  });

  const [shop, setShop] = useState({
    id: "",
    url: "",
    userId: "",
  });
  const [dataChanges, setDataChanges] = useState<any>({
    template: null,
  });
  const isPrivateWABAPresent = JSON.parse(
    localStorage.getItem("private_waba") || "{}"
  );

  useEffect(() => {
    setInitialOrderData(templateData);
    setRefOrderData(templateData);
    setOrderData(templateData);
  }, [templateData]);

  useEffect(() => {
    let shop = shopDetailsData;
    if (shop && shop.id) {
      setShop(shop);
    }
    setOpenModal(open);
  }, [props, open]);

  useEffect(() => {
    var diffObj: any = objectDifference(refOrderData, orderData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        template: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        template: null,
      }));
    }
  }, [orderData, refOrderData]);

  const resetData = () => {
    setDataChanges({
      template: null,
    });
    setRefOrderData(initialOrderData);
    setOrderData(initialOrderData);
  };

  const description = (
    <span>
      <p>
        <b>
          {
            "Automated WhatsApp messages are sent from our official WhatsApp Business API approved "
          }
        </b>
        <Link external={true} url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}>
          {"phone number"}
        </Link>
        {" using message templates approved by WhatsApp."}
        <br />
        <br />
        We include your{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          business name
        </Link>{" "}
        and{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          WhatsApp support number
        </Link>{" "}
        in every automated message. Your customers can recognise you and contact
        you easily if they need help.
      </p>
    </span>
  );

  const handleLanguageChange = (newValue: any) => {
    return () => {
      handleTemplateValueChange("language", newValue);
    };
  };

  const handleTemplateValueChange = (field: string, value: any) => {
    setOrderData((data: any) => ({ ...data, [field]: value }));
  };

  const updateSettings = () => {
    if (dataChanges.template) {
      let params = new URLSearchParams();
      let data: any = dataChanges.template;

      Object.keys(data).forEach((key) => {
        params.append(key, data[key]);
      });

      updateOrderCancellationTemplateSettings(shop.userId, params).then(
        (response: any) => {
          if (response.status === "success") {
            showToast("Data Saved");
            if (Object.keys(data).includes("language")) {
              clevertapEventPush({
                eventId:
                  CT_EVENT_IDS.AUTOMATED_TEMPLATE_MESSAGE_LANGUAGE_CHANGED,
                eventData: {
                  "Automated template message name": constants.order_cancel,
                  Language: data.language,
                  "Private waba":
                    localStorage.getItem("private_waba") === "true"
                      ? true
                      : false,
                },
              });
            }
            setDataChanges({ ...dataChanges, template: null });
            setInitialOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            setRefOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            setOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            setTemplateLanguage(response.settings.language);
          } else {
            showErrorToast(response);
          }
        }
      );
    }
    closeModal();
  };

  const getTemplateMarkup = () => {
    return (
      <>
        <Layout.AnnotatedSection
          title="WhatsApp Message"
          description={description}
        >
          <Stack vertical>
            <TemplateLanguageSettingMarkup
              language={orderData.language}
              onChange={(val: any) => {
                handleTemplateValueChange("language", val);
              }}
            ></TemplateLanguageSettingMarkup>
          </Stack>
        </Layout.AnnotatedSection>
        {isPrivateWABAPresent && (
          <NonOptinMessageSettings
            nonOptinMessage={nonOptinMessage.ORDER_CANCELLATION}
            handleChange={handleTemplateValueChange}
            nonOptinMessageEnabled={orderData.nonOptinEnabled}
          />
        )}
      </>
    );
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        resetData();
        closeModal();
        setOpenModal(false);
      }}
      title={"Order Cancellation Settings"}
      primaryAction={{
        content: "Save",
        onAction: updateSettings,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            resetData();
            closeModal();
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>{getTemplateMarkup()}</Modal.Section>
    </Modal>
  );
};

export default SettingsModal;
