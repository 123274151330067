import { Fragment, useEffect, useState } from "react";
import {
  Card,
  Heading,
  Icon,
  Layout,
  Modal,
  Stack,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import {
  getAllTemplates,
  deleteTemplate,
} from "../../../../redux/actions/manual-messaging";
import Template from "../../../../types/Template.type";
import { EditMinor, DeleteMinor } from "@shopify/polaris-icons";
import EditTemplateModal from "./EditTemplateModal";
import styles from "../../manual-message.module.scss";

function ViewTemplatesModal({
  showToast,
  showErrorToast,
  open,
  setactivateViewTemplate,
  closeModal,
  shopId,
  userId,
}: any) {
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState<any>();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [confirm, setDeleteConfirm] = useState(false);
  const [activateEditModal, setactivateEditModal] = useState(false);
  const [template, setTemplate] = useState({});

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  useEffect(() => {
    fetchAllTemplates(userId);
  }, [userId]);

  const fetchAllTemplates = (userId: string) => {
    if (userId) {
      getAllTemplates(userId).then((res: any) => {
        if (res.status === "success" && res.templates) {
          setTemplates(res.templates);
        }
      });
    }
  };

  const openEditMode = (template: any) => {
    setTemplate(template);
    setactivateEditModal(true);
    setOpenModal(false);
  };

  const handleDeleteTemplate = (userId: string) => {
    if (selected?.id) {
      deleteTemplate(userId, selected?.id).then(
        (response: any) => {
          if (response.status === "success") {
            setDeleteConfirm(false);
            showToast("Template deleted successfully");
            fetchAllTemplates(userId);
          } else {
            showErrorToast(response);
          }
        },
        (err) => {
          showErrorToast("Unable to delete template");
        }
      );
    }
  };
  const renderTemplatesMarkup = () => {
    var markup = [];
    for (let i = 0; i < templates.length; i++) {
      if (templates[i]) {
        markup.push(
          <Fragment key={i}>
            <Layout.Section>
              <div
                className={`${styles.templatesContainer} template-info-container`}
              >
                <div className="Polaris-TextContainer Polaris-TextContainer--spacingTight overflow-wrap width-30">
                  <Heading>{templates[i].name}</Heading>
                  <TextStyle variation="subdued">
                    {templates[i].category}
                  </TextStyle>
                  <br></br>
                </div>
                <div className={`${styles.textContentCard} Polaris-Card w-60`}>
                  <div className="Polaris-Card__Section break-word w-100">
                    <TextContainer>
                      <TextStyle variation="subdued">
                        {templates[i].message}
                      </TextStyle>
                    </TextContainer>
                  </div>
                </div>
                <div
                  className={`${styles.buttonsOuterContainer} template Polaris-Layout__Section Polaris-Layout__Section--secondary m-auto text-center w-10`}
                >
                  <div
                    className={`${styles.templatesButtonContainer} flex-col w-100`}
                  >
                    <div>
                      <button
                        className={
                          "Polaris-Button Polaris-Button--outline Polaris-Button--primary w-50"
                        }
                        type="button"
                        onClick={() => openEditMode(templates[i])}
                      >
                        <span className="Polaris-Button__Content">
                          <span className="Polaris-Button__Text">
                            <div className="d-flex-center">
                              <div>
                                <Icon source={EditMinor} />
                              </div>
                              <div>&nbsp;Edit</div>
                            </div>
                          </span>
                        </span>
                      </button>
                    </div>
                    <div className="delete-button-container">
                      <button
                        className="Polaris-Button Polaris-Button--outline outline Polaris-Button--destructive mt-1-half w-50"
                        type="button"
                        onClick={() => {
                          setSelected(templates[i]);
                          setDeleteConfirm(true);
                        }}
                      >
                        <span className="Polaris-Button__Content">
                          <span className="Polaris-Button__Text">
                            <div className="d-flex-center">
                              <div>
                                <Icon source={DeleteMinor} />
                              </div>
                              <div>&nbsp;Delete</div>
                            </div>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Layout.Section>
          </Fragment>
        );
      }
    }
    return markup;
  };
  const getAbandonedCartSettingsMarkup = () => {
    return (
      <>
        <Stack vertical>
          {renderTemplatesMarkup()}
          {nudgeCheckoutOptinScrollSection}
        </Stack>
      </>
    );
  };

  const nudgeCheckoutOptinScrollSection = (
    <div id="nudgeCheckoutOptin_anchor">
      <Card></Card>
    </div>
  );

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          closeModal();
          setOpenModal(false);
        }}
        title={"Message Templates"}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              closeModal();
              setOpenModal(false);
            },
          },
        ]}
      >
        <Modal.Section>
          {templates.length > 0 ? (
            getAbandonedCartSettingsMarkup()
          ) : (
            <p>No templates found</p>
          )}
        </Modal.Section>
      </Modal>

      <Modal
        open={confirm}
        small
        onClose={() => setDeleteConfirm(false)}
        title={`Are you sure you want to delete "${
          selected?.name ? selected.name : ""
        }"?`}
        primaryAction={{
          content: "Confirm",
          onAction: () => handleDeleteTemplate(userId),
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>Please click Confirm to proceed</p>
          </TextContainer>
        </Modal.Section>
      </Modal>

      {activateEditModal ? (
        <EditTemplateModal
          open={activateEditModal}
          setactivateEditModal={setactivateEditModal}
          setactivateViewTemplate={setactivateViewTemplate}
          closeModal={() => {
            setactivateEditModal(false);
            setactivateViewTemplate(false);
          }}
          showToast={showToast}
          showErrorToast={showErrorToast}
          userId={userId}
          template={template}
          fetchAllTemplates={() => fetchAllTemplates(userId)}
        />
      ) : null}
    </>
  );
}

export default ViewTemplatesModal;
