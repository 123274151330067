import { Button, Card, Layout } from "@shopify/polaris";
import { APP_EMBED_DEEPLINK, PLATFORM } from "../constants/constants";
import { useShopStore } from '../hooks/shopStore';

const AppEmbedDeepLink = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const shopData = shopDetailsData;
  let deepLink = APP_EMBED_DEEPLINK.embed_url.replace(
    "<myshopifyDomain>",
    shopData.url
  );

  return shopData.platform != PLATFORM.WIX ? (
    <Layout.Section>
      <Card sectioned>
        <div className="embedLink">
          <span className="embedNote">{APP_EMBED_DEEPLINK.note}</span>
          <span className="embedContent">
            {APP_EMBED_DEEPLINK.content[0]} {props.source}{" "}
            {APP_EMBED_DEEPLINK.content[1]}
          </span>
          <span className="embedPreview">
            <a href={APP_EMBED_DEEPLINK.preview_link} target="_blank" rel="noreferrer">
              {APP_EMBED_DEEPLINK.preview_text}
            </a>
          </span>
        </div>
        <div className="embedButton">
          <Button primary url={deepLink} external>
            {APP_EMBED_DEEPLINK.embed_text}
          </Button>
        </div>
      </Card>
    </Layout.Section>
  ) : null;
};

export default AppEmbedDeepLink;
