// @ts-nocheck
import { useState, useCallback, useEffect } from 'react';
import {
  Page,
  Layout,
  Popover,
  ActionList,
  Button,
  Modal,
} from '@shopify/polaris';
import { TUTORIAL_VIDEOS } from '../../constants/constants';
import CampaignsTemplates from './CampaignsTemplates';
import './index.scss';
import { PlayCircleMajor } from '@shopify/polaris-icons';
import { getplanPaymentInfo } from '../../utils/helper';
import PaymentFailed from '../../common/PaymentFailed';
import TooltipOverlay from '../../common/Tooltip';
import obj from '../../content/CustomTemplate/OverlaysContent/en.json';
import GuideModal from '../../common/GuideModal';
import { useShopStore } from "../../hooks/shopStore";

const CommonTemplates = (props: any) => {
  const { planPaymentInfo } = useShopStore();
  const showPaymentFailedUI = getplanPaymentInfo(planPaymentInfo);
  const [currentSelectedTemplateId, setCurrentSelectedTemplateId] = useState(
    props?.location?.state || ''
  );
  const [popoverActive, setPopoverActive] = useState(false);
  const [tutorialVideos, setTutorialVideos] = useState([]);
  const [videoTutorial, setVideoTutorial] = useState({
    open: false,
    videoSrc: '',
  });

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );
  const activator = (
    <div className='videoTutsButton'>
      <Button icon={PlayCircleMajor} onClick={togglePopoverActive} disclosure>
        {TUTORIAL_VIDEOS.TITLE}
      </Button>
    </div>
  );

  useEffect(() => {
    let tmpData: any = [];
    TUTORIAL_VIDEOS.CAMPAIGNS_TEMPLATES.forEach((video: any) => {
      let tmpStep = {
        content: video.content,
        onAction: () => showVideoModal(video.videoSrc),
      };
      tmpData.push(tmpStep);
    });
    setTutorialVideos(tmpData);
  }, []);

  const showVideoModal = (videoLinks: string) => {
    setVideoTutorial({ open: true, videoSrc: videoLinks });
  };

  useEffect(() => {
    setCurrentSelectedTemplateId(props.location.state);
  }, [props.location.state]);

  const [openGuideModal, setOpenGuideModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  return (
    <>
      {showPaymentFailedUI.downgraded ? (
        <PaymentFailed failedOn={showPaymentFailedUI.downgradedOn} />
      ) : (
        <>
          <div className='videoTutsButton tooltip-overlay padRight5'>
            <Popover
              active={popoverActive}
              activator={activator}
              onClose={togglePopoverActive}
            >
              <ActionList actionRole='tutorialVideo' items={tutorialVideos} />
            </Popover>
          </div>
          <TooltipOverlay
            btn1Action={() => setOpenGuideModal(true)}
            obj={obj}
          />
          <Page title={'WhatsApp Templates'} divider>
            <Layout>
              <CampaignsTemplates
                privateWabaDetails={props.privateWabaDetails}
              ></CampaignsTemplates>
            </Layout>
            <Modal
              open={videoTutorial.open}
              sectioned={true}
              title={TUTORIAL_VIDEOS.TITLE}
              onClose={() => {
                setVideoTutorial({ open: false, videoSrc: '' });
              }}
            >
              <iframe
                id='ytplayer'
                width='100%'
                height='360'
                allow='fullscreen'
                src={videoTutorial.videoSrc}
                frameBorder='0'
                title={TUTORIAL_VIDEOS.TITLE}
              ></iframe>
            </Modal>

            {openGuideModal ? (
              <GuideModal
                open={true}
                closeModal={closeGuideModal}
                obj={obj}
                tabIndex={tabIndex}
              />
            ) : null}
          </Page>
        </>
      )}
    </>
  );
};
export default CommonTemplates;
