import { Badge, Link } from '@shopify/polaris';
import { capitalize } from '../../../../../utils/common';

const OrderCard = (props: any) => {
  const AbdMockUp = (props: any) => {
    const { orderId, name, orderDate, products, orderAmount, currency } =
      props?.orderDetails;
    return (
      <div className='font-size-13'>
        <br />
        <div className='flexSpaceBetween'>
          <div>
            <p className='color-grey'>Order Id:</p>
            <p>{orderId}</p>
          </div>
          <div>
            <p className='color-grey'>Name:</p>
            <p>{name}</p>
          </div>
        </div>
        <div className='hrGreyLineCss mt-2 mb-2' />
        <p className='color-grey'>Order Date:</p>
        <p>{orderDate}</p>
        <div className='hrGreyLineCss mt-2 mb-2' />
        <p className='color-grey'>Product Details:</p>
        {products.map((p: any) => (
          <p>- {p}</p>
        ))}
        <div className='hrGreyLineCss mt-2 mb-2' />
        <p className='color-grey'>Order Amount:</p>
        <p>{`${currency} ${orderAmount}`}</p>
        <div className='mb-2' />
      </div>
    );
  };

  const getMessageStatus = (
    status: string,
    fulfillmentStatus: string,
    paymentStatus: string,
    paymentDone = false
  ) => {
    let statusBadge;
    let fulfillmentBadge;
    let paymentStatusBadge;
    status = capitalize(status?.replace(/_/g, ' '));
    fulfillmentStatus = capitalize(fulfillmentStatus?.replace(/_/g, ' '));

    if (status === 'Closed') {
      statusBadge = <Badge status='success'>{status}</Badge>;
    } else {
      statusBadge = <Badge>{status}</Badge>;
    }

    fulfillmentBadge = <Badge status='attention'>{fulfillmentStatus}</Badge>;
    // if (fulfillmentStatus === "Fulfilled") {
    // } else {
    //   fulfillmentBadge = <Badge status="attention">{fulfillmentStatus}</Badge>;
    // }

    if (paymentDone) {
      paymentStatus = capitalize(paymentStatus?.replace(/_/g, ' '));
      if (paymentStatus === 'Paid') {
        paymentStatusBadge = <Badge status='success'>{paymentStatus}</Badge>;
      } else {
        paymentStatusBadge = <Badge>{paymentStatus}</Badge>;
      }
    }

    return (
      <div className='mt-1 d-flex-start gap-5'>
        {statusBadge}
        {fulfillmentBadge}
        {paymentStatusBadge}
      </div>
    );
  };

  const OpenOrderMockup = (props: any) => {
    const {
      orderId,
      name,
      orderDate,
      trackingUrl,
      trackingNumber,
      address,
      status,
      fulfillmentStatus,
      paymentStatus,
    } = props?.orderDetails;
    return (
      <div className='font-size-13'>
        <br />
        <div className='flexSpaceBetween'>
          <div>
            <p className='color-grey'>Order Id:</p>
            <p>{orderId}</p>
          </div>
          <div>
            <p className='color-grey'>Name:</p>
            <p>{name}</p>
          </div>
        </div>
        {getMessageStatus(
          status,
          fulfillmentStatus,
          paymentStatus,
          paymentStatus ? true : false
        )}
        <div className='hrGreyLineCss mt-2 mb-2' />
        <p className='color-grey'>Order Date:</p>
        <p>{orderDate}</p>
        <div className='hrGreyLineCss mt-2 mb-2' />
        <p className='color-grey'>Tracking Url:</p>
        {trackingUrl === '-NA-' ? (
          <p>{trackingUrl}</p>
        ) : (
          <Link url={trackingUrl} external>
            <span
              className='color-grey'
              style={{ textDecoration: 'underline', color: 'blue' }}
            >
              Track order
            </span>
          </Link>
        )}
        <div className='mt-1 mb-2' />
        <p className='color-grey'>Tracking No:</p>
        <p>{trackingNumber}</p>
        <div className='hrGreyLineCss mt-2 mb-2' />
        <p className='color-grey'>Address</p>
        <p className='ellipsis-hidden-nowrap'>{address}</p>
        <div className='mb-2' />
      </div>
    );
  };

  return (
    <div>
      {props.orderType === 'AbandonedOrders' ? (
        <AbdMockUp orderDetails={props.orderDetails} />
      ) : (
        <OpenOrderMockup orderDetails={props.orderDetails} />
      )}
    </div>
  );
};

export default OrderCard;
