// @ts-nocheck
import { useEffect, useState } from "react";
import { Card, Icon } from "@shopify/polaris";
import { NotificationMajor } from "@shopify/polaris-icons";

import SearchBar from "./SearchBar";
import ChatTicketStatus from "./ChatTicketStatus";
import ChatList from "./ChatList";
import "../chat.scss";
import { useUsersContext } from "../../../../context/usersContext";
import { saveChatRoomInfo } from "../../../../redux/actions/chats";
import useDebounce from "../../../../hooks/useDebounse";
import AvatarInitial from "../../../../common/AvatarInitials";
import { useShopStore } from '../../../../hooks/shopStore';

const ChatNavigator = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const {
    usersChatListItems,
    setCurrentUser,
    setSearchParams,
    searchParams,
    agentPhone,
    toggleNotification,
    isNotificationEnabled,
    viewChatPhone,
    mobileView,
    setTicketStatus,
    setViewChatPhone,
  } = useUsersContext();

  const shop = shopDetailsData;
  // Queue
  const [queryValue, setQueryValue] = useState("");
  const [value, setValue] = useState("");
  const [selectedTag, setSelectedTag] = useState("");

  const debouncedRequest = useDebounce(() => {
    if (value) setQueryValue(value);
    else handleQueryValueRemove();
  });

  useEffect(() => {
    if (viewChatPhone) {
      setTicketStatus("ALL");
      setTimeout(() => {
        setViewChatPhone("");
        handleFiltersQueryChange(viewChatPhone);
        if (!mobileView.status) {
          setTimeout(() => {
            handleOpenChatUser(viewChatPhone);
          }, 1000);
        }
      }, 500);
    }
  }, [viewChatPhone]);

  const handleFiltersQueryChange = (value: string) => {
    if (value) {
      setValue(value);
    } else {
      handleQueryValueRemove();
    }
    debouncedRequest();
  };

  const handleQueryValueRemove = () => {
    removeFilter("customerPhone");
    removeFilter("customerName");
    setValue("");
  };

  const deSelectUser = () => {
    setCurrentUser({});
  };

  const handleOpenChatUser = async (customerPhone: string) => {
    let selectedUser = usersChatListItems.filter((usrList: any) => {
      return usrList.customerPhone === customerPhone;
    });

    if (selectedUser[0]?.customerPhone) {
      const data = {
        agentPhone: agentPhone,
        customerPhone: selectedUser[0]?.customerPhone,
        tags: selectedUser[0]?.tags ? selectedUser[0].tags.join(",") : "",
        ticketStatus: selectedUser[0]?.ticketStatus,
        notes: selectedUser[0]?.notes,
        chatRead: true,
      };
      const res = await saveChatRoomInfo(data);
      setCurrentUser(res);
      props.setUserChatSelected(false);
      props.setSelectedTab(1);
    } else {
      deSelectUser();
    }
  };

  useEffect(() => {
    if (queryValue) {
      if (queryValue.match(/^[0-9]+$/) != null) {
        setSearchParams({
          ...searchParams,
          customerPhone: queryValue,
        });
      } else {
        setSearchParams({
          ...searchParams,
          customerName: queryValue,
        });
      }
    } else {
      if (selectedTag) {
        setSearchParams({
          tags: selectedTag,
        });
      } else {
        setSearchParams({});
      }
    }
    deSelectUser();
  }, [queryValue]);

  useEffect(() => {
    if (selectedTag) {
      setSearchParams({
        ...searchParams,
        tags: selectedTag,
      });
    } else {
      setSearchParams(searchParams);
    }
    deSelectUser();
  }, [selectedTag]);

  const removeFilter = (param: any) => {
    const newParams = {};
    Object.keys(searchParams).forEach((sp) => {
      if (sp !== param) {
        newParams[sp] = searchParams[sp];
      }
    });

    if (["customerPhone", "customerName"].includes(param)) {
      setQueryValue("");
    } else {
      setSelectedTag("");
    }
    setSearchParams(newParams);
    deSelectUser();
  };

  return (
    <div className="storeDetailsContainer">
      <Card sectioned>
        <div className="storeDetails">
          <div className="storeDetailsLeft">
            <AvatarInitial name={shop?.name} avatarSize={"large"} />
            <span className="storeName">{shop?.name}</span>
          </div>
          <div
            className="storeDetailsRight"
            onClick={() => {
              toggleNotification();
            }}
          >
            <div style={{ scale: "1.4", position: "relative", right: "100%" }}>
              <Icon
                source={NotificationMajor}
                color={isNotificationEnabled === "true" ? "success" : "base"}
              />
            </div>
          </div>
        </div>
      </Card>
      <SearchBar
        handleFiltersQueryChange={handleFiltersQueryChange}
        handleQueryValueRemove={handleQueryValueRemove}
        queryValue={value}
        setSelectedTag={setSelectedTag}
        removeFilter={removeFilter}
      />

      <ChatTicketStatus showChatWindow={props.showChatWindow} />
      {props.showChatWindow ? (
        <ChatList
          handleOpenChatUser={handleOpenChatUser}
          getRemainingTime={props.getRemainingTime}
        />
      ) : (
        <p className="chat__NoChatsFound">No Chats found</p>
      )}
    </div>
  );
};

export default ChatNavigator;
