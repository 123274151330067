import SpinnerLoader from "../assets/icons/spinner-loader.svg";
import "../assets/css/loader.scss";
import { LOADING_SPINNER } from "../constants/constants";

const LoadingUI = ({ note }: any) => {
  return (
    <div className="overlay">
      <div className="loader-spinner">
        <h1 className="loading-text">{LOADING_SPINNER.TITLE}</h1>
        <img
          alt="Loading Icon"
          className="spinsvg w3-spin"
          src={SpinnerLoader}
          title="SpinnerLoader"
          width="100"
        ></img>
        <span className="loading-note">{note}</span>
      </div>
    </div>
  );
};

export default LoadingUI;
