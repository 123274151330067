// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import { useUsersContext } from '../../../../context/usersContext';
import AvatarInitial from '../../../../common/AvatarInitials';
import formatTime from '../../../../utils/formatTime';
import { ReactComponent as UserSendMessage_allowIcon } from '../../../../assets/icons/userSendMessage_allowIcon.svg';
import { ReactComponent as UserSendMessage_blockIcon } from '../../../../assets/icons/userSendMessage_blockIcon.svg';

const ChatElement = (props: any) => {
  const { currentUser } = useUsersContext();
  const tagGroupRef = useRef();
  const user = props.data;
  const tags = user?.tags ? user.tags : [];
  const [showThreeDots, setShowThreeDots] = useState(false);
  const [showAllTags, toggleTags] = useState(false);

  useEffect(() => {
    setShowThreeDots(isOverflowActive(tagGroupRef?.current) ? true : false);
  }, []);

  function isOverflowActive(event: any) {
    return event.offsetWidth < event.scrollWidth;
  }

  return (
    <div
      className={`chatElementBox__outer ${
        currentUser.customerPhone === user.customerPhone ? 'chatSelected' : ''
      } ${showAllTags ? 'heightAuto' : ''}`}
      key={props.index}
    >
      <div
        className={`chatElementBox__inner ${showAllTags ? 'heightAuto' : ''}`}
      >
        <div
          className='chatElementDivider cp'
          onClick={() => props.handleOpenChatUser(user.customerPhone)}
        >
          <div>
            <AvatarInitial name={user?.customerName} avatarSize={'small'} />
          </div>
          <div className='userListLeft'>
            <div className='chat__chatList__name'>
              {user.customerName ? user.customerName : user.customerPhone}
            </div>
            <div
              className={
                !user?.chatRead && user.ticketStatus === 'OPEN'
                  ? 'chat__chatList__lastMessage'
                  : 'chat__chatList__lastMessage--read'
              }
            >
              {user.lastMessage ? user.lastMessage.replace(/\n/g, '') : ''}
            </div>
          </div>
          <div className='userListRight'>
            <div
              className={
                !user?.chatRead && user.ticketStatus === 'OPEN'
                  ? 'chat__chatList__timestamp greencolor'
                  : 'chat__chatList__timestamp'
              }
            >
              {formatTime(user?.lastMessageTimestamp, true)}
            </div>

            {props.getRemainingTime(true, user?.timestamp) ? (
              <UserSendMessage_allowIcon />
            ) : (
              <UserSendMessage_blockIcon />
            )}
            {/*
          <div className='dot'>
            <span className='boldText'>{user.count}</span>
          </div>*/}
          </div>
        </div>

        <div className='chatElementDivider pt5'>
          {showAllTags ? (
            <div className='tagGroupCss chat__showAllTags'>
              {tags.map((tag: any, tag_index: any) => {
                return (
                  <span className='tagElementCss' key={tag_index}>
                    {tag}
                  </span>
                );
              })}
            </div>
          ) : (
            <div ref={tagGroupRef} className='tagGroupCss'>
              {tags.map((tag: any, tag_index: any) => {
                return (
                  <span className='tagElementCss' key={tag_index}>
                    {tag}
                  </span>
                );
              })}
            </div>
          )}

          {showThreeDots && (
            <div
              className='trippleDot'
              onClick={() => {
                toggleTags(!showAllTags);
              }}
              title={
                showAllTags ? 'Click to compress tags' : 'Click to expand tags'
              }
            >
              <span className='tagElementLastCss'>....</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatElement;
