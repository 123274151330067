import * as React from "react";

const Approved = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={27}
    height={27}
    viewBox="0 0 27 27"
    fill="none"
    {...props}
  >
    <rect width={27} height={27} fill="url(#pattern0)" />
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use xlinkHref="#image0_29_896" transform="scale(0.02)" />
      </pattern>
      <image
        id="image0_29_896"
        width={50}
        height={50}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAEw0lEQVRoge2a228UdRTHP2c6s+1S6S24RKOJpggkEqmEYCIgPAiJNC1tIxWxBHhFXtQQ/gVj0Bej8cHES7koTdgSCiuJD6JBSAWhamJpE9OEBCi3ABKlOzt7fGCRZeeys7OT6kO/j7/r+ew5c+b8frMwoxnNKEgS52LdB7tTWVhlQJvCfIGUitYXuu+gclVgTI38WSebOJ7pHbga195Vg7QPtTdjW30Cm4GlFaypAsOq0m+K7h3sHrxZjR2RQdqH2puNbGKXim4HZldjBHAb+MiE96ICRQLpSHdtVtgNpKLMD9AkKu8M9aT3VjqxIpDOQ52z83njE2BTpRtVqP6kbW0f6B24E3ZCaJDug90pWzQDLIlkWuU6Y6msS/ekr4QZHAqkAPE9sKAq0yrXece2VobJbka5AZ2HOmcXPDHdEAALDMs+uvbY2vpyA8uCaN74mOkLJ5cElib+Tn5ablwgSCE79cVnVkSpbOwYXB+YYHyfkfah9maxrVHiT7FRNWnCQr/3jK9HjGxiF9MMMS/VwqInfLec66js9Ov09EjBGxNAQwz2hVJrqoXXX1hEjSEM/jzKyIVJ1xiBWzXwlJdXvD1iW338BxBmjYGI0LVkIYufnOsap9DoiHo+K54ghQJwWjSvCOLf/UV4vMn7d1Qf21yhVXj5Xfbqi1utHhAApycucuTcOIp6TVMxndThjsPXihtdHsmJrub/CwEg6hirSxvdoaWyOA5Dg1QFBACi8lxpmwtEYX61hgapWgiAvEe55PaIqDtd+Ki+1qJpVl3Y4Z4PNsDwH+EhAMTj/eaVtR4Js1h9rcWW5W1sW9lGc315mNZUCxt9PJH5JTxEQa6UVrZo9NJ9iFRDPY3JOrauCIaJI5zKyQuk7KlszbOtpBoeVNaNyTq2LG/zDLNn5sYTTiW6XdrglbXctUGJjo6MM3H94SqhaVadK8xaUy28tswNcWbiUpRwemAiuE6NLhCBsXILZR2HfT/+6oIpDjO/cDozcYmhc2NVhZMB5z3aHlYezoVZLOs47D/5GxduPOzlxmQd21Y87xtO1UIUNFLa4AbJmd9BuJ2mcjn6T4y4PNOQrI09nIpNxHSOlza6QDK9A1cFhsOu6hdmxYojnIp0qrTOAp/0q7CnkpWDYGKGAJV+r2ZPEPMeiCvFBckLJnYIuHk3Z+736qjxahz9evTu/I0LG4CVleziqPL7xWs8PaeJ8ckbcUMA7P7m1YMZrw7T16jaqXfNqdqtCo9VstNULscXJ0bIOfl4IUQvmyrv+3X7liiZdZnbGnDYD5LtOHF7As0bbwXd1AfWWkM96b2ofBmrRVGk8tmRnvRXQUPKFo3JnPmmwun4rKpYw2LldpQbFOpI+8qBDY/WWPYPTPf9r+iok028FMslNtx7Sdq29SJwsmrjwusnK2+sCvudMfR55FjvwI3srL/WAJ4vpDil8LmYzuqw30Yg6qe3wfWbVOUDIPSxOKQxl/Iqb5d7sH3mRlNXuqvJUdmpojuo8lZS4JbCh3dta/e3vQO3Iq5RnV4+sKExmci+obAZlWWED9c8cEphz5Rt7YsKcF+xXsR1HO6YQ65mlRb9YYAHn67/VLgiMKYqZxNwPN2Tvh7n/jOa0Yz89Q8zdP1pPObXZgAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);

export default Approved;
