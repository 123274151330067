//@ts-nocheck
import { useEffect, useState } from 'react';
import { sendHSMTemplate } from '../../redux/actions/chats';
import { useUsersContext } from '../../context/usersContext';
import DragAndDropTemplate from './DragAndDropTemplate';
import { uploadCampaignMedia } from '../../redux/actions/campaigns';
import GenericImageToast from '../GenericImageToast';
import { populateTemplate } from './PopulateTemplate';
import { useShopStore } from '../../hooks/shopStore';

const TemplateSelectedMockup = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const { currentUser, setCurrentUser } = useUsersContext();
  const [variableObj, setVariableObj]: any = useState({});
  const [paramsArray, setParamsArray] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [linkVariableArray, setLinkVariableArray]: any = useState([]);

  const regxBody = /{{(.*?)}}/g;

  const onChangeInput = (e: any) => {
    if (e.target.value.length <= 60) {
      if (linkVariableArray.includes(e.target.id)) {
        if (/\s/.test(e.target.value)) {
          props.showErrorToast('Input variable should not contain any spaces.');
          return;
        }
      } else {
        if (
          e.target.value.length == 4 &&
          e.target.value.slice(0, 4).trim() === ''
        ) {
          props.showErrorToast(
            'Input variable should not have more than 4 consecutive spaces.'
          );
          return;
        }
      }
      if (e.target.value.includes('\\n') || e.target.value.includes('\\t')) {
        props.showErrorToast('Input variable should not have \\n or \\t.');
        return;
      }
      setVariableObj({
        ...variableObj,
        [e.target.id]: {
          ...variableObj[e.target.id],
          value: e.target.value,
        },
      });
    } else {
      props.showErrorToast(
        'You can only add 60 characters in the variable box'
      );
    }
  };

  useEffect(() => {
    const obj: any = {};
    let param: any = [];
    let linkArray: any = [];
    props.template?.body.split(' ').forEach((word: any) => {
      let curMatch = regxBody.exec(word);
      if (curMatch && curMatch[1]) {
        obj[curMatch[1]] = {
          value: '',
          required: true,
        };
        param.push(curMatch[1]);
      }
    });
    if (props.template?.buttons && props.template?.buttons.length) {
      props.template.buttons[0].value.split('/').forEach((word: any) => {
        let curMatch = regxBody.exec(word);
        if (curMatch && curMatch[1]) {
          linkArray.push(curMatch[1]);
          obj[curMatch[1]] = {
            value: '',
            required: true,
          };
          param.push(curMatch[1]);
        }
      });
    }
    setParamsArray(param);
    setLinkVariableArray(linkArray);
    populateTemplate(
      shopDetailsData,
      props.userId,
      obj,
      props.currentSelectedOrder,
      props.template
    ).then((populatedObj) => {
      setVariableObj(populatedObj);
    });
  }, []);

  const sendTemplate = async () => {
    let error = false;
    let mediaUrl = '';
    let params = paramsArray.map((pA) => {
      if (variableObj[pA].required && variableObj[pA]?.value?.trim() === '') {
        error = true;
      }
      return variableObj[pA].value != '' ? variableObj[pA].value : ' ';
    });

    if (error) {
      props.showErrorToast('Please add variable input');
      return;
    }

    if (props.template.type != 'TEXT' && !selectedFile) {
      props.showErrorToast('Please upload media file.');
      return;
    }

    if (selectedFile) {
      let formdata = new FormData();
      formdata.append('file', selectedFile);
      formdata.append('fileType', selectedFile.type);
      const response = await uploadCampaignMedia(props.userId, formdata);
      if (response?.message) {
        mediaUrl = response?.message;
      } else {
        props.showErrorToast('Error in uploading media file.');
        return;
      }
    }

    if (props.from === 'CRM') {
      setTimeout(() => {
        props.loadMessageLogs();
      }, 10000);
    }

    let body = {
      templateId: props.template.templateId,
      internalText: props.template.internalText,
      to:
        props.from === 'CRM' ? props.customerPhone : currentUser.customerPhone,
      params: params,
      media: mediaUrl,
      type: props.template.type,
      entityId: props.currentSelectedOrder?.entityId,
    };

    await sendHSMTemplate(props.agentPhone, props.userId, body);
    let data = {
      ...currentUser,
      toggleChanged: !currentUser.toggleChanged,
    };
    setCurrentUser(data);
    props.closeModal();
    props.setShowSuccess(true);
  };

  function createMarkup(body: any, required: boolean) {
    let value = required ? '/' : ' ';
    let formattedTemplate = body.split(value).map((word: any) => {
      let curMatch = regxBody.exec(word);
      if (curMatch && curMatch[1]) {
        word = curMatch[1];
        return (
          <input
            key={word}
            id={word}
            value={variableObj[word]?.value}
            autoComplete='false'
            placeholder={word}
            onChange={(e) => onChangeInput(e)}
            className='chat__template_input'
            required
          />
        );
      } else {
        return `${word}${value}`;
      }
    });

    return formattedTemplate;
  }

  return (
    <div className='template'>
      <div className='chat__selectedTemplate__container'>
        {props.template.type != 'TEXT' && (
          <div>
            <p style={{ fontWeight: '600' }}>Media</p>
            <div className='FilesDragAndDrop__templateChat'>
              <DragAndDropTemplate
                setSelectedFile={setSelectedFile}
                templateType={props.template.type}
                showErrorToast={props.showErrorToast}
              />
            </div>
            <br />
          </div>
        )}
        <div>
          <p style={{ fontWeight: '600' }}>Message</p>
          {createMarkup(props.template.body, false)}
          <br></br>
          {props.template?.buttons?.length
            ? createMarkup(props.template?.buttons[0].value, true)
            : ''}
        </div>

        <div
          className='chat__selectedTemplateButton'
          onClick={() => sendTemplate()}
        >
          <button>
            <p
              style={{ color: 'white', fontSize: '1.4rem', fontWeight: '500' }}
            >
              Send Template
            </p>
          </button>
        </div>
      </div>
      <GenericImageToast
        showSuccess={props.showSuccess}
        setShowSuccess={props.setShowSuccess}
        value='Message triggered successfully'
      />
    </div>
  );
};

export default TemplateSelectedMockup;
