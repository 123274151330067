import { Button, Card, Layout, Page } from "@shopify/polaris";
import whatsappScreen from "../../../assets/images/whatsappScreen.svg";
import tieIcon from "../../../assets/icons/tie.svg";
import chatWithUs from "../../../assets/images/chatWithUs.svg";
import "./planChange.scss";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  PLAN_ENTERPRISE,
  PLAN_NEW_FREE,
  PLAN_OLD_FREE,
  PLAN_OLD_PRO,
  PLAN_OLD_ULTIMATE,
  PLAN_SMALL_BUSINESS,
} from "../../../constants/constants";
import { updatePlan } from "../../../redux/actions/pricing";
import { sendCleverTapEvent_planUpdate } from "../../../common/helperFunctions";
import { useShopStore } from '../../../hooks/shopStore';

const PlanChangeComponent = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [currentPlanId, setCurrentPlanId] = useState("2");
  const [shop, setShop]: any = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [confirmation, setConfirmation] = useState({
    show: false,
    plan: 0,
  });
  useEffect(() => {
    let planId = shopDetailsData?.planId;
    let shopDetails = shopDetailsData;
    if (shopDetails) {
      setShop(shopDetails);
    }
    if (planId) setCurrentPlanId(planId);
  }, []);
  const history = useHistory();
  const redirectToPlansPage = () => {
    history.push("/pricing");
  };

  const activatePlan = (planId: number) => {
    let shop = shopDetailsData;
    let params = {
      from: 'pricing',
      returnUrl: window.location.origin + '/confirmation',
    };
    setIsLoading(true);
    updatePlan(shop.userId, planId.toString(), params).then(
      async (res: any) => {
        if (res.status === "success" || res === 202) {
          sendCleverTapEvent_planUpdate(planId, shop);
          if (res.charge && res.charge.confirmationUrl) {
            window.open(res.charge.confirmationUrl, "_self");
          } else {
            setToast({ show: true, message: "Plan Activated Successfully" });
          }
          if (Number(planId) === PLAN_OLD_FREE) {
            localStorage.setItem("currentPlan", planId.toString());
            setCurrentPlanId(planId.toString());
            props.setPlanId(planId.toString());
          }
        } else {
          setErrorToast({ show: true, message: res });
        }
        setIsLoading(false);
        setConfirmation({ show: false, plan: 0 });
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };

  const handleUpdatePlan = (planId: any) => {
    let currentPlanId = shopDetailsData?.planId;
    if (Number(currentPlanId) === planId) return null;
    //setConfirmation({ show: true, plan: planId }); // to open popup
    activatePlan(planId);
  };

  return (
    <div>
      {(Number(currentPlanId) === PLAN_OLD_FREE ||        
        Number(currentPlanId) === PLAN_NEW_FREE) && (
        <>
          <div className="business-campaign-window">
            <Layout>
              <Layout.Section>
                <p className="title">
                  Get WhatsApp business account with your business name, run
                  custom campaigns and more.
                </p>
                <p className="planText">
                  <span className="planText-bold">Engage Plan </span>
                  <span>@</span>
                  <span className="planText-bold"> $15 </span>
                  <span>/Month</span>
                  <span className='planText-bold'>
                    <a
                      href='https://www.superlemon.xyz/help-center/pricing-chart/'
                      rel='noreferrer'
                      target='_blank'
                    >
                      + Usage-based charge
                    </a>
                  </span>
                </p>
                <p className="planSubTitle">
                  Get WhatsApp Business Account under your own business name,
                  run marketing campaigns, create custom templates and send
                  automated & manual abandoned cart recovery, order, and
                  delivery notifications
                </p>
                <div>
                  <div style={{ display: "flex" }}>
                    <div className="customButton mr10">
                      {" "}
                      <Button
                        onClick={() => handleUpdatePlan(PLAN_SMALL_BUSINESS)}
                      >
                        Get started
                      </Button>
                    </div>
                    <div className="customButtonGray">
                      <Button onClick={redirectToPlansPage}>Know more</Button>
                    </div>
                  </div>
                </div>
                <p className='footerText'>
                  You will be taken to E-commerce Subscription page, where you
                  need to give your approval. Once approved, you can get
                  started.
                </p>
              </Layout.Section>
              <Layout.Section secondary>
                <div className="whatsappScreen">
                  <img src={whatsappScreen} alt="Whatsapp_Screen" />
                </div>
              </Layout.Section>
            </Layout>
          </div>
          {/* <div className="business-campaign-window">
            <Layout>
              <Layout.Section>
                <div className="tieIconClass">
                  <img src={tieIcon} alt="tie_icon" />
                </div>
                <p className='title'>Get Omnichannel WhatsApp Inbox</p>
                <p className='planText'>
                  <span className='planText-bold'>Engage Plan </span>
                  <span>@</span>
                  <span className="planText-bold"> $25 </span>
                  <span>/Month</span>
                  <span className='planText-bold'>
                    <a
                      href='https://www.superlemon.xyz/help-center/pricing-chart/'
                      rel='noreferrer'
                      target='_blank'
                    >
                      + Usage-based charge
                    </a>
                  </span>
                </p>
                <p className='planSubTitle'>
                  Empower your customer support team with Superlemon WhatsApp
                  Inbox. Tag, prioritize, assign & reply to chats from multiple
                  channels. Setup quick replies & auto-replies to boost
                  efficiency.
                </p>
                <br />
                <div>
                  <div style={{ display: "flex" }}>
                    <div className="customButton mr10">
                      {" "}
                      <Button onClick={() => handleUpdatePlan(PLAN_ENTERPRISE)}>
                        Get started
                      </Button>
                    </div>
                    <div className="customButtonGray">
                      <Button onClick={redirectToPlansPage}>Know more</Button>
                    </div>
                  </div>
                </div>
                <p className='footerText'>
                  You will be taken to E-commerce Subscription page, where you
                  need to give your approval. Once approved, you can get
                  started.
                </p>
              </Layout.Section>
            </Layout>
          </div> */}
        </>
      )}

      {Number(currentPlanId) === PLAN_OLD_FREE &&
        shop &&
        Number(shop.createdOn) >
          Number(process.env.REACT_APP_PLAN_NEW_USER_START_DATE) && (
          <div className="business-campaign-window">
            <Layout>
              <Layout.Section>
                <p className="title">
                  Send automated notifications and provide instant customer
                  support with WhatsApp Chat button.
                </p>
                <p className="planSubTitle">
                  Empower your customers to reach you instantly on their
                  preferred messaging channel.
                </p>
                <div className="customButton m">
                  <Button onClick={() => handleUpdatePlan(PLAN_NEW_FREE)}>
                    Get started for free
                  </Button>
                </div>
                <p className='footerText'>
                  You will be taken to E-commerce Subscription page, where you
                  need to give your approval. Once approved, you can get
                  started.
                </p>
              </Layout.Section>
              <Layout.Section secondary>
                <div className="whatsappScreen">
                  <img src={chatWithUs} alt="chat_with_us" />
                </div>
              </Layout.Section>
            </Layout>
          </div>
        )}
      {/* {Number(currentPlanId) === PLAN_SMALL_BUSINESS && (
        <div className="business-campaign-window">
          <Layout>
            <Layout.Section>
              <div className="tieIconClass">
                <img src={tieIcon} alt="tie_icon" />
              </div>
              <p className='title'>Get Omnichannel WhatsApp Inbox</p>
              <p className='planText'>
                <span className='planText-bold'>Engage Plan </span>
                <span>@</span>
                <span className="planText-bold"> $25 </span>
                <span>/Month</span>
                <span className='planText-bold'>
                  <a
                    href='https://www.superlemon.xyz/help-center/pricing-chart/'
                    rel='noreferrer'
                    target='_blank'
                  >
                    + Usage-based charge
                  </a>
                </span>
              </p>
              <p className='planSubTitle'>
                Empower your customer support team with Superlemon WhatsApp
                Inbox. Tag, prioritize, assign & reply to chats from multiple
                channels. Setup quick replies & auto-replies to boost
                efficiency.
              </p>
              <br />
              <div>
                <div style={{ display: "flex" }}>
                  <div className="customButton mr10">
                    {" "}
                    <Button onClick={() => handleUpdatePlan(PLAN_ENTERPRISE)}>
                      Get started
                    </Button>
                  </div>
                  <div className="customButtonGray">
                    <Button onClick={redirectToPlansPage}>Know more</Button>
                  </div>
                </div>
              </div>
              <p className='footerText'>
                You will be taken to E-commerce Subscription page, where you
                need to give your approval. Once approved, you can get started.
              </p>
            </Layout.Section>
          </Layout>
        </div>
      )} */}
      <br />
    </div>
  );
};

export default PlanChangeComponent;
