import { Modal } from "@shopify/polaris";
import "./planModal.scss";
import { ENGAGE_WIX_PLAN_INFO } from "../../constants/Pricing/Plans/constants";
import Checkmark from "../../assets/icons/checkmark.svg";
import { useHistory } from "react-router-dom";

const PlanModal = (props: any) => {
  const history = useHistory();
  const currentPlanInfo = props.currentPlanInfo ? props.currentPlanInfo : ENGAGE_WIX_PLAN_INFO;

  const PlanModalHeading = () => {
    return (
      <div className="planModalWix_headingComponent">
        <div className="planModalWix_leftC">
          <div className="planModalWix_heading">
            {currentPlanInfo.planName}
          </div>
          <div className="planModalWix_subHeading">
            {currentPlanInfo.catch_line}
          </div>
        </div>
        <div className="planModalWix_rightC">
          <div className="planModalWix_price">
            {currentPlanInfo.currency}
            {currentPlanInfo.price}
          </div>
          <div className="planModalWix_validity">
            {currentPlanInfo.sub_interval}
          </div>
        </div>
      </div>
    );
  };

  const PlanModalFeatures = () => {
    return (
      <div className="planModalWix_features">
        <div className="planModalWix_featuresHeading">Features</div>
        <div className="planModalWix_featuresColumns">
          <div>
            {currentPlanInfo.features1.map((value: any, index: any) => {
              return (
                <div key={index} className="planModalWix_ListItem">
                  <img src={Checkmark} height={20} width={20} />
                  {value}
                </div>
              );
            })}
          </div>
          <div>
            {currentPlanInfo.features2.map((value: any, index: any) => {
              return (
                <div key={index} className="planModalWix_ListItem">
                  <img src={Checkmark} height={20} width={20} />
                  {value}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const PlanModalPricing = () => {
    return (
      <div className="planModalWix_pricing">
        <div className="planModalWix_pricingHeading">Pricing</div>
        <div className="planModalWix_note">{currentPlanInfo.note1}</div>
        <div
          className="planModalWix_note"
          dangerouslySetInnerHTML={{
            __html: currentPlanInfo.note2,
          }}
        ></div>
      </div>
    );
  };

  return (
    <Modal
      open={props.showModal}
      title={""}
      sectioned
      onClose={() => {
        props.setShowModal(false);
      }}
    >
      <div className="planModalWix">
        <PlanModalHeading />
        <PlanModalFeatures />
        <PlanModalPricing />
        {!props.walletRenewalStatus.active && (
          <div className="planModalWix_Footer">
            <button
              className="planModalWix_Button"
              type="button"
              onClick={() => {
                props.setShowModal(false);
                if (props.page) {
                  props.setPage(1);
                } else {
                  history.push({ pathname: "/wallet", state: { page: 1 } });
                }
              }}
            >
              {currentPlanInfo.start_line}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PlanModal;
