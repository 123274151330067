import { useEffect, useState } from 'react';
import CrossSell from './CrossSell';
import CustomerWinback from './CustomerWinback';
import Feedback from './Feedback';
import { Layout, Link, Page, Toast } from '@shopify/polaris';
import { fetchPhoneSettings } from '../../../redux/actions/crmOrders';
import {
  PLAN_OLD_FREE,
  PLAN_OLD_PRO,
  PLATFORM,
  AUTOMATION_PAGE_PRICING,
  SHOP_FEATURES,
  PLANS_MODAL_TITLE,
} from '../../../constants/constants';
import ApprovalModal from '../../ShopifyApprovalModal/ApprovalModal';
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";
import NoWABAModal from "../../Modals/NoWABAModal/NoWABAModal";
import PlansModal from "../../Modals/PlansModal";
import { shopFeaturesFromStoreData } from "../../../utils/helper";
import { useShopStore } from '../../../hooks/shopStore';

const AutomatedPromotionalTemplates = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [phoneData, setPhoneData] = useState({
    countryCode: '',
    phone: '',
  });
  const [showWABAModal, setShowWABAModal] = useState(false);

  let shop = shopDetailsData;
  const currentPlanId = shopDetailsData.featuresList;

  useEffect(() => {
    if (shop && shop.userId) {
      getPhoneData(shop.userId);
    }
    clevertapEventPush({
      eventId: CT_EVENT_IDS.AUTOMATED_PROMOTIONAL_TEMPLATES_PAGE_VIEWED,
      eventData: {},
    });
  }, []);

  const getPhoneData = (userId: string) => {
    fetchPhoneSettings(userId).then((res: any) => {
      if (res.status === 'success') {
        setPhoneData(res.settings);
      }
    });
  };

  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: '' });
      }}
    />
  ) : null;
  const [showModal, setShowModal] = useState(false);

  const checkForApproval = () => {
    if ([PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform)) {
      if (
        props.pvtWabaDetails &&
        props.pvtWabaDetails.appId &&
        props.pvtWabaDetails.whatsappVerificationStatus
      ) {
        return true;
      } else {
        setShowWABAModal(true);
      }
    } else {
      if (shopFeaturesFromStoreData(shop).includes(SHOP_FEATURES.AUTOMATION_BLOCKED)) {
        setShowModal(true);
      } else {
        return true;
      }
    }
  };

  return (
    <Page
      subtitle={
        'SuperLemon provides the ability to send automated messages to your customers. You can enable these messages based on various events from the following list:'
      }
      primaryAction={<button style={{ display: 'none' }}></button>}
    >
      {toastMarkup}
      {errorToastMarkup}
      <div className='mb-3'>
        <Layout>
          <CrossSell
            userId={shop.userId}
            showToast={showToast}
            showErrorToast={showErrorToast}
            phoneData={phoneData}
            checkForApproval={checkForApproval}
            optinEnterpriseTemplates={props.optinEnterpriseTemplates}
          />
        </Layout>
      </div>

      <div className='Polaris-Page--divider'></div>

      <div className='mt-1 mb-3'>
        <Layout>
          <CustomerWinback
            userId={shop.userId}
            showToast={showToast}
            showErrorToast={showErrorToast}
            phoneData={phoneData}
            checkForApproval={checkForApproval}
            optinEnterpriseTemplates={props.optinEnterpriseTemplates}
          />
        </Layout>
      </div>
      <div className='Polaris-Page--divider'></div>

      <div className='mt-1 mb-3'>
        <Layout>
          <Feedback
            userId={shop.userId}
            showToast={showToast}
            showErrorToast={showErrorToast}
            phoneData={phoneData}
            checkForApproval={checkForApproval}
            optinEnterpriseTemplates={props.optinEnterpriseTemplates}
          />
        </Layout>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: `${AUTOMATION_PAGE_PRICING.NEW_PRICING}`,
        }}
      ></div>
      <Layout>
        {/* <ApprovalModal
          showModal={showModal}
          setShowModal={setShowModal}
          feature={"Automated Promotional Messages"}
        /> */}
        {showModal && (
          <PlansModal
            title={PLANS_MODAL_TITLE.AUTOMATION_PAGE}
            currentPlanId={currentPlanId}
            shop={shop}
            showPricingPlansModal={showModal}
            setShowPricingPlansModal={setShowModal}
          />
        )}
      </Layout>
      <NoWABAModal showModal={showWABAModal} setShowModal={setShowWABAModal} />
    </Page>
  );
};
export default AutomatedPromotionalTemplates;
