// @ts-nocheck
import { useState, useEffect } from 'react';
import {
  Page,
  DataTable,
  Button,
  Stack,
  Icon,
  Modal,
  TextField,
} from '@shopify/polaris';
import { PlusMinor, EditMinor, DeleteMinor } from '@shopify/polaris-icons';
import moment from 'moment';
import { CONFIGURATIONSPAGE } from '../../../../constants/constants';
import {
  createQuickResponse,
  deleteQuickResponse,
  updateQuickResponse,
} from '../../../../redux/actions/chats';
import { useUsersContext } from '../../../../context/usersContext';
import { ReactComponent as IIcon } from '../../../../assets/icons/iIcon.svg';

function QuickResponse(props: any) {
  const { showToast, showErrorToast, fetchQuickResponses, quickResponses } =
    useUsersContext();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [quickResponseData, setQuickResponseData] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [keywordToBeDeleted, setKeywordToBeDeleted] = useState('');
  const openAddNewTagModal = () => {
    setOpenAddModal(true);
  };

  const reset = () => {
    fetchQuickResponses();
    setOpenEditModal(false);
    setOpenAddModal(false);
    setQuickResponseData({});
    setSelectedId('');
    setKeywordToBeDeleted('');
    setOpenDeleteModal(false);
  };

  const editQuickResponse = async () => {
    if (quickResponseData?.text && quickResponseData?.keyword) {
      let data = {
        text: quickResponseData.text.trim(),
        keyword: quickResponseData.keyword.trim(),
        quickResponseId: quickResponseData.id,
      };
      await props.runFunc(
        updateQuickResponse,
        data,
        'Quick Response updated successfully.'
      );
      reset();
    } else {
      showErrorToast('Keywords or Message should not be empty.');
    }
  };

  const removeQuickResponse = async (quickResponseId: string) => {
    const qR = quickResponses.filter((q: any) => {
      return quickResponseId === q.id;
    });
    await props.runFunc(
      deleteQuickResponse,
      quickResponseId,
      'Quick Response deleted successfully.'
    );
    reset();
  };

  const addNewQuickResponse = async () => {
    if (quickResponseData?.text && quickResponseData?.keyword) {
      let data = {
        text: quickResponseData.text.trim(),
        keyword: quickResponseData.keyword.trim(),
      };
      await props.runFunc(
        createQuickResponse,
        data,
        'Quick Response added successfully.'
      );
      reset();
    } else {
      showErrorToast('Keywords or Message should not be empty.');
    }
  };

  const rows = quickResponses.map((o: any) => {
    return [
      <Stack vertical spacing='extraTight'>
        <p style={{ lineBreak: 'anywhere', whiteSpace: 'pre-wrap' }}>
          {o.text}
        </p>
      </Stack>,
      <Stack vertical spacing='extraTight'>
        <p style={{ whiteSpace: 'pre-wrap' }}>{o.keyword}</p>
      </Stack>,
      <Stack vertical spacing='extraTight'>
        <p>{moment(new Date(o.createdOn)).format('DD/MM/YYYY | hh:mm:ss A')}</p>
      </Stack>,
      <Stack vertical spacing='extraTight'>
        <div style={{ display: 'flex', gap: '15px' }}>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setQuickResponseData(o);
              setOpenEditModal(true);
            }}
          >
            <Icon source={EditMinor} color='base' />
          </span>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpenDeleteModal(true);
              setKeywordToBeDeleted(o.id);
            }}
          >
            <Icon source={DeleteMinor} color='base' />
          </span>
        </div>
      </Stack>,
    ];
  });

  return (
    <>
      <Page>
        <div className='flexSpaceBetween mb2'>
          <div className='Polaris-Page-Header__TitleWrapper'></div>
        </div>
        <div className='flexSpaceBetween createNewCampaign'>
          <div style={{ width: '75%' }}>
            <h1 className='Polaris-Header-Title'>
              {CONFIGURATIONSPAGE.Quick_Response.TITLE}
            </h1>
          </div>
          <div className='button-design-new'>
            <Button
              icon={PlusMinor}
              disabled={false}
              onClick={() => openAddNewTagModal()}
              primary
            >
              {CONFIGURATIONSPAGE.Quick_Response.ADD_NEW_BUTTON}
            </Button>
          </div>
        </div>
        <br />
        {
          'With quick replies, you can create shortcuts for the messages you send to your customers most frequently. Open a chat, then type "/". This will pull up all your quick replies. You can also type specific keyword after “/” to pull specific quick reply.'
        }
        <div className='table-list'>
          <DataTable
            columnContentTypes={['text', 'text', 'text', 'text']}
            headings={CONFIGURATIONSPAGE.Quick_Response.TABLE.HEADERS}
            rows={rows}
          />
        </div>
      </Page>

      <Modal
        open={openAddModal}
        onClose={() => {
          reset();
        }}
        title={''}
      >
        <Modal.Section>
          <div style={{ padding: '20px' }}>
            <p className='chat__configurationModalHeading'>
              {CONFIGURATIONSPAGE.Quick_Response.MODEL.ADD_TITLE}
            </p>
            <div className='autoamted-response-form'>
              <TextField
                value={quickResponseData.text}
                onChange={(e) =>
                  setQuickResponseData({
                    ...quickResponseData,
                    text: e,
                  })
                }
                label={
                  <>
                    {CONFIGURATIONSPAGE.Quick_Response.MODEL.MESSAGE_BODY}{' '}
                    <IIcon title='You can type /Keyword in your WhatsApp inbox chat to populate the respective quick response' />
                  </>
                }
                type='text'
                multiline={4}
                autoComplete='off'
              />

              <TextField
                value={quickResponseData.keyword}
                onChange={(e) =>
                  setQuickResponseData({
                    ...quickResponseData,
                    keyword: e,
                  })
                }
                label={
                  <>
                    {CONFIGURATIONSPAGE.Quick_Response.MODEL.KEYWORDS}{' '}
                    <IIcon title='You can type /Keyword in your WhatsApp inbox chat to populate the respective quick response' />
                  </>
                }
                type='text'
                maxLength={30}
              />
              <div className='chat__ConfigurationButtonSave'>
                <Button
                  disabled={false}
                  onClick={() => addNewQuickResponse()}
                  primary
                >
                  {CONFIGURATIONSPAGE.Quick_Response.MODEL.SAVE_BUTTON}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Section>
      </Modal>

      <Modal
        open={openEditModal}
        onClose={() => {
          reset();
        }}
        title={''}
      >
        <Modal.Section>
          <div style={{ padding: '20px' }}>
            <p className='chat__configurationModalHeading'>
              {CONFIGURATIONSPAGE.Quick_Response.MODEL.EDIT_TITLE}
            </p>
            <div className='autoamted-response-form'>
              <TextField
                value={quickResponseData.text}
                onChange={(e) =>
                  setQuickResponseData({
                    ...quickResponseData,
                    text: e,
                  })
                }
                label={
                  <>
                    {CONFIGURATIONSPAGE.Quick_Response.MODEL.MESSAGE_BODY}{' '}
                    <IIcon title='You can type /Keyword in your WhatsApp inbox chat to populate the respective quick response' />
                  </>
                }
                type='text'
                multiline={4}
                autoComplete='off'
              />

              <TextField
                value={quickResponseData.keyword}
                onChange={(e) =>
                  setQuickResponseData({
                    ...quickResponseData,
                    keyword: e,
                  })
                }
                label={
                  <>
                    {CONFIGURATIONSPAGE.Quick_Response.MODEL.KEYWORDS}{' '}
                    <IIcon title='You can type /Keyword in your WhatsApp inbox chat to populate the respective quick response' />
                  </>
                }
                type='text'
                maxLength={30}
              />

              <div className='chat__ConfigurationButtonSave'>
                <Button
                  disabled={false}
                  onClick={() => editQuickResponse()}
                  primary
                >
                  {CONFIGURATIONSPAGE.Quick_Response.MODEL.EDIT_BUTTON}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Section>
      </Modal>

      <Modal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        title={''}
        small
      >
        <Modal.Section>
          <p className='chat__configurationModalHeading font2rem'>
            {'Delete Custom Quick Response'}
          </p>
          <p style={{ fontSize: '1.5rem', marginBottom: '10px' }}>
            Are you sure you want to delete Custom Quick Response ?
          </p>
          <br />
          <br />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: 'auto',
              justifyContent: 'center',
              padding: '10px',
              gap: '8px',
            }}
          >
            <div className='chat__ConfigurationButtonSave'>
              <Button
                onClick={() => removeQuickResponse(keywordToBeDeleted)}
                primary
              >
                {'YES'}
              </Button>
            </div>
            <div className='chat__ConfigurationButtonSave'>
              <Button onClick={() => reset()}>{'NO'}</Button>
            </div>
          </div>
        </Modal.Section>
      </Modal>
    </>
  );
}

export default QuickResponse;
