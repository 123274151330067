import { Frame, Layout, Page, Toast } from "@shopify/polaris";
import { useEffect, useState } from "react";
import styles from "./unsub.module.scss";
import { useLocation } from "react-router-dom";
import { UNSUBSCRIBE_EMAILS_PAGE } from "../../constants/constants";
import TickImage from "../../assets/icons/tick.svg"
import logo from "../../assets/images/superlemon-logo_2x.png";

const UnsubscribeEmail = () => {
  const userId = new URLSearchParams(useLocation().search).get("id") || "" ; 
  const [variant, setVariant] = useState<number>(0);

  const [toast, setToast] = useState({ show: false, message: "" });

  const showToast = (message: string) => {
    setToast({ show: true, message: message + userId });
  };

  useEffect(() => {
    if(userId !== "" && "clevertap" in window){
      const isDevEnv =
      process.env.REACT_APP_ENV === 'development' ||
      process.env.REACT_APP_ENV === 'staging';
      const cleverTapObj : any = window.clevertap;
      const profileObj = {
        Site: {
          Identity: userId,
          'MSG-whatsapp': Boolean(variant),
          'MSG-email': Boolean(variant),
        },
      };
      cleverTapObj.profile.push(profileObj);
      isDevEnv && console.log('clevertap User Properties : ', profileObj);
      showToast(variant ?  UNSUBSCRIBE_EMAILS_PAGE.resubToast : UNSUBSCRIBE_EMAILS_PAGE.unsubToast);
    }
    else{
      setVariant(-1);
    }
  }, [variant]);

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  return (
    <Frame>
      <div className={styles.unsub}>
      {toastMarkup}
      <Page narrowWidth>
        <Layout>
          <Layout.Section>
          {variant === -1 ? 
            <div className={styles.unsubContainer}>
              <div className={styles.errorContainer}>
                  {UNSUBSCRIBE_EMAILS_PAGE.errorMessage}
              </div> 
            </div>
          :
            <div className={styles.unsubContainer}>
              <div className={styles.unsubMsgContainer}>
                  <img src={TickImage} height={20} width={20}/> 
                <div>
                  {variant ? UNSUBSCRIBE_EMAILS_PAGE.resubMessage : UNSUBSCRIBE_EMAILS_PAGE.unsubMessage}
                </div>
              </div>
              <div className={styles.unsubBtnContainer}>
                <div>
                  {variant ? UNSUBSCRIBE_EMAILS_PAGE.resubBtnMessage : UNSUBSCRIBE_EMAILS_PAGE.unsubBtnMessage}
                </div>
                <div onClick={() => setVariant((prevState) => {return (1-prevState)})} className={styles.unsubBtn}>
                  {variant ? UNSUBSCRIBE_EMAILS_PAGE.unsubBtn : UNSUBSCRIBE_EMAILS_PAGE.resubBtn}
                </div>
              </div>
            </div>
          }
            <div className={styles['powered-by']}>
              <span className="strong">Powered by</span>
              <br></br>
              <a
                href="https://apps.shopify.com/whatsapp-chat-button?utm_source=cod"
                rel="noopener noreferrer"
                target="_blank"
                className="superlemon">
                <img
                  src={logo}
                  alt="Powered By Superlemon"
                  className={styles.logo}
                />
              </a>
            </div>
          </Layout.Section>
        </Layout>
      </Page>
      </div>
    </Frame>
  );
};

export default UnsubscribeEmail;
  