import { Link, Modal, Stack, TextContainer } from "@shopify/polaris";
import { useState } from "react";
import { useShopStore } from '../../hooks/shopStore';

const CheckoutOptinGuideModalShopify = (props: any) => {
  const { shopDetailsData } = useShopStore();  
  const [guideStep, setGuideStep] = useState(0);
  let shop = shopDetailsData;

  const modalContentGuideStep = () => {
    switch (guideStep) {
      case 0:
        return (
          <TextContainer>
            <p>
              Open your store's{" "}
              <Link
                url={`https://${shop.url}/admin/settings/checkout`}
                external={true}
              >
                {" "}
                E-commerce manager {">"} Checkout settings{" "}
              </Link>{" "}
              page.
            </p>
            <p>
              Search for <b>Customer contact</b> section, and select the option
              that allows customers to checkout using "phone number or email",
              as shown in the screenshot below:
            </p>
            <img
              className="checkoutOptinModalImage"
              src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/customer_contact.png?v=1591021139"
              alt="Customer Contact"
            />
            <p>
              If you need to select the email-only option, it's ok. Make sure to
              complete Step 2.
            </p>
          </TextContainer>
        );

      case 1:
        return (
          <span>
            <TextContainer>
              <p>
                Search for <b>Form options</b> section on the same page. Set{" "}
                <b>Shipping address phone number</b> as Required or Optional.
              </p>
              <img
                className="checkoutOptinModalImage"
                src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/shipping_address_phone_number.png?v=1591021201"
                alt="Form Options"
              />
              <p>
                We recommend setting it to <b>Required</b> if you selected
                email-only option in Step 1. Otherwise, we won't get the
                customer's phone number to send WhatsApp message.
              </p>
            </TextContainer>
          </span>
        );

      case 2:
        return (
          <TextContainer>
            <p>
              Search for <b>SMS Marketing</b> section on the same page. Select
              both the checkboxes as shown in the screenshot below:
            </p>
            <img
              className="checkoutOptinModalImage"
              src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/sms-marketing.png?v=1640177279"
              alt="Checkout Page Checkbox"
            />
            <p>
              Selecting this checkbox helps you get maximum possible opt-ins
              from the Checkout page.
              <br />
              <br />
              You will also be required to add in <b>
                Terms of Service
              </b> and <b>Privacy Policy</b> for your store if you haven’t
              already. If you have Privacy Policy and Terms of Service in place
              for your store, you can skip to Step 5.
            </p>
          </TextContainer>
        );

      case 3:
        return (
          <TextContainer>
            <p>
              To add <b>Terms of Service</b> and <b>Privacy Policy</b>, click on
              the <b>legal settings</b> link as shown below or go to Legal
              Settings page:
            </p>
            <img
              className="checkoutOptinModalImage"
              src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/legal-settings-1.png?v=1640177524"
              alt="Consent Page Url Option"
            />
            <br />
            <p>
              You will be redirected to the Legal Setting Page where you will
              need to add your Store’s Privacy Policy and Terms of Service and
              if you don’t have those, you can use Shopify’s template to add a
              default template by clicking on the <b>Create from Template</b>{" "}
              button as shown below:{" "}
            </p>
            <img
              className="checkoutOptinModalImage"
              src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/legal-settings-2.png?v=1640177751"
              alt="Privacy & Settings"
            />
            <p>
              This will automatically add a default template for{" "}
              <b>Privacy Policy</b>. Do the same for <b>Terms of Service</b> and
              click on <b>Save</b> and go back to the
              <Link
                url={`https://${shop.url}/admin/settings/checkout`}
                external={true}
              >
                {" "}
                E-commerce manager {">"} Checkout settings{" "}
              </Link>{" "}
              page.
            </p>
          </TextContainer>
        );

      case 4:
        return (
          <TextContainer>
            <p>
              Search for <b>Checkout language</b> section on the same page.{" "}
              <br />
              <br />
              Click on the <b>Manage checkout language</b> button as shown in
              the screenshot below:
            </p>
            <img
              className="checkoutOptinModalImage"
              src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/manage-checkout-language.png?v=1640178359"
              alt="Manage Checkout Language"
            />
          </TextContainer>
        );

      case 5:
        return (
          <span>
            <TextContainer>
              <p>
                In the <b>Manage checkout language</b> page,croll till the end
                of the page and then search for <b>Accept sms checkbox label</b>{" "}
                using Ctrl+F keys (CMD+F on Mac) as shown in the screenshot
                below:
              </p>
              <img
                className="checkoutOptinModalImage"
                src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/checkout_marketing.png?v=1640178513"
                alt="Accept sms checkbox label"
              />
              <p>
                Update the text field shown above to ANY of the following
                options:
              </p>
              <ul>
                <li>Get order updates on WhatsApp</li>
                <li>
                  Sign up for order updates, exclusive offers and news on
                  WhatsApp and/or SMS
                </li>
              </ul>
              <p>
                Mention other channels like SMS if you are using them. For
                example - "Get order updates on WhatsApp and/or SMS".
              </p>
            </TextContainer>
          </span>
        );

      case 6:
        return (
          <span>
            <TextContainer>
              <p>
                On the same page, search for <b>Phone label</b> or{" "}
                <b>Optional phone label</b> using Ctrl+F keys (CMD+F on Mac) as
                shown in the screenshot below:
              </p>
              <img
                className="checkoutOptinModalImage"
                src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/optin.png?v=1640178762"
                alt="Phone Label"
              />
              <p>Update the text field shown above to:</p>
              <ul>
                <li>Phone number for order updates</li>
              </ul>
              <p>
                This will help inform your customers about how their phone
                number will be used.
              </p>
            </TextContainer>
          </span>
        );

      case 7:
        return (
          <span>
            <TextContainer>
              <p>
                That was the final change required. Click the <b>Save</b> button
                on the top-right corner of the page to save all your changes.
              </p>
              <img
                className="checkoutOptinModalImage"
                src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/save-checkout.png?v=1640178741"
                alt="Save Button Checkout"
              />
            </TextContainer>
          </span>
        );

      case 8:
        return (
          <TextContainer>
            <p>
              <b>Almost done!</b> In case you missed any of the steps, you can
              click on the "Back" button and complete them.
            </p>
            <p>
              If you completed all the steps successfully, your checkout page
              should look similar to this:
            </p>
            <img
              className="checkoutOptinModalImageMoreWidth"
              src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/checkout-page-preview.png?v=1640178847"
              alt="Checkout Page Preview"
            />
            <p>
              <b>All done?</b> Click on "I have completed all the steps" and
              then Apply for approval.
            </p>
          </TextContainer>
        );
    }
  };

  const incrementGuideStep = () => {
    setGuideStep(guideStep + 1);
  };

  const decrementGuideStep = () => {
    setGuideStep(guideStep - 1);
  };

  const stepsCompletedActions = () => {
    props.stepsCompleted();
    props.closeModal();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
      // {modalTitleButtonContent}
      title={
        guideStep === 8 ? "Final step" : "Step " + (guideStep + 1) + " of 8"
      }
      primaryAction={{
        content:
          guideStep === 8 ? "I have completed all the steps" : "Next step",
        onAction: guideStep === 8 ? stepsCompletedActions : incrementGuideStep,
      }}
      secondaryActions={
        guideStep >= 1
          ? [
              {
                content: "Back",
                onAction: decrementGuideStep,
              },
            ]
          : undefined
      }
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>{modalContentGuideStep()}</Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export default CheckoutOptinGuideModalShopify;
