import { ANALYTICS } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

const config = {
  baseURL: ANALYTICS.baseUrl,
};
const accountConfig = {
  baseURL: ANALYTICS.accountBaseUrl,
};

const getClickCount = (userId: string, from?: string, to?: string) => {
  let query = "";
  if (from && to) {
    query = `from=${from}&to=${to}`;
  }
  return httpClient.get(
    ANALYTICS.api.SHARE_CHAT_CLICK_COUNT.replace("$userId", userId) + query,
    config
  );
};

const getSpinWheelAnalyticsCount = (
  userId: string,
  from?: string,
  to?: string
) => {
  let query = "";
  if (from && to) {
    query = `from=${from}&to=${to}`;
  }
  return httpClient.get(
    ANALYTICS.api.SPIN_WHEE_ANALYTICS.replace("$userId", userId) + query,
    config
  );
};

const getAbandonedCartAnalyticsCount = (
  userId: string,
  from?: string,
  to?: string
) => {
  let query = "";
  if (from && to) {
    query = `from=${from}&to=${to}`;
  }
  return httpClient.get(
    ANALYTICS.api.ABANDONED_CART_ANALYTICS.replace("$userId", userId) + query,
    accountConfig
  );
};

const getMessageAnalyticsCount = (
  userId: string,
  from?: string,
  to?: string,
  messageType?: number
) => {
  let query = "";
  if (from && to) {
    query = `from=${from}&to=${to}`;
  }
  return httpClient.get(
    ANALYTICS.api.MESSAGE_ANALYTICS.replace("$userId", userId) +
      query +
      `&messageType=${messageType}`,
    accountConfig
  );
};
const getMessageSettings = (userId: string) => {
  return httpClient.get(
    ANALYTICS.api.MESSAGE_SETTING.replace("$userId", userId),
    accountConfig
  );
};
const getOptinAnalyticsCount = (userId: string, from?: string, to?: string) => {
  let query = "";
  if (from && to) {
    query = `from=${from}&to=${to}`;
  }
  return httpClient.get(
    ANALYTICS.api.OPTIN.replace("$userId", userId) + query,
    config
  );
};

const getOptinData = (userId: string, from?: string, to?: string) => {
  let query = "&dailyAggregator=true";
  if (from && to) {
    query = `from=${from}&to=${to}` + query;
  }
  return httpClient.get(
    ANALYTICS.api.OPTIN.replace("$userId", userId) + query,
    config
  );
};
const getMessagesData = (userId: string, from?: string, to?: string) => {
  let query = "";
  if (from && to) {
    query = `from=${from}&to=${to}`;
  }
  return httpClient.get(
    ANALYTICS.api.MESSAGES.replace("$userId", userId) + query,
    config
  );
};

export {
  getClickCount,
  getOptinData,
  getMessagesData,
  getSpinWheelAnalyticsCount,
  getAbandonedCartAnalyticsCount,
  getMessageAnalyticsCount,
  getOptinAnalyticsCount,
  getMessageSettings,
};
