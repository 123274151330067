// @ts-nocheck
import { Banner, Layout, Tabs } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import TooltipOverlay from "./Tooltip";
import GuideModal from "../common/GuideModal";
import templateObj from "../content/Templates/OverlaysContent/en.json";
import customTemplateObj from "../content/CustomTemplate/OverlaysContent/en.json";
import extensionObj from "../content/Extension/OverlaysContent/en.json";
import chatSettingsObj from "../content/ChatSettings/OverlaysContent/en.json";
import shareSettingsObj from "../content/ShareSettings/OverlaysContent/en.json";
import spinTheWheelObj from "../content/SpinTheWheel/OverlaysContent/en.json";

const TabsWrapper = (props: any) => {
  const [selected, setSelected] = useState<any>(0);
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };

  // useEffect(() => {
  //     let selectedTab = localStorage.getItem('currentTab');
  //     setSelected(selectedTab ? Number(selectedTab) : 0);
  // }, []);

  useEffect(() => {
    if (props.handleTabChange) {
      props.handleTabChange(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (props.selected) {
      setSelected(props.selected);
    }
  }, [props.selected]);

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
    //   localStorage.setItem('currentTab', selectedTabIndex);
  }, []);

  const renderContent = () => {
    let totalTabs = props.tabs.length;
    let template = props.content[0];
    for (var i = 0; i <= totalTabs; i++) {
      if (selected === i) {
        template = props.content[i];
      }
    }
    return template;
  };

  const guideModalData = () => {
    if (props.tabs[0].id === "Automated") {
      if (selected === 0 || selected === 1) {
        return templateObj;
      } else if (selected === 2) {
        return customTemplateObj;
      } else if (selected === 3) {
        return extensionObj;
      }
    } else if (props.tabs[0].id === "Chat Settings") {
      if (selected === 0) {
        return chatSettingsObj;
      } else if (selected === 1) {
        return shareSettingsObj;
      } else if (selected === 2) {
        return spinTheWheelObj;
      }
    }
  };
  return (
    <>
      {props.tabs[0]?.id !== "checklist" &&
      props.tabs[0]?.panelID !== "templates " &&
      props.tabs[1]?.panelID !== "emailLogs" &&
      guideModalData() ? (
        <TooltipOverlay
          btn1Action={() => setOpenGuideModal(true)}
          obj={guideModalData()}
        />
      ) : null}

      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={guideModalData()}
          tabIndex={tabIndex}
        />
      ) : null}
      <div className="w-100">
        <Tabs tabs={props.tabs} selected={selected} onSelect={handleTabChange}>
          {renderContent()}
          {!props.clean && selected !== 2 && (
            <Layout.Section>
              <Banner
                action={{
                  content: "Contact Us",
                  url:
                    "mailto:support@superlemon.xyz?subject=I%20need%20help%20with%20WhatsApp%20chat%20—%20" +
                    props.shopUrl,
                  external: true,
                }}
                status="info"
              >
                <p>
                  For any bug reports or support related queries, please contact
                  our team (support@superlemon.xyz)
                </p>
              </Banner>
            </Layout.Section>
          )}
        </Tabs>
      </div>
    </>
  );
};

export default TabsWrapper;
