import axios from "axios";
import cookie from "react-cookies";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.headers.put["Content-Type"] =
  "application/x-www-form-urlencoded";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    config.headers["token"] = cookie.load("access_token");
    config.headers["Content-Type"] = "application/x-www-form-urlencoded";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => {
    return res["data"] ? res["data"] : res.status;
  },
  (err) => {
    return Promise.reject(err);
  }
);
export default api;
