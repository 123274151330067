import { Heading, TextStyle } from "@shopify/polaris";
import { useState, useEffect } from "react";
import TooltipIcon from "../assets/icons/tooltip.svg";
import { useShopStore } from '../hooks/shopStore';

function TooltipOverlay({
  obj,
  btn1Action,
  btn2Action,
  btn3Action,
  showPricingBanner,
  currentSection,
}: any) {
  const { shopDetailsData } = useShopStore();
  const [isUltimatePlanUser, setisUltimatePlanUser] = useState(false);
  let features = shopDetailsData.featuresList;

  useEffect(() => {
    if (features) {
      const isUltimatePlan = features?.includes("AUTOMATED_MESSAGING");
      setisUltimatePlanUser(isUltimatePlan);
    }
  }, [features]);

  function getOnclickMethod(i: number) {
    if (i === 0) {
      return btn1Action;
    }
    if (i === 1) {
      return btn2Action;
    }
    if (i === 2) {
      return btn3Action;
    }
  }
  function getContent() {
    return (
      <>
        {obj && (
          <>
            {" "}
            <div className="heading">
              <Heading>{obj.tooltip_card.heading}</Heading>
            </div>
            <br />
            <div className="text">
              {(currentSection || currentSection === 0) ? 
                <TextStyle variation="subdued">
                  <div key={obj.tooltip_card.content[currentSection].text + obj.tooltip_card.content[currentSection].buttonActionText}>
                    {obj.tooltip_card.content[currentSection].text ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${obj.tooltip_card.content[currentSection].text} `,
                        }}
                      ></div>
                    ) : null}
                    <br />
                    {obj.tooltip_card.content[currentSection].buttonActionText ? (
                      <div>
                        <button
                          className="Polaris-Button Polaris-Button--primary"
                          onClick={getOnclickMethod(currentSection)}
                        >
                          {obj.tooltip_card.content[currentSection].buttonActionText}
                        </button>
                      </div>
                    ) : null}
                    <br />
                  </div>
                </TextStyle>
              :
                <TextStyle variation="subdued">
                  {obj.tooltip_card.content.map((el: any, i: number) => (
                    <div key={el.text + el.buttonActionText}>
                      {el.text ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${el.text} `,
                          }}
                        ></div>
                      ) : null}
                      <br />
                      {el.buttonActionText ? (
                        <div>
                          <button
                            className="Polaris-Button Polaris-Button--primary"
                            onClick={getOnclickMethod(i)}
                          >
                            {el.buttonActionText}
                          </button>
                        </div>
                      ) : null}
                      <br />
                    </div>
                  ))}
                </TextStyle>
              }
            </div>
          </>
        )}
      </>
    );
  }
  return (
    <div
      className={`tooltip-overlay`}
    >
      <div className="tooltip-outer-container" id="tooltip-div">
        <div className="icon">
          <img
            alt="Success Icon"
            src={TooltipIcon}
            title="Guide"
            className="tooltip-svg-icon"
          ></img>
        </div>
      </div>
      <div className="tooltip-hover-content">
        <div className="inner-content">{getContent()}</div>
      </div>
    </div>
  );
}

export default TooltipOverlay;
