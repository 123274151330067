import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  FormLayout,
  Layout,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import {
  getAgentTimings,
  getStoreTimings,
} from "../../../redux/actions/chat-settings";
import StoreTimingsModal from "./StoreTimingsModal";
import AgentInfo from "./AgentInfo";
import Agents from "../../../types/Agents.type";
import { objectDifference } from "../../../utils/helper";
import { getAgents } from "../../../redux/actions/agents";
import { useShopStore } from '../../../hooks/shopStore';

const AgentSettings = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [agentUpdated, setAgentUpdated] = useState(false);
  const [agentSettings, setAgentSettings] = useState<any>();
  const [storeTimings, setStoreTimings] = useState<any[]>([]);
  const [agentTimings, setAgentTimings] = useState<any[]>([]);
  const [openEditOnlineHoursModal, setOpenEditOnlineHoursModal] =
    useState(false);
  const [isStoreOpen, setIsStoreOpen] = useState(true);
  const [isDefaultStoreTimings, setIsDefaultStoreTimings] = useState(true);
  const [isChatVisibleOffline, setIsChatVisibleOffline] = useState(true);
  const [isInitialChatVisibleOffline, setIsInitialChatVisibleOffline] =
    useState(true);

  const [agentsData] = useState<Agents>({
    userId: "",
    buttonEnabled: true,
  });

  const [refAgentsData, setRefAgentsData] = useState<Agents>({
    userId: "",
    buttonEnabled: true,
  });

  const [updateData, setUpdateData] = useState({});

  let shop = shopDetailsData;
  const shopSettings = JSON.parse(localStorage.getItem("shopSettings") || "{}");

  useEffect(() => {
    if (shop && shop.userId) {
      fetchAgents(shop.userId);
      fetchAgentTimings(shop.userId);
      fetchStoreTimings(shop.userId);
      setIsStoreOpen(shopSettings.is_store_open);
      setIsDefaultStoreTimings(shopSettings.is_default_store_timings);
    }
  }, []);

  useEffect(() => {
    setIsChatVisibleOffline(props.shopSettings.buttonEnabled);
    setIsInitialChatVisibleOffline(props.shopSettings.buttonEnabled);
  }, [props.shopSettings]);

  useEffect(() => {
    if (props.isDataSubmitted) {
      setRefAgentsData(agentsData);
      setIsInitialChatVisibleOffline(isChatVisibleOffline);
    }
  }, [props.isDataSubmitted]);

  useEffect(() => {
    props.onChanges(updateData);
  }, [updateData]);

  useEffect(() => {
    if (!isChatVisibleOffline === isInitialChatVisibleOffline) {
      setUpdatedAgentsData("buttonEnabled", isChatVisibleOffline);
    }
  }, [isChatVisibleOffline]);

  useEffect(() => {
    var diffObj: any = objectDifference(refAgentsData, agentsData);
    setUpdateData({});
    if (Object.keys(diffObj).length > 0) {
      if (diffObj.buttonEnabled)
        setUpdatedAgentsData("buttonEnabled", diffObj.buttonEnabled);
    }
  }, [agentsData]);

  useEffect(() => {
    if (props.reset) {
      setIsChatVisibleOffline(isInitialChatVisibleOffline);
    }
  }, [props.reset]);

  const fetchAgents = (userId: string) => {
    getAgents(userId).then(
      (response: any) => {
        setAgentSettings(response.agents);
        fetchAgentTimings(userId);
      },
      (err: any) => {}
    );
  };

  const fetchAgentTimings = (userId: string) => {
    getAgentTimings(userId).then(
      (data: any) => {
        setAgentTimings(data);
      },
      (err) => {}
    );
  };

  const fetchStoreTimings = (userId: string) => {
    getStoreTimings(userId).then(
      (response: any) => {
        setStoreTimings(response.timings);
      },
      (err) => {}
    );
  };

  const openTimingsModal = () => {
    setOpenEditOnlineHoursModal(true);
  };

  const getOnlineHoursText = () => {
    var are_all_agents_offline = areAllAgentsOffline();

    if (are_all_agents_offline && isDefaultStoreTimings) {
      return (
        <TextContainer>
          <TextStyle>
            Based on the online hours you have set, all your active{" "}
            <b>chat agents are currently</b>{" "}
            <span className="offlineText">offline</span>.
          </TextStyle>
        </TextContainer>
      );
    } else if (are_all_agents_offline && !isStoreOpen) {
      return (
        <TextContainer>
          <TextStyle>
            Based on the online hours you have set, all your active{" "}
            <b>chat agents are currently</b>{" "}
            <span className="offlineText">offline</span>.
          </TextStyle>
        </TextContainer>
      );
    } else if (are_all_agents_offline && isStoreOpen) {
      return (
        <TextContainer>
          <TextStyle>
            Based on the online hours you have set, all your active{" "}
            <b>chat agents are currently</b>{" "}
            <span className="offlineText">offline</span>.
          </TextStyle>
        </TextContainer>
      );
    } else if (isDefaultStoreTimings) {
      return (
        <TextContainer>
          <TextStyle variation="subdued">
            <p>
              Set on/off hours for your agents. For offline hours you can
              configure an offline message below.
            </p>
            <br />
            <p>
              Note: If your agents work in shifts and you want to set online
              hours for individual agents, then click on "Edit details" for the
              agent and set on/off hours there.
            </p>
          </TextStyle>
        </TextContainer>
      );
    } else {
      if (isStoreOpen) {
        return (
          <TextContainer>
            <TextStyle variation="subdued">
              On/off hours are enabled. The chat button is currently{" "}
              <span className="onlineText">online</span>.
            </TextStyle>
          </TextContainer>
        );
      } else {
        return (
          <TextContainer>
            <TextStyle>
              On/off hours are enabled. Based on the timings set by you, the{" "}
              <b>chat button is currently</b>{" "}
              <span className="offlineText">offline</span>.
            </TextStyle>
          </TextContainer>
        );
      }
    }
  };

  const areAllAgentsOffline = () => {
    var are_all_agents_offline = true;
    if (agentSettings && agentTimings.length > 0) {
      for (let i = 0; i < agentSettings.length; i++) {
        const agentTiming = agentTimings.find(
          (ele) => ele.agentId === agentSettings[i].id
        );

        if (
          agentSettings[i].is_enabled &&
          isAgentOnline(agentTiming, props.shopSettings?.store_timezone)
        ) {
          are_all_agents_offline = false;
          break;
        }
      }
    }

    return are_all_agents_offline;
  };

  var weekday = new Array(7);
  weekday[0] = 6;
  weekday[1] = 0;
  weekday[2] = 1;
  weekday[3] = 2;
  weekday[4] = 3;
  weekday[5] = 4;
  weekday[6] = 5;

  const isAgentOnline = (agentTiming: any, store_timezone = "UTC") => {
    if (storeTimings.length === 0) return false;
    var currentDateInStoreTimezone = new Date(
      new Date().toLocaleString("en-US", { timeZone: store_timezone })
    );
    var currentDayIndex = weekday[currentDateInStoreTimezone.getDay()];
    var currentHourMinutes =
      currentDateInStoreTimezone.getHours() * 60 +
      currentDateInStoreTimezone.getMinutes();

    var startHourMinutes =
      parseInt(
        agentTiming.timings[currentDayIndex].start_time.substring(0, 2)
      ) *
        60 +
      parseInt(agentTiming.timings[currentDayIndex].start_time.substring(2, 4));
    var endHourMinutes =
      parseInt(agentTiming.timings[currentDayIndex].end_time.substring(0, 2)) *
        60 +
      parseInt(agentTiming.timings[currentDayIndex].end_time.substring(2, 4));

    return (
      startHourMinutes <= currentHourMinutes &&
      endHourMinutes >= currentHourMinutes
    );
  };

  const showButtonOffline = () => {
    setIsChatVisibleOffline(!isChatVisibleOffline);
  };

  const modifyAgents = () => {
    setAgentUpdated(!agentUpdated);
  };

  const setUpdatedAgentsData = (field: string, value: any) => {
    setUpdateData((widgetData) => ({ ...widgetData, [field]: value }));
  };

  const basicSettingsDescription = (
    <span>
      <p>
        Our WhatsApp chat widget supports multiple agent functionality. You have
        the option to add multiple support users by having seperate number for
        each user. You need to click on the Add New agent button to configure
        this.
      </p>
      <br />
      <p>
        We also provide functionality to define the working hours for each agent
        and based on their working hours, the end customer will see those agents
        when they click on the WhatsApp chat widget. You can configure this from
        the Edit Details option post adding the agent.
        {/* Edit On/Off hours as per the availability of your agents and their working time. */}
      </p>
    </span>
  );

  return (
    <Layout.AnnotatedSection
      title="Basic Settings"
      description={basicSettingsDescription}
    >
      <div className="pt-2">
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <Checkbox
                label="Show button when store or agents are offline."
                checked={isChatVisibleOffline}
                onChange={showButtonOffline}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card>
        <AgentInfo
          agentSettings={agentSettings}
          agentTimings={agentTimings}
          shopSettings={props.shopSettings}
          storeTimings={storeTimings}
          setToast={props.setToast}
          setErrorToast={props.setErrorToast}
          setShowSuccessToast={props.setShowSuccessToast}
          setShowErrorToast={props.setShowErrorToast}
          modifyAgents={modifyAgents}
          timezone={props.shopSettings?.store_timezone}
          fetchAgents={fetchAgents}
          fetchAgentTimings={fetchAgentTimings}
        />
        <br />
        <StoreTimingsModal
          storeTimings={storeTimings}
          timezone={props.shopSettings?.store_timezone}
          open={openEditOnlineHoursModal}
          closeModal={() => setOpenEditOnlineHoursModal(false)}
          setToast={props.setToast}
          setErrorToast={props.setErrorToast}
          setShowSuccessToast={props.setShowSuccessToast}
          setShowErrorToast={props.setShowErrorToast}
          setIsStoreOpen={setIsStoreOpen}
          updateStoreTimings={(
            timings: any,
            isStoreOpen: boolean,
            isDefaultStoreTimings: boolean
          ) => {
            setStoreTimings(timings);
            setIsStoreOpen(isStoreOpen);
            setIsDefaultStoreTimings(isDefaultStoreTimings);
          }}
          fetchStoreTimings={fetchStoreTimings}
        />
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <Button onClick={openTimingsModal}>{"Edit on/off hours"}</Button>
            </FormLayout.Group>
            <FormLayout.Group>{getOnlineHoursText()}</FormLayout.Group>
          </FormLayout>
        </Card>
      </div>
    </Layout.AnnotatedSection>
  );
};

export default AgentSettings;
