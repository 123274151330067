import { useState, useEffect } from "react";
import obj from "../../../content/OptinNumber/UserGroupsGuide/en.json";
import TooltipOverlay from "../../../common/Tooltip";
import GuideModal from "../../../common/GuideModal";
import { Button, Page, Toast } from "@shopify/polaris";
import CreateUserGroupModal from "./CreateUserGroup/CreateUserGroup";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";

const UserGroups = ({ showPricingBanner }: any) => {
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const [createUserGroupModal, setCreateUserGroupModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [allowGroupCreation, setAllowGroupCreation] = useState(true);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);
  const [errorToast, setErrorToast] = useState({ show: false, message: '' });
  const [groupLimt,setGroupLimit] = useState('')

  useEffect(() => {
    clevertapEventPush({
      eventId: CT_EVENT_IDS.CUSTOMER_SEGMENT_PAGE_VIEWED,
      eventData: {},
    });
  }, []);

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: '' });
      }}
    />
  ) : null;

  const groupCreation = () =>{
    if (allowGroupCreation){
      setCreateUserGroupModal(true);
      setIsEdit(false);
    }
    else setErrorToast({ show: true, message: `Only ${groupLimt} groups are allowed in current plan, please upgrade the plan to create more` });
  }  

  return (
    <>
      <Page>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px",
            }}
          >
            <div style={{ width: "100%" }}>
              You can create a group of Customers based on Various Criteria.
              Engage, communicate, promote and reach out to them to drive sales
              and customer loyalty like never before.
            </div>
            <div>
              <Button
                onClick={() => {
                  groupCreation();  
                }}
                primary
                // disabled={!allowGroupCreation}
              >
                Create Customer Groups
              </Button>
            </div>
          </div>
        </>
        <></>
      </Page>
      <TooltipOverlay
        btn1Action={() => setOpenGuideModal(true)}
        obj={obj}
        showPricingBanner={showPricingBanner}
      />
      {errorToastMarkup}
      <CreateUserGroupModal
        open={createUserGroupModal}
        setCreateUserGroupModal={setCreateUserGroupModal}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setAllowGroupCreation={setAllowGroupCreation}
        setGroupLimit={setGroupLimit}
      />
      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      ) : null}
    </>
  );
};
export default UserGroups;
