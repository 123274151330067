import {
    Modal, 
} from "@shopify/polaris";
import './style.scss';
import Plans from '../../Pricing/Plans';
  
  const PlansModal = (props: any) => {
    const freeTrialText = "7 Day Free Trial";
    return (
      <Modal
        open={props.showPricingPlansModal}
        sectioned={true}
        large
        title={''}
        onClose={() => {
          props.setShowPricingPlansModal(false);
        }}
      >
        <Modal.Section>
          <div>
            <div className="plan-modal-title-wrap">
              <h1 className="plan-modal-title">{props.title}</h1>
              <span className="free-trial-text">{freeTrialText}</span>
            </div>
            <div className="old-free-plan-modal-container">
              <Plans
                  shopId={props.shop.id}
                  userId={props.shop.userId}
                  setShowUpgradePlanDiv={() => {}}
              />
            </div> 
          </div>       
        </Modal.Section>
      </Modal>
    );
  };
  
  export default PlansModal;
  