// @ts-nocheck
import { ReactComponent as GreenHamburgerIcon } from "../../../../assets/icons/greenHamburgerIcon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/editIcon.svg";
import { ReactComponent as LeftArrowIcon } from "../../../../assets/icons/leftArrow.svg";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { ReactComponent as UserSendMessage_blockIcon } from "../../../../assets/icons/userSendMessage_blockIcon.svg";
import moment from "moment";
import { useEffect, useState } from "react";
import { useUsersContext } from "../../../../context/usersContext";
import { DAY_SECONDS } from "../../../../constants/constants";
import { saveChatRoomInfo } from "../../../../redux/actions/chats";
import AvatarInitial from "../../../../common/AvatarInitials";

const ChatWindowHeader = (props: any) => {
  const { currentUser, setCurrentUser, agentPhone, mobileView } =
    useUsersContext();
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [notes, setNotes] = useState("");
  const [remainingTime, setRemainingTime] = useState(0);
  const [showTimer, setShowTimer] = useState(false);

  useEffect(() => {
    setRemainingTime(props.getRemainingTime(false));
    setShowTimer(props.getRemainingTime(true));
    setShowNoteInput(false);
    setNotes(currentUser.notes ? currentUser.notes : "");
  }, [currentUser]);

  const [timeSize, setTimerSize] = useState(50);

  useEffect(() => {
    if (mobileView.status) {
      if (mobileView.screenWidth < 400) setTimerSize(35);
      else setTimerSize(50);
    }
  }, [mobileView]);

  const setNote = async () => {
    if (currentUser.customerPhone) {
      const data = {
        agentPhone: agentPhone,
        customerPhone: currentUser.customerPhone,
        tags: currentUser.tags ? currentUser.tags.join(",") : "",
        ticketStatus: currentUser.ticketStatus,
        notes: notes,
        chatRead: currentUser?.chatRead,
      };
      const res = await saveChatRoomInfo(data);
      setCurrentUser(res);
    }
    setShowNoteInput(false);
  };

  const TimerComponent = () => {
    const getTimeSeconds = (time: any) => {
      const remTime = props.getRemainingTime(false);
      return remTime;
    };

    const renderTime = (time: any) => {
      return (
        <div className="remainingTime-wrapper">
          <div className="remainingTime">
            {moment().startOf("day").seconds(time).format("HH:mm")}
          </div>
        </div>
      );
    };

    return showTimer ? (
      <CountdownCircleTimer
        onComplete={() => {
          setShowTimer(false);
          return { shouldRepeat: false, delay: 1.5 }; // repeat animation in 1.5 seconds
        }}
        isPlaying={true}
        duration={DAY_SECONDS}
        initialRemainingTime={remainingTime}
        colors={"#5EC127"}
        size={timeSize}
        strokeWidth={3.5}
        rotation="counterclockwise"
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime(getTimeSeconds(elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
    ) : (
      <div>
        <UserSendMessage_blockIcon alt="userSendMessage_blockIcon" />
      </div>
    );
  };

  return (
    <div className="chat__chatWindow-header">
      {(mobileView.screenWidth < 1024 ||
        (mobileView.screenWidth < 1300 && props.showUserInfoPanel)) && (
        <div className="chat__arrow-wrapper">
          <LeftArrowIcon
            className="chat__arrow"
            onClick={() => {
              props.setSelectedTab(0);
              props.setShowUserInfoPanel(false);
            }}
          />
        </div>
      )}
      <div className="chat__avatar-wrapper">
        <AvatarInitial name={currentUser?.customerName} avatarSize={"large"} />
      </div>
      <div className="chat__contact-wrapper">
        <span className="chat__contact-name">
          {currentUser?.customerName
            ? currentUser?.customerName
            : currentUser?.customerPhone}
        </span>

        {!showNoteInput ? (
          <div key={"Notes"} className="chat__contact-desc">
            <label className="chat__contact-desc-subTitle">
              {notes ? notes : "Add status or note about this chat"}{" "}
            </label>
            <div
              className="chat__contact-desc-image"
              onClick={() => {
                setShowNoteInput(true);
              }}
            >
              <EditIcon alt="editIcon" />
            </div>
          </div>
        ) : (
          <div key={"Notes"} className="chat__contact-desc">
            <input
              className="chat__contact-desc-input"
              placeholder="Enter Status or Note"
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  setNote();
                }
              }}
              onBlur={setNote}
              maxLength={30}
            ></input>
          </div>
        )}
      </div>

      <div className="chat__actions">
        <div className="chat__timerContainer">
          <TimerComponent />
        </div>
        <br />
        {!props.showUserInfoPanel ? (
          <div
            className="chat__greenHamburger-wrapper"
            onClick={props.handleOpenUserInfoDetails}
          >
            <GreenHamburgerIcon
              className="chat__greenHamburger"
              alt="greenHamburgerIcon"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ChatWindowHeader;
