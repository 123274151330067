import { CUSTOMER_SEGMENTATION } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

let config = {
  baseURL: CUSTOMER_SEGMENTATION.baseUrl,
};

export const createUserGroup = (userId: string, params: any) => {
  return httpClient.post(
    CUSTOMER_SEGMENTATION.api.CREATE_USER_GROUP.replace("$userId", userId),
    params,
    config
  );
};

export const getUserGroups = (userId: any, pageSize : number, pageNumber : number) => {
  return httpClient.get(
    CUSTOMER_SEGMENTATION.api.GET_USER_GROUPS.replace("$userId", userId)
    .replace("$pageSize", String(pageSize))
    .replace("$pageNumber", String(pageNumber)),
    config
  );
};

export const getAllSegment = (userId: any) => {
  return httpClient.get(
    CUSTOMER_SEGMENTATION.api.GET_ALL_SEGMENT.replace("$userId", userId),
    config
  );
};

export const editUserGroup = (userId: string, id: any, params: any) => {
  return httpClient.post(
    CUSTOMER_SEGMENTATION.api.EDIT_USER_GROUP.replace(
      "$userId",
      userId
    ).replace("$groupId", id),
    params,
    config
  );
};

export const deleteUserGroup = (userId: string, id: any) => {
  return httpClient.delete(
    CUSTOMER_SEGMENTATION.api.DELETE_USER_GROUP.replace(
      "$userId",
      userId
    ).replace("$groupId", id)
  );
};

export const downloadCustomerGroup = (userId: string, id: any) => {
  return httpClient.get(
    CUSTOMER_SEGMENTATION.api.DOWNLOAD_CUSTOMER_GROUP.replace(
      "$userId",
      userId
    ).replace("$groupId", id)
  );
};
