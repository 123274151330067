import {
  getAuthenticated,
  getAuthenticated2,
  getRedirectionUrl,
  getUserDetails,
} from '../../redux/actions/login';

export const redirectUser = async function () {
  try {
    const apiRes: any = await getRedirectionUrl();
    if (apiRes?.status === 'success' && apiRes?.redirectionUrl) {
      window.location.replace(apiRes.redirectionUrl);
      window.focus();
      return true;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const whatsappAuth = async function (token: any) {
  try {
    const apiRes: any = await getAuthenticated2(token);
    return apiRes;
  } catch (err) {
    return {
      status: false,
    };
  }
};
