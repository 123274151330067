import React, { useState, useEffect } from "react";
import {
  Card,
  FormLayout,
  Layout,
  RadioButton,
  Stack,
  TextContainer,
} from "@shopify/polaris";
import {
  DEVICE_TYPE_DESKTOP_ONLY,
  DEVICE_TYPE_MOBILE_AND_DESKTOP,
  DEVICE_TYPE_MOBILE_ONLY,
} from "../../../../constants/constants";
import MobileConfig from "./MobileConfig";
import DesktopConfig from "./DesktopConfig";
import { getWheelDevices } from "../../../../redux/actions/spin-the-wheel";
import { useShopStore } from '../../../../hooks/shopStore';

const Devices = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [mobileDeviceData, setMobileDeviceData] = useState({
    deviceType: "mobile",
    spinWheelPosition: "left",
    wheelVerticalOffset: "10",
    wheelHorizontalOffset: "10",
  });
  const [desktopDeviceData, setDesktopDeviceData] = useState({
    deviceType: "desktop",
    spinWheelPosition: "left",
    wheelVerticalOffset: "10",
    wheelHorizontalOffset: "10",
  });
  const [updatedDevices, setUpdatedDevices] = useState<any>({
    mobile: {},
    desktop: {},
  });
  const [selectedDeviceType, setSelectedDeviceType] = useState(
    DEVICE_TYPE_MOBILE_AND_DESKTOP
  );
  const [initialDeviceType, setInitialDeviceType] = useState(
    DEVICE_TYPE_MOBILE_AND_DESKTOP
  );

  useEffect(() => {
    if (selectedDeviceType === 1) {
      setDesktopDeviceData({
        deviceType: "desktop",
        spinWheelPosition: "",
        wheelVerticalOffset: "",
        wheelHorizontalOffset: "",
      });

      props.setDesktopData({});
    } else if (selectedDeviceType === 2) {
      setMobileDeviceData({
        deviceType: "mobile",
        spinWheelPosition: "",
        wheelVerticalOffset: "",
        wheelHorizontalOffset: "",
      });

      props.setMobileData({});
    }
  }, [props.saveFlag]);
  useEffect(() => {
    let data = updatedDevices;
    switch (selectedDeviceType) {
      case DEVICE_TYPE_MOBILE_ONLY:
        data["desktop"] = {};
        break;
      case DEVICE_TYPE_DESKTOP_ONLY:
        data["mobile"] = {};
        break;
    }
    props.onChanges(data);
  }, [updatedDevices]);

  useEffect(() => {
    let shop = shopDetailsData;
    if (shop && shop.userId) {
      fetchDevicesData(shop.userId);
    }
  }, []);

  useEffect(() => {
    if (props.reset) {
      setSelectedDeviceType(initialDeviceType);
      props.setHasChanges(false);
    }
  }, [props.reset]);

  useEffect(() => {
    if (props.isDataSubmitted) {
      setInitialDeviceType(selectedDeviceType);
    }
  }, [props.isDataSubmitted]);

  const fetchDevicesData = (userId: string) => {
    getWheelDevices(userId).then(
      (data: any) => {
        if (data && data.status === "success") {
          let mobile = data.devices.find((m: any) => m.deviceType === "mobile");
          let desktop = data.devices.find(
            (m: any) => m.deviceType === "desktop"
          );

          if (mobile) {
            setMobileDeviceData(mobile);
            props.setMobileData(mobile);
          } else {
            // sets initial data if undefined || null
            props.setMobileData(mobileDeviceData);
          }

          if (desktop) {
            setDesktopDeviceData(desktop);
            props.setDesktopData(desktop);
          } else {
            // sets initial data if undefined || null
            props.setDesktopData(desktopDeviceData);
          }

          if (mobile && !desktop) {
            setSelectedDeviceType(DEVICE_TYPE_MOBILE_ONLY);
            setInitialDeviceType(DEVICE_TYPE_MOBILE_ONLY);
            props.onTypeChange(DEVICE_TYPE_MOBILE_ONLY, false);
          } else if (desktop && !mobile) {
            setSelectedDeviceType(DEVICE_TYPE_DESKTOP_ONLY);
            setInitialDeviceType(DEVICE_TYPE_DESKTOP_ONLY);
            props.onTypeChange(DEVICE_TYPE_DESKTOP_ONLY, false);
          } else {
            setSelectedDeviceType(DEVICE_TYPE_MOBILE_AND_DESKTOP);
            setInitialDeviceType(DEVICE_TYPE_MOBILE_AND_DESKTOP);
            props.onTypeChange(DEVICE_TYPE_MOBILE_AND_DESKTOP, false);
          }
        }
      },
      (err) => {}
    );
  };

  const handleDevicesChange = (value: number) => {
    setSelectedDeviceType(value);
    props.onTypeChange(value, true);
  };

  const updateChanges = (data: any) => {
    if (data) {
      if (data.deviceType === "mobile") {
        setUpdatedDevices({ ...updatedDevices, mobile: data });
        props.setMobileData((old: any) => ({
          ...old,
          ...data,
        }));
      } else if (data.deviceType === "desktop") {
        setUpdatedDevices({ ...updatedDevices, desktop: data });
        props.setDesktopData((old: any) => ({
          ...old,
          ...data,
        }));
      }
    }
  };

  const deviceMarkup = (
    <>
      <MobileConfig
        data={mobileDeviceData}
        selected={selectedDeviceType}
        onChanges={updateChanges}
        isDataSubmitted={props.isDataSubmitted}
        reset={props.reset}
        onError={props.onError}
        setHasChanges={props.setHasChanges}
      ></MobileConfig>
      <DesktopConfig
        data={desktopDeviceData}
        selected={selectedDeviceType}
        onChanges={updateChanges}
        isDataSubmitted={props.isDataSubmitted}
        reset={props.reset}
        onError={props.onError}
        setHasChanges={props.setHasChanges}
      ></DesktopConfig>
    </>
  );

  return (
    <Layout.AnnotatedSection
      title="Button Display & Position"
      description="SuperLemon Provides you the ability to select channels eligible for the Spin the Wheel widget. You can also configure the position of the Spin the Wheel widget on your website by adjusting the height and edge offset."
    >
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <div>
              <TextContainer>Spin the Wheel Widget display</TextContainer>
              <br />
              <Stack>
                <RadioButton
                  label="Mobile + Desktop"
                  checked={
                    selectedDeviceType === DEVICE_TYPE_MOBILE_AND_DESKTOP
                  }
                  name="mobilecheck"
                  onChange={() =>
                    handleDevicesChange(DEVICE_TYPE_MOBILE_AND_DESKTOP)
                  }
                />
                <RadioButton
                  label="Mobile only"
                  name="mobilecheck"
                  checked={selectedDeviceType === DEVICE_TYPE_MOBILE_ONLY}
                  onChange={() => handleDevicesChange(DEVICE_TYPE_MOBILE_ONLY)}
                />
                <RadioButton
                  label="Desktop only"
                  name="mobilecheck"
                  checked={selectedDeviceType === DEVICE_TYPE_DESKTOP_ONLY}
                  onChange={() => handleDevicesChange(DEVICE_TYPE_DESKTOP_ONLY)}
                />
              </Stack>
            </div>
          </FormLayout.Group>
        </FormLayout>
      </Card>

      {deviceMarkup}
    </Layout.AnnotatedSection>
  );
};

export default Devices;
