export const FAQS = [
  {
    topic: "Subscription",
    questions: {
      0: {
        ques: "What happens when the trial for a subscription expires?",
        ans: [
          "As the subscription trial period expires, your subscription will automatically move from the trial state to the live state, which means that the user will be charged for the subscription and will have full access to the subscription's benefits and features. This will happen if a payment option is associated with the subscription and the activation date is on the next day after the trial end date.",
          "However if a payment option is not associated with the subscription and the activation date is the next day after the trial end date. Since there is no payment method on file, the system will not be able to charge the user for the subscription, and it will be marked as canceled."  
        ],
      },
      1: {
        ques: "Where can I see my subscription details?",
        ans: [
          "For Shopify users, from your Shopify admin account, click 'Settings' > 'Billing'. The list includes your Shopify plan and app subscriptions.",

          "For WiX users only the Engage plan is available. They can visit the wallet section inside the SuperLemon application to check the status of the plan. "
        ],
      },
      2: {
        ques: "Can I switch between plans during my subscription period?",
        ans: [
          "Yes,you can switch the plan anytime you want.",
          "We offer the flexibility for users to switch between plans during their subscription period. This means that users can upgrade or downgrade their plan based on their changing needs or preferences. To ensure fair billing, we have implemented a prorata basis which means that you are only charged for the days you use a particular plan.If you switch plans in the middle of your subscription period, we will calculate the charges based on the number of days you have used the current plan and the cost of the new plan you are switching to."
        ],
      },
    },
  },
  {
    topic: "WhatsApp Business API",
    questions: {
      0: {
        ques: "What is the WhatsApp Business API?",
        ans: [
          "WhatsApp Business API is a communication tool that allows businesses to interact with their customers on the WhatsApp messaging platform at scale. It provides an official way for businesses to communicate with their customers via WhatsApp.",

          "WhatsApp Business API enables businesses to send and receive messages, notifications, and alerts to customers in real-time. With this API, businesses can automate their customer support, send appointment reminders, order confirmations, shipping notifications, and other customer-related information. However, it's important to note that WhatsApp Business API is available only on “Engage Plan.”",
        ],
      },
      1: {
        ques: "Is there any charge for creating a WhatsApp Business Account?",
        ans: [
          "There are NO additional charges for creating a WhatsApp Business API account on SuperLemon.",
        ],
      },
      2: {
        ques: "What is the difference between WhatsApp business app & whatsapp business API?",
        ans: [
          "The main difference between the WhatsApp Business app and the WhatsApp Business API lies in their features, capabilities, and the way they are intended to be used.",

          "WhatsApp Business App: ",
          "The WhatsApp Business app is a standalone application designed for small and medium-sized businesses (SMBs) to communicate with their customers. It provides business-specific features to enhance customer interactions, such as creating a business profile, automated greetings and replies, labels for organizing conversations, and statistics on message metrics. The app is primarily meant for manual, one-on-one communication with customers.",
          
          "WhatsApp Business API:",
          "The WhatsApp Business API, on the other hand, is an interface provided by WhatsApp for larger businesses. It allows businesses to send messages in a more automated and programmatic way, enabling features like sending notifications, transactional messages, and customer support messages. The API offers greater scalability, customization, and flexibility, making it suitable for businesses with higher volumes of messages or complex integration requirements.",
        ],
      },
      3: {
        ques: "Will I be able to use the WhatsApp app after registering for WhatsApp Business API?",
        ans: [
          "No, registering for the WhatsApp Business API does not grant you access to the regular WhatsApp app. The WhatsApp Business API is specifically designed for businesses to communicate with their customers at scale. It provides additional features and capabilities tailored for business use, such as automated messaging, chatbot integration, and analytics.",
          "If you register for the WhatsApp Business API, you will need to develop or use third-party software to interact with the API and manage your business communications. This is typically done through a separate platform or application specifically designed for the WhatsApp Business API.",
        ],
      },
      4: {
        ques: "How to apply for WhatsApp Green Tick Verification?",
        ans: [
          "To apply for a whatsapp green tick reach out to us on support@superlemon.xyz",
        ],
      },
      5: {
        ques: "Is a green tick guaranteed?",
        ans: [
          "No, a green tick is not guaranteed on platforms like WhatsApp. The green tick, or verified badge, typically signifies that an account or business has been verified as genuine by the Meta.",

          "On WhatsApp, verified badges are currently only given to select business accounts that have completed a verification process. This process involves meeting certain criteria and providing documentation to prove the authenticity of the business. WhatsApp evaluates each application individually, and not all businesses may be eligible or approved for a green tick.",
          
          "It's important to note that the verification process and criteria can change over time, so it's best to refer to the official guidelines and documentation provided by the Meta itself to understand the requirements for obtaining a green tick on a specific platform. <a href='https://faq.whatsapp.com/794517045178057/?helpref=uf_share' class='FAQ_link' target='blank' rel='noopener noreferrer'>Click here for the reference</a>",
        ],
      },
    },
  },
  {
    topic: "Messages & Messaging Charges",
    questions: {
      0: {
        ques: "What type of messages can I send on WhatsApp?",
        ans: [
          "You can send both transactional and promotional messages on WhatsApp.",


          "&#x2022; Text Messages: You can send plain text messages to your customers containing information about your products, services, promotions, or any other relevant information.",
          "&#x2022; Multimedia Messages: You can send multimedia messages, such as images, videos, audios, and documents, to provide more detailed information about your products or services.",
          "&#x2022; Template Messages: You can use pre-approved templates to send messages for things like shipping notifications, newly launched reminders, and customer support messages.",
          "&#x2022; Interactive Messages: You can use interactive messages, such as surveys to engage with your customers and get feedback."
        ],
      },
      1: {
        ques: "Is there any limit on the number of messages I can send?",
        ans: [
          "Your messaging limits are automatically defined by WhatsApp based on the volume and quality of the messages you send. If you send high or medium-quality messages at higher volumes, you will be able to send an unlimited number of messages. Read more about messaging limits <a href='https://superlemon.xyz/help-center/business-api/17/' class='FAQ_link' target='blank' rel='noopener noreferrer'>here</a>.",
        ],
      },
      2: {
        ques: "How are messaging charges calculated?",
        ans: [
          "Messaging charges are calculated based on usage i.e on the total number of messages being successfully sent. For Engage plan user - It is a sum of <a href='https://developers.facebook.com/docs/whatsapp/pricing/' class='FAQ_link' target='blank' rel='noopener noreferrer'>WhatsApp charges</a> and Superlemon commission charges ($0.004) ",
          "For Free Plan user -  It is a sum of <a href='https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0' class='FAQ_link' target='blank' rel='noopener noreferrer'>Variable consumption charge</a> and Superlemon commission charges ($0.004) ",
          
          "For example :  Business-Initiated Rate ($0.0066) + Superlemon commission ($0.004) = $0.0106/ message",
        ],
      },
      3: {
        ques: "But WhatsApp is free. What does WhatsApp conversation charges mean?",
        ans: [
          "Yes, WhatsApp as a messaging app or a business app is free.",

          "However, for businesses looking to start communicating to their customers and grow their sales at scale, WhatsApp Business API platform is the best fit. To leverage WhatApp Business platform as a sales channel, there are conversation charges levied by WhatsApp.",
          
          "When you send a notification to your customer via WhatsApp Business platform, they are chargeable if your customer has not replied in the last 24 hours. The charges depend on the country code of your customers’ WhatsApp account. <a href='https://developers.facebook.com/docs/whatsapp/pricing/' class='FAQ_link' target='blank' rel='noopener noreferrer'> Learn more </a>",
        ],
      },
      4: {
        ques: "What are automated template messages?",
        ans: [
          "Automated Templates in SuperLemon are pre-written messages that can be triggered automatically based on specific events in your ecommerce store. These templates are designed to simplify communication with your customers by sending timely and relevant messages. For example, you can set up templates to notify customers when their order is confirmed, shipped, or delivered. These messages can include important information such as order details, tracking numbers, and other updates. By enabling Automated Templates in SuperLemon, you can effortlessly keep your customers informed throughout their purchasing journey, saving you time and enhancing the overall customer experience.",
        ],
      },
      5: {
        ques: "What is usage based charge in a free plan?",
        ans: [
          "Free plan does not have any monthly subscription fee. However, SuperLemon charges the user based on the number of automated messages sent. Usage-based charges refer to the fees incurred for sending automated WhatsApp messages. These charges are based on the amount or volume of messages sent through the platform. These charges vary depending on how much the messages have been sent. <a href='https://docs.google.com/spreadsheets/d/1-9L8fgQB1sntX5lIo_evYn4Q9Job2RRy0uZMYlbg5zg/edit#gid=0' class='FAQ_link' target='blank' rel='noopener noreferrer'>Click here to know the rate card</a>",
        ],
      },
      6: {
        ques: "What are $500 recurring charges on Shopify?",
        ans: [
          "Recurring charges on Shopify are fees or payments that are regularly billed to customers. The purpose of these charges is to grant continuous access to a product throughout the subscription period. Typically, recurring charges are set up to be automatically billed at specific intervals.",

          "If, at any point, a customer wishes to discontinue their subscription, they have the flexibility to cancel or modify the recurring charges. It is essential for customers to stay mindful of their recurring charges to ensure accurate billing and to make informed decisions about their subscriptions.",
        ],
      },
    },
  },
  {
    topic: "Others",
    questions: {
      0: {
        ques: "I have a custom requirement",
        ans: [
          "We create AI-powered chatbots and integrate with 500+ platforms to engage customers in 2-way conversations across marketing, commerce and support workflows.",
        ],
      },
      1: {
        ques: "My question is not listed here",
        ans: [
          "For any product-related queries and support, email us at: support@superlemon.xyz",
        ],
      },
    },
  },
];
