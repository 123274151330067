import { Page, Modal, Stack, TextContainer } from '@shopify/polaris';
import './InstructionsModal.scss';

const InstructionsModal = (props: any) => {
  const {
    setShowInstructionModal,
    showInstructionModal,
    content,
    setImportChatStep,
  } = props;

  const closeModal = () => {
    setShowInstructionModal(false);
  };

  return (
    <Modal
      open={showInstructionModal}
      sectioned={true}
      titleHidden
      title={''}
      onClose={() => {
        setShowInstructionModal(false);
        setImportChatStep(1);
      }}
    >
      <Modal.Section>
        <div className='basic-learnMore-modal'>{content}</div>
      </Modal.Section>
    </Modal>
  );
};

export default InstructionsModal;
