import React, { useState, useEffect } from "react";
import {
  Card,
  Checkbox,
  FormLayout,
  Layout,
  Link,
  SettingToggle,
  Stack,
  TextField,
  TextStyle,
  Tooltip,
} from "@shopify/polaris";
import GreetingTemplatePreview from "./GreetingTemplatePreview";
import { GREETING_TEMPLATE_DICT } from "../../../constants/constants";
import CustomColorPicker from "../../../common/CustomColorPicker";
import {
  getGreetingWidgetData,
  updateGreetingWidgetData,
} from "../../../redux/actions/chat-settings";
import ChangeGreetingModal from "./ChangeGreetingModal";
import { isValidHex, objectDifference } from "../../../utils/helper";
import { getShopSettings } from "../../../redux/actions/shop";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";
import { useShopStore } from '../../../hooks/shopStore';

const GreetingWidget = (props: any) => {
  const {
    setToast,
    setErrorToast,
    setShowErrorToast,
    setShowSuccessToast,
    setHasChanges,
  } = props;
  const { shopDetailsData } = useShopStore();
  var templetes: any = GREETING_TEMPLATE_DICT;
  const [openChangeGreetingWidgetModal, setOpenChangeGreetingWidgetModal] =
    useState(false);
  const [isMultiAgentEnabled, setIsMultiAgentEnabled] = useState(
    props.shopSettings.multiAgentEnabled
  );
  const [initialData, setInitialData] = useState({
    greeting_template: "",
    greeting_template_bg_color_1: "",
    greeting_template_bg_color_2: "",
    greeting_template_desc_text_color: "",
    greeting_template_head_text_color: "",
    is_greeting_template_color_custom: false,
    is_greeting_template_solid_background: false,
    multi_agent_msg: "",
    multi_agent_title: "",
    offline_agent_msg: "",
    offline_store_msg: "",
    should_shuffle_agents: false,
  });
  const [greetingWidgetData, setGreetingWidgetData] = useState({
    greeting_template: "",
    greeting_template_bg_color_1: "",
    greeting_template_bg_color_2: "",
    greeting_template_desc_text_color: "",
    greeting_template_head_text_color: "",
    is_greeting_template_color_custom: false,
    is_greeting_template_solid_background: false,
    multi_agent_msg: "",
    multi_agent_title: "",
    offline_agent_msg: "",
    offline_store_msg: "",
    should_shuffle_agents: false,
  });
  const [refGreetingWidgetData, setRefGreetingWidgetData] = useState({
    greeting_template: "",
    greeting_template_bg_color_1: "",
    greeting_template_bg_color_2: "",
    greeting_template_desc_text_color: "",
    greeting_template_head_text_color: "",
    is_greeting_template_color_custom: false,
    is_greeting_template_solid_background: false,
    multi_agent_msg: "",
    multi_agent_title: "",
    offline_agent_msg: "",
    offline_store_msg: "",
    should_shuffle_agents: false,
  });
  const [updateData, setUpdateData] = useState({});

  const [plan] = useState("");
  let shop = shopDetailsData;

  useEffect(() => {
    if (shop && shop.userId) {
      fetchGreetingWidgetData(shop.userId);
      fetchShopSettings(shop.userId);
    }
  }, []);

  useEffect(() => {
    if (props.isDataSubmitted) {
      setRefGreetingWidgetData(greetingWidgetData);
      setInitialData(greetingWidgetData);
    }
  }, [props.isDataSubmitted]);

  useEffect(() => {
    var diffObj: any = objectDifference(
      refGreetingWidgetData,
      greetingWidgetData
    );
    setUpdateData({});
    if (Object.keys(diffObj).length > 0) {
      if (diffObj.multi_agent_msg)
        setUpdatedWidgetData("text", diffObj.multi_agent_msg);
      if (diffObj.multi_agent_title)
        setUpdatedWidgetData("title", diffObj.multi_agent_title);
      if (diffObj.offline_agent_msg)
        setUpdatedWidgetData("offlineAgentMessage", diffObj.offline_agent_msg);
      if (diffObj.offline_store_msg)
        setUpdatedWidgetData("offlineStoreMessage", diffObj.offline_store_msg);
      if ([true, false].includes(diffObj.should_shuffle_agents)) {
        setUpdatedWidgetData(
          "should_shuffle_agents",
          diffObj.should_shuffle_agents
        );
      }
      if (diffObj.greeting_template_bg_color_1)
        setUpdatedWidgetData("bgcolor1", diffObj.greeting_template_bg_color_1);
      if (diffObj.greeting_template_bg_color_2)
        setUpdatedWidgetData("bgcolor2", diffObj.greeting_template_bg_color_2);
      if (diffObj.greeting_template_head_text_color)
        setUpdatedWidgetData(
          "textColor",
          diffObj.greeting_template_head_text_color
        );
      if (diffObj.greeting_template_desc_text_color)
        setUpdatedWidgetData(
          "descColor",
          diffObj.greeting_template_desc_text_color
        );
      if ("is_greeting_template_solid_background" in diffObj)
        setUpdatedWidgetData(
          "solidBg",
          diffObj.is_greeting_template_solid_background
        );
    }
  }, [greetingWidgetData]);

  useEffect(() => {
    let errorMessage = getErrorMessage();
    if (errorMessage) {
      props.onError(errorMessage, "greeting");
      props.onChanges({});
    } else {
      props.onError("", "greeting");
      props.onChanges(updateData);
    }
  }, [updateData]);

  useEffect(() => {
    if (props.reset) {
      setRefGreetingWidgetData(initialData);
      setGreetingWidgetData(initialData);
    }
  }, [props.reset]);

  const getErrorMessage = () => {
    if (validateGreetingWidgetTitle() !== false) {
      return "Invalid Greetings Title";
    } else if (validateGreedingWidgetMsg() !== false) {
      return "Invalid Greetings Help Text";
    } else if (validateOfflineAgentMsg() !== false) {
      return "Invalid Greetings Offline Agent Message";
    } else if (validateOfflineStoreMsg() !== false) {
      return "Invalid Greetings Offline Store Message";
    } else {
      return false;
    }
  };

  const fetchGreetingWidgetData = (userId: string) => {
    getGreetingWidgetData(userId).then(
      (data: any) => {
        setInitialData(data);
        setRefGreetingWidgetData(data);
        setGreetingWidgetData(data);
      },
      (err) => {}
    );
  };

  const handleChange = (val: any, field: string) => {
    setGreetingWidgetData((widgetData) => ({ ...widgetData, [field]: val }));
  };

  const fetchShopSettings = (userId: string) => {
    getShopSettings(userId).then((response: any) => {
      setIsMultiAgentEnabled(response.settings.multiAgentEnabled);
    });
  };

  const greetingsWidgetDescription = (
    <span>
      <p>
        You can greet your store visitors by adding a customized title and help
        message in the greeting widget.
      </p>
      <br />
      <p>
        Please note that the greeting widget is automatically enabled if
        multiple agents are activated.
      </p>
    </span>
  );

  const getGreetingWidgetBtnContent = () => {
    if (isMultiAgentEnabled) {
      return "Disable";
    } else {
      return "Enable";
    }
  };

  const toggleMultiAgentEnabled = () => {
    let multiAgentEnabled = isMultiAgentEnabled ? false : true;
    setUpdateData((widgetData) => ({
      ...widgetData,
      multiAgentEnabled: multiAgentEnabled,
    }));
    setIsMultiAgentEnabled(multiAgentEnabled);
  };
  const getGreetingWidgetVariation = () => {
    if (isMultiAgentEnabled) {
      return "strong";
    } else {
      return "negative";
    }
  };

  const getGreetingWidgetStatus = () => {
    if (isMultiAgentEnabled) {
      return "enabled";
    } else {
      return "disabled";
    }
  };

  const validateGreetingWidgetTitle = () => {
    if (
      greetingWidgetData.multi_agent_title &&
      greetingWidgetData.multi_agent_title.length > 12
    ) {
      return "Maximum 12 characters";
    }
    if (!greetingWidgetData.multi_agent_title.trim()) {
      return "Field can't be empty";
    }
    return false;
  };

  const validateGreedingWidgetMsg = () => {
    if (
      greetingWidgetData.multi_agent_msg &&
      greetingWidgetData.multi_agent_msg.length > 90
    ) {
      return "Maximum 90 characters";
    }
    if (!greetingWidgetData.multi_agent_msg.trim()) {
      return "Field can't be empty";
    }
    return false;
  };

  const validateOfflineAgentMsg = () => {
    if (
      greetingWidgetData.offline_agent_msg &&
      greetingWidgetData.offline_agent_msg.length > 200
    ) {
      return "Maximum 200 characters";
    }
    if (!greetingWidgetData.offline_agent_msg.trim()) {
      return "Field can't be empty";
    }

    return false;
  };

  const getCurrentSelectedGreetingTemplateUrl = () => {
    var template = getCurrentSelectedGreetingTemplate();
    return template ? template["preview_url"] : "";
  };

  const validateOfflineStoreMsg = () => {
    if (
      greetingWidgetData.offline_store_msg &&
      greetingWidgetData.offline_store_msg.length > 200
    ) {
      return "Maximum 200 characters";
    }
    if (!greetingWidgetData.offline_store_msg.trim()) {
      return "Field can't be empty";
    }
    return false;
  };

  const getCurrentSelectedGreetingTemplate = () => {
    for (var i = 1; i <= Object.keys(templetes).length; i++) {
      if (templetes[i].id === greetingWidgetData.greeting_template) {
        return templetes[i];
      }
    }
  };
  const customGreetingColorSettings = () => {
    var multi_agent_title = greetingWidgetData.multi_agent_title;
    var multi_agent_msg = greetingWidgetData.multi_agent_msg;

    var is_greeting_template_solid_background =
      greetingWidgetData.is_greeting_template_solid_background;
    var bgColor1 = greetingWidgetData.greeting_template_bg_color_1 || "#20802C";
    var bgColor2 = greetingWidgetData.greeting_template_bg_color_2 || "#30BF42";
    var buttonTextColor =
      greetingWidgetData.greeting_template_head_text_color || "#ffffff";
    var descTextColor =
      greetingWidgetData.greeting_template_desc_text_color || "#ffffff";

    return {
      is_greeting_template_solid_background,
      bgColor1,
      bgColor2,
      buttonTextColor,
      multi_agent_title,
      multi_agent_msg,
      descTextColor,
    };
  };

  const initCustomGreeting = {
    template: getCurrentSelectedGreetingTemplate() || {},
    type: "greeting",
    isCustomColors: greetingWidgetData.is_greeting_template_color_custom,
    isBgStyleColorSolid:
      greetingWidgetData.is_greeting_template_solid_background,
    bgColor1: isValidHex(greetingWidgetData.greeting_template_bg_color_1)
      ? greetingWidgetData.greeting_template_bg_color_1
      : "#20802C",
    bgColor2: isValidHex(greetingWidgetData.greeting_template_bg_color_2)
      ? greetingWidgetData.greeting_template_bg_color_2
      : "#30BF42",
    buttonTextColor: isValidHex(
      greetingWidgetData.greeting_template_head_text_color
    )
      ? greetingWidgetData.greeting_template_head_text_color
      : "#ffffff",
    descTextColor: isValidHex(
      greetingWidgetData.greeting_template_desc_text_color
    )
      ? greetingWidgetData.greeting_template_desc_text_color
      : "#ffffff",
    allowDescColor: true,
    textColorLabel1: "Heading text colour",
    mainLabel: "Use custom Greetings Widget colours",
  };
  const onCustomGreetingBtnChange = (obj: any) => {
    if (obj.bgColor1)
      handleChange(obj.bgColor1, "greeting_template_bg_color_1");
    if (obj.bgColor2)
      handleChange(obj.bgColor2, "greeting_template_bg_color_2");
    if (obj.buttonTextColor)
      handleChange(obj.buttonTextColor, "greeting_template_head_text_color");
    if (obj.descTextColor)
      handleChange(obj.descTextColor, "greeting_template_desc_text_color");
    if ("isBgStyleColorSolid" in obj)
      handleChange(
        obj.isBgStyleColorSolid,
        "is_greeting_template_solid_background"
      );
  };

  const getGreetingTemplatePreview = () => {
    var template = getCurrentSelectedGreetingTemplate();
    return GreetingTemplatePreview({
      template: template,
      custom: customGreetingColorSettings(),
    });
  };

  const changeGreetingTemplate = (selected: any, data: any = {}) => {
    var updatedData: any = {
      greeting_template: selected,
    };

    for (var key in data) {
      updatedData[key] = data[key];
      setGreetingWidgetData((widgetData) => ({
        ...widgetData,
        [key]: data[key],
      }));
    }

    if (selected) {
      let params = new URLSearchParams();
      params.append("templateId", selected.toString());
      params.append("custom", data.is_greeting_template_color_custom);
      updateGreetingWidgetData(shop.userId, params).then((response: any) => {
        if (response.status === "success") {
          setShowSuccessToast((successToast: any) => !successToast);
          clevertapEventPush({
            eventId: CT_EVENT_IDS.WIDGET_EDITED,
            eventData: {
              "Widget Name": "Chat widget",
            },
          });
          setToast("Greetings Widget Design Saved");
          setHasChanges(false);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }

        setOpenChangeGreetingWidgetModal(false);
        document.body.classList.remove("settings-page");
      });
    }
  };
  const setUpdatedWidgetData = (field: string, value: any) => {
    setUpdateData((widgetData) => ({ ...widgetData, [field]: value }));
  };

  const openGreetingsChangeDesignModal = () => {
    setOpenChangeGreetingWidgetModal(true);
    document.body.classList.add("settings-page");
  };

  const getOfflineAgentText = () => {
    if (greetingWidgetData.offline_agent_msg) {
      return greetingWidgetData.offline_agent_msg;
    }
    if (
      !greetingWidgetData.offline_agent_msg &&
      refGreetingWidgetData.offline_agent_msg
    ) {
      return greetingWidgetData.offline_agent_msg;
    } else {
      return "None of our agents are available to chat at this time.We are sorry for the inconvenience. Please try again later.";
    }
  };
  const getOfflineStoreText = () => {
    if (greetingWidgetData.offline_store_msg) {
      return greetingWidgetData.offline_store_msg;
    }
    if (
      !greetingWidgetData.offline_store_msg &&
      refGreetingWidgetData.offline_store_msg
    ) {
      return greetingWidgetData.offline_store_msg;
    } else {
      return "Hi, our working hours are <start_time> to <end_time>, request you to reach us at the same time. Apologies for the inconvenience.";
    }
  };

  return (
    <Layout.AnnotatedSection
      title="Greetings Widget"
      description={greetingsWidgetDescription}
    >
      <SettingToggle
        action={{
          content: getGreetingWidgetBtnContent(),
          onAction: toggleMultiAgentEnabled,
        }}
        enabled={isMultiAgentEnabled}
      >
        <Stack vertical>
          <p>
            {"Greetings Widget is currently "}
            <TextStyle variation={getGreetingWidgetVariation()}>
              {getGreetingWidgetStatus()}
            </TextStyle>{" "}
            <Link
              url={
                "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_widget_preview_image_7f0c476f-08a5-4028-a098-e0880f9a72dc.png?v=1584168495"
              }
              external={true}
            >
              Preview
            </Link>
          </p>
        </Stack>
      </SettingToggle>
      <br />
      <div className="greetings-widget-setting-toggle">
        <SettingToggle
          action={{
            content: "Select Design",
            onAction: () => openGreetingsChangeDesignModal(),
          }}
          enabled={true}
        >
          <div className="previewBtnBackground">
            {getCurrentSelectedGreetingTemplate() &&
            getCurrentSelectedGreetingTemplate()["is_customizable"] ? (
              <div className="templateItem greetings">
                {getGreetingTemplatePreview()}
              </div>
            ) : (
              <img
                className="previewBtnBackground"
                src={getCurrentSelectedGreetingTemplateUrl()}
                alt="WhatsApp icon"
              />
            )}
          </div>
        </SettingToggle>
      </div>
      <br />
      <CustomColorPicker
        {...initCustomGreeting}
        onCustomChange={onCustomGreetingBtnChange}
      />
      <ChangeGreetingModal
        available_templates={GREETING_TEMPLATE_DICT}
        plan={plan}
        customSettings={customGreetingColorSettings()}
        selected_template_id={greetingWidgetData.greeting_template}
        changeTemplate={changeGreetingTemplate}
        onModalClose={() => {
          setOpenChangeGreetingWidgetModal(false);
          document.body.classList.remove("settings-page");
        }}
        open={openChangeGreetingWidgetModal}
      />
      {isMultiAgentEnabled ? (
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                label="Title to be displayed on top of the greeting Widget."
                type="text"
                value={greetingWidgetData.multi_agent_title}
                onChange={(val) => handleChange(val, "multi_agent_title")}
                error={validateGreetingWidgetTitle()}
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField
                label="Help text to be displayed inside the greeting Widget"
                type="text"
                value={greetingWidgetData.multi_agent_msg}
                onChange={(val) => handleChange(val, "multi_agent_msg")}
                error={validateGreedingWidgetMsg()}
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField
                label="Offline Agent Message to be displayed when all agents are offline."
                type="text"
                value={getOfflineAgentText()}
                onChange={(val) => handleChange(val, "offline_agent_msg")}
                error={validateOfflineAgentMsg()}
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField
                label="Offline Store Message to be displayed when your store is offline."
                type="text"
                value={getOfflineStoreText()}
                onChange={(val) => handleChange(val, "offline_store_msg")}
                error={validateOfflineStoreMsg()}
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <Stack alignment="center" spacing="tight" distribution="leading">
                <Checkbox
                  checked={greetingWidgetData.should_shuffle_agents}
                  label={
                    <span className="polaris-chice-margin-right">
                      Show Chat Agents in random order.
                    </span>
                  }
                  onChange={(val) => handleChange(val, "should_shuffle_agents")}
                />

                <Tooltip
                  content="For multiple numbers, this ensures equal distribution of chats across all numbers"
                  active={false}
                >
                  <img
                    src="https://cdn.shopify.com/s/files/1/0272/5983/0365/files/Vector_2.png?25"
                    alt="info icon"
                    className="callout-card-tooltip-image"
                  />
                </Tooltip>
              </Stack>
            </FormLayout.Group>
          </FormLayout>
        </Card>
      ) : null}
    </Layout.AnnotatedSection>
  );
};

export default GreetingWidget;
