import * as React from "react";

const Rejected = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <path
      d="M11.4019 4.5C12.5566 2.5 15.4434 2.5 16.5981 4.5L23.5263 16.5C24.681 18.5 23.2376 21 20.9282 21H7.07179C4.76239 21 3.31902 18.5 4.47372 16.5L11.4019 4.5Z"
      fill="#F31D1D"
    />
    <path
      d="M14.34 8.636L14.208 14.636H13.296L13.164 8.636H14.34ZM13.8 17.072C13.592 17.072 13.416 17 13.272 16.856C13.128 16.712 13.056 16.536 13.056 16.328C13.056 16.12 13.128 15.944 13.272 15.8C13.416 15.656 13.592 15.584 13.8 15.584C14 15.584 14.168 15.656 14.304 15.8C14.448 15.944 14.52 16.12 14.52 16.328C14.52 16.536 14.448 16.712 14.304 16.856C14.168 17 14 17.072 13.8 17.072Z"
      fill="white"
    />
  </svg>
);

export default Rejected;
