import {
  ContextualSaveBar,
  Layout,
  Link,
  SettingToggle,
  TextStyle,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import {
  getWheelDetails,
  updateWheelDetails,
  getWheelTemplates,
  getWheelConfig,
  setWheelConfigData,
  updateSelectedWheel,
} from "../../redux/actions/spin-the-wheel";
import {
  deleteWheelDevices,
  updateWheelDevices,
} from "../../redux/actions/spin-the-wheel";
import { isNullOrEmpty, isValidHex } from "../../utils/helper";
import React from "react";
import WidgetDesign from "./SpinTheWheel/WidgetDesign";
import qs from "qs";
import Devices from "./SpinTheWheel/Devices";
import {
  DEVICE_TYPE_DESKTOP_ONLY,
  DEVICE_TYPE_MOBILE_AND_DESKTOP,
  DEVICE_TYPE_MOBILE_ONLY,
  PLAN_OLD_PRO,
  PLAN_OLD_FREE,
  SpinTheWheelDefaultResponse,
  SpinTheWheelDefaultTemplates,
  SHOP_FEATURES,
  PLANS_MODAL_TITLE
} from "../../constants/constants";
import PagesToDisplay from "./PagesToDisplay";
import ApprovalModal from "../ShopifyApprovalModal/ApprovalModal";
import { clevertapEventPush, CT_EVENT_IDS } from "../../utils/clevertapUtils";
import NoScriptModal from "../Modals/NoScriptTagModal/NoScriptModal";
import { shopFeaturesFromStoreData } from "../../utils/helper";
import PlansModal from "../Modals/PlansModal";
import { useShopStore } from '../../hooks/shopStore';

const ShareSettings = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const { setToast, setErrorToast, setShowErrorToast, setShowSuccessToast } =
    props;
  const [isWheelEnabled, setisWheelEnabled] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [saveFlag, setSaveFlag] = useState(false);
  const [mobileData, setMobileData] = useState({});
  const [desktopData, setDesktopData] = useState({});
  const [stwData, setSTWData] = useState({
    wheelId: "",
    language: "",
    wheelPages: [],
    is_wheel_enabled: true,
    wheel_color_1: "#AB5252",
    wheel_color_2: "#F9CACD",
    wheel_config: "",
    internalText: "",
  });
  const [dataChanges, setDataChanges] = useState<any>({
    share: null,
    shareTheme: null,
    pagesToDisplay: "",
    sharePagesToDisplay: "",
    devices: null,
    spinTheWheel: [],
  });
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [selectedDeviceType, setSelectedDeviceType] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<any>({
    shareTheme: "",
    mobile: "",
    desktop: "",
  });

  const [wheelTemplates, setWheelTemplates] = useState([]);
  const [wheelConfig, setWheelConfig] = useState<any[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedWheelID, setSelectedWheelID] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showNoScriptModal, setShowNoScriptModal] = useState(false);

  let shop = shopDetailsData;
  let planId = shopDetailsData?.planId;

  useEffect(() => {
    if ([PLAN_OLD_FREE, PLAN_OLD_PRO].includes(Number(planId))) {
      setDefaultValues(
        SpinTheWheelDefaultResponse,
        SpinTheWheelDefaultTemplates
      );
      return;
    }

    if (shop && shop.userId) {
      fetchWheelData(shop.userId);
      fetchWheelTemplatesAndConfig(shop.userId);
    }
  }, []);

  const checkForApproval = () => {
    if (shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.WIDGETS_RESTRICTED)) {
      setShowModal(true);
      return true;
    } else {
      setShowModal(false);
      return false;
    }
  };

  const setDefaultValues = (data: any, templatesData: any) => {
    setisWheelEnabled(data.is_wheel_enabled);
    setSTWData(data);
    setSelectedLanguage(data.language);
    setSelectedWheelID(data.wheelId);

    setWheelTemplates(templatesData.templates);

    const default_template = templatesData.templates[0];
    let blank_template = [];
    blank_template.push({
      text: "",
      code: "",
      color: default_template["color1"],
    });
    blank_template.push({
      text: "",
      code: "",
      color: default_template["color2"],
    });
    blank_template.push({
      text: "",
      code: "",
      color: default_template["color1"],
    });
    blank_template.push({
      text: "",
      code: "",
      color: default_template["color2"],
    });
    setWheelConfig(blank_template);
  };

  useEffect(() => {
    if (hasChanges) {
      setDiscardChanges(false);
    }
  }, [hasChanges]);

  const fetchWheelData = (userId: string) => {
    getWheelDetails(userId).then(
      (data: any) => {
        setisWheelEnabled(data.is_wheel_enabled);
        setSTWData(data);
        setSelectedLanguage(data.language);
        setSelectedWheelID(data.wheelId);
        clevertapEventPush({
          eventId: CT_EVENT_IDS.SPIN_THE_WHEEL_SETTINGS_PAGE_VIEWED,
          eventData: { widgetEnabled: data.is_wheel_enabled },
        });
      },
      (err) => {}
    );
  };

  const fetchWheelTemplatesAndConfig = async (userId: string) => {
    try {
      const templatesData: any = await getWheelTemplates(userId);
      if (templatesData.status === "success") {
        setWheelTemplates(templatesData.templates);
      }

      const configData: any = await getWheelConfig(userId);
      if (configData.status === "success") {
        setWheelConfig(configData.spinWheel);
        if (
          configData.spinWheel &&
          !configData.spinWheel.length &&
          templatesData.templates &&
          templatesData.templates[0]
        ) {
          const default_template = templatesData.templates[0];
          let blank_template = [];
          blank_template.push({
            text: "",
            code: "",
            color: default_template["color1"],
          });
          blank_template.push({
            text: "",
            code: "",
            color: default_template["color2"],
          });
          blank_template.push({
            text: "",
            code: "",
            color: default_template["color1"],
          });
          blank_template.push({
            text: "",
            code: "",
            color: default_template["color2"],
          });
          setWheelConfig(blank_template);
        }
      }
    } catch (error) {
      console.log("fetchWheelTemplatesAndConfig() failed", error);
    }
  };

  const getToggleSwitchShareBtnContent = () => {
    return isWheelEnabled ? "Disable" : "Enable";
  };
  const getToggleSwitchshareTxtContent = () => {
    return isWheelEnabled ? "enabled" : "disabled";
  };
  const getToggleSwitchShareVariationStyle = () => {
    return isWheelEnabled ? "strong" : "negative";
  };
  const enableSpinTheWheel = () => {
    if (!shop.scriptTagAdded) {
      setShowNoScriptModal(true);
      return;
    }
    if (checkForApproval()) {
      return;
    }
    if (
      wheelConfig.length < 4 ||
      (wheelConfig.length >= 4 && wheelConfig.filter((e) => !e.text).length > 0)
    ) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("Please add wheel configuration first");
      return;
    }
    let isWheelEnable = isWheelEnabled ? false : true;
    const params = new URLSearchParams();
    params.append("enabled", isWheelEnable.toString());
    updateWheelDetails(shop.userId, params).then((data: any) => {
      let toastMessage = isWheelEnable ? "Enabled Wheel" : "Disabled Wheel";
      clevertapEventPush({
        eventId: isWheelEnable
          ? CT_EVENT_IDS.SPIN_WHEEL_WIDGET_ENABLED
          : CT_EVENT_IDS.SPIN_WHEEL_WIDGET_DISABLED,
        eventData: {},
      });
      setToast(toastMessage);
      setSTWData(data.spinWheel);
      setisWheelEnabled(isWheelEnable);
      props.refresh();
    });
  };

  const WidgetWheelChanges = (data: any) => {
    if (data && Object.keys(data).length > 0) {
      setHasChanges(true);
      setDataChanges({ ...dataChanges, ...data });
    }
  };

  const devicesChanges = (data: any) => {
    if (Object.keys(data).length > 0) {
      if (Object.keys(data.mobile).length > 0) {
        setDataChanges({
          ...dataChanges,
          devices: {
            ...dataChanges.devices,
            mobile: data.mobile,
          },
        });
        setHasChanges(true);
      }

      if (Object.keys(data.desktop).length > 0) {
        setDataChanges({
          ...dataChanges,
          devices: {
            ...dataChanges.devices,
            desktop: data.desktop,
          },
        });

        setHasChanges(true);
      }
    }
  };

  const sharePagesToDisplayChanges = (data: any) => {
    if (data && data.length > 0) {
      setHasChanges(true);
      setDataChanges({ ...dataChanges, sharePagesToDisplay: data.toString() });
    } else {
      setHasChanges(false);
    }
  };

  const isFormValid = () => {
    // here
    let valid = true,
      message: string = "";
    for (const item of Object.keys(errorMessage)) {
      if (item === "mobile" && selectedDeviceType === DEVICE_TYPE_DESKTOP_ONLY)
        continue;
      if (item === "desktop" && selectedDeviceType === DEVICE_TYPE_MOBILE_ONLY)
        continue;

      if (errorMessage[item]) {
        valid = false;
        message = errorMessage[item];
      }
    }

    return { valid: valid, message: message };
  };

  const updateWheelConfig = () => {
    if (checkForApproval()) {
      return;
    }
    if (
      dataChanges.share &&
      "buttonCta" in dataChanges.share &&
      isNullOrEmpty(dataChanges.share.buttonCta)
    ) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("Wheel button text cannot be empty");
      return;
    }
    if (
      dataChanges.share &&
      "shareMessage" in dataChanges.share &&
      isNullOrEmpty(dataChanges.share.shareMessage)
    ) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("Wheel message text cannot be empty");
      return;
    }

    if (dataChanges.spinTheWheel && dataChanges.spinTheWheel.length < 0) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("Wheel message text cannot be empty");
      return;
    }

    let form = isFormValid();
    if (!form.valid) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast(form.message);
    } else {
      setIsDataSubmitted(false);
      if (dataChanges.spinTheWheel && dataChanges.spinTheWheel.length > 0) {
        let data = "";
        let textLengthError = false;
        let codeLengthError = false;
        let error = false;
        dataChanges.spinTheWheel.forEach((e: any) => {
          if (
            !e.text.trim() ||
            !e.color.trim() ||
            (e.color && !isValidHex(e.color))
          ) {
            error = true;
          }
          if (e?.text?.length > 25) {
            textLengthError = true;
          }
          if (e?.code?.length > 25) {
            codeLengthError = true;
          }
          data += JSON.stringify(e) + ";";
        });
        if (textLengthError) {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast("Invalid Text Length");
          return;
        }
        if (codeLengthError) {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast("Invalid Discount Code Length");
          return;
        }
        if (!error) {
          setWheelConfigData(
            shop.userId,
            qs.stringify({ configuration: data })
          ).then((res) => {
            fetchWheelTemplatesAndConfig(shop.userId);
          });
          updateWheel(selectedWheelID);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast("Invalid wheel config values");
          return;
        }
      }
      if (dataChanges.share) {
        var params: any = new URLSearchParams();
        var customParams = new URLSearchParams();
        var data: any = dataChanges.share;

        Object.keys(data).forEach((key) => {
          switch (key) {
            case "templateId":
              customParams.append(key, data[key]);
              params.append(key, data[key]);
              break;
            case "bgColor1":
            case "bgColor2":
            case "iconColor":
            case "textColor":
            case "solidBg":
              customParams.append(key, data[key]);
              break;
            default:
              params.append(key, data[key]);
              break;
          }
        });
        updateShareButtonDetails(params);
      }

      if (dataChanges.sharePagesToDisplay) {
        const params = new URLSearchParams();
        params.append("wheelPages", dataChanges.sharePagesToDisplay);
        updateWheelDetails(shop.userId, params).then((response: any) => {
          if (response.status === "success") {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.WIDGET_EDITED,
              eventData: {
                "Widget Name": "Spin the wheel",
              },
            });
            clevertapEventPush({
              eventId: CT_EVENT_IDS.WIDGET_EDITED,
              eventData: {
                "Widget Name": "Spin the wheel",
              },
            });
            setShowSuccessToast((successToast: any) => !successToast);
            setToast("Data Saved Successfully");
            setDataChanges({ ...dataChanges, pagesToDisplay: "" });
            setIsDataSubmitted(true);
          } else {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(response);
          }
        });
      }
      switch (selectedDeviceType) {
        case DEVICE_TYPE_MOBILE_AND_DESKTOP:
          updateMobileData();
          updateDesktopData();
          break;
        case DEVICE_TYPE_MOBILE_ONLY:
          updateMobileData();
          deleteDesktopData();
          break;
        case DEVICE_TYPE_DESKTOP_ONLY:
          updateDesktopData();
          deleteMobileData();
          break;
      }

      if (dataChanges.language) {
        const params = new URLSearchParams();
        params.append("language", String(dataChanges.language));
        updateSelectedWheel(shop.userId, params).then((response: any) => {
          if (response.status === "success") {
            clevertapEventPush({
              eventId: CT_EVENT_IDS.WIDGET_EDITED,
              eventData: {
                "Widget Name": "Spin the wheel",
              },
            });
            fetchWheelData(shop.userId);
            console.log("Updated spinWheel message language");
          } else {
            console.log("Failed to update spinWheel message language");
          }
        });
      }

      setHasChanges(false);
    }
  };

  const updateWheel = (selectedWheelID: any) => {
    const params = new URLSearchParams();
    params.append("wheelId", String(selectedWheelID));
    updateSelectedWheel(shop.userId, params).then((response: any) => {
      if (response.status === "success") {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.WIDGET_EDITED,
          eventData: {
            "Widget Name": "Spin the wheel",
          },
        });
        response = response.spinWheel;
        setisWheelEnabled(response.is_wheel_enabled);
        setSTWData(response);
        setSelectedWheelID(response.wheelId);
        console.log("Updated selected spinWheel Template");
      } else {
        console.log("Failed to update selected spinWheel Template");
      }
    });
  };

  const updateShareButtonDetails = (params: any) => {
    updateWheelDetails(shop.userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.WIDGET_EDITED,
            eventData: {
              "Widget Name": "Spin the wheel",
            },
          });
          setShowSuccessToast((successToast: any) => !successToast);
          setToast("Wheel Data Saved");
          setDataChanges({ ...dataChanges, share: null });
          setIsDataSubmitted(true);
          setSTWData({ ...stwData, ...response.share });
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      },
      (err: any) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Something went wrong");
      }
    );
  };
  const updateMobileData = () => {
    if (Object.keys(mobileData).length) {
      const params = new URLSearchParams();
      var mData: any = mobileData;

      Object.keys(mData).forEach((key) => {
        if (key !== "userId") params.append(key, mData[key]);
      });
      updateWheelDevices(shop.userId, params).then((response: any) => {
        if (response.status === "success") {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.WIDGET_EDITED,
            eventData: {
              "Widget Name": "Spin the wheel",
            },
          });
          setShowSuccessToast(true);
          setToast("Data Saved Successfully");

          setDataChanges({
            ...dataChanges,
            devices: { ...dataChanges.devices, mobile: null },
          });
          setIsDataSubmitted(true);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      });
    }
  };

  const deleteMobileData = () => {
    const params = new URLSearchParams();
    params.append("deviceType", "mobile");
    deleteWheelDevices(shop.userId, params).then((response: any) => {
      if (response.status === "success") {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.WIDGET_EDITED,
          eventData: {
            "Widget Name": "Spin the wheel",
          },
        });
        setDataChanges({
          ...dataChanges,
          devices: { ...dataChanges.devices, mobile: null },
        });
      }
    });
  };

  const updateDesktopData = () => {
    if (Object.keys(desktopData).length) {
      const params = new URLSearchParams();
      var dData: any = desktopData;

      Object.keys(dData).forEach((key) => {
        if (key !== "userId") params.append(key, dData[key]);
      });
      updateWheelDevices(shop.userId, params).then((response: any) => {
        if (response.status === "success") {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.WIDGET_EDITED,
            eventData: {
              "Widget Name": "Spin the wheel",
            },
          });
          setShowSuccessToast(true);
          setToast("Data Saved Successfully");

          setDataChanges({
            ...dataChanges,
            devices: { ...dataChanges.devices, desktop: null },
          });
          setIsDataSubmitted(true);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
      });
    }
  };

  const deleteDesktopData = () => {
    const params = new URLSearchParams();
    params.append("deviceType", "desktop");
    deleteWheelDevices(shop.userId, params).then((response: any) => {
      if (response.status === "success") {
        clevertapEventPush({
          eventId: CT_EVENT_IDS.WIDGET_EDITED,
          eventData: {
            "Widget Name": "Spin the wheel",
          },
        });
        setDataChanges({
          ...dataChanges,
          devices: { ...dataChanges.devices, desktop: null },
        });
      }
    });
  };

  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        content: "Save Settings",
        onAction: updateWheelConfig,
      }}
      discardAction={{
        onAction: () => {
          if ([PLAN_OLD_FREE, PLAN_OLD_PRO].includes(Number(planId))) {
            setHasChanges(false);
            setDiscardChanges(true);
            setDefaultValues(
              SpinTheWheelDefaultResponse,
              SpinTheWheelDefaultTemplates
            );
            return;
          }

          setHasChanges(false);
          setDiscardChanges(true);
          fetchWheelData(shop.userId);
          fetchWheelTemplatesAndConfig(shop.userId);
        },
      }}
    />
  ) : null;

  const handleDeviceTypeChange = (type: number, showCTX: boolean) => {
    setSelectedDeviceType(type);
    setHasChanges(showCTX);
  };

  const handleErrorMessage = (message: string, key: string) => {
    let obj: any = errorMessage;
    obj[key] = message;
    setErrorMessage(obj);
  };
  return (
    <React.Fragment>
      {ctxSaveBarMarkup}
      <Layout.AnnotatedSection
        title="Spin The Wheel"
        description="Spin the wheel is designed to ensure high conversions by offering discounts to customers and also getting their WhatsApp optins for further engagement."
      >
        <div className="pt-2">
          <SettingToggle
            action={{
              content: getToggleSwitchShareBtnContent(),
              onAction: enableSpinTheWheel,
            }}
            enabled={isWheelEnabled}
          >
            Spin The Wheel is currently{" "}
            <TextStyle variation={getToggleSwitchShareVariationStyle()}>
              {getToggleSwitchshareTxtContent()}
            </TextStyle>{" "}
            <Link url={"https://" + shop.url} external={true}>
              {" "}
              Click to see it live on your store.
            </Link>
          </SettingToggle>
        </div>
      </Layout.AnnotatedSection>
      <WidgetDesign
        wheelData={stwData}
        wheelTemplates={wheelTemplates}
        wheelConfig={wheelConfig}
        onChanges={WidgetWheelChanges}
        isDataSubmitted={isDataSubmitted}
        reset={discardChanges}
        onError={handleErrorMessage}
        setWheelConfig={setWheelConfig}
        selectedLanguage={selectedLanguage}
        setLanguage={setSelectedLanguage}
        selectedWheelID={selectedWheelID}
        setSelectedWheelID={setSelectedWheelID}
        internalText={stwData.internalText}
      />
      <Devices
        onChanges={devicesChanges}
        onTypeChange={handleDeviceTypeChange}
        isDataSubmitted={isDataSubmitted}
        reset={discardChanges}
        saveFlag={saveFlag}
        setMobileData={setMobileData}
        setDesktopData={setDesktopData}
        onError={handleErrorMessage}
        setHasChanges={setHasChanges}
      />
      <PagesToDisplay
        title="Pages to display"
        isWAEnabled={isWheelEnabled}
        subTitle="Select the pages where you want to show the Spin the wheel."
        footerText="Please note that by default we show the Spin the wheel on all other pages that aren’t listed above."
        errorText="Atleast one page needs to be selected if Spin the wheel is enabled"
        pages={stwData?.wheelPages}
        onFieldChange={sharePagesToDisplayChanges}
        isDataSubmitted={isDataSubmitted}
        reset={discardChanges}
        platform={shop.platform}
      />
      {/* <ApprovalModal
        showModal={showModal}
        setShowModal={setShowModal}
        feature={"Spin The Wheel"}
      /> */}
      <PlansModal
        title={PLANS_MODAL_TITLE.WIDGET_PAGE}
        currentPlanId={planId}
        shop={shop}
        showPricingPlansModal={showModal}
        setShowPricingPlansModal={setShowModal}
      />
      <NoScriptModal
        showModal={showNoScriptModal}
        setShowModal={setShowNoScriptModal}
        wixSiteId={shop.wixSiteId}
      />      
    </React.Fragment>
  );
};
export default ShareSettings;
