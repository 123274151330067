import { COUNTRIES } from './countries';

export const SIGNUP_PAGE = {
  TITLE: 'Sign up to your account',
  SUB_TITLE: 'Start your journey with SuperLemon today!',
  FIELDS: [
    {
      name: 'name',
      label: 'Business Name',
    },
    {
      name: 'website',
      label: 'Business Website',
    },
    {
      name: 'phone',
      label: 'WhatsApp Phone Number (ex: 919999999999)',
    },
    {
      name: 'email',
      label: 'Business Email',
      type: 'email',
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
    },
    {
      name: 'country',
      label: 'Country',
      inputType: 'dropDown',
      options: COUNTRIES,
    },
  ],
  OTP: {
    TITLE: 'OTP',
    SUB_TITLE: 'OTP has been sent to your registered email.',
    FIELDS: [
      {
        name: 'otp',
        label: 'Provide OTP',
      },
    ],
    BUTTON: {
      label: 'Verify OTP',
      className: 'login-button',
    },
  },
  BUTTON: {
    label: 'Sign up',
    className: 'login-button',
  },
  OR: 'or',
  SHOPIFY_LOGIN: {
    BUTTON: 'Sign up with Shopify',
  },
  WIX_LOGIN: {
    BUTTON: 'Sign up with Wix',
  },
  GOOGLE_LOGIN: {
    BUTTON: 'Sign up with Google',
  },
  WHATSAPP_LOGIN: {
    BUTTON: 'Sign up with WhatsApp',
  },
  LOGIN_BTN_TEXT: 'Sign In',
  HAVE_ACC_LABEL: 'Already have an account?',
};

export const PROFILE_PAGE = {
  TITLE: 'Complete your profile',
  SUB_TITLE: 'Start your journey with Superlemon today!',
  FIELDS: [
    {
      name: 'name',
      label: 'Business Name',
    },
    {
      name: 'website',
      label: 'Business Website',
    },
    {
      name: 'phone',
      label: 'WhatsApp Phone Number (ex: 919999999999)',
    },
    {
      name: 'email',
      label: 'Business Email',
      type: 'email',
    },
    {
      name: 'country',
      label: 'Country',
      inputType: 'dropDown',
      options: COUNTRIES,
    },
  ],
  BUTTON: {
    label: 'Save',
    className: 'login-button',
  },
};

export const LOGIN_PAGE = {
  EMAIL_LOGIN: {
    FIELDS: [
      {
        name: 'email',
        label: 'Email',
        type: 'email',
      },
      {
        name: 'password',
        label: 'Password',
        type: 'password',
      },
    ],
    BUTTON: {
      label: 'Sign in',
      className: 'login-button',
    },
    TITLE: 'Sign in to your account',
    SUB_TITLE:
      'Ready to supercharge your conversations? Sign in to SuperLemon to take your messaging to next level',
    EMAIL_LABEL: 'Email',
    PASSWORD_LABEL: 'Password',
    FORGOT_PASSWORD_LINK: 'Forgot Password?',
  },
  FORGOT_PASSWORD: {
    FIELDS: [
      {
        name: 'email',
        label: 'Email',
        type: 'email',
      },
    ],
    BUTTON: {
      label: 'Send',
      className: 'login-button',
    },
    TITLE: 'Forgot password?',
    SUB_TITLE:
      'Enter your email address and we will send you a link to reset your password.',
  },
  RESET_PASSWORD: {
    FIELDS: [
      {
        name: 'newPassword',
        label: 'New password:',
        type: 'password',
      },
      {
        name: 'confirmPassword',
        label: 'Confirm password:',
        type: 'password',
      },
    ],
    BUTTON: {
      label: 'Reset Password',
      className: 'login-button',
    },
    TITLE: 'Set a new password',
    SUB_TITLE: 'Your new password must be different from previous password.',
  },
  OR: 'or',
  SHOPIFY_LOGIN: {
    BUTTON: 'Sign in with Shopify',
  },
  WIX_LOGIN: {
    BUTTON: 'Sign in with Wix',
  },
  GOOGLE_LOGIN: {
    BUTTON: 'Sign in with Google',
  },
  WHATSAPP_LOGIN: {
    BUTTON: 'Sign in with WhatsApp',
  },
  NO_ACCOUNT_LABEL: 'Dont have an account yet?',
  SIGNUP_LINK: 'Sign up here ',
  SHOPIFY_LOGIN_PAGE: {
    TITLE: 'Login with your store',
    BUTTON: 'Login with Shopify',
    PLACEHOLDER: '50kshop.myshopify.com',
    SHOPIFY_APP_STORE: {
      DESCP: 'New to SuperLemon?',
      LABEL: 'Visit Shopify app store',
      LINK: 'https://apps.shopify.com/whatsapp-chat-button',
    },
    WIX_APP_STORE: {
      DESCP: 'New to SuperLemon?',
      LABEL: 'Visit WIX app store',
      LINK: 'https://www.wix.com/app-market',
    },
  },
  WIX_LOGIN_PAGE: {
    TITLE: 'Login with your WIX store',
    BUTTON: 'Login with WIX',
    PLACEHOLDER: 'yourstore.wixsite.com',
  },
};
