// @ts-nocheck
import { useEffect, useState } from "react";
import "./chat.scss";
import ChatNavigator from "./ChatNavigator";
import ChatWindow from "./ChatWindow";
import UserProfile from "./UserProfile";
import { useUsersContext } from "../../../context/usersContext";
import { DAY_SECONDS, SHOP_FEATURES } from "../../../constants/constants";
import WabaNotProcured from "./WabaNotProcured";
import WINudgeScreen from "../NudgeScreen";
import { isSafari, shopFeaturesFromStoreData } from "../../../utils/helper";
import { useShopStore } from '../../../hooks/shopStore';

function Chat(props: any) {
  const {
    currentUser,
    agentPhone,
    toastMarkup,
    errorToastMarkup,
    mobileView,
    mobileType,
    setSearchParams,
  } = useUsersContext();

  const { shopDetailsData } = useShopStore();
  const [showUserInfoPanel, setShowUserInfoPanel] = useState(false);
  const [userChatSelected, setUserChatSelected] = useState(true);
  const [showChatWindow, setShowChatWindow] = useState(true);
  const [showNudgeScreen, setShowNudgeScreen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const currentPlanId = shopDetailsData.planId;
  useEffect(() => {
    if (shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.AGENT_ASSIST)) {
      setShowNudgeScreen(false);
    } else {
      setShowNudgeScreen(true);
    }
  }, [currentPlanId]);

  const getRemainingTime = (sendTimerStatus: boolean, timeStart: any) => {
    if (!timeStart) {
      timeStart = currentUser?.timestamp;
    }
    timeStart = timeStart / 1000;
    const timeNow = Math.round(Date.now() / 1000);
    const timeRemaining = DAY_SECONDS - (timeNow - timeStart);
    return sendTimerStatus ? timeRemaining > 0 : timeRemaining;
  };

  useEffect(() => {
    if (agentPhone) {
      setSearchParams({});
      setShowChatWindow(true);
    } else {
      setShowChatWindow(false);
    }
  }, [agentPhone]);

  const handleCloseUserInfoDetails = () => {
    setShowUserInfoPanel(false);
    setSelectedTab(1);
  };

  const handleOpenUserInfoDetails = () => {
    setShowUserInfoPanel(true);
  };

  return showNudgeScreen ? (
    <WINudgeScreen />
  ) : (
    <div className="chat__globalCss">
      {mobileView.status ? (
        <div
          className={`storeContainer${
            mobileType
              ? ` storeContainer--${mobileType}${isSafari() ? "--safari" : ""}`
              : ""
          }`}
        >
          {toastMarkup}
          {errorToastMarkup}

          {agentPhone && selectedTab === 0 && (
            <ChatNavigator
              setUserChatSelected={setUserChatSelected}
              getRemainingTime={getRemainingTime}
              showChatWindow={showChatWindow}
              setSelectedTab={setSelectedTab}
              setShowChatWindow={setShowChatWindow}
            />
          )}
          {currentUser &&
            (!agentPhone || selectedTab === 1) &&
            (showChatWindow ? (
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <ChatWindow
                  userChatSelected={userChatSelected}
                  handleOpenUserInfoDetails={handleOpenUserInfoDetails}
                  showUserInfoPanel={showUserInfoPanel}
                  getRemainingTime={getRemainingTime}
                  setSelectedTab={setSelectedTab}
                  setShowUserInfoPanel={setShowUserInfoPanel}
                />
                <UserProfile
                  handleCloseUserInfoDetails={handleCloseUserInfoDetails}
                  handleOpenUserInfoDetails={handleOpenUserInfoDetails}
                  showUserInfoPanel={showUserInfoPanel}
                  setSelectedTab={setSelectedTab}
                />
                {showUserInfoPanel && (
                  <div className="chat__chatWindow__Backdrop"></div>
                )}
              </div>
            ) : (
              <WabaNotProcured from="chat" mobileView={mobileView} />
            ))}
        </div>
      ) : (
        <div
          className={`storeContainer ${
            mobileType
              ? ` storeContainer--${mobileType}${isSafari() ? "--safari" : ""}`
              : ""
          }`}
        >
          {toastMarkup}
          {errorToastMarkup}
          {selectedTab === 1 &&
          mobileView.screenWidth > 1025 &&
          mobileView.screenWidth < 1300 &&
          showUserInfoPanel ? null : (
            <ChatNavigator
              setUserChatSelected={setUserChatSelected}
              getRemainingTime={getRemainingTime}
              showChatWindow={showChatWindow}
              setSelectedTab={setSelectedTab}
              setShowChatWindow={setShowChatWindow}
            />
          )}
          {currentUser &&
            (showChatWindow ? (
              <ChatWindow
                userChatSelected={userChatSelected}
                handleOpenUserInfoDetails={handleOpenUserInfoDetails}
                showUserInfoPanel={showUserInfoPanel}
                getRemainingTime={getRemainingTime}
                setShowUserInfoPanel={setShowUserInfoPanel}
                setSelectedTab={setSelectedTab}
              />
            ) : (
              <WabaNotProcured from="chat" mobileView={mobileView} />
            ))}

          <UserProfile
            handleCloseUserInfoDetails={handleCloseUserInfoDetails}
            handleOpenUserInfoDetails={handleOpenUserInfoDetails}
            showUserInfoPanel={showUserInfoPanel}
            setSelectedTab={setSelectedTab}
          />
        </div>
      )}
    </div>
  );
}

export default Chat;
