// @ts-nocheck
import {
  Banner,
  Button,
  Card,
  Checkbox,
  ContextualSaveBar,
  FormLayout,
  Layout,
  Link,
  RadioButton,
  Select,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { Fragment, useEffect, useState } from "react";
import {
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_DUTCH,
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_TURK,
} from "../../constants/constants";
import {
  createWABA,
  saveBusinessDetails,
  saveContactDetails,
  saveFacebookDetails,
  saveProfileDetails,
  sendOTP,
  verifyOTP,
} from "../../redux/actions/private-waba";
import BusinessDetails from "../../types/PrivateWABA/BusinessDetails.type";
import { isNullOrEmpty, objectDifference } from "../../utils/helper";
import {
  getAllLocales,
  getCountryCodes,
} from "../../redux/actions/private-waba";
import LocalesDetails from "../../types/PrivateWABA/LocalesDetails.type";

const ApplicationForm = (props: any) => {
  const {
    setToast,
    setErrorToast,
    setShowErrorToast,
    setShowSuccessToast,
    shop,
  } = props;
  const [initialData, setInitialData] = useState(props.wabaProfile);
  const [wabaProfile, setWabaProfile] = useState(props.wabaProfile);
  const [refWabaProfile, setRefWabaProfile] = useState(props.wabaProfile);
  const [otp, setOTP] = useState<string>();
  const [isApplicationInProgress, setIsApplicationInProgress] = useState(false);
  const [isOTPRequestInProgress, setIsOTPRequestInProgress] = useState(false);
  const [isOTPSubmissionInProgress, setIsOTPSubmissionInProgress] =
    useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [ctxBtnLoading, setCtxBtnLoading] = useState(false);
  const [isBusinessDataUpdated, setisBusinessDataUpdated] = useState(false);
  const [isProfileInfoUpdated, setisProfileInfoUpdated] = useState(false);
  const [isFacebookDetailsUpdated, setisFacebookDetailsUpdated] =
    useState(false);
  const [isContactDetailsUpdated, setisContactDetailsUpdated] = useState(false);
  const [businessDetails, setBusinessDetails] = useState<BusinessDetails>({
    websiteUrl: "",
    entityName: "",
    vertical: "",
    locale: "",
    timezone: "",
    termsAndConditions: "",
  });
  const [facebookDetails, setFacebookDetails] = useState({
    fbManagerId: "",
  });
  const [profileDetails, setProfileDetails] = useState({
    displayName: "",
    countryCode: "",
    number: "",
  });
  const [contactDetails, setContactDetails] = useState({
    email: "",
    contactNo: "",
  });
  const [locales, setLocales] = useState<LocalesDetails[]>([
    { label: "en_US", value: "en_US" },
  ]);
  const [countryCodes, setCountryCodes] = useState<LocalesDetails[]>([
    { label: "United States - 1", value: "1" },
  ]);
  const [otpSms, setOtpSms] = useState(props.otpSms);

  const loadLocales = () => {
    getAllLocales().then(
      (response: any) => {
        if (response.status === "success") {
          const locales = response["locales"].map((el: any) => {
            return {
              label: el.code,
              value: el.code,
              key: el.code,
            };
          });
          setLocales(locales);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const loadCountryCodes = () => {
    getCountryCodes().then(
      (response: any) => {
        if (response.status === "success") {
          const countryCodes = response["locales"].map((el: any) => {
            return {
              label: `${el.displayName} - ${el.dialCode}`,
              value: el.dialCode,
              key: `${el.displayName} - ${el.dialCode}`,
            };
          });
          setCountryCodes(countryCodes);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    loadLocales();
    loadCountryCodes();
    setOtpSms(props.otpSms);
  }, []);

  useEffect(() => {
    setInitialData(props.wabaProfile);
    setRefWabaProfile(props.wabaProfile);
    setWabaProfile(props.wabaProfile);
  }, [props.wabaProfile]);

  useEffect(() => {
    var diffObj: any = objectDifference(refWabaProfile, wabaProfile);
    resetDetails();
    if (Object.keys(diffObj).length !== 0) {
      updateBusinessDetails(diffObj);
      updateFacebookDetails(diffObj);
      updateProfileDetails(diffObj);
      updateContactDetails(diffObj);
    } else {
      resetDetails();
    }
  }, [wabaProfile]);

  const updateBusinessDetails = (diffObj: any) => {
    if ("url" in diffObj) {
      setisBusinessDataUpdated(true);
      setBusinessDetails((details: any) => ({
        ...details,
        websiteUrl: diffObj.url,
      }));
    }
    if ("businessName" in diffObj) {
      setisBusinessDataUpdated(true);
      setBusinessDetails((details: any) => ({
        ...details,
        entityName: diffObj.businessName,
      }));
    }
    if ("businessVertical" in diffObj) {
      setisBusinessDataUpdated(true);
      setBusinessDetails((details: any) => ({
        ...details,
        vertical: diffObj.businessVertical,
      }));
    }
    if ("locale" in diffObj) {
      setisBusinessDataUpdated(true);
      setBusinessDetails((details: any) => ({
        ...details,
        locale: diffObj.locale,
      }));
    }
    if ("timezone" in diffObj) {
      setisBusinessDataUpdated(true);
      setBusinessDetails((details: any) => ({
        ...details,
        timezone: diffObj.timezone,
      }));
    }
    if ("termsAndConditions" in diffObj) {
      setisBusinessDataUpdated(true);
      setBusinessDetails((details: any) => ({
        ...details,
        termsAndConditions: diffObj.termsAndConditions.toString(),
      }));
    }
  };

  const updateFacebookDetails = (diffObj: any) => {
    if ("fbBusinessId" in diffObj) {
      setisFacebookDetailsUpdated(true);
      setFacebookDetails((details: any) => ({
        ...details,
        businessManagerId: diffObj.fbBusinessId,
      }));
    }
  };

  const updateProfileDetails = (diffObj: any) => {
    if ("displayName" in diffObj) {
      setisProfileInfoUpdated(true);
      setProfileDetails((details: any) => ({
        ...details,
        displayName: diffObj.displayName,
      }));
    }
    if ("countryCode" in diffObj) {
      setisProfileInfoUpdated(true);
      setProfileDetails((details: any) => ({
        ...details,
        countryCode: diffObj.countryCode,
      }));
    }
    if ("number" in diffObj) {
      setisProfileInfoUpdated(true);
      setProfileDetails((details: any) => ({
        ...details,
        number: diffObj.number,
      }));
    }
    if ("language" in diffObj) {
      setisProfileInfoUpdated(true);
      setProfileDetails((details: any) => ({
        ...details,
        language: diffObj.language,
      }));
    }
  };

  const updateContactDetails = (diffObj: any) => {
    if ("email" in diffObj) {
      setisContactDetailsUpdated(true);
      setContactDetails((details: any) => ({
        ...details,
        email: diffObj.email,
      }));
    }
    if ("contactNo" in diffObj) {
      setisContactDetailsUpdated(true);
      setContactDetails((details: any) => ({
        ...details,
        contactNo: diffObj.contactNo,
      }));
    }
  };

  const resetDetails = () => {
    setBusinessDetails({
      websiteUrl: "",
      entityName: "",
      vertical: "",
      locale: "",
      timezone: "",
      termsAndConditions: "",
    });
    setFacebookDetails({
      fbManagerId: "",
    });
    setProfileDetails({
      displayName: "",
      countryCode: "",
      number: "",
    });
    setContactDetails({
      email: "",
      contactNo: "",
    });
  };

  const ctxSaveBarMarkup = unsavedChanges ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        onAction: () => saveSettings(),
        loading: ctxBtnLoading,
      }}
      discardAction={{
        onAction: () => {
          resetDetails();
          setWabaProfile(initialData);
          setOtpSms(initialData.otpSms);
          setUnsavedChanges(false);
        },
      }}
    />
  ) : null;

  const handleChange = (val: any, field: any, section?: any) => {
    setWabaProfile((profile: any) => ({ ...profile, [field]: val }));
    setUnsavedChanges(true);
  };

  const isInputFieldDisabled = () => {
    if (
      wabaProfile.whatsappVerificationStatus &&
      wabaProfile.whatsappVerificationStatus !== "NONE"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isDisplayNameDisabled = () => {
    if (
      wabaProfile.whatsappVerificationStatus &&
      wabaProfile.whatsappVerificationStatus !== "NONE" &&
      wabaProfile.whatsappVerificationStatus !== "PN_DN_REJECTED"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isPhoneNumberDisabled = () => {
    if (
      wabaProfile.whatsappVerificationStatus &&
      wabaProfile.whatsappVerificationStatus !== "NONE" &&
      wabaProfile.whatsappVerificationStatus !== "PN_REJECTED"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const stepOneDescription = (
    <TextContainer spacing="loose">
      <p>
        In the first step, you need to share your details about your Facebook
        Business account and your website.
      </p>
      <p>
        Facebook will check your website and approve or reject your application,
        depending on whether your business is compliant to WhatsApp’s terms of
        use
      </p>
      <p>WhatsApp API access is powered by Gupshup.</p>
    </TextContainer>
  );

  const stepTwoDescription = (
    <TextContainer spacing="loose">
      <p>
        In the second step, you need to approve a request from Gupshup in your
        Facebook Business Manager account page.
      </p>
      <p>
        This is necessary to enable Gupshup to provide you with WhatsApp API
        services.
      </p>
    </TextContainer>
  );

  const stepThreeDescription = (
    <TextContainer spacing="loose">
      <p>
        In the final step, after WhatsApp has approved your phone number &
        display name, you need to request and verify an OTP to the phone number.
      </p>
      <p>
        Once the OTP step is complete, automated messages will start getting
        sent from your WhatsApp API approved phone number.
      </p>
    </TextContainer>
  );

  const waBusinessNameGuideliness = (
    <TextContainer>
      Business name should be as per whatsapp guidelines.{" "}
      <Link external={true} url="https://faq.whatsapp.com/en/26000091/">
        Learn more
      </Link>
    </TextContainer>
  );

  const businessVerticalGuidelines = (
    <TextContainer>
      View policy on business verticals{" "}
      <Link
        external={true}
        url="https://www.whatsapp.com/legal/commerce-policy/"
      >
        allowed by WhatsApp
      </Link>
    </TextContainer>
  );

  const businessVerticalOptions = [
    { label: "Automotive", value: "Automotive" },
    { label: "Beauty, Spa and Salon", value: "Beauty, Spa and Salon" },
    { label: "Clothing and Apparel", value: "Clothing and Apparel" },
    { label: "Education", value: "Education" },
    { label: "Entertainment", value: "Entertainment" },
    {
      label: "Event Planning and Service",
      value: "Event Planning and Service",
    },
    { label: "Finance and Banking", value: "Finance and Banking" },
    { label: "Food and Grocery", value: "Food and Grocery" },
    { label: "Public Service", value: "Public Service" },
    { label: "Hotel and Lodging", value: "Hotel and Lodging" },
    { label: "Medical and Health", value: "lMedical and Health" },
    { label: "Non-profit", value: "Non-profit" },
    { label: "Professional Services", value: "Professional Services" },
    { label: "Shopping and Retail", value: "Shopping and Retail" },
    { label: "Travel and Transportation", value: "Travel and Transportation" },
    { label: "Restaurant", value: "Restaurant" },
    { label: "Other", value: "Other" },
  ];

  const getTimeZoneOptions = () => {
    var timezone_options = [
      { label: "America/Bogota", value: "43" },
      { label: "America/Los Angeles", value: "1" },
      { label: "America/Mazatlan", value: "93" },
      { label: "Asia/Manila", value: "104" },
      { label: "Europe/Copenhagen", value: "48" },
      { label: "Africa/Lagos", value: "96" },
      { label: "Pacific/Easter", value: "40" },
      { label: "America/Guatemala", value: "61" },
      { label: "Australia/Perth", value: "13" },
      { label: "Africa/Casablanca", value: "86" },
      { label: "Europe/Luxembourg", value: "84" },
      { label: "America/Costa Rica", value: "44" },
      { label: "Asia/Ho Chi Minh", value: "140" },
      { label: "Asia/Shanghai", value: "42" },
      { label: "Atlantic/Azores", value: "109" },
      { label: "Asia/Gaza", value: "108" },
      { label: "Asia/Nicosia", value: "45" },
      { label: "America/Rainy River", value: "31" },
      { label: "Pacific/Auckland", value: "100" },
      { label: "Europe/Budapest", value: "65" },
      { label: "America/Argentina Buenos Aires", value: "10" },
      { label: "America/New York", value: "7" },
      { label: "America/Anchorage", value: "4" },
      { label: "Asia/Beirut", value: "81" },
      { label: "America/Managua", value: "97" },
      { label: "America/Edmonton", value: "30" },
      { label: "Europe/Athens", value: "60" },
      { label: "Asia/Bahrain", value: "20" },
      { label: "Asia/Muscat", value: "101" },
      { label: "America/Belem", value: "24" },
      { label: "Asia/Kamchatka", value: "125" },
      { label: "Asia/Jerusalem", value: "70" },
      { label: "Atlantic/Reykjavik", value: "73" },
      { label: "Asia/Magadan", value: "124" },
      { label: "America/Toronto", value: "35" },
      { label: "America/Blanc Sablon", value: "36" },
      { label: "Europe/Riga", value: "85" },
      { label: "Africa/Tunis", value: "133" },
      { label: "Asia/Seoul", value: "79" },
      { label: "Europe/Dublin", value: "69" },
      { label: "Indian/Maldives", value: "90" },
      { label: "Europe/Kiev", value: "137" },
      { label: "America/Panama", value: "102" },
      { label: "Atlantic/Canary", value: "54" },
      { label: "America/Port Of Spain", value: "135" },
      { label: "America/Nassau", value: "26" },
      { label: "Asia/Singapore", value: "128" },
      { label: "America/Santiago", value: "41" },
      { label: "America/La Paz", value: "21" },
      { label: "Australia/Broken Hill", value: "14" },
      { label: "America/Tijuana", value: "91" },
      { label: "Pacific/Honolulu", value: "3" },
      { label: "Europe/Lisbon", value: "110" },
      { label: "America/Asuncion", value: "111" },
      { label: "Asia/Krasnoyarsk", value: "120" },
      { label: "America/Hermosillo", value: "92" },
      { label: "America/Phoenix", value: "5" },
      { label: "Asia/Colombo", value: "82" },
      { label: "America/Dawson", value: "27" },
      { label: "Asia/Taipei", value: "136" },
      { label: "Asia/Amman", value: "76" },
      { label: "America/Chicago", value: "6" },
      { label: "Europe/Madrid", value: "55" },
      { label: "Europe/Vienna", value: "12" },
      { label: "Asia/Irkutsk", value: "121" },
      { label: "Europe/Amsterdam", value: "98" },
      { label: "Asia/Kuala Lumpur", value: "95" },
      { label: "Europe/Sofia", value: "19" },
      { label: "America/Montevideo", value: "138" },
      { label: "America/Denver", value: "2" },
      { label: "Europe/Samara", value: "117" },
      { label: "Europe/Brussels", value: "18" },
      { label: "Pacific/Galapagos", value: "50" },
      { label: "America/Mexico City", value: "94" },
      { label: "America/Puerto Rico", value: "107" },
      { label: "Europe/Paris", value: "57" },
      { label: "Europe/Bratislava", value: "130" },
      { label: "Europe/Tallinn", value: "52" },
      { label: "America/Noronha", value: "22" },
      { label: "America/El Salvador", value: "131" },
      { label: "America/Iqaluit", value: "34" },
      { label: "America/Campo Grande", value: "23" },
      { label: "Asia/Qatar", value: "112" },
      { label: "Asia/Kuwait", value: "80" },
      { label: "Africa/Nairobi", value: "78" },
      { label: "America/Lima", value: "103" },
      { label: "Europe/Zagreb", value: "64" },
      { label: "America/Santo Domingo", value: "49" },
      { label: "Europe/Ljubljana", value: "129" },
      { label: "Europe/Oslo", value: "99" },
      { label: "Africa/Johannesburg", value: "141" },
      { label: "America/Argentina Salta", value: "11" },
      { label: "Asia/Baghdad", value: "72" },
      { label: "Asia/Riyadh", value: "126" },
      { label: "Asia/Tokyo", value: "77" },
      { label: "Asia/Yekaterinburg", value: "118" },
      { label: "America/Caracas", value: "139" },
      { label: "Europe/Warsaw", value: "106" },
      { label: "Asia/Kolkata", value: "71" },
      { label: "Europe/Belgrade", value: "114" },
      { label: "Asia/Dhaka", value: "17" },
      { label: "Europe/Kaliningrad", value: "115" },
      { label: "Europe/Prague", value: "46" },
      { label: "America/Regina", value: "32" },
      { label: "Asia/Yakutsk", value: "122" },
      { label: "America/Jamaica", value: "75" },
      { label: "Europe/Rome", value: "74" },
      { label: "Europe/Zurich", value: "39" },
      { label: "Europe/Berlin", value: "47" },
      { label: "Australia/Sydney", value: "15" },
      { label: "Asia/Vladivostok", value: "123" },
      { label: "Asia/Bangkok", value: "132" },
      { label: "Asia/Makassar", value: "67" },
      { label: "Europe/Stockholm", value: "127" },
      { label: "Europe/Moscow", value: "116" },
      { label: "Indian/Mauritius", value: "89" },
      { label: "America/Sao Paulo", value: "25" },
      { label: "Europe/Istanbul", value: "134" },
      { label: "America/Guayaquil", value: "51" },
      { label: "Europe/Bucharest", value: "113" },
      { label: "America/Halifax", value: "37" },
      { label: "Europe/Malta", value: "88" },
      { label: "Asia/Jakarta", value: "66" },
      { label: "Asia/Omsk", value: "119" },
      { label: "Asia/Jayapura", value: "68" },
      { label: "Europe/Helsinki", value: "56" },
      { label: "Europe/Vilnius", value: "83" },
      { label: "Europe/London", value: "58" },
      { label: "Africa/Cairo", value: "53" },
      { label: "Europe/Skopje", value: "87" },
      { label: "Asia/Hong Kong", value: "62" },
      { label: "America/Dawson Creek", value: "29" },
      { label: "Africa/Accra", value: "59" },
      { label: "America/Tegucigalpa", value: "63" },
      { label: "America/Argentina San Luis", value: "9" },
      { label: "Asia/Dubai", value: "8" },
      { label: "Europe/Sarajevo", value: "16" },
      { label: "Asia/Karachi", value: "105" },
      { label: "America/Atikokan", value: "33" },
      { label: "America/St Johns", value: "38" },
      { label: "America/Vancouver", value: "28" },
    ];

    timezone_options.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );
    return timezone_options;
  };

  const businessDetailsCardSection = (
    <Card.Section title="Business details">
      <FormLayout>
        <FormLayout.Group>
          <TextField
            label="Website URL"
            value={wabaProfile.url}
            onChange={(val) => handleChange(val, "url", "business")}
            disabled={isInputFieldDisabled()}
            placeholder={"https://www.superlemon.xyz"}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            label="Business Entity Name"
            value={wabaProfile.businessName}
            onChange={(val) => handleChange(val, "businessName", "business")}
            helpText={waBusinessNameGuideliness}
            disabled={isInputFieldDisabled()}
            placeholder={"SuperLemon Apps private limited"}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <Select
            label="Business Vertical"
            value={wabaProfile.businessVertical}
            onChange={(val) =>
              handleChange(val, "businessVertical", "business")
            }
            options={businessVerticalOptions}
            helpText={businessVerticalGuidelines}
            disabled={isInputFieldDisabled()}
            placeholder={"Select..."}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <Select
            label="Locale"
            value={wabaProfile.locale}
            options={locales}
            onChange={(val) => handleChange(val, "locale", "business")}
            disabled={isInputFieldDisabled()}
            placeholder={"Select..."}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <Select
            label="Timezone"
            value={wabaProfile.timezone}
            onChange={(val) => handleChange(val, "timezone", "business")}
            options={getTimeZoneOptions()}
            disabled={isInputFieldDisabled()}
            placeholder={"Select..."}
          />
        </FormLayout.Group>
      </FormLayout>
    </Card.Section>
  );

  const fbBusinessIdHelpText = (
    <TextContainer>
      Find your FB business manager ID{" "}
      <Link external={true} url="https://business.facebook.com/settings/info">
        here
      </Link>
    </TextContainer>
  );

  const fbBusinessDetailsCardSection = (
    <Card.Section title="Facebook business details">
      <FormLayout>
        <FormLayout.Group>
          <div style={{ marginTop: "1rem" }}>
            <TextField
              label="Facebook Business Manager ID"
              value={wabaProfile.fbBusinessId}
              onChange={(val) => handleChange(val, "fbBusinessId", "facebook")}
              disabled={isInputFieldDisabled()}
              placeholder={"1123432535435"}
              helpText={fbBusinessIdHelpText}
            />
          </div>
        </FormLayout.Group>
      </FormLayout>
    </Card.Section>
  );

  const displayNameHelpText = (
    <TextContainer>
      Display name must adhere to{" "}
      <Link
        external={true}
        url="https://developers.facebook.com/docs/whatsapp/guides/display-name/"
      >
        Facebook guidelines
      </Link>
    </TextContainer>
  );

  const waNumberHelpText = (
    <TextContainer>
      <TextStyle variation="subdued">Note</TextStyle>: This is the phone number
      that will be associated with your business. All automated messages will be
      sent using this phone number. Phone number should not be previously
      registered with WhatsApp. 1800 phone numbers are not allowed. Please read
      the{" "}
      <Link
        external={true}
        url="https://developers.facebook.com/docs/whatsapp/guides/phone-number/"
      >
        Facebook guidelines
      </Link>
    </TextContainer>
  );

  const templateLanguageGuidelines = (
    <TextContainer>
      Message templates in the language of your choice will automatically be
      applied for your WhatsApp API account. In a future app update, you will be
      able to create and apply for custom message templates.
    </TextContainer>
  );

  const templateLanguageOptions = [
    { label: "English", value: TEMPLATE_LANGUAGE_ENG },
    { label: "Portuguese", value: TEMPLATE_LANGUAGE_PORT },
    { label: "Spanish", value: TEMPLATE_LANGUAGE_SPAN },
    { label: "Italian", value: TEMPLATE_LANGUAGE_ITAL },
    { label: "French", value: TEMPLATE_LANGUAGE_FREN },
    { label: "Indonesian", value: TEMPLATE_LANGUAGE_INDO },
    { label: "German", value: TEMPLATE_LANGUAGE_GERM },
    { label: "Arabic", value: TEMPLATE_LANGUAGE_ARAB },
    { label: "Turkish", value: TEMPLATE_LANGUAGE_TURK },
    { label: "Hebrew", value: TEMPLATE_LANGUAGE_HEBR },
    { label: "Dutch", value: TEMPLATE_LANGUAGE_DUTCH },
  ];

  const validatePhoneNo = () => {
    var phoneno = /^(?:[0-9]\d{0,14})$/;
    if (!wabaProfile.number || wabaProfile.number.match(phoneno)) {
      return false;
    } else {
      return "Enter a valid phone number.";
    }
  };

  const validateCountryCode = () => {
    var cc = /^(?:[1-9]\d{0,5})$/;
    if (!wabaProfile.countryCode || wabaProfile.countryCode.match(cc)) {
      return false;
    } else if (wabaProfile.countryCode && wabaProfile.countryCode[0] === "0") {
      return "Cannot start with 0";
    } else {
      return "Enter a valid country code";
    }
  };

  const isCountryCodeDisabled = () => {
    if (
      wabaProfile.whatsappVerificationStatus &&
      wabaProfile.whatsappVerificationStatus !== "NONE" &&
      wabaProfile.whatsappVerificationStatus !== "PN_REJECTED"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const waProfileDetailsCardSection = (
    <Card.Section title="Whatsapp profile details">
      <FormLayout>
        <FormLayout.Group>
          <div style={{ marginTop: "1rem" }}>
            <TextField
              label="Display Name of WhatsApp profile"
              value={wabaProfile.displayName}
              onChange={(val) => handleChange(val, "displayName", "profile")}
              disabled={isDisplayNameDisabled()}
              placeholder={"SuperLemon Ecommerce Bot"}
              helpText={displayNameHelpText}
            />
          </div>
        </FormLayout.Group>
        <FormLayout.Group>
          <Select
            label="Country Code"
            value={wabaProfile.countryCode}
            options={countryCodes}
            onChange={(val) => handleChange(val, "countryCode", "profile")}
            disabled={isCountryCodeDisabled()}
            placeholder={"Select..."}
          />
          <TextField
            label={<TextContainer>Phone Number for WhatsApp API</TextContainer>}
            value={wabaProfile.number}
            onChange={(val) => handleChange(val, "number", "profile")}
            disabled={isPhoneNumberDisabled()}
            placeholder={"Example: 9058195883"}
            error={validatePhoneNo()}
          />
        </FormLayout.Group>
        <TextStyle variation="subdued">{waNumberHelpText}</TextStyle>
        <FormLayout.Group>
          <Select
            label="Automated message templates language"
            value={wabaProfile.language ? wabaProfile.language : ""}
            onChange={(val) => handleChange(val, "language", "profile")}
            options={templateLanguageOptions}
            helpText={templateLanguageGuidelines}
            disabled={isInputFieldDisabled()}
            placeholder={"Select..."}
          />
        </FormLayout.Group>
      </FormLayout>
    </Card.Section>
  );

  const contactDetailsCardSection = (
    <Card.Section title="Contact details">
      <FormLayout>
        <FormLayout.Group>
          <div style={{ marginTop: "1rem" }}>
            <TextContainer>
              This is not related to your WhatsApp API application. This
              information is for us to send you updates or contact you here in
              case there is any problem with your WhatsApp API application.
            </TextContainer>
          </div>
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            label="Contact Email Address"
            value={wabaProfile.email}
            onChange={(val) => handleChange(val, "email", "contact")}
            placeholder={"name@company.com"}
            disabled={false}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            label="Contact Phone Number"
            value={wabaProfile.contactNo}
            onChange={(val) => handleChange(val, "contactNo", "contact")}
            placeholder={"+919058195883"}
            disabled={false}
          />
        </FormLayout.Group>
      </FormLayout>
    </Card.Section>
  );

  const agreeToTermsAndConditionsLabel = (
    <TextContainer>
      Tick here to state that you have read and agree to our Terms and
      Conditions and Privacy Policy of the services
    </TextContainer>
  );

  const canApplyForApproval = () => {
    if (
      isNullOrEmpty(wabaProfile.whatsappVerificationStatus) ||
      wabaProfile.whatsappVerificationStatus === "NONE"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateApplicationForm = (isCtxSave: boolean = false) => {
    var mandatory_fields = [
      { field_name: "url", error_msg: "Website URL cannot be empty" },
      {
        field_name: "businessName",
        error_msg: "Business Entity name cannot be empty",
      },
      {
        field_name: "businessVertical",
        error_msg: "Business vertical cannot be empty",
      },
      { field_name: "locale", error_msg: "Locale cannot be empty" },
      { field_name: "timezone", error_msg: "Timezone cannot be empty" },
      {
        field_name: "fbBusinessId",
        error_msg: "Facebook business ID cannot be empty",
      },
      {
        field_name: "language",
        error_msg: "Template language cannot be empty",
      },
      {
        field_name: "number",
        error_msg: "WhatsApp Phone number cannot be empty",
      },
      { field_name: "countryCode", error_msg: "Country code cannot be empty" },
      {
        field_name: "displayName",
        error_msg: "WhatsApp Display name cannot be empty",
      },
      { field_name: "email", error_msg: "Contact email cannot be empty" },
      {
        field_name: "contactNo",
        error_msg: "Contact phone number cannot be empty",
      },
    ];

    if (!isCtxSave) {
      mandatory_fields.push({
        field_name: "termsAndConditions",
        error_msg: "Please accept terms and conditions before you proceed",
      });
    }

    for (var i = 0; i < mandatory_fields.length; i++) {
      if (!wabaProfile[mandatory_fields[i].field_name]) {
        return mandatory_fields[i].error_msg;
      }
    }

    let error = validateCountryCode() || validatePhoneNo();
    if (error) {
      return error;
    }
    return null;
  };

  const applyForApproval = () => {
    var validationError = validateApplicationForm();
    if (validationError) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast(validationError);
      return;
    }

    setIsApplicationInProgress(true);
    setShowSuccessToast((successToast: any) => !successToast);
    setToast(
      "This process could take 15-20 seconds. Please do not refresh or close the page"
    );
    let params = new URLSearchParams(wabaProfile);
    createWABA(props.shop.userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          setWabaProfile(response.waba);
          setShowSuccessToast((successToast: any) => !successToast);
          setToast("Application form submitted for approval");
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
        setIsApplicationInProgress(false);
      },
      (err) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Something went wrong. Unable to create WABA.");
        setIsApplicationInProgress(false);
      }
    );
  };

  const getWABAStatusBanner = () => {
    if (
      wabaProfile.whatsappVerificationStatus === "WABA_WAITING_FOR_APPROVAL" ||
      wabaProfile.whatsappVerificationStatus === "WABA_CREATION_REQUESTED" ||
      wabaProfile.whatsappVerificationStatus === "PN_DN_WAITING_FOR_APPROVAL"
    ) {
      return (
        <Banner title="Your request has been submitted to WhatsApp">
          Business approval may take upto 7 days. We will send updates on your
          contact email address.
        </Banner>
      );
    } else if (wabaProfile.whatsappVerificationStatus === "PN_DN_APPROVED") {
      return (
        <Banner
          status="success"
          title="Your request has been approved by WhatsApp"
        >
          You can now proceed to Step 3 for verification of your WhatsApp phone
          number.
        </Banner>
      );
    } else if (wabaProfile.whatsappVerificationStatus === "WABA_REJECTED") {
      return (
        <Banner
          status="warning"
          title="Your request has been rejected by WhatsApp"
        >
          Your business does not fully comply with WhatsApp’s terms of use. If
          you need help, contact support.
        </Banner>
      );
    } else if (wabaProfile.whatsappVerificationStatus === "PN_REJECTED") {
      return (
        <Banner
          status="warning"
          title="Your request has been rejected by WhatsApp"
        >
          Your request has been rejected by WhatsApp Your Phone Number does not
          fully comply with
          <Link
            external={true}
            url="https://developers.facebook.com/docs/whatsapp/guides/phone-number/"
          >
            {" "}
            WhatsApp’s policies
          </Link>
          . Please update your Phone Number and apply for approval again. If you
          need help,
          <Link
            external={true}
            url={
              "mailto:support@superlemon.xyz?subject=I%20need%20help%20with%20WhatsApp%20chat%20—%20" +
              props.shop.url
            }
          >
            {" "}
            contact support
          </Link>
          .
        </Banner>
      );
    } else if (wabaProfile.whatsappVerificationStatus === "PN_DN_REJECTED") {
      return (
        <Banner
          status="warning"
          title="Your request has been rejected by WhatsApp"
        >
          Your request has been rejected by WhatsApp Your Display Name does not
          fully comply with
          <Link
            external={true}
            url="https://developers.facebook.com/docs/whatsapp/guides/display-name/"
          >
            {" "}
            WhatsApp’s policies
          </Link>
          . Please update your Display Name and apply for approval again. If you
          need help,
          <Link
            external={true}
            url={
              "mailto:support@superlemon.xyz?subject=I%20need%20help%20with%20WhatsApp%20chat%20—%20" +
              props.shop.url
            }
          >
            {" "}
            contact support
          </Link>
          .
        </Banner>
      );
    }
  };

  const finalCardSection = (
    <Card.Section>
      <Stack vertical>
        <Checkbox
          checked={wabaProfile.termsAndConditions}
          label={agreeToTermsAndConditionsLabel}
          disabled={isInputFieldDisabled()}
          onChange={(val) => handleChange(val, "termsAndConditions")}
        />
        <Button
          primary
          disabled={!canApplyForApproval()}
          onClick={() => applyForApproval()}
          loading={isApplicationInProgress}
        >
          Apply for Approval
        </Button>
        {getWABAStatusBanner()}
      </Stack>
    </Card.Section>
  );

  const fbAccessToGupshupStatusBanner =
    wabaProfile.accessToGupshup || wabaProfile.live ? (
      <Banner title="Status: Complete" status="success">
        You have approved the request by Gupshup in your Facebook Business
        Manager account.
      </Banner>
    ) : (
      <Banner title="Status: Pending">
        Open your Facebook Business Manager account and approve the request from
        Gupshup to complete Step 2.
      </Banner>
    );

  const fbAccessToGupshupStatusCardSection = (
    <Card.Section>
      <Stack vertical>
        <TextContainer>
          Open your Facebook Business Manager account page and approve the
          request from Gupshup. The request arrives 10-15 minutes after you
          click “Apply for approval” in Step 1.
        </TextContainer>
        {fbAccessToGupshupStatusBanner}
      </Stack>
    </Card.Section>
  );

  const fbBusinessManagerVerificationCardSection = (
    <Card.Section>
      <TextContainer>
        <b>Note</b>: Please ensure that your Facebook Business Manager is
        verified. This is a independent verification done by Facebook. You can
        get it verified by{" "}
        <Link
          external={true}
          url="https://www.facebook.com/business/help/2058515294227817"
        >
          following these steps
        </Link>
      </TextContainer>
    </Card.Section>
  );

  const otpVerificationCompletedBanner =
    wabaProfile.pipeLineStage === "FINALIZE" ? (
      <Card.Section>
        <Banner title="OTP verified successfully" status="success">
          Automated messages will now be sent from your WhatsApp API approved
          phone number.
        </Banner>
      </Card.Section>
    ) : null;

  const otpVeritificationDisclaimerCardSection =
    wabaProfile.pipeLineStage !== "FINALIZE" && !wabaProfile.otpSubmitted ? (
      <Card.Section>
        <TextContainer>
          Please ensure that you have the ability to view incoming calls and/or
          SMS on the phone number provided for WhatsApp API before you request
          for the OTP / verification code.
        </TextContainer>
      </Card.Section>
    ) : null;

  const setOtpViaSms = (val: boolean) => {
    setOtpSms(val);
  };

  const isWABAApproved = () => {
    if (
      wabaProfile.whatsappVerificationStatus === "WHATSAPP_VERIFICATION_DONE" ||
      wabaProfile.whatsappVerificationStatus === "PN_DN_APPROVED"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isOTPVerificationDone = () => {
    if (wabaProfile.pipeLineStage === "FINALIZE") {
      return true;
    } else {
      return false;
    }
  };

  const isPipeLineStatusUnknown = () => {
    if (!isNullOrEmpty(wabaProfile.pipeLineStage)) {
      switch (wabaProfile.pipeLineStage) {
        case "FINALIZE":
        case "VERIFY_OTP":
        case "REGISTER_PHONE":
        case "NONE":
        case "CREATE_BUSINESS":
          return false;
        default:
          return true;
      }
    }
    return false;
  };

  const requestOTP = () => {
    setIsOTPRequestInProgress(true);
    let params = new URLSearchParams();
    params.append("viaSms", otpSms);
    sendOTP(props.shop.userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          var waba_profile = wabaProfile;
          waba_profile.pipeLineStage = "SPLMN_OTP_REQUESTED";
          setWabaProfile(waba_profile);
          setOtpSms(waba_profile.otpSms);
          setShowSuccessToast((successToast: any) => !successToast);
          setToast("OTP requested");
          props.pollWABAStatus(props.shop.userId);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
        setIsOTPRequestInProgress(false);
      },
      (err) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Something went wrong. Unable to send OTP.");
        setIsOTPRequestInProgress(false);
      }
    );
  };

  const saveSettings = async () => {
    var validationError = validateApplicationForm(true);
    if (validationError) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast(validationError);
      return;
    } else {
      setCtxBtnLoading(true);
      if (businessDetails && isBusinessDataUpdated) {
        var bParams = new URLSearchParams();
        Object.keys(businessDetails).forEach((key) => {
          //@ts-ignore
          if (businessDetails[key]) {
            //@ts-ignore
            bParams.append(key, businessDetails[key]);
          } else {
            bParams.append(key, "");
          }
        });
        await saveBusinessDetails(shop.userId, bParams).then(
          (response: any) => {
            if (response.status === "success") {
              let waba = wabaProfile;
              waba.businessName = response.waba.businessName;
              waba.businessVertical = response.waba.businessVertical;
              waba.locale = response.waba.locale;
              waba.timezone = response.waba.timezone.toString();
              waba.url = response.waba.url;
              setRefWabaProfile(waba);
              setisBusinessDataUpdated(false);
              props.setWabaProfile(waba);
            } else {
              setShowErrorToast((errorToast: any) => !errorToast);
              setErrorToast(response);
            }
            setUnsavedChanges(false);
            setCtxBtnLoading(false);
          },
          (err) => {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(
              "Something went wrong. Unable to save business details."
            );
            setUnsavedChanges(false);
            setCtxBtnLoading(false);
          }
        );
      }

      if (facebookDetails && isFacebookDetailsUpdated) {
        var fParams = new URLSearchParams();
        //@ts-ignore
        if (facebookDetails["businessManagerId"]) {
          //@ts-ignore
          fParams.append(
            "businessManagerId",
            facebookDetails["businessManagerId"]
          );
        } else {
          fParams.append("businessManagerId", "");
        }
        await saveFacebookDetails(shop.userId, fParams).then(
          (response: any) => {
            if (response.status === "success") {
              let waba = wabaProfile;
              waba.fbBusinessId = response.waba.fbBusinessId;
              setRefWabaProfile(waba);
              setisFacebookDetailsUpdated(false);
              props.setWabaProfile(waba);
            } else {
              setShowErrorToast((errorToast: any) => !errorToast);
              setErrorToast(response);
            }
            setUnsavedChanges(false);
            setCtxBtnLoading(false);
          },
          (err) => {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(
              "Something went wrong. Unable to save Facebook details."
            );
            setUnsavedChanges(false);
            setCtxBtnLoading(false);
          }
        );
      }

      if (profileDetails && isProfileInfoUpdated) {
        var pParams = new URLSearchParams();
        Object.keys(profileDetails).forEach((key) => {
          //@ts-ignore
          if (profileDetails[key]) {
            //@ts-ignore
            pParams.append(key, profileDetails[key]);
          } else {
            pParams.append(key, "");
          }
        });
        await saveProfileDetails(shop.userId, pParams).then(
          (response: any) => {
            if (response.status === "success") {
              let waba = wabaProfile;
              waba.displayName = response.waba.displayName;
              waba.countryCode = response.waba.countryCode;
              waba.number = response.waba.number;
              setRefWabaProfile(waba);
              setisProfileInfoUpdated(false);
              props.setWabaProfile(waba);
            } else {
              setShowErrorToast((errorToast: any) => !errorToast);
              setErrorToast(response);
            }
            setUnsavedChanges(false);
            setCtxBtnLoading(false);
          },
          (err) => {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(
              "Something went wrong. Unable to save profile details."
            );
            setUnsavedChanges(false);
            setCtxBtnLoading(false);
          }
        );
      }

      if (contactDetails && isContactDetailsUpdated) {
        var cParams = new URLSearchParams();
        Object.keys(contactDetails).forEach((key) => {
          //@ts-ignore
          if (contactDetails[key]) {
            //@ts-ignore
            cParams.append(key, contactDetails[key]);
          } else {
            cParams.append(key, "");
          }
        });
        await saveContactDetails(shop.userId, cParams).then(
          (response: any) => {
            if (response.status === "success") {
              let waba = wabaProfile;
              waba.contactNo = response.waba.contactNo;
              waba.email = response.waba.email;
              setRefWabaProfile(waba);
              setisContactDetailsUpdated(false);
              props.setWabaProfile(waba);
            } else {
              setShowErrorToast((errorToast: any) => !errorToast);
              setErrorToast(response);
            }
            setUnsavedChanges(false);
            setCtxBtnLoading(false);
          },
          (err) => {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(
              "Something went wrong. Unable to save contact details."
            );
            setUnsavedChanges(false);
            setCtxBtnLoading(false);
          }
        );
      }
    }
  };

  const getRequestOTPButtonContent = () => {
    if (wabaProfile.pipeLineStage === "VERIFY_OTP") {
      return "Resend OTP / verification code";
    } else {
      return "Request OTP / verification code";
    }
  };

  const getRequestOTPBannerForUnknownState = () => {
    if (isPipeLineStatusUnknown()) {
      return (
        <Banner title="Request in progress">
          Your request has been received. Please wait for a few minutes while we
          generate a verification code. If you do not receive a verification
          code within 5 minutes, please contact support.
        </Banner>
      );
    } else {
      return null;
    }
  };

  const otpVeritificationOptionCardSection =
    wabaProfile.pipeLineStage !== "FINALIZE" && !wabaProfile.otpSubmitted ? (
      <Card.Section>
        <Stack vertical>
          <TextContainer>Receive OTP / verification code via</TextContainer>
          <RadioButton
            label="SMS"
            checked={otpSms === true}
            id="sms"
            name="otp-verification-options"
            onChange={() => setOtpViaSms(true)}
            disabled={!isWABAApproved() || isOTPVerificationDone()}
          />
          <RadioButton
            label="IVR call"
            id="ivr"
            name="otp-verification-options"
            checked={otpSms === false}
            onChange={() => setOtpViaSms(false)}
            disabled={!isWABAApproved() || isOTPVerificationDone()}
          />
          <Button
            primary
            disabled={
              !isWABAApproved() ||
              isOTPVerificationDone() ||
              isPipeLineStatusUnknown()
            }
            loading={isOTPRequestInProgress}
            onClick={() => requestOTP()}
          >
            {getRequestOTPButtonContent()}
          </Button>
          {getRequestOTPBannerForUnknownState()}
        </Stack>
      </Card.Section>
    ) : null;

  const getSubmitOTPContent = () => {
    if (isPipeLineStatusUnknown() && wabaProfile.otpSubmitted) {
      return (
        <Card.Section>
          <Banner title="Waiting for WhatsApp..." status="info">
            Your request has been received. Please wait for a few seconds while
            we verify your details. If you do not see a confirmation here within
            15 seconds, please refresh the page.
          </Banner>
        </Card.Section>
      );
    } else if (wabaProfile.pipeLineStage === "FINALIZE") {
      return null;
    } else {
      return (
        <Card.Section>
          <Stack vertical>
            <TextField
              label="Enter OTP/Verification code"
              onChange={(value: string) => {
                setOTP(value);
              }}
              value={otp}
              helpText="Please enter the verification code that you receive over SMS/IVR"
              disabled={!isWABAApproved() || isOTPVerificationDone()}
            />
            <Button
              primary
              disabled={!isWABAApproved() || isOTPVerificationDone()}
              onClick={() => submitOTP()}
              loading={isOTPSubmissionInProgress}
            >
              Submit
            </Button>
          </Stack>
        </Card.Section>
      );
    }
  };

  const submitOTP = () => {
    if (!otp) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("Please enter the OTP before submitting");
      return;
    }
    setIsOTPSubmissionInProgress(true);
    let params = new URLSearchParams();
    params.append("otp", otp);
    verifyOTP(props.shop.userId, params).then(
      (response: any) => {
        if (response.status === "success") {
          var waba_profile = wabaProfile;
          waba_profile.pipeLineStage = "SPLMN_OTP_SUBMITTED";
          waba_profile.otpSubmitted = true;
          setWabaProfile(waba_profile);
          setShowSuccessToast((successToast: any) => !successToast);
          setToast("OTP submitted");
          props.settimeoutForWabaCall(10000);
          props.pollWABAStatus(props.shop.userId);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
        setIsOTPSubmissionInProgress(false);
      },
      (err: any) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast("Something went wrong. Unable to verify OTP.");
        setIsOTPSubmissionInProgress(false);
      }
    );
  };
  const congratulations_banner = wabaProfile.live ? (
    <Banner
      title="Congratulations! Your WhatsApp API access has been approved"
      status="success"
      action={{
        content: "Contact support (support@superlemon.xyz)",
        url: "mailto:support@superlemon.xyz?subject=I%20need%20help%20with%20WhatsApp%20chat%20—%20",
      }}
    >
      <TextContainer>
        <p>
          Automated messages are now being sent from your WhatsApp API approved
          phone number.
        </p>
        <p>
          Need help or have any questions regarding your WhatsApp API account?
        </p>
      </TextContainer>
    </Banner>
  ) : null;

  return (
    <Fragment>
      {ctxSaveBarMarkup}
      {congratulations_banner}
      <Layout.AnnotatedSection
        title="STEP 1 - Business & Facebook Account Details"
        description={stepOneDescription}
      >
        <Card>
          {businessDetailsCardSection}
          {fbBusinessDetailsCardSection}
          {waProfileDetailsCardSection}
          {contactDetailsCardSection}
          {finalCardSection}
        </Card>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title="STEP 2 - Approve Gupshup request in your Facebook Business Manager"
        description={stepTwoDescription}
      >
        <Card>
          {fbAccessToGupshupStatusCardSection}
          {fbBusinessManagerVerificationCardSection}
        </Card>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title="STEP 3 - Verification of WhatsApp Phone Number"
        description={stepThreeDescription}
      >
        <Card>
          {otpVerificationCompletedBanner}
          {otpVeritificationDisclaimerCardSection}
          {otpVeritificationOptionCardSection}
        </Card>
        <Card>{getSubmitOTPContent()}</Card>
      </Layout.AnnotatedSection>
    </Fragment>
  );
};

export default ApplicationForm;
