import "./index.scss";
import SubTitle from "./SubTitle";

const SetupWarnCard = (props: any) => {
  return (
    <div className="SetupWarnCard">
      {props.title}
      {props.description && <SubTitle subtitle={props.subtitle} />}
      <div className="desc">{props.description}</div>
      {props.children}
      <button className="btnClass" onClick={props.onSubmit} ref={props.btnRef}>
        {props.btnText}
      </button>
    </div>
  );
};
export default SetupWarnCard;
