import { Icon } from "@shopify/polaris";
import { Link } from "react-router-dom";

export default function NavigationMenuItemNew({
  parentActive,
  parentTitle,
  parentIcon,
  child,
}: any) {
  return (
    <>
      <ul className="Polaris-Navigation__Section">
        <div className="Polaris-Navigation__ItemWrapper">
          <span
            className={`Polaris-Navigation__Item ${
              parentActive
                ? "parent-active Polaris-Navigation--subNavigationActive"
                : ""
            }`}
            data-polaris-unstyled="true"
          >
            <div className="Polaris-Navigation__Icon">
              <Icon source={parentIcon} color="subdued" />
            </div>
            <span className="Polaris-Navigation__Text">{parentTitle}</span>
          </span>
        </div>

        <div className="Polaris-Navigation__SecondaryNavigation Polaris-Navigation--isExpanded">
          <div
            id="PolarisSecondaryNavigation4"
            className="Polaris-Collapsible"
            aria-expanded="true"
            style={{
              transitionDuration: "0s",
              maxHeight: "none",
              overflow: "visible",
            }}
          >
            <ul className="Polaris-Navigation__List">
              {child.map((navItem: any) => (
                <li
                  className="Polaris-Navigation__ListItem"
                  onClick={navItem.onClick}
                  key={navItem.label}
                >
                  <div className="Polaris-Navigation__ItemWrapper">
                    <div
                      className={`Polaris-Navigation__Item ${
                        navItem.selected
                          ? "Polaris-Navigation__Item Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                          : ""
                      }`}
                      aria-disabled="false"
                      data-polaris-unstyled="true"
                    >
                      <span className="Polaris-Navigation__Text">
                        {navItem.label}
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </ul>
    </>
  );
}
