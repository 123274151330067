import { RadioButton, Stack } from "@shopify/polaris";
import { useEffect, useState } from "react";
import "../../assets/css/waba.scss";
import GuideModal from "../../common/GuideModal";
import obj from "../../content/WhatsappApi/FBManagerOverlay/en.json";

function WabaDetails({ isVerified }: any) {
  const [verified, setIsVerified]: any = useState(isVerified);
  const [value, setValue] = useState(false);
  const [openGuideModal, setOpenGuideModal] = useState(false);

  useEffect(() => {
    setIsVerified(isVerified);
  }, [isVerified]);

  const handleChange = (status: any) => {
    setValue(status);
  };
  const closeGuideModal = () => {
    setOpenGuideModal(false);
  };
  return (
    <div className="wabaDetails">
      <>
        <p className="p10">
          Learn how to check the facebook business id verification status{" "}
          <span
            style={{ color: "#5EC127", cursor: "default" }}
            onClick={() => setOpenGuideModal(true)}
          >
            here
          </span>
        </p>
        <Stack>
          <div className="radioButtonBorder">
            <RadioButton
              label="I have my Facebook Business Manager ID Unverified"
              checked={value === false}
              id="false"
              name="fbmanagerStatus"
              onChange={() => handleChange(false)}
            />
            <RadioButton
              label="I have my Facebook Business Manager ID Verified"
              id="true"
              name="fbmanagerStatus"
              checked={value === true}
              onChange={() => handleChange(true)}
            />
          </div>
        </Stack>
        <>
          {value ? (
            <>
              <strong>Messaging Limits and Quality Rating :</strong>
              <p>
                Messaging limits determine the maximum number of
                business-initiated conversations each phone number can start in
                a rolling 24-hour period. A business-initiated conversation
                starts when the first message is delivered to a customer and
                ends 24 hours later. A conversation can’t end before then.
              </p>
              <p>
                {" "}
                If you reach your messaging limit, you can start more
                conversations as soon as one or more active conversations end.
                Messaging limits do not apply to user-initiated conversations.
              </p>
              <p>
                {" "}
                There are four levels of messaging limits that apply to
                businesses that have completed Business Verification:
              </p>
              <ul>
                {" "}
                <li>
                  Allows your phone number to have 1,000 business-initiated
                  conversations (with 1,000 unique customers) in a rolling
                  24-hour period.
                </li>
                <li>
                  {" "}
                  Allows your phone number to have 10,000 business-initiated
                  conversations (with 10,000 unique customers) in a rolling
                  24-hour period.{" "}
                </li>
                <li>
                  Allows your phone number to have 100,000 business-initiated
                  conversations (with 100,000 unique customers) in a rolling
                  24-hour period.
                </li>
                <li>
                  {" "}
                  Allows your phone number to have unlimited business-initiated
                  conversations in a rolling 24-hour period.
                </li>
              </ul>
              <strong>Increasing Messaging Limits</strong>
              <p>
                Your messaging limits are automatically increased based on the
                volume and quality of the messages you send. Conversely, your
                limit can also be decreased if more of your messages are
                reported or blocked by users.
              </p>
              <p>
                {" "}
                Limits for a phone number can be increased if the following
                three items are true:
              </p>
              <ul>
                <li>The phone number quality rating is not low.</li>
                <li>
                  {" "}
                  Within a 7-day period, the phone number starts
                  business-initiated conversations with twice the amount of
                  unique customers supported in the current messaging limit.
                </li>
                <li>
                  The phone number has been at the current messaging limit for
                  at least 48 hours.
                </li>
              </ul>{" "}
              <p>
                Once the business reaches this threshold, its limits are
                automatically increased. As you increase your messaging
                activity, you should always analyze phone number quality to
                ensure that your customers are not blocking or reporting your
                messages. Businesses need to demonstrate that they can send high
                or medium quality messages at higher volumes before they can get
                higher messaging limits.
              </p>{" "}
              <strong>Quality Rating</strong>{" "}
              <p>
                Your quality rating is based on how messages have been received
                by recipients over the past seven days and is weighted by
                recency. It is determined by a combination of quality signals
                from conversations between businesses and users. Examples
                include user feedback signals like blocks, reports and the
                reasons users provide when they block a business.
              </p>{" "}
              <strong>Maintaining High Quality</strong>{" "}
              <p>
                Make sure messages follow the WhatsApp Business Policy and
                Commerce Policy. Only send messages to users who have opted into
                receiving messages from your business. Make the messages highly
                personalized and useful to users. Avoid sending open-ended
                welcome or introductory messages. Be mindful of messaging
                frequency; avoid sending customers too many messages a day. Be
                thoughtful of informational messages, optimizing for content and
                length.
              </p>
            </>
          ) : (
            <>
              <p>
                <strong>Conditions: </strong>
              </p>
              <p>You're in Unverified Trial Experience</p>
              <p>
                Account will expire if Business Verification or Whatsapp checks
                get rejected.
              </p>
              <br />
              <p>
                <strong>Note:</strong> Limitations during Unverified Trial
                Experience
              </p>
              <br />

              <p>
                Send business-initiated conversations to 50 unique customers in
                a rolling 24-hour period and Register up to two (2) phone
                numbers.
              </p>
              <br />

              <p>
                <strong>Session Messages:</strong> Can be sent in customer
                initiated sessions
              </p>
              <br />

              <p>
                Respond to unlimited customer-initiated conversations (24-hour
                messaging windows).
              </p>
            </>
          )}
        </>
      </>
      {openGuideModal ? (
        <GuideModal open={true} closeModal={closeGuideModal} obj={obj} />
      ) : null}
    </div>
  );
}
export default WabaDetails;
