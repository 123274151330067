import { useEffect, useState } from 'react';
import PageMarkup from '../../common/PageMarkup';
import AutomatedTemplates from './Automated/AutomatedTemplates';
import AutomatedPromotionalTemplates from './AutomatedPromotional';
import {
  getWabaDetailsbyShopId,
  loadWabaDetails,
} from '../../redux/actions/private-waba';
import {
  NUDGE_SCREEN_CONTACT_SUPPORT,
  NUDGE_SCREEN_CONTENT,
  PLAN_ENGAGE_WIX,
  PLAN_ENTERPRISE,
  PLAN_OLD_FREE,
  PLATFORM,
  TUTORIAL_VIDEOS,
} from '../../constants/constants';
import { getTeamInboxStatus } from '../../redux/actions/teamInbox';
import { fetchPhoneSettings } from '../../redux/actions/crmOrders';
import { getplanPaymentInfo } from '../../utils/helper';
import PaymentFailed from '../../common/PaymentFailed';
import { Page, TextContainer, Heading, TextStyle } from '@shopify/polaris';
import NudgeScreenContent from '../../common/NudgeScreen';
import { useShopStore } from '../../hooks/shopStore';
import NudgeScreenContactSupport from '../../common/NudgeScreenContactSupport';

const CommonTemplates = ({
  privateWabaDetails,
  selectedTabForTemplate,
}: any) => {
  const { shopDetailsData, planPaymentInfo } = useShopStore();
  const showPaymentFailedUI = getplanPaymentInfo(planPaymentInfo);
  const [selectedTab, setSelectedTab] = useState(selectedTabForTemplate);
  const shop = shopDetailsData;
  const currentPlanId = shopDetailsData.featuresList;
  const [pvtWabaDetails, setPvtWabaDetails]: any = useState(privateWabaDetails);
  const [teamInboxDone, setTeamInboxDone] = useState(false);
  const [phoneData, setPhoneData] = useState({ countryCode: '', phone: '' });
  const [optinEnterpriseTemplatesEnabled, setOptinEnterpriseTemplatesEnabled] =
    useState(false);
  const [showAutomationBanner, setShowAutomationBanner] = useState(false);

  useEffect(() => {
    if (!privateWabaDetails) {
      getEmbeddedWabaDetails();
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    getTeamInboxStatus(shop.userId).then((res: any) => {
      if (res?.data?.status === 'ACCOUNT_LIVE') {
        setTeamInboxDone(true);
      } else {
        setTeamInboxDone(false);
      }
    });
    fetchPhoneSettings(shop.userId).then((res: any) => {
      if (res.status === 'success') {
        setPhoneData(res.settings);
      }
    });
  }, []);

  useEffect(() => {
    checkOptinEnterpriseTemplates();
  }, [teamInboxDone, pvtWabaDetails, phoneData]);

  useEffect(() => {
    if (Number(shop.planId) !== Number(PLAN_OLD_FREE)) setShowAutomationBanner(localStorage.getItem('private_waba') === 'true' ? false : true);
  }, []);

  const getEmbeddedWabaDetails = () => {
    if (shop && shop.userId) {
      loadWabaDetails(shop.userId).then((res: any) => {
        if (res && res.appId && res.whatsappVerificationStatus) {
          setPvtWabaDetails(res);
        } else {
          getWabaDetailsbyShopId(shop.userId).then((res: any) => {
            if (res && res.appId && res.whatsappVerificationStatus) {
              setPvtWabaDetails(res);
            }
          });
        }
      });
    }
  };

  const checkSupportNoSameAsWabaNo = () => {
    let phone;
    if (
      pvtWabaDetails &&
      phoneData.hasOwnProperty('countryCode') &&
      phoneData?.countryCode
    ) {
      if (pvtWabaDetails.hasOwnProperty('number')) {
        phone = pvtWabaDetails.number;
      } else if (pvtWabaDetails.hasOwnProperty('wabaNumber')) {
        phone = pvtWabaDetails.wabaNumber;
      }
      return Number(phoneData?.countryCode.concat(phoneData?.phone)) ===
        Number(phone)
        ? true
        : false;
    } else {
      return false;
    }
  };

  // Check whether user is on Enterprise Plan , has Agent Assist setup and uses WABA number as support number
  const checkOptinEnterpriseTemplates = () => {
    if (
      [PLAN_ENTERPRISE, PLAN_ENGAGE_WIX].includes(currentPlanId) &&
      teamInboxDone &&
      checkSupportNoSameAsWabaNo()
    ) {
      setOptinEnterpriseTemplatesEnabled(true);
    } else {
      setOptinEnterpriseTemplatesEnabled(false);
    }
  };

  const tabs = [
    {
      id: 'Automated',
      content: 'Order Automation',
      accessibilityLabel: 'Order Automation',
      panelID: 'automated',
    },
    {
      id: 'Automated Promotional',
      content: 'Promotion Automation',
      accessibilityLabel: 'Promotion Automation',
      panelID: 'automatedPromotional',
    },
  ];

  const tabContent = {
    0: (
      <AutomatedTemplates
        optinEnterpriseTemplates={optinEnterpriseTemplatesEnabled}
        pvtWabaDetails={pvtWabaDetails}
      ></AutomatedTemplates>
    ),
    1: (
      <AutomatedPromotionalTemplates
        optinEnterpriseTemplates={optinEnterpriseTemplatesEnabled}
        pvtWabaDetails={pvtWabaDetails}
      ></AutomatedPromotionalTemplates>
    ),
  };

  return (
    <>
      {showPaymentFailedUI.downgraded ? (
        <PaymentFailed failedOn={showPaymentFailedUI.downgradedOn} />
      ) : [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform) &&
        PLAN_OLD_FREE === Number(shop.planId) ? (
        <Page title={NUDGE_SCREEN_CONTENT.AUTOMATION.TITLE}>
          <br />
          <br />
          <div className='nudgeScreen-singlebox'>
            <div>
              <TextContainer>
                <Heading>
                  <TextStyle variation='strong'>
                    {NUDGE_SCREEN_CONTENT.AUTOMATION.SUBTITLE}
                  </TextStyle>
                </Heading>
                <TextStyle variation='subdued'>
                  <br />
                  <br />
                  {NUDGE_SCREEN_CONTENT.AUTOMATION.CONTENT[0]}
                </TextStyle>
              </TextContainer>

              <TextContainer>
                <TextStyle variation='subdued'>
                  <br />
                  {NUDGE_SCREEN_CONTENT.AUTOMATION.CONTENT[1]}
                  <br />
                  <br />
                  {NUDGE_SCREEN_CONTENT.AUTOMATION.CONTENT[2]}
                </TextStyle>
              </TextContainer>
            </div>
            <NudgeScreenContent />
          </div>
        </Page>
      ) : [PLATFORM.NONE].includes(shop.platform) &&
        [PLAN_ENGAGE_WIX, PLAN_ENTERPRISE].includes(Number(shop.planId)) ? (
        <NudgeScreenContactSupport
          pageDetails={NUDGE_SCREEN_CONTACT_SUPPORT.AUTOMATION}
          shop={shop}
        />
      ) : (
        <PageMarkup
          title='Automation'
          tutorial
          tutorialSrc={TUTORIAL_VIDEOS.AUTOMATION_PAGE.videoSrc}
          tutorialTitle={TUTORIAL_VIDEOS.AUTOMATION_PAGE.content}
          tabs={tabs}
          tabContent={tabContent}
          selected={selectedTab}
          showAutomationBanner={showAutomationBanner}
        ></PageMarkup>
      )}
    </>
  );
};
export default CommonTemplates;
