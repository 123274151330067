import { useHistory, useLocation } from 'react-router-dom';
import { Navigation } from '@shopify/polaris';
import { InviteMinor } from '@shopify/polaris-icons';
import NavigationSectionWrapper from './NavigationSectionWrapper';
import { PLAN_OLD_FREE, PLATFORM, SHOP_FEATURES } from '../constants/constants';
import NavigationMenuItemNew from './NavigationMenuItemNew';
import {
  HomeIcon,
  AnalyticsIcon,
  CampaignIcon,
  LanguageIcon,
  SettingsIcon,
  TemplatesIcon,
  WhatsAppWidgetIcon,
  WhatsAppBusinessAccountIcon,
  CustomerIcon,
  TeamInboxIcon,
  MessagingIcon,
  WalletIcon,
  TemplateSidebarIcon,
} from './SvgIcons';
import NavigationMenuItemBadge from './NavigationMenuItemBadge';
import { getplanPaymentInfo, shopFeaturesFromStoreData } from '../utils/helper';
import { useShopStore } from '../hooks/shopStore';

const SideMenu = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const { shopDetailsData, planPaymentInfo } = useShopStore();

  let shop = shopDetailsData;
  let features = shopDetailsData.featuresList;

  const showPaymentFailedUI = getplanPaymentInfo(planPaymentInfo);

  const changePath = (path: any) => {
    history.push(path);
  };

  const displayMenuEmail = () => {
    if (features.findIndex((el: any) => el === 'EMAIL') !== -1) {
      const subNavItems = [
        {
          label: 'Templates',
          onClick: () => changePath('/email-templates'),
          selected: location.pathname.includes('/email-templates'),
        },
        {
          label: 'Configuration',
          onClick: () => changePath('/email-config'),
          selected: location.pathname.includes('/email-config'),
        },
      ];
      const routes = ['/email-templates', '/email-config'];
      const path = location.pathname;
      return (
        <NavigationMenuItemNew
          parentActive={routes.some((el) => path.includes(el))}
          parentTitle='Email'
          parentIcon={InviteMinor}
          child={subNavItems}
        />
      );
    }
  };

  return [PLAN_OLD_FREE].includes(Number(props.planId)) &&
    [PLATFORM.WIX, PLATFORM.NONE].includes(shop.platform) ? (
    // Case : Wix New User
    <Navigation location='/'>
      <div data-tut='private-waba' id='private-waba'>
        <Navigation.Section
          key='private-waba'
          items={[
            {
              onClick: () => changePath('/private-waba'),
              url: '/',
              label: 'My Whatsapp',
              icon: WhatsAppBusinessAccountIcon,
              selected: location.pathname.includes('/private-waba'),
            },
          ]}
        />
      </div>
      <div data-tut='campaigns' id='campaigns'>
        <Navigation.Section
          key='Campaigns'
          items={[
            {
              onClick: () => changePath('/campaigns'),
              url: '/',
              label: 'Campaigns',
              icon: CampaignIcon,
              selected: location.pathname.includes('/campaigns'),
            },
          ]}
        />
      </div>
      <div data-tut='whatsappTemplates' id='whatsappTemplates'>
        <Navigation.Section
          key='WhatsappTemplates'
          items={[
            {
              onClick: () => changePath('/whatsapp-templates'),
              url: '/',
              label: 'Templates',
              icon: CampaignIcon,
              selected: location.pathname.includes('/whatsapp-templates'),
            },
          ]}
        />
      </div>
      {props.showSharedInbox && (
        <div data-tut='teamInbox' id='teamInbox'>
          <Navigation.Section
            key='team-inbox'
            items={[
              {
                onClick: () => changePath('/team-inbox'),
                url: '/',
                label: 'Shared Team Inbox',
                icon: TeamInboxIcon,
                selected: location.pathname.includes('/team-inbox'),
              },
            ]}
          />
        </div>
      )}
      <div data-tut='automation' id='automation'>
        <Navigation.Section
          key='Automation'
          items={[
            {
              onClick: () => changePath('/templates'),
              url: '/',
              label: 'Automation',
              icon: TemplatesIcon,
              selected: location.pathname.includes('/templates'),
            },
          ]}
        />
      </div>
      {!showPaymentFailedUI.downgraded && (
        <div data-tut='widgets' id='widgets'>
          <NavigationMenuItemBadge
            to='/widget'
            label='Widgets'
            icon={WhatsAppWidgetIcon}
            selected={location.pathname.includes('/widget')}
          />
        </div>
      )}
      <div data-tut='wallet' id='wallet'>
        <Navigation.Section
          key='Wallet'
          items={[
            {
              onClick: () => changePath('/wallet'),
              url: '/',
              label: 'Wallet',
              icon: WalletIcon,
              selected: location.pathname.includes('/wallet'),
            },
          ]}
        />
      </div>
      <NavigationSectionWrapper
        icon={LanguageIcon}
        label={<div id='google_translate_element'></div>}
      />
    </Navigation>
  ) : shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.EXTENSION) ? (
    // Case : Shopify,Wix Engage Plan User
    <Navigation location='/'>
      <div data-tut='homepage' id='homepage'>
        <Navigation.Section
          key='homepage'
          items={[
            {
              onClick: () => changePath('/home'),
              url: '/',
              label: 'Home',
              icon: HomeIcon,
              selected: location.pathname.includes('/home'),
            },
          ]}
        />
      </div>
      {shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.AGENT_ASSIST) &&
      props.agentPhone ? (
        <div data-tut='whatsAppInbox' id='whatsAppInbox'>
          <Navigation.Section
            key='whatsAppInbox'
            items={[
              {
                onClick: () => changePath('/chat'),
                url: '/',
                label: 'WhatsApp Inbox',
                icon: TeamInboxIcon,
                selected: ['/chat', '/chat-config'].includes(location.pathname),
                subNavigationItems: [
                  {
                    onClick: () => changePath('/chat'),
                    url: '/chat',
                    label: 'Chat',
                  },
                  {
                    onClick: () => changePath('/chat-config'),
                    url: '/chat-config',
                    disabled: false,
                    label: 'Configuration',
                  },
                ],
              },
            ]}
          />
        </div>
      ) : (
        <div data-tut='whatsAppInbox' id='whatsAppInbox'>
          <Navigation.Section
            key='whatsAppInbox'
            items={[
              {
                onClick: () => changePath('/chat'),
                url: '/',
                label: 'WhatsApp Inbox',
                icon: TeamInboxIcon,
                selected: location.pathname.includes('/chat'),
              },
            ]}
          />
        </div>
      )}
      <div data-tut='campaigns' id='campaigns'>
        <Navigation.Section
          key='Campaigns'
          items={[
            {
              onClick: () => changePath('/campaigns'),
              url: '/',
              label: 'Campaigns',
              icon: CampaignIcon,
              selected: location.pathname.includes('/campaigns'),
              badge: 'Marketing',
            },
          ]}
        />
      </div>
      <div data-tut='automation' id='automation'>
        <Navigation.Section
          key='Automation'
          items={[
            {
              onClick: () => changePath('/templates'),
              url: '/',
              label: 'Automation',
              icon: TemplatesIcon,
              selected: location.pathname.includes('/templates'),
              badge: 'Productivity',
            },
          ]}
        />
      </div>
      {shop.platform !== PLATFORM.NONE && (
        <div data-tut='messaging' id='messaging'>
          <Navigation.Section
            key='Messaging'
            items={[
              {
                onClick: () => changePath('/messaging'),
                url: '/',
                label: 'CRM Tool',
                icon: MessagingIcon,
                selected: location.pathname.includes('/messaging'),
              },
            ]}
          />
        </div>
      )}

      <div data-tut='whatsappTemplates' id='whatsappTemplates'>
        <Navigation.Section
          key='WhatsappTemplates'
          items={[
            {
              onClick: () => changePath('/whatsapp-templates'),
              url: '/',
              label: 'Templates',
              icon: TemplateSidebarIcon,
              selected: location.pathname.includes('/whatsapp-templates'),
            },
          ]}
        />
      </div>
      {shop.platform !== PLATFORM.NONE &&
        shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.CUSTOMERS_LIST) && (
          <>
            <div data-tut='customers' id='customers'>
              <Navigation.Section
                key='customers'
                items={[
                  {
                    onClick: () => changePath('/optins'),
                    url: '/',
                    label: 'Customers',
                    icon: CustomerIcon,
                    selected: location.pathname.includes('/optins'),
                  },
                ]}
              />
            </div>
          </>
        )}
      <div data-tut='widgets' id='widgets'>
        <NavigationMenuItemBadge
          to='/widget'
          label='Widgets'
          icon={WhatsAppWidgetIcon}
          selected={location.pathname.includes('/widget')}
        />
      </div>
      {shop.platform !== PLATFORM.NONE &&
        shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.ANALYTICS) && (
          <div data-tut='analyticsMessageLogs' id='analyticsMessageLogs'>
            <Navigation.Section
              key='analyticsMessageLogs'
              items={[
                {
                  onClick: () => changePath('/analytics-message-logs'),
                  url: '/',
                  label: 'Analytics & Logs',
                  icon: AnalyticsIcon,
                  selected: location.pathname.includes(
                    '/analytics-message-logs'
                  ),
                },
              ]}
            />
          </div>
        )}
      <div data-tut='private-waba' id='private-waba'>
        <Navigation.Section
          key='private-waba'
          items={[
            {
              onClick: () => changePath('/private-waba'),
              url: '/',
              label: 'My Whatsapp',
              icon: WhatsAppBusinessAccountIcon,
              selected: location.pathname.includes('/private-waba'),
              badge: 'Branding',
            },
          ]}
        />
      </div>
      <div>
        {props.showSharedInbox && (
          <div data-tut='teamInbox' id='teamInbox'>
            <Navigation.Section
              key='team-inbox'
              items={[
                {
                  onClick: () => changePath('/team-inbox'),
                  url: '/',
                  label: 'Shared Team Inbox',
                  icon: TeamInboxIcon,
                  selected: location.pathname.includes('/team-inbox'),
                },
              ]}
            />
          </div>
        )}
      </div>
      {shop?.platform !== PLATFORM.SHOPIFY && (
        <div data-tut='wallet' id='wallet'>
          <Navigation.Section
            key='Wallet'
            items={[
              {
                onClick: () => changePath('/wallet'),
                url: '/',
                label: 'Wallet',
                icon: WalletIcon,
                selected: location.pathname.includes('/wallet'),
              },
            ]}
          />
        </div>
      )}
      {shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.SETTINGS) && (
        <>
          <Navigation.Section
            key='admin-config'
            items={[
              {
                onClick: () => changePath('/admin-config'),
                url: '/',
                label: 'Settings',
                icon: SettingsIcon,
                selected: location.pathname.includes('/admin-config'),
              },
            ]}
          />
        </>
      )}
      {displayMenuEmail()}
      <NavigationSectionWrapper
        icon={LanguageIcon}
        label={<div id='google_translate_element'></div>}
      />
    </Navigation>
  ) : (
    // Case : Shopify New Free User
    <Navigation location='/'>
      <div data-tut='homepage' id='homepage'>
        <Navigation.Section
          key='homepage'
          items={[
            {
              onClick: () => changePath('/home'),
              url: '/',
              label: 'Home',
              icon: HomeIcon,
              selected: location.pathname.includes('/home'),
            },
          ]}
        />
      </div>
      {shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.AGENT_ASSIST) &&
          props.agentPhone ? (
            <div data-tut='whatsAppInbox' id='whatsAppInbox'>
              <Navigation.Section
                key='whatsAppInbox'
                items={[
                  {
                    onClick: () => changePath('/chat'),
                    url: '/',
                    label: 'WhatsApp Inbox',
                    icon: TeamInboxIcon,
                    selected: ['/chat', '/chat-config'].includes(
                      location.pathname
                    ),
                    subNavigationItems: [
                      {
                        onClick: () => changePath('/chat'),
                        url: '/chat',
                        label: 'Chat',
                      },
                      {
                        onClick: () => changePath('/chat-config'),
                        url: '/chat-config',
                        disabled: false,
                        label: 'Configuration',
                      },
                    ],
                  },
                ]}
              />
            </div>
          ) : (
            <div data-tut='whatsAppInbox' id='whatsAppInbox'>
              <Navigation.Section
                key='whatsAppInbox'
                items={[
                  {
                    onClick: () => changePath('/chat'),
                    url: '/',
                    label: 'WhatsApp Inbox',
                    icon: TeamInboxIcon,
                    selected: location.pathname.includes('/chat'),
                  },
                ]}
              />
            </div>
          )}
      <div data-tut='campaigns' id='campaigns'>
        <Navigation.Section
          key='Campaigns'
          items={[
            {
              onClick: () => changePath('/campaigns'),
              url: '/',
              label: 'Campaigns',
              icon: CampaignIcon,
              selected: location.pathname.includes('/campaigns'),
              badge: 'Marketing',
            },
          ]}
        />
      </div>
      <div data-tut='automation' id='automation'>
        <Navigation.Section
          key='Automation'
          items={[
            {
              onClick: () => changePath('/templates'),
              url: '/',
              label: 'Automation',
              icon: TemplatesIcon,
              selected: location.pathname.includes('/templates'),
              badge: 'Productivity',
            },
          ]}
        />
      </div>
      <div data-tut='whatsappTemplates' id='whatsappTemplates'>
        <Navigation.Section
          key='WhatsappTemplates'
          items={[
            {
              onClick: () => changePath('/whatsapp-templates'),
              url: '/',
              label: 'Templates',
              icon: TemplateSidebarIcon,
              selected: location.pathname.includes('/whatsapp-templates'),
            },
          ]}
        />
      </div>      
      {shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.CUSTOMERS_LIST) && (
        <>
          <div data-tut='customers' id='customers'>
            <Navigation.Section
              key='customers'
              items={[
                {
                  onClick: () => changePath('/optins'),
                  url: '/',
                  label: 'Customers',
                  icon: CustomerIcon,
                  selected: location.pathname.includes('/optins'),
                },
              ]}
            />
          </div>
        </>
      )}
      <div data-tut='widgets' id='widgets'>
        <NavigationMenuItemBadge
          to='/widget'
          label='Widgets'
          icon={WhatsAppWidgetIcon}
          selected={location.pathname.includes('/widget')}
        />
      </div>
      {shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.ANALYTICS) && (
        <div data-tut='analyticsMessageLogs' id='analyticsMessageLogs'>
          <Navigation.Section
            key='analyticsMessageLogs'
            items={[
              {
                onClick: () => changePath('/analytics-message-logs'),
                url: '/',
                label: 'Analytics & Logs',
                icon: AnalyticsIcon,
                selected: location.pathname.includes('/analytics-message-logs'),
              },
            ]}
          />
        </div>
      )}
      <div data-tut='private-waba' id='private-waba'>
            <Navigation.Section
              key='private-waba'
              items={[
                {
                  onClick: () => changePath('/private-waba'),
                  url: '/',
                  label: 'My Whatsapp',
                  icon: WhatsAppBusinessAccountIcon,
                  selected: location.pathname.includes('/private-waba'),
                  badge: 'Branding',
                },
              ]}
            />
          </div>
      {shopFeaturesFromStoreData(shopDetailsData).includes(SHOP_FEATURES.SETTINGS) && (
        <>
          <Navigation.Section
            key='admin-config'
            items={[
              {
                onClick: () => changePath('/admin-config'),
                url: '/',
                label: 'Settings',
                icon: SettingsIcon,
                selected: location.pathname.includes('/admin-config'),
              },
            ]}
          />
        </>
      )}      
      <div className='sideBarSub'>
        <div className='requiresSubText'>{'Upgrade to unlock'}</div>
        <div>          
          <div data-tut='messaging' id='messaging'>
            <Navigation.Section
              key='Messaging'
              items={[
                {
                  onClick: () => changePath('/messaging'),
                  url: '/',
                  label: 'CRM Tool',
                  icon: MessagingIcon,
                  selected: location.pathname.includes('/messaging'),
                },
              ]}
            />
          </div>                    
        </div>
      </div>
      {displayMenuEmail()}
      <NavigationSectionWrapper
        icon={LanguageIcon}
        label={<div id='google_translate_element'></div>}
      />
    </Navigation>
  );
};

export default SideMenu;
