import { Checkbox, Modal, Select, TextField } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { createTemplate } from '../../../../redux/actions/manual-messaging';
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from '../../../../utils/clevertapUtils';
import { handleNewLineCharacters } from '../../../../utils/helper';
import { createTemplate as createABTemplate } from '../../../../redux/actions/templates';
import { PLATFORM } from '../../../../constants/constants';

function CreateTemplateModal({
  showToast,
  showErrorToast,
  open,
  closeModal,
  userId,
  platform,
}: any) {
  const [openModal, setOpenModal] = useState(open);
  const [selectedTemplate, setSelectedTemplate] = useState({
    message: '',
    name: '',
    category: '',
    id: '',
    discount: true,
    discountRatio: '',
  });
  const [initialTemplate, setInitialTemplate] = useState({
    message: '',
    name: '',
    category: '',
    id: '',
    discount: true,
    discountRatio: '',
  });
  const [extensionTemplate, setExtensionTemplate] = useState({
    text: '',
    name: '',
    category: '',
  });
  const [initialExtensionTemplate, setInitialExtensionTemplate] = useState({
    text: '',
    name: '',
    category: '',
  });

  const [activateErrorCheckForName, setActivateErrorCheckForName] =
    useState(false);
  const [activateErrorCheckForContent, setActivateErrorCheckForContent] =
    useState(false);
  const [activateErrorCheckForDiscount, setActivateErrorCheckForDiscount] =
    useState(false);
  const [isSendButtonActive, setIsSendButtonActive] = useState(true);
  const categories = [
    { label: 'Abandoned Cart', value: 'CART' },
    { label: 'Orders', value: 'ORDER' },
    { label: 'Quick Reply', value: 'QUICK' },
  ];
  if (platform === PLATFORM.WIX) {
    categories.pop();
  }
  const extensionCategories = [
    { label: 'Order Template', value: 'OTHERS' },
    { label: 'Quick Reply', value: 'GREETING' },
  ];

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  const resetData = () => {
    setSelectedTemplate(initialTemplate);
    setExtensionTemplate(initialExtensionTemplate);
    setActivateErrorCheckForContent(false);
    setActivateErrorCheckForName(false);
    setActivateErrorCheckForDiscount(false);
  };

  const validateForm = () => {
    let template = selectedTemplate;
    if (!template.name) {
      return 'Template name can not be empty';
    } else if (!template.message) {
      return 'Template message can not be empty';
    } else if (!template.category) {
      return 'Please select a category';
    } else if (
      !template.discountRatio &&
      template.discount &&
      platform === PLATFORM.SHOPIFY
    ) {
      return 'Disount value cannot be empty';
    } else if (validateTemplateName() !== false) {
      return 'Template name should be less than 50 characters';
    } else if (validateTemplateContent() !== false) {
      return 'Template message should be less than 1000 characters';
    }
    return false;
  };

  const validateTemplateName = () => {
    if (activateErrorCheckForName) {
      let template = selectedTemplate;
      if (template.name.length > 50) {
        return 'Template name should be less than 50 characters';
      }
      if (template.name.length <= 0) {
        return 'Template name cannot be empty';
      }
    }
    return false;
  };

  const validateTemplateContent = () => {
    if (activateErrorCheckForContent) {
      let template = selectedTemplate;
      if (template.message.length > 1000) {
        return 'Template message should be less than 1000 characters';
      }
      if (template.message.length <= 0) {
        return 'Template message cannot be empty';
      }
    }
    return false;
  };

  const handleChange = (val: any, field: string) => {
    if (!activateErrorCheckForName && field === 'name') {
      setActivateErrorCheckForName(true);
    }
    if (!activateErrorCheckForContent && field === 'message') {
      setActivateErrorCheckForContent(true);
    }
    setSelectedTemplate((template: any) => ({ ...template, [field]: val }));
  };

  const handleExtensionChange = (val: any, field: string) => {
    if (!activateErrorCheckForName && field === 'name') {
      setActivateErrorCheckForName(true);
    }
    if (!activateErrorCheckForContent && field === 'message') {
      setActivateErrorCheckForContent(true);
    }
    setExtensionTemplate((template: any) => ({ ...template, [field]: val }));
  };

  const validateABForm = () => {
    let template = extensionTemplate;
    if (!template.name) {
      return 'Template name can not be empty';
    } else if (!template.category) {
      return 'Please select a category';
    } else if (!template.text) {
      return 'Template content can not be empty';
    } else if (validateABTemplateName() !== false) {
      return 'Template name should be less than 50 characters';
    } else if (validateABTemplateContent() !== false) {
      return 'Template content should be less than 1000 characters';
    }
    return false;
  };

  const validateABTemplateName = () => {
    if (activateErrorCheckForName) {
      let template = extensionTemplate;
      if (template.name.length > 50) {
        return 'Template name should be less than 50 characters';
      }
      if (template.name.length <= 0) {
        return 'Template name cannot be empty';
      }
    }
    return false;
  };

  const validateABTemplateContent = () => {
    if (activateErrorCheckForContent) {
      let template = extensionTemplate;
      if (template.text.length > 1000) {
        return 'Template content should be less than 1000 characters';
      }
      if (template.text.length <= 0) {
        return 'Template content cannot be empty';
      }
    }
    return false;
  };

  const validateDiscountRatio = () => {
    if (activateErrorCheckForDiscount && platform === PLATFORM.SHOPIFY) {
      if (selectedTemplate.discount && !selectedTemplate.discountRatio) {
        return 'Discount Value cannot be empty';
      }
      if (
        selectedTemplate.discount &&
        String(parseInt(selectedTemplate.discountRatio)) === '0'
      ) {
        return 'Discount Value cannot be 0';
      }
    }
    return false;
  };

  const handleDiscountChange = (newValue: any) => {
    if (!activateErrorCheckForDiscount) {
      setActivateErrorCheckForDiscount(true);
    }
    if (newValue <= 100 && newValue >= 0) {
      setSelectedTemplate((template: any) => ({
        ...template,
        discountRatio: newValue,
      }));
    }
  };

  const toggleOptinBehaviour = (name: string, value: boolean) => {
    setSelectedTemplate((template: any) => ({
      ...template,
      [name]: value,
    }));
  };

  const saveTemplate = (userId: string) => {
    if (selectedTemplate.category === 'QUICK') {
      let params = new URLSearchParams();
      let text = extensionTemplate.text;
      let category = extensionTemplate.category;
      text = handleNewLineCharacters(text);
      params.append('name', extensionTemplate.name);
      params.append('category', category);
      params.append('text', text);

      let error = validateABForm();
      if (error) {
        showErrorToast(error);
        return;
      } else {
        setIsSendButtonActive(false);
        createABTemplate(userId, params).then(
          (response: any) => {
            if (response.status === 'success') {
              showToast('New Template created successfully');
              clevertapEventPush({
                eventId: CT_EVENT_IDS.EXTENSION_TEMPLATE_CREATED,
                eventData: {
                  Type:
                    category === 'OTHERS' ? 'Order Template' : 'Quick Reply',
                },
              });
              setIsSendButtonActive(true);
              closeModal();
              resetData();
            } else {
              showErrorToast(response);
              setIsSendButtonActive(true);
            }
          },
          (err) => {
            showErrorToast('Unable to create template');
            setIsSendButtonActive(true);
          }
        );
      }
    } else {
      let params = new URLSearchParams();
      let message = selectedTemplate.message;
      message = handleNewLineCharacters(message);
      params.append('name', selectedTemplate.name);
      params.append('category', selectedTemplate.category);
      params.append('message', message);
      // @ts-ignore
      params.append('discount', selectedTemplate.discount);
      // @ts-ignore
      let discountRatio = isNaN(parseInt(selectedTemplate.discountRatio))
        ? ''
        : parseInt(selectedTemplate.discountRatio);
      params.append('discountRatio', `${discountRatio}`);

      let error = validateForm();
      if (error) {
        showErrorToast(error);
        return;
      } else {
        setIsSendButtonActive(false);
        createTemplate(userId, params).then(
          (response: any) => {
            if (response.status === 'success') {
              setSelectedTemplate(initialTemplate);
              showToast('Template created successfully');
              setActivateErrorCheckForContent(false);
              setActivateErrorCheckForName(false);
              setActivateErrorCheckForDiscount(false);
              setIsSendButtonActive(true);
              closeModal();
            } else {
              showErrorToast(response);
              setIsSendButtonActive(true);
            }
          },
          (err) => {
            showErrorToast('Unable to create template');
            setIsSendButtonActive(true);
          }
        );
      }
    }
  };

  const getTemplateMarkup = () => {
    return selectedTemplate.category === 'QUICK' ? (
      <>
        <TextField
          label='Name'
          value={extensionTemplate.name}
          onChange={(val) => {
            handleExtensionChange(val, 'name');
          }}
          autoComplete='off'
        />
        <br />
        <TextField
          label='Message'
          value={extensionTemplate.text}
          onChange={(val) => {
            handleExtensionChange(val, 'text');
          }}
          multiline={3}
          autoComplete='off'
        />
        <br />
        <Select
          label='Select Category'
          options={categories}
          onChange={(val) => {
            handleChange(val, 'category');
          }}
          placeholder='Select Category'
          value={selectedTemplate.category}
        />
        <br />
        <Select
          label='Select Quick Reply Category'
          options={extensionCategories}
          onChange={(val) => {
            handleExtensionChange(val, 'category');
          }}
          placeholder='Select Category'
          value={extensionTemplate.category}
        />
        <br /> <br />
        <div
          className='Polaris-Banner Polaris-Banner--statusSuccess Polaris-Banner--withinPage'
          tabIndex={0}
          role='status'
          aria-live='polite'
          aria-labelledby='PolarisBanner2Heading'
          aria-describedby='PolarisBanner2Content'
        >
          <div className='Polaris-Banner__ContentWrapper'>
            <div className='Polaris-Banner__Content' id='PolarisBanner2Content'>
              <div className='Polaris-Stack Polaris-Stack--distributionEqualSpacing Polaris-Stack--alignmentCenter'>
                <div className='Polaris-Stack__Item'>
                  <p>
                    <strong>Tip:</strong> While creating a template, if you want
                    to display a variable like "order id", e.g "Your order with
                    order ID : 123 is out for delivery"
                  </p>
                  <p className='mt-1'>
                    For this, type "Your order with Order ID :
                    &#123;&#123;name&#125;&#125; is out for delivery"
                  </p>
                  <br />
                  <p style={{ color: 'red' }}>
                    <span className='Polaris-TextStyle--variationSubdued'>
                      <b>Note:</b>{' '}
                    </span>
                    The below listed tags are only for Order Templates and
                    should not be added in Quick Reply templates
                  </p>
                  <p className='mt-1'>
                    Similar useful tags can be found below,
                  </p>
                  <br />
                  <div className='d-flex space-evenly'>
                    <div className='mr-2'>
                      <p>
                        <strong>1. For Orders : </strong>
                      </p>
                      <br />
                      <p>
                        &#123;&#123;cod&#125;&#125; -&gt; describes if its a COD
                        order
                      </p>
                      <p>
                        &#123;&#123;createdOn&#125;&#125; -&gt; timestamp of
                        Order Creation
                      </p>
                      <p>
                        &#123;&#123;currency&#125;&#125; -&gt; Currency of
                        Purchase
                      </p>
                      <p>
                        &#123;&#123;customerName&#125;&#125; -&gt; Customer Name
                      </p>
                      <p>
                        &#123;&#123;discount&#125;&#125; -&gt; Discount Amount
                      </p>
                      <p>
                        &#123;&#123;fulfillmentStatus&#125;&#125; -&gt;
                        Fulfillment Status
                      </p>
                      <p>
                        &#123;&#123;gateway&#125;&#125; -&gt; Payment Gateway
                      </p>
                      <p>&#123;&#123;name&#125;&#125; -&gt; Order Id</p>
                      <p>
                        &#123;&#123;paymentStatus&#125;&#125; -&gt; Payment
                        Status
                      </p>
                      <p>
                        &#123;&#123;phone&#125;&#125; -&gt; Customer contact
                        number
                      </p>
                      <p>
                        &#123;&#123;processingMethod&#125;&#125; -&gt; Payment
                        Processing Method
                      </p>
                      <p>&#123;&#123;status&#125;&#125; -&gt; Order Status</p>
                      <p>
                        &#123;&#123;statusUrl&#125;&#125; -&gt; Order Status URL
                      </p>
                      <p>&#123;&#123;tax&#125;&#125; -&gt; total tax</p>
                      <p>
                        &#123;&#123;token&#125;&#125; -&gt; Order Specific token
                      </p>
                      <p>&#123;&#123;total&#125;&#125; -&gt; total Amount</p>
                      <p>
                        &#123;&#123;trackingNumber&#125;&#125; -&gt; Tracking
                        Number
                      </p>
                      <p>
                        &#123;&#123;trackingUrl&#125;&#125; -&gt; Tracking URL
                      </p>
                    </div>
                    <div>
                      <p>
                        <strong>2. For Checkouts (AbCart) : </strong>
                      </p>
                      <br />
                      <p>
                        &#123;&#123;cancelledOn&#125;&#125; -&gt; timestamp of
                        cancellation
                      </p>
                      <p>
                        &#123;&#123;checkoutUrl&#125;&#125; -&gt; checkout URL
                      </p>
                      <p>
                        &#123;&#123;completedOn&#125;&#125; -&gt; timestamp of
                        completion
                      </p>
                      <p>
                        &#123;&#123;createdOn&#125;&#125; -&gt; timestamp of
                        creation
                      </p>
                      <p>
                        &#123;&#123;currency&#125;&#125; -&gt; currecny of
                        payment
                      </p>
                      <p>
                        &#123;&#123;discount&#125;&#125; -&gt; Discount Amount
                      </p>
                      <p>
                        &#123;&#123;modifiedOn&#125;&#125; -&gt; timestamp of
                        modification
                      </p>
                      <p>&#123;&#123;name&#125;&#125; -&gt; checkout id</p>
                      <p>
                        &#123;&#123;status&#125;&#125; -&gt; checkout status
                      </p>
                      <p>&#123;&#123;tax&#125;&#125; -&gt; tax amount</p>
                      <p>&#123;&#123;total&#125;&#125; -&gt; total Amount</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <>
        <TextField
          label='Name'
          value={selectedTemplate.name}
          onChange={(val) => {
            handleChange(val, 'name');
          }}
          autoComplete='off'
          error={validateTemplateName()}
        />
        <br />
        <TextField
          label='Message'
          value={selectedTemplate.message}
          onChange={(val) => {
            handleChange(val, 'message');
          }}
          multiline={3}
          autoComplete='off'
          error={validateTemplateContent()}
        />
        <br />
        <Select
          label='Select Category'
          options={categories}
          onChange={(val) => {
            handleChange(val, 'category');
          }}
          placeholder='Select Category'
          value={selectedTemplate.category}
        />
        <br />
        {platform === PLATFORM.SHOPIFY && (
          <>
            <Checkbox
              label={
                'Create discount code (creates a unique code for each customer)'
              }
              checked={selectedTemplate.discount}
              onChange={(value) => toggleOptinBehaviour('discount', value)}
            />
            <br />
            <br />
            <TextField
              label='Discount value (1-100)'
              suffix='%'
              type='number'
              name='discount_percentage'
              disabled={!selectedTemplate.discount}
              value={selectedTemplate.discountRatio.toString()}
              error={validateDiscountRatio()}
              onChange={(val) => handleDiscountChange(val)}
            />
            <br /> <br />{' '}
          </>
        )}
        <div
          className='Polaris-Banner Polaris-Banner--statusSuccess Polaris-Banner--withinPage'
          tabIndex={0}
          role='status'
          aria-live='polite'
          aria-labelledby='PolarisBanner2Heading'
          aria-describedby='PolarisBanner2Content'
        >
          <div className='Polaris-Banner__ContentWrapper'>
            <div className='Polaris-Banner__Content' id='PolarisBanner2Content'>
              <div className='Polaris-Stack Polaris-Stack--distributionEqualSpacing Polaris-Stack--alignmentCenter'>
                <div className='Polaris-Stack__Item'>
                  <p className='mt-1'>Some useful tags can be found below,</p>
                  <br />
                  <div className='d-flex space-evenly'>
                    <div className='mr-2'>
                      <p>
                        <strong>1. For Orders :</strong>
                      </p>
                      <br />
                      <p>&#123;&#123;shop_name&#125;&#125; -&gt; Shop name</p>
                      <p>&#123;&#123;store_url&#125;&#125; -&gt; Store url</p>
                      <p>&#123;&#123;first_name&#125;&#125; -&gt; First Name</p>
                      <p>&#123;&#123;order_id&#125;&#125; -&gt; Order id</p>
                      <p>
                        &#123;&#123;order_number&#125;&#125; -&gt; Order Number
                      </p>
                      <p>
                        &#123;&#123;order_value&#125;&#125; -&gt; Total order
                        value
                      </p>
                      <p>
                        &#123;&#123;order_status_url&#125;&#125; -&gt; Current
                        order status url
                      </p>
                      <p>
                        &#123;&#123;customer_name&#125;&#125; -&gt; Full
                        customer name
                      </p>
                      <p>&#123;&#123;last_name&#125;&#125; -&gt; Last name</p>
                      <p>
                        &#123;&#123;order_items&#125;&#125; -&gt; Order items
                      </p>
                      <p>
                        &#123;&#123;trackingNumber&#125;&#125; -&gt; Tracking
                        number
                      </p>
                      <p>
                        &#123;&#123;trackingUrl&#125;&#125; -&gt; Order tracking
                        url
                      </p>
                      <p>
                        &#123;&#123;tracking_id&#125;&#125; -&gt; Tracking id
                      </p>
                      {platform === PLATFORM.SHOPIFY && (
                        <>
                          <p>
                            &#123;&#123;discount&#125;&#125; -&gt; Discount %
                          </p>
                          <p>
                            &#123;&#123;discountCode&#125;&#125; -&gt; Discount
                            code
                          </p>
                        </>
                      )}
                      <p>&#123;&#123;owner_name&#125;&#125; -&gt; Owner Name</p>
                      <p>
                        &#123;&#123;order_address&#125;&#125; -&gt; Order
                        Address
                      </p>
                    </div>
                    <div>
                      <p>
                        <strong>2. For Abandoned Cart : </strong>
                      </p>
                      <br />
                      <p>&#123;&#123;shop_name&#125;&#125; -&gt; Shop name</p>
                      <p>
                        &#123;&#123;checkout_url&#125;&#125; -&gt; checkout URL
                      </p>
                      {platform === PLATFORM.SHOPIFY && (
                        <>
                          <p>
                            &#123;&#123;discount&#125;&#125; -&gt; Discount %
                          </p>
                          <p>
                            &#123;&#123;discountCode&#125;&#125; -&gt; Discount
                            code
                          </p>
                        </>
                      )}
                      <p>&#123;&#123;first_name&#125;&#125; -&gt; First name</p>
                      <p>
                        &#123;&#123;order_value&#125;&#125; -&gt; Order Value
                      </p>
                      <p>&#123;&#123;cart_items&#125;&#125; -&gt; Cart items</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setIsSendButtonActive(true);
        resetData();
        closeModal();
        setOpenModal(false);
      }}
      title={'Add Message Template'}
      primaryAction={{
        content: 'Save',
        onAction: () => saveTemplate(userId),
        disabled: !isSendButtonActive,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            setIsSendButtonActive(true);
            resetData();
            closeModal();
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>{getTemplateMarkup()}</Modal.Section>
    </Modal>
  );
}

export default CreateTemplateModal;
