import { useState, useEffect } from 'react';

import {
  DatePicker,
  Button,
  Popover,
  FormLayout,
  TextField,
  Stack,
  Card,
  DataTable,
  Link,
  Badge,
  Pagination,
  SkeletonBodyText,
  TextStyle,
} from '@shopify/polaris';

import { PageDownMajor } from '@shopify/polaris-icons';
import moment from 'moment';
import {
  exportNumbers,
  getNumbers,
  getTotalNumbers,
} from '../../../redux/actions/optIn';
import { mobilecheck } from '../../../utils/helper';
import { useShopStore } from '../../../hooks/shopStore';

const momentFormat = 'YYYY-MM-DD';

export default function ExportPage(props: any) {
  const { shopDetailsData } = useShopStore();
  const { setToast, setErrorToast, setShowErrorToast, setShowSuccessToast } =
    props;
  const pageSize = 10;
  const [shopId, setShopId] = useState(null);
  const [userId, setUserId] = useState('');
  const [shopUrl, setShopUrl] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const [email, setEmail] = useState(
    localStorage.getItem('sl-wa-optin-email') || ''
  );
  const [startInput, setStartInput] = useState(
    moment().subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [endInput, setEndInput] = useState(moment().format('YYYY-MM-DD'));
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);
  const [start, setStart] = useState(
    new Date(moment().subtract(30, 'days').format())
  );
  const [end, setEnd] = useState(new Date(moment().format()));
  const [month, setMonth] = useState(
    Number(moment().subtract(30, 'days').format('MM')) - 1
  );
  const [year, setYear] = useState(
    Number(moment().subtract(30, 'days').format('YYYY'))
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading_results, setLoading_results] = useState(true);
  const [results, setResults] = useState([]);
  const [reports, setReports] = useState([]);
  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [showDateRangeError, setShowDateRangeError] = useState(false);
  const [showNext, setShowNext] = useState(true);

  useEffect(() => {
    toggleLoading();

    let shop = shopDetailsData;

    if (shop && shop.id) {
      setShopId(shop.id);
      setUserId(shop.userId);
    }

    if (shop && shop.url) {
      setShopUrl(shop.url);
    }
  }, []);

  useEffect(() => {
    if (!userId) return;
    getNumbers(userId, currentPageNum, pageSize).then((res: any) => {
      let data = res.message;
      data.ts = res.message.modifiedOn;
      if (data && data.length < pageSize) {
        setShowNext(false);
      } else {
        setShowNext(true);
      }
      if (data && data.length > 0) {
        data.sort((a: any, b: any) => (a.ts > b.ts ? -1 : a.ts < b.ts ? 1 : 0));
        setResults(data);
        setLoading_results(false);
        toggleLoading();
      } else {
        setLoading_results(false);
        toggleLoading();
      }
    });

    // add a class to the root for css.
    document.body.className += 'optin-number-page';

    return () => {
      document.body.className = '';
    };
  }, [userId]);

  useEffect(() => {
    if (!userId) return;
    getNumbers(userId, currentPageNum, pageSize).then((res: any) => {
      let data = res.message;
      data.ts = res.message.modifiedOn;
      if (data && data.length < pageSize) {
        setShowNext(false);
      } else {
        setShowNext(true);
      }
      if (data && data.length > 0) {
        data.sort((a: any, b: any) => (a.ts > b.ts ? -1 : a.ts < b.ts ? 1 : 0));
        setResults(data);
        setLoading_results(false);
        toggleLoading();
      } else {
        setLoading_results(false);
        toggleLoading();
      }
    });
  }, [currentPageNum]);

  const handlePagination = async (num: any) => {
    setCurrentPageNum((old) => old + num);
    setLoading_results(true);
    setLoading(true);
  };

  const togglePopoverActive = () => {
    setShowPicker(!showPicker);
  };

  const activator = (
    <Button onClick={togglePopoverActive} icon={PageDownMajor} disclosure>
      Export
    </Button>
  );

  const checkValidityOfDates = (type: any, val: any) => {
    if (
      !moment(val, 'YYYY-MM-DD', true).isValid() ||
      Number(moment()) - Number(moment(val)) < 0
    ) {
      return setIsSubmitBtnDisabled(true);
    }

    var formatVal = moment(val).format('YYYY-MM-DD');

    var finalStart = moment(start).format('YYYY-MM-DD');
    var finalEnd = moment(end).format('YYYY-MM-DD');

    // if end is less than start then set start and end to be the same date.
    if (type === 'start') {
      var startValDate = new Date(Number(moment(formatVal)));
      if (startValDate > end) {
        finalEnd = formatVal;
      }
      finalStart = formatVal;
    } else {
      var endValDate = new Date(Number(moment(formatVal)));
      if (endValDate < start) {
        finalStart = formatVal;
      }
      finalEnd = formatVal;
    }

    if (moment(finalEnd).diff(moment(finalStart), 'days') + 1 > 90) {
      setIsSubmitBtnDisabled(true);
      setShowDateRangeError(true);
    } else {
      setIsSubmitBtnDisabled(false);
      setShowDateRangeError(false);
    }

    setStartInput(finalStart);
    setEndInput(finalEnd);
    setStart(new Date(Number(moment(finalStart))));
    setEnd(new Date(Number(moment(finalEnd))));
  };

  const setSelectedDates = (d: any) => {
    setStart(d.start);
    setEnd(d.end);
    setStartInput(moment(d.start).format('YYYY-MM-DD'));
    setEndInput(moment(d.end).format('YYYY-MM-DD'));

    if (moment(d.end).diff(moment(d.start), 'days') + 1 > 90) {
      setIsSubmitBtnDisabled(true);
      setShowDateRangeError(true);
    } else {
      setIsSubmitBtnDisabled(false);
      setShowDateRangeError(false);
    }
  };

  const handleMonthChange = (month: any, year: any) => {
    setMonth(month);
    setYear(year);
  };

  const toggleLoading = () => {
    setLoading(!loading);
  };
  const handleSubmit = async () => {
    if (!email) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast('Please provide an email address.');
      return;
    }

    // eslint-disable-next-line
    var emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailRegex.test(email)) {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast('Please provide a valid email address.');
      return;
    }

    toggleLoading();
    setIsSubmitting(true);

    const reqBody = {
      startDate: moment(start).format(momentFormat),
      endDate: moment(end).format(momentFormat),
      emailId: email,
    };

    localStorage.setItem('sl-wa-optin-email', reqBody.emailId);

    exportNumbers(userId, new URLSearchParams(reqBody))
      .then(
        (res: any) => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            window.location.href = '/install/?shop=' + shopUrl;
            return;
          }
        },
        (error) => {}
      )
      .then(() => {
        setIsSubmitting(false);
        setLoading(false);
        setStart(new Date());
        setShowPicker(false);
        setShowSuccessToast((successToast: any) => !successToast);
        setToast(
          'Your requested export will be sent as a CSV to ' +
            reqBody.emailId +
            ' shortly.'
        );
      });
  };

  const onSendClick = (order: any) => {
    if (loading_results) {
      return;
    }

    toggleLoading();

    var phone = order.phone.replace('+', '');
    var link = '';
    if (mobilecheck() || shopUrl === 'dm7store.myshopify.com') {
      link = 'https://api.whatsapp.com/send';
    } else {
      link = 'https://web.whatsapp.com/send';
    }

    link += '?phone=' + encodeURIComponent(phone);

    // update the state.
    const updatedReports: any = results.map((r: any, i) => {
      if (r.phone !== order.phone) {
        return r;
      } else {
        r.is_wa_msg_sent = true;
        return r;
      }
    });

    setReports(updatedReports);

    if (mobilecheck()) {
      window.open(link, '_self');
    } else {
      var wnd = null;
      wnd = window.open('', '_blank');
      wnd && (wnd.location.href = link);
    }
  };

  const getSendMsgText = (order: any) => {
    if (order.phone && order.is_wa_msg_sent) {
      return <Link onClick={() => onSendClick(order)}>Sent</Link>;
    } else if (order.phone && !order.is_wa_msg_sent) {
      return <Link onClick={() => onSendClick(order)}>Send Message</Link>;
    } else if (!order.phone) {
      return <Badge status='warning'>Phone Unavailable</Badge>;
    }
  };

  const rows = results.map((o: any) => {
    return [
      o.phone,
      `${o.firstName || ''} ${o.lastName || ''}`,
      <Stack vertical spacing='extraTight'>
        <p>{o.noOfOrders}</p>
      </Stack>,
      <Stack vertical spacing='extraTight'>
        <p>{o.totalOrderValue?.toFixed(2)}</p>
      </Stack>,
      <Stack vertical spacing='extraTight'>
        <p>{o.lastAbCartValue?.toFixed(2)}</p>
      </Stack>,
      <Stack vertical spacing='extraTight'>
        {!!o.optedIn ? (
          <p style={{ color: 'green' }}>Opted-in</p>
        ) : (
          <p style={{ color: 'red' }}> Not opted-in</p>
        )}
      </Stack>,

      <Stack vertical spacing='extraTight'>
        <p>
          {moment(new Date(parseInt(o.createdOn))).format(
            'MMMM Do YYYY, hh:mm a'
          )}
        </p>
      </Stack>,
      <Stack vertical spacing='extraTight'>
        <p>{o.source}</p>
      </Stack>,

      <Stack vertical spacing='extraTight'>
        <p>{getSendMsgText(o)}</p>
      </Stack>,
    ];
  });

  const selectedDates = { start, end };
  return (
    <div className='optin-number-page' style={{ marginTop: '1%' }}>
      <div style={{ marginBottom: '1rem' }} className='optin-number-page'></div>
      {!loading_results ? (
        <Card>
          <DataTable
            columnContentTypes={['text', 'text', 'text', 'text']}
            headings={[
              'Phone Number',
              'Customer Name',
              'Number of Orders',
              'Total Order Value',
              'Abandoned Cart Value',
              'Optin',
              'Optin Date',
              'Optin Source',
              'Whatsapp',
            ]}
            rows={rows}
            footerContent={
              <Stack vertical>
                <Pagination
                  hasPrevious={currentPageNum > 0}
                  onPrevious={() => handlePagination(-1)}
                  hasNext={showNext}
                  onNext={() => handlePagination(1)}
                />
              </Stack>
            }
          />
        </Card>
      ) : (
        <Card sectioned>
          <SkeletonBodyText />
        </Card>
      )}
    </div>
  );
}
