import {
  Button,
  Card,
  Heading,
  Icon,
  Link,
  Stack,
  TextContainer,
  TextStyle,
  Tooltip,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import Switch from "react-switch";
import "./optinwidget.scss";
import { ExternalMinor, SettingsMajor } from "@shopify/polaris-icons";
import {
  OPTIN_WIDGETS_PAGE,
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_DUTCH,
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_TURK,
} from "../../../constants/constants";
import { useShopStore } from '../../../hooks/shopStore';

const SettingCard = (props: any) => {
  const { shopDetailsData } = useShopStore();
  const [widgetEnabled, setWidgetEnabled] = useState(
    props.enabled ? props.enabled : false
  );
  let shop = shopDetailsData;

  useEffect(() => {
    setWidgetEnabled(props.enabled);
  }, [props]);

  const renderStorefrontWidget = () => {
    return (
      <>
        <div className="topRow">
          <div className="title">
            <b>Storefront Widget</b>{" "}
            <TextStyle variation="subdued">(Default)</TextStyle>
          </div>
          {props.disabled ? (
            <Tooltip
              preferredPosition="above"
              content="This option is available after getting approval for Checkout Widget"
            >
              <div className="switch">
                <Switch
                  onChange={(val) => props.handleOptinChange("enabled", val)}
                  checked={widgetEnabled}
                  onColor="#5DC001"
                  onHandleColor="#fff"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 6px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  handleDiameter={30}
                  disabled={props.disabled}
                />
                <div className="switchValue">
                  <TextStyle variation="subdued">
                    {widgetEnabled ? "Enabled" : "Disabled"}
                  </TextStyle>
                </div>
              </div>
            </Tooltip>
          ) : (
            <div className="switch">
              <Switch
                onChange={(val) => props.handleOptinChange("enabled", val)}
                checked={widgetEnabled}
                onColor="#5DC001"
                onHandleColor="#fff"
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 6px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
                handleDiameter={30}
                disabled={props.disabled}
              />
              <div className="switchValue">
                <TextStyle variation="subdued">
                  {widgetEnabled ? "Enabled" : "Disabled"}
                </TextStyle>
              </div>
            </div>
          )}
        </div>
        <div className="middle">
          <TextContainer>
            <TextStyle variation="subdued">
              {
                "This widget opens a pop-up where the user can enter their Whatsapp number and “optin” to receive messages from your business"
              }
            </TextStyle>
          </TextContainer>
          <br />
        </div>
        <div className="bottomRow">
          <div className="stats">
            <div className="optinStat">
              Optins Collected {" : "} {props.stats}
            </div>
            {/* <div className="revenueStat">
                        Revenue Generated {" : "} 0$
                    </div> */}
          </div>
          <div className="bottomActions">
            <div
              className="primaryAction"
              onClick={() => {
                props.setPage(props.type);
              }}
            >
              <div className="primaryExtenalIcon">
                <Icon source={SettingsMajor} color={"interactive"}></Icon>
              </div>
              <div className="primaryLinkText">Edit Configuration</div>
            </div>
            <div
              className="secondaryAction"
              onClick={() => {
                window.open(getStoreOptinWidgetPreview(), "_blank");
              }}
            >
              <div className="secondaryExtenalIcon">
                <Icon source={ExternalMinor} color={"interactive"}></Icon>
              </div>
              <div className="secondaryLinkText">Preview</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderThankYouPageWidget = () => {
    return (
      <>
        <div className="topRow">
          <div className="title">
            <b>Thank you Page Widget</b>
          </div>
          <div className="switch">
            <Switch
              onChange={() => {
                setWidgetEnabled(!widgetEnabled);
              }}
              checked={widgetEnabled}
              onColor="#5DC001"
              onHandleColor="#fff"
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 6px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              handleDiameter={30}
              disabled={props.disabled}
            />
            <div className="switchValue">
              <TextStyle variation="subdued">
                {widgetEnabled ? "Enabled" : "Disabled"}
              </TextStyle>
            </div>
          </div>
        </div>
        <div className="middle">
          <TextContainer>
            <TextStyle variation="subdued">
              {
                "This widget adds a input field at order summary page to collect optin from your customers."
              }
            </TextStyle>
          </TextContainer>
          <br />
        </div>
        <div className="bottomRow">
          <div className="stats">
            {/* <div className="optinStat">
                        Optins Collected {" : "} 0 
                    </div>
                    <div className="revenueStat">
                        Revenue Generated {" : "} 0$
                    </div> */}
          </div>
          <div className="bottomActions">
            <div
              className="secondaryAction"
              onClick={() => {
                window.open(getThankYouPageOptinWidgetPreview(), "_blank");
              }}
            >
              <div className="secondaryExtenalIcon">
                <Icon source={ExternalMinor} color={"interactive"}></Icon>
              </div>
              <div className="secondaryLinkText">Preview</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderCheckoutWidget = () => {
    return (
      <>
        <div className="topRow">
          <div className="title">
            <b>Checkout Widget</b>
          </div>
          <div className="switch">
            <div className="switchValue">
              <TextStyle variation="subdued">
                {props.configured ? "Configured" : "Not Configured"}
              </TextStyle>
            </div>
          </div>
        </div>
        <div className="middle-50">
          <TextContainer>
            <TextStyle variation="subdued">
              {
                "This widget collects optins from your customers at the time of checkout."
              }
            </TextStyle>
          </TextContainer>
          <br />
        </div>
        <div className="bottomRow">
          <div className="stats">
            <Button
              primary
              onClick={() => {
                props.setPage(props.type);
              }}
            >
              Configure Widget
            </Button>
            <div className="optinStat">
              Optins Collected {" : "} {props.stats}
            </div>
          </div>
          <div className="bottomActions">
            {/* <div className="secondaryAction" >
                        <div className="greenMonochromeButton">
                            <Button outline monochrome>
                                Preview
                            </Button>
                        </div>
                    </div> */}
          </div>
        </div>
      </>
    );
  };

  const renderSpinTheWheel = () => {
    return (
      <>
        <div className="topRow">
          <div className="title">
            <b>Spin the Wheel</b>
          </div>
          <div className="switch">
            <div className="switchValue">
              <TextStyle variation="subdued">
                {props.configured ? "Configured" : "Not Configured"}
              </TextStyle>
            </div>
          </div>
        </div>
        <div className="middle">
          <TextContainer>
            <TextStyle variation="subdued">
              {
                "Spin the wheel is designed to ensure high conversions by offering discounts to customers and also getting their WhatsApp optins for further engagement."
              }
            </TextStyle>
          </TextContainer>
          <br />
        </div>
        <div className="bottomRow">
          <div className="stats">
            <Button
              primary
              onClick={() => {
                props.setPage(props.type);
              }}
            >
              Configure Widget
            </Button>
            <div className="optinStat">
              Optins Collected {" : "} {props.stats}
            </div>
          </div>
          <div className="bottomActions">
            <div
              className="secondaryAction"
              onClick={() => {
                window.open("https://" + shop.url, "_blank");
              }}
            >
              <div className="secondaryExtenalIcon">
                <Icon source={ExternalMinor} color={"interactive"}></Icon>
              </div>
              <div className="secondaryLinkText">View on store</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderOptInViaChatWidget = () => {
    return (
      <>
        <div className="topRow">
          <div className="title">
            <b>Opt-in Via Chat Widget</b>{" "}
            <TextStyle variation="subdued">(Default)</TextStyle>
          </div>
          <div className="switch">
            <Switch
              onChange={props.toggleOptinEnabled}
              checked={widgetEnabled}
              onColor="#5DC001"
              onHandleColor="#fff"
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 6px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              handleDiameter={30}
            />
            <div className="switchValue">
              <TextStyle variation="subdued">
                {widgetEnabled ? "Enabled" : "Disabled"}
              </TextStyle>
            </div>
          </div>
        </div>
        <div className="middle">
          <TextContainer>
            <TextStyle variation="subdued">
              {
                "This widget allows you to collect customer numbers & their WhatsApp opt-in via chat widget."
              }
            </TextStyle>
          </TextContainer>
          <br />
        </div>
        <div className="bottomRow">
          <div className="stats">
            {/* <div className="optinStat">
                        Optins Collected {" : "} 0 
                    </div> */}
            {/* <div className="revenueStat">
                        Revenue Generated {" : "} 0$
                    </div> */}
          </div>
          <div className="bottomActions">
            <div
              className="primaryAction"
              onClick={() => {
                props.setPage(props.type);
              }}
            >
              <div className="primaryExtenalIcon">
                <Icon source={SettingsMajor} color={"interactive"}></Icon>
              </div>
              <div className="primaryLinkText">Edit Configuration</div>
            </div>
            <div
              className="secondaryAction"
              onClick={() => {
                window.open(
                  "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Optin_Via_Chat_Preview.png?v=1672224796",
                  "_blank"
                );
              }}
            >
              <div className="secondaryExtenalIcon">
                <Icon source={ExternalMinor} color={"interactive"}></Icon>
              </div>
              <div className="secondaryLinkText">Preview</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderContent = () => {
    switch (props.type) {
      case 0:
        return renderStorefrontWidget();
      case 1:
        return renderThankYouPageWidget();
      case 2:
        return renderCheckoutWidget();
      case 3:
        return renderSpinTheWheel();
      case 4:
        return renderOptInViaChatWidget();
    }
  };

  const getThankYouPageOptinWidgetPreview = () => {
    switch (props.language) {
      case TEMPLATE_LANGUAGE_ENG:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_ENG.png?1570";
      case TEMPLATE_LANGUAGE_PORT:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_POR.png?1570";
      case TEMPLATE_LANGUAGE_SPAN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_SPA.png?1570";
      case TEMPLATE_LANGUAGE_ITAL:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_ITA.png?1570";
      case TEMPLATE_LANGUAGE_FREN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/FR_-_thank_you_page_widget.png?v=1582884364";
      case TEMPLATE_LANGUAGE_INDO:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_IDA_updated.png?v=1587802222";
      case TEMPLATE_LANGUAGE_GERM:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/GER_-_thank_you_page_widget.png?v=1582884364";
      case TEMPLATE_LANGUAGE_ARAB:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_ARABIC.png?v=1586931260";
      case TEMPLATE_LANGUAGE_TURK:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_TURKISH.png?v=1587874601";
      case TEMPLATE_LANGUAGE_HEBR:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_HEBREW.png?v=1591681715";
      case TEMPLATE_LANGUAGE_DUTCH:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_thank_you_page_opt_in_widget_-_DUTCH.png?v=1591681715";
    }
  };

  const getStoreOptinWidgetPreview = () => {
    switch (props.language) {
      case TEMPLATE_LANGUAGE_ENG:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ThankYouOptinGuide.png?v=1672148065";
      case TEMPLATE_LANGUAGE_PORT:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_POR.png?1562";
      case TEMPLATE_LANGUAGE_SPAN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_SPA.png?1562";
      case TEMPLATE_LANGUAGE_ITAL:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_ITA.png?1566";
      case TEMPLATE_LANGUAGE_FREN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/FR_-_optin_widget.png?v=1582878068";
      case TEMPLATE_LANGUAGE_INDO:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_IDA_-_updated.png?v=1587802221";
      case TEMPLATE_LANGUAGE_GERM:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/GER_-_optin_widget.png?v=1582883329";
      case TEMPLATE_LANGUAGE_ARAB:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_ARABIC.png?v=1586931259";
      case TEMPLATE_LANGUAGE_TURK:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_TURKISH.png?v=1587874600";
      case TEMPLATE_LANGUAGE_HEBR:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_HEBREW.png?v=1591681714";
      case TEMPLATE_LANGUAGE_DUTCH:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/whatsapp_opt_in_widget_-_DUTCH.png?v=1591681714";
    }
  };

  return (
    <>
      <Card>
        <div className="cardCover">{renderContent()}</div>
      </Card>
    </>
  );
};

export default SettingCard;
