// @ts-nocheck
import { useState, useCallback, useEffect } from 'react';
import {
  Page,
  Layout,
  Popover,
  ActionList,
  Button,
  Modal,
} from '@shopify/polaris';
import { TUTORIAL_VIDEOS } from '../../constants/constants';
import MyCampaigns from './MyCampaigns';
import './index.scss';
import { PlayCircleMajor } from '@shopify/polaris-icons';
import { clevertapEventPush, CT_EVENT_IDS } from '../../utils/clevertapUtils';
import { getplanPaymentInfo } from '../../utils/helper';
import MarketingGuideModal from './MarketingGuideModal';
import PaymentFailed from '../../common/PaymentFailed';
import TooltipOverlay from '../../common/Tooltip';
import obj from '../../content/Campaigns/OverlaysContent/en.json';
import { useShopStore } from '../../hooks/shopStore';

const CommonTemplates = (props: any) => {
  const { shopDetailsData, planPaymentInfo } = useShopStore();
  const showPaymentFailedUI = getplanPaymentInfo(planPaymentInfo);
  const [currentSelectedTemplateId, setCurrentSelectedTemplateId] = useState(
    props?.location?.state || ''
  );

  const [popoverActive, setPopoverActive] = useState(false);
  const [tutorialVideos, setTutorialVideos] = useState([]);
  const [videoTutorial, setVideoTutorial] = useState({
    open: false,
    videoSrc: '',
  });
  const [open_marketing_campaign_modal, setopen_marketing_campaign_modal] =
    useState(false);
  const [marketing_campaign_modal_steps_completed] = useState(false);
  const [guideStep, setGuideStep] = useState(0);
  const [openGuide, setOpenGuide] = useState(false);

  const marketingCampaignModalStepsCompleted = () => {
    setopen_marketing_campaign_modal(false);
  };

  const closeMarketingCampaignGuideModal = () => {
    setopen_marketing_campaign_modal(false);
    if (guideStep < 4) {
      clevertapEventPush({
        eventId: CT_EVENT_IDS.GUIDE_CLOSED,
        eventData: {},
      });
    } else if (guideStep == 4) {
      clevertapEventPush({
        eventId: CT_EVENT_IDS.GUIDE_COMPLETED,
        eventData: {},
      });
    }
  };

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );
  const activator = (
    <div className='videoTutsButton'>
      <Button icon={PlayCircleMajor} onClick={togglePopoverActive} disclosure>
        {TUTORIAL_VIDEOS.TITLE}
      </Button>
    </div>
  );

  useEffect(() => {
    let tmpData: any = [];
    TUTORIAL_VIDEOS.MY_CAMPAIGNSPAGE.forEach((video: any) => {
      let tmpStep = {
        content: video.content,
        onAction: () => showVideoModal(video.videoSrc),
      };
      tmpData.push(tmpStep);
    });
    setTutorialVideos(tmpData);
  }, []);

  const showVideoModal = (videoLinks: string) => {
    setVideoTutorial({ open: true, videoSrc: videoLinks });
  };

  useEffect(() => {
    setCurrentSelectedTemplateId(props.location.state);
  }, [props.location.state]);

  return (
    <>
      {showPaymentFailedUI.downgraded ? (
        <PaymentFailed failedOn={showPaymentFailedUI.downgradedOn} />
      ) : (
        <>
          <div className='videoTutsButton tooltip-overlay padRight5'>
            <Popover
              active={popoverActive}
              activator={activator}
              onClose={togglePopoverActive}
            >
              <ActionList actionRole='tutorialVideo' items={tutorialVideos} />
            </Popover>
          </div>
          <TooltipOverlay
            btn1Action={() => setopen_marketing_campaign_modal(true)}
            obj={obj}
          />
          <Page title={'Marketing Campaigns'} divider>
            <Layout>
              <div className='w-100'>
                <MyCampaigns
                  currentSelectedTemplateId={currentSelectedTemplateId}
                  setCurrentSelectedTemplateId={setCurrentSelectedTemplateId}
                />
              </div>
            </Layout>
            <Modal
              open={videoTutorial.open}
              sectioned={true}
              title={TUTORIAL_VIDEOS.TITLE}
              onClose={() => {
                setVideoTutorial({ open: false, videoSrc: '' });
              }}
            >
              <iframe
                id='ytplayer'
                width='100%'
                height='360'
                allow='fullscreen'
                src={videoTutorial.videoSrc}
                frameBorder='0'
                title={TUTORIAL_VIDEOS.TITLE}
              ></iframe>
            </Modal>
            {open_marketing_campaign_modal ? (
              <MarketingGuideModal
                open={true}
                completed={marketing_campaign_modal_steps_completed}
                closeModal={closeMarketingCampaignGuideModal}
                stepsCompleted={marketingCampaignModalStepsCompleted}
                shopName={shopDetailsData}
                setGuideStep={setGuideStep}
              />
            ) : null}
          </Page>
        </>
      )}
    </>
  );
};
export default CommonTemplates;
