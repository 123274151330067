import {
  Heading,
  Stack,
  TextContainer,
  TextStyle,
  Tooltip,
} from "@shopify/polaris";
import QuestionMark from "../../../assets/images/QuestionMark";
import TooltipContent from "./ToolTipContent";

type TextFieldSectionProps = {
  id: string;
  isTooltip: boolean;
  fieldLabel: string;
  fieldMessage: string;
  children: React.ReactNode;
};

const TextFieldSection = ({
  id,
  fieldLabel,
  isTooltip,
  fieldMessage,
  children,
}: TextFieldSectionProps) => {
  return (
    <TextContainer spacing="loose">
      <Stack>
        <TextStyle variation="strong">
          <div className="d-flex">
            {fieldLabel}
            {isTooltip && (
              <Tooltip content={<TooltipContent fieldName={id} />}>
                <div className="d-flex">
                  <QuestionMark />
                </div>
              </Tooltip>
            )}
          </div>
        </TextStyle>
      </Stack>
      <TextStyle variation="subdued">{fieldMessage}</TextStyle>
      {children}
    </TextContainer>
  );
};

export default TextFieldSection;
