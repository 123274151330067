import { Icon, Link } from "@shopify/polaris";
import React, { Fragment } from "react";
import { PhoneMajor, ArrowLeftMinor } from "@shopify/polaris-icons";
import "../assets/css/whatsappContainer.scss";
import { options } from "../constants/constants";
import ChatMetricIcon from "../assets/icons/chat_click_metric.svg";
import mediaImage from "../assets/images/document.png";
import mediaImagePreview from "../assets/images/image.png";
import mediaVideoPreview from "../assets/images/video.png";
import mediaDocumentPreview from "../assets/images/document.png";

const WhatsappContainer = ({ messageTemplate }: any) => {
  return (
    <div className="sl-mobile-main-container">
      <p className="preview">Preview</p>
      <div className="sl-mobile-container">
        <div className="five wide mobile hidden column sl-mobile">
          <div className="whatsappHeader">
            <div className="marginVAuto">
              <Icon source={ArrowLeftMinor} color="base" />
            </div>
            <div className="marginVAuto">
              <img src={ChatMetricIcon} alt="profile" />
            </div>
            <div className="marginVAuto white">ABC Ltd.</div>
          </div>
          <div className="whatsapp-message-container">
            <div className="whatsapp-preview-header"></div>
            <div className="whatsapp-message-inner-container">
              <div style={{ display: "flex" }}>
                <span data-testid="tail-in" data-icon="tail-in" className="">
                  <svg viewBox="0 0 8 15" width="8" height="15" className="">
                    <path
                      opacity=".13"
                      fill="#ffffff"
                      d="M1.533 3.568 8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
                    ></path>
                    <path
                      fill="white"
                      d="M1.533 2.568 8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
                    ></path>
                  </svg>
                </span>
                <div
                  className="whatsapp-message received"
                  style={{
                    borderRadius:
                      messageTemplate?.buttons?.length > 0
                        ? "2px 12px 0px 0px"
                        : "2px 12px 12px 12px",
                  }}
                >
                  <div className="header">
                    {messageTemplate.header ? messageTemplate.header : ""}
                  </div>
                  <div className="body">
                    <div className="pre">
                      {!!messageTemplate.image ? (
                        <img
                          src={mediaImage}
                          alt="media"
                          style={{ background: "grey", width: "100%" }}
                        />
                      ) : (
                        ""
                      )}
                      <p style={{ margin: "5px" }}>
                        {messageTemplate.type === options.TYPE[2].value && (
                          <img
                            src={mediaImagePreview}
                            alt="media"
                            style={{ background: "grey", width: "100%" }}
                          />
                        )}
                        {messageTemplate.type === options.TYPE[3].value && (
                          <img
                            src={mediaVideoPreview}
                            alt="media"
                            style={{ background: "grey", width: "100%" }}
                          />
                        )}
                        {messageTemplate.type === options.TYPE[4].value && (
                          <img
                            src={mediaDocumentPreview}
                            alt="media"
                            style={{ background: "grey", width: "100%" }}
                          />
                        )}
                        {messageTemplate.body}
                      </p>
                    </div>
                    <div className="footer">
                      {messageTemplate.footer ? messageTemplate.footer : ""}
                    </div>
                    <div className="metadata">
                      <span className="time">10:37 AM</span>
                    </div>
                  </div>
                </div>
              </div>
              {messageTemplate?.buttons?.length > 0 ? (
                <div className="quick_reply_buttons">
                  {messageTemplate.buttons.map((e: any) => {
                    if (e.type === "URL") {
                      return (
                        <div
                          className="call_to_action_button"
                          style={{
                            direction: "rtl",
                            borderRadius:
                              messageTemplate?.buttons?.length === 1
                                ? "0px 0px 10px 10px"
                                : "0px",
                          }}
                        >
                          <Link external={true} url={e.value}>
                            {e.name}
                          </Link>
                        </div>
                      );
                    } else {
                      return (
                        <div className="call_to_action_buttons">
                          <div
                            className="call_to_action_button"
                            style={{
                              direction: "rtl",
                              borderRadius: "0px 0px 10px 10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                direction: "rtl",
                                justifyContent: "center",
                              }}
                            >
                              <div>{e.name} </div>
                              <Icon source={PhoneMajor} color="base"></Icon>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              ) : (
                ""
              )}
              {/* {whatsappMessage.callToAction ? (
              
            ) : (
              ''
            )}
            <div className='quick_reply_buttons'>
              <div className='quick_reply_button'>gfhgfhg</div>
              <div className='quick_reply_button'>7779</div>
            </div> */}
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};
export default WhatsappContainer;
