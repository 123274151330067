import { Modal, Select, TextField } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { createTag } from "../../../../redux/actions/manual-messaging";

function CreateTagModal({
  showToast,
  showErrorToast,
  open,
  setactivateCreateTemplate,
  closeModal,
  userId,
}: any) {
  const [openModal, setOpenModal] = useState(open);
  const [selectedTemplate, setSelectedTemplate] = useState({
    name: "",
    category: "",
  });
  const [initialTemplate, setInitialTemplate] = useState({
    name: "",
    category: "",
  });
  const [activateErrorCheckForName, setActivateErrorCheckForName] =
    useState(false);
  const [activateErrorCheckForContent, setActivateErrorCheckForContent] =
    useState(false);
  const [isSendButtonActive, setIsSendButtonActive] = useState(true);

  const categories = [
    { label: "Abandoned Cart", value: "CART" },
    { label: "Orders", value: "ORDER" },
  ];

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  const resetData = () => {
    setSelectedTemplate(initialTemplate);
    setActivateErrorCheckForContent(false);
    setActivateErrorCheckForName(false);
  };

  const validateForm = () => {
    let template = selectedTemplate;
    if (!template.name) {
      return "Tag name can not be empty";
    } else if (!template.category) {
      return "Please select a category";
    } else if (validateTemplateName() !== false) {
      return "Tag name should be less than 50 characters";
    }
    return false;
  };

  const validateTemplateName = () => {
    if (activateErrorCheckForName) {
      let template = selectedTemplate;
      if (template.name.length > 50) {
        return "Tag name should be less than 50 characters";
      }
      if (template.name.length <= 0) {
        return "Tag name cannot be empty";
      }
    }
    return false;
  };

  const handleChange = (val: any, field: string) => {
    if (!activateErrorCheckForName && field === "name") {
      setActivateErrorCheckForName(true);
    }
    if (!activateErrorCheckForContent && field === "message") {
      setActivateErrorCheckForContent(true);
    }
    setSelectedTemplate((template: any) => ({ ...template, [field]: val }));
  };

  const saveTemplate = (userId: string) => {
    let params = new URLSearchParams();
    params.append("name", selectedTemplate.name);
    params.append("category", selectedTemplate.category);

    let error = validateForm();
    if (error) {
      showErrorToast(error);
      return;
    } else {
      setIsSendButtonActive(false);
      createTag(userId, params).then(
        (response: any) => {
          if (response.status === "success") {
            showToast("Tag created successfully");
            resetData();
            setActivateErrorCheckForContent(false);
            setActivateErrorCheckForName(false);
            setIsSendButtonActive(true);
            closeModal();
          } else {
            showErrorToast(response);
            setIsSendButtonActive(true);
          }
        },
        (err) => {
          showErrorToast("Unable to create tag");
          setIsSendButtonActive(true);
        }
      );
    }
  };

  const getTemplateMarkup = () => {
    return (
      <>
        <TextField
          label="Name"
          value={selectedTemplate.name}
          onChange={(val) => {
            handleChange(val, "name");
          }}
          autoComplete="off"
          error={validateTemplateName()}
        />
        <br />
        <Select
          label="Select Category"
          options={categories}
          onChange={(val) => {
            handleChange(val, "category");
          }}
          placeholder="Select Category"
          value={selectedTemplate.category}
        />
      </>
    );
  };

  return (
    <Modal
      small
      open={openModal}
      onClose={() => {
        setIsSendButtonActive(true);
        resetData();
        closeModal();
        setOpenModal(false);
      }}
      title={"Add new Tag"}
      primaryAction={{
        content: "Save",
        onAction: () => saveTemplate(userId),
        disabled: !isSendButtonActive,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setIsSendButtonActive(true);
            resetData();
            closeModal();
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>{getTemplateMarkup()}</Modal.Section>
    </Modal>
  );
}

export default CreateTagModal;
