// @ts-nocheck
import {
  TextStyle,
  Button,
  FormLayout,
  Popover,
  Stack,
  TextField,
  RadioButton,
} from '@shopify/polaris';
import { useState } from 'react';
import moment from 'moment';

const dateFilterType = [
  'Last week',
  // 'Last month',  // TODO: just uncomment this two line to enable this dateFilter option
  // 'Last 3 months',
  'Customize',
];

type DateFilterProps = {
  showPicker: any;
  togglePopoverActive: any;
  handleSubmit: any;
  isSubmitting: boolean;
};

function DateFilter({
  showPicker,
  togglePopoverActive,
  handleSubmit,
  isSubmitting,
}: DateFilterProps) {
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);
  const [showDateRangeError, setShowDateRangeError] = useState(false);
  const [filter, setFilter] = useState({
    type: dateFilterType[0],
    startDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });
  const minDateLimit = moment().subtract(6, 'months'); // Data upto last 6 months should be fetchable

  const activator : any = (
    <Button onClick={togglePopoverActive} disclosure>
      {filter.type}
    </Button>
  );

  const handleDateChange = (key: any, value: any) => {
    setFilter({
      ...filter,
      [key]: value,
    });
    checkValidityOfDates(key, value);
  };

  const handleFilterTypeChange = (value: any) => {
    const newFilter = { ...filter };
    newFilter.type = value;
    if (value === 'Last week') {
      newFilter.startDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
      newFilter.endDate = moment().format('YYYY-MM-DD');
    } else if (value === 'Last month') {
      newFilter.startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
      newFilter.endDate = moment().format('YYYY-MM-DD');
    } else if (value === 'Last 3 months') {
      newFilter.startDate = moment().subtract(3, 'month').format('YYYY-MM-DD');
      newFilter.endDate = moment().format('YYYY-MM-DD');
    }
    setFilter(newFilter);
  };

  const checkValidityOfDates = (type: any, val: any) => {
    if (
      !moment(val, 'YYYY-MM-DD', true).isValid() ||
      Number(moment()) - Number(moment(val)) < 0
    ) {
      return setIsSubmitBtnDisabled(true);
    }

    var formatVal = moment(val).format('YYYY-MM-DD');
    var finalStart = moment(filter.startDate).format('YYYY-MM-DD');
    var finalEnd = moment(filter.endDate).format('YYYY-MM-DD');

    // if end is less than start then set start and end to be the same date.
    if (type === 'startDate') {
      if (moment(formatVal).isAfter(filter.endDate)) {
        finalEnd = formatVal;
      }
      finalStart = formatVal;
    } else {
      if (moment(formatVal).isBefore(filter.startDate)) {
        finalStart = formatVal;
      }
      finalEnd = formatVal;
    }
    if (moment(minDateLimit).diff(moment(finalStart), 'days') > 0) {
      setIsSubmitBtnDisabled(true);
      setShowDateRangeError(true);
    } else {
      setIsSubmitBtnDisabled(false);
      setShowDateRangeError(false);
    }
    setFilter({
      ...filter,
      startDate: finalStart,
      endDate: finalEnd,
    });
  };

  return (
    <Popover
      activator={activator}
      active={showPicker}
      onClose={togglePopoverActive}
      ariaHaspopup={false}
      preferredPosition='mostSpace'
      preferredAlignment='right'
    >
      <Popover.Pane sectioned>
        <FormLayout>
          <Stack distribution='fillEvenly'>
            {dateFilterType.map((filterType, index) => (
              <RadioButton
                key={index}
                label={filterType}
                helpText=''
                checked={filterType === filter.type}
                id={filterType}
                name='filter-type'
                onChange={() => handleFilterTypeChange(filterType)}
              />
            ))}
          </Stack>
          <div>
            {filter.type === 'Customize' && (
              <>
                {showDateRangeError ? (
                  <TextStyle variation='negative'>
                    Data should be fetchable upto last 6 months
                  </TextStyle>
                ) : null}
                <TextField
                  label='Starting date'
                  type='date'
                  value={filter.startDate}
                  onChange={(v) => {
                    handleDateChange('startDate', v);
                  }}
                  min={minDateLimit.format('YYYY-MM-DD')}
                  max={moment().format('YYYY-MM-DD')}
                />
                <TextField
                  label='Ending date'
                  type='date'
                  value={filter.endDate}
                  onChange={(v) => {
                    handleDateChange('endDate', v);
                  }}
                  min={minDateLimit.format('YYYY-MM-DD')}
                  max={moment().format('YYYY-MM-DD')}
                />
              </>
            )}
          </div>
        </FormLayout>
      </Popover.Pane>
      <Popover.Pane fixed sectioned>
        <Stack>
          <Stack.Item fill>
            <Button onClick={togglePopoverActive}>Cancel</Button>
          </Stack.Item>
          <Button
            primary
            loading={isSubmitting}
            onClick={() => handleSubmit(filter)}
            disabled={isSubmitBtnDisabled}
          >
            Submit
          </Button>
        </Stack>
      </Popover.Pane>
    </Popover>
  );
}

export default DateFilter;
