import {
  ACCOUNT_OAUTH_USER_LOGIN,
  ACCOUNT_OAUTH_USER_SIGNUP,
  LOGIN_GOOGLE,
  LOGIN_WHATSAPP,
  OWNER_PROFILE,
  VERIFY_OTP,
  LOGOUT,
  ACCOUNT_OAUTH_USER_FORGOT_PASSWORD,
  ACCOUNT_OAUTH_USER_RESET_PASSWORD,
} from '../../constants/api-constants';
import httpClient from '../../utils/http-client';

export const getRedirectionUrl = () => {
  return httpClient.get(LOGIN_WHATSAPP.api.GET_REDIRECTIONAL_URL, {});
};

export const getGoogleRedirectionUrl = () => {
  return httpClient.get(LOGIN_GOOGLE.api.GET_GOOGLE_REDIRECTION_URL, {});
};

export const getUserDetails = (params: any) => {
  return httpClient.post(LOGIN_WHATSAPP.api.IS_AUTHENTICATED, params);
};

export const getAuthenticated = (token: any) => {
  return httpClient.get(`${LOGIN_WHATSAPP.api.COOKIE_SET}?token=${token}`);
};

export const getAuthenticated2 = (token: any) => {
  return httpClient.get(`${LOGIN_WHATSAPP.api.COOKIE_SET2}?token=${token}`);
};

export const user_signup = (params: any) => {
  return httpClient.post(ACCOUNT_OAUTH_USER_SIGNUP, params);
};

export const user_forgot_password = (params: any) => {
  return httpClient.post(ACCOUNT_OAUTH_USER_FORGOT_PASSWORD, params);
};

export const user_reset_password = (params: any) => {
  return httpClient.post(ACCOUNT_OAUTH_USER_RESET_PASSWORD, params);
};

export const user_login = (params: any) => {
  return httpClient.post(ACCOUNT_OAUTH_USER_LOGIN, params, {
    withCredentials: true,
  });
};

export const user_profile = (userId: any, params: any) => {
  return httpClient.post(OWNER_PROFILE.replace('$userId', userId), params);
};

export const verify_otp = (userId: any, params: any) => {
  return httpClient.post(VERIFY_OTP.replace('$userId', userId), params);
};

export const logoutUser = () => {
  return httpClient.get(LOGOUT);
};
