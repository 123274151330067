// @ts-nocheck
import {
  Button,
  Card,
  Form,
  FormLayout,
  Layout,
  RadioButton,
  Select,
  Stack,
  TextField,
  Toast,
  Icon,
} from "@shopify/polaris";
import { CircleCancelMinor } from "@shopify/polaris-icons";

import { useCallback, useEffect, useState } from "react";
import "./customTemplate.scss";
import TextFieldSection from "./TextFieldSection";
import {
  options,
  HIDE_VARIABLES,
  PreConfiguredTemplateVariables,
  SAMPLE_MEDIA_FILE,
  validaFormats,
} from "../../../constants/constants";
import {
  createCustomTemplates,
  addCustomTemplateMedia,
} from "../../../redux/actions/custom-templates";
import CallToActionSection from "./CallToActionSection";
import {
  parameterKeysCleverTap,
  parameterKeysConst,
  variableOptions,
} from "../../OptinNumber/utils";
import {
  clevertapEventPush,
  CT_EVENT_IDS,
} from "../../../utils/clevertapUtils";
import { invalidMediaFileSizeAndType } from "../../../utils/common";

const DYNAMIC = options["CALL_TO_ACTION_BUTTON_URL_TYPE"][0].value;

const CreateCustomTemplate = (props: any) => {
  const [formValue, setFormValue] = useState<any>({
    templateName: "",
    category: options["CATEGORY"][0].value,
    type: options["TYPE"][0].value,
    language: options["LANGUAGE"][0].value,
    body: "",
    header: "",
    footer: "",
    sampleMessage: "",
    exampleMedia: "",
    button: "none",
    suffix: "",
  });
  const [validFileFormats, setValidFileFormats] = useState("");
  const [toast, setToast] = useState({ show: false, message: "" });
  const [customVarCount, setCustomVarCount] = useState(0);

  const filteredVariableOptions = variableOptions.filter((vos) => {
    return !HIDE_VARIABLES.includes(vos.value);
  });

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };
  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });

  const [callToAction, setCallToAction] = useState([
    {
      type: "",
      name: "",
      urlType: "",
      value: "",
      example: "",
    },
  ]);
  const [callToActionCount, setCallToActionCount] = useState(1);
  const [callToActionUrlType, setCallToActionUrlType] = useState("");

  const [variables, setVariables] = useState<any>({});
  const [filteredVariables, setFilteredVariables] = useState<any>([]);
  const [mediaFileTypeSelected, setMediaFileTypeSelected] = useState(false);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [sampleMediaFileName, setSampleMediaFileName] = useState("");

  const handleActionCount = () => {
    if (callToAction.length) {
      if (
        !callToAction[0].type ||
        (callToAction[0].type &&
          callToAction[0].type === "URL" &&
          !callToAction[0].urlType)
      ) {
        setErrorToast({
          show: true,
          message: !callToAction[0].type
            ? "Type is Required"
            : "URL Type is Required",
        });
        return;
      }
      if (
        !callToAction[0].name ||
        (callToAction[0].name && callToAction[0].name.trim().length === 0)
      ) {
        setErrorToast({ show: true, message: "Button Name is Required" });
        return;
      }
      if (
        !callToAction[0].value ||
        (callToAction[0].value && callToAction[0].value.trim().length === 0)
      ) {
        setErrorToast({ show: true, message: "Button value is Required" });
        return;
      }
      options["CALL_TO_ACTION_BUTTON_TYPE"] = options[
        "CALL_TO_ACTION_BUTTON_TYPE"
      ].map((item: any) => {
        if (item.value == callToAction[0].type) item.disabled = true;
        return item;
      });
    } else {
      options["CALL_TO_ACTION_BUTTON_TYPE"].forEach((item: any) => {
        item.disabled = false;
        return item;
      });
    }

    let object = {
      type: "",
      name: "",
      urlType: "",
      value: "",
      example: "",
    };
    setCallToAction([...callToAction, object]);
    setCallToActionCount(callToActionCount + 1);
  };

  const clearData = () => {
    setFormValue({
      templateName: "",
      category: options["CATEGORY"][0].value,
      type: options["TYPE"][0].value,
      language: options["LANGUAGE"][0].value,
      body: "",
      header: "",
      footer: "",
      sampleMessage: "",
      button: "none",
      suffix: "",
    });
    setCallToAction([
      {
        type: "",
        name: "",
        urlType: "",
        value: "",
        example: "",
      },
    ]);
    setCallToActionCount(1);
    setCallToActionUrlType(DYNAMIC);
    setSampleMediaFileName("");
  };

  const formValueValidation = (fieldName: string, fieldValue: string) => {
    if (fieldName == "templateName") {
      if (fieldValue) {
        let regX = /^[a-z0-9_]*$/;
        if (!fieldValue.match(regX)) {
          return "Enter valid Template Name";
        } else if (fieldValue.length > 512) {
          return "Character limit exceeded 512";
        }
      }
    } else if (fieldName == "body") {
      if (fieldValue) {
        let regX =
          /(\{{(?:[^}{]+|\{(?:[^}{]+|\{[^}{]*\})*\})*\}}|[a-zA-Z0-9 #$%.])$/;
        let consecutiveRegx = /\{\{\d\}\}\s*{\{\d\}\}/;

        if (!fieldValue.match(regX)) {
          return "Enter valid body content";
        } else if (fieldValue.match(consecutiveRegx)) {
          return "Consecutive variable not allowed";
        } else if (fieldValue.length > 1024) {
          return "Character limit exceeded 1024";
        }
      }
    } else if (fieldName == "name") {
      if (fieldValue) {
        let regX = /^[a-zA-Z0-9 ]*$/;
        if (!fieldValue.match(regX)) {
          return "Enter valid Button Name";
        } else if (fieldValue.length > 20) {
          return "Character limit exceeded 20";
        }
      }
    } else if (fieldName === "header") {
      if (fieldValue) {
        if (fieldValue.length > 60) {
          return "Character limit exceeded 60";
        }
      }
    } else if (fieldName == "footer") {
      if (fieldValue) {
        if (fieldValue.length > 60) {
          return "Character limit exceeded 60";
        }
      }
    }
  };

  var isCustomVariableEmpty = (sampleMessage: any) => {
    var filteredVariable = sampleMessage.split(" ").filter(getCustomVariables);
    if (filteredVariable.length) return true;
    return false;
  };

  var matchBodyandSample = (body: any, sample: any) => {
    if (body.split(" ").length === sample.split(" ").length) {
      let error = false;
      let regX = /^{{[a-zA-Z0-9 _#$%.]*}}$/;
      let bodyMessage = body.split(" ");
      let sampleMessage = sample.split(" ");
      for (let i = 0; i < bodyMessage.length; i++) {
        if (!regX.test(bodyMessage[i]) && bodyMessage[i] !== sampleMessage[i]) {
          error = true;
        }
      }
      return error;
    } else {
      return true;
    }
  };

  const isValidForm = () => {
    if (!formValue.templateName) {
      return "Template Name is Required";
    } else if (!formValue.type) {
      return "Media Type is Required";
    } else if (!formValue.category) {
      return "Category is Required";
    } else if (!formValue.language) {
      return "Language is Required";
    } else if (!formValue.body) {
      return "Body is Required";
    } else if (
      formValue.type !== options.TYPE[1].value &&
      !formValue.exampleMedia
    ) {
      return "Sample Media File is Required";
    } else if (!formValue.sampleMessage) {
      return "Sample Message is Required";
    } else if (isCustomVariableEmpty(formValue.sampleMessage)) {
      return "Sample for custom variables cannot be empty";
    } else if (matchBodyandSample(formValue.body, formValue.sampleMessage)) {
      return "Body and sample message doesn't match";
    } else if (formValue.button === "callToActions") {
      if (callToActionCount === 1) {
        if (!callToAction[0].type) {
          return "Button Type is Required";
        } else if (
          !callToAction[0].name ||
          (callToAction[0].name && callToAction[0].name.trim().length === 0)
        ) {
          return "Button Name is Required";
        } else if (
          !callToAction[0].value ||
          (callToAction[0].value && callToAction[0].value.trim().length === 0)
        ) {
          return "Button Value is Required";
        } else if (callToAction[0].type === "URL" && !callToAction[0].urlType) {
          return "URL Type is Required";
        }
      } else if (callToActionCount === 2) {
        if (!callToAction[1].type) {
          return "Button Type is Required";
        } else if (
          !callToAction[1].name ||
          (callToAction[1].name && callToAction[1].name.trim().length === 0)
        ) {
          return "Button Name is Required";
        } else if (
          !callToAction[1].value ||
          (callToAction[1].value && callToAction[1].value.trim().length === 0)
        ) {
          return "Button Value is Required";
        } else if (callToAction[1].type === "URL" && !callToAction[1].urlType) {
          return "URL Type is Required";
        }
      }
    }
    let suffixNotPresent = false;
    if (callToAction.length) {
      callToAction.forEach((item: any) => {
        if (item?.type === "URL" && item?.urlType === DYNAMIC) {
          if (!formValue.suffix) {
            suffixNotPresent = true;
          } else if (!formValue.suffix.trim()) {
            suffixNotPresent = true;
          }
        }
      });
      if (suffixNotPresent) return "Example Suffix is required.";
    }
    for (const key in formValue) {
      const error = formValueValidation(key, formValue[key]);
      if (error) return error;
    }
  };

  const handleSubmit = useCallback(
    (_event) => {
      let error = isValidForm();
      if (error) {
        setErrorToast({ show: true, message: error });
      } else {
        callToAction.map((item: any) => {
          if (item?.type === "URL" && item?.urlType === DYNAMIC) {
            item.example = item.value + formValue.suffix;
            item.value = item.value + "{{1}}";
          }
          if (item?.type === "PHONE_NUMBER") {
            delete item["urlType"];
            delete item["example"];
          }
          return item;
        });

        let params = {
          shopId: props.shop.id,
          userId: props.shop.userId,
          name: formValue.templateName,
          category: formValue.category,
          type: formValue.type,
          language: formValue.language,
          header: formValue.header,
          footer: formValue.footer,
          body: formValue.body,
          exampleMedia: formValue.exampleMedia,
          example: formValue.sampleMessage,
          buttons: formValue.button == "none" ? [] : callToAction,
        };

        createCustomTemplates(props.shop.userId, params).then(
          (response: any) => {
            if (response.status === "success") {
              let regX = /^{{[a-zA-Z0-9 _#$%.]*}}$/;
              let variable = "none";
              let defined = false,
                custom = false;
              let keys = Object.keys(parameterKeysCleverTap).map(
                (e) => `{{${parameterKeysCleverTap[e]}}}`
              );
              if (
                params.body.split(" ").filter((e: any) => regX.test(e)).length
              ) {
                let variables = params.body
                  .split(" ")
                  .filter((e: any) => regX.test(e));
                variables.forEach((element: any) => {
                  if (keys.includes(element)) {
                    defined = true;
                  } else {
                    custom = true;
                  }
                });
              }
              if (defined && custom) {
                variable = "both";
              } else if (defined) {
                variable = "predefined";
              } else if (custom) {
                variable = "custom";
              }
              let eventData = {
                "Template type": params.type,
                Category: params.category,
                Language: options.LANGUAGE.filter(
                  (e: any) => e.value === params.language
                )[0].label,
                Button: JSON.stringify(params.buttons),
                "Number of variables": params.body
                  .split(" ")
                  .filter((e: any) => regX.test(e)).length,
                "Message variables": variable,
              };
              clevertapEventPush({
                eventId: CT_EVENT_IDS.CUSTOM_TEMPLATE_CREATED,
                eventData: eventData,
              });
              showToast("New Template created successfully");
              props.setIsCreateTemplate(false);
            } else {
              callToAction.map((item: any) => {
                if (item?.type === "URL" && item?.urlType === DYNAMIC) {
                  item.value = item.value.replace("{{1}}", "");
                  item.example = "";
                }
                return item;
              });
              showErrorToast(response);
            }
          },
          (err) => {
            callToAction.map((item: any) => {
              if (item?.type === "URL" && item?.urlType === DYNAMIC) {
                item.value = item.value.replace("{{1}}", "");
                item.example = "";
              }
              return item;
            });
            if (err.response.data[0] === "Body and Example Mismatch") {
              showErrorToast("Body and Sample Message Mismatch");
            } else if (err.response.data[0] === "Duplicate Template Name") {
              showErrorToast("Duplicate Template Name");
            } else {
              showErrorToast("Unable to create template");
            }
          }
        );
      }
    },
    [formValue, callToAction]
  );
  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const handleVariable = (value: any) => {
    const tempVar: any = [];
    let curMatch: any = "";
    const regExp = /{{(.*?)}}/g; // Regex for get value between two {{}}

    while ((curMatch = regExp.exec(formValue.body))) {
      tempVar.push(curMatch[1]);
    }
    const isVariableRepeated = tempVar.includes(value);
    if (isVariableRepeated) {
      showErrorToast("Variable already exists");
    } else {
      const tVar = " {{" + value + "}} ";
      var curPos = (document.getElementById("tempBody") as HTMLInputElement)
        ?.selectionStart;
      setFormValue({
        ...formValue,
        ["body"]: [
          formValue.body.slice(0, curPos),
          tVar,
          formValue.body.slice(curPos),
        ].join(""),
      });
    }
  };

  const handleCustomeVariable = () => {
    let count = customVarCount + 1;
    setCustomVarCount(count);
    const tVar = ` {{Variable${count}}} `;
    var curPos = (document.getElementById("tempBody") as HTMLInputElement)
      ?.selectionStart;
    setFormValue({
      ...formValue,
      ["body"]: [
        formValue.body.slice(0, curPos),
        tVar,
        formValue.body.slice(curPos),
      ].join(""),
    });
  };

  const handleCustomeVariableRemoval = (length: number) => {
    let count = length;
    setCustomVarCount(count);
    const tVar = ` {{Variable${count}}} `;
    var curPos = (document.getElementById("tempBody") as HTMLInputElement)
      ?.selectionStart;
    setFormValue({
      ...formValue,
      ["body"]: [
        formValue.body.slice(0, curPos),
        tVar,
        formValue.body.slice(curPos),
      ].join(""),
    });
  };

  const createSampleMessage = () => {
    let regX = /^{{[a-zA-Z0-9 _#$%.]*}}$/;
    let message = formValue.body;
    let AllVariables = {
      ...PreConfiguredTemplateVariables,
      ...variables,
    };

    formValue.body.split(" ").forEach((x: keyof typeof AllVariables) => {
      if (regX.test(x as string) && AllVariables[x]) {
        message = message.replace(x, AllVariables[x]);
      }
    });

    setFormValue({ ...formValue, sampleMessage: message });
  };

  function getCustomVariables(x: any) {
    var regexExp = /^{{Variable[0-9]{1,}}}$/;
    return regexExp.test(x);
  }

  const mediaTypeSelected = (val: any) => {
    if (options["TYPE"][1].value !== val) {
      setSampleMediaFileName("");
      setMediaFileTypeSelected(true);
      setValidFileFormats(validaFormats[val as keyof typeof validaFormats]);
    } else {
      setMediaFileTypeSelected(false);
    }
    setFormValue({ ...formValue, type: val, exampleMedia: "" });
  };

  const clickInputField = () => {
    (document as any).getElementById("uploadSampleMediaFile").click();
  };

  const fileUpload = (e: any) => {
    //20MB === 20971520
    let resMessage = e.target.files.length
      ? invalidMediaFileSizeAndType(
          formValue.type,
          e.target.files[0].type,
          e.target.files[0].size
        )
      : { error: true, message: SAMPLE_MEDIA_FILE.file_size_error };
    if (e.target?.files.length < 1 || resMessage?.error) {
      setErrorToast({ show: true, message: resMessage.message });
    } else {
      let formdata = new FormData();
      formdata.append("file", e.target.files[0]);
      formdata.append("fileType", e.target.files[0].type);
      setUploadingMedia(true);
      addCustomTemplateMedia(props.shop.userId, formdata)
        .then((response: any) => {
          if (response?.message)
            setFormValue({
              ...formValue,
              exampleMedia: response?.message,
            });
          setSampleMediaFileName(e.target.files[0].name);
          setUploadingMedia(false);
        })
        .catch((err) => {
          console.log(err);
          setSampleMediaFileName("");
          setUploadingMedia(false);
        });
    }
  };

  const removeSampleMedia = () => {
    setFormValue({ ...formValue, exampleMedia: "" });
    setSampleMediaFileName("");
  };

  useEffect(() => {
    var filteredVariable = formValue.body.split(" ").filter(getCustomVariables);
    setFilteredVariables(filteredVariable);
    setVariables(
      Object.keys(variables)
        .filter((key) => filteredVariables.includes(key))
        .reduce((obj: any, key: any) => {
          obj[key] = variables[key];
          return obj;
        }, {})
    );
    if (filteredVariable.length < customVarCount)
      handleCustomeVariableRemoval(filteredVariable.length);
    createSampleMessage();
  }, [formValue.body]);

  return (
    <Layout>
      {toastMarkup}
      {errorToastMarkup}
      <div className="create-custom-template">
        <Layout>
          <Form onSubmit={handleSubmit}>
            <FormLayout.Group condensed>
              <TextFieldSection
                id="templateName"
                fieldLabel="Template Name*"
                fieldMessage="The name should resemble the template and contain lowercase letters, numbers, and underscores only."
                isTooltip={false}
              >
                <TextField
                  maxLength={513}
                  value={formValue.templateName}
                  name={`templateName`}
                  onChange={(val) => {
                    setFormValue({ ...formValue, templateName: val });
                  }}
                  label=""
                  type="text"
                  error={formValueValidation(
                    "templateName",
                    formValue.templateName
                  )}
                />
              </TextFieldSection>

              <TextFieldSection
                id="category"
                fieldLabel="Category*"
                fieldMessage="Choose the category of message template you want to create."
                isTooltip={true}
              >
                <Select
                  label=""
                  placeholder=""
                  options={options["CATEGORY"]}
                  value={formValue.category}
                  onChange={(val) =>
                    setFormValue({ ...formValue, category: val })
                  }
                />
              </TextFieldSection>
            </FormLayout.Group>

            <FormLayout.Group condensed>
              <TextFieldSection
                id="type"
                fieldLabel="Type*"
                fieldMessage="Select a media type."
                isTooltip={true}
              >
                <Select
                  name={`type`}
                  label=""
                  placeholder=""
                  options={options["TYPE"]}
                  value={formValue.type}
                  onChange={(val) => mediaTypeSelected(val)}
                />
              </TextFieldSection>

              <TextFieldSection
                id="language"
                fieldLabel="Language*"
                fieldMessage="Choose the language of your message."
                isTooltip={false}
              >
                <Select
                  label=""
                  placeholder=""
                  options={options["LANGUAGE"]}
                  value={formValue.language}
                  onChange={(val) =>
                    setFormValue({ ...formValue, language: val })
                  }
                />
              </TextFieldSection>
            </FormLayout.Group>

            <div className="custom-temp-body">
              <FormLayout.Group condensed>
                <TextFieldSection
                  id="body"
                  fieldLabel="Body*"
                  fieldMessage="Enter the text for your message in the language you've selected. To personalize the message for your customers you can specify dynamic variables as a number enclosed in double curly braces {{#}}. Ex. Hi {{1}}, your order no. {{2}} has been placed."
                  isTooltip={true}
                >
                  <TextField
                    id="tempBody"
                    label=""
                    name={`body`}
                    value={formValue.body}
                    onChange={(val) => {
                      setFormValue({ ...formValue, body: val });
                    }}
                    multiline={4}
                    error={formValueValidation("body", formValue.body)}
                    maxLength={1025}
                  />
                  <div
                    className="add-variable absolute"
                    style={{
                      bottom: formValueValidation("body", formValue.body)
                        ? "34px"
                        : "10px",
                    }}
                  >
                    <Button>+ Add Variable</Button>
                    <div className="absolute">
                      <div className="variable-content">
                        <div
                          className="pointer"
                          onClick={() => handleCustomeVariable()}
                        >
                          Custom variable
                        </div>
                        {filteredVariableOptions.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="pointer"
                              onClick={() => handleVariable(item.value)}
                            >
                              {item.label}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </TextFieldSection>
                <Stack distribution="fillEvenly">
                  {/* Header should not be sent id template supports media */}
                  {!mediaFileTypeSelected && (
                    <TextFieldSection
                      id="header"
                      fieldLabel="Header (optional)"
                      fieldMessage="Add a 60 character title to your message."
                      isTooltip={false}
                    >
                      <TextField
                        maxLength={61}
                        value={formValue.header}
                        name={`header`}
                        onChange={(val) => {
                          setFormValue({ ...formValue, header: val });
                        }}
                        label=""
                        type="text"
                        error={formValueValidation("header", formValue.header)}
                      />
                    </TextFieldSection>
                  )}

                  <TextFieldSection
                    id="footer"
                    fieldLabel="Footer (optional)"
                    fieldMessage="Add a 60 character footer to your message. Variables are not supported in the footer."
                    isTooltip={false}
                  >
                    <TextField
                      maxLength={61}
                      value={formValue.footer}
                      name={`footer`}
                      onChange={(val) => {
                        setFormValue({ ...formValue, footer: val });
                      }}
                      label=""
                      type="text"
                      error={formValueValidation("footer", formValue.footer)}
                    />
                  </TextFieldSection>
                </Stack>
              </FormLayout.Group>
            </div>
            <FormLayout.Group>
              <TextFieldSection
                id="buttons"
                fieldLabel="Buttons (optional)"
                fieldMessage="Create buttons that let customers respond to your message or take action."
                isTooltip={false}
              >
                <Stack>
                  <RadioButton
                    label="None"
                    checked={formValue.button == "none"}
                    id="disabled"
                    name=""
                    onChange={() =>
                      setFormValue({ ...formValue, button: "none" })
                    }
                  />
                  <RadioButton
                    label="Call to Actions"
                    id="callToActions"
                    name=""
                    checked={formValue.button === "callToActions"}
                    onChange={() =>
                      setFormValue({ ...formValue, button: "callToActions" })
                    }
                  />
                </Stack>
              </TextFieldSection>
            </FormLayout.Group>
            <FormLayout.Group>
              {formValue?.button == "callToActions" && (
                <Card sectioned>
                  {
                    <>
                      {[...Array(callToActionCount)].map((_, i) => (
                        <CallToActionSection
                          key={i}
                          index={i}
                          option={options}
                          setCallToActionCount={setCallToActionCount}
                          setCallToActionUrlType={setCallToActionUrlType}
                          callToAction={callToAction}
                          setCallToAction={setCallToAction}
                          formValueValidation={formValueValidation}
                          callToActionCount={callToActionCount}
                          callToActionUrlType={callToActionUrlType}
                        />
                      ))}
                      {/* {callToActionCount < 2 && (
                      <Button onClick={handleActionCount} primary size='slim'>
                        Add call to Action
                      </Button>
                    )} */}
                    </>
                  }
                </Card>
              )}
            </FormLayout.Group>
            {filteredVariables.length ? (
              <FormLayout.Group>
                <TextFieldSection
                  id="InputCustomVariables"
                  fieldLabel="Sample Variable*"
                  fieldMessage="To help WhatsApp understand what kind of messages you send, please provide specific sample content for the variables."
                  isTooltip={false}
                >
                  {filteredVariables.map((variableName: string | number) => {
                    return (
                      <TextField
                        maxLength={61}
                        value={variables[variableName]}
                        onChange={(val) => {
                          let d = {
                            ...variables,
                            [variableName]: val,
                          };
                          setVariables(d);
                        }}
                        onBlur={createSampleMessage}
                        placeholder={`Enter Sample for ${variableName}`}
                        type="text"
                        label=""
                      />
                    );
                  })}
                </TextFieldSection>
              </FormLayout.Group>
            ) : null}

            {mediaFileTypeSelected ? (
              <FormLayout.Group>
                <TextFieldSection
                  id="InputSampleMedia"
                  fieldLabel="Sample Media*"
                  fieldMessage={SAMPLE_MEDIA_FILE.label}
                  isTooltip={false}
                >
                  <div className="uploadMediaFile">
                    <input
                      onChange={fileUpload}
                      accept={validFileFormats}
                      id="uploadSampleMediaFile"
                      type="file"
                      hidden
                    />
                    <Button
                      onClick={clickInputField}
                      loading={uploadingMedia}
                      disabled={sampleMediaFileName ? true : false}
                    >
                      {SAMPLE_MEDIA_FILE.button_text}
                    </Button>
                    {sampleMediaFileName ? (
                      <div
                        className="removeMediaFile"
                        onClick={removeSampleMedia}
                      >
                        <label>
                          {sampleMediaFileName}
                          <Icon source={CircleCancelMinor}></Icon>
                        </label>
                      </div>
                    ) : (
                      <label>{SAMPLE_MEDIA_FILE.no_file_text}</label>
                    )}
                  </div>
                  <div className="fileTypeNote">
                    {formValue.type === options.TYPE[2].value && (
                      <span>{SAMPLE_MEDIA_FILE.imageFileLimit}</span>
                    )}
                    {formValue.type === options.TYPE[3].value && (
                      <span>{SAMPLE_MEDIA_FILE.videoFileLimit}</span>
                    )}
                    {formValue.type === options.TYPE[4].value && (
                      <span>{SAMPLE_MEDIA_FILE.documentFileLimit}</span>
                    )}
                  </div>
                </TextFieldSection>
              </FormLayout.Group>
            ) : null}

            {callToActionUrlType === DYNAMIC &&
              formValue?.button === "callToActions" && (
                <FormLayout.Group>
                  <TextField
                    type="text"
                    label=""
                    name={`suffix`}
                    value={formValue.suffix}
                    onChange={(val) => {
                      setFormValue({ ...formValue, suffix: val });
                    }}
                    placeholder={"Enter Sample for Suffix of Dynamic URL {{1}}"}
                    // error={formValueValidation('suffix', formValue.suffix)}
                  />
                </FormLayout.Group>
              )}
            <FormLayout.Group>
              <Stack>
                <Button size="large" onClick={clearData}>
                  Discard
                </Button>
                <Button size="large" submit primary>
                  Create
                </Button>
              </Stack>
            </FormLayout.Group>
          </Form>
        </Layout>
      </div>
    </Layout>
  );
};

export default CreateCustomTemplate;
