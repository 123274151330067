import {
  Card,
  Layout,
  Loading,
  Modal,
  SettingToggle,
  SkeletonBodyText,
  SkeletonDisplayText,
  Stack,
  TextContainer,
  TextStyle,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import { Fragment, useEffect, useState } from "react";
import {
  addIntegration,
  disableIntegration,
  getAllIntegrations,
  getIntegrationsListForShop,
} from "../../redux/actions/private-waba";

const SupportedIntegrations = (props: any) => {
  const {
    shop,
    setToast,
    setErrorToast,
    setShowErrorToast,
    setShowSuccessToast,
  } = props;
  const [supportedIntegrations, setSupportedIntegrations] = useState([]);
  const [existingIntegrations, setExistingIntegrations] = useState([]);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [agreeToPricing, setAgreeToPricing] = useState(true);
  const [openedDialogId, setOpenedDialogId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchSupportedIntegrations();
  }, []);

  const fetchSupportedIntegrations = () => {
    setIsLoading(true);
    getAllIntegrations().then(
      (response: any) => {
        if (response.status === "success") {
          setSupportedIntegrations(response.integrations);
          // checkIntegrations();
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
        setIsLoading(false);
      },
      (err) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast(
          "Something went wrong. Unable to fetch integration list."
        );
        setIsLoading(false);
      }
    );
  };

  const closePricingModal = () => {
    setShowPricingModal(false);
    setAgreeToPricing(false);
    setOpenedDialogId(null);
  };

  const handleIntegration = (existing_integration: any, integration: any) => {
    if (existing_integration) {
      updateIntegration(integration);
    } else {
      createIntegration(integration);
    }
  };

  const getToggleSwitchBtnContent = (
    is_active: boolean,
    is_verified: boolean
  ) => {
    if (is_verified !== undefined && !is_verified) {
      return "Pending";
    } else if (is_active) {
      return "Disable";
    } else {
      return "Enable";
    }
  };

  const getToggleSwitchTxtContent = (
    is_enabled: boolean,
    is_verified: boolean
  ) => {
    if (is_verified !== undefined && !is_verified) {
      return "pending";
    } else if (is_enabled) {
      return "enabled";
    } else {
      return "disabled";
    }
  };

  const getToggleSwitchVarationStyle = (
    is_active: boolean,
    is_verified: boolean
  ) => {
    if (is_verified !== undefined && !is_verified) {
      return "subdued";
    } else if (is_active) {
      return "strong";
    } else {
      return "negative";
    }
  };

  const checkIntegrations = () => {
    setIsLoading(true);
    getIntegrationsListForShop(shop.userId).then(
      (response: any) => {
        if (response.status === "success") {
          setExistingIntegrations(response.integrations);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast(response);
        }
        setIsLoading(false);
      },
      (err) => {
        setShowErrorToast((errorToast: any) => !errorToast);
        setErrorToast(
          "Something went wrong. Unable to fetch integrations of shop."
        );
        setIsLoading(false);
      }
    );
  };

  const createIntegration = (integration: any) => {
    if (agreeToPricing) {
      setIsLoading(true);
      let params = new URLSearchParams();
      params.append("integrationId", integration.id);
      let is_enabled = getToggleSwitchTxtContent(!integration.active, true);
      addIntegration(shop.userId, params).then(
        (response: any) => {
          if (response.status === "success") {
            fetchSupportedIntegrations();
            setToast("Integration is " + is_enabled + " successfully");
          } else {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(response);
          }
          setIsLoading(false);
          setShowPricingModal(false);
          // setAgreeToPricing(false);
        },
        (err) => {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast("Something went wrong. Unable to add integration.");
          setIsLoading(false);
        }
      );
    } else {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("Please accept the terms before proceeding");
    }
  };

  const updateIntegration = (integration: any) => {
    if (agreeToPricing) {
      setIsLoading(true);
      let params = new URLSearchParams();
      params.append("enable", JSON.stringify(!integration.active));
      let is_enabled = getToggleSwitchTxtContent(!integration.active, true);
      disableIntegration(integration.id, params).then(
        (response: any) => {
          if (response.status === "success") {
            fetchSupportedIntegrations();
            setToast("Integration is " + is_enabled + " successfully");
          } else {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(response);
          }
          setIsLoading(false);
          setShowPricingModal(false);
          // setAgreeToPricing(false);
        },
        (err) => {
          setShowErrorToast((errorToast: any) => !errorToast);
          setErrorToast("Something went wrong. Could not be " + is_enabled);
          setIsLoading(false);
        }
      );
    } else {
      setShowErrorToast((errorToast: any) => !errorToast);
      setErrorToast("Please accept the terms before proceeding");
    }
  };

  const enableButton = () => {
    setAgreeToPricing(!agreeToPricing);
  };

  const renderModal = (existing_integration: any, integration: any) => {
    const tooltip = (
      <div>
        <p>Privacy Policy:</p>
        <ol>
          <li>
            You are allowing Delight Chat to send messages via their SuperLemon
            private WhatsApp business account. This means your data will be
            shared between Delight Chat and SuperLemon.
          </li>
          <li>
            Application of charges for messages sent via Delight Chat after 30th
            April,2021.
          </li>
          <li>Data sharing between Delight Chat and SuperLemon.</li>
        </ol>
      </div>
    );
    return showPricingModal ? (
      <div style={{ height: "500px" }}>
        <Modal
          open={showPricingModal && openedDialogId === integration.id}
          onClose={closePricingModal}
          title="Pricing Details"
          primaryAction={{
            content: "Submit",
            onAction: () =>
              handleIntegration(existing_integration, integration),
          }}
          secondaryActions={[
            {
              content: "Close",
              onAction: closePricingModal,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>
                Please take a look at the SuperLemon - Delight Chat Pricing
                Policy.
              </p>
              <p>
                <strong>Special Offer: </strong> You will not be charged by
                SuperLemon till 30th April.
              </p>
              <div>
                <input
                  type="checkbox"
                  id="terms"
                  onChange={() => enableButton()}
                  defaultChecked={agreeToPricing}
                  checked={agreeToPricing}
                />
                <label htmlFor="terms">
                  {" "}
                  Yes I agree to SuperLemon - Delight Chat &nbsp;
                  <Tooltip content={tooltip}>
                    <span className="policy-link">Privacy Policy</span>
                  </Tooltip>
                  .
                </label>
              </div>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </div>
    ) : null;
  };

  const openPricingModal = (existing_integration: any, integration: any) => {
    if (!integration.active) {
      setAgreeToPricing(false);
      setShowPricingModal(true);
      setOpenedDialogId(integration.id);
    } else {
      // setAgreeToPricing(true);
      handleIntegration(existing_integration, integration);
    }
  };

  const renderIntegration = (integration: any) => {
    let existingIntegration: any,
      enabled = false;
    if (existingIntegrations) {
      let integrationFound: any = existingIntegrations.find(
        (m: any) => m.integrationId === integration.id
      );

      if (integrationFound) {
        existingIntegration = {
          ...integrationFound,
          description: integration.description,
          name: integration.name,
          logoUrl: integration.logoUrl,
          redirectionUrl: integration.redirectionUrl,
        };
      }
    }

    let logoUrl = null;
    let pricingModal = null;
    if (existingIntegration) {
      if (existingIntegration.logoUrl) {
        logoUrl = (
          <div
            className="pointer"
            onClick={() => {
              window.open(existingIntegration.redirectionUrl, "_blank");
            }}
          >
            <Thumbnail source={existingIntegration.logoUrl} alt="Logo" />
          </div>
        );
      }
      pricingModal = renderModal(true, existingIntegration);
      if (existingIntegration.verified && existingIntegration.active) {
        enabled = true;
      }
      return (
        <div className="card-integration" key={integration.id}>
          <Layout>
            <Layout.Section>
              <SettingToggle
                action={{
                  content: getToggleSwitchBtnContent(
                    existingIntegration.active,
                    existingIntegration.verified
                  ),
                  onAction: () => openPricingModal(true, existingIntegration),
                  disabled: !existingIntegration.verified,
                }}
                enabled={enabled}
              >
                <Stack wrap={false}>
                  {logoUrl}
                  <div className="Polaris-Stack__Item">
                    <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingExtraTight">
                      <div className="Polaris-Stack__Item">
                        <TextStyle>
                          <strong>{integration.name}</strong> is currently
                          <TextStyle
                            variation={getToggleSwitchVarationStyle(
                              existingIntegration.active,
                              existingIntegration.verified
                            )}
                          >
                            {" "}
                            {getToggleSwitchTxtContent(
                              existingIntegration.active,
                              existingIntegration.verified
                            )}
                          </TextStyle>
                        </TextStyle>
                      </div>
                      <div className="Polaris-Stack__Item description-integration">
                        <TextStyle variation="subdued">
                          {integration.description}
                        </TextStyle>
                      </div>
                    </div>
                  </div>
                </Stack>
              </SettingToggle>
              {pricingModal}
            </Layout.Section>
          </Layout>
        </div>
      );
    } else {
      integration.active = false;
      if (integration.logoUrl) {
        logoUrl = (
          <div
            className="pointer"
            onClick={() => {
              window.open(integration.redirectionUrl, "_blank");
            }}
          >
            <Thumbnail source={integration.logoUrl} alt="Logo" />
          </div>
        );
      }
      pricingModal = renderModal(false, integration);
      return (
        <div className="card-integration" key={integration.id}>
          <Layout>
            <Layout.Section>
              <SettingToggle
                action={{
                  content: getToggleSwitchBtnContent(
                    integration.active,
                    integration.verified
                  ),
                  onAction: () => openPricingModal(false, integration),
                  disabled: false,
                }}
                enabled={false}
              >
                <div className="Polaris-Stack Polaris-Stack--noWrap">
                  <div className="Polaris-Stack__Item">{logoUrl}</div>
                  <div className="Polaris-Stack__Item description-integration">
                    <div className="Polaris-Stack__Item">
                      <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingExtraTight">
                        <div className="Polaris-Stack__Item">
                          <TextStyle>
                            <strong>{integration.name}</strong>
                          </TextStyle>
                        </div>
                        <div className="Polaris-Stack__Item">
                          <TextStyle variation="subdued">
                            {integration.description}
                          </TextStyle>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SettingToggle>
              {pricingModal}
            </Layout.Section>
          </Layout>
        </div>
      );
    }
  };

  const loadingMarkup = isLoading && <Loading />;

  const integrationsMarkup = isLoading ? (
    <Layout.AnnotatedSection>
      <Card sectioned>
        <SkeletonBodyText />
      </Card>
      <Card sectioned>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </Card>
      <Card sectioned>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </Card>
    </Layout.AnnotatedSection>
  ) : null;

  return (
    <Fragment>
      {loadingMarkup}
      {integrationsMarkup}
      {supportedIntegrations.map((integration) => {
        return renderIntegration(integration);
      })}
    </Fragment>
  );
};

export default SupportedIntegrations;
