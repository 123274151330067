// @ts-nocheck
import { Checkbox } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { useUsersContext } from '../../../../context/usersContext';

function TagsComponent(props: any) {
  const { searchParams } = useUsersContext();
  const [actionListItems, setActionListItems] = useState([]);
  const [selected, setSelected] = useState({});

  const handleChange = (ali: any) => {
    setSelected({
      ...selected,
      [ali]: !selected[ali],
    });
  };

  useEffect(() => {
    if (
      Object.keys(searchParams).includes('tags') &&
      searchParams['tags'].length
    ) {
      const selTag = {};
      searchParams['tags'].split(',').forEach((tag: any) => {
        selTag[tag] = true;
      });
      setSelected(selTag);
    }
  }, []);

  useEffect(() => {
    if (props.Tags.length) {
      setActionListItems(props.Tags);
    }
  }, [props.Tags]);

  useEffect(() => {
    props.setSelectedTags(selected);
  }, [selected]);

  return (
    <div className='chat__TagCheckbox'>
      {actionListItems.map((ali: any) => {
        return (
          <Checkbox
            id={ali.name}
            label={ali.name}
            checked={selected[ali.name]}
            onChange={() => {
              handleChange(ali.name);
            }}
          />
        );
      })}
    </div>
  );
}

export default TagsComponent;
