import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Page } from "@shopify/polaris";
import InstructionsImg1 from "../../assets/images/instructions-1.svg";
import { getPlans } from "../../redux/actions/pricing";
import Plan from "../../types/Plan.type";
import {
  PLAN_OLD_FREE,
  PLAN_OLD_PRO,
  PLAN_OLD_ULTIMATE,
} from "../../constants/constants";

const FreeSupport = () => {
  const history = useHistory();
  const [availablePlans, setAvailablePlans] = useState<Plan[]>();
  const [currentPlan, setCurrentPlan] = useState<number>();

  const openPricing = () => {
    history.push("/pricing");
  };

  useEffect(() => {
    let currentPlan = localStorage.getItem("currentPlan");
    setCurrentPlan(currentPlan ? Number(currentPlan) : PLAN_OLD_FREE);
    fetchPlans();
  }, []);

  const fetchPlans = () => {
    getPlans("").then(
      (response: any) => {
        if (response.status === "success") {
          response.plans.sort((a: any, b: any) =>
            a.index < b.index ? -1 : a.index > b.index ? 1 : 0
          );
          setAvailablePlans(response.plans);
        }
      },
      (err) => {}
    );
  };

  const getUpgradePlanText = (id: number) => {
    let content = "";
    switch (id) {
      case PLAN_OLD_PRO:
        content =
          "Upgrade to the Pro Plan to use the support extension tool via your personal WhatsApp number and create your own templates and quick replies";
        break;
      case PLAN_OLD_ULTIMATE:
        content =
          "Upgrade to the Ultimate Plan to send automated template messages via our WhatsApp Business Account or make custom templates via your own private WhatsApp Business Account";
        break;
    }
    return content;
  };

  const plansTemplates = () => {
    var temp: any = [];
    if (availablePlans) {
      for (let i = 0; i < availablePlans.length; i++) {
        if (Number(availablePlans[i].id) !== Number(currentPlan)) {
          var template = (
            <Layout.Section>
              <div className="Polaris-Card">
                <div className="Polaris-CalloutCard__Container">
                  <div className="Polaris-Card__Section">
                    <div className="Polaris-CalloutCard">
                      <div className="Polaris-Stack Polaris-Stack--alignmentCenter Polaris-Stack--distributionFill w-100">
                        <div className="Polaris-Stack__Item">
                          <img
                            src={InstructionsImg1}
                            alt="Upgrade to Pro Plan"
                            className="Polaris-CalloutCard__Image w-1 h-1"
                          />
                        </div>
                        <div className="Polaris-Stack__Item upgrade-text">
                          <p className="Polaris-DisplayText fs-1">
                            {availablePlans[i].name}
                          </p>
                          <p>
                            {getUpgradePlanText(Number(availablePlans[i].id))}
                          </p>
                        </div>
                        <div className="Polaris-Stack__Item">
                          <button
                            className="Polaris-Button Polaris-Button--primary Polaris-Button--outline outline pull-right"
                            type="button"
                            onClick={openPricing}
                          >
                            <span className="Polaris-Button__Content">
                              <span className="Polaris-Button__Text">
                                Upgrade Plan
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Layout.Section>
          );
          temp.push(template);
        }
      }
      return temp;
    }
  };
  return (
    <Page title="Upgrade Plan">
      <Layout>{plansTemplates()}</Layout>
    </Page>
  );
};

export default FreeSupport;
